import PlanInfo from "./PlanInfo";
import "./css/BusinessPlanMain.scss";
import { useEffect, useState } from "react";
import CommonAPI from "../../API/common";
import Pagination from "../common/Pagination";
import { useHistory } from "react-router-dom";
import { businessPlanDefaultData } from "../../atom";
import { useRecoilState } from "recoil";
import SearchCrawledData from "../common/SearchCrawledData";

const BusinessPlanMain = () => {
  const history = useHistory();
  const [categoryList, setCategoryList] = useState();
  const [list, setList] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [last, setLast] = useState(1);
  const [defaultData, setDefaultData] = useRecoilState(businessPlanDefaultData);
  const handleButtonClick = () => {
    history.push('rnd/first');
  };
  useEffect(() => {
    getCategory();
  }, []);

  useEffect(() => {
    getCategoryList();
  }, [curPage]);

  const getCategory = () => {
    CommonAPI.getBusinessCategory().then((res) => {
      setCategoryList(
        res.data.filter((item) => !item.parent_idx && item.idx < 10)
      );
    });
  };

  const getCategoryList = () => {
    let payload = {
      count: 10,
      page: curPage,
    };

    CommonAPI.getBusinessCategoryList(payload).then((res) => {
      setList(res.data.items);
      setTotalCount(res.data.total_count);
      setLast(res.data.last);
    });
  };

  const downloadFile = (idx) => {
    window.$Global
      .fileDownloadSync(`/common/business/category/${idx}/download`)
      .then((res) => console.log(res));
  };

  return (
    <div id="BusinessPlanMain">
      <div className="banner_wrapper">
      <div className="banner_item" style={{ maxWidth: '90%' }}>
        <p style={{ textAlign: 'center', height: '70px' }}>창업사업화지원사업</p>
        <p style={{ textAlign: 'center', lineHeight: '1px' }}>
          <span style={{ color: 'yellow' }}>예비창업 </span>패키지<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        </p>
        <p style={{ textAlign: 'center', height: '70px' }}>사업계획서 작성하기</p>
        
          <button onClick={handleButtonClick}>작성하러 가기</button>
        </div>
        <div className="banner_item" style={{ maxWidth: '90%', }}>
        <p style={{ textAlign: 'center', height: '70px' }}>창업사업화지원사업</p>
        <p style={{ textAlign: 'center', lineHeight: '1px' }}>
          <span style={{ color: 'yellow' }}>초기창업 </span>패키지<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        </p>
        <p style={{ textAlign: 'center', height: '70px' }}>사업계획서 작성하기</p>
        
          <button onClick={handleButtonClick}>작성하러 가기</button>
        </div>
        <div className="banner_item" style={{ maxWidth: '90%' }}>
        <p style={{ textAlign: 'center', height: '70px' }}>창업사업화지원사업</p>
        <p style={{ textAlign: 'center', lineHeight: '1px' }}>
          <span style={{ color: 'yellow' }}>도약단계(일반/협업과제) </span><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        </p>
        <p style={{ textAlign: 'center', height: '70px' }}>사업계획서 작성하기</p>
        
          <button onClick={handleButtonClick}>작성하러 가기</button>
        </div>
      </div>
      <div className="categoryList">
        <div className="category_items">
          {categoryList?.map((el) => {
            return (
              <div
                className="category_item"
                onClick={() => alert("준비중입니다.")}
              >
                <div className="category_item_name">
                  <i className="icon_government" />
                  <p>
                  <span>
                    {el.name === '중기부' ? '중소벤처기업부' :
                    el.name === '산업부' ? '산업통상자원부' :
                    el.name === '과기부' ? '과학기술정보통신부' :
                    el.name === '문체부' ? '문화체육관광부' :
                    el.name === '해수부' ? '해양수산부' :
                    el.name === '국토부' ? '국토교통부' :
                    el.name === '복지부' ? '보건복지부' :
                    el.name === '농림부' ? '농림축산식품부' :
                    el.name === '환경부' ? '환경부' : el.name}
                  </span>
                    모아보기
                  </p>
                </div>
                <i className="icon_double_arrow_right" />
              </div>
            );
          })}
        </div>
      </div>
      <div className="businessPlanList">
        <header>
          <p>
            활용할 수 있는 사업계획서{" "}
            <span>{window.$Global.commaify(totalCount)}</span>건
          </p>
        </header>
        <section>
          <table>
            <colgroup>
              <col width={50} />
              <col width={70} />
              <col width={450} />
              <col width={100} />
              <col width={80} />
            </colgroup>
            <thead>
              <tr>
                <th>순번</th>
                <th>기관명</th>
                <th style={{ textAlign: "left" }}>
                  기술개발 사업계획서 양식명
                </th>
                <th>원본 다운</th>
                <th>작성하러 가기</th>
              </tr>
            </thead>
            <tbody>
              {list.map((item, index) => {
                const name = item.name.replace(/(.hwp|.docx)$/, "");
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item.categoryName}</td>
                    <td style={{ textAlign: "left" }}>{name}</td>
                    <td>
                      <i
                        className="icon_download_attached"
                        onClick={() => downloadFile(item.idx)}
                      />
                    </td>
                    <td>
                      <i
                        className="icon_arrow_right_s"
                        onClick={() => {
                          setDefaultData({
                            name: item.name,
                            category_name: item.categoryName,
                          });
                          history.push("rnd/first");
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pagination
            curPage={curPage}
            lastNum={last}
            onClick={setCurPage}
            scrollType={false}
          />
        </section>
      </div>
    </div>
  );
};

export default BusinessPlanMain;
