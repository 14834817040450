import "../css/HoldingsComp.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const HoldingsComp = () => {
  const history = useHistory();
  const compData = [
    {
      name: "아이피나우",
      ceo: "황차동",
      product: "아이피나우",
      date: "24.05.24",
      level: "Series A",
      sales: "245,784",
      invest: "300,000",
      network: "updated",
      link: "/holdings/project?id=ipnow",
    },
    {
      name: "위너링크",
      ceo: "황한석",
      product: "위너링크",
      date: "24.02.20",
      level: "Series A",
      sales: "127,890",
      invest: "200,000",
      network: "updated",
      link: "/holdings/project?id=winnerlink",
    },
    {
      name: "아이티엘",
      ceo: "황차동",
      product: "아이티엘",
      date: "24.03.17",
      level: "Series A",
      sales: "209,800",
      invest: "270,000",
      network: "normal",
    },
    {
      name: "비즈내비",
      ceo: "황차동",
      product: "비즈내비",
      date: "24.04.22",
      level: "Series A",
      sales: "187,500",
      invest: "125,000",
      network: "normal",
    },
    {
      name: "꿀픽",
      ceo: "황차동",
      product: "꿀픽",
      date: "23.10.14",
      level: "Series B",
      sales: "90,750",
      invest: "15,000",
      network: "normal",
    },
    {
      name: "김조교",
      ceo: "황차동",
      product: "김조교",
      date: "23.09.24",
      level: "Series B",
      sales: "56,340",
      invest: "20,000",
      network: "normal",
    },
    {
      name: "인크리셀",
      ceo: "황차동",
      product: "인크리셀",
      date: "23.06.17",
      level: "Series B",
      sales: "147,360",
      invest: "90,000",
      network: "normal",
    },
    {
      name: "에듀온링크",
      ceo: "황차동",
      product: "에듀온링크",
      date: "23.07.16",
      level: "Series C",
      sales: "87,340",
      invest: "20,000",
      network: "normal",
    },
    {
      name: "나우앤드",
      ceo: "황차동",
      product: "나우앤드",
      date: "24.04.02",
      level: "Series B",
      sales: "12,550",
      invest: "10,000",
      network: "normal",
    },
    {
      name: "iAi",
      ceo: "황차동",
      product: "iAi",
      date: "24.03.12",
      level: "Series C",
      sales: "23,974",
      invest: "12,000",
      network: "normal",
    },
  ];
  return (
    <div id="HoldingsComp">
      <div className="part">
        <div>
          <strong class="part_tit">기업별 현황</strong>
        </div>
        <div className="comp_tbl">
          <table>
            <thead>
              <tr>
                <th>기업명</th>
                <th>대표자</th>
                <th>제품(서비스)</th>
                <th>투자일</th>
                <th>투자단계</th>
                <th>전분기 매출(만원)</th>
                <th>투자금액(만원)</th>
                {/* <th>최신 IR</th> */}
                <th>네트워킹룸</th>
              </tr>
            </thead>
            <tbody>
              {compData?.length ? (
                compData.map((comp, idx) => (
                  <tr key={idx}>
                    <td>{comp.name}</td>
                    <td>{comp.ceo}</td>
                    <td>{comp.product}</td>
                    <td>{comp.date}</td>
                    <td>{comp.level}</td>
                    <td>{comp.sales}</td>
                    <td>{comp.invest}</td>
                    {/* <td>
                      <button className="download">내려받기</button>
                    </td> */}
                    <td>
                      {comp.network === "updated" ? (
                        <button
                          className="updated"
                          onClick={() => history.push(comp.link)}
                        >
                          updated
                        </button>
                      ) : (
                        <button className="go_network">바로가기</button>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={8}>데이터가 없습니다.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default HoldingsComp;
