import GwAPI from "../../../../API/groupware";
import "./scss/GroupwareCashflow.scss";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import cashflowUtils from "./cashflowUtils";

const CashflowHistoryList = ({ getId }) => {
  const step = "list";
  let history = useHistory();
  const [cashflowList, setcashflowList] = useState([]);

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    try {
      const res = await GwAPI.getCashPlanList();

      if (res.data.cashflowReportList.length > 0) {
        const formattedList = await Promise.all(
          res.data.cashflowReportList.map(async (item) => {
            const formattedDate = formatDate(item.reg_date);

            const runwayRes = await GwAPI.getMonthGraphPlan({
              report_id: item.report_id,
            });
            const runway = runwayRes.data.runways;

            return {
              ...item,
              updated_Date: formattedDate,
              runway: runway,
            };
          })
        );

        return setcashflowList(formattedList);
      } else {
        alert("저장된 캐시플로우가 없습니다. 작성페이지로 이동합니다.");
        history.push("/cashflow/business/bizAct?isIncome=1&type=1&step=2");
      }
    } catch (error) {
      console.error("Error fetching cashflow list:", error);
    }
  };

  const delCashflow = (id) => {
    const isConfirmed = window.confirm("정말로 삭제하시겠습니까?");

    if (isConfirmed) {
      return GwAPI.deleteCashPlan({ report_id: id }).then((res) => {
        if (res.status === 200) {
          getId();
          getList();
        } else {
          alert("삭제를 실패했습니다.");
        }
      });
    }
  };

  const formatDate = (date) => {
    const dateString =
      typeof date === "number"
        ? new Date(date).toLocaleDateString("ko-KR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
        : date;

    const matched = dateString.match(/(\d{4})\. (\d{2})\. (\d{2})\./);
    if (matched) {
      return `${matched[1]}-${matched[2]}-${matched[3]}`;
    }
    return dateString;
  };

  return (
    <div className="cashflow_list history wrap bg_wh rounded">
      <table>
        <colgroup>
          <col />
          <col width="20%" />
          <col width="20%" />
          <col width="100px" />
          <col width="100px" />
          <col width="100px" />
        </colgroup>
        <thead>
          <tr>
            <th>업데이트 날짜</th>
            <th>현금 흐름 현황</th>
            <th>예상 RUNWAY 기간</th>
            <th>상태</th>
            <th>작성자</th>
            <th>리포트</th>
            <th>수정</th>
            <th>삭제</th>
          </tr>
        </thead>
        <tbody>
          {cashflowList.length ? (
            cashflowList.map((item) => (
              <tr key={item.report_id} className={`item ${item.report_id}`}>
                <td>{item.runway_period}</td>
                <td
                  className={
                    cashflowUtils.translateMonthToStatus(item.runway.runway_idx)
                      .code
                  }
                >
                  {
                    cashflowUtils.translateMonthToStatus(item.runway.runway_idx)
                      .text
                  }
                </td>
                <td
                  className={
                    cashflowUtils.translateMonthToStatus(item.runway.runway_idx)
                      .code
                  }
                >
                  RUNWAY까지&nbsp;
                  {item.runway.runway_idx === ""
                    ? "1년 이상"
                    : item.runway.runway_idx + "개월"}
                </td>
                <td>
                  {item.is_completed === 0 ? (
                    <span className="blue">작성중</span>
                  ) : (
                    <span className="red">완료</span>
                  )}
                </td>
                <td>{item.reg_name}</td>
                <td>
                  <Link
                    className="view_btn btn"
                    to={`/cashflow/business/view?report_id=${item.report_id}`}
                  >
                    보기
                  </Link>
                </td>
                <td>
                  <Link
                    className="mod_btn btn"
                    to={`/cashflow/business/bizAct?isIncome=1&type=1&step=2&report_id=${item.report_id}&mod=1`}
                  >
                    수정
                  </Link>
                </td>
                <td>
                  <button
                    className="del_btn btn"
                    onClick={() => delCashflow(item.report_id)}
                  >
                    삭제
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5}>데이터가 없습니다.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CashflowHistoryList;
