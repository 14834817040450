import API from "../../util/api";
import { process_params } from "express/lib/router";

export default {
  getAnnualPay(payload) {
    return API.get("/manager/ip/annual_pay_year", { params: payload });
  },

  singleAnnualPay(payload) {
    return API.get("/manager/ip/annual_pay_list", { params: payload });
  },

  getPatentAnnualPay(patent_idx, payload) {
    return API.get(`/manager/patent/${patent_idx}/annual_pay_year`, {
      params: payload,
    });
  },

  getTradeMarkAnnualPay(trademark_idx, payload) {
    return API.get(`/manager/trademark/${trademark_idx}/annual_pay_year`, {
      params: payload,
    });
  },

  getDesignAnnualPay(design_idx, payload) {
    return API.get(`/manager/design/${design_idx}/annual_pay_year`, {
      params: payload,
    });
  },

  getIPAnnualList(start_year, end_year) {
    return API.get(`/manager/ip/annual_graph`, {
      params: { start_year: start_year, end_year: end_year },
    });
  },

  getAnnualCount(payload) {
    return API.get("/manager/ip/year", { params: payload });
  },

  getPatentPayList(patent_idx, payload) {
    return API.get(`/manager/patent/${patent_idx}/annualPay`, {
      params: payload,
    });
  },

  getTradeMarkPayList(trademark_idx, payload) {
    return API.get(`/manager/trademark/${trademark_idx}/annualPay`, {
      params: payload,
    });
  },

  getPatentPayDetail(patent_idx, annual_pay_idx, payload) {
    return API.get(
      `/manager/patent/${patent_idx}/annualPay/${annual_pay_idx}`,
      {
        params: payload,
      }
    );
  },

  getTrademarkPayDetail(trademark_idx, annual_pay_idx, payload) {
    return API.get(
      `/manager/trademark/${trademark_idx}/annualPay/${annual_pay_idx}`,
      {
        params: payload,
      }
    );
  },

  uploadPatentPayData(patent_idx, annual_pay_idx, payload) {
    return API.post(
      `/manager/patent/${patent_idx}/annualPay/${annual_pay_idx}`,
      payload
    );
  },

  uploadTradeMarkPayData(trademark_idx, annual_pay_idx, payload) {
    return API.post(
      `/manager/trademark/${trademark_idx}/annualPay/${annual_pay_idx}`,
      payload
    );
  },

  patentApplication(patent_idx, annual_pay_idx, payload) {
    return API.post(
      `/manager/patent/${patent_idx}/annualPay/${annual_pay_idx}/application`,
      payload
    );
  },

  tradeMarkApplication(trademark_idx, annual_pay_idx, payload) {
    return API.post(
      `/manager/trademark/${trademark_idx}/annualPay/${annual_pay_idx}/application`,
      payload
    );
  },
  patentPayGiveUp(patent_idx, annual_pay_idx, payload) {
    return API.post(
      `/manager/patent/${patent_idx}/annualPay/${annual_pay_idx}/giveUp`,
      payload
    );
  },

  tradeMarkPayGiveUp(trademark_idx, annual_pay_idx, payload) {
    return API.post(
      `/manager/trademark/${trademark_idx}/annualPay/${annual_pay_idx}/giveUp`,
      payload
    );
  },

  getPatentReceiptData(patent_idx, annual_pay_idx, payload) {
    return API.get(
      `/manager/patent/${patent_idx}/annualPay/${annual_pay_idx}/billing`,
      payload
    );
  },

  getTradeMarkReceiptData(trademark_idx, annual_pay_idx, payload) {
    return API.get(
      `/manager/trademark/${trademark_idx}/annualPay/${annual_pay_idx}/billing`,
      payload
    );
  },

  getPatentList(payload) {
    return API.get("/manager/patent/annualPayList", { params: payload });
  },

  updatePatentList(data) {
    return API.post("/manager/patent/annualPayList", data);
  },

  getTradeMarkList(payload) {
    return API.get("/manager/trademark/annualPayList", { params: payload });
  },

  updateTradeMarkList(data) {
    return API.post("/manager/trademark/annualPayList", data);
  },

  // 공동출원인 보기
  getJointApplicantList() {
    return API.get("/manager/patent/jointApplicant");
  },
  // 특허 연차료 코멘트 수정
  updateComment(patent_idx, annual_pay_idx, comment_idx, payload) {
    return API.patch(
      `/manager/patent/${patent_idx}/annualPay/${annual_pay_idx}/comment/${comment_idx}`,
      payload
    );
  },
};
