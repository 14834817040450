import qs from "query-string";
import React, { useEffect, useState } from "react";
import GroupwareSideBar from "../GroupwareSideBar";
import { groupWareWorksPath } from "../groupwarePath";
import GroupwareBoard from "./GroupwareBoard";
import commonFunc from "../../admin/commonFunc/common";
import "../css/GroupwareInfoBoardMain.scss";

const GroupwareInfoBoardMain = ({ location, history }) => {
  const { section } = qs.parse(location.search);
  const [sectionItems, setSectionItems] = useState([]);

  useEffect(() => {
    handleSection();
  }, []);

  const handleSection = () => {
    commonFunc.convertCommunityCategory().then((res) => {
      let arr = [];
      res.map((item) => {
        console.log(item);
        if (item.child) {
          item.child.map((childItem) => {
            if (section === childItem.section) {
              arr.push(childItem);
            }
          });
        }
      });
      console.log(arr);
      setSectionItems(arr);
    });
  };

  return (
    <div id="GroupwareWorks">
      <GroupwareSideBar currentPath={groupWareWorksPath[section]} />
      <div className="board_main">
        <h5>{commonFunc.convertCommunitySectionName(section)}</h5>
        <div className="section_items">
          {sectionItems?.map((item) => {
            return (
              <div className="section_item">
                <h5>{item.name}</h5>
                <p>
                  {commonFunc.convertCommunitySectionName(section)} {item.name}
                  게시판
                </p>
                <button
                  onClick={() =>
                    history.push(
                      `/groupware/infoBoard/list?section=${section}&category=${item.value}`
                    )
                  }
                >
                  바로가기
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default GroupwareInfoBoardMain;
