import "./css/AnnualFeeReceipt.scss";
import React, { useEffect, useRef, useState } from "react";
import AnnualPay from "../../API/annualPay";
import { useReactToPrint } from "react-to-print";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import AdminAPI from "../../API/admin";

const AnnualFeeReceipt = ({
  annualFeeData,
  setShowPopup,
  showPatent,
  isAdmin,
}) => {
  const [data, setData] = useState([]);
  const componentRef = useRef();
  const hideRef = useRef();
  const hideRefTwo = useRef();
  const hideRefThree = useRef();
  const pageStyle = `{ size: 2.5in 4in }`;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: () => pageStyle,
  });

  useEffect(() => {
    isAdmin
      ? showPatent
        ? getAdminPatentReceiptData()
        : getAdminTradeMarkReceipt()
      : showPatent
      ? getPatentReceipt()
      : getTradeMarkReceipt();
  }, []);

  const getAdminPatentReceiptData = () => {
    return AdminAPI.getAdminPatentReceiptData(
      annualFeeData.patent_idx,
      annualFeeData.annual_pay_idx
    ).then((res) => {
      setData(res.data);
      console.log(res.data);
    });
  };

  const getAdminTradeMarkReceipt = () => {
    return AdminAPI.getAdminTradeMarkReceiptData(
      annualFeeData.trademark_idx,
      annualFeeData.annual_pay_idx
    ).then((res) => {
      setData(res.data);
      console.log(res.data);
    });
  };

  const getPatentReceipt = () => {
    return AnnualPay.getPatentReceiptData(
      annualFeeData.patent_idx,
      annualFeeData.annual_pay_idx
    ).then((res) => {
      setData(res.data);
      console.log(res.data);
    });
  };

  const getTradeMarkReceipt = () => {
    return AnnualPay.getTradeMarkReceiptData(
      annualFeeData.trademark_idx,
      annualFeeData.annual_pay_idx
    ).then((res) => {
      setData(res.data);
      console.log(res.data);
    });
  };

  const downloadFile = (file_idx) => {
    window.$Global
      .fileDownloadSync(
        isAdmin
          ? showPatent
            ? `/admin/company/${annualFeeData.patent_idx}/patent/annualPay/${annualFeeData.annual_pay_idx}/billing/file/${file_idx}/download`
            : `/admin/company/${annualFeeData.trademark_idx}/trademark/annualPay/${annualFeeData.annual_pay_idx}/billing/file/${file_idx}/download`
          : showPatent
          ? `/manager/patent/${annualFeeData.patent_idx}/annualPay/${annualFeeData.annual_pay_idx}/billing/file/${file_idx}/download`
          : `/manager/trademark/${annualFeeData.trademark_idx}/annualPay/${annualFeeData.annual_pay_idx}/billing/file/${file_idx}/download`
      )
      .then((res) => console.log(res.data));
  };

  const download = () => {
    hideRef.current.style.visibility = "hidden";
    hideRefTwo.current.style.visibility = "hidden";
    hideRefThree.current.style.visibility = "hidden";
    const divToDisplay = componentRef.current;
    html2canvas(divToDisplay).then(function (canvas) {
      const divImage = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        unit: "px",
        format: [550, 850],
      });
      pdf.addImage(divImage, "PNG", 0, 0);
      pdf.save("청구서.pdf");
      hideRef.current.style.visibility = "visible";
      hideRefTwo.current.style.visibility = "visible";
      hideRefThree.current.style.visibility = "visible";
    });
  };

  return (
    <>
      <div id="AnnualFeeReceipt" ref={componentRef}>
        <div className="title">
          <h2>연차료 비용 청구서</h2>
        </div>
        <div className="title" ref={hideRef}>
          <i
            className="icon_exit_gray"
            onClick={() =>
              setShowPopup({
                receipt: false,
              })
            }
          />
        </div>
        <div className="company_info">
          <p>주식회사 아이티엘</p>
          <p>
            (06744) 서울특별시 서초구 바우뫼로 175, 5F
            <br />
            (양재동, 창덕빌딩)
          </p>
        </div>
        <div className="annual_fee_receipt_company">
          <div>
            <p>수&emsp;신</p>
            <p>{data.company_name}</p>
          </div>
          <div>
            <p>청구 일자</p>
            <p>{window.$Global.convertDate(data.created_at) || 0}</p>
          </div>
          <div>
            <p>청구번호</p>
            <p>{data.billing_number}</p>
          </div>
          <div>
            <p>아이티엘 관리번호</p>
            <p>{data.manage_number}</p>
          </div>
          <div>
            <p>대리인 관리번호</p>
            <p>{data.agent_manage_number}</p>
          </div>
          <div>
            <p>출원 번호</p>
            <p>{data.apply_number}</p>
          </div>
          <div>
            <p>발명의 명칭</p>
            <p>{data.invention_name}</p>
          </div>
          <div>
            <p>청구 내용</p>
            <p>{data.type === 0 ? "연차료" : null}</p>
          </div>
          <div>
            <p>발명자</p>
            <p>{data.researcher}</p>
          </div>
        </div>
        <div className="annual_fee_receipt_table">
          <table>
            <colgroup>
              <col width="10%" />
              <col width="30%" />
              <col width="10%" />
              <col width="30%" />
            </colgroup>
            <thead>
              <tr>
                <th>청구내역</th>
                <th>청구 금액</th>
                <th>청구내역</th>
                <th>청구 금액</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>연차료 납부</td>
                <td>
                  {window.$Global.numberWithCommas(
                    data?.annual_pay_amount || 0
                  )}
                </td>
                <td>대리인 수수료</td>
                <td>{window.$Global.numberWithCommas(data?.agent_fee || 0)}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>부가세</td>
                <td>{window.$Global.numberWithCommas(data?.surtax || 0)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="annual_fee_receipt_cost">
          <div>
            <p>
              합&emsp;계 <span>단위 (KRW)</span>
            </p>
          </div>
          <p>{window.$Global.numberWithCommas(data?.total_amount || 0)}</p>
        </div>
        <div className="annual_fee_receipt_file" ref={hideRefTwo}>
          <div>첨부 파일</div>
          <div>
            {data?.file?.map((el) => {
              return (
                <p
                  onClick={() => downloadFile(el.idx)}
                  style={{ cursor: "pointer" }}
                >
                  <i className="icon_download_attached" />
                  {el.file_name}.{el.file_type}
                </p>
              );
            })}
          </div>
        </div>
        <p className="annual_fee_receipt_account">
          계좌번호 : 하나은행 {data.account_number} (예금주 :{" "}
          {data.account_name})
        </p>
        <div className="annual_fee_receipt_print" ref={hideRefThree}>
          <button onClick={handlePrint}>
            <i className="icon_print" />
            프린트 하기
          </button>
          <button onClick={download}>
            <i className="icon_download_attached" />
            청구서 PDF 다운로드
          </button>
        </div>
      </div>
    </>
  );
};

export default AnnualFeeReceipt;
