import "../css/ChangeFavorite.scss";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import CommonAPI from "../../../API/common";
import { favoriteIcon } from "../../../const/favoriteIcon";
import { allFavoriteArray, myFavoriteArray } from "../../../atom";
import { useRecoilState, useRecoilValue } from "recoil";

const ChangeFavorite = ({ setShowModal, off }) => {
  const allFavoriteList = useRecoilValue(allFavoriteArray);
  const [myFavoriteList, setMyFavoriteList] = useRecoilState(myFavoriteArray);
  const [favoriteList, setFavoriteList] = useState([]);

  useEffect(() => {
    let sortable = [];
    for (let item in allFavoriteList) {
      if (allFavoriteList[item] !== "사업계획서 작성") {
        sortable.push({
          name: allFavoriteList[item],
          number: item,
          icon: favoriteIcon[item].src,
        });
      }
    }
    setFavoriteList(sortable);
    initMyFavList();
  }, []);

  const initMyFavList = () => {
    CommonAPI.getMyFavoriteList().then((res) => {
      const data = res.data.favoriteList;
      let arr = [];
      data.map((item) => {
        arr.push({
          idx: item.idx,
          name: allFavoriteList[item.work_idx],
          number: item.work_idx,
          icon: favoriteIcon[item.work_idx].src,
          pathname: favoriteIcon[item.work_idx].pathname,
        });
      });
      setMyFavoriteList(arr);
    });
  };

  const deleteFavorite = (item) => {
    CommonAPI.deleteMyFavoriteList(item.idx).then(() => {
      alert("삭제되었습니다.");
      initMyFavList();
    });
  };

  const addFavorite = (obj) => {
    // if (myFavoriteList.length >= 8) {
    //   alert("즐겨찾기는 최대 8개까지 등록 가능합니다.");
    // } else {
    //   CommonAPI.addMyFavoriteList({ work_idx: obj.number }).then(() =>
    //     initMyFavList()
    //   );
    // }
    CommonAPI.addMyFavoriteList({ work_idx: obj.number }).then(() =>
      initMyFavList()
    );
  };

  return (
    <div id="ChangeFavorite">
      <header>
        <h2>즐겨찾기 편집하기</h2>
        <i className="icon_del_big_gray" onClick={() => setShowModal(false)} />
      </header>
      <div className="my_favorite custom_scroll">
        <h5>선택 된 즐겨찾기 서비스</h5>

        <div
          className="favoriteList"
          // style={{ width: "879px", height: "150px" }}
        >
          {myFavoriteList.map((item, idx) => {
            return (
              <div>
                <i className={item.icon} />
                <p>{item.name}</p>
                <i
                  className="icon_checkbox_blue"
                  onClick={() => deleteFavorite(item)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="choice_favorite">
        <h5>즐겨찾기에 들어갈 서비스를 선택 해 주세요.</h5>
        <div className="choice_favorite_list custom_scroll">
          <ul>
            {favoriteList.map((item) => {
              return (
                <li onClick={() => addFavorite(item)}>
                  <i className={item.icon} />
                  <p>{item.name}</p>
                  <i className="icon_big_blue_add" />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ChangeFavorite;
