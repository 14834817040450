import GroupwareSideBar from "./GroupwareSideBar";
import { groupWareOfficeRoom } from "./groupwarePath";
import IframeResizer from "iframe-resizer-react";
import React, { useEffect, useState } from "react";
import config from "../../const/config";
import { useLocation } from "react-router-dom";
import qs from "query-string";
import { param } from "express/lib/router";

const getAppPath = (section, command, params) => {
  var src = config.gwUrl + "/";
  let strParam = "";
  if (section == "command") {
    src += "command.information.do";
    if (params.get("category") == "chat") {
      strParam +=
        "&method=" +
        params.get("method") +
        "&category=" +
        params.get("category");
    }
    strParam += "&method=" + params.get("method") + "&idx=" + params.get("idx");
  } else if (section == "emp") {
    src += "emp.auth.do";
    strParam += "&name" + params.get("name");
  }
  src += "?command=" + command + strParam;
  return src;
};

const GroupwareOfficeRoom = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { name } = qs.parse(location.search);
  const [currentMsg, setCurrentMsg] = useState("");
  const dummy = params.get("dummy");

  var isSection = !params.get("section") ? "doc" : params.get("section");
  var isCommand = !params.get("command") ? "writeForm" : params.get("command");
  var isApp = getAppPath(isSection, isCommand, params) + "&siteFlag=biznavi";

  window.addEventListener(
    "message",
    function (event) {
      if (event.data.msg === "messengerLayoutChange") {
        // 메신저 뷰페이지
        setCurrentMsg(event.data.msg);
      } else if (event.data.msg === "messengerLayoutOrigin") {
        // 메신저 뷰페이지 아닌 페이지
        setCurrentMsg(event.data.msg);
      }
    },
    false
  );

  useEffect(() => {
    if (currentMsg === "messengerLayoutChange") {
      document.querySelector("#GroupwareWorks .commute_wrapper").style.padding =
        "0";
    } else if (currentMsg === "messengerLayoutOrigin") {
      document.querySelector("#GroupwareWorks .commute_wrapper").style.padding =
        "30px";
    }
  }, [currentMsg]);

  return (
    <div id="GroupwareWorks">
      <GroupwareSideBar currentPath={groupWareOfficeRoom} />
      <div className="commute_wrapper">
        <IframeResizer
          id="contentWrap"
          src={`${isApp}${name ? `&name=${name}` : ""}&dummy=${dummy}`}
          //src={`${config.gwUrl}/emp.auth.do?command=lstEmp&siteFlag=biznavi${name ? `&name=${name}` : ""}`}
          style={{ minWidth: "100%", minHeight: "100vh" }}
          heightCalculationMethod="bodyOffset"
          autoResize={true}
        />
      </div>
    </div>
  );
};

export default GroupwareOfficeRoom;
