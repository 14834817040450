import React from "react";
import UserAuthRoute from "./common/UserAuthRoute";
import CustomSwitch from "./common/CustomSwitch";
import ResearchTechCategoryList from "../components/researchTech/ResearchTechCategoryList";
import ResearchTechMySideBar from "../components/researchTech/myPage/ResearchTechMySideBar";
import ResearchTechEdit from "../components/researchTech/edit/ResearchTechEdit";
import ResearchTechMain from "../components/researchTech/ResearchTechMain";
import ResearchTechMyRequest from "../components/researchTech/myPage/ResearchTechMyRequest";
import ResearchTechPatentList from "../components/researchTech/ResearchTechPatentList";
import ResearchTechInfoText from "../components/researchTech/ResearchTechInfoText";
import ResearchTechSiteMap from "../components/researchTech/ResearchTechSiteMap";
import ResearchTechHeader from "../components/researchTech/ResearchTechHeader";
import ResearchTechIntro from "../components/researchTech/ResearchTechIntro";
import ResearchTechMyKeyword from "../components/researchTech/myPage/ResearchTechMyKeyword";
import ResearchTechMyList from "../components/researchTech/myPage/ResearchTechMyList";
import ResearchTechPatentInfo from "../components/researchTech/ResearchTechPatentInfo";
import ResearchTechPatentInfoIpnow from "../components/researchTech/ResearchTechPatentInfoIpnow";

function ResearchTechRoute({ match }) {
  return (
    <>
      <UserAuthRoute
        exact
        path={`${match.path}/intro`}
        requireAuth={false}
        component={ResearchTechIntro}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/category`}
        requireAuth={false}
        component={ResearchTechCategoryList}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/my/keyword`}
        requireAuth={true}
        component={ResearchTechMyKeyword}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/my/list`}
        requireAuth={true}
        component={ResearchTechMyList}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/my/request`}
        requireAuth={true}
        component={ResearchTechMyRequest}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/edit`}
        requireAuth={true}
        component={ResearchTechEdit}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/patent/:apply_number/detail`}
        requireAuth={true}
        component={ResearchTechPatentInfo}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/list`}
        requireAuth={true}
        component={ResearchTechMain}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/patent`}
        requireAuth={true}
        component={ResearchTechPatentList}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/requestEdit`}
        requireAuth={true}
        component={ResearchTechMyRequest}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/ipnow/patent/:apply_number/detail`}
        requireAuth={false}
        component={ResearchTechPatentInfoIpnow}
      />
    </>
  );
}

export default ResearchTechRoute;
