import React, { useRef, useState } from "react";
import "./css/Common.scss";
import { Link } from "react-router-dom";
import Validator from "../../util/validator";
import MemberAPI from "../../API/member";
import emoji from "../../assets/images/intro/icon-smile.png";

function FindPw() {
  const [findSuccess, setFindSuccess] = useState(false);
  let idRef = useRef();
  let emailRef = useRef();
  let idMRef = useRef();
  let emailMRef = useRef();

  const onClickFind = (client) => {
    let refs = [];
    let payload = {};

    if (client === "mo") {
      refs = [idMRef, emailMRef];
      payload = {
        id: idMRef.current.value,
        email: emailMRef.current.value,
      };
    } else {
      refs = [idRef, emailRef];
      payload = {
        id: idRef.current.value,
        email: emailRef.current.value,
      };
    }

    if (Validator.refValidator(refs)) {
      MemberAPI.findPw(payload).then((res) => setFindSuccess(res.data));
    }
  };

  return (
    <div id="Find">
      <div className="pc">
        <div className="form">
          {findSuccess ? (
            <div className="show_find_txt">
              <h2>
                가입 시 입력한 이메일로
                <br />
                임시 비밀번호를 발송하였습니다.
              </h2>
              <p>{emailRef.current.value}</p>
              <Link to="/login" className="btn_login btn">
                로그인 하러가기
              </Link>
            </div>
          ) : (
            <div className="find_format">
              <h2>
                <img src={emoji} />
                비밀번호 찾기
              </h2>
              <h5>
                비밀번호를 찾고자하는
                <br />
                아이디과 이메일을 입력해주세요
              </h5>
              <div className="input_area">
                <label>아이디</label>
                <input
                  type="text"
                  data-name="아이디"
                  ref={idRef}
                  placeholder="아이디를 입력하세요"
                  onKeyUp={(e) => {
                    if (e.key === "Enter") onClickFind();
                  }}
                />
              </div>
              <div className="input_area">
                <label>이메일</label>
                <input
                  type="text"
                  data-name="이메일"
                  ref={emailRef}
                  placeholder="이메일을 입력하세요"
                  onKeyUp={(e) => {
                    if (e.key === "Enter") onClickFind();
                  }}
                />
              </div>
              <button className="btn_find btn" onClick={onClickFind}>
                비밀번호 찾기
              </button>
              <div className="account_search">
                아이디를 잊으셨나요?&emsp;
                <Link to="/find/id">
                  아이디 찾기 <i className="icon_arrow_right_s" />
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="mo">
        <div className="form">
          {findSuccess ? (
            <div className="show_find_txt">
              <h2>
                가입 시 입력한 이메일로
                <br />
                임시 비밀번호를 발송하였습니다.
              </h2>
              <p>{emailMRef.current.value}</p>
              <Link to="/login" className="btn_login btn">
                로그인 하러가기
              </Link>
            </div>
          ) : (
            <div className="find_format">
              <h2>비밀번호 찾기</h2>
              <div className="input_area">
                <p>아이디</p>
                <input
                  type="text"
                  data-name="아이디"
                  ref={idMRef}
                  placeholder="아이디를 입력하세요"
                  onKeyUp={(e) => {
                    if (e.key === "Enter") onClickFind("mo");
                  }}
                />
              </div>
              <div className="input_area">
                <p>이메일</p>
                <input
                  type="text"
                  data-name="이메일"
                  ref={emailMRef}
                  placeholder="이메일을 입력하세요"
                  onKeyUp={(e) => {
                    if (e.key === "Enter") onClickFind("mo");
                  }}
                />
              </div>
              <button
                className="btn_find btn"
                onClick={() => onClickFind("mo")}
              >
                비밀번호 찾기
              </button>
              <div className="account_search">
                <Link to="/find/id">아이디를 잊으셨나요?</Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default FindPw;
