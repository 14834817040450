import React, { useRef, useState } from "react";
import Validator from "../../util/validator";
import MemberAPI from "../../API/member";
import { Link } from "react-router-dom";
import "./css/Common.scss";
import emoji from "../../assets/images/intro/icon-smile.png";

const RenewalFindId = () => {
  const [findId, setFindId] = useState("");
  const [type, setType] = useState(0);
  let emailRef = useRef();
  let emailMRef = useRef();

  const onClickFind = (client) => {
    let refs = [];
    let payload = {};

    if (client === "mo") {
      refs = [emailMRef];
      payload = {
        type: Number(type),
        email: emailMRef.current.value,
      };
    } else {
      refs = [emailRef];
      payload = {
        type: Number(type),
        email: emailRef.current.value,
      };
    }
    if (Validator.refValidator(refs)) {
      MemberAPI.findId(payload).then((res) => setFindId(res.data));
    }
  };

  return (
    <div id="Find">
      <div className="pc">
        <div className="form">
          {Boolean(findId) ? (
            <div className="find_format">
              <h2>
                <img src={emoji} />
                아이디 찾기
              </h2>
              <h5>아이디를 알려드립니다.</h5>
              <p>
                <i className="icon_check_blue" />
                {findId}
              </p>
              <Link to="/login" className="btn_login btn">
                로그인 하러가기
              </Link>
            </div>
          ) : (
            <div className="find_format">
              <h2>
                <img src={emoji} />
                아이디 찾기
              </h2>
              <h5>
                아이디를 찾고자하는
                <br />
                이름과 이메일을 입력해주세요
              </h5>
              <div className="input_area">
                <label>이름</label>
                <input
                  type="text"
                  data-name="이메일"
                  ref={emailRef}
                  placeholder="입력하세요"
                  onKeyUp={(e) => {
                    if (e.key === "Enter") onClickFind();
                  }}
                />
              </div>
              <div className="input_area">
                <label>이메일</label>
                <input
                  type="text"
                  data-name="이메일"
                  ref={emailRef}
                  placeholder="입력하세요"
                  onKeyUp={(e) => {
                    if (e.key === "Enter") onClickFind();
                  }}
                />
              </div>
              <button className="btn_find btn" onClick={onClickFind}>
                아이디 찾기
              </button>
              <div className="account_search">
                비밀번호를 잊으셨나요?&emsp;
                <Link to="/find/pw">
                  비밀번호 찾기 <i className="icon_arrow_right_s" />
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="mo">
        <div className="form">
          {Boolean(findId) ? (
            <div className="show_find_txt">
              <h2>아이디를 알려드립니다.</h2>
              <p>{findId}</p>
              <Link to="/login" className="btn btn_login btn">
                로그인 하러가기
              </Link>
            </div>
          ) : (
            <>
              <h2>아이디 찾기</h2>
              <div className="input_area">
                <p>가입유형</p>
                <select
                  defaultValue={0}
                  onChange={(e) => setType(e.target.value)}
                >
                  <option value={0}>기업·대학·연구소</option>
                  <option value={1}>특허 사무소</option>
                  <option value={2}>투자자</option>
                </select>
              </div>
              <div className="input_area">
                <p>이메일</p>
                <input
                  type="text"
                  data-name="이메일"
                  ref={emailMRef}
                  placeholder="이메일을 입력하세요"
                  onKeyUp={(e) => {
                    if (e.key === "Enter") onClickFind("mo");
                  }}
                />
              </div>
              <button
                className="btn_find btn"
                onClick={() => onClickFind("mo")}
              >
                아이디 찾기
              </button>
              <div className="account_search">
                <Link to="/find/pw">비밀번호를 잊으셨나요?</Link>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RenewalFindId;
