import React, { useContext, useEffect, useState } from "react";
import "./css/MarketInfoMain.scss";
import CommonAPI from "../../API/common";
import MarketInfoPopupDetail from "./MarketInfoPopupDetail";
import { Link, useHistory } from "react-router-dom";
import qs from "query-string";
import MarketInfoMainCart from "./MarketInfoMainCart";
import memberContext from "../../store/Member";
import convertCategory from "./js/convertCategory";
import MarketInfoMainItem from "./MarketInfoMainItem";
import config from "../../const/config";
import CommonContext from "../../store/Common";
import message from "../../const/message";
import Pagination from "../common/Pagination";
import MarketInfoCategory from "./MarketInfoCategory";
import MarketInfoSideBar from "./MarketInfoSideBar";
import marketInfoCartItem from "./MarketInfoCartItem";
import { marketInfoCartArrayLength } from "../../atom";
import { useRecoilState } from "recoil";

function MarketInfoMain({ history, location }) {
  const { isMobile } = useContext(CommonContext);

  const memberInfo = useContext(memberContext);

  const isLogin = Boolean(memberInfo.idx);
  const isAdmin = memberInfo.admin; // 슈퍼 계정인지 판별
  const mi_cart_max_cnt = memberInfo.mi_cart_max_cnt || 0;
  const { category_idx1, category_idx2 } = qs.parse(location.search);
  const [category, setCategory] = useState([]);
  const [selectIdx, setSelectIdx] = useState(null);
  const [marketInfoList, setMarketInfoList] = useState({ items: [] });
  const [keyword, setKeyword] = useState("");
  const [cart, setCart] = useState([]);
  const [cartArrLength, setCartArrLength] = useRecoilState(
    marketInfoCartArrayLength
  );
  const [smallCategory, setSmallCategory] = useState("");
  const [popupType, setPopupType] = useState("graph"); // graph, content
  const [popupInfo, setPopupInfo] = useState({});
  const [popupShow, setPopupShow] = useState(false);
  const [moPopupCartShow, setMoPopupCartShow] = useState(isMobile);

  const [chk, setChk] = useState(false);
  const [curPage, setCurPage] = useState(1);
  const [last, setLast] = useState(1);
  const [datalist, setDatalist] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (!category_idx1 || !category_idx2) {
      alert("비정상적인 카테고리입니다");
      history.push("category");
      return;
    }

    CommonAPI.getMarketCategory().then((res) => {
      let data = res.data;
      let small_category_list = data.small_category_list;
      let category3_idx = small_category_list[0]?.idx;
      setCategory(data);
      setSelectIdx(category3_idx);
      getList(category3_idx);
    });

    if (isLogin) {
      getCartList();
    }
    setChk(true);
  }, []);

  useEffect(() => {
    if (chk) {
      getList(selectIdx);
    }
  }, [curPage]);

  useEffect(() => {
    if (curPage !== 1) {
      setCurPage(1);
    } else {
      if (chk) {
        getList(selectIdx);
      }
    }
  }, [category_idx1, category_idx2]);

  const getList = (category3_idx) => {
    let params = {
      page: curPage,
      count: 20,
      keyword: keyword,
      category1_idx: category_idx1,
      category2_idx: category_idx2,
      category3_idx: category3_idx || selectIdx,
    };
    CommonAPI.getMarketList(params).then((res) => {
      setMarketInfoList(res.data);
      setLast(res.data.last);
      setDatalist(res.data.items);
    });
  };

  const getCartList = () => {
    CommonAPI.getMarketCartList().then((res) => {
      let data = res.data;
      CommonAPI.getMarketImageList().then((res2) => {
        res2.data.map((item) => {
          item.type = 1;
        });
        let copy = data.concat(res2.data);
        copy.sort((a, b) => b.created_at - a.created_at);
        setCart(copy);
        setCartArrLength(copy.length);
      });
    });
  };

  const changeCategory = (category3_idx) => {
    setSelectIdx(category3_idx);
    setDatalist([]);
    setPage(1);
    setCurPage(1);
    setLast(1);
    getList(category3_idx);
  };

  const onClickShowPopup = (item, type) => {
    let isGraph = type == "graph";
    let isContent = type == "content";
    let hasContent = Boolean(item.content || item.content_image_list.length);

    if (isMobile) {
      let imageWin = window.open("", "_blank");
      imageWin.document.write(
        `<html><body style='margin:0; text-align: center;'><style type="text/css">img {max-width: 1100px;}</style>`
      );

      if (isGraph) {
        imageWin.document.write(
          `<img src="${window.$Global.getCDN(
            item.image_key
          )}" alt="그래프 이미지"/>`
        );
      } else if (isContent) {
        if (hasContent) {
          if (item.content) {
            imageWin.document.write(`<p>${item.content}</p>`);
          } else {
            imageWin.document.write(
              `<img src="${window.$Global.getCDN(
                item.content_image_list[0].content_key
              )}" alt="그래프 설명 이미지"/>`
            );
          }
        }
      }
      console.log(item);
      if (item.mi_info?.gpt_summary) {
        imageWin.document.write(`<p>${item.mi_info?.gpt_summary}</p>`);
      }

      imageWin.document.write("</body><html>");
    } else {
      setPopupShow(true);
      setPopupInfo(item);
      setPopupType(type);
    }
  };

  const getCategory = () => {
    let result = convertCategory(category, category_idx1, category_idx2);
    return result.b_category + " > " + result.s_category;
  };

  const onChangeFile = (files) => {
    if (!config.imgTypeCheck.test(files.name)) {
      alert("이미지 파일만 업로드할 수 있습니다");
      return;
    }

    let formData = new FormData();
    formData.append("image", files);

    CommonAPI.addMarketImage(formData).then(() => {
      getCartList();
    });
  };

  const onClickImageDelete = (idx) => {
    CommonAPI.deleteMarketImage(idx).then(() => getCartList());
  };

  const onClickCheck = async (e, obj) => {
    if (e.target.checked) {
      if (cart.length == mi_cart_max_cnt) {
        if (isLogin) {
          alert(`최대 ${mi_cart_max_cnt}개까지만 담을 수 있습니다`);
        } else {
          alert(message.requireLogin);
          history.push("/login");
        }
        return;
      }
      await CommonAPI.addMarketCart(obj.idx).then(() => {
        alert("장바구니에 추가되었습니다.");
      });
    } else {
      if (obj.type === 1) {
        let cart_idx = cart.find(
          (item) => (item.mi_idx || item.idx) == (obj.mi_idx || obj.idx)
        ).idx;
        await CommonAPI.deleteMarketImage(cart_idx);
      } else {
        let cart_idx = cart.find(
          (item) => (item.mi_idx || item.idx) == (obj.mi_idx || obj.idx)
        ).idx;
        await CommonAPI.removeMarketCart(cart_idx);
      }
    }
    getCartList();
  };

  const onClickMoPopupCartShow = () => {
    setMoPopupCartShow(!moPopupCartShow);
  };

  const downloadReport = () => {
    let result = convertCategory(category, category_idx1, category_idx2);
    CommonAPI.downloadMarketReport({ keyword: result.s_category });
  };
  return (
    <div id="MarketInfoMain" className={moPopupCartShow ? "" : "show"}>
      <MarketInfoSideBar />
      <div className="marketInfoMain">
        <div className="wrap_category">
          <div className="category">
            <p>{getCategory()}</p>
          </div>
          <div style={{ display: "flex" }}>
            <div className="keyword_find pc">
              <input
                type="text"
                placeholder="찾고 싶은 키워드를 입력하세요"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                onKeyUp={(e) => e.key === "Enter" && getList()}
              />
              <button
                className="icon_search_gray ir_txt"
                onClick={() => getList()}
              >
                검색
              </button>
            </div>
            <button
              className="btn_marketinfo_report"
              onClick={() => {
                isAdmin
                  ? downloadReport()
                  : alert("요금제 결제 후 사용가능한 기능입니다.");
              }}
            >
              <i className="icon_download_attached" />
              시장정보 보고서 다운로드
            </button>
          </div>
        </div>
        <div className="marketInfoMain_header">
          <div className="inner">
            <ul className="s_category">
              {category.small_category_list?.map((item) => (
                <li
                  key={item.idx}
                  className={selectIdx == item.idx ? "active" : ""}
                  onClick={() => changeCategory(item.idx)}
                >
                  {item.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="marketInfoMain_list">
          {marketInfoList.items.length ? (
            datalist.map((item) => (
              <MarketInfoMainItem
                key={item.idx}
                item={item}
                cartArr={cart}
                showPopup={onClickShowPopup}
                onClickCheck={onClickCheck}
              />
            ))
          ) : (
            <p>조회된 데이터가 없습니다</p>
          )}
        </div>
        <Pagination curPage={curPage} lastNum={last} onClick={setCurPage} />
      </div>
      <MarketInfoMainCart
        location={location}
        cartArr={cart}
        showPopup={onClickShowPopup}
        onClickImageDelete={onClickImageDelete}
        onChangeFile={onChangeFile}
        onClickCheck={onClickCheck}
        onClickMoPopupClose={onClickMoPopupCartShow}
      />
      <MarketInfoPopupDetail
        show={popupShow}
        type={popupType}
        item={popupInfo}
        onClose={() => setPopupShow(false)}
      />

      <div className="floating_cart mo" onClick={onClickMoPopupCartShow}>
        <i className="icon_posting_pencil" />
        <p>내가 담은 그래프</p>
      </div>
    </div>
  );
}

export default MarketInfoMain;
