import { useState, useEffect, useContext } from "react";
import _ from "lodash";
import memberContext from "../../store/Member";
import ProductAPI from "../../API/product";

const ProductList = ({
  productList = [],
  setPatentListByProduct,
  setSelectedProduct,
  getPatentListOfProduct,
  sort,
  setIsNeedGetProduct,
}) => {
  const { auth } = useContext(memberContext);
  const [response, setResponse] = useState(productList); // 초기 제품 리스트
  const [editIndex, setEditIndex] = useState(null); // 수정 모드 인덱스
  const [historyResponse, setHistoryResponse] = useState(null); // 수정 전 데이터를 저장
  const isAdminCompany = window.$Global.checkAuth("특허회사_관리자", auth);
  const isAdminAgent = window.$Global.checkAuth("대리인_관리자", auth);
  const isCompany = window.$Global.checkAuth("특허회사", auth);
  const isAgent = window.$Global.checkAuth("대리인", auth);
  const isInvestor = window.$Global.checkAuth("투자자", auth);
  const content = isCompany
    ? "제품명" //제품명/서비스명
    : isAgent
    ? "주요 분야명"
    : isInvestor && "관심 분야";

  // productList가 변경될 때마다 response 상태를 업데이트
  useEffect(() => {
    setResponse(productList);
  }, [productList]);

  const handleEditChange = (e, idx) => {
    let updatedResponse = _.cloneDeep(response);
    updatedResponse[idx].name = e.target.value;
    setResponse(updatedResponse);
  };

  // 수정 모드로 들어갈 때 기존 데이터를 백업
  const handleEdit = (idx) => {
    setHistoryResponse(_.cloneDeep(response[idx])); // 수정 전 데이터를 저장
    setEditIndex(idx); // 수정 모드로 전환
  };

  const handleSave = async (item, idx) => {
    try {
      // 권한에 따라 적절한 API 호출
      if (isCompany) {
        await ProductAPI.updateProduct(item.idx, item); // 특허회사 업데이트 API 호출
      } else if (isAgent) {
        await ProductAPI.updateProductAsAgent(item.idx, item); // 대리인 업데이트 API 호출
      } else if (isInvestor) {
        await ProductAPI.updateProductAsInvestor(item.idx, item); // 투자자 업데이트 API 호출
      }
      // 저장 후 수정 모드 해제
      setEditIndex(null);
      setIsNeedGetProduct(true);
    } catch (error) {
      console.error("제품 저장 중 오류 발생:", error);
    }
  };

  const handleCancel = () => {
    if (historyResponse) {
      let updatedResponse = _.cloneDeep(response);
      updatedResponse[editIndex] = historyResponse; // 수정 전의 값으로 되돌림
      setResponse(updatedResponse);
    }
    setEditIndex(null); // 수정 모드 해제
  };

  const handleDelete = async (p_idx, idx) => {
    // 삭제 확인 창 표시
    const confirmDelete = window.confirm(
      "삭제하시겠습니까?\n제품 삭제 시 적용한 특허 내역이 초기화 됩니다"
    );

    if (confirmDelete) {
      if (isCompany) {
        await ProductAPI.deleteProduct(p_idx);
      } else if (isAgent) {
        await ProductAPI.deleteProductAsAgent(p_idx);
      } else if (isInvestor) {
        await ProductAPI.deleteProductAsInvestor(p_idx);
      }

      // 제품 목록에서 삭제된 항목 제거
      const updatedResponse = response.filter((_, index) => index !== idx);
      setResponse(updatedResponse);
      setIsNeedGetProduct(true);
    }
  };

  const isEditing = (idx) => editIndex === idx;

  let tbody = response.map((item, idx) => (
    <tr key={idx}>
      <td width="10%">{idx + 1}</td>
      <td style={{ textAlign: "left" }}>
        {isEditing(idx) ? (
          <input
            type="text"
            value={item.name}
            onChange={(e) => handleEditChange(e, idx)}
          />
        ) : (
          <p
            style={{ cursor: "pointer" }}
            onClick={() => {
              getPatentListOfProduct(item.idx, sort);
            }}
          >
            {item.name}
          </p>
        )}
      </td>
      {isAdminCompany || isAdminAgent ? (
        <td width="20%">
          {isEditing(idx) ? (
            <>
              <button
                className="btn_save btn"
                onClick={() => {
                  handleSave(item, idx);
                }}
              >
                저장
              </button>
              <button className="btn_cancel btn" onClick={handleCancel}>
                취소
              </button>
            </>
          ) : (
            <>
              <button className="btn_edit btn" onClick={() => handleEdit(idx)}>
                수정
              </button>
              <button
                className="btn_del btn"
                onClick={() => {
                  handleDelete(item.idx, idx);
                }}
              >
                삭제
              </button>
            </>
          )}
        </td>
      ) : null}
    </tr>
  ));

  if (!tbody.length) {
    tbody = (
      <tr>
        <td colSpan="3">등록된 제품이 없습니다.</td>
      </tr>
    );
  }

  return (
    <div id="ProductList">
      <div className="list_box custom_scroll">
        <table>
          <thead>
            <tr>
              <th width="10%">순번</th>
              <th style={{ textAlign: "left" }}>{content}</th>
              {isAdminCompany || isAdminAgent ? (
                <th width="20%">변경</th>
              ) : null}
            </tr>
          </thead>
          <tbody>{tbody}</tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductList;
