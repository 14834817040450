import React from "react";

let state = React.createContext({
  idx: null,
  id: null,
  name: null,
  company_name: null,
  company_idx: null,
  company_code: null,
  manage_company_idx: null, // 대리인 계정일 경우 사용되는 변수 (관리 회사 idx)
  created_at: null,
  auth: false,
  email: "",
  auth_name: "",
  admin: false,
  code: null, // 회사 고유 코드
  company_type: null,
  simple: 0, // 1이면 간편 가입
  corporate_number: null,
  license_number: null,
});

export default state;
