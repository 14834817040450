import React, { useEffect, useLayoutEffect, useState } from "react";
import "./css/ResearchTechPatentList.scss";
import CommonAPI from "../../API/common";
import { Link, useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import convertCategory from "../marketInfo/js/convertCategory";
import iconDownload from "../../assets/images/common/icon/icon-download.svg";
import ResearchTechCategory from "./ResearchTechCategory";
import Pagination from "../common/Pagination";
import ResearchTechAddPatent from "./ResearchTechAddPatent";
import { useRecoilState } from "recoil";
import { applyNumberArray, categoryData } from "../../atom";

function ResearchTechPatentList() {
  const history = useHistory();
  const location = useLocation();
  const { idx, category_idx1, category_idx2, keyword } = qs.parse(
    location.search
  );
  const [researchInfo, setResearchInfo] = useState({});
  const [category, setCategory] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [last, setLast] = useState(1);
  const [showPopup, setShowPopup] = useState(false);
  const [addPatentData, setAddPatentData] = useState([]);
  const [deletePatentCheck, setDeletePatentCheck] = useState([]);
  const [deletePatentData, setDeletePatentData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [applyNumArr, setApplyNumArr] = useRecoilState(applyNumberArray);
  const [categoryObj, setCategoryObj] = useRecoilState(categoryData);
  const [count, setCount] = useState(10);

  useLayoutEffect(() => {
    CommonAPI.getMarketCategory().then((res) => setCategory(res.data));
  }, []);

  useEffect(() => {
    getList();
  }, [curPage, deletePatentData, count]);

  const getList = () => {
    let params = {
      page: curPage,
      count: count,
    };

    CommonAPI.getResearchInfo(idx, params).then((res) => {
      setCategoryObj({
        idx: res.data.idx,
        category1_idx: res.data.category1_idx,
        category1_name: res.data.category1_name,
        category2_idx: res.data.category2_idx,
        category2_name: res.data.category2_name,
        title: res.data.title,
        keyword: res.data.keyword,
      });

      res.data.data.patent_list.items.map((el) => {
        if (deletePatentData.includes(el.apply_number)) {
          el.isDeleted = true;
        } else {
          el.isDeleted = false;
        }

        if (deletePatentCheck.includes(el.apply_number)) {
          el.isChecked = true;
        } else {
          el.isChecked = false;
        }
      });
      setResearchInfo(res.data.data.patent_list);
      setTotalCount(res.data.data.patent_list.total_count);
    });
  };

  const sendData = () => {
    let arr = [];
    let params = {
      count: totalCount,
      page: 1,
    };
    CommonAPI.getResearchInfo(idx, params)
      .then((res) => {
        res.data.data.patent_list.items.map((el) => {
          console.log(el);
          arr.push(el.apply_number);
        });
        addPatentData.map((el) => {
          arr.push(el.apply_number);
        });
        arr = arr.filter((el) => {
          return !deletePatentData.includes(el);
        });
      })
      .then(() => {
        setApplyNumArr(arr);
        history.push(`edit?idx=${idx}`);
      });
  };

  const getCategory = () => {
    let result = convertCategory(category, category_idx1, category_idx2);
    return result.b_category + " > " + result.s_category;
  };

  const handleCountChange = (e) => {
    setCount(e.target.value);
    setCurPage(1);
  };

  const downloadExcel = async () => {
    let arr = [];
    let params = {
      count: totalCount,
      page: 1,
    };
    CommonAPI.getResearchInfo(idx, params).then((res) => {
      res.data.data.patent_list.items.map((el) => {
        arr.push(el.apply_number);
      });
      addPatentData.map((el) => {
        arr.push(el.apply_number);
      });
      arr = arr.filter((el) => {
        return !deletePatentData.includes(el);
      });
      window.$Global.newDownload("/common/patent/downloadKeywordExcel", {
        patentApplyNumberList: arr,
      });
    });
  };

  const handleChange = (e, item) => {
    item.isChecked = !item.isChecked;
    if (item.isChecked) {
      setDeletePatentCheck([...deletePatentCheck, item.apply_number]);
    } else {
      setDeletePatentCheck(
        deletePatentCheck.filter((el) => el !== item.apply_number)
      );
    }
    setResearchInfo({ ...researchInfo });
  };

  const deletePatent = () => {
    setDeletePatentData([...deletePatentCheck]);
    setCurPage(1);
    alert("삭제되었습니다.");
  };

  return (
    <div id="ResearchTechPatentList">
      <ResearchTechCategory />
      <div>
        <div className="wrap_category">
          <div className="category">
            <p>{getCategory()}</p>
          </div>
        </div>
        <div className="wrap_count">
          <p className="add_count">
            특허 건 수{" "}
            <span>{window.$Global.commaify(researchInfo.total_count)}</span> 건
          </p>
          <div className="button_link">
            <div className="download_button" onClick={() => downloadExcel()}>
              <img src={iconDownload} alt="download_image" />
              <span>Excel 다운받기</span>
            </div>
            <div className="link" onClick={() => history.go(-1)}>
              <span>목록으로 돌아가기</span>
              <i className="icon_more_arrow_right" />
            </div>
          </div>
        </div>
        <div className="wrap_edit_btn">
          <div>
            <button onClick={() => setShowPopup(true)}>특허 추가하기</button>
            <button onClick={() => deletePatent()}>특허 삭제하기</button>
          </div>
          <div>
            <select name="count" onChange={(e) => handleCountChange(e)}>
              <option value={10}>10건 씩 보기</option>
              <option value={20}>20건 씩 보기</option>
              <option value={50}>50건 씩 보기</option>
            </select>
          </div>
        </div>
        <div className="wrap_table">
          <table>
            <colgroup>
              <col width={30} />
              <col width={50} />
              <col width={50} />
              <col width={400} />
              <col width={130} />
              <col width={140} />
              <col width={50} />
              <col width={90} />
              <col width={60} />
            </colgroup>
            <thead>
              <tr>
                <th></th>
                <th>번호</th>
                <th>국가</th>
                <th style={{ textAlign: "left" }}>특허명</th>
                <th>출원인</th>
                <th>출원번호</th>
                <th style={{ textAlign: "left" }}>등급</th>
                <th>출원일</th>
                <th>내용보기</th>
              </tr>
            </thead>
            <tbody>
              {researchInfo?.items?.map((item, idx) => {
                return (
                  <tr style={{ opacity: item.isDeleted && "0.4" }}>
                    <td>
                      <input
                        type="checkbox"
                        onChange={(e) => handleChange(e, item)}
                        checked={item.isChecked}
                        disabled={item.isDeleted}
                      />
                    </td>
                    <td>{(curPage - 1) * 10 + idx + 1}</td>
                    <td>{item.nation}</td>
                    <td style={{ textAlign: "left" }}>{item.invention_name}</td>
                    <td>{item.applicant}</td>
                    <td>{item.apply_number}</td>
                    <td>
                      <div
                        className={`circle grade_${
                          item.now_grade || "default"
                        }`}
                      >
                        {item.now_grade || "N"}
                      </div>
                    </td>
                    <td>{`${item.apply_at?.substr(
                      0,
                      4
                    )}-${item.apply_at?.substr(4, 2)}-${item.apply_at?.substr(
                      6,
                      2
                    )}`}</td>
                    <td>
                      <i className="icon_download_attached" />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Pagination
          curPage={curPage}
          lastNum={researchInfo.last}
          onClick={setCurPage}
        />
        {addPatentData.length > 0 && (
          <>
            <p className="add_count">
              추가한 특허 개수 <span>{addPatentData.length}</span> 건
            </p>
            <table>
              <colgroup>
                <col width={50} />
                <col width={50} />
                <col width={400} />
                <col width={130} />
                <col width={140} />
                <col width={50} />
                <col width={100} />
                <col width={60} />
              </colgroup>
              <thead>
                <tr>
                  <th>번호</th>
                  <th>국가</th>
                  <th style={{ textAlign: "left" }}>특허명</th>
                  <th>출원인</th>
                  <th>출원번호</th>
                  <th>등급</th>
                  <th>출원일</th>
                  <th>내용보기</th>
                </tr>
              </thead>
              <tbody>
                {addPatentData?.map((item, idx) => {
                  return (
                    <tr>
                      <td>{(curPage - 1) * 10 + idx + 1}</td>
                      <td>{item.nation}</td>
                      <td style={{ textAlign: "left" }}>
                        {item.invention_name}
                      </td>
                      <td>{item.applicant}</td>
                      <td>{item.apply_number}</td>
                      <td>
                        <div
                          className={`circle grade_${
                            item.now_grade || "default"
                          }`}
                        >
                          {item.now_grade || "N"}
                        </div>
                      </td>
                      <td>{`${item.apply_at?.substr(
                        0,
                        4
                      )}-${item.apply_at?.substr(4, 2)}-${item.apply_at?.substr(
                        6,
                        2
                      )}`}</td>
                      <td>
                        <i className="icon_download_attached" />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
        <div className="btns">
          <button className="btn_prev" onClick={() => history.go(-1)}>
            이전
          </button>
          <button className="btn_next" onClick={() => sendData()}>
            보고서 작성하기
          </button>
        </div>
      </div>
      {showPopup && (
        <ResearchTechAddPatent
          onChangeShow={setShowPopup}
          list={addPatentData}
          setList={setAddPatentData}
          setCurPage={setCurPage}
        />
      )}
    </div>
  );
}

export default ResearchTechPatentList;
