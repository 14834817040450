import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import CommonFunc from "./common";
import Path from "./const/path";
import { useLocation } from "react-router-dom";
import memberContext from "../../store/Member";

function IntroMenu({ headHide, activeTab, auth, location, isExceptionURL }) {
  const isViewTypeIpnow = window.$Global.isIpnow(location);
  const isLogin = Boolean(useContext(memberContext).idx);
  const isGroupWare = location.pathname.includes("renewal");
  const redirect = () => {
    window.$Global.redirectHome(auth);
  };

  const onClickChangeView = () => {
    window.$Global.toggleViewType();
  };
  //const location = useLocation();
  //console.log(window.location.host);
  return (
    <div id="IntroMenu">
      {!headHide && !isExceptionURL && (
        <div className="intro_link">
          <div className="intro_menu_left">
            {isViewTypeIpnow ? (
              <img
                src={
                  require("../../assets/images/common/logo_ipnow.svg").default
                }
                style={{ width: 120 }}
                onClick={redirect}
                alt="이미지"
              />
            ) : (
              <img
                src={
                  require("../../assets/images/common/logo_findBiz.svg").default
                }
                style={{ width: 160 }}
                onClick={redirect}
                alt="이미지"
              />
            )}
            <div
              className={
                isLogin ? "tab_list_wrapper_login" : "tab_list_wrapper pc"
              }
            >
              <ul
                className="tab_list"
                id={!isViewTypeIpnow && "Intro_tab_list"}
              >
                {!isGroupWare &&
                  CommonFunc.DrawTab(
                    isViewTypeIpnow
                      ? isLogin
                        ? Path.match_ip_service_login
                        : Path.match_ip_service
                      : isLogin
                      ? Path.match_intro_login
                      : Path.match_intro,
                    activeTab,
                    "bold",
                    isLogin
                  )}
              </ul>
            </div>
          </div>
          {!auth && (
            <div className="sign">
              {/*{!isLogin && (*/}
              {/*  <div className="tab_list_service">*/}
              {/*    /!*<Link to="/intro/main">서비스 소개</Link>*!/*/}
              {/*    /!*<Link to="/intro/price">이용요금</Link>*!/*/}
              {/*    <Link to="/intro/service">Q&A</Link>*/}
              {/*    /!*<h2>|</h2>*!/*/}
              {/*    /!*{isViewTypeIpnow ? (*!/*/}
              {/*    /!*  <button*!/*/}
              {/*    /!*    className="btn_change btn_findbiz"*!/*/}
              {/*    /!*    onClick={onClickChangeView}*!/*/}
              {/*    /!*  >*!/*/}
              {/*    /!*    <p>*!/*/}
              {/*    /!*      <span>비즈내비</span>&nbsp;바로가기&nbsp;&gt;*!/*/}
              {/*    /!*    </p>*!/*/}
              {/*    /!*  </button>*!/*/}
              {/*    /!*) : (*!/*/}
              {/*    /!*  <div className="btn_change btn_ipnow" onClick={onClickChangeView}>*!/*/}
              {/*    /!*    <p>*!/*/}
              {/*    /!*      <span>아이피나우</span>&nbsp;바로가기&nbsp;&gt;*!/*/}
              {/*    /!*    </p>*!/*/}
              {/*    /!*  </div>*!/*/}
              {/*    /!*)}*!/*/}
              {/*  </div>*/}
              {/*)}*/}
              {/* <Link className="btn_login" to="/login">
                로그인
              </Link> */}
              <Link className="btn_login" to="/login">
                로그인
              </Link>
              <Link className="btn_signup" to="/signup/type">
                무료 회원가입
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default IntroMenu;
