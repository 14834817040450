const CalculatorResult = ({ data }) => {
  const { type, govSupportAmount, govSupportPercent, selfFundingPercent } =
    data;
  const categoryBg = type === "original" ? "#f9f9f9" : "#eaf7ff";
  // 총 사업비
  const totalAmount =
    type === "original"
      ? (govSupportAmount / govSupportPercent) * 100
      : Math.ceil(((govSupportAmount / govSupportPercent) * 100) / 1000000) *
        1000000; // 100만 원 절상

  // 자기부담사업비(현물) 계산 (보정값 우선 처리)
  const inKindAmount =
    type === "original"
      ? totalAmount -
        govSupportAmount -
        (totalAmount * selfFundingPercent) / 100
      : Math.round(
          totalAmount * (1 - govSupportPercent / 100 - selfFundingPercent / 100)
        );

  // 자기부담사업비(현금) 계산
  const cashAmount =
    type === "original"
      ? totalAmount * (selfFundingPercent / 100)
      : Math.round((totalAmount - govSupportAmount - inKindAmount) * 100) / 100;

  // 총 자기부담 사업비
  const selfFundingAmount = totalAmount - govSupportAmount;

  // 포맷 함수
  const formatNumberWithCommas = (value) => {
    if (!value) return "";

    // 숫자로 변환 후 소수점 제거
    const numericValue = Math.floor(parseFloat(value));

    // 콤마 추가
    return window.$Global.numberWithCommas(numericValue);
  };

  return (
    <section className="result-section">
      <div class="section-header">
        <p>{type === "original" ? "최적 비율" : "최적 금액"}</p>
      </div>
      <div className="flex-container">
        {/* 왼쪽 열 */}
        <aside
          className="left-column"
          style={{ backgroundColor: `${categoryBg}` }}
        >
          <h2 className="result-text">사업비 구성 계획</h2>
        </aside>

        {/* 중간 열 */}
        <div className="middle-column">
          <div
            className="wide-row1"
            style={{ backgroundColor: `${categoryBg}` }}
          >
            <p>정부지원사업비</p>
          </div>
          <div className="nested-container">
            <section
              className="large-box"
              style={{ backgroundColor: `${categoryBg}` }}
            >
              <h3 className="result-text">자기부담사업비</h3>
            </section>
            <section className="small-box-container">
              <div
                className="small-box"
                style={{ backgroundColor: `${categoryBg}` }}
              >
                <p>현금</p>
              </div>
              <div
                className="small-box"
                style={{ backgroundColor: `${categoryBg}` }}
              >
                <p>현물</p>
              </div>
            </section>
          </div>
        </div>

        {/* 중간 열: 금액 */}
        <div className="middle-column">
          <div className="wide-row2">
            <div className="result-value">
              <span>{formatNumberWithCommas(govSupportAmount) || 0}</span>
              <span>
                {totalAmount
                  ? `(${((govSupportAmount / totalAmount) * 100).toFixed(2)})` // 소수점 2자리
                  : "(0)"}
              </span>
            </div>
            <div className="result-value">
              <span>원</span>
              <span>(%)</span>
            </div>
          </div>
          <div className="wide-row2">
            <div className="result-value">
              <span>{formatNumberWithCommas(cashAmount) || 0}</span>
              <span>
                {cashAmount
                  ? `(${((cashAmount / totalAmount) * 100).toFixed(2)})` // 소수점 2자리
                  : "(0)"}
              </span>
            </div>
            <div className="result-value">
              <span>원</span>
              <span>(%)</span>
            </div>
          </div>
          <div className="wide-row2">
            <div className="result-value">
              <span>{formatNumberWithCommas(inKindAmount) || 0}</span>
              <span>
                {selfFundingPercent
                  ? `(${((inKindAmount / totalAmount) * 100).toFixed(2)})` // 소수점 2자리
                  : "(0)"}
              </span>
            </div>
            <div className="result-value">
              <span>원</span>
              <span>(%)</span>
            </div>
          </div>
        </div>

        {/* 오른쪽 열 */}
        <aside
          className="total-column"
          style={{ backgroundColor: `${categoryBg}` }}
        >
          <div>
            <strong>합계</strong>
          </div>
        </aside>
        <aside className="right-column">
          <div className="result-value">
            <span>{formatNumberWithCommas(totalAmount) || 0}</span>
            <span>{totalAmount ? "(100.00)" : "(0)"}</span>
          </div>

          <div className="result-value">
            <span>원</span>
            <span>(%)</span>
          </div>
        </aside>
      </div>
    </section>
  );
};
export default CalculatorResult;
