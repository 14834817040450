import React, { useRef, useEffect, useState } from "react";
import { useController, useForm } from "react-hook-form";
import "./css/CalculatorSupport.scss";
import CalculatorResult from "./CalculatorResult";

const projectInfoList = [
  "정부지원사업비는 창업기업 7천만원 내외(최대 1억원).",
  "정부지원사업비는 평가결과에 따라 신청금액 대비 감액될 수 있으며, 신청금액을 초과하여 지급될 수 없음.",
  "창업기업은 자기부담사업비를 필수로 납부하여야 하며, 미이행시 협약체결 대상에서 제외.",
  "정부지원사업비는 총 사업비의 70% 이하, 자기부담사업비 중 현금은 10% 이상, 현물은 20% 이하로 작성.",
  "자기부담사업비 중 현금 비중은 최소 10%에서 최대 30%까지 납부할 수 있음.",
];

const CalculatorSupport = () => {
  const inputRef = useRef(null); // 입력 필드 참조
  const [govSupportAmount, setGovSupportAmount] = useState(0); // 정부지원사업비
  const [govSupportPercent, setGovSupportPercent] = useState(0); // 정부지원사업비 비율
  const [selfFundingPercent, setSelfFundingPercent] = useState(0); // 자기부담사업비 현금 비율

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // 포맷 함수
  const formatNumberWithCommas = (value) => {
    if (!value) return "";

    // 숫자로 변환
    const numericValue = parseFloat(value);

    // 소수점 여부 판단
    if (Number.isInteger(numericValue)) {
      // 자연수일 경우 그대로 반환
      return window.$Global.numberWithCommas(numericValue);
    } else {
      // 소수점 포함 값은 소수점 둘째 자리까지 반올림 후 반환
      return window.$Global.numberWithCommas(numericValue.toFixed(2));
    }
  };

  // 정부지원사업비 컨트롤러
  const {
    field: { value, onChange },
  } = useController({
    name: "govSupportAmount",
    control,
    defaultValue: "",
    rules: {
      required: "정부지원사업비를 입력하세요.",
      validate: (value) => {
        const rawValue = value.replace(/,/g, ""); // 콤마 제거 후 검증
        return rawValue > 0 || "0 이상의 값을 입력하세요.";
      },
    },
  });

  // 입력값 변경 시 처리
  const handleInputChange = (e) => {
    const rawValue = e.target.value.replace(/,/g, ""); // 콤마 제거
    if (!isNaN(rawValue)) {
      onChange(rawValue); // React Hook Form에 원본 값 저장
    }
  };

  // 새로고침 시 govSupportAmount input에 포커스
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // 포커스 설정
    }
  }, []);

  const onSubmit = (data) => {
    const govAmount = parseFloat(data.govSupportAmount.replace(/,/g, "")) || 0;
    const govPercent = parseFloat(data.govSupportPercent) || 0;
    const selfPercent = parseFloat(data.selfFundingPercent) || 0;

    // 상태 업데이트
    setGovSupportAmount(govAmount);
    setGovSupportPercent(govPercent);
    setSelfFundingPercent(selfPercent);
  };

  return (
    <div id="CalculatorSupport">
      <header>
        <h1>지원사업 계산기</h1>
      </header>
      {/* 지원사업 설명 섹션 */}
      <section className="project-info">
        <h2>창업중심대학 사업비 안내</h2>
        <ul>
          {projectInfoList.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </section>

      {/* 입력 폼 */}
      <section>
        <form onSubmit={handleSubmit(onSubmit)} className="calculator-section">
          <div className="calculator-container">
            <div className="input-wrapper">
              <label>정부지원사업비</label>
              <input
                type="text" // 콤마 처리를 위해 text로 변경
                placeholder="금액 입력"
                ref={inputRef} // ref 연결
                value={formatNumberWithCommas(value)} // 포맷팅된 값 표시
                onChange={handleInputChange} // 입력값 처리
              />
              <span>원</span>
              {errors.govSupportAmount && (
                <p className="error-text1">{errors.govSupportAmount.message}</p>
              )}
            </div>

            <div className="input-wrapper">
              <label>정부지원사업비 비율</label>

              <input
                type="number"
                placeholder="0"
                {...register("govSupportPercent", {
                  required: "비율을 입력하세요.",
                  min: { value: 0, message: "0 이상 입력해야 합니다." },
                  max: { value: 70, message: "70 이하로 입력하세요." },
                })}
              />
              <span>%</span>
              {errors.govSupportPercent && (
                <p className="error-text2">
                  {errors.govSupportPercent.message}
                </p>
              )}
            </div>

            <div className="input-wrapper">
              <label>자기부담사업비 현금 비율</label>
              <input
                type="number"
                placeholder="0"
                {...register("selfFundingPercent", {
                  required: "비율을 입력하세요.",
                  min: { value: 10, message: "10 이상 입력해야 합니다." },
                  max: { value: 30, message: "30 이하로 입력하세요." },
                })}
              />
              <span>%</span>
              {errors.selfFundingPercent && (
                <p className="error-text2">
                  {errors.selfFundingPercent.message}
                </p>
              )}
            </div>
          </div>

          <button type="submit">사업비 계산하기</button>
        </form>
      </section>

      {/* 보정 값 결과 섹션 */}
      <CalculatorResult
        data={{
          type: "adjusted",
          govSupportAmount: govSupportAmount,
          govSupportPercent: govSupportPercent,
          selfFundingPercent: selfFundingPercent,
        }}
      />

      {/* 기본 값 결과 섹션 */}
      <CalculatorResult
        data={{
          type: "original",
          govSupportAmount: govSupportAmount,
          govSupportPercent: govSupportPercent,
          selfFundingPercent: selfFundingPercent,
        }}
      />
    </div>
  );
};

export default CalculatorSupport;
