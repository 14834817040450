import React from "react";
import { Route } from "react-router-dom";
import TermsPrivate from "../components/terms/TermsPrivate";
import TermsUse from "../components/terms/TermsUse";
import TermsPayment from "../components/terms/TermsPayment";
import CustomSwitch from "./common/CustomSwitch";
import TermsPrivateMobile from "../components/terms/TermsPrivateMobile";
import TermsUseMobile from "../components/terms/TermsUseMobile";

function TermsRoute({ match }) {
  return (
    <CustomSwitch>
      <Route exact path={`${match.path}/private`} component={TermsPrivate} />
      <Route
        exact
        path={`${match.path}/mo/private`}
        component={TermsPrivateMobile}
      />
      <Route exact path={`${match.path}/use`} component={TermsUse} />
      <Route exact path={`${match.path}/mo/use`} component={TermsUseMobile} />
      <Route exact path={`${match.path}/payment`} component={TermsPayment} />
    </CustomSwitch>
  );
}

export default TermsRoute;
