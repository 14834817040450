import util from "../../../util/util";
import React, { useEffect, useState } from "react";
import "../css/GroupwareVacationDashBoard.scss";
import GWAPI from "../../../API/groupware";
import GroupwareSideBar from "../GroupwareSideBar";
import { groupWareWorksPath } from "../groupwarePath";

const GroupwareVacationDashBoard = () => {
  const [adminMenu, setAdminMenu] = useState([]);
  const [vacationList, setVacationList] = useState([]);

  useEffect(() => {
    getAdminMenu();
  }, []);

  useEffect(() => {
    GWAPI.getVacKindWithDays().then((res) => {
      // console.log("현 사용 휴가 리스트: ", res.data);
      setVacationList(res.data.vacationKindDay);
    });
  }, []);

  const sortingIconByCd = (holiday_cd, holiday_name) => {
    if(holiday_name.includes('생일')) {
      return 'icon_widget_cake'
    } else if(holiday_name.includes('여름')) {
      return 'icon_widget_parasol';
    }

    // 기본 휴가
    switch(holiday_cd) {
      // 병가
      case '100090':
        return 'icon_widget_medicine';
      
      // 조퇴
      case '100092' :
        return 'icon_widget_briefcase';

      // 기본 캘린더 아이콘
      default:
        return 'icon_widget_calendar';
    }
  };

  const getAdminMenu = () => {
    let param = {
      su_menu_cd: "100010",
      menuTitle: "관리자",
      isContentWrap: false,
      isOpen: false,
    };
    util.getGroupwareAdminMenu(setAdminMenu, param);
  };

  return (
    <div id="GroupwareVacationDashBoard">
      <GroupwareSideBar
        currentPath={groupWareWorksPath["vacation"]}
        adminMenu={adminMenu}
        sidebar={"vacation"}
      />
      <div className="vacation_wrapper">
        <h5>휴가 신청하기</h5>
        <div className="vacation_grid_apply">
          {vacationList && vacationList.map(vac => (
            <div key={vac.holiday_name}>
              <p>
                <i className={sortingIconByCd(vac.holiday_cd, vac.holiday_name)} />
                {vac.holiday_name}
              </p>
              <p className="item_head_title">{vac.holiday_name} 신청하기</p>
              <button
                onClick={() =>
                  util.getGroupwareSrcPath("writeForm", `${vac.holiday_cd}`, "vacation")
                }
              >
                신청하기
              </button>
            </div>
          ))
          }
        </div>
      </div>
    </div>
  );
};

export default GroupwareVacationDashBoard;
