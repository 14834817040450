import React, { useEffect, useState } from "react";
import util from "../../../util/util";
import GroupwareSideBar from "../GroupwareSideBar";
import { groupWareWorksPath } from "../groupwarePath";
import { useHistory } from "react-router-dom";

const GroupwareCommuteDashBoard = () => {
  const history = useHistory();
  const [adminMenu, setAdminMenu] = useState([]);

  useEffect(() => {
    getAdminMenu();
  }, []);

  const getAdminMenu = () => {
    let param = {
      su_menu_cd: "100010",
      menuTitle: "관리자",
      isContentWrap: false,
      isOpen: false,
    };
    util.getGroupwareAdminMenu(setAdminMenu, param);
  };

  return (
    <div id="GroupwareVacationDashBoard">
      <GroupwareSideBar
        currentPath={groupWareWorksPath["attend"]}
        adminMenu={adminMenu}
        sidebar={"attend"}
      />
      <div className="vacation_wrapper">
        <h5>근태 신청하기</h5>
        <div className="vacation_grid_apply">
        <div>
            <p>
              <i className="icon_widget_briefcase" />
              외근계
            </p>
            <p className="item_head_title">외근계 신청하기</p>
            <button
              onClick={() =>
                history.push(
                  "/groupware/works?section=vacation&command=writeForm&doc_type=100105"
                )
              }
            >
              신청하기
            </button>
          </div>
          <div>
            <p>
              <i className="icon_widget_briefcase" />
              출장계
            </p>
            <p className="item_head_title">출장계 신청하기</p>
            <button
              onClick={() =>
                history.push(
                  "/groupware/works?section=vacation&command=writeForm&doc_type=100095"
                )
              }
            >
              신청하기
            </button>
          </div>
          <div>
            <p>
              <i className="icon_widget_clock" />
              조정계
            </p>
            <p className="item_head_title">조정계 신청하기</p>
            <button
              onClick={() =>
                history.push(
                  "/groupware/works?section=vacation&command=writeForm&doc_type=100108"
                )
              }
            >
              신청하기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupwareCommuteDashBoard;
