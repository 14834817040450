import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

const ResearchTechFamilyView = ({ familyData, state, viewType }) => {
  const history = useHistory();
  const [patentTree, setPatentTree] = useState([]);
  const [singleClassName, setSingleClassName] = useState("");

  useEffect(() => {
    drawTree();
  }, []);

  const linkHandler = (item, nation) => {
    if (nation === "KR") {
      return `/researchTech/patent${
        viewType === "ipnow" ? "/ipnow" : ""
      }/${item.apply_number.replace(/-/g, "")}/detail?nation=${nation}`;
    } else {
      return `/researchTech/patent${viewType === "ipnow" ? "/ipnow" : ""}/${
        item.document_number
      }/detail?nation=${nation}`;
    }
  };

  const getItemTag = (item, nation) => {
    let tag = (
      <>
        <div className="flag">
          {nation}
          <i className={`icon_flag_${nation} flag`} />
        </div>
        <div className="no">{item.apply_number || "출원 정보 입력 중"}</div>
        <div className="date">
          <p>
            {item.apply_at ? window.$Global.convertDate(item.apply_at) : ""}
          </p>
          <i className="icon_arrow_right_s" />
        </div>
      </>
    );
    return (
      <div
        className="node"
        onClick={() => {
          history.push({
            pathname: linkHandler(item, nation),
            state: state,
          });
          window.location.reload();
        }}
      >
        {tag}
      </div>
    );
  };

  const drawTree = () => {
    let data = familyData.concat();
    let arr = [];
    let arr_root = data.filter((item) => item.parent_idx === null);

    if (arr_root.length === 1) {
      setSingleClassName("single");
    }

    arr_root.map((item, idx) => {
      let nation = window.$Global.convertNationCodeToStr(item.nation);
      data.splice(
        data.findIndex((obj) => obj.idx === item.idx),
        1
      );

      return arr.push(
        <li
          key={`${item.idx}_li_${idx}`}
          className={`level_1 ${arr_root.length === 1 ? "single" : ""}`}
        >
          {getItemTag(item, nation)}
          {drawChildPatentRecursion(data, item.idx)}
        </li>
      );
    });
    setPatentTree(arr);
  };

  const drawSamePatent = (data, parent_idx, self_idx, current_depth) => {
    let same_patent_arr = data.filter(
      (item) =>
        (item.parent_idx === parent_idx &&
          item.depth === current_depth &&
          self_idx !== item.idx) ||
        item.dp == 0
    );

    // for (let i = 0; i < same_patent_arr.length; i++) {
    if (same_patent_arr.length) {
      let item = same_patent_arr[0];
      // let item = same_patent_arr[i];
      let nation = window.$Global.convertNationCodeToStr(item.nation);
      data.splice(
        data.findIndex((obj) => obj.idx === item.idx),
        1
      );
      return (
        <>
          <li key={`${item.idx}_li`} className={item.dp == 0 ? "dp_0" : ""}>
            {getItemTag(item, nation)}
            {drawChildPatentRecursion(data, item.idx, item.depth)}
          </li>
          {drawSamePatent(data, item.parent_idx, item.idx, item.depth)}
        </>
      );
    }
    // }
  };

  const drawChildPatentRecursion = (data, parent_idx) => {
    let next_patent_arr = data.filter((item) => {
      if (item.dp === 1) {
        return item.parent_idx === parent_idx;
      }
    });

    if (next_patent_arr.length) {
      // for (let i = 0; i < next_patent_arr.length; i++) {
      let item = next_patent_arr[0];
      let nation = window.$Global.convertNationCodeToStr(item.nation);
      data.splice(
        data.findIndex((obj) => obj.idx === item.idx),
        1
      );
      return (
        <ul className={`level_${item.depth}`}>
          <li key={`${item.idx}_li`}>
            {getItemTag(item, nation)}
            {drawChildPatentRecursion(data, item.idx)}
          </li>
          {drawSamePatent(data, item.parent_idx, item.idx, item.depth)}
        </ul>
      );
      // }
    }
  };

  return (
    <div className="tree custom_scroll">
      <ul className={`root ${singleClassName}`}>{patentTree}</ul>
    </div>
  );
};

export default ResearchTechFamilyView;
