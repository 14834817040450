import React from "react";
import "../css/GroupwareBoard.scss";
import { useHistory } from "react-router-dom";
import commonFunc from "../../admin/commonFunc/common";
import Pagination from "../../common/Pagination";

const GroupwareBoard = ({
  section,
  categoryName,
  boardItem,
  curPage,
  setCurPage,
  last,
}) => {
  const history = useHistory();
  console.log(categoryName);
  return (
    <div id="GroupwareBoard">
      <p className="title">
        {commonFunc.convertCommunitySectionName(section)} >{" "}
        {categoryName || "마케팅팀"}
      </p>
      <div className="board_items">
        {boardItem?.items?.map((item, index) => {
          return (
            <div className="board_item">
              <img
                src={window.$Global.getCDN(item.thumbnail_key)}
                alt="이미지"
              />
              <div>
                <h2>{item.title || item.question}</h2>
                <h5>{window.$Global.convertDate(item.created_at)}</h5>
              </div>
              <button
                onClick={() =>
                  history.push(`/groupware/infoBoard/detail/${item.idx}`)
                }
              >
                바로가기
              </button>
            </div>
          );
        })}
        {boardItem?.items?.length === 0 && <p>등록된 게시글이 없습니다.</p>}
      </div>
      <Pagination curPage={curPage} lastNum={last} onClick={setCurPage} />
    </div>
  );
};

export default GroupwareBoard;
