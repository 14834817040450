import React, { useContext } from "react";
import PatentContext from "../../../store/Patent";
import DateAndFileRow from "./common/DateAndFileRow";
import InnerDatePickerAndFileRow from "./common/InnerDatePickerAndFileRow";
import NormalRow from "./common/NormalRow";
import ToggleButtonWithSpan from "../../common/ToggleButtonWithSpan";
import InputRow from "./common/InputRow";
import typeName from "./constrant/typeName";
import { Link } from "react-router-dom";
import DatePickerRow from "./common/DatePickerRow";
import ButtonRow from "./common/ButtonRow";
import SelectRow from "./common/SelectRow";
import InputAndFileRow from "./common/InputAndFileRow";
import InventorRow from "./common/InventorRow";
import ProductAndStateRowContainer from "./common/ProductAndStateRowContainer";
import ShareRow from "./common/ShareRow";
import ProcessStep from "./common/ProcessStep";
import ProcessManage from "./common/ProcessManage";
import ButtonApprovalRow from "./common/ButtonApprovalRow";

function FormCompanyPct({
  productSelect,
  inventorSelectTag,
  ListOaTag,
  jointApplyListTag,
  agentCompanyListTag,
  managerSelectTag,
  checkAuth,
  inventorListTag,
  onClickDownPopShow,
  onClickUpPopShow,
  onChangeInput,
  onClickInventorAdd,
  onClickProductAdd,
  onChangeDatePickerInnerDate,
  onChangeDatePicker,
  onClickClaim,
  onClickFirstClaim,
}) {
  const patentInfo = useContext(PatentContext);

  return (
    <div className="form">
      <div className="field">
        <ProcessStep text="서지 사항" state={patentInfo.state} />
        <div className="rows">
          <InputRow
            title="관리번호"
            titleClass="color_blue"
            condition={patentInfo.editing}
            content={patentInfo.manage_number}
            onChange={onChangeInput}
            setKey="manage_number"
          />
          <NormalRow title="유형" content={patentInfo.type} />
          <div className="row">
            <h2 className="title">
              Family 출원
              <Link
                className="icon_plus"
                to={`/patent/family/${patentInfo.idx}`}
              />
            </h2>
            <p>{patentInfo.family_cnt}건</p>
          </div>
        </div>
        <div className="rows">
          <SelectRow
            title="담당자"
            content={patentInfo.manager_name}
            onChange={(e) =>
              patentInfo.setPatent({
                ...patentInfo,
                manager_idx: e.target.value,
                manager_name: e.target.options[e.target.selectedIndex].text,
              })
            }
            condition={patentInfo.editing}
            contentIdx={patentInfo.manager_idx}
            selectTag={managerSelectTag}
          />
          <InventorRow
            index={0}
            editing={patentInfo.editing}
            inventor={patentInfo.inventor[0]}
            inventorSelectTag={inventorSelectTag}
            onClickEvent={onClickInventorAdd}
          />
          <ShareRow
            index={0}
            editing={patentInfo.editing}
            inventor={patentInfo.inventor[0]}
          />
        </div>
        {inventorListTag}
        <div className="rows">
          <SelectRow
            title="현지대리인"
            content={patentInfo.local_agent_company_name}
            onChange={(e) =>
              patentInfo.setPatent({
                ...patentInfo,
                local_agent_company_idx: e.target.value,
                local_agent_company_name:
                  e.target.options[e.target.selectedIndex].text,
              })
            }
            condition={patentInfo.editing}
            contentIdx={patentInfo.local_agent_company_idx}
            selectTag={agentCompanyListTag}
          />
          <ProductAndStateRowContainer
            index={0}
            patentInfo={patentInfo}
            setPatentInfo={patentInfo.setPatent}
            productSelect={productSelect}
            onClickProductAdd={onClickProductAdd}
          />
        </div>
        {patentInfo.productListTag}
        {jointApplyListTag}
      </div>
      {patentInfo.mergeTag}
      <div className="field">
        <ProcessStep text="출원" state={patentInfo.state} />
        <div className="rows">
          <InputAndFileRow
            title="출원번호"
            titleClassName="color_blue"
            content={patentInfo.apply_number}
            haveFile={Boolean(patentInfo.apply_file_cnt)}
            onChange={(e) => onChangeInput("apply_number", e.target.value)}
            editing={patentInfo.editing}
            onClickDownPopShow={() => onClickDownPopShow(typeName.apply)}
            onClickUpPopShow={() => onClickUpPopShow(typeName.apply)}
          />
          <DatePickerRow
            title="출원일"
            condition={patentInfo.editing}
            data={patentInfo.apply_at}
            dataKey="apply_at"
            useOaFunc={false}
            onChangeDatePicker={onChangeDatePicker}
          />
          <NormalRow
            title="우선일"
            content={patentInfo.priority_at}
            txtType="date"
          />
        </div>
        <div className="rows">
          <InputRow
            rowClassName="colspan2"
            title="발명의 명칭"
            condition={patentInfo.editing}
            content={patentInfo.invention_name}
            inputClassName="long"
            onChange={onChangeInput}
            setKey="invention_name"
          />
        </div>
        <div className="rows">
          <InputRow
            rowClassName="colspan2"
            title="영문 명칭"
            condition={patentInfo.editing}
            content={patentInfo.invention_name_en}
            inputClassName="long"
            onChange={onChangeInput}
            setKey="invention_name_en"
          />
        </div>
        <div className="rows">
          <NormalRow
            title="우선심사청구"
            component={
              <ToggleButtonWithSpan
                className={patentInfo.editingClass}
                text=""
                condition={{ on: "유", off: "무" }}
                selected={patentInfo.priority_screen}
                toggleSelected={onClickFirstClaim}
              />
            }
          />
          <NormalRow
            title="심사청구"
            component={
              <ToggleButtonWithSpan
                className={patentInfo.editingClass}
                text=""
                condition={{ on: "유", off: "무" }}
                selected={patentInfo.screen_req}
                toggleSelected={onClickClaim}
              />
            }
          />
          <NormalRow
            title="심사청구 기한"
            titleClassName="color_blue"
            content={patentInfo.screen_deadline}
            contentClassName="color_red"
            txtType="date"
          />
        </div>
        <div className="rows">
          <DatePickerRow
            title="심사청구일"
            condition={patentInfo.editing}
            data={patentInfo.screen_req_at}
            dataKey="screen_req_at"
            useOaFunc={false}
            onChangeDatePicker={onChangeDatePicker}
          />
        </div>
        <div className="process_manage">
          <ProcessManage
            title="출원 상세 절차 관리"
            selected={patentInfo.apply_detail}
          />
          {Boolean(patentInfo.apply_detail) && (
            <>
              <div className="rows">
                <ButtonRow
                  title="초안작성 지시"
                  condition={patentInfo.editing}
                  data={patentInfo.draft_order_at}
                  onClick={() =>
                    patentInfo.setPatent({
                      ...patentInfo,
                      draft_order_at: new Date().getTime(),
                    })
                  }
                />
                <InnerDatePickerAndFileRow
                  title="초안 접수"
                  data={patentInfo.draftReceivingDate}
                  condition={patentInfo.editing}
                  setKey="draftReceivingDate"
                  onChangeDatePickerJson={onChangeDatePickerInnerDate}
                  haveFile={Boolean(patentInfo.draft_file_cnt)}
                  uploadBtnShow={true}
                  onClickDownPopShow={() =>
                    onClickDownPopShow(typeName.draft, { modified: 0 })
                  }
                  onClickUpPopShow={() =>
                    onClickUpPopShow(typeName.draft, { modified: 0 })
                  }
                />
                <InnerDatePickerAndFileRow
                  title={"초안\n발명자 검토"}
                  data={patentInfo.draftInventorsDate}
                  condition={patentInfo.editing}
                  setKey="draftInventorsDate"
                  onChangeDatePickerJson={onChangeDatePickerInnerDate}
                  haveFile={Boolean(patentInfo.draft_reply_researcher_file_cnt)}
                  uploadBtnShow={checkAuth("특허회사")}
                  align={true}
                  onClickDownPopShow={() =>
                    onClickDownPopShow(typeName.draft_reply, {
                      type: 2,
                      modified: 0,
                    })
                  }
                  onClickUpPopShow={() =>
                    onClickUpPopShow(typeName.draft_reply, {
                      type: 2,
                      modified: 0,
                    })
                  }
                />
              </div>
              <div className="rows">
                <div className="row no_data"></div>
                <div className="row no_data"></div>
                <InnerDatePickerAndFileRow
                  title={`초안\n담당자 검토`}
                  data={patentInfo.draftManagerDate}
                  condition={patentInfo.editing}
                  setKey="draftManagerDate"
                  onChangeDatePickerJson={onChangeDatePickerInnerDate}
                  haveFile={Boolean(patentInfo.draft_reply_manager_file_cnt)}
                  uploadBtnShow={checkAuth("특허회사")}
                  align={true}
                  onClickDownPopShow={() =>
                    onClickDownPopShow(typeName.draft_reply, { type: 1 })
                  }
                  onClickUpPopShow={() =>
                    onClickUpPopShow(typeName.draft_reply, { type: 1 })
                  }
                />
              </div>
              <div className="rows">
                <DateAndFileRow
                  title="초안 수정본 전달"
                  date={patentInfo.draftModifiedUploadDate.date}
                  onClickDownPopShow={() =>
                    onClickDownPopShow(typeName.draft, { modified: 1 })
                  }
                  onClickUpPopShow={() =>
                    onClickUpPopShow(typeName.draft, { modified: 1 })
                  }
                  haveFile={Boolean(patentInfo.modified_draft_file_cnt)}
                  uploadBtnShow={checkAuth("특허회사_관리자")}
                />
                <InnerDatePickerAndFileRow
                  title={"초안\n최종본 접수"}
                  data={patentInfo.draftFinalDate}
                  condition={patentInfo.editing}
                  setKey="draftFinalDate"
                  onChangeDatePickerJson={onChangeDatePickerInnerDate}
                  haveFile={Boolean(patentInfo.final_draft_file_cnt)}
                  uploadBtnShow={checkAuth("모두")}
                  onClickDownPopShow={() =>
                    onClickDownPopShow(typeName.draft, { modified: 2 })
                  }
                  onClickUpPopShow={() =>
                    onClickUpPopShow(
                      checkAuth("특허회사")
                        ? typeName.draft
                        : typeName.agent_draft,
                      { modified: 2 }
                    )
                  }
                />
                {patentInfo.apply_approval ? (
                  <ButtonApprovalRow
                    patentInfo={patentInfo}
                    onClick={() =>
                      patentInfo.setPatentApprovalApplyPopupShow(true)
                    }
                  />
                ) : (
                  <ButtonRow
                    title="출원 지시"
                    titleClassName="color_blue"
                    condition={patentInfo.editing}
                    data={patentInfo.apply_order_at}
                    onClick={() =>
                      patentInfo.setPatent({
                        ...patentInfo,
                        apply_order_at: new Date().getTime(),
                      })
                    }
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="field">
        <ProcessStep text="번역문" state={patentInfo.state} />
        <div className="rows">
          <div
            className="row translate"
            style={{
              width: patentInfo.translation ? "100%" : "32%",
              margin: 0,
            }}
          >
            <h2 className="title">
              번역문 {patentInfo.translation ? "ON" : "OFF"}
              {patentInfo.editing ? (
                <ToggleButtonWithSpan
                  text=""
                  condition={{ on: "", off: "" }}
                  selected={patentInfo.translation}
                  toggleSelected={() =>
                    patentInfo.setPatent({
                      ...patentInfo,
                      translation: !patentInfo.translation,
                    })
                  }
                />
              ) : null}
            </h2>
          </div>
        </div>
        {Boolean(patentInfo.translation) && (
          <>
            <div className="rows">
              <ButtonRow
                title={`번역문\n초안작성 지시`}
                condition={patentInfo.editing}
                data={patentInfo.draft_translation_order_at}
                onClick={() =>
                  patentInfo.setPatent({
                    ...patentInfo,
                    draft_translation_order_at: new Date().getTime(),
                  })
                }
              />
              <InnerDatePickerAndFileRow
                title={"번역문\n초안 접수"}
                data={patentInfo.translateDraftReceivingDate}
                condition={patentInfo.editing}
                setKey="translateDraftReceivingDate"
                onChangeDatePickerJson={onChangeDatePickerInnerDate}
                haveFile={Boolean(patentInfo.translation_draft_file_cnt)}
                uploadBtnShow={true}
                onClickDownPopShow={() =>
                  onClickDownPopShow(typeName.draft_translation, {
                    modified: 0,
                  })
                }
                onClickUpPopShow={() =>
                  onClickUpPopShow(typeName.draft_translation, { modified: 0 })
                }
              />
              <InnerDatePickerAndFileRow
                title={"번역문 초안\n발명자 검토"}
                data={patentInfo.translateDraftInventorsDate}
                condition={patentInfo.editing}
                setKey="translateDraftInventorsDate"
                onChangeDatePickerJson={onChangeDatePickerInnerDate}
                haveFile={Boolean(
                  patentInfo.translation_draft_reply_researcher_file_cnt
                )}
                uploadBtnShow={true}
                onClickDownPopShow={() =>
                  onClickDownPopShow(typeName.draft_translation_reply, {
                    type: 2,
                    modified: 0,
                  })
                }
                onClickUpPopShow={() =>
                  onClickUpPopShow(typeName.draft_translation_reply, {
                    type: 2,
                    modified: 0,
                  })
                }
              />
            </div>
            <div className="rows">
              <div className="row no_data" />
              <div className="row no_data" />
              <InnerDatePickerAndFileRow
                title={"번역문 초안\n담당자 검토"}
                data={patentInfo.translateDraftManagerDate}
                condition={patentInfo.editing}
                setKey="translateDraftManagerDate"
                onChangeDatePickerJson={onChangeDatePickerInnerDate}
                haveFile={Boolean(
                  patentInfo.translation_draft_reply_manager_file_cnt
                )}
                uploadBtnShow={true}
                onClickDownPopShow={() =>
                  onClickDownPopShow(typeName.draft_translation_reply, {
                    type: 1,
                    modified: 0,
                  })
                }
                onClickUpPopShow={() =>
                  onClickUpPopShow(typeName.draft_translation_reply, {
                    type: 1,
                    modified: 0,
                  })
                }
              />
            </div>
            <div className="rows">
              <DateAndFileRow
                title={`번역문\n초안 수정본 전달${
                  patentInfo.draft_translation_modified_upload_at !== null
                    ? "일"
                    : ""
                }`}
                date={patentInfo.draft_translation_modified_upload_at}
                haveFile={Boolean(
                  patentInfo.translation_modified_draft_file_cnt
                )}
                onClickDownPopShow={() =>
                  onClickDownPopShow(typeName.draft_translation, {
                    modified: 1,
                  })
                }
                onClickUpPopShow={() =>
                  onClickUpPopShow(typeName.draft_translation, { modified: 1 })
                }
                uploadBtnShow={true}
              />
              <InnerDatePickerAndFileRow
                title={"번역문 초안\n최종본 접수"}
                data={patentInfo.translateDraftFinalDate}
                condition={patentInfo.editing}
                setKey="translateDraftFinalDate"
                onChangeDatePickerJson={onChangeDatePickerInnerDate}
                haveFile={Boolean(patentInfo.translation_final_draft_file_cnt)}
                uploadBtnShow={true}
                onClickDownPopShow={() =>
                  onClickDownPopShow(typeName.draft_translation, {
                    modified: 2,
                  })
                }
                onClickUpPopShow={() =>
                  onClickUpPopShow(typeName.draft_translation, { modified: 2 })
                }
              />
              <NormalRow
                title="번역문 기한"
                titleClassName="color_blue"
                content={patentInfo.translation_deadline}
              />
            </div>
            <div className="rows">
              <div className="row no_data" />
              <div className="row no_data" />
              <ButtonRow
                title="출원 지시"
                condition={patentInfo.editing}
                titleClassName="color_blue"
                data={patentInfo.apply_order_at}
                onClick={() =>
                  patentInfo.setPatent({
                    ...patentInfo,
                    apply_order_at: new Date().getTime(),
                  })
                }
              />
            </div>
          </>
        )}
      </div>
      <div className="field">
        <ProcessStep text="국제조사보고서" state={patentInfo.state} />
        <div className="rows">
          <DateAndFileRow
            title={`국제조사보고서`}
            titleClassName="color_blue"
            date={patentInfo.international_report_upload_at}
            haveFile={patentInfo.international_file_cnt}
            onClickDownPopShow={() => onClickDownPopShow(typeName.report)}
            onClickUpPopShow={() => onClickUpPopShow(typeName.report)}
            uploadBtnShow={true}
          />
          <NormalRow
            title="국내단계 진입기한"
            content={patentInfo.domestic_level_deadline}
            txtType="date"
          />
        </div>
      </div>
      {ListOaTag}
    </div>
  );
}

export default FormCompanyPct;
