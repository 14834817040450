import React, { useEffect, useRef, useState } from "react";
import "./css/ResearchTechKeywordSearch.scss";
import { useHistory, Link } from "react-router-dom";
import CommonAPI from "../../API/common";
import Pagination from "../common/Pagination";
import ResearchTechAddPatent from "./ResearchTechAddPatent";
import { useRecoilState } from "recoil";
import { applyNumberArray, keywordIdx, researchTechHistory } from "../../atom";
import InfoCompanyAPI from "../../API/infoCompany";
import InfoAPI from "../../API/info";
import AnnualFeeReport from "../annualFee/AnnualFeeReport";
import ReportDummyPatentButton from "./ReportDummyPatentButton";

const ResearchTechApplicantSearch = ({
  company_keyword,
  company_code,
  company_name,
}) => {
  const didMount = useRef(false);
  const history = useHistory();
  const [searchInput, setSearchInput] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [list, setList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [companyCode, setCompanyCode] = useState("");
  const [corporateNumber, setCorporateNumber] = useState("");
  const [licenseNumber, setLicenseNumber] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  const [curPage, setCurPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [addPatentData, setAddPatentData] = useState([]);
  const [deletePatentCheck, setDeletePatentCheck] = useState([]);
  const [deletePatentData, setDeletePatentData] = useState([]);
  const [applyNumArr, setApplyNumArr] = useRecoilState(applyNumberArray);
  const [historyData, setHistoryData] = useRecoilState(researchTechHistory);
  const [keywordNumber, setKeywordNumber] = useRecoilState(keywordIdx);
  const [isClick, setIsClick] = useState(false);
  const [count, setCount] = useState(10);
  const [last, setLast] = useState(1);
  const [isViewCompanyList, setIsViewCompanyList] = useState(false);
  const [companyIdx, setCompanyIdx] = useState(null);
  const [annualFeeData, setAnnualFeeData] = useState({});
  const [isReportShow, setIsReportShow] = useState(false);

  useEffect(() => {
    if (company_keyword !== undefined) historyBackSearchPatent();
    const storedCompanyIdx = window.localStorage.getItem("company_idx");
    setCompanyIdx(storedCompanyIdx);
  }, []);

  useEffect(() => {
    if (didMount.current) {
      onClickKeywordSearch();
    } else didMount.current = true;
  }, [deletePatentData]);

  useEffect(() => {
    if (searchKeyword) searchPatentPagination();
  }, [curPage, count]);

  const onChangeSearchKeyword = (e) => {
    e.persist();
    setSearchInput(e.target.value);
  };

  const onClickSearchCancel = () => {
    setSearchInput("");
  };

  const onClickCompanyCode = (companyCode) => {
    let payload = {
      companyIdx: companyCode,
    };

    window.$Global.fileDownloadSync(
      "/common/company/patent/downloadSummary",
      payload
    );
  };

  const onClickKeywordSearch = () => {
    setSearchKeyword(searchInput);
    setIsViewCompanyList(true);
    InfoCompanyAPI.getInfoCompany(searchInput).then((res) => {
      if (res.data.length) {
        setCompanyList(res.data);
      }
    });
  };

  const handleChange = (e, item) => {
    item.isChecked = !item.isChecked;
    if (item.isChecked) {
      setDeletePatentCheck([...deletePatentCheck, item.apply_number]);
    } else {
      setDeletePatentCheck(
        deletePatentCheck.filter((el) => el !== item.apply_number)
      );
    }
    setList({ ...list });
  };

  const deletePatent = () => {
    setDeletePatentData([...deletePatentCheck]);
    setCurPage(1);
    alert("삭제되었습니다.");
  };

  const sendData = () => {
    let arr = [];
    let params = {
      company_code: companyCode,
    };
    InfoAPI.getCompanyPatent(params)
      .then((res) => {
        res.data.map((el) => {
          if (el.nation === "KR") arr.push(el.apply_number.replace(/-/g, ""));
        });
      })
      .then(() => {
        setApplyNumArr(arr);
        setHistoryData({
          companyKeyword: searchInput,
          companyCode: companyCode,
          companyName: companyName,
          isKeywordSearch: 3,
        });
        history.push(`edit?company_code=${companyCode}`);
      });
  };

  const handleCountChange = (e) => {
    setCount(e.target.value);
    setCurPage(1);
  };

  const downloadExcel = () => {
    let arr = [];
    let params = {
      company_code: companyCode,
    };
    InfoAPI.getCompanyPatent(params)
      .then((res) => {
        res.data.map((el) => {
          if (el.nation === "KR") arr.push(el.apply_number.replace(/-/g, ""));
        });
      })
      .then(() => {
        window.$Global.download("/common/patent/downloadKeywordExcel", {
          patentApplyNumberList: JSON.stringify(arr),
        });
      });
  };

  const searchPatentPagination = () => {
    let params = {
      company_code: companyCode,
      page: curPage,
      count: count,
    };
    InfoAPI.getCompanyPatent(params).then((res) => {
      if (res.data.items.length > 0) {
        setList(res.data.items);
        setLast(res.data.last);
      }
    });
  };

  const historyBackSearchPatent = () => {
    setSearchKeyword(company_keyword);
    let params = {
      company_code: company_code,
      page: curPage,
      count: count,
    };
    InfoAPI.getCompanyPatent(params).then((res) => {
      console.log(res.data);
      if (res.data.items.length > 0) {
        setSearchInput(company_keyword);
        setList(res.data.items);
        setCompanyName(company_name);
        setLast(res.data.last);
        setCompanyCode(company_code);
        setIsViewCompanyList(false);
        setCurPage(1);
        setTotalCount(res.data.total_count);
      } else {
        alert("특허가 존재하지 않는 회사입니다");
        return;
      }
    });
  };

  const searchPatent = (item) => {
    let params = {
      company_code: item.company_code,
      page: curPage,
      count: count,
    };
    setCorporateNumber(item.corporate_number);
    setLicenseNumber(item.license_number);
    if (item.company_code) {
      InfoAPI.getCompanyPatent(params).then((res) => {
        if (res.data.items.length > 0) {
          setList(res.data.items);
          setCompanyName(item.name);
          setLast(res.data.last);
          setCompanyCode(item.company_code);
          setIsViewCompanyList(false);
          setCurPage(1);
          setTotalCount(res.data.total_count);
        } else {
          alert("특허가 존재하지 않는 회사입니다");
          return;
        }
      });
    } else {
      alert("특허 정보를 검색할 수 없는 회사입니다.");
      return;
    }
  };

  /*const renderReportButton = (item) => {
    let value = item.nation;
    if (item.pge_idx || item.pge_idx2 || item.pge_idx_f) {
      return (
          <button
              className="report_btn"
              onClick={(event) => {
                event.stopPropagation();

                let report_idx = "";
                let family_idx = item.family_idx;

                if (item.pge_idx) {
                  report_idx = item.pge_idx;
                } else if (item.pge_idx2) {
                  report_idx = item.pge_idx2;
                } else if (item.pge_idx_f) {
                  report_idx = item.pge_idx_f;
                }

                setIsReportShow(true);
                setAnnualFeeData({
                  report_idx : report_idx,
                  nation : item.nation,
                  apply_number: item.apply_number,
                  register_number: item.register_number || item.regist_number,
                  idx : item.idx,
                });
              }}
          >
            확인하기
          </button>
      );
    } else if (item.nation === "KR") {
      if (item.register_number !== null && item.register_number !== "") {
        if(item.apply_cnt > 0) {
          return "생성중";
        } else {
          return (
              <button
                  className="report_btn"
                  onClick={(event) => {
                    event.stopPropagation();
                    reportApply(item);
                  }}
              >
                생성하기
              </button>
          );
        }
      } else {
        return "-";
      }
    } else {
      if (
          item.parent_register_number !== null &&
          item.parent_register_number !== "" &&
          item.parent_register_number !== undefined
      ) {
        if(item.apply_cnt > 0 ){
          return "생성중";
        } else {
          return (
              <button
                  className="report_btn"
                  onClick={(event) => {
                    event.stopPropagation();
                    reportForApply(item);
                  }}
              >
                생성하기
              </button>
          );
        }
        //return "-" + item.parent_register_number;
      }  else {
        return "-";
      }
    }
  };*/

  const reportApply = (item) => {
    const check = window.confirm("특허활용 보고서를 생성하시겠습니까?");
    let param = {};
    param.patent_idx = item.idx;
    param.org_nation = item.org_nation;
    // MOZQYC25XKG8KEE0 우리회사
    //alert(companyIdx);
    if (check) {
      CommonAPI.dummyPatentReportApply(item).then(() => {
        alert("보고서 생성까지 5분정도 소요됩니다.\n5분후 새로고침 해 주세요.");
        searchPatentPagination();
      });
    }
  };

  const reportForApply = (item) => {
    const check = window.confirm("특허활용 보고서를 생성하시겠습니까?");
    let param = {};
    param.patent_idx = item.parent_register_number;
    // MOZQYC25XKG8KEE0 우리회사
    //alert(companyIdx);
    if (check) {
      CommonAPI.dummyPatentReportApply(item).then(() => {
        alert("보고서 생성까지 5분정도 소요됩니다.\n5분후 새로고침 해 주세요.");
      });
    }
  };
  return (
    <div id="ResearchTechKeywordSearch">
      <div className="search_wrapper_border">
        <div className="search_wrapper">
          <p className="search_title">
            출원인(특허권자) 검색
            <i
              className="icon_info_with_blue_bg"
              onMouseEnter={() => setShowInfo(true)}
              onMouseLeave={() => setShowInfo(false)}
            />
          </p>
          {showInfo && (
            <div className="search_info">
              키워드가 많을수록 정확하고 빠른검색 지원
              <div>
                ex)
                <p>
                  <span>로봇 AND 인공지능 (O)</span>
                </p>
              </div>
            </div>
          )}
          <div className="search">
            <input
              type="text"
              placeholder="검색어를 입력하세요"
              value={searchInput}
              onChange={onChangeSearchKeyword}
              onKeyUp={(e) => e.key === "Enter" && onClickKeywordSearch()}
              onFocus={() => searchKeyword && setIsViewCompanyList(true)}
            />
            {searchInput.length > 0 ? (
              <button
                className="icon_del_small_with_bg"
                onClick={onClickSearchCancel}
              />
            ) : (
              <button
                className="icon_search_gray ir_txt"
                onClick={onClickKeywordSearch}
              >
                검색
              </button>
            )}
            {isViewCompanyList && searchInput && (
              <ul className="company_list custom_scroll">
                {companyList?.map((item, idx) => {
                  return (
                    <li onClick={() => searchPatent(item)}>
                      <p className="company_name">{item.name}</p>
                      <p>
                        법인등록번호: <span>{item.corporate_number}</span>
                      </p>
                      <p>
                        사업자등록번호: <span>{item.license_number}</span>
                      </p>
                      <p>
                        대표자: <span>{item.representative}</span>
                      </p>
                      <p>
                        설립일:{" "}
                        <span>
                          {window.$Global.convertDate(item.establishment_at)}
                        </span>
                      </p>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          <button
            className="btn_history"
            onClick={() => history.push("/researchTech/my/keyword")}
            style={{ marginLeft: 10 }}
          >
            검색내역 보기
            <i className="icon_arrow_right_s" />
          </button>
        </div>
      </div>
      {list.length > 0 && (
        <>
          <div className="keyword_search_wrapper">
            <div className="keyword_header">
              <h2>
                "{companyName}"검색 결과 <span>{totalCount}</span>건
              </h2>
              <div className="util_btn">
                <select name="count" onChange={(e) => handleCountChange(e)}>
                  <option value={10}>10건 씩 보기</option>
                  <option value={20}>20건 씩 보기</option>
                  <option value={50}>50건 씩 보기</option>
                </select>
                <button onClick={() => sendData()}>보고서 작성하기</button>
                {/*<button onClick={downloadExcel}>*/}
                {/*  <i className="icon_download_attached" /> Excel 다운받기*/}
                {/*</button>*/}
              </div>
            </div>
            <table>
              <colgroup>
                <col width={50} />
                <col width={50} />
                <col width={150} />
                <col width={100} />
                <col width={150} />
                <col width={100} />
                <col width={500} />
                <col width={75} />
                <col width={70} />
                <col width={70} />
              </colgroup>
              <thead>
                <tr>
                  <th>번 호</th>
                  <th>국가</th>
                  <th>등록번호</th>
                  <th>등록일</th>
                  <th>출원번호</th>
                  <th>출원일</th>
                  <th style={{ textAlign: "left" }}>발명의 명칭</th>
                  <th>등급</th>
                  <th></th>
                  <th>{"특허보고서"}</th>
                </tr>
              </thead>
              <tbody>
                {list?.map((item, idx) => {
                  return (
                    <tr
                      style={{ opacity: item.isDeleted && "0.4" }}
                      onClick={() => {
                        history.push({
                          pathname: `patent/${
                            item.nation === "KR"
                              ? item.apply_number.replace(/-/g, "")
                              : item.document_number
                          }/detail?nation=${item.nation}`,
                          state: {
                            companyKeyword: searchInput,
                            companyCode: companyCode,
                            companyName: companyName,
                            corporateNumber: corporateNumber,
                            licenseNumber: licenseNumber,
                            isKeywordSearch: 3,
                          },
                        });
                        window.location.reload();
                      }}
                    >
                      <td>{(curPage - 1) * 10 + (idx + 1)}</td>
                      <td>{item.nation}</td>
                      <td>{item.register_number}</td>
                      <td>{window.$Global.convertDate(item.register_at)}</td>
                      <td>{item.apply_number}</td>
                      <td>{window.$Global.convertDate(item.apply_at)}</td>
                      <td style={{ textAlign: "left" }}>
                        {item.invention_title || item.invention_title_en}
                      </td>
                      <td
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          className={`circle grade_${
                            item.sm_grade || "default"
                          }`}
                        >
                          {item.sm_grade || "N"}
                        </div>
                      </td>
                      <td>
                        {(item.invention_title || item.invention_title_en) && (
                          <i
                            className="icon_arrow_right_s"
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </td>
                      <td>
                        {" "}
                        {(<ReportDummyPatentButton item={item} setIsReportShow={setIsReportShow} setAnnualFeeData={setAnnualFeeData} />)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Pagination curPage={curPage} lastNum={last} onClick={setCurPage} />
          <div className="research_util_btn">
            <button onClick={() => downloadExcel()}>
              <i className="icon_download_blue" />
              Excel로 다운받기
            </button>
          </div>
        </>
      )}
      {showPopup && (
        <ResearchTechAddPatent
          onChangeShow={setShowPopup}
          list={addPatentData}
          setList={setAddPatentData}
          setCurPage={setCurPage}
        />
      )}
      {isReportShow && (
          <AnnualFeeReport
              report_idx={annualFeeData.report_idx}
              annualFeeData={annualFeeData}
              setIsReportShow={setIsReportShow}
              family_idx={annualFeeData.family_idx}
              patent_idx={annualFeeData.patent_idx}
          />
      )}
    </div>
  );
};

export default ResearchTechApplicantSearch;
