import React from "react";
import UserAuthRoute from "./common/UserAuthRoute";
import CustomSwitch from "./common/CustomSwitch";
import HoldingsStatistic from "../components/groupware/HoldingsStatistic";

function HoldingsRoute({ match }) {
  return (
    <CustomSwitch>
      <UserAuthRoute
        exact
        path={`${match.path}/statistic`}
        requireAuth={true}
        component={HoldingsStatistic.HoldingsStatistic}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/project`}
        requireAuth={true}
        component={HoldingsStatistic.HoldingsProject}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/info`}
        requireAuth={true}
        component={HoldingsStatistic.HoldingsInfo}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/current`}
        requireAuth={true}
        component={HoldingsStatistic.HoldingsCurrentPage}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/invest`}
        requireAuth={true}
        component={HoldingsStatistic.HoldingsInvestPage}
      />
    </CustomSwitch>
  );
}

export default HoldingsRoute;
