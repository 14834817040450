import React from "react";
import UserAuthRoute from "./common/UserAuthRoute";
import CustomSwitch from "./common/CustomSwitch";
import InventorStepOne from "../components/inventor/InventorStepOne";
import InventorStepTwo from "../components/inventor/InventorStepTwo";
import InventorStepFour from "../components/inventor/InventorStepFour";
import InventorStepThree from "../components/inventor/InventorStepThree";
import InventorDone from "../components/inventor/InventorDone";
import InventorStepThreeDone from "../components/inventor/InventorStepThreeDone";
import InventorList from "../components/inventor/InventorList";
import InventorPatentList from "../components/inventor/InventorPatentList";

function InventorRoute({ match }) {
  return (
    <CustomSwitch>
      <UserAuthRoute
        exact
        path={`${match.path}/stepone`}
        requireAuth={true}
        component={InventorStepOne}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/steptwo`}
        requireAuth={true}
        component={InventorStepTwo}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/stepthree`}
        requireAuth={true}
        component={InventorStepThree}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/stepfour`}
        requireAuth={true}
        component={InventorStepFour}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/done`}
        requireAuth={true}
        component={InventorDone}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/stepthree/done`}
        requireAuth={true}
        component={InventorStepThreeDone}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/list`}
        requireAuth={true}
        component={InventorList}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/patent/list`}
        requireAuth={true}
        component={InventorPatentList}
      />
    </CustomSwitch>
  );
}

export default InventorRoute;
