import React, { useEffect, useState } from "react";
import "./css/ResearchTechPatentInfo.scss";
import { useHistory, useLocation, useParams } from "react-router-dom";
import CommonAPI from "../../API/common";
import _ from "lodash";
import ResearchTechFamilyView from "./ResearchTechFamilyView";
import useMoveScroll from "../../util/useMoveScroll";
import { useRecoilValue } from "recoil";
import { highlightText } from "../../atom";
import Highlighter from "react-highlight-words";
import qs from "query-string";
import axios from "axios";
import config from "../../const/config";
import logo from "../../assets/images/common/logo_findBiz.svg";
import PatentInfoKR from "./patentInfoForm/PatentInfoKR";
import PatentInfoUS from "./patentInfoForm/PatentInfoUS";
import CompetitorLoading from "../competitor/CompetitorLoading";
import MarketInfoMainItem from "../marketInfo/MarketInfoMainItem";
import MarketInfoPopupDetail from "../marketInfo/MarketInfoPopupDetail";
import Pagination from "../common/Pagination";

const ResearchTechPatentInfoIpnow = ({
  match,
  applyNum,
  nationParam,
  tabParam,
  type,
}) => {
  const params = useParams();
  const apply_number = params?.apply_number || applyNum.replace(/-/g, "");
  const history = useHistory();
  const location = useLocation();
  const [infoData, setInfoData] = useState({});
  const highlightKeyword = useRecoilValue(highlightText);
  const { nation = nationParam, tab = tabParam } = qs.parse(location.search);

  // const { corporateNumber, licenseNumber } = location.state;
  const [activeTab, setActiveTab] = useState(Number(tab) || 0); // 0번 탭을 초기 선택 상태로 설정
  const [companyList, setCompanyList] = useState([]);
  const [remainCompanyList, setRemainCompanyList] = useState([]);
  const [remainShow, setRemainShow] = useState(false);
  const [isCompanyListLoading, setIsCompanyListLoading] = useState(true);
  const [curPage, setCurPage] = useState(1);
  const [last, setLast] = useState(1);

  // Active tab 3번 시장정보에서 사용하기 위한 state 값들
  const [marketInfoList, setMarketInfoList] = useState({ items: [] });
  const [popupType, setPopupType] = useState("graph"); // graph, content
  const [popupInfo, setPopupInfo] = useState({});
  const [popupShow, setPopupShow] = useState(false);
  // 시장정보 검색 키워드 값
  const [marketInfoKeyword, setMarketInfoKeyword] = useState(
    highlightKeyword.join(" ")
  );
  const [showTextBox, setShowTextBox] = useState(true);
  let index = 0;
  //console.log(highlightKeyword);
  // useEffect(() => {
  //   getCompanyList();
  // }, []);

  useEffect(() => {
    getPatentInfo();
  }, [location.state]);

  useEffect(() => {
    if (activeTab === 3) {
      getMarketList();
    }
  }, [activeTab, curPage]);

  const getMarketList = () => {
    let params = {
      page: curPage,
      count: 25,
      keyword: marketInfoKeyword,
      // category1_idx: category_idx1,
      // category2_idx: category_idx2,
      // category3_idx: category3_idx || selectIdx,
    };
    CommonAPI.getMarketList(params).then((res) => {
      setMarketInfoList(res.data);
      setLast(res.data.last);
    });
  };

  const getCompanyList = async (ipc) => {
    await axios
      .get("https://biznavi.co.kr/api/v3/lcompany", {
        params: {
          // search: companyKeyword,
          ipc: ipc,
        },
      })
      .then((res) => {
        console.log(res.data);
        setIsCompanyListLoading(false);
        setCompanyList(res.data);
      });
  };

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const getPatentInfo = () => {
    CommonAPI.getPatentInfo(apply_number, { nation: nation }).then((res) => {
      setInfoData(res.data);
      getCompanyList(res.data.ipcInfoList);
    });
  };

  // const getSmart5Url = (type) => {
  //   const params = {
  //     nation: nation,
  //     ...(apply_number && { apply_number: apply_number }),
  //     ...(infoData.register_number && {
  //       register_number: infoData.register_number,
  //     }),
  //   };
  //
  //   const analysisParams = {
  //     ...(corporateNumber && { corporate_number: corporateNumber }),
  //     ...(licenseNumber && { license_number: licenseNumber }),
  //   };
  //   if (type === 1) {
  //     CommonAPI.getSmart5URL(params).then((res) => {
  //       window.open(res.data.smart5Url, "_blank", "width=560,height=550");
  //     });
  //   } else if (type === 2) {
  //     CommonAPI.getSmart5AnalysisURL(analysisParams).then((res) => {
  //       window.open(res.data.smart5Url, "_blank", "width=560,height=550");
  //     });
  //   }
  // };

  const claimTabs = {
    0: useMoveScroll("대표청구항"),
    1: useMoveScroll("독립항"),
    2: useMoveScroll("종속항"),
    3: useMoveScroll("삭제청구항"),
    length: 4,
  };

  const convertClaimType = (type) => {
    let str = "";
    switch (type) {
      case 0:
        str = "대표청구항";
        break;
      case 1:
        str = "독립항";
        break;
      case 2:
        str = "종속항";
        break;
      case 3:
        str = "삭제청구항";
        break;
    }
    return str;
  };

  const handleNavigate = (item, type) => {
    console.log(item);
    if (item.info.company_bizno) {
      if (type === "biznavi") {
        history.push(
          `/competitor/details?applicant=${item.info.applicant_code}&bizno=${item.info.company_bizno}`
        );
      } else {
        history.push(
          `/competitor/ipnow/details?applicant=${item.info.applicant_code}&bizno=${item.info.company_bizno}`
        );
      }
    } else {
      alert("분석 정보를 확인할 수 없는 회사입니다.");
      return;
    }
  };

  const onClickShowPopup = (item, type) => {
    setPopupShow(true);
    setPopupInfo(item);
    setPopupType(type);
  };

  const downloadReport = () => {
    if (marketInfoKeyword) {
      CommonAPI.downloadMarketReport({ keyword: marketInfoKeyword });
    } else {
      alert("키워드를 입력해주세요.");
      return;
    }
  };

  return (
    !_.isEmpty(infoData) && (
      <div id="ResearchTechPatentInfo">
        <header>
          <p>{infoData.bibliographyInfo.invention_name}</p>
          <p>출원번호 {infoData.bibliographyInfo.apply_number}</p>
        </header>
        <div className="info_tab">
          <div>
            <p
              className={activeTab === 0 && "active"}
              onClick={() => handleTabClick(0)}
            >
              특허정보
            </p>
            <p
              className={activeTab === 1 && "active"}
              onClick={() => handleTabClick(1)}
            >
              명세서
            </p>
            {/* {companyCode && ( */}
            <p
              className={activeTab === 2 && "active"}
              onClick={() => handleTabClick(2)}
            >
              연관기업
            </p>
            {/* )} */}
            <p
              className={activeTab === 3 && "active"}
              onClick={() => handleTabClick(3)}
            >
              시장정보
            </p>
          </div>
          {/*<div>*/}
          {/*  <button onClick={() => getSmart5Url(1)}>평가 결과</button>*/}
          {/*  {(corporateNumber || licenseNumber) && (*/}
          {/*    <button onClick={() => getSmart5Url(2)}>기업별 분석</button>*/}
          {/*  )}*/}
          {/*</div>*/}
        </div>
        {activeTab === 0 && (
          <div className="patent_info_items">
            <div className="patent_info_grid">
              <h5>서지사항</h5>
              <div>
                <div>
                  <p style={{ color: "#4593f5" }}>특허상태</p>
                  <p>{infoData.bibliographyInfo.register_detail}</p>
                </div>
                <div>
                  <p>최종심사단계</p>
                  <p>{infoData.bibliographyInfo.final_disposition_detail}</p>
                </div>
                <div>
                  <p>출원번호</p>
                  <p>{infoData.bibliographyInfo.apply_number}</p>
                </div>
                <div>
                  <p>등록번호</p>
                  <p>{infoData.bibliographyInfo.register_number}</p>
                </div>
                <div>
                  <p>공개번호</p>
                  <p>{infoData.bibliographyInfo.open_number}</p>
                </div>
                <div>
                  <p>출원인</p>
                  <p>{infoData.applicant}</p>
                </div>
                <div>
                  <p>발명자</p>
                  <p>{infoData.inventor}</p>
                </div>
                <div>
                  <p>IPC</p>
                  <p>
                    {infoData.ipcInfoList.map((item) => {
                      return <span>{item};</span>;
                    })}
                  </p>
                </div>
                <div>
                  <p>CPC</p>
                  <p>
                    {infoData.cpcInfoList.map((item) => {
                      return <span>{item};</span>;
                    })}
                  </p>
                </div>
              </div>
            </div>
            <div className="patent_idea">
              <h5>기술 요지</h5>
              <div>
                <p style={{ color: "#4593f5" }}>요약</p>
                <p>{infoData.abstract}</p>
              </div>
              <div>
                <p>대표 청구항</p>
                <p>
                  {infoData.claimList.map((item) => {
                    if (item.type === 0) {
                      return (
                        <>
                          <span>청구항 {item.num}항</span>
                          <br />
                          <br />
                          <span>{item.text}</span>
                          <br />
                          <br />
                        </>
                      );
                    }
                  })}
                </p>
              </div>
            </div>
            <div>
              <h5>패밀리</h5>
              <ResearchTechFamilyView
                familyData={infoData.familyList}
                state={location.state}
              />
            </div>
          </div>
        )}
        {activeTab === 1 && (
          <div className="patent_spec">
            {nation === "KR" ? (
              <PatentInfoKR
                infoData={infoData}
                highlightKeyword={highlightKeyword}
              />
            ) : nation === "US" ? (
              <PatentInfoUS
                infoData={infoData}
                highlightKeyword={highlightKeyword}
              />
            ) : null}
            <div>
              <h5>청구항</h5>
              <div className="spec_tab">
                {Array.from(claimTabs).map((tab, index) => {
                  return <div onClick={tab.onMoveToElement}>{tab.name}</div>;
                })}
              </div>
              <div className="patent_spec_items">
                {infoData.claimList
                  ?.sort((a, b) => {
                    if (a.type > b.type) {
                      return 1;
                    }
                    if (a.type < b.type) {
                      return -1;
                    }
                    return 0;
                  })
                  .map((item, idx) => {
                    return (
                      <>
                        {idx === 0 && (
                          <p ref={claimTabs[index++].element}>
                            {convertClaimType(item.type)}
                          </p>
                        )}
                        {idx !== 0 &&
                          item.type !== infoData.claimList[idx - 1].type && (
                            <p ref={claimTabs[index++].element}>
                              {convertClaimType(item.type)}
                            </p>
                          )}
                        <div>
                          <p>청구항 {item.num}항</p>
                          <p>
                            <Highlighter
                              searchWords={highlightKeyword}
                              autoEscape={true}
                              textToHighlight={item.text}
                            />
                            <br />
                            <br />
                          </p>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
        {activeTab === 2 ? (
          isCompanyListLoading ? (
            <CompetitorLoading />
          ) : (
            <div className="company_info_wrapper">
              <div>
                <div className="company_info_items_ipnow custom_scroll">
                  {companyList["normal"]?.slice(0, 30).map((item) => {
                    return (
                      <div className="company_info_item">
                        <img src={logo} />
                        <div className="item_text">
                          <p>{item.info.company_name}</p>
                          <p>
                            대표명: {item.info?.company_ceo} / 주소:{" "}
                            {item.info?.company_addr} / 매출액(2022) :{" "}
                            {window.$Global.commaify(
                              (item.finance || {})[2022]?.enpSaleAmt?.val ||
                                "ㅡ"
                            )}
                          </p>
                          <div className="item_text_category">
                            {item.info.grouptags.map((keyword) => {
                              return <p>{keyword.company_group_name}</p>;
                            })}
                          </div>
                        </div>
                        <button onClick={() => handleNavigate(item, type)}>
                          기업 분석정보
                        </button>
                      </div>
                    );
                  })}
                  {remainShow &&
                    companyList["normal"]
                      ?.slice(30, companyList["normal"].length)
                      .map((item) => {
                        return (
                          <div className="company_info_item">
                            <img src={logo} />
                            <div className="item_text">
                              <p>{item.info.company_name}</p>
                              <p>
                                대표명: {item.info?.company_ceo} / 주소:{" "}
                                {item.info?.company_addr} / 매출액(2022) :{" "}
                                {window.$Global.commaify(
                                  (item.finance || {})[2022]?.enpSaleAmt?.val ||
                                    "ㅡ"
                                )}
                              </p>
                              <div className="item_text_category">
                                {item.info.grouptags.map((keyword) => {
                                  return <p>{keyword.company_group_name}</p>;
                                })}
                              </div>
                            </div>
                            <button onClick={() => handleNavigate(item, type)}>
                              기업 분석정보
                            </button>
                          </div>
                        );
                      })}
                  <div
                    className="more_company_btn"
                    onClick={() => setRemainShow(true)}
                  >
                    더 보기
                    <i className="icon_more_arrow_down_black" />
                  </div>
                </div>
              </div>
            </div>
          )
        ) : null}
        {activeTab === 3 ? (
          <>
            <div className="input_wrapper">
              <p>키워드 검색</p>
              <div className="search">
                <input
                  type="text"
                  placeholder="검색어를 입력하세요"
                  value={marketInfoKeyword}
                  onChange={(e) => setMarketInfoKeyword(e.target.value)}
                  onMouseOver={() => setShowTextBox(false)}
                  onKeyUp={(e) => e.key === "Enter" && getMarketList()}
                />
                {/* {marketInfoKeyword.length > 0 ? (
                  <button
                    className="icon_del_small_with_bg"
                    onClick={() => setMarketInfoKeyword("")}
                  />
                ) : ( */}
                <button
                  className="icon_search_gray ir_txt"
                  onClick={getMarketList}
                >
                  검색
                </button>
                {/* )} */}
              </div>
              {/* <button
                className="btn_marketinfo_report"
                onClick={downloadReport}
              >
                <i className="icon_download_attached" />
                시장정보 보고서 다운로드
              </button> */}
            </div>
            <div className="text_box">
              <p>
                <i className="icon_info_with_bg" />
                연관키워드로 시장검색 해보세요.
              </p>
              <p>
                [오픈예정] 해당 특허와 관련된 시장정보가 자동으로 검색되는
                시스템이 오픈 예정입니다.
              </p>
            </div>
            <div className="marketInfoMain_list">
              {marketInfoList.items.length ? (
                marketInfoList.items.map((item) => {
                  let hasContent = Boolean(
                    item.content || item.content_image_list.length
                  );
                  return (
                    <div className="marketInfoMain_item">
                      <img
                        src={window.$Global.getCDN(item.image_key)}
                        alt="이미지"
                        onContextMenu={window.$Global.blockRightClick}
                        onClick={() => onClickShowPopup(item, "graph")}
                      />
                      <div className="info">
                        <h2>{item.title}</h2>
                      </div>
                      <div className="etc">
                        <div className="etc_btn">
                          {hasContent && (
                            <button
                              onClick={() => onClickShowPopup(item, "content")}
                              className="content_btn"
                            >
                              관련글 보기
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>조회된 데이터가 없습니다</p>
              )}
            </div>
            <Pagination curPage={curPage} lastNum={last} onClick={setCurPage} />
          </>
        ) : null}
        <MarketInfoPopupDetail
          show={popupShow}
          type={popupType}
          item={popupInfo}
          onClose={() => setPopupShow(false)}
        />
      </div>
    )
  );
};

export default ResearchTechPatentInfoIpnow;
