import React from "react";
import TradeMarkList from "../components/trademark/TradeMarkListView";
import MTradeMarkListView from "../components/trademark/mobile/MTradeMarkListView";
import UserAuthRoute from "./common/UserAuthRoute";
import MTradeMarkDetail from "../components/trademark/mobile/MTradeMarkDetail";
import TradeMarkDetail from "../components/trademark/TradeMarkDetail";
import TradeMarkRelativeFileListView from "../components/trademark/relativeFile/RelativeFileListView";
import CustomSwitch from "./common/CustomSwitch";
import CacheRoute from "react-router-cache-route";
import PatentList from "../components/patent/PatentListView";
import MPatentListView from "../components/patent/mobile/MPatentListView";

function TradeMarkRoute({match}) {
	return (
		<CustomSwitch>
			<CacheRoute exact path={`${match.path}/list`}
						render={(props) => window.$Global.renderFunc(props, TradeMarkList)}
						when="always"
			/>
			<CacheRoute exact path={`${match.path}/mlist`}
						render={(props) => window.$Global.renderFunc(props, MTradeMarkListView)}
						when="always"
			/>
			<UserAuthRoute exact path={`${match.path}/mobile/:trademark_idx`} requireAuth={true} component={MTradeMarkDetail}/>
			<UserAuthRoute exact path={`${match.path}/detail/:trademark_idx`} requireAuth={true} component={TradeMarkDetail}/>
			<UserAuthRoute exact path={`${match.path}/relativeFile/:trademark_idx`} requireAuth={true} component={TradeMarkRelativeFileListView}/>

		</CustomSwitch>
	)
}

export default TradeMarkRoute;