import GroupwareSideBar from "../GroupwareSideBar";
import { groupCashflow } from "../groupwarePath";
import Top from "./Top";
import TopBanner from "./TopBanner";
import GwAPI from "../../../API/groupware";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import BizActParent from "./BizActParent";
import { useEffect, useState } from "react";
import BizActChild from "./BizActChild";
import "./scss/GroupwareCashflow.scss";
import StepBtns from "./StepBtns";
import CashflowStep from "./CashflowStep";

const BizAct = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let history = useHistory();
  const [isIncome, setIsIncome] = useState(
    Number(searchParams.get("isIncome"))
  ); // 지출, 수입여부
  const [generatedReportId, setGeneratedReportId] = useState(
    Number(searchParams.get("report_id"))
  ); // 레포트 아이디
  const [recentReportId, setRecentReportId] = useState(
    Number(searchParams.get("recent_id"))
  ); // 가장 최근에 생성된 리포트 아이디
  const [type, setType] = useState(Number(searchParams.get("type"))); // 타입 ex) 영업, 재무..
  const [both, setBoth] = useState(Number(searchParams.get("both"))); // 지출, 수입 둘 다 일때
  const [stepNum, setStepNum] = useState(Number(searchParams.get("step"))); // 단계
  const [lastCompleted, setLastCompleted] = useState(0);
  const [today, setToday] = useState({});
  const [reportMon, setReportMon] = useState(0); // 레포트 기준 날짜
  const [cashIdNum, setCashIdNum] = useState(1);
  const [cashOtherIdNum, setCashOtherIdNum] = useState(1);
  const [reset, setReset] = useState(false);
  const [prev, setPrev] = useState(
    `/groupware/cashflow/balanceIns?report_id=${generatedReportId}`
  ); // BACK url
  const [next, setNext] = useState(
    `/groupware/cashflow/bizAct?isIncome=0&type=1&step=3&report_id=${generatedReportId}`
  ); // NEXT url
  const step = "step";
  const [end, setEnd] = useState(0);
  const [reportData, setReportData] = useState({
    id: generatedReportId,
    title: today, // 오늘 날짜를 기본으로 설정
    year: null,
    period: today,
    status: 0,
    is_completed: end,
    is_deleted: 0,
    update_type: type,
  });
  const [cashItems, setCashItems] = useState([
    {
      id: `cash_${cashIdNum}`,
      category: {
        category_name: "", // 카테고리명
        type: type,
        is_income: isIncome,
      },
      cash_data: {
        jan: 0,
        feb: 0,
        mar: 0,
        apr: 0,
        may: 0,
        jun: 0,
        jul: 0,
        aug: 0,
        sep: 0,
        oct: 0,
        nov: 0,
        dec: 0,
      },
    },
  ]); // cashItem : isIncome에 따른 아이템 변수

  const [cashOtherItems, setCashOtherItems] = useState([
    {
      id: `cash_other_${cashOtherIdNum}`,
      category: {
        category_name: "", // 카테고리명
        type: type,
        is_income: isIncome === 1 ? 0 : 1,
      },
      cash_data: {
        jan: 0,
        feb: 0,
        mar: 0,
        apr: 0,
        may: 0,
        jun: 0,
        jul: 0,
        aug: 0,
        sep: 0,
        oct: 0,
        nov: 0,
        dec: 0,
      },
    },
  ]); // cashOtherItem : 지출, 수입 둘 다 일때 cashItem 반대 아이템 변수 (ex:cashItem:수입 -> cashOtherItems: 지출)
  const [monthSum, setMonthSum] = useState([
    { 1: "jan", value: 0 },
    { 2: "feb", value: 0 },
    { 3: "mar", value: 0 },
    { 4: "apr", value: 0 },
    { 5: "may", value: 0 },
    { 6: "jun", value: 0 },
    { 7: "jul", value: 0 },
    { 8: "aug", value: 0 },
    { 9: "sep", value: 0 },
    { 10: "oct", value: 0 },
    { 11: "nov", value: 0 },
    { 12: "dec", value: 0 },
  ]);

  const [monthOtherSum, setMonthOtherSum] = useState([
    { 1: "jan", value: 0 },
    { 2: "feb", value: 0 },
    { 3: "mar", value: 0 },
    { 4: "apr", value: 0 },
    { 5: "may", value: 0 },
    { 6: "jun", value: 0 },
    { 7: "jul", value: 0 },
    { 8: "aug", value: 0 },
    { 9: "sep", value: 0 },
    { 10: "oct", value: 0 },
    { 11: "nov", value: 0 },
    { 12: "dec", value: 0 },
  ]);
  const [sumTrigger, setSumTrigger] = useState(false);
  const [mod, setMod] = useState(Number(searchParams.get("mod"))); // 수정페이지

  const types = ["", "영업", "재무", "투자"];
  const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  useEffect(() => {
    setReportData((prevState) => ({
      ...prevState,
      is_completed: lastCompleted,
    }));
  }, [lastCompleted]);

  useEffect(() => {
    const today = getToday();

    setToday(today);
    setReportMon(today.month);

    if (type === null || type === undefined) {
      alert("타입이 지정되지 않았습니다.");
      history.push("/groupware/cashflow/landing");
    }

    if (isIncome === null || isIncome === undefined) {
      alert("지출, 수입이 정해지지 않았습니다.");
      history.push("/groupware/cashflow/landing");
    }

    setReportData(() => ({
      report_id: generatedReportId,
      year: today.year,
      title: today.formattedDate,
      period: today.formattedDate,
      status: 0,
      is_completed: end,
      is_deleted: 0,
      update_type: type,
    }));

    resetIds();
    resetData();
    if (mod !== 1) {
      if (recentReportId !== 0) {
        setCashflowDataInfo(recentReportId);
      }
    } else if (mod === 1) {
      setCashflowDataInfo(generatedReportId);
    }

    if (stepNum === 5) {
      setEnd(1);
    }
  }, []);

  useEffect(() => {
    setReset(true); // ture면 리셋
    //setCashIdNum((prev) => prev + 1);
    //setCashOtherIdNum((prev) => prev + 1);
    resetIds();
    resetData();
    setIsIncome(Number(searchParams.get("isIncome")));
    setType(Number(searchParams.get("type")));
    setBoth(Number(searchParams.get("both")));
    setMod(Number(searchParams.get("mod")));
    setRecentReportId(Number(searchParams.get("recent_id")));
    setStepNum(Number(searchParams.get("step")));
  }, [location.search]);

  useEffect(() => {
    if (reset) {
      resetIds();
      setReset(false);
      if (mod !== 1) {
        if (recentReportId !== 0) {
          setCashflowDataInfo(recentReportId);
        }
      } else if (mod === 1) {
        setCashflowDataInfo(generatedReportId);
      }
    }
  }, [stepNum]);

  useEffect(() => {
    if (stepNum === 2) {
      if (mod) {
        setNext(
          `/groupware/cashflow/bizAct?isIncome=0&type=1&step=3&report_id=${generatedReportId}&mod=1`
        );
      } else {
        setNext(
          `/groupware/cashflow/bizAct?isIncome=0&type=1&step=3&report_id=${generatedReportId}&recent_id=${recentReportId}`
        );
      }
      setPrev(
        `/groupware/cashflow/balanceIns?report_id=${generatedReportId}&mod=1`
      );
      setEnd(0);
    } else if (stepNum === 3) {
      if (mod) {
        setNext(
          `/groupware/cashflow/bizAct?isIncome=1&type=2&step=4&both=1&report_id=${generatedReportId}&mod=1`
        );
      } else {
        setNext(
          `/groupware/cashflow/bizAct?isIncome=1&type=2&step=4&both=1&report_id=${generatedReportId}&recent_id=${recentReportId}`
        );
      }
      setPrev(
        `/groupware/cashflow/bizAct?isIncome=1&type=1&step=2&report_id=${generatedReportId}&mod=1`
      );
      setEnd(0);
    } else if (stepNum === 4) {
      if (mod) {
        setNext(
          `/groupware/cashflow/bizAct?isIncome=1&type=3&step=5&both=1&report_id=${generatedReportId}&mod=1`
        );
      } else {
        setNext(
          `/groupware/cashflow/bizAct?isIncome=1&type=3&step=5&both=1&report_id=${generatedReportId}&recent_id=${recentReportId}`
        );
      }
      setPrev(
        `/groupware/cashflow/bizAct?isIncome=0&type=1&step=3&report_id=${generatedReportId}&mod=1`
      );
      setEnd(0);
    } else if (stepNum === 5) {
      setNext("/groupware/cashflow/history");
      setPrev(
        `/groupware/cashflow/bizAct?isIncome=1&type=2&step=4&both=1&report_id=${generatedReportId}&mod=1`
      );
      setEnd(1);
    }

    if (mod === 1) {
      setCashItems([]);
      setCashOtherItems([]);
      setCashflowDataInfo(generatedReportId);
    }

    if (stepNum === 5) {
      setEnd(1);
    }
  }, [stepNum]);

  useEffect(() => {
    monthlySums();
    // if (sumTrigger) {
    //   monthlySums();
    // }
    // setSumTrigger(false);
  }, [cashItems, cashOtherItems]);

  const updateCashData = (id, newCashData, part, other) => {
    // 캐시 데이터 업데이트 로직
    if (part === 1) {
      const processedCashData = {};

      // newCashData의 모든 키에 대해 반복
      Object.keys(newCashData).forEach((key) => {
        const value = newCashData[key];
        // 값이 숫자인지 문자열인지 확인
        if (typeof value === "string") {
          // 문자열에서 콤마 제거 후 숫자로 변환
          processedCashData[key] = parseInt(value.replace(/,/g, ""), 10);
        } else {
          // 숫자인 경우 그대로 유지
          processedCashData[key] = value;
        }
      });

      // 캐시 데이터 업데이트 로직
      if (other) {
        setCashOtherItems((currentItems) =>
          currentItems.map((item) =>
            item.id === id ? { ...item, cash_data: processedCashData } : item
          )
        );
      } else {
        setCashItems((currentItems) =>
          currentItems.map((item) =>
            item.id === id ? { ...item, cash_data: processedCashData } : item
          )
        );
      }
    } else {
      // 카테고리 업데이트 로직
      if (other) {
        setCashOtherItems((currentItems) =>
          currentItems.map((item) =>
            item.id === id ? { ...item, category: newCashData } : item
          )
        );
      } else {
        setCashItems((currentItems) =>
          currentItems.map((item) =>
            item.id === id ? { ...item, category: newCashData } : item
          )
        );
      }
    }
  };

  const addCashItem = (other) => {
    // other: cashOtherItem 여부
    if (other) {
      const newItem = {
        id: `cash_other_${cashOtherIdNum + 1}`,
        category: {
          category_name: "", // 카테고리명
          type: type,
          is_income: isIncome === 1 ? 0 : 1,
        },
        cash_data: {
          jan: 0,
          feb: 0,
          mar: 0,
          apr: 0,
          may: 0,
          jun: 0,
          jul: 0,
          aug: 0,
          sep: 0,
          oct: 0,
          nov: 0,
          dec: 0,
        },
      };
      setCashOtherItems([...cashOtherItems, newItem]);
      setCashOtherIdNum((prev) => prev + 1);
    } else {
      const newItem = {
        id: `cash_${cashIdNum + 1}`,
        category: {
          category_name: "", // 카테고리명
          type: type,
          is_income: isIncome,
        },
        cash_data: {
          jan: 0,
          feb: 0,
          mar: 0,
          apr: 0,
          may: 0,
          jun: 0,
          jul: 0,
          aug: 0,
          sep: 0,
          oct: 0,
          nov: 0,
          dec: 0,
        },
      };
      setCashItems([...cashItems, newItem]);
      setCashIdNum((prev) => prev + 1);
    }
  };

  const deleteCashItem = (id) => {
    setCashItems(cashItems.filter((item) => item.id !== id));
  };

  const deleteCashOtherItem = (id) => {
    setCashOtherItems(cashOtherItems.filter((item) => item.id !== id));
  };

  const resetData = () => {
    setCashItems([
      {
        id: `cash_${cashIdNum}`,
        category: {
          category_name: "", // 카테고리명
          type: type,
          is_income: isIncome,
        },
        cash_data: {
          jan: 0,
          feb: 0,
          mar: 0,
          apr: 0,
          may: 0,
          jun: 0,
          jul: 0,
          aug: 0,
          sep: 0,
          oct: 0,
          nov: 0,
          dec: 0,
        },
      },
    ]);

    setCashOtherItems([
      {
        id: `cash_other_${cashOtherIdNum}`,
        category: {
          category_name: "", // 카테고리명
          type: type,
          is_income: isIncome === 1 ? 0 : 1,
        },
        cash_data: {
          jan: 0,
          feb: 0,
          mar: 0,
          apr: 0,
          may: 0,
          jun: 0,
          jul: 0,
          aug: 0,
          sep: 0,
          oct: 0,
          nov: 0,
          dec: 0,
        },
      },
    ]);
  };

  const resetIds = () => {
    setCashIdNum((prev) => prev + 1);
    setCashOtherIdNum((prev) => prev + 1);
  };

  const getToday = () => {
    const today = new Date();

    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    const formattedDate = `${year}-${month}-${day}`;

    setReportData((prevState) => ({
      ...prevState,
      year: year,
    }));

    return { formattedDate, year, month, day };
  };

  const createCashflow = (data) => {
    return GwAPI.createCashflow(data)
      .then((res) => {
        //console.log("입력된 데이터:", data);
        //console.log("RES::", res);
        if (res.data?.code === 200 && res.data?.report_id) {
          setGeneratedReportId(res.data.report_id);
          return res.data?.report_id;
        } else {
          alert("데이터 입력을 실패했습니다");
          return false;
        }
      })
      .catch((error) => {
        console.error("Create cashflow failed:", error);
        alert("데이터 입력 중 오류가 발생했습니다");
        return false;
      });
  };

  const updateCashflow = (data) => {
    //console.log("mod data::", data);
    return GwAPI.updateCashflow(data)
      .then((res) => {
        //console.log("res mod::", res);
        if (res.status === 200) {
          return true;
        } else {
          alert("레포트 수정을 실패했습니다");
          return false;
        }
      })
      .catch((error) => {
        console.error("Update cashflow failed:", error);
        alert("레포트 수정 중 오류가 발생했습니다");
        return false;
      });
  };

  const tempSave = async () => {
    let bodyData = {};
    let result = 0;

    if (both === 1) {
      bodyData = {
        report: reportData,
        data: [...cashItems, ...cashOtherItems],
      };
    } else {
      bodyData = {
        report: reportData,
        data: cashItems,
      };
    }
    //if (generatedReportId) {
    if (mod === 1) {
      result = await updateCashflow(bodyData);
    } else {
      result = await createCashflow(bodyData);
    }
    alert("저장되었습니다.");
    return result;
  };

  const getCashflow = (data) => {
    return GwAPI.getCashflow(data)
      .then((res) => {
        //console.log("res get::", res);
        if (res.status === 200) {
          if (res.data?.CashFlowData) {
            const report_mon =
              new Date(res.data?.CashFlowData.report.reg_date).getMonth() + 1;
            setReportMon(report_mon);
            return res.data?.CashFlowData;
          } else {
            return null;
          }
        } else {
          alert("레포트 가져오기 중 오류가 발생했습니다.");
          return false;
        }
      })
      .catch((error) => {
        console.error("Fetching cashflow failed:", error);
        alert("레포트 가져오기 중 오류가 발생했습니다");
        return false;
      });
  };

  const setCashflowDataInfo = async (id) => {
    const reportData = await getCashflow({
      report_id: id,
      type: type,
    });
    //console.log("reportData::", reportData);
    if (mod) {
      if (reportData?.report) {
        setReportData({
          ...reportData?.report,
          ...(stepNum === 5
            ? { is_completed: 1 }
            : { is_completed: reportData?.report.is_completed }),
          update_type: type,
        });
      }
    }
    if (stepNum === 5) {
      setEnd(1);
      setReportData((prevState) => ({
        ...prevState,
        is_completed: 1,
      }));
    }

    let newCashItems = [];
    let newCashOtherItems = [];
    let cashCount = cashIdNum + 1;
    let cashOtherCount = cashOtherIdNum + 1;

    if (reportData?.data.length > 0) {
      reportData?.data.forEach((data) => {
        if (both === 1) {
          if (data.category.is_income === 1) {
            let updatedCashData = {
              ...data,
              id: `cash_${cashCount}`,
              cash_data: {
                ...data.cash_data,
              },
            };
            newCashItems.push(updatedCashData);
            //console.log("newCashItems-1:", newCashItems);
          } else {
            let updatedCashOtherData = {
              ...data,
              id: `cash_other_${cashOtherCount}`,
              cash_data: {
                ...data.cash_data,
              },
            };
            newCashOtherItems.push(updatedCashOtherData);
            //console.log("newCashOtherItems:", newCashOtherItems);
          }
        } else {
          if (isIncome === 1) {
            if (data.category.is_income === 1) {
              let updatedCashData = {
                ...data,
                id: `cash_${cashCount}`,
                cash_data: {
                  ...data.cash_data,
                },
              };
              newCashItems.push(updatedCashData);
              //console.log("newCashItems:-2", newCashItems);
            }
          } else {
            if (data.category.is_income === 0) {
              let updatedCashData = {
                ...data,
                id: `cash_${cashCount}`,
                cash_data: {
                  ...data.cash_data,
                },
              };
              newCashItems.push(updatedCashData);
              //console.log("newCashItems-3:", newCashItems);
            }
          }
        }
        cashCount++;
        cashOtherCount++;
      });
    }

    if (newCashItems.length === 0) {
      newCashItems = [
        {
          id: `cash_${cashIdNum}`,
          category: {
            category_name: "", // 카테고리명
            type: type,
            is_income: isIncome,
          },
          cash_data: {
            jan: 0,
            feb: 0,
            mar: 0,
            apr: 0,
            may: 0,
            jun: 0,
            jul: 0,
            aug: 0,
            sep: 0,
            oct: 0,
            nov: 0,
            dec: 0,
          },
        },
      ];
    }

    if (newCashOtherItems.length === 0) {
      newCashOtherItems = [
        {
          id: `cash_other_${cashOtherIdNum}`,
          category: {
            category_name: "", // 카테고리명
            type: type,
            is_income: isIncome === 1 ? 0 : 1,
          },
          cash_data: {
            jan: 0,
            feb: 0,
            mar: 0,
            apr: 0,
            may: 0,
            jun: 0,
            jul: 0,
            aug: 0,
            sep: 0,
            oct: 0,
            nov: 0,
            dec: 0,
          },
        },
      ];
    }
    //console.log("=======newCashItems======", newCashItems);
    setCashItems(newCashItems);
    setCashOtherItems(newCashOtherItems);
    setCashIdNum(cashCount);
    setCashOtherIdNum(cashOtherCount);

    return reportData;
  };
  const goNext = async () => {
    const result = await tempSave();
    if (!result) {
      alert("생성된 레포트가 없습니다.");
    } else {
      history.push(next);
    }
  };

  const goPrev = async () => {
    const result = await tempSave();
    if (!result) {
      alert("생성된 레포트가 없습니다.");
    } else {
      history.push(prev);
    }
  };

  const monthlySums = () => {
    const monthNames = [
      "jan",
      "feb",
      "mar",
      "apr",
      "may",
      "jun",
      "jul",
      "aug",
      "sep",
      "oct",
      "nov",
      "dec",
    ];

    // 콤마 추가
    const formatNumber = (number) => {
      return new Intl.NumberFormat().format(number);
    };

    const newMonths = monthNames.map((monthName) => ({
      month: monthName,
      value: formatNumber(
        cashItems.reduce((sum, item) => {
          // 먼저 null 체크 후 문자열로 변환, 이후 콤마 제거
          const cashValueRaw =
            item.cash_data[monthName] == null
              ? "0"
              : item.cash_data[monthName].toString();
          // 문자열에서 콤마를 제거하고 숫자로 변환
          const cashValue = Number(cashValueRaw.replace(/,/g, ""));
          return sum + (isNaN(cashValue) ? 0 : cashValue); // 숫자가 아닌 경우 0을 더함
        }, 0)
      ),
    }));

    const newOtherMonths = monthNames.map((monthName) => ({
      month: monthName,
      value: formatNumber(
        cashOtherItems.reduce((sum, item) => {
          // 먼저 null 체크 후 문자열로 변환, 이후 콤마 제거
          const cashValueRaw =
            item.cash_data[monthName] == null
              ? "0"
              : item.cash_data[monthName].toString();
          // 문자열에서 콤마를 제거하고 숫자로 변환
          const cashValue = Number(cashValueRaw.replace(/,/g, ""));
          return sum + (isNaN(cashValue) ? 0 : cashValue); // 숫자가 아닌 경우 0을 더함
        }, 0)
      ),
    }));

    //setSumTrigger(true);
    setMonthSum(newMonths);
    setMonthOtherSum(newOtherMonths);
  };

  return (
    <div id="GroupwareWorks">
      <GroupwareSideBar currentPath={groupCashflow} />
      <div className="commute_wrapper">
        <div id="GroupwareCashflow">
          <TopBanner />
          <Top page={step} />
          <CashflowStep step={stepNum} />
          <div className="step_cont">
            <div className="wrap bg_wh rounded">
              <div className="tit_box">
                <h4 className="blue">
                  STEP {stepNum}. {types[type]}
                  {!both ? (isIncome === 1 ? "수입" : "지출") : ""}
                </h4>
                <button className="temp_save blue_bg" onClick={tempSave}>
                  임시 저장하기
                </button>
              </div>
              <strong className="desc">
                {types[type]}활동을 작성하세요 <span>단위: 천원</span>
              </strong>
              <div className="part_box">
                <div className="top bg_gray">
                  <div className="label"></div>
                  <ul>
                    {months.map((month) =>
                      Number(reportMon) + Number(month) > 12 ? (
                        <li>{Number(reportMon) + Number(month) - 12}월</li>
                      ) : (
                        <li>{Number(reportMon) + Number(month)}월</li>
                      )
                    )}
                  </ul>
                </div>
                <div className="item_box">
                  <BizActParent
                    isIncome={isIncome}
                    addCashItem={() => addCashItem(false)}
                    monthSum={monthSum}
                  />
                </div>
                <div className="child_box">
                  {cashItems.map((item) => (
                    <BizActChild
                      key={item.id}
                      id={item.id}
                      category={item.category}
                      cash_data={item.cash_data}
                      onUpdateCashData={(newCashData) =>
                        updateCashData(item.id, newCashData, 1, false)
                      }
                      onUpdateCategoryData={(newCategoryData) => {
                        updateCashData(item.id, newCategoryData, 0, false);
                      }}
                      deleteCashItem={deleteCashItem}
                    />
                  ))}
                </div>
                <div className="btn_list act">
                  <div className="box">
                    <button className="add" onClick={() => addCashItem(false)}>
                      +
                    </button>
                  </div>
                </div>
                {both === 1 ? (
                  <>
                    <div className="item_box">
                      <BizActParent
                        isIncome={isIncome === 0 ? 1 : 0}
                        addCashItem={() => addCashItem(true)}
                        monthSum={monthOtherSum}
                      />
                    </div>
                    <div className="child_box">
                      {cashOtherItems.map((item) => (
                        <BizActChild
                          key={item.id}
                          id={item.id}
                          category={item.category}
                          cash_data={item.cash_data}
                          onUpdateCashData={(newCashData) =>
                            updateCashData(item.id, newCashData, 1, true)
                          }
                          onUpdateCategoryData={(newCategoryData) => {
                            updateCashData(item.id, newCategoryData, 0, true);
                          }}
                          deleteCashItem={deleteCashOtherItem}
                        />
                      ))}
                    </div>
                    <div className="btn_list act">
                      <div className="box">
                        <button
                          className="add"
                          onClick={() => addCashItem(true)}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <StepBtns
            prev={prev}
            next={next}
            goNext={goNext}
            goPrev={goPrev}
            end={end}
          />
        </div>
      </div>
    </div>
  );
};

export default BizAct;
