import { useRef, useEffect, useState } from "react";
import CompetitorStatisticList from "./CompetitorStatisticList";
import CompetitorStatisticSubject from "./CompetitorStatisticSubject";
import "./css/CompetitorStatistic.scss";
import TreemapChart from "./Treemap";
import { Pie } from "react-chartjs-2";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import {
  doughnutChartDataComp1,
  doughnutChartDataComp2,
  doughnutChartDataComp3,
  doughnutChartDataComp4,
  doughnutChartDataComp5,
  doughnutChartDataTech1,
  doughnutChartDataTech2,
  doughnutChartDataTech3,
  doughnutChartDataTech4,
  doughnutChartDataTech5,
  doughnutChartDataFinance1,
  doughnutChartDataFinance2,
  doughnutChartDataFinance3,
  doughnutChartDataFinance4,
  doughnutChartDataFinance5,
  doughnutChartDataBiz1,
  doughnutChartDataBiz2,
  doughnutChartDataBiz3,
  doughnutChartDataBiz4,
  doughnutChartDataBiz5,
} from "./competitorSearchData";

const CompetitorTrend = () => {
  const history = useHistory();
  const location = useLocation();
  const targetList = ["특구사업"];
  const totalNum = location.state.totalNum;

  const dataLocaComp = [
    { title: "강소(경남김해)", value: 6 },
    { title: "강소(경남창원)", value: 3 },
    { title: "강소(경북포항)", value: 3 },
    { title: "강소(충북청주)", value: 2 },
    { title: "광주", value: 280 },
    { title: "대구", value: 38 },
    { title: "대덕", value: 659 },
    { title: "부산", value: 4 },
    { title: "전북", value: 135 },
    { title: "기타", value: 34 },
  ];
  const dataLocaTech = [
    { title: "강소(경남김해)", value: 6 },
    { title: "강소(경남창원)", value: 3 },
    { title: "강소(경북포항)", value: 3 },
    { title: "강소(충북청주)", value: 2 },
    { title: "광주", value: 280 },
    { title: "대구", value: 38 },
    { title: "대덕", value: 659 },
    { title: "부산", value: 4 },
    { title: "전북", value: 135 },
    { title: "기타", value: 34 },
  ];
  const dataLocaFinance = [
    { title: "강소(경남김해)", value: 6 },
    { title: "강소(경남창원)", value: 3 },
    { title: "강소(경북포항)", value: 3 },
    { title: "강소(충북청주)", value: 2 },
    { title: "광주", value: 280 },
    { title: "대구", value: 38 },
    { title: "대덕", value: 659 },
    { title: "부산", value: 4 },
    { title: "전북", value: 135 },
    { title: "기타", value: 34 },
  ];
  const dataLocaBiz = [
    { title: "강소(경남김해)", value: 6 },
    { title: "강소(경남창원)", value: 3 },
    { title: "강소(경북포항)", value: 3 },
    { title: "강소(충북청주)", value: 2 },
    { title: "광주", value: 280 },
    { title: "대구", value: 38 },
    { title: "대덕", value: 659 },
    { title: "부산", value: 4 },
    { title: "전북", value: 135 },
    { title: "기타", value: 34 },
  ];

  const doughnutChartOption = {
    responsive: false,
    legend: {
      display: true,
      align: "start",
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            display: false,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            display: false,
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };

  const statisticList = [
    {
      cd: 2,
      name: "기술 기준",
      locaData: dataLocaTech,
      data: [
        doughnutChartDataTech1,
        doughnutChartDataTech2,
        doughnutChartDataTech3,
        doughnutChartDataTech4,
        doughnutChartDataTech5,
      ],
    },
    {
      cd: 1,
      name: "기업 기준",
      locaData: dataLocaComp,
      data: [
        doughnutChartDataComp1,
        doughnutChartDataComp2,
        doughnutChartDataComp3,
        doughnutChartDataComp4,
        doughnutChartDataComp5,
      ],
    },
    {
      cd: 3,
      name: "재무 기준",
      locaData: dataLocaFinance,
      data: [
        doughnutChartDataFinance1,
        doughnutChartDataFinance2,
        doughnutChartDataFinance3,
        doughnutChartDataFinance4,
        doughnutChartDataFinance5,
      ],
    },
    {
      cd: 4,
      name: "사업화 기준",
      locaData: dataLocaBiz,
      data: [
        doughnutChartDataBiz1,
        doughnutChartDataBiz2,
        doughnutChartDataBiz3,
        doughnutChartDataBiz4,
        doughnutChartDataBiz5,
      ],
    },
  ];

  const [activeTab, setActiveTab] = useState(statisticList[0].cd);

  const goStatistic = () => {
    history.push("/competitor/statistic", { totalNum: totalNum });
  };

  useEffect(() => {
    const initCd = location.state.cd;
    setActiveTab(initCd);
  }, []);

  return (
    <div id="CompetitorTrend" className="CompetitorStatistic">
      <div className="center_content">
        <CompetitorStatisticSubject tagList={targetList} num={totalNum} />
        <div className="view pb20 mb20">
          <p className="sub_tit">기업 분석/통계보기</p>
          {/* <CompetitorStatisticList statisticList={statisticList} /> */}
          <ul className="tag_list mr10">
            <li className="tag bg_light_gray" onClick={() => goStatistic()}>
              #전체 랭킹
            </li>
            {statisticList.length
              ? statisticList.map((item, idx) => (
                  <li
                    key={idx}
                    className={
                      item.cd === activeTab
                        ? "tab-list-item tab-list-active tag bg_light_gray"
                        : "tab-list-item tag bg_light_gray"
                    }
                    onClick={() => setActiveTab(item.cd)}
                  >
                    {item.name}
                  </li>
                ))
              : ""}
          </ul>
        </div>
        {statisticList
          .filter((item) => item.cd === activeTab)
          .map((item) => (
            <div key={item.cd}>
              <div className="distribution pa20 border_light_gray mb20">
                <p className="sub_tit">특구별 분포</p>
                <TreemapChart chartData={item.locaData} />
              </div>
              <div className="pie_box">
                <ul>
                  <li className="pa20 border_light_gray">
                    <p className="sub_tit">매출액 비중</p>
                    <Pie
                      data={item.data[0]}
                      height={360}
                      options={doughnutChartOption}
                    />
                  </li>
                  <li className="pa20 border_light_gray">
                    <p className="sub_tit">업력별 비중</p>
                    <Pie
                      data={item.data[1]}
                      height={360}
                      options={doughnutChartOption}
                    />
                  </li>
                  <li className="pa20 border_light_gray">
                    <p className="sub_tit">기술평가 비중</p>
                    <Pie
                      data={item.data[2]}
                      height={360}
                      options={doughnutChartOption}
                    />
                  </li>
                  {/* <li className="pa20 border_light_gray">
                    <p className="sub_tit">12대 기술분야 비중</p>
                    <Pie
                      data={item.data[3]}
                      height={360}
                      options={doughnutChartOption}
                    />
                  </li>
                  <li className="pa20 border_light_gray">
                    <p className="sub_tit">산업분야 비중</p>
                    <Pie
                      data={item.data[4]}
                      height={360}
                      options={doughnutChartOption}
                    />
                  </li> */}
                </ul>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default CompetitorTrend;
