import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import "../css/HoldingsLevel.scss";

const HoldingsLevel = () => {
  const [centerText, setCenterText] = useState("");

  const levelChartData = {
    labels: ["Seed", "Series A", "Series B", "Series C"],
    datasets: [
      {
        label: "비율",
        data: [34, 22, 25, 19],
        borderColor: ["#66AEF7", "#605BFF", "#66EBAB", "#FFCA66"],
        backgroundColor: ["#66AEF7", "#605BFF", "#66EBAB", "#FFCA66"],
      },
    ],
  };

  const levelChartOption = {
    // responsive: false,
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: "bottom",
      labels: {
        boxWidth: 11,
        boxHeight: 11,
        usePointStyle: true,
      },
    },
    tooltips: {
      enabled: false,
      custom: function (tooltipModel) {
        if (tooltipModel.opacity === 0) {
          setCenterText("");
          return;
        }

        const total = levelChartData.datasets[0].data.reduce(
          (acc, curr) => acc + curr,
          0
        );
        const index = tooltipModel.dataPoints[0].index;
        const value = levelChartData.datasets[0].data[index];
        const percentage = ((value / total) * 100).toFixed(0);
        const labels = levelChartData.labels;
        const currentLabel = labels[index];

        setCenterText(
          <div className="chart_label" style={{ textAlign: "center" }}>
            {percentage}%<br />
            <span>{currentLabel}</span>
          </div>
        );
      },
    },
    hover: {
      onHover: function (e, elements) {
        if (elements.length > 0) {
          const chart = elements[0]._chart;
          const datasetIndex = elements[0]._datasetIndex;
          const index = elements[0]._index;

          const meta = chart.getDatasetMeta(datasetIndex);
          const total = meta.total;
          const value = chart.data.datasets[datasetIndex].data[index];
          const percentage = ((value / total) * 100).toFixed(0);
          const labels = levelChartData.labels;
          const currentLabel = labels[index];

          setCenterText(
            <div className="chart_label" style={{ textAlign: "center" }}>
              {percentage}%<br />
              <span>{currentLabel}</span>
            </div>
          );
        } else {
          setCenterText("");
        }
      },
    },
  };

  const centerTextStyle = {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -60%)",
    fontSize: "24px",
    pointerEvents: "none",
  };

  return (
    <div id="HoldingsLevel" style={{ position: "relative" }}>
      <div className="part">
        <div>
          <strong className="part_tit">투자단계별 기업 분포</strong>
        </div>
        <div className="chart_box">
          <Doughnut
            data={levelChartData}
            options={levelChartOption}
            height={450}
          />
          <div style={centerTextStyle}>{centerText}</div>
        </div>
      </div>
    </div>
  );
};

export default HoldingsLevel;
