import "../css/HoldingsCommon.scss";
import HoldingsComp from "./HoldingsComp";
import HoldingsStatic from "./HoldingsStatic";

const HoldingsCurrent = () => {
  return (
    <div id="HoldingsCurrent" className="holdings_common">
      <HoldingsComp />
      <HoldingsStatic />
    </div>
  );
};

export default HoldingsCurrent;
