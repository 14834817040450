import React, {useState, useEffect} from 'react';
import CheckBox from "../common/CheckBox";
import './css/ProductSelectPopup.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import TradeMarkAPI from "../../API/trademark";

function ProductSelectPopup({productClassNo, onClickConfirm, onClickClose}) {

    const count = 500;
    const [curPage, setCurPage]= useState(1);
    const [hasMore, setHasMore] = useState(true);

    const [keyword, setKeyword] = useState('');
    const [keywordList, setKeywordList] = useState([]);
    const [keywordListShow, setKeywordListShow] = useState(false);
    const [allChecked, setAllChecked] = useState(false);
    const [checkBoxList, setCheckBoxList] = useState([]);
    const [data, setData] = useState([]);
    const [mouse, setMouse] = useState(false);

    useEffect(() => {
        drawSearchClass();
    }, []);

    const onBlur = () => {
        if (!mouse) {
            setKeywordListShow(false);
        }
    };

    const drawSearchClass = () => {
        TradeMarkAPI.getSearchClass(curPage, count, productClassNo).then(res => {
            let items = res.data.items;
            let check_box_arr = [];

            items.map(item => check_box_arr.push({text: item, checked: false}));
            setData(data.concat(items));
            setCheckBoxList(checkBoxList.concat(check_box_arr));
            setCurPage(curPage+1);
            if (res.data.page == res.data.last) {
                setHasMore(false);
            } else {
                setHasMore(true);
            }
        });
    };

    const onClickAllChecked = () => {
        setAllChecked(!allChecked);
        setCheckBoxList(checkBoxList.map(item => {
            item.checked = !allChecked;
            return item;
        }));
    };

    const onClickChecked = (idx) => {
        let copy_json = window.$Global.cloneObject(checkBoxList);
        let copy_arr = Object.keys(copy_json).map(key => copy_json[key]);
        copy_arr[idx].checked = !copy_arr[idx].checked;
        setCheckBoxList(copy_arr);
    };

    const onClickKeywordSelect = (item) => {
        setKeyword(item);
        setKeywordListShow(false);
    };

    const onChangeFindKeyword = (e) => {
        setKeyword(e.target.value);

        if (e.target.value.length == 0) {
            setKeywordListShow(false);
            setKeywordList([]);
            return;
        }

        let result = data.filter(item => {
            return item.indexOf(e.target.value) > -1;
        });

        if (result.length) {
            setKeywordListShow(true);
            setKeywordList(result.map((item, index) => {
                return (
                    <li key={index} onClick={() => onClickKeywordSelect(item)}>{item}</li>
                )
            }));
        } else {
            setKeywordListShow(false);
            setKeywordList([]);
        }
    };

    const onClickSearchKeyword = () => {
        // keyword
        let result = data.filter(item => {
            return item.indexOf(keyword) > -1;
        });
        setData(result);
    };

    const onClickRegist = () => {
        let select_arr = checkBoxList.filter(item => item.checked  == true);
        if (select_arr.length == 0) {
            alert("1개 이상을 선택해주세요");
            return;
        }
        onClickConfirm(select_arr);
    };

    if (!checkBoxList.length) return null;

    let tag =
        data.map((item, idx) => {
            return (
                <tr key={idx}>
                    <td>
                        <CheckBox checked={checkBoxList[idx] ? checkBoxList[idx].checked : false} onChangeChecked={() => onClickChecked(idx)}/>
                    </td>
                    <td>제{productClassNo}류</td>
                    <td>{item}</td>
                </tr>
            );
        });

    return (
        <div id="ProductSelectPopup">
            <div className="header">
                <h2>지정상품 선택하기</h2>
                <div className="btns">
                    <button className="btn_confirm" onClick={onClickRegist}>등록하기</button>
                    <button className="icon_exit" onClick={onClickClose}/>
                </div>
            </div>
            <div className="search">
                <div>
                    <input type="text" value={keyword} placeholder="지정상품을 입력하세요" onBlur={onBlur} onChange={onChangeFindKeyword} onKeyUp={(e) => {if(e.key === 'Enter') onClickSearchKeyword()}}/>
                    {
                        keywordListShow &&
                        <ul className="keyword_list custom_scroll" onMouseEnter={() => setMouse(true)} onMouseLeave={() => setMouse(false)}>
                            {keywordList}
                        </ul>
                    }
                </div>
                <button className="icon_search_black_small" onClick={onClickSearchKeyword}/>
            </div>
            <div id="scrollableDiv" className="wrap_table">
                <InfiniteScroll
                    dataLength={data.length}
                    next={drawSearchClass}
                    hasMore={hasMore}
                    scrollableTarget="scrollableDiv">
                    <table>
                        <thead>
                            <tr>
                                <th width="10%">
                                    <CheckBox checked={allChecked} onChangeChecked={onClickAllChecked}/>
                                </th>
                                <th width="12%">상품분류</th>
                                <th>지정상품 또는 지정 서비스업</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tag}
                        </tbody>
                    </table>
                </InfiniteScroll>
            </div>
        </div>
    );
}

export default ProductSelectPopup;
