export default {
  translateMonthToStatus(month) {
    // 런웨이까지 걸리는 개월수에 따라 상태 리턴
    if (month === "") {
      return { code: "stable", text: "안정적" };
    } else if (month < 3) {
      return { code: "danger", text: "위험" };
    } else if (month > 2 && month < 7) {
      return { code: "warn", text: "경고" };
    } else if (month > 6 && month < 12) {
      return { code: "good", text: "양호" };
    }
  },
  addMonths(date, months) {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + months);
    return newDate;
  },
  getDaysDifference(date1, date2) {
    const diffTime = Math.abs(date2 - date1); // 밀리초 차이 계산
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // 일 수로 변환
    return diffDays;
  },
};
