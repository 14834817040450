import CustomSwitch from "./common/CustomSwitch";
import UserAuthRoute from "./common/UserAuthRoute";
import RnDPlanFirst from "../components/businessPlan/R&D/planOne/RnDPlanFirst";
import RnDPlanSecond from "../components/businessPlan/R&D/planOne/RnDPlanSecond";
import RnDPlanThird from "../components/businessPlan/R&D/planOne/RnDPlanThird";
import RnDPlanFourth from "../components/businessPlan/R&D/planOne/RnDPlanFourth";
import RnDPlanFifth from "../components/businessPlan/R&D/planOne/RnDPlanFifth";
import React from "react";
import GroupwareBoardDetail from "../components/groupware/infoBoard/GroupwareBoardDetail";
import Price from "../components/price/Price";
import PaymentList from "../components/myPage/PaymentList";

const GroupWareRoute = ({ match }) => {
  return (
    <>
      <CustomSwitch>
        <UserAuthRoute
          exact
          path={`${match.path}/rnd/list/first`}
          requireAuth={true}
          component={RnDPlanFirst}
        />
        <UserAuthRoute
          exact
          path={`${match.path}/rnd/list/second`}
          requireAuth={true}
          component={RnDPlanSecond}
        />
        <UserAuthRoute
          exact
          path={`${match.path}/rnd/list/third`}
          requireAuth={true}
          component={RnDPlanThird}
        />
        <UserAuthRoute
          exact
          path={`${match.path}/rnd/list/fourth`}
          requireAuth={true}
          component={RnDPlanFourth}
        />
        <UserAuthRoute
          exact
          path={`${match.path}/rnd/list/fifth`}
          requireAuth={true}
          component={RnDPlanFifth}
        />
        <UserAuthRoute
          exact
          path={`${match.path}/infoBoard/detail/:idx`}
          requireAuth={true}
          component={GroupwareBoardDetail}
        />
        <UserAuthRoute
          exact
          path={`${match.path}/payment/list`}
          requireAuth={true}
          component={PaymentList}
        />
      </CustomSwitch>
    </>
  );
};

export default GroupWareRoute;
