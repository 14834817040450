import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Link, useHistory } from "react-router-dom";
import CommonAPI from "../../API/common";
import "./css/ResearchTechExisting.scss";

const ResearchTechExisting = () => {
  const history = useHistory();
  const [keyword, setKeyword] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchState, setSearchState] = useState(false);
  const [category, setCategory] = useState([]);
  const [historyCategory, setHistoryCategory] = useState([]);
  const [isHover, setIsHover] = useState(false);

  const onClickView = (idx1, idx2) => {
    history.push(`list?category_idx1=${idx1}&category_idx2=${idx2}`);
  };

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = () => {
    CommonAPI.getResearchCategory().then((res) => {
      let data = res.data.category_list;
      data.map((el) => {
        el.isOpen = true;
      });
      console.log(data);
      setCategory(data);
      setHistoryCategory(data);
    });
  };

  const onClickSearch = () => {
    let copy = _.cloneDeep(historyCategory);
    let result = copy.filter((item) => {
      let child = item.child.filter((item2) => {
        if (item2.name.indexOf(keyword.replace(/(\s*)/g, "")) > -1) {
          return item2;
        }
      });

      item.child = child;
      if (child.length) return child;
    });
    setCategory(result);
    setSearchState(true);
  };

  const onClickSearchCancel = () => {
    setKeyword("");
    setSearchState(false);
    setCategory(historyCategory);
  };

  return (
    <div id="ResearchTechExisting">
      <header>
        <h2>작성 분야 선택하기</h2>
        <div className="search_wrapper">
          <p>작성 분야 검색하기</p>
          <div className="search_input">
            <input
              type="text"
              placeholder="분야명을 입력하세요"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              onKeyUp={(e) => e.key === "Enter" && onClickSearch()}
            />
            {keyword.length > 0 ? (
              <button
                className="icon_del_small_with_bg"
                onClick={onClickSearchCancel}
              />
            ) : (
              <button
                className="icon_search_gray ir_txt"
                onClick={onClickSearch}
              >
                검색
              </button>
            )}
          </div>
        </div>
      </header>
      <section>
        {category?.map((item) => {
          return (
            <div className="category">
              <div className="category_title">
                <p>
                  <i className="icon_m_menu" />
                  {item.name}
                </p>
                <i
                  className={
                    item.isOpen
                      ? "icon_more_arrow_up"
                      : "icon_more_arrow_down_black"
                  }
                  onClick={() => {
                    item.isOpen = !item.isOpen;
                    setCategory([...category]);
                  }}
                />
              </div>
              {item.isOpen && (
                <ul className="category_list">
                  {item.child?.map((child) => {
                    let checkDataLength = !child.research_cnt;
                    return (
                      <li
                        className={checkDataLength ? "not_data" : ""}
                        onClick={() => onClickView(item.idx, child.idx)}
                      >
                        {checkDataLength && (
                          <img
                            className="pc"
                            src={
                              require("../../assets/images/marketInfo/icon_badge_ready.png")
                                .default
                            }
                            alt="준비중 이미지"
                          />
                        )}
                        <div>
                          <p>{child.name}</p>
                          {!checkDataLength && (
                            <i className="icon_more_arrow_right_black" />
                          )}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          );
        })}
      </section>
    </div>
  );
};

export default ResearchTechExisting;
