import React, { useEffect, useState } from "react";
import ConsultingAPI from "../../API/consulting";
import "./css/ConsultComDetail.scss";
import { Link, useParams } from "react-router-dom";
import CommonAttachPopup from "../common/CommonAttachPopup";

let { kakao } = window;
function ConsultComDetail() {
  const { idx } = useParams();
  const [info, setInfo] = useState({});
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    ConsultingAPI.getInfoUser(idx).then((res) => {
      let data = res.data;
      setInfo(data);

      if (data.lat && data.lon) {
        let container = document.getElementById("map");
        let options = {
          center: new kakao.maps.LatLng(data.lat, data.lon),
          level: 3,
        };
        let map = new kakao.maps.Map(container, options);
        // 마커를 생성합니다
        let marker = new kakao.maps.Marker({
          // 마커가 표시될 위치입니다
          position: options.center,
        });

        // 마커가 지도 위에 표시되도록 설정합니다
        marker.setMap(map);
      }
    });
  }, []);

  const download = (file_idx) => {
    ConsultingAPI.download(idx, file_idx);
  };

  return (
    <div id="consultComDetail">
      {/*<CommonAttachPopup list={info.introduce_file || []} show={showPopup} onClickDownload={download} onClickClose={() => setShowPopup(false)}/>*/}
    </div>
  );
}

export default ConsultComDetail;
