import GroupwareSideBar from "../groupware/GroupwareSideBar";
import { groupwareAdminmenuPath } from "../groupware/groupwarePath";
import CommonAPI from "../../API/common";
import { useEffect, useState } from "react";
import moment from "moment";
import "./css/PaymentList.scss";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { groupwareAdmin } from "../../atom";
import { useRecoilState } from "recoil";
import NotAdmin from "../error/NotAdmin";

const PaymentList = () => {
  const [gwAdmin, setGwAdmin] = useRecoilState(groupwareAdmin);
  const [payResult, setPayResult] = useState([]);
  useEffect(() => {
    CommonAPI.getHistoryList().then((res) => {
      if (res?.data?.items.length) {
        setPayResult(res.data.items);
      }
    });
  }, []);

  return (
    <div id="GroupwareWorks" className="PaymentList">
      <GroupwareSideBar currentPath={groupwareAdminmenuPath} />
      <div className="commute_wrapper">
        <div className="rounded bg_wh tb_box">
          {gwAdmin ? (
            <>
              <h2>결제내역</h2>
              <div className="btn_box">
                <Link to="/intro/price" className="more_pay">
                  추가 결제하러 가기
                </Link>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>결제번호</th>
                    <th>결제내역</th>
                    <th>결제수단</th>
                    <th>상태</th>
                    <th>결제금액</th>
                    <th>결제날짜</th>
                  </tr>
                </thead>
                <tbody>
                  {payResult.length ? (
                    payResult.map((item, idx) => (
                      <tr>
                        <td>{item.payment_no}</td>
                        <td>{item.product_nm}</td>
                        <td>
                          {item.pay_method === "card"
                            ? item.card_name + " [" + item.card_number + "]"
                            : item.pay_method === "point"
                            ? "포인트 결제"
                            : "상담 결제"}
                        </td>
                        <td>
                          {item.status === "paid" ? "결제완료" : "결제취소"}
                        </td>
                        <td>{item.product_price.toLocaleString()}원</td>
                        <td>
                          {moment.unix(item.created_at).format("YYYY-MM-DD")}
                          <br />
                          {moment.unix(item.created_at).format("HH:mm:ss")}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <td colSpan={6}>결제내역이 없습니다.</td>
                  )}
                </tbody>
              </table>
            </>
          ) : (
            <NotAdmin
              text={
                <p class="text">
                  죄송합니다.
                  <br />
                  세션이 자동 로그아웃 되었거나 잘못된 경로로 접속하셨습니다.
                </p>
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentList;
