import config from "../../const/config";
import API from "../../util/api";

export default {
  getBusinessCategory(payload) {
    return API.get("/common/business/category", { params: payload });
  },
  getBusinessCategoryList(payload) {
    return API.get("/common/business/category/list", { params: payload });
  },
  getBusinessList(payload) {
    return API.get("/common/business", { params: payload });
  },
  getCrawledData(payload) {
    return API.get("/common/reference", { params: payload });
  },
  getNoticeList(payload) {
    return API.get(`/common/notice`, { params: payload });
  },

  geCommunityList(payload) {
    return API.get(`/common/notice`, { params: payload });
  },

  getMarketInfo(mi_idx) {
    return API.get(`/info/mi/${mi_idx}`);
  },

  getNoticeInfo(notice_idx) {
    return API.get(`/common/notice/${notice_idx}`);
  },

  downloadNotice(notice_idx, file_idx) {
    window.$Global.download(
      `common/notice/${notice_idx}/file/${file_idx}/download`
    );
  },

  getQnaList(payload) {
    return API.get(`/common/qna`, { params: payload });
  },

  getQnaInfo(qna_idx) {
    return API.get(`/common/qna/${qna_idx}`);
  },

  downloadQna(edu_idx, file_idx) {
    window.$Global.download(`/common/qna/${edu_idx}/file/${file_idx}/download`);
  },

  getEduList(payload) {
    return API.get(`/common/edu`, { params: payload });
  },

  getEduInfo(edu_idx) {
    return API.get(`/common/edu/${edu_idx}`);
  },

  getOption(type) {
    return API.get("/member/option", { params: { type: type } });
  },

  addOption(payload) {
    return API.post("/member/option", payload);
  },

  getList(payload) {
    return API.get("/common/market/trademark", { params: payload });
  },

  getInfo(idx) {
    return API.get(`/common/market/trademark/${idx}`);
  },

  getTaskField(showType) {
    return API.get("/common/task/field", { params: { show_type: showType } });
  },

  getTaskList(payload) {
    return API.get("/common/task", { params: payload });
  },

  getTaskTotalCount() {
    return API.get("/common/task/total_count");
  },

  getTaskInfo(task_idx, params) {
    return API.get(`/common/task/${task_idx}`, params);
  },

  addMatchingBiz(payload) {
    return API.post("/common/task", payload);
  },

  editMatchingBiz(task_idx, payload) {
    return API.patch(`/common/task/${task_idx}`, payload);
  },

  uploadMatchingBiz(task_idx, payload) {
    return API.post(`/common/task/${task_idx}/file`, payload);
  },

  deleteTask(task_idx) {
    return API.delete(`/common/task/${task_idx}`);
  },

  deleteMatchingBizFile(task_idx, file_idx) {
    return API.delete(`/common/task/${task_idx}/file/${file_idx}`);
  },

  addInterest(task_idx) {
    return API.post(`/common/task/${task_idx}/interest`);
  },

  deleteInterest(task_idx) {
    return API.delete(`/common/task/${task_idx}/interest`);
  },

  taskFileDownload(task_idx, file_idx) {
    return window.$Global.download(
      `/common/task/${task_idx}/file/${file_idx}/download`
    );
  },

  getHaveIpCnt() {
    return API.get("/common/payment/ip_cnt");
  },

  sendChatbot(msg) {
    return API.post("/common/chat_bot", { msg: msg });
  },

  getCommunityCategory() {
    return API.get("/common/notice/category");
  },

  getCommunityCategoryVer2() {
    return API.get("/common/notice/category_ver2");
  },

  getFindxCondition() {
    return API.get("/common/task/mailing");
  },

  updateFindxCondition(payload) {
    return API.post("/common/task/mailing", payload);
  },

  updateFindxKeywordCondition() {
    return API.post("/common/task/mailing/keyword/use");
  },

  getFindxKeywordList(params) {
    return API.get("/common/task/mailing/keyword", { params: params });
  },

  addFindxKeyword(payload) {
    return API.post("/common/task/mailing/keyword", payload);
  },

  updateFindxKeyword(keyword_idx, payload) {
    return API.patch(`/common/task/mailing/keyword/${keyword_idx}`, payload);
  },

  deleteFIndxKeyword(keyword_idx) {
    return API.delete(`/common/task/mailing/keyword/${keyword_idx}`);
  },

  getCompanyInfo() {
    return API.get("/common/company/info");
  },

  getWithDrawAsCompany(params) {
    return API.get("/common/company/withdraw", { params: params });
  },

  withdraw(payload) {
    return API.post("/common/company/withdraw", payload);
  },

  getTaskStatistic(params) {
    return API.get("/common/task/statistic", { params: params });
  },

  log(payload) {
    return API.post("/log/visit", payload);
  },

  addVisitCount(payload) {
    return API.post("/log/marketing", payload);
  },

  getVisitCount(params) {
    return API.get("/log/marketing/", { params: params });
  },

  getMarketList(params) {
    return API.get("/common/mi", { params: params });
  },

  getMarketCategory() {
    return API.get("/common/mi/category");
  },

  getMarketCartList() {
    return API.get("/common/mi/cart");
  },

  addMarketCart(mi_idx) {
    return API.post("/common/mi/cart", { mi_idx: mi_idx });
  },

  addCustomChart(mi_idx, payload) {
    return API.post(`/common/mi/${mi_idx}/custom`, payload);
  },

  removeMarketCart(cart_idx) {
    return API.delete(`/common/mi/cart/${cart_idx}`);
  },

  getMarketDocumentList(params) {
    return API.get(`/common/mi/document`, { params: params });
  },

  getMarketDocument(document_idx) {
    return API.get(`/common/mi/document/${document_idx}`);
  },

  addMarketDocument(payload) {
    return API.post("/common/mi/document", payload);
  },

  updateMarketDocument(document_idx, payload) {
    return API.patch(`/common/mi/document/${document_idx}`, payload);
  },

  downloadMarketDocument(document_idx) {
    return window.$Global.download(
      `/common/mi/document/${document_idx}/download`
    );
  },

  downloadMarketInfoDocument(payload) {
    return window.$Global.newDownload(`/common/mi/item_download`, payload);
  },
  // downloadMarketInfoDocument(payload) {
  //   return API.post("/common/mi/item_download", payload);
  // },

  deleteMarketDocument(document_idx) {
    return API.delete(`/common/mi/document/${document_idx}`);
  },

  getMarketImageList() {
    return API.get("/common/mi/my");
  },

  addMarketImage(payload) {
    return API.post(`/common/mi/my`, payload);
  },

  getMarketImage(my_idx) {
    return API.get(`/common/mi/my/${my_idx}`);
  },

  deleteMarketImage(my_idx) {
    return API.delete(`/common/mi/my/${my_idx}`);
  },

  addMarketRequest(payload) {
    return API.post("/common/mi/request", payload);
  },

  updateMarketRequest(request_idx, payload) {
    return API.patch(`/common/mi/request/${request_idx}`, payload);
  },

  getMarketRequestList(params) {
    return API.get("/common/mi/request", { params: params });
  },

  getMarketRequest(request_idx) {
    return API.get(`/common/mi/request/${request_idx}`);
  },

  deleteMarketRequest(request_idx) {
    return API.delete(`/common/mi/request/${request_idx}`);
  },

  downloadMarketReport(payload) {
    return window.$Global.fileDownloadSync(`common/mi/download`, payload);
  },

  downloadMarketRequest(request_idx) {
    return window.$Global.download(
      `/common/mi/request/${request_idx}/download`
    );
  },

  getTaskHome() {
    return API.get(`/common/task/home`);
  },

  getTaskNewHome(param) {
    return API.get(`/common/task/new_home`, { params: param });
  },

  getTaskPDFList() {
    return API.get("/common/task/pdf");
  },

  getTaskPDFInfo(pdf_idx) {
    return API.get(`/common/task/pdf/${pdf_idx}`);
  },

  getResearchDocumentList(param) {
    return API.get(`/common/research/document`, { params: param });
  },

  getResearchDocument(document_idx) {
    return API.get(`/common/research/document/${document_idx}`);
  },

  getResearchList(param) {
    return API.get(`/common/research`, { params: param });
  },

  getResearchInfo(research_idx, params) {
    return API.get(`/common/research/${research_idx}`, { params: params });
  },

  getResearchKeyword(payload) {
    return API.post(`/common/research/keyword`, payload);
  },

  getResearchRelated(research_idx) {
    return API.get(`/common/research/${research_idx}/relatedTask`);
  },

  getResearchApplicant(payload) {
    return API.post(`/common/company/patent/downloadSummary`, payload);
  },

  getMyKeywordList(params) {
    return API.get(`/common/patent/history`, { params: params });
  },
  getResearchCategory() {
    return API.get(`/common/research/category`);
  },
  addResearchDoc(payload) {
    return API.post("/common/research/document", payload);
  },

  updateResearchDoc(document_idx, payload) {
    return API.patch(`/common/research/document/${document_idx}`, payload);
  },

  uploadResearchImage(document_idx, payload) {
    return API.post(`/common/research/document/${document_idx}/img`, payload);
  },

  deleteResearchDoc(document_idx) {
    return API.delete(`/common/research/document/${document_idx}`);
  },

  deleteMyKeyword(history_idx) {
    return API.delete(`/common/patent/history/${history_idx}`);
  },

  downloadDocument(document_idx) {
    window.$Global.download(
      `common/research/document/${document_idx}/download`
    );
  },

  getPatentData(payload) {
    return API.post(`/common/patent/test`, payload);
  },

  getPatentDataKeyword(payload) {
    return API.post(`/common/patent/selectKeyword`, payload);
  },

  getPatentDataUseApplyNumber(params) {
    return API.get(`/common/patent/selectApplyNumber`, { params: params });
  },
  getPatentDataAddress(payload) {
    return API.post(`/common/patent/address`, payload);
  },

  getPatentRelateKeyword(keyword) {
    return API.get(`/common/patent/relateKeyword?keyword=${keyword}`);
  },

  downloadKeywordExcel(payload) {
    return API.post(`/common/patent/downloadKeywordExcel`, payload);
  },

  addBusinessData(payload) {
    return API.post("/common/business", payload);
  },

  updateBusinessData(business_idx, payload) {
    return API.patch(`/common/business/${business_idx}`, payload);
  },

  getBusinessPlanList(params) {
    return API.get("/common/business", params);
  },

  getBusinessData(business_idx) {
    return API.get(`/common/business/${business_idx}`);
  },

  downloadBusinessHWP(business_idx) {
    return API.post(`/common/business/${business_idx}/download`);
  },

  getPatentInfo(apply_number, params) {
    return API.get(`/common/patent/${apply_number}/detail`, {
      headers: {
        "x-token": config.findbizFreeToken,
      },
      params: params,
    });
  },

  getIpInfo(params) {
    return API.get("/info/company/statistic", { params: params });
  },

  // 서비스사이트 메인 페이지 즐겨찾기 관리 API
  getAllFavoriteList() {
    return API.get("/common/service");
  },
  getMyFavoriteList() {
    return API.get("/common/service/favorite");
  },
  addMyFavoriteList(payload) {
    return API.post("/common/service/favorite", payload);
  },
  deleteMyFavoriteList(favorite_idx) {
    return API.delete(`/common/service/favorite/${favorite_idx}`);
  },

  //SMART5 특허 평가 보고서 다운로드 URL API
  getSmart5URL(params) {
    return API.get("/info/patent/smart5File", { params: params });
  },
  getSmart5AnalysisURL(params) {
    return API.get("/info/company/smart5Analysis", { params: params });
  },

  // 연차료 납부 보이는 계정 체크 API
  getAnnualPayUsableList() {
    return API.get("/common/company/annualPayUsableList");
  },

  // 이용가격 상품정보 가져오는 API
  getProductList() {
    return API.get("/common/product");
  },
  getHistoryList() {
    return API.get("common/payment/history_list");
  },
  //연차료 납부 검토 보고서 데이터 가져오기
  getReportInfo(payload) {
    return API.get("/common/patent/report", { params: payload });
  },
  patentReportApply(payload) {
    return API.post(`/common/patent/report/apply`, payload);
  },
  getReportDummyPatentInfo(payload) {
    return API.get("/common/patent/report/dummyPatentReportView", {
      params: payload,
    });
  },
  dummyPatentReportApply(payload) {
    return API.post(`/common/patent/report/applyDummyPatent`, payload);
  },
  getReportApplyCheck(payload) {
    return API.post(`/common/patent/report/reportApplyCheck`, payload);
  },
  downloadPatentReport(payload) {
    window.$Global.download(`/common/patent/report/download`, payload, 1);
  },
  checkPatentReport(payload) {
    return API.post(`/common/patent/report/checkPatentReport`, payload);
  },
  patentReportPayment(payload) {
    return API.post(`/common/patent/report/reportPayment`, payload);
  },
};
