const type = {
    invent: 'invent',  // 발명신고서
    agent_invent: 'agent_invent', // 대리인 발명신고서
    survey: 'survey', // 선행기술 조사
    agent_survey: 'agent_survey', // 대리인 선행기술 조사
    draft: 'draft', // 초안 접수
    agent_draft: 'agent_draft', // 대리인 초안 접수
    draft_reply: 'draft_reply', // 초안 수정본, 최종본
    oa_content: 'oa_content',  // oa 발행일
    apply: 'apply', // 출원번호
    agent_apply: 'agent_apply', // 출원번호
    register: 'register', // 등록번호
    agent_register: 'agent_register', // 등록번호
    ep_register: 'ep_register', // 유럽 등록번호
    agent_ep_register: 'agent_ep_register', // 대리인 유럽 등록번호
    agent_oa_content: 'agent_oa_content', // 대리인 oa 발행일
    oa_draft: 'oa_draft', // oa 초안
    agent_oa_draft: 'agent_oa_draft', // 대리인 oa 초안
    oa_reply: 'oa_reply', // 대응안 검토
    oa_opinion: 'oa_opinion', // 대응안 수정본
    agent_oa_opinion: 'agent_oa_opinion', // 대리인 대응안 수정본, 최종본
    draft_translation: 'draft_translation', // 초안 번역본 보기
    draft_translation_reply: 'draft_translation_reply', // 발명자 번역문 발명자, 담당자 검토기한
    agent_draft_translation: 'agent_draft_translation',
    ids: 'ids',
    agent_ids: 'agent_ids',
    report: 'report',
    agent_report: 'agent_report',
    ep: 'ep',
    ep_draft_translation_order: 'ep_draft_translation_order',
    ep_draft_translation: 'ep_draft_translation',
    ep_draft_translation_reply: 'ep_draft_translation_reply',
    agent_ep_draft_translation_order: 'agent_ep_draft_translation_order',
    agent_ep_draft_translation: 'agent_ep_draft_translation',
    agent_ep_draft_translation_reply: 'agent_ep_draft_translation_reply',
};
export default type;
