export const matchIcons = [
  {
    text: "업무대시보드",
    icon: "pie_graph_pink",
  },
  {
    text: "이메일",
    icon: "mail",
  },
  {
    text: "전자결재",
    icon: "project",
  },
  {
    text: "근태관리",
    icon: "stopwatch",
  },
  {
    text: "휴가관리",
    icon: "paper_aircraft",
  },
  {
    text: "지출관리",
    icon: "receipt",
  },
  {
    text: "클라우드",
    icon: "cloud",
  },
  {
    text: "TO DO",
    icon: "to_do_list",
  },
  {
    text: "업무보고",
    icon: "project",
  },
  {
    text: "게시판",
    icon: "loudspeaker",
  },
  {
    text: "일정관리",
    icon: "calendar",
  },
  {
    text: "회의실 예약",
    icon: "clock",
  },
  {
    text: "법인차 예약",
    icon: "clock",
  },
  {
    text: "인사관리",
    icon: "info",
  },
  {
    text: "업무챗",
    icon: "talk",
  },
  {
    text: "프로젝트룸",
    icon: "paper_list",
  },
  {
    text: "비품 관리",
    icon: "notebook",
  },
  {
    text: "캐시플로우",
    icon: "cashflow",
  },
  {
    text: "프로젝트 관리",
    icon: "manage",
  },
];

export const freePackageItems = [
  "업무대시보드",
  "전자결재",
  "근태관리",
  "휴가관리",
  "지출관리",
  "클라우드",
  "TO DO",
  "업무보고",
  "게시판",
  "일정관리",
  "회의실 예약",
  "법인차 예약",
  "인사관리",
  "업무챗",
  "프로젝트룸",
  "맞춤 정부과제 정보",
  "이메일",
];

export const allPackageItems = [
  "업무대시보드",
  "이메일",
  "전자결재",
  "근태관리",
  "휴가관리",
  "지출관리",
  "클라우드",
  "TO DO",
  "업무보고",
  "게시판",
  "일정관리",
  "회의실 예약",
  "법인차 예약",
  "인사관리",
  "업무챗",
  // "프로젝트룸",
  // "맞춤 정부과제 정보",
];

export const lightPackageItems = [
  "업무대시보드",
  "전자결재",
  "근태관리",
  "휴가관리",
  "지출관리",
  "클라우드",
  "TO DO",
  "업무보고",
  "게시판",
  "일정관리",
  "회의실 예약",
  "법인차 예약",
  "인사관리",
  "업무챗",
  "프로젝트룸",
  "맞춤 정부과제 정보",
];

export const subPackageData = [
  // {
  //   key: "free",
  //   title: "무료 패키지",
  //   subTitle: "가볍지만 알찬 시작! 한달 간 모든 서비스를 경험하세요.",
  //   id: "free",
  //   name: "subsPackage",
  //   badgeType: "1",
  // },
  {
    key: "all",
    title: "베이직 패키지",
    subTitle: "이메일은 기본, 모든 업무툴을 자유롭게 이용해 보세요.",
    id: "all",
    name: "subsPackage",
    badgeType: "2",
  },
  // {
  //   key: "light",
  //   title: "라이트 패키지",
  //   subTitle: "이메일을 제외한 그룹웨어-협업툴 서비스를 이용해 보세요.",
  //   id: "light",
  //   name: "subsPackage",
  //   badgeType: "0",
  // },
];

export const infoPackageData = [
  {
    key: "info",
    title: "기업정보 검색",
    subTitle: "관심기업을 검색하여 재무,기술현황 등을 확인해 보세요.",
    id: "info",
    name: "infoPackage",
    badgeType: "0",
    icon: "camera",
    isChecked: false,
  },
  {
    key: "market",
    title: "최신 시장정보",
    subTitle: "최신 시장정보로 시장조사·IR·사업계획서를 작성해보세요.",
    id: "market",
    name: "infoPackage",
    badgeType: "0",
    icon: "graph",
    isChecked: false,
  },
  {
    key: "research",
    title: "특허/기술 트렌드 조사",
    subTitle: "쉬운 검색으로 찾는 기술동향 리포트+특허요약 정보",
    id: "research",
    name: "infoPackage",
    badgeType: "0",
    icon: "cloud_n",
    isChecked: false,
  },
  // {
  //   key: "business",
  //   title: "비즈니스 종합정보",
  //   subTitle: "기업,시장,기술정보를 종합적으로 확인하세요.",
  //   id: "business",
  //   name: "infoPackage",
  //   badgeType: "0",
  //   icon: "magnifier",
  //   isChecked: false,
  // },
];

export const infoOption = [
  {
    key: "bizinfo",
    name: "기업정보 검색",
    subInfo: [
      "기업 정보 조회 (월 100건)",
      "기업 재무정보",
      "기업 기술정보",
      "기업 경쟁기업 정보",
      "기업 과제 입찰정보",
    ],
  },
  {
    key: "mi",
    name: "시장정보 조사",
    subInfo: [
      "최신 시장그래프 활용 (월 100건)",
      "그래프 커스터마이징",
      "보도자료 검색 및 활용",
    ],
  },
  {
    key: "research",
    name: "특허/기술트렌드 조사",
    subInfo: [
      "쉬운 특허/기술 검색 (월 100건)",
      "특허/기술동향 보고서",
      "개별특허 요약정보",
      "특허 연관기업 정보",
      "특허 연관시장 정보",
    ],
  },
];

export const period = [
  {
    name: "12개월 (40% 할인)",
    value: 12,
  },
  // {
  //   name: "6개월 (33% 할인)",
  //   value: 6,
  // },
  // {
  //   name: "3개월 (20% 할인)",
  //   value: 3,
  // },
  {
    name: "1개월",
    value: 1,
  },
];

export const dataPeriod = [
  {
    name: "12개월",
    value: 12,
  },
  // {
  //   name: "6개월 (33% 할인)",
  //   value: 6,
  // },
  // {
  //   name: "3개월 (20% 할인)",
  //   value: 3,
  // },
  // {
  //   name: "1개월",
  //   value: 1,
  // },
];

export const storage = [
  {
    name: "용량을 선택하세요",
    value: 0,
  },
  {
    name: "10GB/월",
    value: 10,
  },
  {
    name: "20GB/월",
    value: 20,
  },
  {
    name: "30GB/월",
    value: 30,
  },
  {
    name: "40GB/월",
    value: 40,
  },
  {
    name: "50GB/월",
    value: 50,
  },
  {
    name: "100GB/월",
    value: 100,
  },
];

export const getText = (data) => {
  if (data.idx === "Q39I9DWX3R4KLNU2") {
    return (
      <>
        <p>{`└ 정부사업 검색 및 조회 무제한 제공`}</p>
        <p>{`└ 관심 사업 키워드 메일링 알림 제공`}</p>
        <p>{`└ 정부사업 유관 정보 및 콘텐츠 무제한 제공`}</p>
      </>
    );
  } else if (data.idx === "MGX0P6B0HRN6RE5T") {
    return (
      <>
        <p>{`└ 시장정보 10건/월 다운로드`}</p>
        <p>{`└ 기업정보 10건/월 조회`}</p>
        <p>{`└ 특허분석정보 10건/월 다운로드`}</p>
        <p>{`└ 기술역량등급정보 10건/월 조회`}</p>
        <p>{`└ 정부사업 검색 및 조회 무제한 제공`}</p>
        <p>{`└ 관심 정부사업 키워드 메일링 알림 제공`}</p>
        <p>{`└ 특허활용 보고서 2건 제공 (Open Event)`}</p>
      </>
    );
  } else if (data.idx === "GPFFNBV0Y8KEU5YD") {
    return (
      <>
        <p>{`└ 시장정보 20건/월 다운로드`}</p>
        <p>{`└ 기업정보 20건/월 조회`}</p>
        <p>{`└ 특허분석정보 20건/월 다운로드`}</p>
        <p>{`└ 기술역량등급정보 20건/월 조회`}</p>
        <p>{`└ 정부사업 검색 및 조회 무제한 제공`}</p>
        <p>{`└ 관심 정부사업 키워드 메일링 알림 제공`}</p>
        <p>{`└ 특허활용 보고서 4건 제공 (Open Event)`}</p>
      </>
    );
  } else if (data.idx === "W1QWCY1CXDEPQ1MH") {
    return (
      <>
        <p>{`└ 시장정보 20건/월 다운로드`}</p>
        <p>{`└ 기업정보 20건/월 조회`}</p>
        <p>{`└ 정부사업 검색 및 조회 무제한 제공`}</p>
        <p>{`└ 관심 정부사업 키워드 메일링 알림 제공`}</p>
      </>
    );
  } else if (data.idx === "91IIJXLUB2X4YGKI") {
    return (
      <>
        <p>{`└ 시장정보 40건/월 다운로드`}</p>
        <p>{`└ 기업정보 40건/월 조회`}</p>
        <p>{`└ 정부사업 검색 및 조회 무제한 제공`}</p>
        <p>{`└ 관심 정부사업 키워드 메일링 알림 제공`}</p>
      </>
    );
  } else return null;
};
