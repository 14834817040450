import "./css/InventorStepTwo.scss";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const InventorStepTwo = ({ history }) => {
  return (
    <div id="InventorStepTwo">
      <div className="inventor_info_box_title">
        <h5>소속했던 기관 선택하기</h5>
        <p>
          <i className="icon_badge_info_gray" />
          소속했던 기관을 선택하세요.
        </p>
      </div>
      <Tabs className="custom-tabs" centered>
        <Tab label="대학" className="custom-tab" />
        <Tab label="출연연" className="custom-tab" />
        <Tab label="공공연" className="custom-tab" />
        <Tab label="기업" className="custom-tab" />
      </Tabs>
      <div className="company_list">
        <div className="input_wrap">
          <input type="text" placeholder="부산" />
          <i className="icon_search_gray" />
        </div>
        <div className="list_item">
          <div onClick={() => history.push("/inventor/stepThree")}>
            부산교육대학교 [본교]
          </div>
          <div>부산교육대학교 [본교]</div>
          <div>부산교육대학교 [본교]</div>
          <div>부산교육대학교 [본교]</div>
          <div>부산교육대학교 [본교]</div>
          <div>부산교육대학교 [본교]</div>
          <div>부산교육대학교 [본교]</div>
          <div>부산교육대학교 [본교]</div>
          <div>부산교육대학교 [본교]</div>
          <div>부산교육대학교 [본교]</div>
          <div>부산교육대학교 [본교]</div>
        </div>
      </div>
    </div>
  );
};

export default InventorStepTwo;
