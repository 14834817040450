import "./css/InventorStepThreeDone.scss";

const InventorStepThreeDone = ({ history }) => {
  return (
    <div id="InventorStepThreeDone">
      <h2>
        “김고은”의 “부산교육대학교[본교]”특허 리스트 등록이 완료되었습니다.
      </h2>
      <h5>타기관에 나의 특허가 더 있다면 추가등록을 진행해 주세요.</h5>
      <div>
        <button onClick={() => history.push("/inventor/steptwo")}>
          추가등록 하러가기
        </button>
        <button onClick={() => history.push("/inventor/stepfour")}>
          내 특허 등록하러 가기
        </button>
      </div>
    </div>
  );
};

export default InventorStepThreeDone;
