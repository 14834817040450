import React, { useContext, useEffect, useState } from "react";
import LineBizCnt from "./LineBizCnt";
import BarBizTypeCnt from "./BarBizTypeCnt";
import DoughnutTotalAmount from "./DoughnutTotalAmount";
import "../css/IntroChartContainer.scss";
import CommonAPI from "../../../API/common";
import PopupKeywordCondition from "../PopupKeywordCondition";
import memberContext from "../../../store/Member";

function IntroChartContainer() {
  const isLogin = Boolean(useContext(memberContext).idx);
  const backgroundColor = [
    "#ffe700",
    "#2d99ff",
    "#2cd9c5",
    "#826af9",
    "#c3eb3f",
    "#ffa600",
    "#fa8fff",
  ];
  const [taskStatistic, setTaskStatistic] = useState({});
  const [keywordCondition, setKeywordCondition] = useState(false);

  useEffect(async () => {
    let params = {};

    if (isLogin) {
      params = await CommonAPI.getOption(2).then((res) => res.data[0]?.option);
    } else {
      params = JSON.parse(localStorage.getItem("keyword_for_type"));
    }
    CommonAPI.getTaskStatistic(params).then((res) =>
      setTaskStatistic(res.data)
    );
  }, []);

  if (window.$Global.isEmptyObject(taskStatistic))
    return (
      <div style={{ fontFamily: "Pretendard_bold", textAlign: "center" }}>
        데이터를 불러오는 중입니다
      </div>
    );

  const totalAmount = window.$Global.numberToKorean(
    Object.keys(taskStatistic.daily_amount["7"]).reduce(
      (accumulator, currentValue) =>
        accumulator + taskStatistic.daily_amount["7"][currentValue].amount,
      0
    )
  );

  return (
    <div id="IntroChartContainer">
      <div className="chartform-wrap">
        <div className="chartform">
          <h2 className="chartform_title">최근 일별 biz 개수</h2>
          <div className="legend">
            <div className="legend_data">
              <div className="circle total" />
              <p>일별 List up한 전체 사업 건수</p>
            </div>
            <div className="legend_data">
              <div className="circle organized" />
              <p>일별 정리된 추천 사업 건수</p>
            </div>
          </div>
          <LineBizCnt
            biz_cnt={taskStatistic.biz_cnt}
            customSize={true}
            height={250}
          />
        </div>
        <div className="chartform">
          <h2 className="chartform_title">최근 한달 주요 사업별 갯수</h2>
          <button
            className="chartform_btn"
            onClick={() => setKeywordCondition(true)}
          >
            조건 설정하기
          </button>
          <BarBizTypeCnt type_cnt={taskStatistic.type_cnt} />
        </div>
        <div className="chartform doughnut">
          <h2 className="chartform_title">지난 일주일 사업 총 금액</h2>
          <div className="legend">
            {Object.keys(taskStatistic.daily_amount["7"]).map((item, idx) => {
              return (
                <div className="legend_data">
                  <div
                    key={idx}
                    className="circle"
                    style={{ backgroundColor: backgroundColor[idx] }}
                  />
                  <p>{item.substr(5)}</p>
                </div>
              );
            })}
          </div>
          <div className="total_amount">
            <h2>
              {"약 " + totalAmount.substr(0, totalAmount.indexOf("억") + 1)}
            </h2>
            <p>총 금액</p>
          </div>
          <DoughnutTotalAmount
            daily_amount={taskStatistic.daily_amount}
            colors={backgroundColor}
            customSize={true}
          />
        </div>
        <div className="chartform">
          <h2 className="chartform_title">최근 한달 주요 사업별 금액</h2>
          <BarBizTypeCnt
            type_cnt={taskStatistic.type_amount}
            pluginHide={false}
            tooltipsText="원"
          />
        </div>
      </div>
      <PopupKeywordCondition
        show={keywordCondition}
        onClose={() => setKeywordCondition(false)}
      />
    </div>
  );
}

export default IntroChartContainer;
