import GroupwareSideBar from "./GroupwareSideBar";
import { groupWareWorksPath, groupwareAdminmenuPath } from "./groupwarePath";
import "./css/GroupwareWorks.scss";
import { useLocation } from "react-router-dom"
import "moment/locale/ko";
import { param } from "express/lib/router";
import config from "../../const/config";
import React, { useEffect, useState } from "react";
import util from "../../util/util";
import IframeResizer from 'iframe-resizer-react';

const getAppPath = (section, command, boardno, params) => {
  var src = config.gwUrl + "/";
  if (section == "board") {
    src += "board.auth.do";
  } else if (section == "service") {
    src += "service.auth.do";
  }
  src += "?command=" + command + "&boardno=" + boardno;
  return src;
};

const resizeIframe = (ifrIdName) => {
  //var the_height = document.getElementById(ifrIdName).body.scrollHeight;
  //document.getElementById(ifrIdName).height = the_height;
  //let iframe = document.querySelector("#contentWrap");
  //alert(iframe.contentDocument.body.scrollHeight);
  //iframe.style.height = iframe.contentDocument.body.scrollHeight;
};

export const GroupwareBoardList = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [adminMenu, setAdminMenu] = useState([]);
  const [gwMenu, setGwMenu] = useState([]);

  var isSection = !params.get('section') ? "board" : params.get('section');
  var isCommand = !params.get('command') ? "list" : params.get("command");
  var isBoardNo = !params.get("boardno") ? "101" : params.get("boardno");
  var isApp = getAppPath(isSection, isCommand, isBoardNo, params) + "&siteFlag=biznavi";

  // sideBarSection 구분
  let sideBarSection = "";
  if (isSection === "board") {
    sideBarSection = "board";
  } else {
    sideBarSection = isSection;
  }

  // useEffect(() => {
  //   window.addEventListener(
  //     "message",
  //     (e) => {
  //       //alert(e.data.iframeHeight);
  //       let height = e.data.iframeHeight;
  //       if (height < 800) {
  //         height = 800;
  //       }
  //       document.getElementById('contentWrap').height = height + 20;
  //     },
  //     false
  //   );
  // }, []);

  useEffect(() => {
    getAdminMenu();
  }, []);

  const getAdminMenu = () => {
    let param = {
      su_menu_cd: "",
      menuTitle: "관리자",
      isContentWrap: true,
      isOpen: false
    };

    let gwParam = {
      su_menu_cd: "",
      menuTitle: "",
      isContentWrap: true,
      isOpen: false
    };

    if (sideBarSection == "board") {
      gwParam.su_menu_cd = "200057";
      gwParam.menuTitle = "게시판";
      gwParam.isOpen = true;
      util.getGroupwareMenu(setGwMenu, gwParam);

      param.su_menu_cd = "200345";
      param.menu_cd = "200290";
      param.menuTitle = "관리자";
      param.isOpen = false;
      util.getGroupwareAdminMenu(setAdminMenu, param);
    }
  }

  return (
    <div id="GroupwareWorks">
      {sideBarSection == "board" ? <GroupwareSideBar currentPath={gwMenu} adminMenu={adminMenu} sidebar={sideBarSection} /> :
        <GroupwareSideBar currentPath={groupWareWorksPath[sideBarSection]} adminMenu={adminMenu} sidebar={sideBarSection} />}
      <div className="commute_wrapper">
        {/* <iframe
          id="contentWrap"
          src={isApp}
          width="100%"
          height="800px"
          onLoad={() => {
            resizeIframe("contentWrap");
          }}
        /> */}
        <IframeResizer
          id="contentWrap"
          src={isApp}
          style={{ minWidth: '100%' }}
          heightCalculationMethod='bodyScroll'
        />
      </div>
    </div>
  );
};

export default GroupwareBoardList;















