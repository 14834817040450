import "chartjs-plugin-datalabels";
import React from "react";
import PersonalDashboard from "../intro/findbiz/PersonalDashboard";
import RenewalServiceMain from "../intro/findbiz/RenewalServiceMain";

function HomeTaskManage({ data }) {
  const pmode = localStorage.getItem("pmode") === "on";
  return (
    <div id="HomeTaskManage">
      <div className="pc">
        {pmode ? <PersonalDashboard data={data} /> : <RenewalServiceMain data={data} />}
      </div>
    </div>
  );
}

export default HomeTaskManage;
