import React, { useEffect, useRef } from "react";
import ipLoanLandingImg from "../../assets/images/common/support_landing.png";

const SupportLandingImage = () => {
  const imgRef = useRef(null);

  useEffect(() => {
    const resizeMap = () => {
      const img = imgRef.current;
      const widthRatio = img.clientWidth / img.naturalWidth;
      const heightRatio = img.clientHeight / img.naturalHeight;

      // 이미지가 로드된 후에만 맵을 조정
      const mapAreas = img.parentNode.querySelectorAll("area");

      mapAreas.forEach((area) => {
        const coords = area.dataset.coords.split(",").map(Number);
        const scaledCoords = coords.map((coord, index) =>
          index % 2 === 0 ? coord * widthRatio : coord * heightRatio
        );
        area.coords = scaledCoords.join(",");
      });
    };

    const img = imgRef.current;
    if (img.complete) {
      resizeMap();
    } else {
      img.onload = resizeMap;
    }

    window.addEventListener("resize", resizeMap);
    return () => window.removeEventListener("resize", resizeMap);
  }, []);

  return (
    <>
      <img
        ref={imgRef}
        src={ipLoanLandingImg}
        useMap="#support"
        alt="지원사업 고민해결 패키지"
        style={{
          maxWidth: "100%",
          height: "auto",
          display: "block",
          margin: "0 auto",
        }}
      />
      <map name="support">
        <area
          target="_blank"
          alt="지금 문의하기"
          title="지금 문의하기"
          href="https://forms.gle/7SggTXBhibiBDhmEA"
          data-coords="38,12427,852,12536"
          shape="rect"
        />
      </map>
    </>
  );
};

export default SupportLandingImage;
