import { Link } from "react-router-dom/cjs/react-router-dom";
import "./css/GovProjectBySubject.scss";
import angleRightIcon from '../../assets/images/intro/angle-right-solid.svg';

const GovProjectBySubject = () => {
  return (
    <div id="GovProjectBySubject">
      <h2 className="work_tit">유형별 정부사업</h2>
      <div className="subject_list">
        <ul>
          <li>
            <Link to='/taskManage/part/list?keyword=2'>기술개발 지원사업 <img src={angleRightIcon} /></Link>
          </li>
          <li>
            <Link to='/taskManage/part/list?keyword=256'>창업 지원사업 <img src={angleRightIcon} /></Link>
          </li>
          <li>
            <Link to='/taskManage/part/list?keyword=8'>자금 지원사업<img src={angleRightIcon} /></Link>
          </li>
          <li>
            <Link to='/taskManage/part/list?keyword=1024'>인프라 지원사업<img src={angleRightIcon} /></Link>
          </li>
          <li>
            <Link to='/taskManage/part/list?keyword=64'>판로-수출 지원사업<img src={angleRightIcon} /></Link>
          </li>
          <li>
            <Link to='/taskManage/part/list?keyword=512'>인력 양성사업<img src={angleRightIcon} /></Link>
          </li>
          <li>
            <Link to='/taskManage/part/list?keyword=2048'>바우처 지원사업<img src={angleRightIcon} /></Link>
          </li>
          <li>
            <Link to='/taskManage/part/list?keyword=128'>기타 지원사업<img src={angleRightIcon} /></Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default GovProjectBySubject;