import "../competitor/css/CompetitorList.scss";
import React, { useEffect, useState } from "react";
import logo from "../../assets/images/common/logo_findBiz.svg";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import config from "../../const/config";

const CompetitorIpnowList = () => {
  const history = useHistory();
  const [searchInput, setSearchInput] = useState(
    history.location.state.keyword
  );
  const [companyList, setCompanyList] = useState([]);
  const [showSearchInput, setShowSearchInput] = useState(
    history.location.state.keyword
  );
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getCompanyList();
  }, []);

  const getCompanyList = async () => {
    await axios
      .get(`/api/v3/company`, {
        params: {
          search: searchInput,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setShowSearchInput(searchInput);
        setCompanyList(res.data);
      });
  };

  const handleNavigate = (item) => {
    history.push({
      pathname: `/competitorIpnow/details/${item.dummy_idx}`,
      state: {
        company_data: item,
      },
    });
  };

  return (
    <div id="CompetitorList">
      <header className="header_base">
        <h2>경쟁사 조사</h2>
        <p>
          {/*설명 란<br />*/}
          {/*설명 란<br />*/}
          {/*설명 란*/}
        </p>
      </header>
      <div className="search_wrapper">
        <p>
          기업검색결과 <i className="icon_info_with_blue_bg" />
        </p>
        <div className="search_input">
          <input
            type="text"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === "Enter") getCompanyList();
            }}
          />
          <i className="icon_search_gray" onClick={getCompanyList} />
        </div>
        <button>
          검색내역 보기
          <i className="icon_arrow_right_s" />
        </button>
      </div>
      <div className="company_info_wrapper">
        {isLoading ? (
          <p className="loading">Loading...</p>
        ) : companyList?.total === 0 ? (
          <p className="loading">경쟁사 정보 없음</p>
        ) : (
          <div>
            <h5>
              <span>{showSearchInput}</span> 검색 결과
            </h5>
            <div className="company_info_items custom_scroll">
              {companyList?.result?.map((item) => {
                return (
                  <div className="company_info_item">
                    <img src={logo} />
                    <div className="item_text">
                      <p>{item.company_name}</p>
                      <p>대표명: {item.company_ceo}</p>
                      <div className="item_text_category">
                        {item.grouptags.map((keyword) => {
                          return <p>{keyword.company_group_name}</p>;
                        })}
                      </div>
                    </div>
                    <button onClick={() => handleNavigate(item)}>
                      기업 분석정보
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompetitorIpnowList;
