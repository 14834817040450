import "../css/RenewalIntro.scss";
import RenewalSide from "./RenewalSide";

const RenewalIntro = () => {
  return (
    <div id="Renewal">
      <div id="RenewalIntro">
        <iframe
          src="http://bizoffi.timworks.co.kr/"
          width="100%"
          height="100%"
        />
        {/*/!*<SearchInterestedForm />*/}
        {/*<div className="grid_wrapper">*/}
        {/*  <div>그룹웨어 서비스1</div>*/}
        {/*  <div>전자결재</div>*/}
        {/*  <div>주간업무보고</div>*/}
        {/*  <div>메일 리스트</div>*/}
        {/*  <div>웹하드</div>*/}
        {/*  <div>주소록</div>*/}
        {/*  <div>전자게시</div>*/}
        {/*</div>*!/*/}
      </div>
    </div>
  );
};

export default RenewalIntro;
