import "../css/RenewalServiceMain.scss";
import Moment from "react-moment";
import moment from "moment";
import "moment/locale/ko";
import React, { useCallback, useEffect, useRef, useState } from "react";
import CommonAPI from "../../../API/common";
import WizardAPI from "../../../API/wizard";
import DashBoardTitle from "../DashBoardTitle";
import axios from "axios";
import config from "../../../const/config";
import uuid from "react-uuid";

import util from "../../../util/util";
import Slider from "react-slick";
import { useHistory } from "react-router-dom";
import mailOff from "../../../assets/images/intro/widget-mail-off.png";
import mailOn from "../../../assets/images/intro/widget-mail-on.png";
import mailHover from "../../../assets/images/intro/widget-mail-hover.png";
import newWorkOff from "../../../assets/images/intro/widget-newWork-off.png";
import newWorkOn from "../../../assets/images/intro/widget-newWork-on.png";
import newWorkHover from "../../../assets/images/intro/widget-newWork-hover.png";
import sendPaymentOff from "../../../assets/images/intro/widget-sendPayment-off.png";
import sendPaymentOn from "../../../assets/images/intro/widget-sendPayment-on.png";
import sendPaymentHover from "../../../assets/images/intro/widget-sendPayment-hover.png";
import receivePaymentOff from "../../../assets/images/intro/widget-receivePayment-off.png";
import receivePaymentOn from "../../../assets/images/intro/widget-receivePayment-on.png";
import receivePaymentHover from "../../../assets/images/intro/widget-receivePayment-hover.png";
import ChangeFavorite from "./ChangeFavorite";
import GwAPI from "../../../API/groupware";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  allFavoriteArray,
  groupwareAdmin,
  myFavoriteArray,
  tabNotice,
} from "../../../atom";
import { favoriteIcon } from "../../../const/favoriteIcon";
import GovProjectPop from "../GovProjectPop";
import WizardPop from "../WizardPop";
import deadline from "../../../API/deadline";
import PaymentAlert from "../PaymentAlert";
import MenuBlockAlert from "../MenuBlockAlert";
import MemberAPI from "../../../API/member";
import MiniLoading from "../../common/MiniLoading";
import { Link } from "react-router-dom/cjs/react-router-dom";

moment.locale('ko');

const hoverModalItems = [
  <p>
    업무 필수템 TO DO LIST,
    <br />
    TO DO를 구성원에게 공유하고 리마인드까지 할 수 있다면?
    <br /> BIZNAVI의 특별한 공유 TO DO LIST기능을 기대해 주세요.
  </p>, // TO DO LIST 0
  <p>
    팀과 회사의 목표를 시각화하고 빠르게 달성하세요!
    <br /> 간트차트·진행상황 리스트로 목표를 쉽게 관리할 수 있습니다.
  </p>, // 프로젝트 관리 1
  <p>
    월간·주간·일간 업무보고서를 데이터 연동으로 작성할 수 있습니다.
    <br />
    효율적인 정기 보고를 경험해보세요.
  </p>, // 업무보고 2
  <p>
    지난 정부사업 중 관심있는 정부사업의 경쟁률과 낙찰업체를 확인하고,
    <br />
    경쟁력있는 정부사업 입찰을 준비해 보세요.
  </p>, // 정부사업 경쟁률 3
  <p>
    BIZNAVI와 함께라면 사업계획서 작성 시간은 혁신적으로 단축됩니다.
    <br />
    참여인력·사업비 자동 적용 및 계산은 물론,
    <br /> 시장·기술정보 데이터 제공 및 AI 자동작성까지,
    <br /> 사업계획서 작성 업무시간을 10배 이상 절약해 보세요.
  </p>, // 사업계획서 작성 4
  <p>
    내가 아는 그 경쟁사 말고, 다른 경쟁사가 또 있다면?
    <br /> BIZNAVI의 독보적인 기술로 다각적인 기업 데이터를 분석하여 경쟁사를
    발굴해 드립니다.
  </p>, // 나의 경쟁사 5
  <p>
    검색한 기업의 재무정보부터 기업 경쟁력·보유기술·상품·상표·
    <br /> 최신 보도자료 까지 기업 현황 리포트를 제공합니다.
  </p>, // 경쟁사 찾기 6
  <p>
    즐겨찾는 경쟁기업의 최신 활동현황 소식을 실시간 알림으로 제공합니다.
    <br />
    선제대응을 위한 경쟁기업 현황을 빠르게 확인하세요.
  </p>, // 경쟁사 NOW 7
  <p>상품·브랜드 런칭을 위해 필수적으로 검토해야 하는 상표를 확인하세요.</p>, // 상표명 검색 8
  <p>
    관심있는 시장 분야의 최신 뉴스를 받아보고,
    <br /> 시장조사 리포트·사업계획서·IR자료에 활용해 보세요.
  </p>, // 시장뉴스 보기 9
  <p>
    시장차트 간편 커스텀, 시장전망 자동 계산기, 시장조사 리포트 개요 샘플과
    자동완성 AI로 전문가 없이도 빠르게 시장조사 리포트 작성을 마칠 수 있습니다.
  </p>, // 시장정보 리포트 10
  <p>
    법정의무교육·IP지식 교육 등 기업 필수교육까지
    <br /> BIZNAVI에서 해결하세요.
  </p>, // 온라인 교육 11
  <p>
    아마존,쇼피,라자다,이베이 등 글로벌 이커머스 진출, 막막하신가요?
    <br /> BIZNAVI와 함께라면 입점부터 상세페이지 제작, CS까지 한 번에 할 수
    있도록 지원해 드립니다.
  </p>, // 해외수출 지원 12
  <p>
    BIZNAVI 고객을 대상으로 제공하는 복지몰입니다.
    <br /> Biz 복지몰로 놀라운 할인혜택을 받아보세요.
  </p>, // Biz 복지몰 13
  <p>
    기업을 위한 자체 PR페이지를 제공합니다.
    <br /> 링크를 공유하여 기업 소식 홍보에 활용해보세요.
  </p>, // SNS 홍보 미디어 14
  <p>BIZNAVI의 알찬 비즈니스 인사이트 콘텐츠를 확인해 보세요!</p>, // 뉴스레터 15
  <p>기업명을 검색하면 해당 기업이 보유한 상표권을 확인할 수 있습니다.</p>, // 상표권자 상표검색 16
];

const RenewalServiceMain = ({ data }) => {
  const history = useHistory();
  const [homeData, setHomeData] = useState({});
  const [taskData, setTaskData] = useState([]);
  const [taskDataLoading, setTaskDataLoading] = useState(false);
  const [taskKeyword, setTaskKeyword] = useState("");
  const [taskInterestData, setTaskInterestData] = useState([]);
  const [marketInfoCount, setMarketInfoCount] = useState(0);
  const [fieldCount, setFieldCount] = useState(0);
  const [marketKeyword, setMarketKeyword] = useState("메타버스");
  const [marketInfoData, setMarketInfoData] = useState([]);
  const [marketCartData, setMarketCartData] = useState([]);
  const [keywordList, setKeywordList] = useState([]);
  const [businessPlanData, setBusinessPlanData] = useState([]);
  const [marketDocData, setMarketDocData] = useState([]);
  const [researchDocData, setResearchDocData] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [userVacInfo, setUserVacInfo] = useState({});
  const [image, setImage] = useState("");
  const [isWrite, setIsWrite] = useState(false);
  const [todo, setTodo] = useState("");
  const [todos, setTodos] = useState([]);
  const [isHover, setIsHover] = useState([false, false, false, false]);
  const [tabs, setTabs] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const isMount = useRef(true);
  const [attendFlag, setAttendFlag] = useState(""); // 출근 상태값
  const [attendType, seAttendType] = useState(""); // 출근 상태값
  const [typeNm, setTypeNm] = useState(""); // 출근상태명
  const [attendSdate, setAttendSdate] = useState(""); // 출근시간
  const [attendTodayInfo, setAttendTodayInfo] = useState({}); // 금일 출근 정보
  const [dataAttendMap, setDataAttendMap] = useState({}); // 일정리스트
  const [isVacation, setIsVacation] = useState(false); // 일정리스트
  const [empNm, setEmpNm] = useState(""); // Emp No
  const [totDocCnt, setTotDocCnt] = useState(0); // 새로운 결재 카운트
  const [commandCnt, setCommandCnt] = useState(0); // 새로운 업무 카운트
  const [docList, setDocList] = useState([]); // 올린결재리스트
  const [docApprovalList, setDocApprovalList] = useState([]); // 미결재결재리스트
  const [commandList, setCommandList] = useState([]); // 업무공유리스트
  const [mailList, setMailList] = useState([]); // 메일공유리스트
  const [unreadCnt, setUnreadCnt] = useState(0); // 않읽음 메일카운트
  const [scheduleList, setScheduleList] = useState([]); // 일정리스트
  const [boardList, setBoardList] = useState([]); // 메일 게시판 리스트
  const setAllFavoriteList = useSetRecoilState(allFavoriteArray); // 즐겨찾기 전체항목 저장
  const [myFavoriteList, setMyFavoriteList] = useRecoilState(myFavoriteArray); // 내 즐겨찾기 목록 저장
  const [marketCartSetting, setMarketCartSetting] = useState({});
  const [isHoverModal, setIsHoverModal] = useState(() =>
    Array.from({ length: 17 }, () => false)
  );

  const [govPopShow, setGovPopShow] = useState(true); // 정부사업 팝업
  const [showPaymentAlert, setShowPaymentAlert] = useState(false);
  const [showMenuBlockAlert, setShowMenuBlockAlert] = useState(false);
  const [gwAdmin, setGwAdmin] = useRecoilState(groupwareAdmin);
  // MenuTab에 메일, 전자결재 New 표시를 위한 전역변수
  const [noticeCount, setNoticeCount] = useRecoilState(tabNotice);
  const off = data;
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const govPjPopupDate = localStorage.getItem("gov_pj_popup_date"); // 정부공고 팝업 로컬 스토리지에서 가져오기
  const [wizardInfo, setWizardInfo] = useState({}); // 그룹웨어 설정 마법사(위자드) 정보
  const [showWizard, setShowWizard] = useState(false); // 위자드 팝업 보이기 여부

  const now = Math.floor(Date.now() / 1000);
  const package_idx = localStorage.getItem("package_idx");
  const package_deadline = localStorage.getItem("package_expiration_at");
  const [dragging, setDragging] = useState(false);
  const handleBeforeChange = useCallback(() => {
    setDragging(true);
  }, []);
  const handleAfterChange = useCallback((i) => {
    setDragging(false);
  }, []);
  const sliderSettings = {
    dots: false,
    infinite: false,
    playspeed: 1000,
    slidesToShow: 8,
    slidesToScroll: 8,
    centerMode: false,
    arrows: true,
    touchThreshold: 100,
    beforeChange: handleBeforeChange,
    afterChange: handleAfterChange,
  };
  const handleClick = useCallback(
    (e) => {
      if (dragging) {
        e.preventDefault();
      }
    },
    [dragging]
  );

  // useEffect(() => {
  //   if (
  //     package_idx == null &&
  //     window.localStorage.getItem("company_code") == "120130512335"
  //   ) {
  //     window.$Global.logout("test");
  //   }
  // }, []);

  useEffect(() => {
    getUserInfo();
    getAttendInfo();
    getDocInfo();
    getSchedule();

    getMailInfo();
    getBoardList();
    getTodo();
    getWizardInfo();

    MemberAPI.getInfo().then((res) => {
      let data = res.data;
      localStorage.setItem("package_idx", data.package_idx);
      localStorage.setItem("package_expiration_at", data.package_expiration_at);
    });

    CommonAPI.getTaskHome().then((res) => {
      setHomeData(res.data);
    });
    CommonAPI.getTaskList({ count: 11, caching: 1 }).then((res) => {
      setTaskData(res.data.items);
    });
    CommonAPI.getTaskList({ count: 5, interest: 1 }).then((res) => {
      setTaskInterestData(res.data.items);
    });
    CommonAPI.getMarketCartList().then((res) => {
      setMarketCartSetting({
        dots: false,
        infinite: true,
        playspeed: 1000,
        slidesToShow: res.data.length === 1 ? 1 : 1,
        slidesToScroll: 1,
        centerMode: true,
        arrows: false,
      });
      setMarketCartData(res.data);
    });
    CommonAPI.getMarketCategory().then((res) => {
      getCount(res.data.category_list);
    });
    CommonAPI.getMyKeywordList().then((res) => {
      setKeywordList(res.data.items);
    });
    CommonAPI.getMarketDocumentList().then((res) => {
      setMarketDocData(res.data.items);
    });
    CommonAPI.getResearchDocumentList().then((res) => {
      setResearchDocData(res.data.items);
    });
    CommonAPI.getBusinessList().then((res) => {
      setBusinessPlanData(res.data.items);
    });
    CommonAPI.getAllFavoriteList().then((res) => {
      const allData = res.data;
      let tmp = [];
      Object.entries(allData).map(([key, value]) => {
        if (key === "선행기술 검색") {
          key = "특허/기술트렌드조사";
        }
        // 구현되지 않은 기능들은 즐겨찾기에서 제외하는 조건문
        if (favoriteIcon[value].pathname === "") return;
        // 그룹웨어 서비스 가입 후 사용 가능한 기능들 제외하는 조건문
        else if (off === "off" && favoriteIcon[value].type === "off") return;
        else {
          if (favoriteIcon[value].type) {
            if (
              (package_idx === "4GX4DZ8Q77QBM5DO" ||
                package_idx === "ULU0W2VSZIN3O48P") &&
              now < package_deadline &&
              favoriteIcon[value].type === "premium"
            ) {
              return (tmp[value] = key);
            }
            // 베이직 패키지 메뉴들
            else if (
              (package_idx === "4GX4DZ8Q77QBM5DO" ||
                package_idx === "IPV8WKP6NQ3U17AO") &&
              now < package_deadline &&
              favoriteIcon[value].type !== "premium"
            ) {
              return (tmp[value] = key);
            }
            // 무료패키지 메뉴들
            else if (
              (package_idx === "4GX4DZ8Q77QBM5DO" ||
                package_idx === "IPV8WKP6NQ3U17AO" ||
                package_idx === "ULU0W2VSZIN3O48P") &&
              favoriteIcon[value].type !== "premium" &&
              favoriteIcon[value].type !== "basic"
            ) {
              return (tmp[value] = key);
            }
          } else {
            return (tmp[value] = key);
          }
        }
      });
      setAllFavoriteList(tmp);
      CommonAPI.getMyFavoriteList().then((res) => {
        const data = res.data.favoriteList;
        let arr = [];
        data.map((item) => {
          arr.push({
            name: tmp[item.work_idx],
            number: item.work_idx,
            icon: favoriteIcon[item.work_idx].src,
            pathname: favoriteIcon[item.work_idx].pathname,
          });
        });
        setMyFavoriteList(arr);
      });
    });

    // 정부공고 팝업
    if (govPjPopupDate && new Date(govPjPopupDate) > new Date()) {
      setGovPopShow(false);
    }
  }, []);

  useEffect(() => {
    CommonAPI.getMarketList({ count: 6, keyword: marketKeyword }).then(
      (res) => {
        setMarketInfoData(res.data.items);
      }
    );
  }, [marketKeyword]);

  const getUserInfo = () => {
    let url = config.gwUrl;
    axios
      .get(url + "/bizoffi-api/member/userInfo.json", {
        headers: {
          "g-token": localStorage.getItem(config.gwKeyToken),
        },
      })
      .then((res) => {
        //console.log(res);
        if (res.data.result == "fail") {
          window.$Global.logout("self");
        } else {
          setUserInfo(res.data.userInfo);
          setUserVacInfo(res.data.userInfo.vacInfo);
          setImage(res.data.userInfo.user_picture);
          setEmpNm(res.data.userInfo.emp_nm);
          setGwAdmin(res.data.userInfo?.gwAdmin);
        }
      });
  };

  const getTask = () => {
    setTaskDataLoading(true);
    CommonAPI.getTaskList({ count: 11, keyword: taskKeyword }).then((res) => {
      setTaskData(res.data.items);
      setTaskDataLoading(false);
    });
  };

  const getCount = (data) => {
    data.map((el) => {
      setMarketInfoCount((prevState) => prevState + el.child.length);
      el.child.map((item) => {
        setFieldCount((prevState) => prevState + item.data_cnt);
      });
    });
  };

  const checkState = (itemState) => {
    let state, stateClassName;
    if (itemState === 2) {
      state = "접수예정";
      stateClassName = "schedule";
    } else if (itemState === 4) {
      state = "접수중";
      stateClassName = "ing";
    } else if (itemState === 8) {
      state = "접수마감";
      stateClassName = "end";
    }
    return [state, stateClassName];
  };

  // const onClickPathMenu = (path, type) => {
  //   if (type === "off" && off === "off") {
  //     alert("그룹웨어 서비스 가입 후 사용 가능한 기능입니다.");
  //     return;
  //   }
  //
  //   const isPremium = type === "premium";
  //   const isBasic = type === "basic";
  //
  //   const isPackageValid = now < package_deadline;
  //
  //   switch (package_idx) {
  //     case "4GX4DZ8Q77QBM5DO":
  //       if (isPremium && isPackageValid) {
  //         window.parent.location.href = path;
  //         return;
  //       }
  //       break;
  //     case "IPV8WKP6NQ3U17AO":
  //       if (!isPremium && isPackageValid) {
  //         window.parent.location.href = path;
  //         return;
  //       }
  //       break;
  //     case "ULU0W2VSZIN3O48P":
  //       if (isPackageValid && (!isPremium || isBasic)) {
  //         window.parent.location.href = path;
  //         return;
  //       }
  //       break;
  //     default:
  //       break;
  //   }
  //
  //   alert("Error");
  // };

  const onClickPathMenu = (path, type, isAdmin) => {
    if (type === "off" && off === "off") {
      alert("그룹웨어 서비스 가입 후 사용 가능한 기능입니다.");
      return;
    }

    if (isAdmin && gwAdmin === false) {
      setShowMenuBlockAlert(true);
      return;
    }

    if (type) {
      // 프리미엄 패키지 메뉴들 (30일 무료체험시에도 사용가능)
      if (
        (package_idx === "4GX4DZ8Q77QBM5DO" ||
          package_idx === "ULU0W2VSZIN3O48P") &&
        now < package_deadline &&
        type === "premium"
      ) {
        window.parent.location.href = path;
      }
      // 베이직 패키지 메뉴들
      else if (
        (package_idx === "4GX4DZ8Q77QBM5DO" ||
          package_idx === "IPV8WKP6NQ3U17AO" ||
          package_idx === "ULU0W2VSZIN3O48P") &&
        now < package_deadline &&
        type !== "premium"
      ) {
        window.parent.location.href = path;
      }
      // 무료패키지 메뉴들
      else if (
        (package_idx === "4GX4DZ8Q77QBM5DO" ||
          package_idx === "IPV8WKP6NQ3U17AO" ||
          package_idx === "ULU0W2VSZIN3O48P") &&
        type !== "premium" &&
        type !== "basic"
      ) {
        window.parent.location.href = path;
      } else {
        setShowPaymentAlert(true);
      }
    } else {
      window.parent.location.href = path;
    }
  };

  const getTodo = () => {
    GwAPI.getTodo().then((res) => {
      res.data.hashMap.todoCompList.map((item) => {
        item.isEdit = false;
      });
      setTodos(res.data.hashMap.todoCompList);
    });
  };

  const addToDo = () => {
    if (todo === "") {
      alert("To do를 입력하세요");
      return;
    }

    let payload = {
      emp_no: Number(empNm),
      contents: todo,
    };

    GwAPI.addTodo(payload).then(() => {
      alert("추가되었습니다.");
      setTodo("");
      setIsWrite(false);
      getTodo();
    });
  };

  const updateTodo = (item) => {
    let payload = {
      idx: item.idx,
      contents: item.contents,
    };

    GwAPI.updateTodo(payload).then((res) => {
      //console.log(res);
      alert("수정되었습니다.");
      getTodo();
    });
  };

  const updateTodoDetail = (item, mode) => {
    let stance = item.stance;
    if (mode === "stance") {
      stance = stance === 0 ? 1 : 0;
    }

    let payload = {
      idx: item.idx,
      contents: item.contents,
      stance: stance,
    };

    GwAPI.updateTodoDetail(payload).then((res) => {
      //console.log(res);
      getTodo();
    });
  };

  const deleteTodo = (item) => {
    let payload = {
      idx: item.idx,
    };

    GwAPI.deleteTodo(payload).then((res) => {
      //console.log(res);
      alert("삭제되었습니다.");
      getTodo();
    });
  };

  const handleTodo = (type, item) => {
    if (type === "update") {
      item.isEdit = !item.isEdit;
    }
    setTodos([...todos]);
  };

  const handleChange = (e, item) => {
    item.contents = e.target.value;
    setTodos([...todos]);
  };

  const openIpnow = () => {
    window.open("https://www.ipnow.co.kr", "_blank");
  };

  const openIncrisel = () => {
    window.open("https://incrisel.co.kr/", "_blank");
  };
  // 근태 기록
  const getAttendInfo = () => {
    GwAPI.getAttendDashBoradInfo().then((res) => {
      let attendFlag = res.data.dataAttendMap.attendInfo.type;
      let attendTypeNm = res.data.dataAttendMap.attendInfo.type_name;
      let attendType = res.data.dataAttendMap.attendEmp.attend_type;
      let sdate = res.data.dataAttendMap.attendInfo.sdate;
      let todate = res.data.dataAttendMap.attendInfo.todate;

      setAttendTodayInfo(res.data.dataAttendMap.attendInfo);
      setDataAttendMap(res.data.dataAttendMap);
      setTypeNm(attendTypeNm);
      setAttendFlag(attendFlag);
      seAttendType(attendType);
      setIsVacation(res.data.dataAttendMap.isVacation);

      if (sdate != todate) {
        setAttendSdate(sdate.substring(11, 16));
      } else {
        setAttendSdate("00:00");
      }
      //seIsAttendNew(res.data.dataAttendMap.isAttendNew);
      if (attendFlag == 900773) {
        setTypeNm("출근전");
      }
    });
  };

  // 출근 처리 상황별 정리 필요
  const onClickAttend = (kind) => {
    let currentTime = moment().format("HH:mm");
    let checkTime = currentTime.replace(":", "");
    let attendStart = "0000";
    let attendEnd = "0500";

    if (attendType != "900782") {
      alert("그룹웨어(사이트) 근태처리 설정이 조회되지 않습니다.");
      return;
    }

    if (attendFlag == "900775") {
      // 휴가
      alert("휴가시에는 출/퇴근처리 대상자가 아닙니다.");
      return;
    } else if (attendFlag == "900776") {
      // 휴직
      alert("휴직시에는 출/퇴근처리 대상자가 아닙니다.");
      return;
    } else if (attendFlag == "900778") {
      // 결근
      alert("결근시에는 근태조정계를 제출하십시오");
      return;
    }

    // 출근일 경우 예외 처리
    if (kind == "900773") {
      if (checkTime >= attendStart && checkTime <= attendEnd) {
        // 00:00 ~ ##:## 사이에는 출근 체크를 할 수 없습니다.
        alert(
          "00:00 ~ " +
            attendEnd.substring(0, 2) +
            ":" +
            attendEnd.substring(2, 4) +
            " 사이에는 출근 체크를 할 수 없습니다."
        );
        return;
      }

      if (attendFlag != "900773" && attendFlag != "") {
        if (!isVacation) {
          // 해당일 초기화된 대상의 근태코드가 휴가유형인 경우 출근 시간 입력 허용
          // 이미 출근 체크를 하셨습니다.
          alert("이미 출근체크를 하셨습니다.");
          return;
        }
      }
    }

    // 퇴근일 경우 예외 처리
    if (kind == "900788") {
      if (checkTime >= attendStart && checkTime <= attendEnd) {
        if (
          !window.confirm(
            "00:00 ~ " +
              attendEnd.substring(0, 2) +
              ":" +
              attendEnd.substring(2, 4) +
              " 사이의 퇴근은 전일 퇴근으로 처리됩니다."
          )
        ) {
          // 00:00 ~ ??:?? 사이의 퇴근은 전일 퇴근으로 처리됩니다.
          return;
        }
      }

      // 퇴근요청시 전일 근무기록이 조회되지 않는 경우 금일 출근처리가 진행되지 않아 퇴근할수 없음
      if (attendTodayInfo.sdate == attendTodayInfo.todate) {
        alert("업무 시작을 하지 않으셨습니다.");
        return;
      }
    }

    let payload = {
      kind: kind,
    };
    // 900773 : 출근전
    // 900775 : 휴가
    // 900776 : 휴직
    // 900778 : 결근
    GwAPI.postAttened(payload).then((res) => {
      alert(res.data.jsonMessage.message);
      getAttendInfo();
      // getAttendWorkInfo();
    });
  };

  const getDocInfo = () => {
    GwAPI.getDocMainDashBoardInfo().then((res) => {
      let resultData = res.data.dataShareMap;
      let result = resultData.result;
      if (result == "success") {
        setTotDocCnt(resultData.total_doc_count);
        setNoticeCount({
          ...noticeCount,
          approval: resultData.total_doc_count,
        });
        if (resultData.docList != null) {
          setDocList(resultData.docList);
        }
        if (resultData.docApprovalList != null) {
          setDocApprovalList(resultData.docApprovalList);
        }
        setCommandCnt(resultData.commandListCount);
        setCommandList(resultData.listCommand);
      }
    });
  };

  const getMailInfo = () => {
    let obj = {
      listType: "true", // true : 받은 메일함, false : 안읽은 메일함
      mailPageSize: 6, // 가져올 갯수
    };

    GwAPI.getMailMainDashBoardInfo(obj).then((res) => {
      let resultData = res.data;
      let result = resultData.dataMailMap;
      setNoticeCount({ ...noticeCount, mail: resultData.unread });
      setUnreadCnt(resultData.unread);
      setMailList(result.results);
    });
  };

  const getSchedule = () => {
    let toDate = moment().format("YYYY-MM-DD");
    let sdate = moment().format("YYYY-MM-DD 00:00:00");
    let edate = moment().format("YYYY-MM-DD 23:59:59");
    let obj = {
      type: "main",
      sdate: sdate,
      edate: edate,
      toDate: toDate,
    };

    GwAPI.getScheduleMainDashBoardInfo(obj).then((res) => {
      let resultData = res.data;
      let result = resultData.toDayList;
      if (result != null) {
        setScheduleList(result);
      }
    });
  };

  const mailView = (obj) => {
    let param = {
      part: "mailView",
      uid: obj.uid,
    };

    GwAPI.getMailViewUrl(param).then((res) => {
      let resultData = res.data;
      window.parent.location.href =
        "/groupware/mail" + "?mailUrl=" + resultData.contentPage;
    });
  };

  const getBoardList = () => {
    let param = {
      listPerPage: "8",
    };

    GwAPI.getBoardMainDashBoardInfo(param).then((res) => {
      let resultData = res.data.dataBoardMap;
      let result = resultData.boardList;
      if (result != null) {
        setBoardList(result);
      }
    });
  };

  const getWizardInfo = () => {
    WizardAPI.wizardInfo().then((res) => {
      //console.log("wizard:", res);
      if (res?.data) {
        setWizardInfo(res.data);
        if (res.data.wizardEnd === "N") {
          setShowWizard(true);
        }
      }
    });
  };

  return (
    <div id="RenewalServiceMain">
      <header>
        <div className="header_profile">
          <div className="profile_info">
            <img
              onError={(e) => {
                e.target.src =
                  config.gwUrl +
                  "/resource/gw/images/kor/g2works/main/basic/user_photo_default.png";
              }}
              src={`${
                config.gwUrl
              }/bizoffi-api/member/userImg.do?type=user&id=${image}&g-token=${localStorage.getItem(
                config.gwKeyToken
              )}`}
            />
            <div className="user_profile">
              <p className="department">{userInfo.branch_name || ""}</p>
              <p className="user_name">
                {userInfo.user_nm} <span>{userInfo.position_name}</span>
              </p>
            </div>
          </div>
          <div className="date_time">
            <Moment format="yyyy-MM-DD(ddd)" />
            <div className="line" />
            <p className="to_work">
              {attendSdate}
              <span>({typeNm})</span>
            </p>
          </div>
          <div className="check_btn">
            {(attendFlag === "900773" ||
              (attendFlag === "900811" && attendSdate === "00:00")) &&
            attendType === "900782" ? (
              <button onClick={() => onClickAttend("900787")}>출근하기</button>
            ) : attendType === "900782" ||
              (attendFlag === "900811" && attendSdate != "00:00") ? (
              <button onClick={() => onClickAttend("900788")}>퇴근하기</button>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="half_box h195 new_schedule">
          <div className="half_box_title">
            <p>새로운 일정</p>
            <p>
              <a href="#" onClick={() => onClickPathMenu("groupware/schedule")}>
                더보기
              </a>
            </p>
          </div>
          <div>
            <ul className="schedule_item_list custom_scroll">
              {scheduleList.map((item, index) => {
                let sDate = "";
                let eDate = "";
                if (item.isAllDay == true) {
                  sDate = "종일";
                } else {
                  sDate = item.eventSdate.substr(11, 5);
                  eDate = item.eventEdate.substr(11, 5);
                }
                return (
                  <li>
                    <p className="schedule_time">
                      {sDate} {eDate && "~"} {eDate}
                    </p>
                    <p className="schedule_name">
                      <a href="#" onClick={() => util.schedulerView(item, "")}>
                        {item.title}
                      </a>
                    </p>
                    <i
                      className="icon_arrow_right_s"
                      onClick={() => util.schedulerView(item, "")}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        {/* <div className="header_fav">
          <h5>
            즐겨찾기
            <i
              className="icon_big_blue_add"
              onClick={() => setShowModal(true)}
            />
          </h5>
          <ul className="fav_list custom_scroll">
            {localStorage.getItem("company_idx") === "MOZQYC25XKG8KEE0" ? (
              <li
                onClick={() =>
                  history.push(
                    "/groupware/boardlist?section=board&command=list&boardno=1725323685684"
                  )
                }
              >
                <i className="fi-rs-lightbulb-head"></i>
                <p>아이디어 저장소</p>
              </li>
            ) : (
              ""
            )}

            {myFavoriteList.map((item) => {
              return (
                <li
                  onClick={() => {
                    if (item.pathname === "") {
                      alert("준비중인 페이지입니다.");
                    } else {
                      onClickPathMenu(item.pathname);
                    }
                  }}
                >
                  <i className={item.icon} />
                  <p>{item.name}</p>
                </li>
              );
            })}
          </ul>
        </div> */}
        <div className="header_fav slider">
          <h5>
            즐겨찾기
            <i
              className="icon_big_blue_add"
              onClick={() => setShowModal(true)}
            />
          </h5>
          <Slider {...sliderSettings}>
            {localStorage.getItem("company_idx") === "MOZQYC25XKG8KEE0" ? (
              <Link to="/groupware/boardlist?section=board&command=list&boardno=1725323685684">
                <i className="fi-rs-lightbulb-head"></i>
                <p>아이디어 저장소</p>
              </Link>
            ) : (
              ""
            )}
            {myFavoriteList.map((item) => {
              return (
                <Link
                  to={item.pathname}
                  onClick={handleClick}
                  draggable="false"
                >
                  <i className={item.icon} />
                  <p>{item.name}</p>
                </Link>
              );
            })}
          </Slider>
        </div>
      </header>
      <section>
        <div className="section_wrap">
          <div className="section_header">
            <p>비즈내비 서비스 이용하기</p>
            {/*<div className="setting_button">*/}
            {/*  <button className="active">Full Mode</button>*/}
            {/*  <button onClick={() => alert("준비중인 기능입니다.")}>*/}
            {/*    Work Mode*/}
            {/*  </button>*/}
            {/*  <button onClick={() => alert("준비중인 기능입니다.")}>*/}
            {/*    Custom*/}
            {/*  </button>*/}
            {/*</div>*/}
          </div>
          <div className="dashboard_wrapper">
            <div className="dashboard_left">
              {/* <div className="dashboard_box h195">
              <ul className="notification_item_list">
                <li onClick={() => onClickPathMenu("groupware/mail", "basic")}>
                  <p>새로운 메일</p>
                  <i className="icon_3d_mail" />
                  <p>{unreadCnt}건</p>
                </li>
                <li
                  onClick={() =>
                    onClickPathMenu(
                      "groupware/office?section=command&command=showCommandList&method=receive_list",
                      "basic"
                    )
                  }
                >
                  <p>새로운 업무</p>
                  <i className="icon_3d_project" />
                  <p>{commandCnt}건</p>
                </li>
                <li
                  onClick={() => onClickPathMenu("groupware/approval", "basic")}
                >
                  <p>새로운 결재</p>
                  <i className="icon_3d_message" />
                  <p>{totDocCnt}건</p>
                </li>
              </ul>
            </div> */}
              <div className="dashboard_box_tab">
                <div className="dashboard_tab">
                  <div
                    style={{
                      background: isHover[0]
                        ? `url(${mailHover})`
                        : tabs === 0
                        ? `url(${mailOn})`
                        : `url(${mailOff})`,
                    }}
                    onMouseEnter={() => {
                      if (tabs !== 0) {
                        isHover[0] = true;
                      }
                      setIsHover([...isHover]);
                    }}
                    onMouseLeave={() => {
                      isHover[0] = false;
                      setIsHover([...isHover]);
                    }}
                    onClick={() => setTabs(0)}
                  >
                    {unreadCnt ? <p className="cnt">{unreadCnt}</p> : ""}
                  </div>
                  <div
                    style={{
                      background: isHover[1]
                        ? `url(${newWorkHover})`
                        : tabs === 1
                        ? `url(${newWorkOn})`
                        : `url(${newWorkOff})`,
                    }}
                    onMouseEnter={() => {
                      if (tabs !== 1) {
                        isHover[1] = true;
                      }
                      setIsHover([...isHover]);
                    }}
                    onMouseLeave={() => {
                      isHover[1] = false;
                      setIsHover([...isHover]);
                    }}
                    onClick={() => setTabs(1)}
                  >
                    {commandCnt ? <p className="cnt">{commandCnt}</p> : ""}
                  </div>
                  <div
                    style={{
                      background: isHover[2]
                        ? `url(${sendPaymentHover})`
                        : tabs === 2
                        ? `url(${sendPaymentOn})`
                        : `url(${sendPaymentOff})`,
                    }}
                    onMouseEnter={() => {
                      if (tabs !== 2) {
                        isHover[2] = true;
                      }
                      setIsHover([...isHover]);
                    }}
                    onMouseLeave={() => {
                      isHover[2] = false;
                      setIsHover([...isHover]);
                    }}
                    onClick={() => setTabs(2)}
                  />
                  <div
                    style={{
                      background: isHover[3]
                        ? `url(${receivePaymentHover})`
                        : tabs === 3
                        ? `url(${receivePaymentOn})`
                        : `url(${receivePaymentOff})`,
                    }}
                    onMouseEnter={() => {
                      if (tabs !== 3) {
                        isHover[3] = true;
                      }
                      setIsHover([...isHover]);
                    }}
                    onMouseLeave={() => {
                      isHover[3] = false;
                      setIsHover([...isHover]);
                    }}
                    onClick={() => setTabs(3)}
                  >
                    {totDocCnt ? <p className="cnt">{totDocCnt}</p> : ""}
                  </div>
                </div>
                {tabs === 0 && (
                  <div className="dashboard_tab_item h335">
                    <ul className="dashboard_tab_item_list">
                      {mailList.map((item, index) => {
                        return (
                          <li>
                            <p className="item_address">{item.from}</p>
                            <p className="item_title">
                              <a
                                href="#"
                                onClick={() => mailView(item, "receive_list")}
                              >
                                {item.subject}
                              </a>
                            </p>
                            <p className="item_date">{item.receiveddate}</p>
                            <i
                              className="icon_arrow_right_s"
                              onClick={() => mailView(item, "receive_list")}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
                {tabs === 1 && (
                  <div className="dashboard_tab_item h335">
                    <ul className="dashboard_tab_item_list">
                      {commandList.map((item, index) => {
                        return (
                          <li
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "flex-start",
                            }}
                          >
                            <div style={{}}>
                              <img
                                style={{ maxWidth: "40px" }}
                                onError={(e) => {
                                  e.target.src =
                                    config.gwUrl +
                                    "/resource/gw/images/kor/g2works/main/basic/user_photo_default.png";
                                }}
                                src={`${
                                  config.gwUrl
                                }/bizoffi-api/member/userImg.do?type=user&id=${
                                  item.user_picture
                                }&g-token=${localStorage.getItem(
                                  config.gwKeyToken
                                )}`}
                              />
                            </div>
                            <div style={{ width: "100%" }}>
                              <p
                                style={{
                                  color: "#606060",
                                  padding: "6px 10px",
                                  fontSize: "10px",
                                }}
                              >
                                {item.name_kor}
                              </p>
                              <div
                                className="item_title"
                                style={{
                                  background: "#f4f4f4",
                                  borderRadius: "5px",
                                  padding: "10px 10px",
                                  marginTop: "4px",
                                  marginLeft: "5px",
                                  textAlign: "left",
                                  width: "95%",
                                  height: "28px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  util.commandView(item, "receive_list")
                                }
                              >
                                {item.content}
                              </div>
                              <p
                                style={{
                                  color: "#606060",
                                  padding: "6px 10px",
                                  fontSize: "10px",
                                }}
                              >
                                {item.subject} / {item.reg_date}
                              </p>
                            </div>
                            {/* <p className="item_date">{item.reg_date}</p>
                          <i className="icon_arrow_right_s" /> */}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
                {tabs === 2 && (
                  <div className="dashboard_tab_item h335">
                    <ul className="dashboard_tab_item_list">
                      {docList.map((item, index) => {
                        return (
                          <li>
                            <p className="item_address">{item.docu_name}</p>
                            <p className="item_title">
                              <a
                                href="#"
                                onClick={() => util.docView(item, "myList")}
                              >
                                {item.subject}
                              </a>
                            </p>
                            <p className="item_date">{item.reg_date}</p>
                            <i className="icon_arrow_right_s" />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
                {tabs === 3 && (
                  <div className="dashboard_tab_item h335">
                    <ul className="dashboard_tab_item_list">
                      {docApprovalList.map((item, index) => {
                        return (
                          <li>
                            <p className="item_address">{item.docu_name}</p>
                            <p className="item_title">
                              <a
                                href="#"
                                onClick={() => util.docView(item, "wait")}
                              >
                                {item.subject}
                              </a>
                            </p>
                            <p className="item_date">{item.reg_date}</p>
                            <i className="icon_arrow_right_s" />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="dashboard_right">
              <div className="half_box_wrapper">
                <div className="divide_box">
                  {/* <div className="half_box h195">
                  <div className="half_box_title">
                    <p>새로운 일정</p>
                    <p>
                      <a
                        href="#"
                        onClick={() => onClickPathMenu("groupware/schedule")}
                      >
                        더보기
                      </a>
                    </p>
                  </div>
                  <div>
                    <ul className="schedule_item_list custom_scroll">
                      {scheduleList.map((item, index) => {
                        let sDate = "";
                        let eDate = "";
                        if (item.isAllDay == true) {
                          sDate = "종일";
                        } else {
                          sDate = item.eventSdate.substr(11, 5);
                          eDate = item.eventEdate.substr(11, 5);
                        }
                        return (
                          <li>
                            <p className="schedule_time">
                              {sDate} {eDate && "~"} {eDate}
                            </p>
                            <p className="schedule_name">
                              <a
                                href="#"
                                onClick={() => util.schedulerView(item, "")}
                              >
                                {item.title}
                              </a>
                            </p>
                            <i
                              className="icon_arrow_right_s"
                              onClick={() => util.schedulerView(item, "")}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div> */}
                  <div className="half_box h378">
                    <div className="half_box_title">
                      <p>게시판</p>
                      <p>
                        <a
                          href="#"
                          onClick={() =>
                            onClickPathMenu(
                              "/groupware/boardlist?section=board&command=list&doc_type=101"
                            )
                          }
                        >
                          더보기
                        </a>
                      </p>
                    </div>
                    <div>
                      <ul className="board_item_list custom_scroll">
                        {boardList.map((item, index) => {
                          let subject = item.subject
                            .replace("<span>", "")
                            .replace("</span>", "");
                          return (
                            <li>
                              <a
                                href="#"
                                onClick={() => util.boardView(item, "")}
                              >
                                <p>{subject}</p>
                              </a>
                              <i
                                className="icon_arrow_right_s"
                                onClick={() => util.boardView(item, "")}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="half_box todo_list">
                  <div className="half_box_title">
                    <p>
                      <i className="icon_fav_on_tack" />
                      TO DO LIST
                    </p>
                    <button
                      className="add_to_do"
                      onClick={() => setIsWrite(!isWrite)}
                    >
                      추가하기
                      <i className="icon_blue_add" />
                    </button>
                    <button
                      className="add_to_do report"
                      onClick={() =>
                        onClickPathMenu("/groupware/report/daily/ins")
                      }
                    >
                      보고하기
                    </button>
                  </div>
                  {isWrite && (
                    <div className="todo_input">
                      <input
                        type="text"
                        placeholder="추가할 To Do를 입력하세요"
                        value={todo}
                        onChange={(e) => setTodo(e.target.value)}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") addToDo();
                        }}
                      />
                      <i className="icon_big_blue_add" onClick={addToDo} />
                    </div>
                  )}
                  <div>
                    <ul className="todo_item_list custom_scroll">
                      {todos.map((item) => {
                        return item.isEdit ? (
                          <li key={item.idx}>
                            <input
                              type="text"
                              value={item.contents}
                              onChange={(e) => handleChange(e, item)}
                            />
                            <i
                              className="icon_checkbox_blue"
                              onClick={() => updateTodo(item)}
                            />
                          </li>
                        ) : item.stance === 0 ? (
                          <li key={item.idx}>
                            {item.stance === 0 ? (
                              <p>
                                <i
                                  className="icon_checkbox_gray"
                                  onClick={() =>
                                    updateTodoDetail(item, "stance")
                                  }
                                />
                                <span>{item.contents}</span>
                              </p>
                            ) : (
                              <p className="done">
                                <i
                                  className="icon_checkbox_blue"
                                  onClick={() =>
                                    updateTodoDetail(item, "stance")
                                  }
                                />
                                <span>{item.contents}</span>
                              </p>
                            )}
                            <i
                              className="icon_pencil_outline"
                              onClick={() => handleTodo("update", item)}
                            />
                            <i
                              className="icon_del_trashcan"
                              style={{ width: "18px", height: "18px" }}
                              onClick={() => deleteTodo(item)}
                            />
                          </li>
                        ) : (
                          ""
                        );
                        // <li key={item.idx}>
                        //   {item.stance === 0 ?
                        //     <p>
                        //       <i
                        //         className="icon_checkbox_gray"
                        //         onClick={() => updateTodoDetail(item, "stance")}
                        //       />
                        //       <span>{item.contents}</span>
                        //     </p> :
                        //     <p className="done">
                        //       <i
                        //         className="icon_checkbox_blue"
                        //         onClick={() => updateTodoDetail(item, "stance")}
                        //       />
                        //       <span>{item.contents}</span>
                        //     </p>
                        //   }
                        //   <i
                        //     className="icon_pencil_outline"
                        //     onClick={() => handleTodo("update", item)}
                        //   />
                        //   <i
                        //     className="icon_del_trashcan"
                        //     style={{ width: "18px", height: "18px" }}
                        //     onClick={() => deleteTodo(item)}
                        //   />
                        // </li>
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="my_section">
            <div className="my_data_room full_box my">
              <h5>My 데이터룸</h5>
              <div className="my_data_wrapper">
                <div className="my_data_task">
                  <div className="my_data_title">
                    <p>정부사업</p>
                    <Slider {...settings}>
                      <p>
                        지난 6개월 사업 개수
                        <span>
                          {window.$Global.commaify(homeData?.biz_total_count)}건
                        </span>
                      </p>
                      <p>
                        지난 일주일 사업 총 금액
                        <span>
                          {window.$Global
                            .numberToKorean(homeData?.biz_week_amount)
                            .substr(
                              0,
                              window.$Global
                                .numberToKorean(homeData?.biz_week_amount)
                                .indexOf("억") + 1
                            ) || 0}
                        </span>
                      </p>
                      <p>
                        오늘 등록된 사업 개수
                        <span>
                          {window.$Global.commaify(homeData?.biz_today_count)}건
                        </span>
                      </p>
                    </Slider>
                  </div>
                  <div className="task_search">
                    <input
                      type="text"
                      placeholder="사업 키워드를 입력하세요"
                      value={taskKeyword}
                      onChange={(e) => setTaskKeyword(e.target.value)}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") getTask();
                      }}
                    />
                    <i
                      className="icon_search_gray"
                      onClick={getTask}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <ul className="task_list">
                    {taskData.length > 0 && taskDataLoading === false ? (
                      taskData?.map((item) => {
                        let [state, stateClassName] = checkState(item.state);

                        return (
                          <li>
                            <p className={`task_item_state ${stateClassName}`}>
                              {state || ""}
                            </p>
                            <p className="task_item_title">{item.title}</p>
                            <p className="task_item_amount">
                              {window.$Global.commaify(item.amount)}
                            </p>
                            <i
                              className="icon_more_arrow_right_small_black"
                              onClick={() =>
                                history.push(
                                  `/taskManage/list?searchKeyword=${item.title}`
                                )
                              }
                              style={{ cursor: "pointer" }}
                            />
                          </li>
                        );
                      })
                    ) : (
                      <li className="mini_loading">
                        <MiniLoading />
                      </li>
                    )}
                  </ul>
                </div>
                <div className="my_data_market">
                  <div className="my_data_title">
                    <p>
                      시장조사 <span>분야&nbsp;</span>
                      {marketInfoCount}개
                    </p>
                    <p>
                      제공되는 시장정보
                      <span>{fieldCount.toLocaleString("en-AU")}건</span>
                    </p>
                  </div>
                  <div className="market_keyword">
                    <ul className="tag">
                      <li onClick={() => setMarketKeyword("메타버스")}>
                        <span>#메타버스</span>
                      </li>
                      <li onClick={() => setMarketKeyword("자율주행")}>
                        <span>#자율주행</span>
                      </li>
                      <li onClick={() => setMarketKeyword("인공지능")}>
                        <span>#인공지능</span>
                      </li>
                      <li onClick={() => setMarketKeyword("반도체")}>
                        <span>#반도체</span>
                      </li>
                    </ul>
                  </div>
                  <div className="market_data custom_scroll">
                    {marketInfoData.length > 0 ? (
                      marketInfoData.map((item) => {
                        return (
                          <div>
                            <img
                              src={window.$Global.getCDN(item.image_key)}
                              alt="이미지"
                              onContextMenu={window.$Global.blockRightClick}
                            />
                            <p>{item.title}</p>
                          </div>
                        );
                      })
                    ) : (
                      <div className="mini_loading">
                        <MiniLoading />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="full_box w room">
              <h5>My 업무룸</h5>
              <div className="my_work_wrapper">
                <div>
                  <h5>나의 관심사업</h5>
                  {taskInterestData.length > 0 ? (
                    <ul className="my_work_task custom_scroll">
                      {taskInterestData.map((item) => {
                        let [state, stateClassName] = checkState(item.state);

                        return (
                          <li>
                            <i className="icon_fav_on_tack" />
                            <p className={`task_item_state ${stateClassName}`}>
                              {state || ""}
                            </p>
                            <p className="task_item_title">{item.title}</p>
                            <i
                              className="icon_arrow_right_s"
                              onClick={() => history.push("/taskManage/my")}
                              style={{ cursor: "pointer" }}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <div className="empty_list">
                      <p>
                        관심 정부과제 키워드를 등록하고,
                        <br />
                        스크랩 해 보세요.
                      </p>
                      <button onClick={() => history.push("taskManage/list")}>
                        최신 정부과제 보러가기
                      </button>
                    </div>
                  )}
                </div>
                <div>
                  <h5>나의 특허 기술트렌드 정보 키워드</h5>
                  {keywordList.length > 0 ? (
                    <ul className="search_keyword_list custom_scroll">
                      {keywordList.slice(0, 6).map((item) => {
                        return (
                          <li>
                            <p>- {item.original_keyword}</p>
                            <i
                              className="icon_arrow_right_s"
                              onClick={() => {
                                history.push({
                                  pathname: "/researchTech/category",
                                  state: {
                                    keyword: item.original_keyword,
                                    isKeywordSearch: 2,
                                  },
                                });
                              }}
                              style={{ cursor: "pointer" }}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <div className="empty_list">
                      <p>
                        간단 키워드 검색으로
                        <br />
                        관심기술의 동향을 파악해 보세요.
                      </p>
                      <button
                        onClick={() => history.push("researchTech/category")}
                      >
                        관심 기술 검색하기
                      </button>
                    </div>
                  )}
                  {/* <ul className="search_keyword_list">
                  {keywordList.slice(0, 6).map((item) => {
                    return (
                      <li>
                        <p>- {item.original_keyword}</p>
                        <i
                          className="icon_arrow_right_s"
                          onClick={() => {
                            history.push({
                              pathname: "/researchTech/category",
                              state: {
                                keyword: item.original_keyword,
                                isKeywordSearch: 1,
                              },
                            });
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </li>
                    );
                  })}
                </ul>*/}
                </div>
                <div>
                  <h5>내가 담은 시장정보</h5>
                  {marketCartData.length > 0 ? (
                    <div className="empty_box">
                      <Slider {...marketCartSetting}>
                        {marketCartData.map((item) => {
                          return (
                            <div className="cart_slide_item">
                              <img
                                src={window.$Global.getCDN(item.image_key)}
                                alt="이미지"
                                onContextMenu={window.$Global.blockRightClick}
                              />
                              <p>{item.title}</p>
                            </div>
                          );
                        })}
                      </Slider>
                    </div>
                  ) : (
                    <div className="empty_list">
                      <p>
                        관심 시장정보를 스크랩하고
                        <br />
                        시장조사 보고서를 작성하세요
                      </p>
                      <button
                        onClick={() => history.push("marketInfo/category")}
                      >
                        시장정보 보러가기
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="full_box_none_bg">
            <div className="dashboard_box">
              <DashBoardTitle text="퀵 기본업무" />
              <ul className="quick_item_list">
                {/* icon_3d_mail */}
                <li>
                  <i
                    className="fi-rs-mail-plus-circle"
                    onClick={() => onClickPathMenu("/groupware/mail", "basic")}
                  />
                  <p>메일</p>
                </li>
                {/* icon_3d_message */}
                <li>
                  <i
                    className="fi-rs-assessment-alt"
                    onClick={() =>
                      onClickPathMenu("groupware/approval", "basic")
                    }
                  />
                  <p>전자결재</p>
                </li>
                {/* icon_3d_to_do_list */}
                <li>
                  <i
                    className="fi-rs-check-circle"
                    onClick={() => onClickPathMenu("groupware/todo", "free")}
                  />
                  <p>To do list</p>
                </li>
                {/* <li
                className="soon"
                onMouseEnter={() => {
                  isHoverModal[0] = true;
                  setIsHoverModal([...isHoverModal]);
                }}
                onMouseLeave={() => {
                  isHoverModal[0] = false;
                  setIsHoverModal([...isHoverModal]);
                }}
              >
                <i className="icon_3d_to_do_list off" />
                <p className="off">To do list</p>
                {isHoverModal[0] && (
                  <div className="modal">{hoverModalItems[0]}</div>
                )}
              </li> */}
                {/* icon_3d_calendar */}
                <li>
                  <i
                    className="fi-rs-calendar-days"
                    onClick={() =>
                      onClickPathMenu("groupware/schedule", "free")
                    }
                  />
                  <p>캘린더</p>
                </li>
                {/* icon_3d_phone */}
                <li>
                  <i
                    className="fi-rs-phone-guide"
                    onClick={() =>
                      onClickPathMenu("groupware/addressbook", "free")
                    }
                  />
                  <p>주소록</p>
                </li>
                {/* icon_3d_vacation */}
                <li>
                  <i
                    className="fi-rs-umbrella-beach"
                    onClick={() =>
                      onClickPathMenu("groupware/vacation/dashboard", "basic")
                    }
                  />
                  <p>휴가신청</p>
                </li>
              </ul>
            </div>
            <div style={{ height: "400px" }}>
              <div className="dashboard_box w831">
                <DashBoardTitle text="업무효율 UP 도구" />
                <ul className="box_list">
                  <li>
                    {/*  icon_3d_stopwatch*/}
                    <i
                      className="fi-rs-time-check"
                      onClick={() =>
                        onClickPathMenu("groupware/attend", "free")
                      }
                    />
                    <p>출퇴근관리</p>
                  </li>
                  <li>
                    {/* icon_3d_paper_aircraft */}
                    <i
                      className="fi-rs-umbrella-beach"
                      onClick={() =>
                        onClickPathMenu("groupware/vacation", "basic")
                      }
                    />
                    <p>휴가관리</p>
                  </li>
                  <li>
                    {/*icon_3d_doughnut_graph  */}
                    <i
                      className="fi-rs-money-check-edit"
                      onClick={() =>
                        onClickPathMenu(
                          "groupware/works?section=extension&command=expenseTotal",
                          "basic"
                        )
                      }
                    />
                    <p>지출관리</p>
                  </li>
                  <li>
                    {/*icon_3d_paper  */}
                    <i
                      className="fi-rs-meeting-alt"
                      onClick={() =>
                        onClickPathMenu(
                          "groupware/works?section=reservation&command=conferenceReserve",
                          "basic"
                        )
                      }
                    />
                    <p>회의실 예약</p>
                  </li>
                  <li>
                    {/* icon_3d_paper */}
                    <i
                      className="fi-rs-car-side"
                      onClick={() =>
                        onClickPathMenu(
                          "groupware/works?section=reservation&command=vehicleReserve",
                          "basic"
                        )
                      }
                    />
                    <p>법인차 예약</p>
                  </li>
                  <li>
                    {/* icon_3d_info */}
                    <i
                      className="fi-rs-employee-man"
                      onClick={() =>
                        onClickPathMenu(
                          "groupware/mypage?section=conAuth&command=lstCustomerContractor",
                          "basic"
                        )
                      }
                    />
                    <p>영업 관리</p>
                  </li>
                </ul>
              </div>
              <div className="dashboard_box w831">
                {/* h195 */}
                <DashBoardTitle text="스마트 협업툴" />
                <ul className="box_list">
                  <li>
                    {/* icon_3d_calendar */}
                    <i
                      className="fi-rs-calendar-days"
                      onClick={() =>
                        onClickPathMenu("groupware/schedule", "free")
                      }
                    />
                    <p>캘린더</p>
                  </li>
                  <li>
                    {/* icon_3d_cloud */}
                    <i
                      className="fi-rs-cloud"
                      onClick={() =>
                        onClickPathMenu("groupware/cloud", "basic")
                      }
                    />
                    <p>클라우드</p>
                  </li>
                  <li>
                    {/* icon_3d_loudspeaker */}
                    <i
                      className="fi-rs-note"
                      onClick={() =>
                        onClickPathMenu(
                          "groupware/works?section=board&command=list&doc_type=101",
                          "free"
                        )
                      }
                    />
                    <p>게시판</p>
                  </li>
                  <li>
                    {/* icon_3d_talk */}
                    <i
                      className="fi-rs-messages"
                      onClick={() =>
                        onClickPathMenu(
                          "groupware/office?section=command&command=showCommandList&method=receive_list&category=chat",
                          "basic"
                        )
                      }
                    />
                    <p>업무챗</p>
                  </li>
                  <li
                    className=""
                    onMouseEnter={() => {
                      isHoverModal[1] = true;
                      setIsHoverModal([...isHoverModal]);
                    }}
                    onMouseLeave={() => {
                      isHoverModal[1] = false;
                      setIsHoverModal([...isHoverModal]);
                    }}
                  >
                    {/* icon_3d_project */}
                    <i
                      className="fi-rs-workflow-alt"
                      onClick={() =>
                        onClickPathMenu(
                          "groupware/office?section=command&command=showCommandList&method=receive_list",
                          "premium"
                        )
                      }
                    />
                    <p>프로젝트룸</p>
                    {/* {isHoverModal[1] && (
                    <div className="modal">{hoverModalItems[1]}</div>
                  )} */}
                  </li>
                  <li>
                    {/* icon_3d_project */}
                    <i
                      className="fi-rs-ballot-check"
                      onClick={() =>
                        onClickPathMenu("groupware/report/daily/ins", "basic")
                      }
                    />
                    <p>업무보고</p>
                  </li>
                  {/* <li
                  className="soon"
                  onMouseEnter={() => {
                    isHoverModal[2] = true;
                    setIsHoverModal([...isHoverModal]);
                  }}
                  onMouseLeave={() => {
                    isHoverModal[2] = false;
                    setIsHoverModal([...isHoverModal]);
                  }}
                >
                  <i
                    className="icon_3d_project off"
                    onClick={() => onClickPathMenu("groupware/cloud")}
                  />
                  <p className="off">업무보고</p>
                  {isHoverModal[2] && (
                    <div className="modal">{hoverModalItems[2]}</div>
                  )}
                </li> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="full_box h597">
            <DashBoardTitle text="업무툴 100+" />
            <ul className="full_box_list">
              <li className="highlight">
                {/* icon_3d_paper_list */}
                <i className="fi-rs-government-flag" />
                <p>정부사업</p>
              </li>
              <li>
                {/* icon_3d_magnifier_blue */}
                <i
                  className="fi-rs-category"
                  onClick={() => onClickPathMenu("taskManage/list")}
                />
                <p>정부사업검색</p>
              </li>
              <li>
                {/* icon_3d_like */}
                <i
                  className="fi-rs-bell-notification-social-media"
                  onClick={() => onClickPathMenu("taskManage/setting")}
                />
                <p>관심사업 알림</p>
              </li>
              {/* <li>
                icon_3d_pencil
                <i
                  className="fi-rs-blog-pencil"
                  onClick={() => onClickPathMenu("business/main")}
                />
                <p>사업계획서 작성</p>
              </li> */}
              <li className="highlight">
                {/* icon_3d_pie_graph_purple */}
                <i className="fi-rs-arrow-trend-up" />
                <p>특허기술트렌드</p>
              </li>
              <li>
                {/* icon_3d_folder */}
                <i
                  className="fi-rs-analyse"
                  onClick={() =>
                    history.push({
                      pathname: "/researchTech/category",
                      state: { isKeywordSearch: 1 },
                    })
                  }
                />
                <p>
                  정부 R&D
                  <br />
                  선행기술 검색
                </p>
              </li>
              <li>
                {/* icon_3d_magnifier_blue */}
                <i
                  className="fi-rs-text-box-edit"
                  onClick={() =>
                    history.push({
                      pathname: "/researchTech/category",
                      state: { isKeywordSearch: 2 },
                    })
                  }
                />
                <p>특허 키워드 검색</p>
              </li>
              <li>
                {/* icon_3d_magnifier_purple */}
                <i
                  className="fi-rs-member-search"
                  onClick={() =>
                    history.push({
                      pathname: "/researchTech/category",
                      state: { isKeywordSearch: 3 },
                    })
                  }
                />
                <p>출원인(특허권자) 검색</p>
              </li>
              <li>
                {/* icon_3d_magnifier_green */}
                <i
                  className="fi-rs-input-numeric"
                  onClick={() =>
                    history.push({
                      pathname: "/researchTech/category",
                      state: { isKeywordSearch: 4 },
                    })
                  }
                />
                <p>
                  출원·등록번호
                  <br />
                  검색
                </p>
              </li>
              <li>
                {/* icon_3d_magnifier_green */}
                <i
                  className="fi-rs-license"
                  onClick={() =>
                    history.push({
                      pathname: "/researchTech/category",
                      state: { isKeywordSearch: 5 },
                    })
                  }
                />
                <p>우리회사 특허검색</p>
              </li>
            </ul>
            <ul className="full_box_list">
              <li className="highlight">
                {/* icon_3d_binocular */}
                <i className="fi-rs-building" />
                <p>기업정보</p>
              </li>
              <li>
                {/* icon_3d_info */}
                <i
                  className="fi-rs-analyse-alt"
                  onClick={() => onClickPathMenu("competitor/main")}
                />
                <p>기업 검색</p>
              </li>
              <li>
                {/* icon_3d_magnifier_purple */}
                <i
                  className="fi-rs-folder-tree"
                  onClick={() => onClickPathMenu("/competitor/search")}
                />
                <p>기업분류검색</p>
              </li>
              <li>
                {/* icon_3d_star */}
                <i
                  className="fi-rs-ranking-stars"
                  onClick={() => onClickPathMenu("/ipHome/grade")}
                />
                <p>기업기술등급</p>
              </li>
              <li className="highlight">
                {/* icon_3d_bar_graph */}
                <i className="fi-rs-marketplace" />
                <p>시장정보조사</p>
              </li>
              <li>
                {/* icon_3d_magnifier_green */}
                <i
                  className="fi-rs-search-dollar"
                  onClick={() => onClickPathMenu("marketInfo/category")}
                />
                <p>시장정보 검색</p>
              </li>
              <li>
                {/* icon_3d_paper */}
                <i
                  className="fi-rs-file-invoice-dollar"
                  onClick={() => onClickPathMenu("marketInfo/category")}
                />
                <p>시장정보 리포트</p>
              </li>
              <li className="soon">
                {/* icon_3d_news  */}
                <i className="fi-rs-newspaper off" />
                <p className="off">시장 뉴스</p>
              </li>
              {/* <li className="highlight">
                <i className="fi-rs-customer-service" />
                <p>업무지원서비스</p>
              </li>
              <li>
                <i
                  className="fi-rs-plane-departure"
                  onClick={() => openIncrisel()}
                />
                <p>해외수출 지원</p>
              </li> */}
              <li className="highlight">
                {/* icon_3d_matching */}
                <i className="fi-rs-rectangle-pro" />
                <p>전문가 매칭</p>
              </li>
              <li>
                {/* icon_3d_lawfirm */}
                <i
                  className="fi-rs-lawyer-man"
                  onClick={() => onClickPathMenu("/lawfirm/list")}
                />
                <p>해외로펌 소개</p>
              </li>
            </ul>
            <ul className="full_box_list">
              <li className="highlight">
                {/* icon_3d_pie_graph_green */}
                <i className="fi-rs-legal" />
                <p>IP관리</p>
              </li>
              <li>
                {/* icon_3d_decoration_star */}
                <i
                  className="fi-rs-document-gear"
                  onClick={() => onClickPathMenu("/patent/list")}
                />
                <p>특허 관리</p>
              </li>
              <li>
                {/* icon_3d_puzzle */}
                <i
                  className="fi-rs-brand"
                  onClick={() => onClickPathMenu("/tradeMark/list")}
                />
                <p>상표 관리</p>
              </li>
              <li>
                {/* icon_3d_pencil */}
                <i
                  className="fi-rs-resources"
                  onClick={() => onClickPathMenu("/design/list")}
                />
                <p>디자인 관리</p>
              </li>
              <li>
                {/* icon_3d_money */}
                <i
                  className="fi-rs-handshake-deal-loan"
                  onClick={() => onClickPathMenu("/trade/list")}
                />
                <p>상표 거래</p>
              </li>
              <li>
                {/* icon_3d_house */}
                <i
                  className="fi-rs-loan"
                  onClick={() => onClickPathMenu("/mortgageLoan/list")}
                />
                <p>IP담보대출 정보</p>
              </li>
              <li>
                {/* icon_3d_shield */}
                <i
                  className="fi-rs-flag-usa"
                  onClick={() => onClickPathMenu("/freeApply/list")}
                />
                <p>미국특허 무료출원</p>
              </li>
              <li className="highlight">
                {/* icon_3d_paper */}
                <i className="fi-rs-memo-circle-check" />
                <p>업무보고</p>
              </li>
              <li>
                {/* icon_3d_paper */}
                <i
                  className="fi-rs-calendar-day"
                  onClick={() =>
                    onClickPathMenu("/groupware/report/daily/ins", "basic")
                  }
                />
                <p>일일보고</p>
              </li>
              <li>
                {/* icon_3d_paper */}
                <i
                  className="fi-rs-calendar-week"
                  onClick={() =>
                    onClickPathMenu("/groupware/report/week/ins", "basic")
                  }
                />
                <p>주간보고</p>
              </li>
            </ul>
            {/*<ul className="full_box_list">*/}
            {/*  <li className="highlight">*/}
            {/*    <i className="icon_3d_message" />*/}
            {/*    <p>전자결재</p>*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <i*/}
            {/*      className="icon_3d_paper"*/}
            {/*      onClick={() =>*/}
            {/*        onClickPathMenu("/groupware/works?mode=default", "basic")*/}
            {/*      }*/}
            {/*    />*/}
            {/*    <p>기안하기</p>*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <i*/}
            {/*      className="icon_3d_paper"*/}
            {/*      onClick={() =>*/}
            {/*        onClickPathMenu(*/}
            {/*          "/groupware/works?section=docList&command=approvalList&list_type=wait",*/}
            {/*          "basic"*/}
            {/*        )*/}
            {/*      }*/}
            {/*    />*/}
            {/*    <p>받은 결재 대기함</p>*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <i*/}
            {/*      className="icon_3d_paper"*/}
            {/*      onClick={() =>*/}
            {/*        onClickPathMenu(*/}
            {/*          "/groupware/works?section=docList&command=approvalList&list_type=end",*/}
            {/*          "basic"*/}
            {/*        )*/}
            {/*      }*/}
            {/*    />*/}
            {/*    <p>받은 결재 완료함</p>*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <i*/}
            {/*      className="icon_3d_paper"*/}
            {/*      onClick={() =>*/}
            {/*        onClickPathMenu(*/}
            {/*          "/groupware/works?section=docList&command=myList",*/}
            {/*          "basic"*/}
            {/*        )*/}
            {/*      }*/}
            {/*    />*/}
            {/*    <p>상신 문서</p>*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <i*/}
            {/*      className="icon_3d_paper"*/}
            {/*      onClick={() =>*/}
            {/*        onClickPathMenu(*/}
            {/*          "/groupware/works?section=docList&command=myList&status=900118",*/}
            {/*          "basic"*/}
            {/*        )*/}
            {/*      }*/}
            {/*    />*/}
            {/*    <p>올린 결재 완료함</p>*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <i*/}
            {/*      className="icon_3d_paper"*/}
            {/*      onClick={() =>*/}
            {/*        onClickPathMenu(*/}
            {/*          "/groupware/works?section=docList&command=dgiReceiveList",*/}
            {/*          "basic"*/}
            {/*        )*/}
            {/*      }*/}
            {/*    />*/}
            {/*    <p>수신참조함</p>*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <i*/}
            {/*      className="icon_3d_paper"*/}
            {/*      onClick={() =>*/}
            {/*        onClickPathMenu(*/}
            {/*          "/groupware/works?section=docList&command=approvalList&list_type=save",*/}
            {/*          "basic"*/}
            {/*        )*/}
            {/*      }*/}
            {/*    />*/}
            {/*    <p>임시저장함</p>*/}
            {/*  </li>*/}
            {/*</ul>*/}
            {/*<ul className="full_box_list">*/}
            {/*  <li className="highlight">*/}
            {/*    <i className="icon_3d_stopwatch" />*/}
            {/*    <p>근태관리</p>*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <i*/}
            {/*      className="icon_3d_paper"*/}
            {/*      onClick={() =>*/}
            {/*        onClickPathMenu(*/}
            {/*          "/groupware/works?section=attend&command=myAttendList",*/}
            {/*          "free"*/}
            {/*        )*/}
            {/*      }*/}
            {/*    />*/}
            {/*    <p>출퇴근 기록</p>*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <i*/}
            {/*      className="icon_3d_paper"*/}
            {/*      onClick={() =>*/}
            {/*        onClickPathMenu(*/}
            {/*          "/groupware/works?section=attend&command=attendMonthStatusNew",*/}
            {/*          "free"*/}
            {/*        )*/}
            {/*      }*/}
            {/*    />*/}
            {/*    <p>부서출근기록</p>*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <i*/}
            {/*      className="icon_3d_paper"*/}
            {/*      onClick={() =>*/}
            {/*        onClickPathMenu(*/}
            {/*          "/groupware/works?section=attend&command=attendStatistics",*/}
            {/*          "free"*/}
            {/*        )*/}
            {/*      }*/}
            {/*    />*/}
            {/*    <p>부서출근통계</p>*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <i*/}
            {/*      className="icon_3d_paper"*/}
            {/*      onClick={() =>*/}
            {/*        onClickPathMenu(*/}
            {/*          "/groupware/works?section=attend&command=attendStatisticsNew",*/}
            {/*          "free"*/}
            {/*        )*/}
            {/*      }*/}
            {/*    />*/}
            {/*    <p>부서근로시간 통계</p>*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <i*/}
            {/*      className="icon_3d_paper"*/}
            {/*      onClick={() =>*/}
            {/*        onClickPathMenu("/groupware/attend/dashboard", "free")*/}
            {/*      }*/}
            {/*    />*/}
            {/*    <p>근태 신청하기</p>*/}
            {/*  </li>*/}
            {/*</ul>*/}
            <ul className="full_box_list">
              {/*<li className="highlight">*/}
              {/*  <i className="icon_3d_paper_aircraft" />*/}
              {/*  <p>휴가현황</p>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <i*/}
              {/*    className="icon_3d_paper"*/}
              {/*    onClick={() =>*/}
              {/*      onClickPathMenu("/groupware/vacation/dashboard", "basic")*/}
              {/*    }*/}
              {/*  />*/}
              {/*  <p>휴가 신청</p>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <i*/}
              {/*    className="icon_3d_paper"*/}
              {/*    onClick={() =>*/}
              {/*      onClickPathMenu(*/}
              {/*        "/groupware/vacationWorks?section=vacation&command=vacationStats&doc_type=",*/}
              {/*        "basic"*/}
              {/*      )*/}
              {/*    }*/}
              {/*  />*/}
              {/*  <p>휴가 사용현황</p>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <i*/}
              {/*    className="icon_3d_paper"*/}
              {/*    onClick={() =>*/}
              {/*      onClickPathMenu(*/}
              {/*        "/groupware/vacationWorks?section=vacation&moveUrl=vacation.admin.do&command=vacationStatusList&siteFlag=biznavi&suMenuCd=100010&menuTitle=%EA%B4%80%EB%A6%AC%EC%9E%90&menuCd=120002",*/}
              {/*        "basic"*/}
              {/*      )*/}
              {/*    }*/}
              {/*  />*/}
              {/*  <p>휴가자 현황</p>*/}
              {/*</li>*/}
              <li className="highlight">
                {/* icon_3d_money */}
                <i className="fi-rs-budget" />
                <p>자금관리</p>
              </li>
              <li>
                {/* icon_3d_paper */}
                <i
                  className="fi-rs-money-coin-transfer"
                  onClick={() =>
                    onClickPathMenu("/groupware/cashflow/landing", "premium")
                  }
                />
                <p>캐시플로우</p>
              </li>
              <li>
                {/* icon_3d_paper */}
                <i
                  className="fi-rs-money-check-edit"
                  onClick={() =>
                    onClickPathMenu(
                      "/groupware/works?section=expense&command=expenseWriteForm&doc_type=expense",
                      "basic"
                    )
                  }
                />
                <p>지출결의서 작성</p>
              </li>
              <li>
                {/* icon_3d_paper */}
                <i
                  className="fi-rs-document-paid"
                  onClick={() =>
                    onClickPathMenu(
                      "/groupware/works?section=extension&command=expenseTotal",
                      "basic"
                    )
                  }
                />
                <p>지출내역 조회</p>
              </li>
              <li className="highlight">
                {/* icon_3d_money */}
                <i className="fi-rs-chart-pie-simple-circle-dollar" />
                <p>자산관리</p>
              </li>
              <li>
                {/* icon_3d_paper */}
                <i
                  className="fi-rs-boxes"
                  onClick={() =>
                    onClickPathMenu(
                      "/groupware/works?section=reservation&command=lstEquipment&dummy=45342",
                      "basic"
                    )
                  }
                />
                <p>비품 관리</p>
              </li>
            </ul>
            {/*<ul className="full_box_list">*/}
            {/*  <li className="highlight">*/}
            {/*    <i className="icon_3d_info" />*/}
            {/*    <p>조직관리</p>*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <i*/}
            {/*      className="icon_3d_paper"*/}
            {/*      onClick={() =>*/}
            {/*        onClickPathMenu(*/}
            {/*          "/groupware/emp?section=corp&command=lstCorp",*/}
            {/*          "free",*/}
            {/*          true*/}
            {/*        )*/}
            {/*      }*/}
            {/*    />*/}
            {/*    <p>법인정보 관리</p>*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <i*/}
            {/*      className="icon_3d_paper"*/}
            {/*      onClick={() =>*/}
            {/*        onClickPathMenu(*/}
            {/*          "/groupware/emp?section=branch&command=listBranch",*/}
            {/*          "free",*/}
            {/*          true*/}
            {/*        )*/}
            {/*      }*/}
            {/*    />*/}
            {/*    <p>조직도 관리</p>*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <i*/}
            {/*      className="icon_3d_paper"*/}
            {/*      onClick={() =>*/}
            {/*        onClickPathMenu(*/}
            {/*          "/groupware/emp?section=code&command=lstCode",*/}
            {/*          "free",*/}
            {/*          true*/}
            {/*        )*/}
            {/*      }*/}
            {/*    />*/}
            {/*    <p>직원/직무 관리</p>*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <i*/}
            {/*      className="icon_3d_paper"*/}
            {/*      onClick={() =>*/}
            {/*        onClickPathMenu(*/}
            {/*          "/groupware/emp?section=empAdmin&command=lstEmp",*/}
            {/*          "free",*/}
            {/*          true*/}
            {/*        )*/}
            {/*      }*/}
            {/*    />*/}
            {/*    <p>인사 관리</p>*/}
            {/*  </li>*/}
            {/*  /!*<li>*!/*/}
            {/*  /!*  <i*!/*/}
            {/*  /!*    className="icon_3d_paper"*!/*/}
            {/*  /!*    onClick={() =>*!/*/}
            {/*  /!*      onClickPathMenu(*!/*/}
            {/*  /!*        "/groupware/emp?section=personnel&command=lstPersonnel",*!/*/}
            {/*  /!*        "free",*!/*/}
            {/*  /!*        true*!/*/}
            {/*  /!*      )*!/*/}
            {/*  /!*    }*!/*/}
            {/*  /!*  />*!/*/}
            {/*  /!*  <p>인사이동 처리</p>*!/*/}
            {/*  /!*</li>*!/*/}
            {/*  /!*<li>*!/*/}
            {/*  /!*  <i*!/*/}
            {/*  /!*    className="icon_3d_paper"*!/*/}
            {/*  /!*    onClick={() =>*!/*/}
            {/*  /!*      onClickPathMenu(*!/*/}
            {/*  /!*        "/groupware/emp?section=personnel&command=lstPersonnelWork",*!/*/}
            {/*  /!*        "free",*!/*/}
            {/*  /!*        true*!/*/}
            {/*  /!*      )*!/*/}
            {/*  /!*    }*!/*/}
            {/*  /!*  />*!/*/}
            {/*  /!*  <p>인사이동 조회</p>*!/*/}
            {/*  /!*</li>*!/*/}
            {/*</ul>*/}
            {/*<ul className="full_box_list">*/}
            {/*  /!*<li>*!/*/}
            {/*  /!*  <i*!/*/}
            {/*  /!*    className="icon_3d_paper"*!/*/}
            {/*  /!*    onClick={() =>*!/*/}
            {/*  /!*      onClickPathMenu("/groupware/report/daily/send", "basic")*!/*/}
            {/*  /!*    }*!/*/}
            {/*  /!*  />*!/*/}
            {/*  /!*  <p>보낸 업무보고</p>*!/*/}
            {/*  /!*</li>*!/*/}
            {/*  /!*<li>*!/*/}
            {/*  /!*  <i*!/*/}
            {/*  /!*    className="icon_3d_paper"*!/*/}
            {/*  /!*    onClick={() =>*!/*/}
            {/*  /!*      onClickPathMenu("/groupware/report/daily/receive", "basic")*!/*/}
            {/*  /!*    }*!/*/}
            {/*  /!*  />*!/*/}
            {/*  /!*  <p>받은 업무보고</p>*!/*/}
            {/*  /!*</li>*!/*/}
            {/*  /!*<li>*!/*/}
            {/*  /!*  <i*!/*/}
            {/*  /!*    className="icon_3d_paper"*!/*/}
            {/*  /!*    onClick={() =>*!/*/}
            {/*  /!*      onClickPathMenu("/groupware/report/daily/temp", "basic")*!/*/}
            {/*  /!*    }*!/*/}
            {/*  /!*  />*!/*/}
            {/*  /!*  <p>임시저장함</p>*!/*/}
            {/*  /!*</li>*!/*/}
            {/*</ul>*/}
          </div>
          <div className="full_box h220">
            <DashBoardTitle text="BIZ 업무매뉴얼" />
            <ul className="full_box_list">
              <li className="highlight">
                {/* icon_3d_paper */}
                <i className="fi-rs-book-open-reader" />
                <p>업무매뉴얼</p>
              </li>
              <li>
                {/* icon_3d_heart */}
                <i
                  className="fi-rs-guide-alt"
                  onClick={() =>
                    onClickPathMenu(
                      "/groupware/infoBoard/main?section=infoBoard1"
                    )
                  }
                />
                <p>
                  BIZ
                  <br />
                  업무매뉴얼
                </p>
              </li>
              <li>
                {/* icon_3d_handshake */}
                <i
                  className="fi-rs-lightbulb-on"
                  onClick={() =>
                    onClickPathMenu(
                      "/groupware/infoBoard/main?section=infoBoard2"
                    )
                  }
                />
                <p>
                  BIZ
                  <br />
                  업무 TIP
                </p>
              </li>
              <li>
                {/* icon_3d_biznavi */}
                <i
                  className="fi-rs-idea-exchange"
                  onClick={() =>
                    onClickPathMenu(
                      "/groupware/infoBoard/main?section=infoBoard3"
                    )
                  }
                />
                <p>
                  비즈내비
                  <br />
                  활용 TIP
                </p>
              </li>
              <li>
                {/* icon_3d_pie_graph */}
                <i
                  className="fi-rs-hand-holding-document"
                  onClick={() =>
                    onClickPathMenu(
                      "/groupware/infoBoard/main?section=infoBoard4"
                    )
                  }
                />
                <p>
                  IP관리의
                  <br />
                  모든 것
                </p>
              </li>
            </ul>
          </div>
          <div className="full_box h220">
            <DashBoardTitle text="Coming Up Next!" />
            <ul className="full_box_list" style={{ gap: "0px" }}>
              <li className="highlight">
                {/* icon_3d_paper_list */}
                <i
                  className="fi-rs-government-flag"
                  onClick={() => onClickPathMenu("groupware/cloud")}
                />
                <p>정부사업</p>
              </li>
              <li
                onMouseEnter={() => {
                  isHoverModal[3] = true;
                  setIsHoverModal([...isHoverModal]);
                }}
                onMouseLeave={() => {
                  isHoverModal[3] = false;
                  setIsHoverModal([...isHoverModal]);
                }}
              >
                {/* icon_3d_pie_graph_pink  */}
                <i
                  className="fi-rs-ranking-podium-empty off"
                  onClick={() => onClickPathMenu("groupware/cloud")}
                />
                <p className="off">정부사업 경쟁률</p>
                {isHoverModal[3] && (
                  <div className="modal">{hoverModalItems[3]}</div>
                )}
              </li>
              <li className="highlight">
                {/* icon_3d_puzzle */}
                <i
                  className="fi-rs-label"
                  onClick={() => onClickPathMenu("groupware/cloud")}
                />
                <p>상표 검색</p>
              </li>
              <li
                onMouseEnter={() => {
                  isHoverModal[8] = true;
                  setIsHoverModal([...isHoverModal]);
                }}
                onMouseLeave={() => {
                  isHoverModal[8] = false;
                  setIsHoverModal([...isHoverModal]);
                }}
              >
                {/* icon_3d_magnifier_orange  */}
                <i
                  className="fi-rs-pen-square off"
                  onClick={() => onClickPathMenu("groupware/cloud")}
                />
                <p className="off">상표명 검색</p>
                {isHoverModal[8] && (
                  <div className="modal">{hoverModalItems[8]}</div>
                )}
              </li>
              <li
                onMouseEnter={() => {
                  isHoverModal[16] = true;
                  setIsHoverModal([...isHoverModal]);
                }}
                onMouseLeave={() => {
                  isHoverModal[16] = false;
                  setIsHoverModal([...isHoverModal]);
                }}
              >
                {/* icon_3d_info  */}
                <i
                  className="fi-rs-member-search off"
                  onClick={() => onClickPathMenu("groupware/cloud")}
                />
                <p className="off">
                  상표권자
                  <br />
                  상표검색
                </p>
                {isHoverModal[16] && (
                  <div className="modal">{hoverModalItems[16]}</div>
                )}
              </li>
              <li className="highlight">
                {/* icon_3d_bar_graph */}
                <i
                  className="fi-rs-shop"
                  onClick={() => onClickPathMenu("groupware/cloud")}
                />
                <p>시장정보조사</p>
              </li>
              <li
                onMouseEnter={() => {
                  isHoverModal[9] = true;
                  setIsHoverModal([...isHoverModal]);
                }}
                onMouseLeave={() => {
                  isHoverModal[9] = false;
                  setIsHoverModal([...isHoverModal]);
                }}
              >
                {/* icon_3d_book_blue  */}
                <i
                  className="fi-rs-newspaper-open off"
                  onClick={() => onClickPathMenu("groupware/cloud")}
                />
                <p className="off">시장뉴스 보기</p>
                {isHoverModal[9] && (
                  <div className="modal">{hoverModalItems[9]}</div>
                )}
              </li>
              <li className="highlight">
                {/* icon_3d_cart */}
                <i
                  className="fi-rs-shopping-cart"
                  onClick={() => onClickPathMenu("groupware/cloud")}
                />
                <p>Biz Mall</p>
              </li>
              <li
                onMouseEnter={() => {
                  isHoverModal[13] = true;
                  setIsHoverModal([...isHoverModal]);
                }}
                onMouseLeave={() => {
                  isHoverModal[13] = false;
                  setIsHoverModal([...isHoverModal]);
                }}
              >
                {/* icon_3d_heart  */}
                <i
                  className="fi-rs-shopping-cart-buyer off"
                  onClick={() => onClickPathMenu("groupware/cloud")}
                />
                <p className="off">Biz 복지몰</p>
                {isHoverModal[13] && (
                  <div className="modal">{hoverModalItems[13]}</div>
                )}
              </li>
              <li className="highlight">
                {/* icon_3d_loudspeaker */}
                <i
                  className="fi-rs-megaphone"
                  onClick={() => onClickPathMenu("groupware/cloud")}
                />
                <p>기업홍보</p>
              </li>
              <li
                onMouseEnter={() => {
                  isHoverModal[14] = true;
                  setIsHoverModal([...isHoverModal]);
                }}
                onMouseLeave={() => {
                  isHoverModal[14] = false;
                  setIsHoverModal([...isHoverModal]);
                }}
              >
                {/* icon_3d_play  */}
                <i
                  className="fi-rs-megaphone-announcement-leader off"
                  onClick={() => onClickPathMenu("groupware/cloud")}
                />
                <p className="off">
                  SNS
                  <br />
                  홍보미디어
                </p>
                {isHoverModal[14] && (
                  <div className="modal">{hoverModalItems[14]}</div>
                )}
              </li>
              <li
                onMouseEnter={() => {
                  isHoverModal[15] = true;
                  setIsHoverModal([...isHoverModal]);
                }}
                onMouseLeave={() => {
                  isHoverModal[15] = false;
                  setIsHoverModal([...isHoverModal]);
                }}
              >
                {/* icon_3d_mail  */}
                <i
                  className="fi-rs-newsletter-subscribe off"
                  onClick={() => onClickPathMenu("groupware/cloud")}
                />
                <p className="off">뉴스레터</p>
                {isHoverModal[15] && (
                  <div className="modal">{hoverModalItems[15]}</div>
                )}
              </li>
            </ul>
          </div>
          {/*<div className="my_data_room full_box">
          <h5>My 데이터룸</h5>
          <div className="my_data_wrapper">
            <div className="my_data_task">
              <div className="my_data_title">
                <p>정부사업</p>
                <Slider {...settings}>
                  <p>
                    지난 6개월 사업 개수
                    <span>
                      {window.$Global.commaify(homeData?.biz_total_count)}건
                    </span>
                  </p>
                  <p>
                    지난 일주일 사업 총 금액
                    <span>
                      {window.$Global
                        .numberToKorean(homeData?.biz_week_amount)
                        .substr(
                          0,
                          window.$Global
                            .numberToKorean(homeData?.biz_week_amount)
                            .indexOf("억") + 1
                        ) || 0}
                    </span>
                  </p>
                  <p>
                    오늘 등록된 사업 개수
                    <span>
                      {window.$Global.commaify(homeData?.biz_today_count)}건
                    </span>
                  </p>
                </Slider>
              </div>
              <div className="task_search">
                <input
                  type="text"
                  placeholder="사업 키워드를 입력하세요"
                  value={taskKeyword}
                  onChange={(e) => setTaskKeyword(e.target.value)}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") getTask();
                  }}
                />
                <i
                  className="icon_search_gray"
                  onClick={getTask}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <ul className="task_list">
                {taskData?.map((item) => {
                  let [state, stateClassName] = checkState(item.state);

                  return (
                    <li>
                      <p className={`task_item_state ${stateClassName}`}>
                        {state || ""}
                      </p>
                      <p className="task_item_title">{item.title}</p>
                      <p className="task_item_amount">
                        {window.$Global.commaify(item.amount)}
                      </p>
                      <i
                        className="icon_more_arrow_right_small_black"
                        onClick={() =>
                          history.push(
                            `/taskManage/list?searchKeyword=${item.title}`
                          )
                        }
                        style={{ cursor: "pointer" }}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="my_data_market">
              <div className="my_data_title">
                <p>
                  시장조사 <span>분야&emsp;</span>
                  {marketInfoCount}개
                </p>
                <p>
                  제공되는 시장정보
                  <span>{fieldCount.toLocaleString("en-AU")}건</span>
                </p>
              </div>
              <div className="market_keyword">
                <ul className="tag">
                  <li onClick={() => setMarketKeyword("메타버스")}>
                    <span>#메타버스</span>
                  </li>
                  <li onClick={() => setMarketKeyword("자율주행")}>
                    <span>#자율주행</span>
                  </li>
                  <li onClick={() => setMarketKeyword("인공지능")}>
                    <span>#인공지능</span>
                  </li>
                  <li onClick={() => setMarketKeyword("반도체")}>
                    <span>#반도체</span>
                  </li>
                </ul>
              </div>
              <div className="market_data">
                {marketInfoData.map((item) => {
                  return (
                    <div>
                      <img
                        src={window.$Global.getCDN(item.image_key)}
                        alt="이미지"
                        onContextMenu={window.$Global.blockRightClick}
                      />
                      <p>{item.title}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
              </div>*/}
          {/* <div className="full_box w">
          <h5>My 업무룸</h5>
          <div className="my_work_wrapper">
            <div>
              <h5>나의 관심사업</h5>
              {taskInterestData.length > 0 ? (
                <ul className="my_work_task">
                  {taskInterestData.map((item) => {
                    let [state, stateClassName] = checkState(item.state);

                    return (
                      <li>
                        <i className="icon_fav_on_tack" />
                        <p className={`task_item_state ${stateClassName}`}>
                          {state || ""}
                        </p>
                        <p className="task_item_title">{item.title}</p>
                        <i
                          className="icon_arrow_right_s"
                          onClick={() => history.push("/taskManage/my")}
                          style={{ cursor: "pointer" }}
                        />
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <div className="empty_list">
                  <p>
                    관심 정부과제 키워드를 등록하고,
                    <br />
                    스크랩 해 보세요.
                  </p>
                  <button onClick={() => history.push("taskManage/list")}>
                    최신 정부과제 보러가기
                  </button>
                </div>
              )}
            </div>
            <div>
              <h5>내가 담은 시장정보</h5>
              {marketCartData.length > 0 ? (
                <div className="empty_box">
                  <Slider {...marketCartSetting}>
                    {marketCartData.map((item) => {
                      return (
                        <div className="cart_slide_item">
                          <img
                            src={window.$Global.getCDN(item.image_key)}
                            alt="이미지"
                            onContextMenu={window.$Global.blockRightClick}
                          />
                          <p>{item.title}</p>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              ) : (
                <div className="empty_list">
                  <p>
                    관심 시장정보를 스크랩하고
                    <br />
                    시장조사 보고서를 작성하세요
                  </p>
                  <button onClick={() => history.push("marketInfo/category")}>
                    시장정보 보러가기
                  </button>
                </div>
              )}
            </div>
            <div>
              <h5>나의 특허 기술트렌드 정보 키워드</h5>
              {keywordList.length > 0 ? (
                <ul className="search_keyword_list">
                  {keywordList.slice(0, 6).map((item) => {
                    return (
                      <li>
                        <p>- {item.original_keyword}</p>
                        <i
                          className="icon_arrow_right_s"
                          onClick={() => {
                            history.push({
                              pathname: "/researchTech/category",
                              state: {
                                keyword: item.original_keyword,
                                isKeywordSearch: 2,
                              },
                            });
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <div className="empty_list">
                  <p>
                    간단 키워드 검색으로
                    <br />
                    관심기술의 동향을 파악해 보세요.
                  </p>
                  <button onClick={() => history.push("researchTech/category")}>
                    관심 기술 검색하기
                  </button>
                </div>
              )}
              {/* <ul className="search_keyword_list">
                {keywordList.slice(0, 6).map((item) => {
                  return (
                    <li>
                      <p>- {item.original_keyword}</p>
                      <i
                        className="icon_arrow_right_s"
                        onClick={() => {
                          history.push({
                            pathname: "/researchTech/category",
                            state: {
                              keyword: item.original_keyword,
                              isKeywordSearch: 1,
                            },
                          });
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div> */}
        </div>
      </section>

      {showModal && (
        <ChangeFavorite
          setShowModal={setShowModal}
          showModal={showModal}
          off={off}
        />
      )}

      {showWizard && <WizardPop setShowWizard={setShowWizard} />}

      {showMenuBlockAlert && (
        <MenuBlockAlert setShowMenuBlockAlert={setShowMenuBlockAlert} />
      )}

      {showPaymentAlert && (
        <PaymentAlert setShowPaymentAlert={setShowPaymentAlert} />
      )}
      {/*govPopShow && <GovProjectPop setGovPopShow={setGovPopShow} />*/}

      {/*<iframe id="popupframe" name="popupframe" width="100%" src="/board.auth.do?command=mainG2worksPopupListView&siteFlag=biznavi" height="100%" style={{ maxWidth:'1280px',position: "fixed", top: "0", zIndex: '99' }}></iframe>*/}
    </div>
  );
};

export default RenewalServiceMain;
