import React, {useEffect, useState} from "react";
import "./css/PDFDocument.scss";
import WithPreventScroll from "../../HOC/WithPreventScroll";
import CommonAPI from "../../API/common";

function PDFDocument({file, setFile}) {
	const [page, setPage] = useState(0);
	const [pdf, setPDF] = useState({preview: []});

	console.log(file);
	useEffect(() => {
		CommonAPI.getTaskPDFInfo(file.idx).then(res => {
			console.log(res.data);
			setPDF(res.data);
		})
	}, []);

	const Empty = () => {
		return <div/>
	};

	if (!file) return null;

	const ComponentWithDimmed = WithPreventScroll(Empty, file);

	return (
		<div id="PDFDocument">
			<ComponentWithDimmed/>

			<img className="pdf_image" src={window.$Global.getCDN(pdf?.preview[page]?.file_key)} alt="PDF 이미지"/>
			<button className="icon_exit ir_txt" onClick={() => setFile(null)}>닫기 버튼</button>
			<button className="icon_more_arrow_left_black btn_left ir_txt" onClick={()=> page !== 0 ? setPage(page-1):null}>이전 버튼</button>
			<button className="icon_more_arrow_right_black btn_right ir_txt" onClick={()=> page < pdf?.preview?.length - 1 ? setPage(page+1):null}>다음 버튼</button>

			<span className="page_info">Page {page + 1} of {pdf?.preview?.length}</span>
		</div>
	)
}

export default PDFDocument;