import { Link, useHistory } from "react-router-dom";
import React from "react";

export default {
  DrawTab(arr, activeTab, className, location, gwAdmin, noticeCount) {
    const isViewTypeIpnow = window.$Global.isIpnow();
    const currentPath = window.location;
    const date = new Date().getTime(); // 메뉴 탭 새로고침을 위한 date timestamp
    const history = useHistory();
    let userMail = false;
    if (localStorage.getItem("company_code") == "120130512335") {
      userMail = true;
    }

    const borderStyle = (item) => {
      let str = "";
      if (item.url.includes("groupware")) {
        if (window.$Global.matchGroupwareMenu(item, location)) {
          str = "4px solid #0078F1";
        }
      } else {
        if (item.url.includes(currentPath.pathname)) {
          if (window.$Global.isIpSystem(currentPath.pathname)) {
            str = "4px solid #FFBB55";
          } else {
            str = "4px solid #0078F1";
          }
        }
      }
      return str;
    };

    const handleNavigate = (item) => {
      if (item.redirectURL) {
        history.push(item.redirectURL);
      } else {
        if (
          item.name === "메일" ||
          item.name === "일정관리" ||
          item.name === "클라우드"
        ) {
          history.push(`${item.url}?temp=${date}`);
        } else {
          history.push(item.url);
        }
      }
    };
    return arr.map((item, idx) => {
      if (item.isAdmin && !gwAdmin) {
        return <></>;
      }
      // else if (!userMail && item.name === "캐시플로우") {
      //   return <></>;}
      else {
        return (
          <li
            key={idx}
            className={
              activeTab === (item.activeTab || idx) + 1
                ? !isViewTypeIpnow
                  ? className
                  : className + "_ipnow"
                : ""
            }
            style={{
              borderBottom: borderStyle(item),
            }}
          >
            <a
              onClick={() => handleNavigate(item)}
              className={isViewTypeIpnow ? "a_ipnow" : "a_biznavi"}
            >
              {item.name}
            </a>
            {item.name === "메일" && Boolean(noticeCount?.mail) && (
              <p className="notice_icon">New</p>
            )}
            {item.name === "전자결재" && Boolean(noticeCount?.approval) && (
              <p className="notice_icon">New</p>
            )}
            {Boolean(item.child_Tab?.length) && (
              <>
                <i className="icon_more_arrow_down" />
                <ul className="child_tab_list">
                  {item.child_Tab.map((item2) => {
                    return (
                      <li key={item2.url}>
                        <Link to={item2.url}>{item2.name}</Link>
                      </li>
                    );
                  })}
                </ul>
              </>
            )}
          </li>
        );
      }
    });
  },
};
