import React, { useContext, useEffect, useState } from "react";
import qs from "query-string";
import MemberContext from "../../store/Member";
import CheckBox from "../common/CheckBox";
import ToggleButtonWithSpan from "../common/ToggleButtonWithSpan";
import InfiniteScroll from "react-infinite-scroll-component";
import "./css/CostList.scss";
import CostAPI from "../../API/cost/index";
import _ from "lodash";
import PopupComment from "./PopupComment";
import { patentCostTypeValueInfo } from "../../types/patentCostType";
import { Link } from "react-router-dom";
import "../../types/nationType";
import { nationType } from "../../types/nationType";

function CostList({ history, location }) {
  let table_head_tag = [],
    tab_tags = [],
    options_tag = [];

  const { nation } = qs.parse(location.search);

  const { auth, manage_company_idx } = useContext(MemberContext);
  const [check, setCheck] = useState({
    patent: true,
    tradeMark: false,
    design: false,
  });
  const count = 100;
  const [curPage, setCurPage] = useState(1);
  const [data, setData] = useState({ items: [] });
  const [mouse, setMouse] = useState(false);

  const [tabState, setTabState] = useState(false); // Tab 설정 활성화 여부
  const [selectedBasics, setSelectedBasics] = useState(true); // Tab 기본으로 설정 on / off
  const [table_head, setTableHead] = useState([]); // 테이블 헤더값

  const [keyword, setKeyword] = useState("");
  const [keywordType, setKeywordType] = useState(0);

  const [billingContentList, setBillingContentList] = useState([]);
  const [nationList, setNationList] = useState([]);
  const [moreTab, setMoreTab] = useState({ nation: true, claimContent: true });
  const [hasMore, setHasMore] = useState(true);

  const isPatent = check.patent;
  const isTradeMark = check.tradeMark;
  const isDesign = check.design;
  const isCompany = window.$Global.checkAuth("특허회사", auth);
  const isAgent = window.$Global.checkAuth("대리인", auth);

  const [popupCommentShow, setPopupCommentShow] = useState(false);
  const [comment, setComment] = useState("");
  const [popuSelectIndex, setPopuSelectIndex] = useState(null);
  const [filterCheckList, setFilterCheckList] = useState([]);

  const [btns_state_tab, setBtnsStateTab] = useState([]);
  const [columnShow, setColumnShow] = useState({
    nation: window.localStorage.getItem("costManage_nation") !== "0",
    manage_number:
      window.localStorage.getItem("costManage_manage_number") !== "0",
    agent_manage_number:
      window.localStorage.getItem("costManage_agent_manage_number") !== "0",
    billing_number:
      window.localStorage.getItem("costManage_billing_number") !== "0",
    apply_number:
      window.localStorage.getItem("costManage_apply_number") !== "0",
    type: window.localStorage.getItem("costManage_type") !== "0",
    gc: window.localStorage.getItem("costManage_gc") !== "0",
    surtax: window.localStorage.getItem("costManage_surtax") !== "0",
    sum_total: true,
    agent_commission:
      window.localStorage.getItem("costManage_agent_commission") !== "0",
    sum_commission:
      window.localStorage.getItem("costManage_sum_commission") !== "0",
    scheduled_claim_at:
      window.localStorage.getItem("costManage_scheduled_claim_at") !== "0",
    real_claim_at:
      window.localStorage.getItem("costManage_real_claim_at") !== "0",
    state: window.localStorage.getItem("costManage_state") !== "0",
    deposit_at: window.localStorage.getItem("costManage_deposit_at") !== "0",
    for_gf: window.localStorage.getItem("costManage_for_gf") !== "0",
    for_agent_commision:
      window.localStorage.getItem("costManage_for_agent_commision") !== "0",
  });

  const initParams = {
    count: count,
    keyword: keyword,
    type: keywordType,
    nation: nation,
  };

  useEffect(async () => {
    if (window.localStorage.getItem("costManage_tab_init")) {
      window.localStorage.setItem("costManage_nation", "1");
    } else {
      setSelectedBasics(false);
    }

    let result = {};
    if (isCompany) {
      result = await CostAPI.getSortField().then((res) => res.data);
    } else if (isAgent) {
      result = await CostAPI.getSortFieldAsAgent(manage_company_idx).then(
        (res) => res.data
      );
    }
    setNationList(result.nation);
    setBillingContentList(result.type);
    setFilterCheckList(result.type.map(() => false).concat([true]));
  }, [auth]);

  useEffect(() => {
    if (isCompany) {
      if (nation == 1) {
        if (isPatent || isDesign) {
          setBtnsStateTab([
            {
              text: "청구번호",
              key: "billing_number",
              type: 0,
              checked:
                window.localStorage.getItem("costManage_billing_number") !==
                "0",
              defaultChecked: 1,
            },
            {
              text: "출원번호",
              key: "apply_number",
              type: 6,
              checked:
                window.localStorage.getItem("costManage_apply_number") !== "0",
              defaultChecked: 1,
            },
            {
              text: "청구사유",
              key: "type",
              type: null,
              checked: window.localStorage.getItem("costManage_type") !== "0",
              defaultChecked: 1,
            },
            {
              text: "청구비용",
              key: "sum_total_domestic",
              type: null,
              checked:
                window.localStorage.getItem("costManage_sum_total_domestic") !==
                "0",
              defaultChecked: 1,
            },
            {
              text: "부가세",
              key: "sum_surtax",
              type: null,
              checked: window.localStorage.getItem("costManage_surtax") !== "0",
              defaultChecked: 1,
            },
            {
              text: "합계",
              key: "sum_total",
              type: null,
              checked:
                window.localStorage.getItem("costManage_sum_total") !== "0",
              defaultChecked: 1,
            },
            {
              text: "청구일",
              key: "real_claim_at",
              type: 4,
              checked:
                window.localStorage.getItem("costManage_real_claim_at") !== "0",
              defaultChecked: 1,
            },
            {
              text: "검토",
              key: "state",
              type: null,
              checked: window.localStorage.getItem("costManage_state") !== "0",
              defaultChecked: 1,
            },
            {
              text: "송금 완료",
              key: "deposit_at",
              type: null,
              checked:
                window.localStorage.getItem("costManage_deposit_at") !== "0",
              defaultChecked: 1,
            },
            {
              text: "상세보기",
              key: "more",
              type: null,
              checked: 1,
              defaultChecked: 1,
            },
          ]);
        } else if (isTradeMark) {
          setBtnsStateTab([
            {
              text: "청구번호",
              key: "billing_number",
              type: 0,
              checked:
                window.localStorage.getItem("costManage_billing_number") !==
                "0",
              defaultChecked: 1,
            },
            {
              text: "출원번호",
              key: "apply_number",
              type: 6,
              checked:
                window.localStorage.getItem("costManage_apply_number") !== "0",
              defaultChecked: 1,
            },
            {
              text: "청구비용",
              key: "sum_total_domestic",
              type: null,
              checked:
                window.localStorage.getItem("costManage_sum_total_domestic") !==
                "0",
              defaultChecked: 1,
            },
            {
              text: "부가세",
              key: "sum_surtax",
              type: null,
              checked: window.localStorage.getItem("costManage_surtax") !== "0",
              defaultChecked: 1,
            },
            {
              text: "합계",
              key: "sum_total",
              type: null,
              checked:
                window.localStorage.getItem("costManage_sum_total") !== "0",
              defaultChecked: 1,
            },
            {
              text: "청구일",
              key: "real_claim_at",
              type: 4,
              checked:
                window.localStorage.getItem("costManage_real_claim_at") !== "0",
              defaultChecked: 1,
            },
            {
              text: "검토",
              key: "state",
              type: null,
              checked: window.localStorage.getItem("costManage_state") !== "0",
              defaultChecked: 1,
            },
            {
              text: "송금 완료",
              key: "deposit_at",
              type: null,
              checked:
                window.localStorage.getItem("costManage_deposit_at") !== "0",
              defaultChecked: 1,
            },
            {
              text: "상세보기",
              key: "more",
              type: null,
              checked: 1,
              defaultChecked: 1,
            },
          ]);
        }
      } else if (nation == 2) {
        if (isPatent) {
          setBtnsStateTab([
            {
              text: "청구번호",
              key: "billing_number",
              type: 0,
              checked:
                window.localStorage.getItem("costManage_billing_number") !==
                "0",
              defaultChecked: 1,
            },
            {
              text: "국가",
              key: "billing_number",
              type: null,
              checked:
                window.localStorage.getItem("costManage_billing_number") !==
                "0",
              defaultChecked: 1,
            },
            {
              text: "출원번호",
              key: "apply_number",
              type: 6,
              checked:
                window.localStorage.getItem("costManage_apply_number") !== "0",
              defaultChecked: 1,
            },
            {
              text: "청구사유",
              key: "type",
              type: null,
              checked: window.localStorage.getItem("costManage_type") !== "0",
              defaultChecked: 1,
            },
            {
              text: "청구비용",
              key: "sum_total_domestic",
              type: null,
              checked:
                window.localStorage.getItem("costManage_sum_total_domestic") !==
                "0",
              defaultChecked: 1,
            },
            {
              text: "부가세",
              key: "sum_surtax",
              type: null,
              checked: window.localStorage.getItem("costManage_surtax") !== "0",
              defaultChecked: 1,
            },
            {
              text: "합계",
              key: "sum_total",
              type: null,
              checked:
                window.localStorage.getItem("costManage_sum_total") !== "0",
              defaultChecked: 1,
            },
            {
              text: "청구일",
              key: "real_claim_at",
              type: 4,
              checked:
                window.localStorage.getItem("costManage_real_claim_at") !== "0",
              defaultChecked: 1,
            },
            {
              text: "검토",
              key: "state",
              type: null,
              checked: window.localStorage.getItem("costManage_state") !== "0",
              defaultChecked: 1,
            },
            {
              text: "송금 완료",
              key: "deposit_at",
              type: null,
              checked:
                window.localStorage.getItem("costManage_deposit_at") !== "0",
              defaultChecked: 1,
            },
            {
              text: "상세보기",
              key: "more",
              type: null,
              checked: 1,
              defaultChecked: 1,
            },
          ]);
        }
      }
    } else if (isAgent) {
      if (nation == 1) {
        if (isPatent || isDesign) {
          setBtnsStateTab([
            {
              text: "청구번호",
              key: "billing_number",
              type: 0,
              checked:
                window.localStorage.getItem("costManage_billing_number") !==
                "0",
              defaultChecked: 1,
            },
            {
              text: "출원번호",
              key: "apply_number",
              type: 6,
              checked:
                window.localStorage.getItem("costManage_apply_number") !== "0",
              defaultChecked: 1,
            },
            {
              text: "청구사유",
              key: "type",
              type: null,
              checked: window.localStorage.getItem("costManage_type") !== "0",
              defaultChecked: 1,
            },
            {
              text: "청구비용",
              key: "sum_total_domestic",
              type: null,
              checked:
                window.localStorage.getItem("costManage_sum_total_domestic") !==
                "0",
              defaultChecked: 1,
            },
            {
              text: "부가세",
              key: "sum_surtax",
              type: null,
              checked: window.localStorage.getItem("costManage_surtax") !== "0",
              defaultChecked: 1,
            },
            {
              text: "합계",
              key: "sum_total",
              type: null,
              checked:
                window.localStorage.getItem("costManage_sum_total") !== "0",
              defaultChecked: 1,
            },
            {
              text: "청구일",
              key: "real_claim_at",
              type: 4,
              checked:
                window.localStorage.getItem("costManage_real_claim_at") !== "0",
              defaultChecked: 1,
            },
            {
              text: "기업검토",
              key: "state",
              type: null,
              checked: window.localStorage.getItem("costManage_state") !== "0",
              defaultChecked: 1,
            },
            {
              text: "입금완료",
              key: "deposit_at",
              type: 5,
              checked:
                window.localStorage.getItem("costManage_deposit_at") !== "0",
              defaultChecked: 1,
            },
            {
              text: "상세보기",
              key: "more",
              type: null,
              checked: 1,
              defaultChecked: 1,
            },
          ]);
        } else if (isTradeMark) {
          setBtnsStateTab([
            {
              text: "청구번호",
              key: "billing_number",
              type: 0,
              checked:
                window.localStorage.getItem("costManage_billing_number") !==
                "0",
              defaultChecked: 1,
            },
            {
              text: "출원번호",
              key: "apply_number",
              type: 6,
              checked:
                window.localStorage.getItem("costManage_apply_number") !== "0",
              defaultChecked: 1,
            },
            {
              text: "청구비용",
              key: "sum_total_domestic",
              type: null,
              checked:
                window.localStorage.getItem("costManage_sum_total_domestic") !==
                "0",
              defaultChecked: 1,
            },
            {
              text: "부가세",
              key: "sum_surtax",
              type: null,
              checked: window.localStorage.getItem("costManage_surtax") !== "0",
              defaultChecked: 1,
            },
            {
              text: "합계",
              key: "sum_total",
              type: null,
              checked:
                window.localStorage.getItem("costManage_sum_total") !== "0",
              defaultChecked: 1,
            },
            {
              text: "청구일",
              key: "real_claim_at",
              type: 4,
              checked:
                window.localStorage.getItem("costManage_real_claim_at") !== "0",
              defaultChecked: 1,
            },
            {
              text: "기업검토",
              key: "state",
              type: null,
              checked: window.localStorage.getItem("costManage_state") !== "0",
              defaultChecked: 1,
            },
            {
              text: "입금완료",
              key: "deposit_at",
              type: 5,
              checked:
                window.localStorage.getItem("costManage_deposit_at") !== "0",
              defaultChecked: 1,
            },
            {
              text: "상세보기",
              key: "more",
              type: null,
              checked: 1,
              defaultChecked: 1,
            },
          ]);
        }
      } else if (nation == 2) {
        if (isPatent) {
          setBtnsStateTab([
            {
              text: "청구번호",
              key: "billing_number",
              type: 0,
              checked:
                window.localStorage.getItem("costManage_billing_number") !==
                "0",
              defaultChecked: 1,
            },
            {
              text: "국가",
              key: "billing_number",
              type: null,
              checked:
                window.localStorage.getItem("costManage_billing_number") !==
                "0",
              defaultChecked: 1,
            },
            {
              text: "출원번호",
              key: "apply_number",
              type: 6,
              checked:
                window.localStorage.getItem("costManage_apply_number") !== "0",
              defaultChecked: 1,
            },
            {
              text: "청구사유",
              key: "type",
              type: null,
              checked: window.localStorage.getItem("costManage_type") !== "0",
              defaultChecked: 1,
            },
            {
              text: "청구비용",
              key: "sum_total_domestic",
              type: null,
              checked:
                window.localStorage.getItem("costManage_sum_total_domestic") !==
                "0",
              defaultChecked: 1,
            },
            {
              text: "부가세",
              key: "sum_surtax",
              type: null,
              checked: window.localStorage.getItem("costManage_surtax") !== "0",
              defaultChecked: 1,
            },
            {
              text: "합계",
              key: "sum_total",
              type: null,
              checked:
                window.localStorage.getItem("costManage_sum_total") !== "0",
              defaultChecked: 1,
            },
            {
              text: "청구일",
              key: "real_claim_at",
              type: 4,
              checked:
                window.localStorage.getItem("costManage_real_claim_at") !== "0",
              defaultChecked: 1,
            },
            {
              text: "기업검토",
              key: "state",
              type: null,
              checked: window.localStorage.getItem("costManage_state") !== "0",
              defaultChecked: 1,
            },
            {
              text: "입금완료",
              key: "deposit_at",
              type: 5,
              checked:
                window.localStorage.getItem("costManage_deposit_at") !== "0",
              defaultChecked: 1,
            },
            {
              text: "상세보기",
              key: "more",
              type: null,
              checked: 1,
              defaultChecked: 1,
            },
          ]);
        }
      }
    }
  }, [auth, nation, check]);

  useEffect(() => {
    setCurPage(1);
    setDataList({
      page: 1,
      count: count,
      nation: check.tradeMark || check.design ? 1 : nation,
    }).then((require) => {});
  }, [check, nation]);

  useEffect(() => {
    let result = window.$Global.notTd(20, "데이터가 없습니다");
    if (window.$Global.isEmptyObject(data)) return;
  }, [data, columnShow]);

  const drawBillingAt = (item, index) => {
    if (isCompany) {
      return (
        <td>
          {window.$Global.convertDate(
            !isTradeMark ? item.real_claim_at : item.billing_at
          )}
        </td>
      );
    } else if (isAgent) {
      return (
        <td>
          {(item.state != 2 && item.real_claim_at) || item.billing_at ? (
            <p>
              {window.$Global.convertDate(
                !isTradeMark ? item.real_claim_at : item.billing_at
              )}
            </p>
          ) : (
            <button
              className="btn_success"
              onClick={() => onClickBilling(index)}
              style={{
                backgroundColor: "#0095ae",
                width: "64px",
                height: "30px",
                color: "white",
              }}
            >
              청구하기
            </button>
          )}
        </td>
      );
    }
  };

  const drawPay = (item, index) => {
    if (isCompany) {
      return (
        <td>
          {item.state == 4 ? (
            <p className="color_red">
              {window.$Global.convertDate(item.deposit_at || item.pay_at)}
            </p>
          ) : (
            <button
              className="btn_success"
              onClick={() => onClickPaySuccess(index)}
              style={{
                backgroundColor: "#0095ae",
                width: "64px",
                height: "30px",
                color: "white",
              }}
            >
              완료하기
            </button>
          )}
        </td>
      );
    } else if (isAgent) {
      return (
        <td>
          {item.state == 4 ? (
            <p className="color_red">
              {window.$Global.convertDate(item.deposit_at)}
            </p>
          ) : (
            <p className="color_red">미입금</p>
          )}
        </td>
      );
    }
  };

  const drawSelect = (item, index) => {
    if (isCompany) {
      if (item.state == 4) {
        return (
          <td>
            <p>송금 완료</p>
          </td>
        );
      } else {
        return (
          <td>
            <select
              value={item.state}
              onChange={(e) => onChangeConfirm(e, index)}
            >
              <option value={1}>미확인</option>
              <option value={2}>수정요청</option>
              <option value={3}>확인</option>
            </select>
          </td>
        );
      }
    } else if (isAgent) {
      let str = "";
      if (item.state == 0) {
        str = "미청구";
      } else if (item.state == 1) {
        str = "미확인";
      } else if (item.state == 2) {
        str = "수정요청";
      } else if (item.state == 3 || item.state == 4) {
        str = "확인";
      }
      return (
        <td>
          <p>{str}</p>
        </td>
      );
    }
  };

  const onChangeTabChecked = (input, text) => {
    let copy_tab = window.$Global.cloneObject(btns_state_tab);
    let copy_arr_tab = Object.keys(copy_tab).map((key) => copy_tab[key]);
    let item = copy_arr_tab.find((item) => item.text === text);
    item.checked = Number(input.target.checked);

    setBtnsStateTab(copy_arr_tab);

    let copy = _.cloneDeep(columnShow);
    copy[item.key] = input.target.checked;
    setColumnShow(copy);

    if (item.checked) {
      table_head_tag.push(<th>{item.text}</th>);
      setTableHead(table_head.concat([item.text]));
    } else {
      let findItem = table_head.find((item) => {
        return item === item.text;
      });
      let idx = table_head.indexOf(findItem);
      setTableHead(table_head.splice(idx, 1));
    }
  };

  const onClickToggleselectedBasics = () => {
    // 기본으로 설정 ON 일때 체크 값 기본으로 설정
    if (!selectedBasics) {
      btns_state_tab.forEach((item) => {
        item.checked = item.defaultChecked;
      });
      setBtnsStateTab(btns_state_tab);
      let keys = Object.keys(columnShow);
      let copy = _.cloneDeep(columnShow);

      for (let i in keys) {
        copy[keys[i]] = true;
      }
      setColumnShow(copy);
    }
    setSelectedBasics(!selectedBasics);
  };

  const setDataList = async (param) => {
    let result = await authCheckAPI(param || { page: curPage, ...initParams });
    setData(result);
    setCurPage(curPage + 1);
    checkHasMore(result);
  };

  const nextList = async () => {
    let result = await authCheckAPI({ page: curPage, ...initParams });
    result.items = data.items.concat(result.items);
    setData(result);
    setCurPage(curPage + 1);
    checkHasMore(result);
  };

  const authCheckAPI = async (param) => {
    let result = {};

    if (isPatent) {
      if (isCompany) {
        result = await CostAPI.getPatentCostList(
          param || { page: curPage, count: count }
        ).then((res) => res.data);
      } else if (isAgent) {
        result = await CostAPI.getAgentPatentCostList(
          manage_company_idx,
          param || { page: curPage, count: count }
        ).then((res) => res.data);
      } else if (isDesign) {
        result = await CostAPI.getDesignCostList(
          manage_company_idx,
          param || { page: curPage, count: count }
        ).then((res) => res.data);
      }
    } else if (isTradeMark) {
      if (isCompany) {
        result = await CostAPI.getTradeMarkCostList(
          param || { page: curPage, count: count }
        ).then((res) => res.data);
      } else if (isAgent) {
        result = await CostAPI.getAgentTradeMarkCostList(
          manage_company_idx,
          param || { page: curPage, count: count }
        ).then((res) => res.data);
      }
    } else if (isDesign) {
      if (isCompany) {
        result = await CostAPI.getDesignCostList(
          manage_company_idx,
          param || { page: curPage, count: count }
        ).then((res) => res.data);
      } else if (isAgent) {
        result = await CostAPI.getDesignCostListAsAgent(
          manage_company_idx,
          param || { page: curPage, count: count }
        ).then((res) => res.data);
      }
    }
    return result;
  };

  const searchList = async () => {
    let param = {
      page: 1,
      ...initParams,
    };
    let result = await authCheckAPI(param);
    setCurPage(2);
    setData(result);
    checkHasMore(result);
  };

  const onClickFilterCheckBox = (e, text, index, type) => {
    let copy = _.cloneDeep(filterCheckList);
    copy = copy.map((item, idx) => {
      if (idx != index) {
        return false;
      }
    });
    copy[index] = e.target.checked;

    if (copy.findIndex((item) => item == true) == -1) {
      copy[copy.length - 1] = true;
    }

    setFilterCheckList(copy);
    onClickMoreStateDisplay(type);
    if (copy[index]) {
      onClickFilter(text);
    } else {
      searchList({
        count: count,
        nation: nation,
      });
    }
  };

  const onClickFilter = async (payload) => {
    let param = {
      page: 1,
      count: count,
      keyword: keyword,
      type: keywordType,
      nation: nation,
      ...payload,
    };
    let result = await authCheckAPI(param);

    let object = {};
    let keys = Object.keys(moreTab);

    for (let i = 0; i < keys.length; i++) {
      object[keys[i]] = true;
    }

    setCurPage(2);
    setData(result);
    checkHasMore(result);
  };

  const checkHasMore = (data) => {
    if (data.page == data.last) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  };

  const onClickTabSave = () => {
    let copy_tab = window.$Global.cloneObject(btns_state_tab);
    let copy_arr_tab = Object.keys(copy_tab).map((key) => copy_tab[key]);

    // 기본 탭 설정일때
    if (selectedBasics) {
      window.localStorage.setItem("costManage_tab_init", "1");
      copy_arr_tab.forEach((item) => {
        item.checked = item.defaultChecked;
      });
    } else {
      window.localStorage.removeItem("costManage_tab_init");
      copy_arr_tab.forEach((item) => {
        window.localStorage.setItem(
          "costManage_" + item.key,
          String(Number(item.checked))
        );
      });
    }
    setTabState(false);
  };

  const onClickMoreStateDisplay = (type) => {
    setMoreTab({ ...moreTab, [type]: !moreTab[type] });
  };

  const onClickBilling = (index) => {
    if (window.confirm("청구하시겠습니까?")) {
      let item = data.items[index];

      if (isPatent) {
        CostAPI.getPatentCostBilling(
          manage_company_idx,
          item.patent_idx,
          item.idx
        ).then(() => {
          let copy = _.cloneDeep(data);
          copy.items[index].real_claim_at = new Date().getTime();
          copy.items[index].state = 1;
          setData(copy);
        });
      } else if (isTradeMark) {
        CostAPI.getTradeMarkCostBilling(
          manage_company_idx,
          item.trademark_idx,
          item.idx
        ).then(() => {
          let copy = _.cloneDeep(data);
          copy.items[index].billing_at = new Date().getTime();
          copy.items[index].state = 1;
          setData(copy);
        });
      } else if (isDesign) {
        CostAPI.getDesignCostBilling(
          manage_company_idx,
          item.design_idx,
          item.idx
        ).then(() => {
          let copy = _.cloneDeep(data);
          copy.items[index].real_claim_at = new Date().getTime();
          copy.items[index].state = 1;
          setData(copy);
        });
      }
    }
  };

  const onClickPaySuccess = async (index) => {
    let item = data.items[index];

    if (window.confirm("송금 완료 처리를 하시겠습니까?")) {
      if (item.state != 3) {
        alert("검토 확인을 하신 후에 완료 가능합니다");
        return;
      }
      if (isPatent) {
        await CostAPI.getPatentPaySuccess(item.patent_idx, item.idx);
      } else if (isTradeMark) {
        await CostAPI.getTradeMarkPaySuccess(item.trademark_idx, item.idx);
      } else if (isDesign) {
        await CostAPI.getDesignPaySuccess(item.design_idx, item.idx);
      }

      let copy = _.cloneDeep(data);
      copy.items[index].state = 4;
      copy.items[index].deposit_at = new Date().getTime();
      setData(copy);
    }
  };

  const onChangeConfirm = (e, index) => {
    let state = e.target.value;
    if (e.target.value == 2) {
      setPopupCommentShow(true);
      setPopuSelectIndex(index);
      setComment(data.items[index].comment);
      return;
    }
    CostConfirmAPI(index, { state: state });
  };

  const CostConfirmAPI = async (index, payload) => {
    let item = data.items[index];

    if (isPatent) {
      await CostAPI.CostConfirm(item.patent_idx, item.idx, payload);
    } else if (isTradeMark) {
      await CostAPI.CostConfirmTradeMark(item.trademark_idx, item.idx, payload);
    } else if (isDesign) {
      await CostAPI.CostConfirmDesign(item.design_idx, item.idx, payload);
    }
    let copy = _.cloneDeep(data);
    copy.items[index].state = payload.state;
    setData(copy);
    setPopupCommentShow(false);
    window.$Global.saveToast();
  };

  const onClickEditRequest = (text) => {
    CostConfirmAPI(popuSelectIndex, { state: 2, comment: text });
  };

  const onClickPopCommentClose = () => {
    setPopupCommentShow(false);
  };

  const onClickChangeNation = (nation) => {
    setCurPage(1);
    history.push(`/cost/list?nation=${nation}`);
  };

  const onChangeChecked = (e, type) => {
    let copy = { patent: false, tradeMark: false, design: false };
    let keys = Object.keys(copy);
    let changed = false;
    copy[type] = e.target.checked;

    for (let i in keys) {
      if (copy[keys[i]]) {
        changed = true;
        break;
      }
    }
    if (changed) {
      setCheck(copy);
    }
  };

  const onBlur = (type) => {
    if (!mouse) {
      setMoreTab({ ...moreTab, [type]: true });
    }
  };

  btns_state_tab.forEach((item, i) => {
    if (item.checked) {
      if (item.text === "국가") {
        let tag = nationList.map((item, index) => {
          return (
            <li key={index} tabIndex={0} onBlur={() => onBlur("nation")}>
              <CheckBox
                key={i}
                text={window.$Global.convertNationCodeToStr(item)}
                checked={filterCheckList[index] || false}
                onChangeChecked={(e) =>
                  onClickFilterCheckBox(
                    e,
                    { sort_nation: item },
                    index,
                    "nation"
                  )
                }
              />
            </li>
          );
        });
        table_head_tag.push(
          <th
            key={i}
            className={moreTab.nation ? "dp_none" : ""}
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 40,
            }}
            onBlur={() => onBlur("nation")}
          >
            {item.text}
            <button
              className="icon_more_white"
              onClick={() => onClickMoreStateDisplay("nation")}
            />
            <ul
              onMouseEnter={() => setMouse(true)}
              onMouseLeave={() => setMouse(false)}
            >
              <li tabIndex={0} onBlur={() => onBlur("nation")}>
                <CheckBox
                  text="전체"
                  checked={filterCheckList[filterCheckList.length - 1] || false}
                  onChangeChecked={(e) =>
                    onClickFilterCheckBox(
                      e,
                      { sort_nation: null },
                      filterCheckList.length - 1,
                      "nation"
                    )
                  }
                />
              </li>
              {tag}
            </ul>
          </th>
        );
      } else if (item.text === "청구사유") {
        let tag = billingContentList.map((item, index) => {
          return (
            <li key={index} tabIndex={0} onBlur={() => onBlur("claimContent")}>
              <CheckBox
                key={i}
                text={window.$Global.convertBillTypeToStr(item)}
                checked={filterCheckList[index] || false}
                onChangeChecked={(e) =>
                  onClickFilterCheckBox(
                    e,
                    { sort_type: item },
                    index,
                    "claimContent"
                  )
                }
              />
            </li>
          );
        });
        table_head_tag.push(
          <th
            key={i}
            className={`select_tab ${moreTab.claimContent ? "dp_none" : ""}`}
            onBlur={() => onBlur("claimContent")}
          >
            <div>
              {item.text}
              <button
                className="icon_more_white"
                onClick={() => onClickMoreStateDisplay("claimContent")}
              />
            </div>
            <ul
              onMouseEnter={() => setMouse(true)}
              onMouseLeave={() => setMouse(false)}
            >
              <li tabIndex={0} onBlur={() => onBlur("claimContent")}>
                <CheckBox
                  text="전체"
                  checked={filterCheckList[filterCheckList.length - 1] || false}
                  onChangeChecked={(e) =>
                    onClickFilterCheckBox(
                      e,
                      { sort_type: null },
                      filterCheckList.length - 1,
                      "claimContent"
                    )
                  }
                />
              </li>
              {tag}
            </ul>
          </th>
        );
      } else {
        table_head_tag.push(<th key={i}>{item.text}</th>);
      }

      if (item.text == "청구번호" || item.text == "출원번호") {
        options_tag.push(
          <option key={i} value={item.type}>
            {item.text}
          </option>
        );
      }
    }

    if (item.key != "more") {
      tab_tags.push(
        <CheckBox
          key={i}
          text={item.text}
          checked={item.checked}
          onChangeChecked={onChangeTabChecked}
          disabled={selectedBasics}
        />
      );
    }
  });

  return (
    <div id="CostList">
      <div className="header">
        <h2 className="title">
          {nation == 1 ? "국내" : "해외"} 비용 청구 리스트
        </h2>
        <div className="btns">
          {tabState ? (
            <>
              <ToggleButtonWithSpan
                text="Tab 기본 설정하기 "
                condition={{ on: "ON", off: "OFF" }}
                selected={selectedBasics}
                toggleSelected={onClickToggleselectedBasics}
              />
              <button className="btn_tab_setting_save" onClick={onClickTabSave}>
                Tab 설정 저장
              </button>
            </>
          ) : (
            <>
              {isPatent && (
                <>
                  <button
                    className="btn_domestic_list"
                    onClick={() => onClickChangeNation(1)}
                  >
                    국내 비용 리스트
                  </button>
                  <button
                    className="btn_foreign_list"
                    onClick={() => onClickChangeNation(2)}
                    ㅁ
                  >
                    해외 비용 리스트
                  </button>
                </>
              )}
              {isAgent && (
                <button
                  className="btn_pay_input"
                  onClick={() => (window.location = "/cost/input")}
                >
                  비용 청구하기
                </button>
              )}
            </>
          )}
        </div>
      </div>
      <div className="wrap_tab_btns">{tabState && tab_tags}</div>
      {tabState || (
        <>
          <div className="wrap_search">
            <select onChange={(e) => setKeywordType(e.target.value)}>
              {options_tag}
            </select>
            <input
              type="text"
              className="input_search"
              onChange={(e) => setKeyword(e.target.value)}
              placeholder="검색어를 입력해주세요"
              onKeyUp={(e) => {
                if (e.key === "Enter") searchList();
              }}
            />
            <button className="icon_search_black" onClick={searchList} />
          </div>
          <div className="sorting">
            <CheckBox
              text="특허"
              checked={check.patent}
              onChangeChecked={(e) => onChangeChecked(e, "patent")}
            />
            {nation == 1 && (
              <CheckBox
                text="상표"
                checked={check.tradeMark}
                onChangeChecked={(e) => onChangeChecked(e, "tradeMark")}
              />
            )}
            {nation == 1 && (
              <CheckBox
                text="디자인"
                checked={check.design}
                onChangeChecked={(e) => onChangeChecked(e, "design")}
              />
            )}
          </div>
        </>
      )}
      <div className="table-box-wrap">
        <div id="scrollableDiv" className="wrap_list">
          <InfiniteScroll
            dataLength={data.items}
            next={nextList}
            hasMore={hasMore}
            scrollableTarget="scrollableDiv"
          >
            <table className="list">
              <thead>
                <tr>{table_head_tag}</tr>
              </thead>
              <tbody>
                {data.items.map((item, index) => (
                  <tr key={index} onClick={() => console.log(item, isCompany)}>
                    {columnShow.billing_number && (
                      <td>{item.billing_number}</td>
                    )}
                    {nation == 2 && (
                      <td style={{ paddingRight: "15px" }}>
                        {nationType[item.nation]}
                      </td>
                    )}
                    {columnShow.apply_number && <td>{item.apply_number}</td>}
                    {!isTradeMark && columnShow.type && (
                      <td>{patentCostTypeValueInfo[item.type.toString()]}</td>
                    )}
                    {columnShow.sum_commission && (
                      <td>
                        {window.$Global.commaify(
                          !isTradeMark
                            ? item.sum_commission
                            : item.total_commision
                        )}
                      </td>
                    )}
                    {columnShow.surtax && (
                      <td>
                        {window.$Global.commaify(
                          item.surtax || 0 + item.for_surtax || 0
                        )}
                      </td>
                    )}
                    {columnShow.sum_total && (
                      <td>
                        {window.$Global.commaify(item.sum_total_domestic)}
                      </td>
                    )}
                    {columnShow.real_claim_at && drawBillingAt(item, index)}
                    {columnShow.state && drawSelect(item, index)}
                    {columnShow.deposit_at && drawPay(item, index)}
                    <td>
                      <Link
                        to={
                          isCompany
                            ? `/cost/billing/${
                                isPatent
                                  ? "patent"
                                  : isTradeMark
                                  ? "tradeMark"
                                  : "design"
                              }/${
                                isPatent
                                  ? item.patent_idx
                                  : isTradeMark
                                  ? item.trademark_idx
                                  : item.design_idx
                              }/${item.idx}`
                            : `/cost/detail/patent/${item.patent_idx}/${item.idx}`
                        }
                      >
                        <div className="icon_more_red" />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
      </div>
      <PopupComment
        show={popupCommentShow}
        text={comment}
        onChange={(e) => setComment(e.target.value)}
        onClickEditRequest={onClickEditRequest}
        onClickClose={onClickPopCommentClose}
      />
    </div>
  );
}

export default CostList;
