import { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import "./css/GroupwareSideBar.scss";
import config from "../../const/config";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const loadAppliction = (src, e) => {
  e.preventDefault();
  document.getElementById("contentWrap").src = src;
};

const loadGwAppliction = (item, e, sidebar) => {
  e.preventDefault();
  let strUrl = item.url;
  let arrUrl = [];
  let isOpen = false;
  if (strUrl.indexOf("999999") > -1) {
    // 상단메뉴 링크 처리
    isOpen = true;
  }

  if (strUrl.indexOf("javascript") > -1) {
    if (strUrl.indexOf("window.open") > -1) {
      // 출퇴근관리 근무설정 가이드 팝업창 url 변경
      if (strUrl.indexOf("command=attendConfigGuide") > -1) {
        strUrl = strUrl.replace(
          "command=attendConfigGuide",
          "command=attendConfigGuide&siteFlag=biznavi"
        );
      }

      // 설정안내 페이지 팝업창 처리
      isOpen = true;
      eval(strUrl);
    } else {
      strUrl = strUrl.replace("javascript:goLeftMenu('", "").replace("')", "");
    }
    arrUrl = strUrl.split("?");
  }
  if (!isOpen) {
    if (item.isContentWrap) {
      let strSrc =
        config.gwUrl +
        "/" +
        strUrl +
        "&siteFlag=biznavi" +
        "&menuCd=" +
        item.menu_cd;
      document.getElementById("contentWrap").src = strSrc;
    } else {
      let strSrc = "/groupware/admin?sideBarSection=" + sidebar;
      strSrc += "&moveUrl=" + arrUrl[0] + "&" + arrUrl[1] + "&siteFlag=biznavi";
      strSrc += "&suMenuCd=" + item.suMenuCd;
      strSrc += "&menuTitle=" + item.menuTitle;
      strSrc += "&menuCd=" + item.menu_cd;
      window.parent.location.href = strSrc;
    }
  }
};

const createDummy = () => {
  return Math.floor(Math.random() * 1000000); // 0에서 999999 사이의 난수 생성 / iframe 리다이렉트 위함
};

const GroupwareSideBar = (props) => {
  const { currentPath, adminMenu, sidebar } = props;
  const [sidebarPath, setSidebarPath] = useState("");
  const [depth, setDepth] = useState(0);
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const [path, setPath] = useState(currentPath || []);
  const [adminPath, setAdminPath] = useState([]);
  const nowPath = window.location;
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [selectedBg, setSelectedBg] = useState("transparent");
  const myRef = useRef(null);
  const isItl = localStorage.getItem("company_idx") === "MOZQYC25XKG8KEE0";
  const isTeamReport =
    localStorage.getItem("company_idx") === "AU40Y4XM890T30M2" || // penventures
    localStorage.getItem("company_idx") === "I9OFGWZBIG759WES"; // dev01
  const history = useHistory();

  useEffect(() => {
    setPath(currentPath);
  }, [currentPath]);

  useEffect(() => {
    if (typeof sidebar !== undefined) {
      setSidebarPath(sidebar);
    }
    if (typeof adminMenu !== undefined) {
      setAdminPath(adminMenu);
    }
  }, [sidebar, adminMenu]);

  // useEffect(() => {
  //   console.log("ref 바뀜::", myRef);
  // }, myRef)

  // useEffect(() => {
  //   console.log("여기진입하니", selectedMenu);
  //   console.log("myRef.current::", myRef.current);
  //   (selectedMenu === myRef.current) ? setSelectedBg("#f5f5f5") : setSelectedBg("transparent");
  //   console.log("SelectedBg::", selectedBg);
  // }, [selectedMenu])

  const isViewTypeIpnow = window.$Global.isIpnow();

  /**
   *
   * @param {*} url 메뉴링크
   * @returns
   * 현재 메뉴 식별
   * 현재 페이지 주소와 메뉴 링크의 주소 비교
   */
  const onMenu = (url) => {
    if (nowPath.href.includes(url)) {
      return true;
    } else {
      return false;
    }
  };

  const SubItem = ({ item, depth }) => {
    const toggleCollapsed = (item) => {
      item.isOpen = !item.isOpen;
      setPath([...path]);
    };

    // const setBg = (item) => {
    //   let bg = "transparent";
    //   item.url ? onMenu(item.url) ? bg = "#f5f5f5" : bg = "transparent" : bg = "transparent";
    //   return bg;
    // }
    if (item.children || depth < 3) {
      return !isItl && item.name === "업무챗" ? (
        ""
      ) : !isTeamReport && item.name === "팀주간보고 쓰기" ? (
        ""
      ) : !isItl && item.name === "사업계획" ? (
        ""
      ) : (
        <li
          className={`sidebar_depth${depth}_item`}
          style={
            {
              //paddingTop: 20,
              //paddingBottom: 20,
              //borderBottom: depth > 1 && "1px solid #f0f0f0",
              //borderTop: depth > 1 && "1px solid #f0f0f0",
              //background: item.url ? onMenu(item.url) ? "#f5f5f5" : "transparent" : "transparent"\
              //background: setBg(item)
            }
          }
        >
          <div
            ref={myRef}
            style={{
              //margin: depth < 2 && "10px 0 20px 0",
              paddingLeft: depth * 30,
              paddingTop: "20px",
              paddingBottom: "20px",
              color: "#343434",
              borderTop: depth > 1 && "1px solid #f0f0f0",
            }}
            className={item.url === window.location.pathname && "on"}
          >
            {depth === 1 && (
              <i className="icon_edit_square" style={{ marginRight: 10 }} />
            )}

            {item.menu_cd ? (
              item.url ? (
                <Link
                  onClick={(e) => {
                    loadGwAppliction(item, e, sidebarPath);
                    //setSelectedMenu(e.currentTarget.parentNode);
                  }}
                  to="#"
                >
                  {item.name}
                </Link>
              ) : (
                item.name
              )
            ) : item.url ? (
              // <Link to={item.url}>
              //   {item.icon ? (
              //     <i
              //       className={`${item.icon}${
              //         item.url === window.location.pathname ? "_on" : ""
              //       }`}
              //     ></i>
              //   ) : (
              //     ""
              //   )}
              //   {item.name}
              // </Link>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  const dummy = createDummy();
                  const currentUrl = item.url;
                  const separator = currentUrl.includes("?") ? "&" : "?";

                  history.push(`${currentUrl}${separator}dummy=${dummy}`);
                }}
              >
                {item.name}
              </div>
            ) : (
              item.name
            )}
            {/* <Link to={item.url}>{item.name}</Link> */}
            {item.children && (
              <i
                className={
                  item.isOpen ? "icon_arrow_up_gray" : "icon_arrow_down_gray"
                }
                onClick={() => toggleCollapsed(item)}
              />
            )}
          </div>
          {item.children &&
            (item.isOpen ? (
              <ul
                className={`sidebar_depth${depth}`}
                style={{ borderTop: "1px solid #f0f0f0" }}
              >
                {item.children.map((child) => {
                  return <SubItem item={child} depth={depth + 1} />;
                })}
              </ul>
            ) : null)}
        </li>
      );
    } else {
      return (
        <li
          className={`sidebar_depth${depth}_item`}
          style={{ paddingTop: 10, paddingBottom: 10 }}
        >
          {item.src ? (
            <Link
              onClick={(e) => {
                loadAppliction(item.src, e);
              }}
              to="#"
              style={{
                fontSize: 16,
                color: "#959595",
                paddingLeft: depth * 30,
              }}
            >
              {item.name}
            </Link>
          ) : item.menu_cd ? (
            <Link
              onClick={(e) => {
                loadGwAppliction(item, e, sidebarPath);
              }}
              to="#"
              style={{
                fontSize: 16,
                color: "#959595",
                paddingLeft: depth * 30,
              }}
            >
              {item.name}
            </Link>
          ) : (
            <div
              style={{
                fontSize: 16,
                //color: onMenu(item.url) ? "#0078f1" : "#959595",
                paddingLeft: depth * 30,
                cursor: "pointer",
              }}
              onClick={() => {
                const dummy = createDummy();
                const currentUrl = item.url;
                const separator = currentUrl.includes("?") ? "&" : "?";

                history.push(`${currentUrl}${separator}dummy=${dummy}`);
              }}
            >
              {item.name}
            </div>
            // <Link
            //   to={item.url}
            //   style={{
            //     fontSize: 16,
            //     //color: onMenu(item.url) ? "#0078f1" : "#959595",
            //     paddingLeft: depth * 30,
            //   }}
            // >
            //   {item.name}
            // </Link>
          )}
        </li>
      );
    }
  };

  return (
    <div id="GroupwareSideBar">
      <ul className={`sidebar_depth${depth + 1}`}>
        {path?.map((item) => {
          return <SubItem item={item} depth={depth + 1} />;
        })}
      </ul>
      <ul className={`sidebar_depth${depth + 1}`}>
        {adminPath?.map((item) => {
          return <SubItem item={item} depth={depth + 1} />;
        })}
      </ul>
    </div>
  );
};

export default GroupwareSideBar;
