import GroupwareSideBar from "../GroupwareSideBar";
import { groupWareWorksPath } from "../groupwarePath";
import "../css/GroupwareWorks.scss";
import { useHistory, useLocation } from "react-router-dom";
import "moment/locale/ko";
import React, { useEffect, useState } from "react";
import qs from "query-string";
import CommonAPI from "../../../API/common";
import commonFunc from "../../admin/commonFunc/common";
import GroupwareBoard from "./GroupwareBoard";

const GroupwareInfoBoard = () => {
  const location = useLocation();
  const { section, category } = qs.parse(location.search);
  const [categoryName, setCategoryName] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [boardItem, setBoardItem] = useState({});
  const [last, setLast] = useState(1);

  useEffect(() => {
    getCategoryList();
  }, [category, curPage]);

  const getCategoryList = () => {
    let params = {
      page: curPage,
      count: 12,
      category: category || 6,
      service: -1,
    };
    CommonAPI.getNoticeList(params).then((res) => {
      setBoardItem(res.data);
      setLast(res.data.last);
    });

    commonFunc.convertCommunityCategory().then((res) => {
      let arr = [];
      res.map((item) => {
        if (item.child) {
          arr = [...arr, ...item.child];
        }
      });
      console.log(arr);
      setCategoryName(
        arr.find((item) => item.value === Number(category))?.name
      );
    });
  };

  console.log(category);

  return (
    <div id="GroupwareWorks">
      <GroupwareSideBar currentPath={groupWareWorksPath[section]} />
      <div
        className="commute_wrapper"
        style={{
          backgroundColor: "white",
          borderLeft: "1px solid rgb(240, 240, 240)",
        }}
      >
        <GroupwareBoard
          section={section}
          categoryName={categoryName}
          boardItem={boardItem}
          curPage={curPage}
          setCurPage={setCurPage}
          last={last}
        />
      </div>
    </div>
  );
};

export default GroupwareInfoBoard;
