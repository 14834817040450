import React from 'react';

function SelectRow({title, titleClass, selectTag, condition, content, contentIdx, onChange}) {
    return (
        <div className="row">
            <h2 className={`title ${titleClass || ""}`}>{title}<i className="icon_info" data-content={window.$Global.getInfoMessage(title)}/></h2>

            {
                condition
                    ?
                    selectTag.length
                        ?
                        <select defaultValue={contentIdx || 0} onChange={onChange}>
                            <option value={0} disabled hidden>선택</option>
                            {selectTag}
                        </select>
                        : <p>등록 후 선택 가능</p>
                    : <p className={`${Boolean(content) ? '' : 'color_blue'}`}>{content ? content : '수정을 눌러 입력해 주세요.'}</p>
            }
        </div>
    );
}

export default SelectRow;
