import React from "react";
import "./css/FindBizChange.scss";
function FindBizChange() {
  const onClickChangeView = () => {
    window.$Global.toggleViewType();
  };

  return (
    <div id="findBizChange">
      <div className="change">
        <div className="change_text">
          <em className="small">
            쉽게 파악 가능한 정리된 사업&middot;과제 정보 제공
          </em>
          <br />
          <em className="strong">모든 공공사업·과제 ONE Click 찾기</em>
          <br />
          <span className="middle">
            Bizinfo, Smtech, NTIS, 조달청, <br />
            1500여 개 공공기관 사업·과제 모두 정리
          </span>
        </div>
        <div className="change_img">
          <div className="change_logo">비즈내비</div>
          <button
            type="button"
            className="change_btn-link"
            onClick={onClickChangeView}
          >
            BizNavi로 전환하기
          </button>
        </div>
        <div className="change_subtitle">
          ※ 전환 시 비즈내비 전용 화면으로 제공됩니다.
        </div>
      </div>
    </div>
  );
}

export default FindBizChange;
