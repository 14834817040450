import GWAPI from "../../util/groupwareAPI";
import API from "../../util/api";

export default {
  getUserInfo() {
    return GWAPI.get("/member/userInfo.json");
  },
  getDocDashBoradInfo() {
    return GWAPI.get("/doc/mainInfo.json");
  },
  getAttendDashBoradInfo() {
    return GWAPI.get("/attend/mainInfo.json");
  },
  getAttendWorkInfo() {
    return GWAPI.get("/attend/mainWorkInfo.json");
  },
  getVacationDashBoradInfo() {
    return GWAPI.get("/vacation/mainInfo.json");
  },
  postAttened(payload) {
    return GWAPI.post("/attend/attendPost.json", null, { params: payload });
  },
  getDocMainDashBoardInfo() {
    return GWAPI.get("/main/docInfo.json");
  },
  getMailMainDashBoardInfo(payload) {
    return GWAPI.post("/main/mailInfo.json", null, { params: payload });
  },
  getScheduleMainDashBoardInfo(payload) {
    return GWAPI.post("/main/scheduleInfo.json", null, { params: payload });
  },
  getMailViewUrl(payload) {
    return GWAPI.post("/main/getMailViewUrl.json", null, { params: payload });
  },
  getBoardMainDashBoardInfo(payload) {
    return GWAPI.post("/main/getBoardList.json", null, { params: payload });
  },
  getAdminMenuList(payload) {
    return GWAPI.post("/menu/adminLeftMenuInfo.json", null, {
      params: payload,
    });
  },
  getMenuList(payload) {
    return GWAPI.post("/menu/leftMenuInfo.json", null, { params: payload });
  },

  // To Do List CRUD API
  getTodo() {
    return GWAPI.get("/todolist/getlist.json");
  },
  addTodo(payload) {
    return GWAPI.post("/todolist/insert.json", null, { params: payload });
  },
  updateTodo(payload) {
    return GWAPI.patch("/todolist/update.json", null, { params: payload });
  },
  updateTodoDetail(payload) {
    return GWAPI.patch("/todolist/updateDetail.json", null, {
      params: payload,
    });
  },
  deleteTodo(payload) {
    return GWAPI.delete("/todolist/upIsdel.json", { params: payload });
  },

  // 휴가자 현황 API
  getVacationerList() {
    return GWAPI.get("/vacation/vacInfo.json");
  },

  // 휴가자 현황 API
  getCommonDocList() {
    return GWAPI.get("/doc/usedDocList.json");
  },

  // 현 사용 중인 휴가종류 API
  getVacationKind() {
    return GWAPI.get("/vacation/vacaKind.json");
  },

  // 사용 휴가종류 + 남은 잔여일수 API
  getVacKindWithDays() {
    return GWAPI.get("/vacation/vacaKindWReaminDays.json");
  },

  // 캐시플로우 생성
  createCashflow(data) {
    return GWAPI.post("/cashflow/report_create.json", data);
  },

  // 캐시플로우 가져오기
  getCashflow(data) {
    return GWAPI.get("/cashflow/select_cash_on_data.json", { params: data });
  },

  // 캐시플로우 수정
  updateCashflow(data) {
    return GWAPI.post("/cashflow/report_update_all.json", data);
  },

  // 캐시플로우 삭제
  deleteCashflow(data) {
    return GWAPI.delete("/cashflow/report_delete.json", { params: data });
  },

  // 캐시플로우 리스트
  getCashflowList(data) {
    return GWAPI.get("/cashflow/report_select.json", { params: data });
  },

  // 캐시플로우 레포트 월별 그래프 데이터
  getMonthGraph(data) {
    return GWAPI.get("/cashflow/cash_flow_cash_amount.json", { params: data });
  },

  // 캐시플로우 레포트 금액 합계
  getReportSum(data) {
    return GWAPI.get("/cashflow/cash_flow_preview.json", { params: data });
  },

  // 캐시플로우 레포트 하나 정보 가져오기
  getOneReportInfo(data) {
    return GWAPI.get("/cashflow/report_one.json", { params: data });
  },

  // 캐시플로우 최신 레포트 아이디 가져오기
  getRecentReportId() {
    return GWAPI.get("/cashflow/recent_id.json");
  },

  addCalendar(data) {
    return GWAPI.post("/scheduler/registerSchedule.json", data);
  },

  getCalendarList(data) {
    return GWAPI.get("/scheduler/showCalendarCombo.json", { params: data });
  },

  // start 캐시플로우 사업계획서용 ========================================================
  // 캐시플로우 생성
  createCashPlan(data) {
    return GWAPI.post("/cashplan/report_create.json", data);
  },

  // 캐시플로우 가져오기
  getCashPlan(data) {
    return GWAPI.get("/cashplan/select_cash_on_data.json", { params: data });
  },

  // 캐시플로우 수정
  updateCashPlan(data) {
    return GWAPI.post("/cashplan/report_update_all.json", data);
  },

  // 캐시플로우 삭제
  deleteCashPlan(data) {
    return GWAPI.delete("/cashplan/report_delete.json", { params: data });
  },

  // 캐시플로우 리스트
  getCashPlanList(data) {
    return GWAPI.get("/cashplan/report_select.json", { params: data });
  },

  // 캐시플로우 레포트 월별 그래프 데이터
  getMonthGraphPlan(data) {
    return GWAPI.get("/cashplan/cash_flow_cash_amount.json", { params: data });
  },

  // 캐시플로우 레포트 금액 합계
  getReportSumPlan(data) {
    return GWAPI.get("/cashplan/cash_flow_preview.json", { params: data });
  },

  // 캐시플로우 레포트 하나 정보 가져오기
  getOneReportInfoPlan(data) {
    return GWAPI.get("/cashplan/report_one.json", { params: data });
  },

  // 캐시플로우 최신 레포트 아이디 가져오기
  getRecentReportIdPlan() {
    return GWAPI.get("/cashplan/recent_id.json");
  },
  // end 캐시플로우 사업계획서용 ========================================================
};
