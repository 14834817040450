import { logos } from "./bankLogo";

const Banks = () => {
  const bankList = [
    {
      logo: logos.ibk,
      txt: (
        <span>
          양재역 지점 : 02-3462-6494
          <br />
          정유진 차장 (내선 430)
        </span>
      ),
      related: true,
    },
    {
      logo: logos.bnk,
      txt: (
        <span>
          부전동 금융센터 : 051-669-8245
          <br />
          김태원 부지점장
        </span>
      ),
      related: true,
    },
    {
      logo: logos.hana,
      txt: (
        <span>
          서여의도 금융센터지점 : 010-3649-0243
          <br />
          이승훈 부장
        </span>
      ),
      related: true,
    },
    {
      logo: logos.woori,
      txt: "1588-5000",
      related: false,
    },
    {
      logo: logos.shinhan,
      txt: "1577-8008",
      related: false,
    },
    {
      logo: logos.kb,
      txt: "1800-9500",
      related: false,
    },
    {
      logo: logos.dgb,
      txt: "1566-9500",
      related: false,
    },
    {
      logo: logos.kdb,
      txt: "1588-1500",
      related: false,
    },
    {
      logo: logos.nh,
      txt: "1661-3000",
      related: false,
    },
  ];
  return (
    <div id="Banks">
      <ul className="bank_list">
        {bankList.length > 0 ? (
          bankList.map((item, idx) => (
            <li className={item.related ? "related" : ""}>
              {item.related ? (
                <span className="tag">BIZNAVI 연계은행</span>
              ) : (
                ""
              )}
              <img src={item.logo} alt="logo" />
              <p>{item.txt}</p>
            </li>
          ))
        ) : (
          <p className="no_data">데이터가 없습니다.</p>
        )}
      </ul>
    </div>
  );
};

export default Banks;
