export const patentCostType = {
    domestic: "국내",
    domestic_before_apply: "출원전",
    domestic_before_apply_survey: "선행기술조사",
    domestic_apply: "출원",
    domestic_apply_default: "원출원",
    domestic_apply_division: "분할출원",
    domestic_apply_change: "변경출원",
    domestic_apply_temporary: "가출원",
    domestic_apply_screen: "심사청구",
    domestic_apply_priority_screen: "우선심사신청",
    domestic_oa: "중간",
    domestic_oa_oa: "중간사건",
    domestic_oa_self: "자진보정",
    domestic_oa_extension: "기간연장",
    domestic_oa_response: "중간사건(OA대응)",
    domestic_register: "등록",
    domestic_register_regist: "등록비용",
    domestic_referee: "심판",
    domestic_referee_claim: "심사청구",
    domestic_referee_oa_opinion: "심판중간(의견서제출)",
    domestic_referee_oa_answer: "심판중간(답변서제출)",
    domestic_referee_success: "심판성사",
    domestic_etc: "기타",
    domestic_etc_annual_pay: "연차료관리",
    domestic_etc_change_name: "명의변경 및 실시권설정등록",
    domestic_etc_change_info: "정보변경",
    domestic_etc_analysis: "기타 분석 업무",
    domestic_etc_others: "그외업무(기타)",
    foreign: "국외",
    foreign_before_apply: "출원전",
    foreign_before_apply_survey: "선행기술조사",
    foreign_apply: "출원",
    foreign_apply_default: "출원(개별국 출원)",
    foreign_apply_screen: "심사청구",
    foreign_apply_priority_screen: "우선심사청구",
    foreign_apply_continue: "계속출원",
    foreign_apply_division: "분할출원",
    foreign_apply_reissue: "Reissue",
    foreign_apply_ep: "EP 개별국 진입",
    foreign_oa: "중간",
    foreign_oa_oa: "OA",
    foreign_oa_maintenance: "출원유지료(EP, CA, AU 등)",
    foreign_oa_ids: "IDS(US)",
    foreign_oa_self: "자진보정",
    foreign_register: "등록",
    foreign_register_regist: "등록비용",
    foreign_etc: "기타",
    foreign_etc_type_a: "기타비용(정보변경, 통지서 송부 등)",
    foreign_etc_type_b: "기타비용(클레임차트 작성, 분석비용)",
    pct: "PCT",
    pct_before_apply: "출원전",
    pct_before_apply_survey: "선행기술조사",
    pct_apply: "출원",
    pct_apply_international: "출원(국제단계)",
    pct_apply_domestic: "출원(국내단계)",
    pct_apply_etc: "기타",

    domestic_apply_etc: "기타",
    domestic_oa_apply: "원출원",
    domestic_oa_etc: "기타",
    domestic_register_apply: "원출원",
    domestic_register_etc: "기타",
    domestic_referee_apply: "원출원",
    domestic_referee_etc: "기타",
    domestic_etc_apply: "원출원",
    foreign_apply_etc: "기타",
    foreign_oa_apply: "원출원",
    foreign_oa_etc: "기타",
    foreign_register_apply: "원출원",
    foreign_register_etc: "기타",
    foreign_etc_apply: "원출원",
    foreign_etc_etc: "기타",
};

export const patentCostTypeValue = {
    "출원전": [
        "0",
        "21",
        "36"
    ],
    "출원": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "39",
        "47",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "48",
        "37",
        "38",
        "55"
    ],
    "중간": [
        "40",
        "7",
        "8",
        "9",
        "10",
        "41",
        "49",
        "29",
        "30",
        "31",
        "32",
        "50"
    ],
    "등록": [
        "42",
        "11",
        "43",
        "51",
        "33",
        "52"
    ],
    "심판": [
        "44",
        "12",
        "13",
        "14",
        "15",
        "45"
    ],
    "기타": [
        "46",
        "16",
        "17",
        "18",
        "19",
        "20",
        "53",
        "34",
        "35",
        "54",
    ]
};

export const patentCostTypeValueInfo = {
    "0": "선행기술조사",
    "1": "원출원",
    "2": "분할출원",
    "3": "변경출원",
    "4": "가출원",
    "5": "심사청구",
    "6": "우선심사신청",
    "7": "중간사건",
    "8": "자진보정",
    "9": "기간연장",
    "10": "중간사건(OA대응)",
    "11": "등록비용",
    "12": "심사청구",
    "13": "심판중간(의견서제출)",
    "14": "심판중간(답변서제출)",
    "15": "심판성사",
    "16": "연차료관리",
    "17": "명의변경 및 실시권설정등록",
    "18": "정보변경",
    "19": "기타 분석 업무",
    "20": "그외업무(기타)",
    "21": "선행기술조사",
    "23": "심사청구",
    "24": "우선심사청구",
    "25": "계속출원",
    "26": "분할출원",
    "27": "Reissue",
    "28": "EP 개별국 진입",
    "29": "OA",
    "30": "출원유지료(EP, CA, AU 등)",
    "31": "IDS(US)",
    "32": "자진보정",
    "33": "등록비용",
    "34": "기타비용(정보변경, 통지서 송부 등)",
    "35": "기타비용(클레임차트 작성, 분석비용)",
    "36": "선행기술조사",
    "37": "출원(국제단계)",
    "38": "출원(국내단계)",
    "39": "기타",
    "40": "원출원",
    "41": "기타",
    "42": "원출원",
    "43": "기타",
    "44": "원출원",
    "45": "기타",
    "46": "원출원",
    "47": "출원(개별국 출원)",
    "48": "기타",
    "49": "원출원",
    "50": "기타",
    "51": "원출원",
    "52": "기타",
    "53": "원출원",
    "54": "기타",
    "55": "기타"
};
