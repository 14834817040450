import { useHistory } from "react-router-dom";
import "./css/EstimateDone.scss";

const EstimateDone = () => {
  const history = useHistory();
  return (
    <div id="EstimateDone">
      <h2>견적서 발송이 성공적으로 완료되었습니다.</h2>
      <p>
        입력하신 이메일을 확인해주시고, 추가 이용문의는 아래로 연락주시기
        바랍니다.
        <br />
        1551-8442 / ipnow@ipnow.co.kr
      </p>
      <button onClick={() => history.push("/")}>돌아가기</button>
    </div>
  );
};

export default EstimateDone;
