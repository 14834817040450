import { useEffect, useState } from "react";
import Banks from "./Banks";
import DocumentStep from "./DocumentStep";
import IntroIPNew from "./IntroIPNew";
import MarketMovement from "./MarketMovement";
import PatentApplicationInfoListNew from "./PatentApplicationInfoListNew";
import PatentRegistPop from "./PatentRegistPop";
import Steps from "./Steps";
import "./css/IpLoan.scss";

const IpLoan = () => {
  const [isRegistPopOpen, setIsRegistPopOpen] = useState(false); // 제품 등록하기 팝업
  // const [marketInfoPopOpen, setMarketInfoPopOpen] = useState(false); // 나의 제품 연관 시장정보 상세팝업
  // 제품 추가하기 팝업을 닫을때 특허리스트 다시 가져오기 여부 - 수정, 삭제 등 데이터 변경되면 다시 불러와야함
  const [isNeedGetProduct, setIsNeedGetProduct] = useState(false);
  const [triggergetPatentList, setTriggergetPatentList] = useState(false); //특허리스트 다시 가져오기 발생시키기

  useEffect(() => {
    if (!isRegistPopOpen && isNeedGetProduct) {
      setTriggergetPatentList(true);
    }
  }, [isRegistPopOpen]);

  return (
    <div id="IpLoan">
      <section className="wrap">
        <Steps />
      </section>
      <section className="bg_gray">
        <div className="wrap">
          <strong className="section_tit">
            STEP 1. 비즈내비 연계 은행 정보를 확인하고 대출 준비를 시작해
            보세요.
          </strong>
          <Banks />
        </div>
      </section>
      <section className="wrap">
        <strong className="section_tit">
          STEP 2. 보유특허 가치 점검하기
          <span className="tit_desc">
            <strong>{localStorage.getItem("company_name")}</strong>의 공개 특허
            리스트는 비즈내비가 불러왔어요. 우리 회사 특허의 기본 정보와 등급,
            활용가능성을 확인해 보세요.
          </span>
        </strong>
        <PatentApplicationInfoListNew
          setIsRegistPopOpen={setIsRegistPopOpen}
          setIsNeedGetProduct={setIsNeedGetProduct}
          triggergetPatentList={triggergetPatentList}
          setTriggergetPatentList={setTriggergetPatentList}
        />
        <strong className="section_tit">
          STEP 3. 대출상담 및 은행 제출자료 준비하기
          <span className="tit_desc">
            특허와 연관된 제품, 예상 매출, 제품 적용여부만 입력해보세요. 은행
            제출용 특허검토 자료가 완성됩니다.
          </span>
        </strong>
        <DocumentStep />
      </section>
      <section className="bg_gray">
        <div className="wrap">
          <strong className="section_tit">
            우리 회사 특허 연계 제품의 시장동향을 파악해 보세요.
          </strong>
          <MarketMovement />
        </div>
      </section>
      <section className="wrap">
        <strong className="section_tit">
          IP담보대출과 관련한 소식을 확인해 보세요.
        </strong>
        <IntroIPNew />
      </section>
      {isRegistPopOpen && (
        <PatentRegistPop
          setIsRegistPopOpen={setIsRegistPopOpen}
          setIsNeedGetProduct={setIsNeedGetProduct}
        />
      )}
    </div>
  );
};

export default IpLoan;
