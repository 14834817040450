import "./css/AnnualFeeBilling.scss";
import React, { useEffect, useState } from "react";
import Admin from "../../API/admin";
import AdminAPI from "../../API/admin";

const AnnualFeeBilling = ({
  annualFeeData,
  setShowPopup,
  showPatent,
  isAdmin,
}) => {
  const [sendData, setSendData] = useState({
    billing_number: "",
    type: 0,
    annual_pay_amount: 0,
    agent_fee: 0,
    surtax: 0,
    total_amount: 0,
    file: [],
    account_number: "",
    account_name: "",
    bank_name: "",
  });
  const [fileName, setFileName] = useState([]);

  useEffect(() => {
    if (annualFeeData.billing_idx !== null) {
      showPatent
        ? AdminAPI.getAdminPatentReceiptData(
            annualFeeData.patent_idx,
            annualFeeData.annual_pay_idx
          ).then((res) => {
            console.log(res.data);
            setSendData(res.data);
          })
        : AdminAPI.getAdminTradeMarkReceiptData(
            annualFeeData.trademark_idx,
            annualFeeData.annual_pay_idx
          ).then((res) => {
            console.log(res.data);
            setSendData(res.data);
          });
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "file") {
      const fileArray = Array.from(e.currentTarget.files);
      setFileName([]);
      setSendData({
        ...sendData,
        [name]: e.currentTarget.files,
      });
      fileArray.map((el) => {
        setFileName((prevState) => [...prevState, el.name]);
      });
    } else {
      setSendData({
        ...sendData,
        [name]: value,
      });
    }
  };

  const deleteFile = (idx) => {
    AdminAPI.deleteAdminPatentReceiptFile(
      annualFeeData.patent_idx,
      annualFeeData.annual_pay_idx,
      idx
    ).then(() => {
      alert("삭제되었습니다.");
      window.location.reload();
    });
  };

  const handleClick = (e) => {
    const formData = new FormData();
    for (const item in sendData) {
      if (item === "file") {
        Array.from(sendData.file).map((el) => {
          formData.append("file", el);
        });
      } else {
        formData.append(item, sendData[item]);
      }
    }

    showPatent
      ? AdminAPI.uploadAdminPatentReceiptData(
          annualFeeData.patent_idx,
          annualFeeData.annual_pay_idx,
          formData
        ).then((res) => {
          console.log(res);
          alert("청구서 등록이 완료되었습니다.");
          window.location.reload();
        })
      : AdminAPI.uploadAdminTradeMarkReceiptData(
          annualFeeData.trademark_idx,
          annualFeeData.annual_pay_idx,
          formData
        ).then((res) => {
          console.log(res);
          alert("청구서 등록이 완료되었습니다.");
          window.location.reload();
        });
  };
  return (
    <div id="AnnualFeeBilling">
      <div className="annual_fee_billing_header">
        <h2>청구서 등록/수정</h2>
        <i
          className="icon_exit_gray"
          onClick={() =>
            setShowPopup({
              billing: false,
            })
          }
        />
      </div>
      <div className="annual_fee_billing_body">
        <div>
          <p>청구번호</p>
          <input
            type="text"
            name="billing_number"
            value={sendData.billing_number}
            onChange={handleChange}
          />
        </div>
        <div>
          <p>구분</p>
          <select name="type" onChange={handleChange}>
            <option value="0" defaultValue>
              연차료
            </option>
          </select>
        </div>
        <div>
          <p>연차료 금액</p>
          <input
            type="text"
            name="annual_pay_amount"
            value={sendData.annual_pay_amount}
            onChange={handleChange}
          />
        </div>
        <div>
          <p>대리인 금액</p>
          <input
            type="text"
            name="agent_fee"
            value={sendData.agent_fee}
            onChange={handleChange}
          />
        </div>
        <div>
          <p>부가세</p>
          <input
            type="text"
            name="surtax"
            value={sendData.surtax}
            onChange={handleChange}
          />
        </div>
        <div>
          <p>총 금액</p>
          <input
            type="text"
            name="total_amount"
            value={sendData.total_amount}
            onChange={handleChange}
          />
        </div>
        <div>
          <p>은행명</p>
          <input
            type="text"
            name="bank_name"
            value={sendData.bank_name}
            onChange={handleChange}
          />
        </div>
        <div>
          <p>계좌번호</p>
          <input
            type="text"
            name="account_number"
            value={sendData.account_number}
            onChange={handleChange}
          />
        </div>
        <div>
          <p>예금주</p>
          <input
            type="text"
            name="account_name"
            value={sendData.account_name}
            onChange={handleChange}
          />
        </div>
        <div>
          <p>첨부파일</p>
          {sendData.file.length === 0 ? (
            <div className="file_input">
              <label htmlFor="file">첨부파일 등록하기</label>
              <input
                type="file"
                name="file"
                id="file"
                multiple
                onChange={handleChange}
              />
            </div>
          ) : (
            <div className="file_list_items">
              {Array.from(sendData?.file).map((el) => {
                console.log(el);
                return (
                  <div className="file_list_item">
                    <p>{el.name || el.file_name}</p>
                    <i
                      className="icon_badge_del"
                      onClick={() => deleteFile(el.idx)}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className="annual_fee_billing_footer">
        <button onClick={handleClick}>등&nbsp; 록</button>
      </div>
    </div>
  );
};

export default AnnualFeeBilling;
