import React from "react";
import UserAuthRoute from "./common/UserAuthRoute";
import SignUpCheck from "../components/admin/SignUpCheck";
import InvestmentRequestList from "../components/admin/InvestmentRequestList";
import FreeUsApplyList from "../components/admin/FreeUsApplyList";
import AdminContentList from "../components/admin/AdminContentList";
import AdminPosting from "../components/admin/AdminPosting";
import AdminCommunityPosting from "../components/admin/AdminCommunityPosting";
import AdminCommunity from "../components/admin/AdminCommunity";
import AdminServicePosting from "../components/admin/AdminServicePosting";
import AdminService from "../components/admin/AdminService";
import AdminManage from "../components/admin/manage/AdminManage";
import AdminLawfirmPosting from "../components/admin/manage/AdminLawfirmPosting";
import AdminConsultingPosting from "../components/admin/manage/AdminConsultingPosting";
import AdminMailingTemplate from "../components/admin/manage/AdminMailingTemplate";
import AdminStatistic from "../components/admin/manage/AdminStatistic";
import AdminMarketSurvey from "../components/admin/manage/AdminMarketSurvey";
import AdminCalcYearPay from "../components/admin/AdminCalcYearPayList";
import CustomSwitch from "./common/CustomSwitch";
import AdminRecommendTask from "../components/admin/manage/AdminRecommendTask";
import ChartInput from "../components/admin/manage/ChartInput";
import AdminDummyAccount from "../components/admin/manage/AdminDummyAccount";
import AdminAnnualManage from "../components/annualFee/admin/AdminAnnualManage";
import AdminAnnualFeeList from "../components/annualFee/admin/AdminAnnualFeeList";
import AdminGPTBoard from "../components/admin/AdminGPTBoard";
import AdminGPTBoardPosting from "../components/admin/AdminGPTBoardPosting";
import AdminSupport from "../components/admin/AdminSupport";
import AdminExpectationAnnual from "../components/annualFee/admin/AdminExpectationAnnual";
import AdminGiveUpAnnual from "../components/annualFee/admin/AdminGiveUpAnnual";
import AdminInventorList from "../components/annualFee/admin/AdminInventorList";

function AdminRoute({ match }) {
  return (
    <CustomSwitch>
      <UserAuthRoute
        exact
        path={`${match.path}/check`}
        requireAuth={true}
        component={SignUpCheck}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/invest`}
        requireAuth={true}
        component={InvestmentRequestList}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/freeUs`}
        requireAuth={true}
        component={FreeUsApplyList}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/list/:type`}
        requireAuth={true}
        component={AdminContentList}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/posting/:view_type/:type/:idx`}
        requireAuth={true}
        component={AdminPosting}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/community/posting`}
        requireAuth={true}
        component={AdminCommunityPosting}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/community/list`}
        requireAuth={true}
        component={AdminCommunity}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/service/posting`}
        requireAuth={true}
        component={AdminServicePosting}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/service/list`}
        requireAuth={true}
        component={AdminService}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/manage/list`}
        requireAuth={true}
        component={AdminManage}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/manage/lawfirm/posting`}
        requireAuth={true}
        component={AdminLawfirmPosting}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/manage/consulting/posting`}
        requireAuth={true}
        component={AdminConsultingPosting}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/manage/maillingTep`}
        requireAuth={true}
        component={AdminMailingTemplate}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/manage/statistic`}
        requireAuth={true}
        component={AdminStatistic}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/manage/recommend`}
        requireAuth={true}
        component={AdminRecommendTask}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/manage/market/posting`}
        requireAuth={true}
        component={AdminMarketSurvey}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/yearPay`}
        requireAuth={true}
        component={AdminCalcYearPay}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/manage/chart`}
        requireAuth={true}
        component={ChartInput}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/account`}
        requireAuth={true}
        component={AdminDummyAccount}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/annual`}
        requireAuth={true}
        component={AdminAnnualManage}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/annual/giveup`}
        requireAuth={true}
        component={AdminGiveUpAnnual}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/annual/graph`}
        requireAuth={true}
        component={AdminExpectationAnnual}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/annual/:company_idx`}
        requireAuth={true}
        component={AdminAnnualFeeList}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/annual/inventor/list`}
        requireAuth={true}
        component={AdminInventorList}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/gptboard/list`}
        requireAuth={true}
        component={AdminGPTBoard}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/gptboard/posting`}
        requireAuth={true}
        component={AdminGPTBoardPosting}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/support`}
        requireAuth={true}
        component={AdminSupport}
      />
    </CustomSwitch>
  );
}

export default AdminRoute;
