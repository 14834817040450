import React, { useContext, useEffect } from "react";
import "./terms.scss";
import memberContext from "../../store/Member";
import Terms from "../../const/Terms";

function TermsPrivate() {
  const { auth } = useContext(memberContext);

  const redirect = () => {
    window.$Global.redirectHome(auth);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="Terms">
      <h2>IPNOW 개인정보처리방침</h2>
      <p
        className="custom_scroll"
        dangerouslySetInnerHTML={{ __html: Terms.privateTxtMobile }}
      />
      <button className="btn_confirm" onClick={redirect}>
        확인
      </button>
    </div>
  );
}

export default TermsPrivate;
