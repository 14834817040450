import React from "react";
import UserAuthRoute from "./common/UserAuthRoute";
//import PatentApplicationInfoList from "../components/patentApplicationInfo/PatentApplicationInfoList";
import PatentApplicationInfoContent from "../components/patentApplicationInfo/PatentApplicationInfoContent";
import CustomSwitch from "./common/CustomSwitch";
import IpLoan from "../components/patentApplicationInfo/IpLoan";

function MortgageLoanRoute({ match }) {
  return (
    <CustomSwitch>
      <UserAuthRoute
        exact
        path={`${match.path}/list`}
        requireAuth={true}
        //component={PatentApplicationInfoList}
        component={IpLoan}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/list/new`}
        requireAuth={true}
        component={IpLoan}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/content/:type`}
        requireAuth={true}
        component={PatentApplicationInfoContent}
      />
    </CustomSwitch>
  );
}

export default MortgageLoanRoute;
