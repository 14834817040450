/* 주석 = [index, name]
   name = X 인 것들은 서버의 serviceList 에도 같이 이름을 넣어줘야함
   name이 있는 것들은 pathname만 넣으면 전체 즐겨찾기 리스트에 추가됨
 */

export const favoriteIcon = [
  // { src: "icon_3d_mail", pathname: "/groupware/mail", type: "basic" }, // 0 메일
  { src: "fi-rs-mail-plus-circle", pathname: "/groupware/mail", type: "basic" }, // 0 메일
  // { src: "icon_3d_message", pathname: "/groupware/approval", type: "basic" }, // 1 전자결재
  {
    src: "fi-rs-assessment-alt",
    pathname: "/groupware/approval",
    type: "basic",
  }, // 1 전자결재
  // { src: "icon_3d_stopwatch", pathname: "/groupware/attend", type: "free" }, // 2 출퇴근관리
  { src: "fi-rs-time-check", pathname: "/groupware/attend", type: "free" }, // 2 출퇴근관리
  // { src: "icon_3d_to_do_list", pathname: "/groupware/todo", type: "free" }, // 3 Todo
  { src: "fi-rs-check-circle", pathname: "/groupware/todo", type: "free" }, // 3 Todo
  // {
  //   src: "icon_3d_project",
  //   pathname: "/groupware/report/daily/ins",
  //   type: "basic",
  // }, // 4 업무보고
  {
    src: "fi-rs-ballot-check",
    pathname: "/groupware/report/daily/ins",
    type: "basic",
  }, // 4 업무보고
  { src: "", pathname: "", type: "" }, // 5 X
  // { src: "icon_3d_calendar", pathname: "groupware/schedule", type: "free" }, // 6 캘린더
  { src: "fi-rs-calendar-days", pathname: "groupware/schedule", type: "free" }, // 6 캘린더
  {
    // src: "icon_3d_project",
    src: "fi-rs-workflow-alt",
    pathname:
      "/groupware/office?section=command&command=showCommandList&method=receive_list",
    type: "premium",
  }, // 7 프로젝트 관리
  { src: "fi-rs-cloud", pathname: "groupware/cloud", type: "basic" }, // 8 클라우드 icon_3d_cloud
  {
    src: "fi-rs-umbrella-beach",
    pathname: "groupware/vacation",
    type: "basic",
  }, // 9 휴가관리 icon_3d_paper_aircraft
  {
    src: "fi-rs-money-check-edit",
    pathname: "groupware/works?section=extension&command=expenseTotal",
    type: "basic",
  }, // 10 지출/수당관리 icon_3d_doughnut_graph
  { src: "", pathname: "" }, // 11 X
  { src: "icon_3d_book", pathname: "" }, // 12 방문자 관리
  {
    src: "fi-rs-employee-man",
    pathname: "groupware/works?section=extension&command=lstCustomerContractor",
    type: "basic",
  }, // 13 거래처관리 icon_3d_book
  {
    src: "icon_3d_info",
    // pathname: "groupware/works?section=reservation&command=insBusinessCardForm",
    // type: "off",
    pathname: "",
  }, // 14 명함신청
  {
    src: "fi-rs-meeting-alt",
    pathname: "groupware/works?section=reservation&command=conferenceReserve",
    type: "basic",
  }, // 15 회의실 예약 icon_3d_clock
  {
    src: "fi-rs-car-side",
    pathname: "groupware/works?section=reservation&command=vehicleReserve",
    type: "basic",
  }, // 16 법인차 예약 icon_3d_paper
  { src: "fi-rs-text-box-edit", pathname: "/researchTech/category" }, // 17 특허/기술트렌드 검색 icon_3d_folder
  { src: "icon_3d_like", pathname: "" }, // 18 관심기술
  { src: "icon_3d_paper_list", pathname: "" }, // 19 특허분석조사보고서 발명진흥회
  { src: "", pathname: "" }, // 20 X
  { src: "fi-rs-label", pathname: "tradeMark/list" }, // 21 상표 icon_3d_like
  { src: "fi-rs-resources", pathname: "design/list" }, // 22 디자인 icon_3d_paper_list
  { src: "icon_3d_plus_green", pathname: "" }, // 23 리포트 등록
  { src: "icon_3d_magnifier_black", pathname: "" }, // 24 리포트 검색
  { src: "icon_3d_book", pathname: "" }, // 25 나의 리포트
  {
    src: "icon_3d_paper",
    pathname: "/groupware/works?section=board&command=list&doc_type=101",
    type: "free",
  }, // 26 게시판
  { src: "icon_3d_gavel", pathname: "" }, // 27 법무
  { src: "fi-rs-document-gear", pathname: "patent/list" }, // 28 특허 icon_3d_decoration_star
  { src: "icon_3d_calculator", pathname: "" }, // 29 세무
  { src: "icon_3d_receipt", pathname: "" }, // 30 회계
  { src: "icon_3d_book", pathname: "" }, // 31 노무
  { src: "icon_3d_temple", pathname: "" }, // 32 경영
  { src: "icon_3d_target", pathname: "" }, // 33 마케팅
  { src: "icon_3d_plus_blue", pathname: "" }, // 34 기업등록
  { src: "icon_3d_magnifier_blue", pathname: "" }, // 35 기업 찾기
  { src: "icon_3d_handshake", pathname: "" }, // 36 컨소시엄
  { src: "fi-rs-category", pathname: "/taskManage/list" }, // 37 정부사업 검색 icon_3d_magnifier_blue
  { src: "fi-rs-thumbs-up-trust", pathname: "/taskManage/setting" }, // 38 맞춤 정부사업 icon_3d_like
  { src: "icon_3d_pie_graph_pink", pathname: "" }, // 39 정부사업 경쟁률
  { src: "icon_3d_pencil", pathname: "" }, // 40 사업계획서 쓰기
  { src: "fi-rs-book-copy", pathname: "/taskManage/page2" }, // 41 2025 정부사업총정리 icon_3d_book_blue
  { src: "icon_3d_tack", pathname: "" }, // 42 사업계획서 Tip
  { src: "icon_3d_book_green", pathname: "" }, // 43 정부사업 정보
  { src: "icon_3d_info", pathname: "" }, // 44 나의 경쟁사
  { src: "icon_3d_magnifier_purple", pathname: "" }, // 45 경쟁사 찾기
  { src: "icon_3d_star", pathname: "" }, // 46 경쟁사 NOW
  { src: "fi-rs-search-dollar", pathname: "/marketInfo/category" }, // 47 시장정보 검색 icon_3d_magnifier_green
  { src: "icon_3d_book", pathname: "" }, // 48 시장뉴스 보기
  { src: "icon_3d_paper", pathname: "" }, // 49 시장정보 리포트
  { src: "icon_3d_calculator", pathname: "" }, // 50 상표 검색
  { src: "icon_3d_info", pathname: "" }, // 51 상표권자 상표검색
  { src: "icon_3d_handshake", pathname: "" }, // 52 브랜드 거래하기
  { src: "icon_3d_play", pathname: "" }, // 53 온라인교육
  { src: "icon_3d_box", pathname: "" }, // 54 해외수출 지원
  { src: "icon_3d_heart", pathname: "" }, // 55 BIZ 복지몰
  { src: "icon_3d_play", pathname: "" }, // 56 SNS 홍보미디어
  { src: "icon_3d_mail", pathname: "" }, // 57 뉴스레터
  { src: "icon_3d_talk", pathname: "" }, // 58 홍보자료 신청하기
  { src: "icon_3d_talk", pathname: "" }, // 59 인사관리
  {
    src: "fi-rs-note",
    pathname: "/groupware/works?section=board&command=list&doc_type=101",
    type: "free",
  }, // 60 게시판 icon_3d_loudspeaker
  {
    src: "fi-rs-messages",
    pathname:
      "/groupware/office?section=command&command=showCommandList&method=receive_list&category=chat",
    type: "basic",
  }, // 61 업무챗 icon_3d_talk
  { src: "fi-rs-blog-pencil", pathname: "/business/main" }, // 62 사업계획서 작성 icon_3d_pencil
  { src: "fi-rs-analyse-alt", pathname: "/competitor/main" }, // 63 기업검색 icon_3d_info
  {
    src: "fi-rs-phone-guide",
    pathname: "/groupware/addressbook",
    type: "free",
  }, // 64 주소록 icon_3d_phone
  { src: "fi-rs-file-invoice-dollar", pathname: "/marketInfo/category" }, // 65 시장정보 리포트 icon_3d_paper
  { src: "fi-rs-handshake-deal-loan", pathname: "/trade/list" }, // 66 상표 거래 icon_3d_money
  { src: "fi-rs-loan", pathname: "/mortgageLoan/list" }, // 67 IP담보대출 정보 icon_3d_house
  { src: "fi-rs-flag-usa", pathname: "/freeApply/list" }, // 68 미국특허 무료출원 icon_3d_shield
  { src: "fi-rs-lawyer-man", pathname: "/lawfirm/list" }, // 69 해외로펌 소개 icon_3d_lawfirm
  {
    src: "fi-rs-guide-alt",
    pathname: "/groupware/infoBoard/main?section=infoBoard1",
  }, // 70 BIZ 업무매뉴얼 icon_3d_heart
  {
    src: "fi-rs-lightbulb-on",
    pathname: "/groupware/infoBoard/main?section=infoBoard2",
  }, // 71 BIZ 업무 TIP icon_3d_handshake
  {
    src: "fi-rs-idea-exchange",
    pathname: "/groupware/infoBoard/main?section=infoBoard3",
  }, // 72 비즈내비 활용 TIP icon_3d_biznavi
  {
    src: "fi-rs-hand-holding-document",
    pathname: "/groupware/infoBoard/main?section=infoBoard4",
  }, // 73 IP관리의 모든것 icon_3d_pie_graph
];
