import "./css/ResearchTechNumberSearch.scss";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import InfoAPI from "../../API/info";
import Pagination from "../common/Pagination";
import AnnualFeeReport from "../annualFee/AnnualFeeReport";
import CommonAPI from "../../API/common";
import ReportDummyPatentButton from "./ReportDummyPatentButton";
import ResearchTechPatentInfoIpnow from "./ResearchTechPatentInfoIpnow";

const ResearchTechNumberSearch = () => {
  const history = useHistory();
  const [sendData, setSendData] = useState({
    type: 0,
    number: "",
  });
  const [list, setList] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [last, setLast] = useState(1);
  const [companyIdx, setCompanyIdx] = useState(null);
  const [isReportShow, setIsReportShow] = useState(false);
  const [annualFeeData, setAnnualFeeData] = useState({});
  const [isCompFrameShow, setIsCompFrameShow] = useState(false);

  useEffect(() => {
    const storedCompanyIdx = window.localStorage.getItem("company_idx");
    setCompanyIdx(storedCompanyIdx);
  }, []);

  const getPatent = () => {
    let params = {
      count: 10,
      ...sendData,
    };

    InfoAPI.getNumberPatent(params).then((res) => {
      console.log(res.data);
      setList(res.data);
    });
  };

  const renderReportButton = (item) => {
    let value = item.nation;
    if (item.pge_idx || item.pge_idx2 || item.pge_idx_f) {
      return (
        <button
          className="report_btn"
          onClick={(event) => {
            event.stopPropagation();

            let report_idx = "";
            let family_idx = item.family_idx;

            if (item.pge_idx) {
              report_idx = item.pge_idx;
            } else if (item.pge_idx2) {
              report_idx = item.pge_idx2;
            } else if (item.pge_idx_f) {
              report_idx = item.pge_idx_f;
            }

            setIsReportShow(true);
            setAnnualFeeData({
              report_idx: report_idx,
              nation: item.nation,
              apply_number: item.apply_number,
              register_number: item.register_number || item.regist_number,
              idx: item.idx,
            });
          }}
        >
          확인하기
        </button>
      );
    } else if (item.nation === "KR") {
      if (item.register_number !== null && item.register_number !== "") {
        if (item.apply_cnt > 0) {
          return "생성중";
        } else {
          return (
            <button
              className="report_btn"
              onClick={(event) => {
                event.stopPropagation();
                reportApply(item);
              }}
            >
              생성하기
            </button>
          );
        }
      } else {
        return "-";
      }
    } else {
      if (
        item.parent_register_number !== null &&
        item.parent_register_number !== "" &&
        item.parent_register_number !== undefined
      ) {
        if (item.apply_cnt > 0) {
          return "생성중";
        } else {
          return (
            <button
              className="report_btn"
              onClick={(event) => {
                event.stopPropagation();
                reportForApply(item);
              }}
            >
              생성하기
            </button>
          );
        }
        //return "-" + item.parent_register_number;
      } else {
        return "-";
      }
    }
  };
  const reportApply = (item) => {
    const check = window.confirm("특허활용 보고서를 생성하시겠습니까?");
    let param = {};
    param.patent_idx = item.idx;
    param.org_nation = item.org_nation;
    // MOZQYC25XKG8KEE0 우리회사
    //alert(companyIdx);
    if (check) {
      CommonAPI.dummyPatentReportApply(item).then(() => {
        alert("보고서 생성까지 5분정도 소요됩니다.\n5분후 새로고침 해 주세요.");
        getPatent();
      });
    }
  };

  const reportForApply = (item) => {
    const check = window.confirm("특허활용 보고서를 생성하시겠습니까?");
    let param = {};
    param.patent_idx = item.parent_register_number;
    // MOZQYC25XKG8KEE0 우리회사
    //alert(companyIdx);
    if (check) {
      CommonAPI.dummyPatentReportApply(item).then(() => {
        alert("보고서 생성까지 5분정도 소요됩니다.\n5분후 새로고침 해 주세요.");
      });
    }
  };
  return (
    <div id="ResearchTechNumberSearch">
      <div className="search_wrapper_border">
        <div className="search_wrapper">
          <p className="search_title">
            출원·등록번호 검색
            <i className="icon_info_with_blue_bg" />
          </p>
          <div className="search">
            <select
              onChange={(e) =>
                setSendData({ ...sendData, type: e.target.value })
              }
            >
              <option value={0}>출원번호</option>
              <option value={1}>등록번호</option>
            </select>
            <input
              type="text"
              placeholder={
                sendData.type == 0
                  ? "[구분번호]-[연도 4자리]-[일련번호 7자리]"
                  : "[구분번호]-[일련변호 7자리]-0000"
              }
              value={sendData.number}
              onChange={(e) =>
                setSendData({ ...sendData, number: e.target.value })
              }
              onKeyUp={(e) => {
                if (e.key === "Enter") getPatent();
              }}
            />
            <button
              className="icon_search_gray ir_txt"
              style={{ marginRight: "10px" }}
              onClick={getPatent}
            >
              검색
            </button>
          </div>
        </div>
        <div className="keyword_search_sub">
          <button
            className="btn_history"
            onClick={() => history.push("/researchTech/my/keyword")}
          >
            검색내역 보기
            <i className="icon_arrow_right_s" />
          </button>
        </div>
      </div>
      {list.length > 0 && (
        <div className="keyword_search_wrapper">
          <div className="keyword_header">
            <h2>
              검색 결과 <span>{window.$Global.commaify(list.length)}</span>건
            </h2>
          </div>
          <table>
            <colgroup>
              <col width={50} />
              <col width={50} />
              <col width={150} />
              <col width={100} />
              <col width={150} />
              <col width={100} />
              <col width={600} />
              <col width={50} />
              <col width={60} />
            </colgroup>
            <thead>
              <tr>
                <th>번 호</th>
                <th>국 가</th>
                <th>등록번호</th>
                <th>등록일</th>
                <th>출원번호</th>
                <th>출원일</th>
                <th style={{ textAlign: "left" }}>발명의 명칭</th>
                <th>등급</th>
                <th></th>
                <th width={70}>{"특허보고서"}</th>
              </tr>
            </thead>
            <tbody>
              {list.map((item, idx) => {
                return (
                  <tr
                    onClick={() => {
                      history.push({
                        pathname: `patent/${
                          item.nation === "KR"
                            ? item.apply_number.replace(/-/g, "")
                            : item.document_number
                        }/detail?nation=${item.nation}`,
                        state: {
                          // keyword: searchKeyword,
                          isKeywordSearch: 3,
                        },
                      });
                      window.location.reload();
                    }}
                    style={{
                      opacity: item.isDeleted && "0.4",
                      cursor: "pointer",
                    }}
                  >
                    <td>{(curPage - 1) * 10 + (idx + 1)}</td>
                    <td>{item.nation}</td>
                    <td>{item.register_number}</td>
                    <td>{window.$Global.convertDate(item.register_at)}</td>
                    <td>{item.apply_number}</td>
                    <td>{window.$Global.convertDate(item.apply_at)}</td>
                    <td style={{ textAlign: "left" }}>
                      {item.invention_title}
                    </td>
                    <td
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className={`circle grade_${item.sm_grade || "default"}`}
                      >
                        {item.sm_grade || "N"}
                      </div>
                    </td>
                    <td>
                      <i className="icon_arrow_right_s" />
                    </td>
                    <td>
                      {" "}
                      {
                        <ReportDummyPatentButton
                          item={item}
                          setIsReportShow={setIsReportShow}
                          setAnnualFeeData={setAnnualFeeData}
                        />
                      }
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      <Pagination curPage={curPage} lastNum={last} onClick={setCurPage} />
      {isReportShow && (
        <AnnualFeeReport
          report_idx={annualFeeData.report_idx}
          annualFeeData={annualFeeData}
          setIsReportShow={setIsReportShow}
          family_idx={annualFeeData.family_idx}
          setIsCompFrameShow={setIsCompFrameShow}
        />
      )}
      {isCompFrameShow && (
        <div className="ResearchTechPatentInfoIpnow">
          <div className="close">
            <button onClick={() => setIsCompFrameShow(false)}>
              <i className="icon_exit_gray"></i>
            </button>
          </div>

          <ResearchTechPatentInfoIpnow
            applyNum={annualFeeData.apply_number}
            nationParam={annualFeeData.nation}
            tabParam={2}
            type={"biznavi"}
          />
        </div>
      )}
    </div>
  );
};

export default ResearchTechNumberSearch;
