import { Link } from "react-router-dom/cjs/react-router-dom";
import "./NotAdmin.scss";

const NotAdmin = ({ text }) => {
  return (
    <div id="NotAdmin">
      <img
        src={require("../../assets/images/common/forbidden.png").default}
        alt="forbidden"
      />
      {text}
      <Link to="/" className="confirm">
        확인
      </Link>
    </div>
  );
};

export default NotAdmin;
