import React from "react";

function InputRow({title, titleClass, content, defaultValueUse, defaultValue, editing, inputClass, inputType, onChange}) {

    return (
        <div className={`row ${titleClass || ''}`}>
            <h2 className="title">{title}</h2>
            {
                editing
                    ? <input className={inputClass || ''} type={inputType || 'text'} value={defaultValue || ''} onChange={onChange}/>
                    : <p>{content || 'N/A'}</p>
            }
        </div>
    );
}

export default InputRow;
