import GroupwareSideBar from "./GroupwareSideBar";
import { groupWareEmpPath, groupWareExpensionWorksPath } from "./groupwarePath";
import "./css/GroupwareWorks.scss";
import { useLocation } from "react-router-dom";
import "moment/locale/ko";
import { param } from "express/lib/router";
import config from "../../const/config";
import React, { useEffect, useState, useRef } from "react";
import IframeResizer from "iframe-resizer-react";

const getAppPathExt = (pathObject) => {
  var src = config.gwUrl + "/";
  var tmp = "?";
  pathObject.map = (key, val) => {
    tmp += "&" + key + "=" + val;
  };
  return src + tmp;
};

const getAppPath = (section, command, doc_type, searchName, params) => {
  var src = config.gwUrl + "/";
  let strParam = "";
  let adminMenuParam = "";
  if (section == "empAdmin") {
    src += "emp.admin.do";
    adminMenuParam = "&menuCd=100016";
    if (searchName) {
      adminMenuParam += "&searchName=" + searchName;
    }
  } else if (section == "batchmain") {
    src += "batchmain.main.do";
  } else if (section == "docList") {
    src += "doc.auth.do";
  } else if (section == "personnel") {
    // 인사이동 조회
    src += "personnel.admin.do";
  } else {
    src += section + ".admin.do";
    switch (command) {
      // 직원등록
      case "insRecruit":
        adminMenuParam = "&menuCd=100056";
        break;
      // 인사 이동
      case "lstPersonnel":
        adminMenuParam = "&menuCd=200285";
        break;
      // 법인 정보
      case "lstCorp":
        adminMenuParam = "&menuCd=100031";
        break;
      // 직무 직위
      case "lstCode":
        adminMenuParam = "&menuCd=100027";
        break;
      // 소속 관리
      case "listBranch":
        adminMenuParam = "&menuCd=200291";
        break;
    }
  }

  if (section == "vacationList") {
    return src + "?command=" + command + "&lstPart=" + doc_type;
  } else if (section == "board") {
    return src + "?command=" + command + "&boardno=" + doc_type;
  } else if (section == "docView" || section == "command") {
    return src + "?command=" + command + strParam;
  } else {
    switch (command) {
      case "approvalList":
        src += "?command=" + command + "&list_type=" + params.get("list_type");
        break;
      default:
        src += "?command=" + command + "&doc_type=" + doc_type + adminMenuParam;
        break;
    }
    return src;
  }
};
const resizeIframe = (ifrIdName) => {
  //var the_height = document.getElementById(ifrIdName).body.scrollHeight;
  //document.getElementById(ifrIdName).height = the_height;
  //let iframe = document.querySelector("#contentWrap");
  //alert(iframe.contentDocument.body.scrollHeight);
  //iframe.style.height = iframe.contentDocument.body.scrollHeight;
};

export const GroupwareEmp = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const iframeRef = useRef(null);

  var isSection = !params.get("section") ? "doc" : params.get("section");
  var isCommand = !params.get("command") ? "writeForm" : params.get("command");
  var isDocType = !params.get("doc_type") ? "draft" : params.get("doc_type");
  var searchName = !params.get("name") ? "" : params.get("name");
  var isApp =
    getAppPath(isSection, isCommand, isDocType, searchName, params) +
    "&siteFlag=biznavi";
  //var _isApp     = getAppPathExt({"section":isSection, "command":isCommand, "doc_type":isDocType, "lstPart", });
  let sideBarSection = "";
  const dummy = params.get("dummy");

  if (
    isSection === "approval" ||
    isSection === "docView" ||
    isSection === "docList"
  ) {
    sideBarSection = "approval";
  } else {
    sideBarSection = isSection;
  }
  //alert(sideBarSection);
  // useEffect(() => {
  //   window.addEventListener(
  //     "message",
  //     (e) => {
  //       //alert(e.data.iframeHeight);
  //       let height = e.data.iframeHeight;
  //       if (height < 800) {
  //         height = 800;
  //       }
  //       document.getElementById('contentWrap').height = height + 20;
  //     },
  //     false
  //   );
  // }, []);

  // useEffect(() => {
  //   function handleMessage(event) {
  //     console.log("event::", event.data);
  //     let message = event.data; // 전송된 메시지
  //     console.log("message::", message);
  //     if (message === "resize") {
  //       console.log('Received message from child:', message);
  //       console.log("iframeRef::", iframeRef.current);
  //       iframeRef.current.resize();
  //     }
  //     //iframeRef.current.resize();
  //     // 메시지 수신 후 원하는 동작을 수행
  //   }

  //   window.addEventListener('message', handleMessage);
  //   return () => {
  //     window.removeEventListener('message', handleMessage);
  //   };
  // }, []);

  console.log(isApp);
  return (
    <div id="GroupwareWorks">
      <GroupwareSideBar currentPath={groupWareEmpPath} />
      <div className="commute_wrapper">
        {/* <iframe
          id="contentWrap"
          src={isApp}
          width="100%"
          height="800px"
          onLoad={() => {
            resizeIframe("contentWrap");
          }}
        /> */}
        <IframeResizer
          id="contentWrap"
          src={isApp + `&dummy=${dummy}`}
          style={{ minWidth: "100%", minHeight: "100vh" }}
          heightCalculationMethod="bodyScroll"
          autoResize={true}
        />
      </div>
    </div>
  );
};

export default GroupwareEmp;
