import "./css/IntroWaiting.css";

const IntroWaiting = ({ history }) => {
  return (
    <div id="Waiting">
      <p>
        회원가입이 성공적으로 완료되었습니다.
        <br />
        고객님의 그룹웨어 계정 생성까지 약 30분정도 소요됩니다.
        <br />
        30분 이후 로그인 해주시기 바랍니다.
      </p>
      <button onClick={() => history.push("/intro/main")}>
        메인페이지로 이동하기
      </button>
    </div>
  );
};

export default IntroWaiting;
