import "../css/ResearchTechEditSideBar.scss";
import { Link } from "react-scroll";

const ResearchTechEditSideBar = ({ isCompanyCodeSearch }) => {
  return (
    <div id="ResearchTechEditSideBar">
      <ul>
        <li>
          <Link to="step1" spy={true} smooth={true}>
            Step.1 기본정보
          </Link>
        </li>
        <li>
          <Link to="step2" spy={true} smooth={true}>
            Step.2 내용 작성하기
          </Link>
          <ul>
            <li>
              <Link to="child1" spy={true} smooth={true}>
                주요 특허 리스트
              </Link>
            </li>
            <li>
              <Link to="child2" spy={true} smooth={true}>
                {isCompanyCodeSearch
                  ? "특허권자의 현재 특허보유자"
                  : "특허권자별 특허 건수"}
              </Link>
            </li>
            <li>
              <Link to="child3" spy={true} smooth={true}>
                연도별 특허 건 수
              </Link>
            </li>
            <li>
              <Link to="child4" spy={true} smooth={true}>
                IPC 분포 현황
              </Link>
            </li>
            <li>
              <Link to="child5" spy={true} smooth={true}>
                {isCompanyCodeSearch
                  ? "국내외 특허 이전 기업 현황"
                  : "연도별 국내외기업 특허 건수"}
              </Link>
            </li>
            <li>
              <Link to="child6" spy={true} smooth={true}>
                등록건 특허 등급 정보
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to="step3" spy={true} smooth={true}>
            Step.3 특허 요약 건수 설정하기
          </Link>
        </li>
        <li>
          <Link to="step4" spy={true} smooth={true}>
            Step.4 저장 후 다운받기
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default ResearchTechEditSideBar;
