import React from "react";
import TaskTableHead from "./TaskTableHead";
import TaskTableBody from "./TaskTableBody";
import "./css/TaskTable.scss";

function TaskTable({
  showType,
  stateType,
  searchType,
  list,
  field,
  onClickDetail,
  onClickInterest,
  onClickShowTaskFile,
  onChangeSearchType,
  setIsLoginPopVisible,
  isLogin
}) {
  return (
    <table id="TaskTable" className={`table_list custom_tr_hover`}>
      <TaskTableHead
        showType={showType}
        stateType={stateType}
        searchType={searchType}
        onChangeSearchType={onChangeSearchType}
      />
      <TaskTableBody
        showType={showType}
        stateType={stateType}
        list={list}
        field={field}
        onClickDetail={onClickDetail}
        onClickInterest={onClickInterest}
        onClickShowTaskFile={onClickShowTaskFile}
        setIsLoginPopVisible={setIsLoginPopVisible}
        isLogin={isLogin}
      />
    </table>
  );
}

export default TaskTable;
