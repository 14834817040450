import React from 'react';
import DatePickerCustom from '../../datepicker/DatePickerCustom';


function DatePickerRow({title, titleClassName, rowClassName, condition, data, dataClassName, onChangeDatePicker, maxDate}) {

    return (
        <div className="row">
            <h2 className={`title ${titleClassName ? titleClassName : ''}`}>{title}</h2>
            <div className="btns">
                <span className={dataClassName ? dataClassName : ''}>{data ? window.$Global.convertDate(data) : 'N/A'} </span>
                {
                    condition
                    &&
                    <DatePickerCustom data={data} onChangeDatePicker={onChangeDatePicker} maxDate={maxDate}/>
                }
            </div>
        </div>
    );
}

export default DatePickerRow;
