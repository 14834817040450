import "../css/HoldingsInvest.scss";

const HoldingsInvest = () => {
  const investData = [
    {
      name: "금년 투자금액",
      amout: "1,345백만원",
    },
    {
      name: "금년도 투자건수",
      amout: "12건",
    },
    {
      name: "누적 투자금액",
      amout: "6,320백만원",
    },
    {
      name: "누적 투자건수",
      amout: "57건 ",
    },
  ];
  return (
    <div id="HoldingsInvest">
      <div className="part">
        {investData?.length ? (
          <ul className="invest_list">
            {investData.map((item, idx) => (
              <li key={idx}>
                <i className={`holdings_invest_${idx}`}></i>
                <div className="invest">
                  <strong>{item.amout}</strong>
                  <p>{item.name}</p>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default HoldingsInvest;
