import React, { useContext, useEffect, useState } from "react";
import { ReactChannelIO } from "react-channel-plugin";
import ChannelTalkPage from "./ChannelTalkPage";
import memberContext from "../../store/Member";
import MemberAPI from "../../API/member";
import AdminAPI from "../../API/admin";
import axios from "axios";

const ChannelTalk = () => {
  const { auth } = useContext(memberContext);
  const isViewTypeIpnow = window.$Global.isIpnow();
  const isLogin = auth != null && auth != undefined;
  const [memberInfo, setMemberInfo] = useState({});

  useEffect(() => {
    if (isLogin) {
      MemberAPI.getInfo().then(async (res) => {
        let data = res.data;
        data.auth_name = window.$Global.convertAuthCodeToStr(res.data.rank);
        const isAdmin = await AdminAPI.checkAuth().then((res) => res.data);
        const companyType = res.data.company_type;
        let userInfo = {
          name: data.name,
          mobileNumber: data.phone,
          email: data.email,
          description: data.auth_name,
          memberId: data.id,
        };
        setMemberInfo(userInfo);
      });
    }
  }, []);

  if (isViewTypeIpnow) return null;

  return (
    <ReactChannelIO
      pluginKey={process.env.REACT_APP_CHANNEL_TALK_KEY}
      memberId={memberInfo.memberId}
      language="ko"
      autoBoot
      profile={memberInfo}
    >
      <ChannelTalkPage />
    </ReactChannelIO>
  );
};

export default ChannelTalk;
