import "../css/RenewalSignupCompany.scss";
import emoji from "../../../assets/images/intro/icon-smile.png";
import React, { useCallback, useEffect, useState, useContext } from "react";
import _ from "lodash";
import config from "../../../const/config";
import MemberAPI from "../../../API/member";
import message from "../../../const/message";
import InfoAPI from "../../../API/info";
import KeywordListWithCompany from "../../common/KeywordListWithCompany";
import { useHistory } from "react-router-dom";
import CommonContext from "../../../store/Common";
import SignupAlertPopup from "../SignupAlertPopup";
import PopupIPAutoAdd from "../PopupIPAutoAdd";
import SignupAlertAddSuccess from "../SignupAlertAddSuccess";

const RenewalSignupCompany = () => {
  const history = useHistory();
  const [next, setNext] = useState(false);
  const [firstPageReg, setFirstPageReg] = useState(false);
  const [toggleChecked, setToggleChecked] = useState(false);
  const [signInfo, setSignInfo] = useState({
    imp_uid: "",
    company: "",
    company_name: "",
    company_name_en: "",
    company_code: "",
    licence_number: "", // 사업자 등록번호
    corporate_number: "", // 법인번호
    unq_key: "",
    id: "",
    password: "",
    password_confirm: "",
    email: "",
    patent: [],
    tradeMark: [],
    design: [],
    product: [],
    manage_phone: "",
    phone: "",
    location: "", // 특허사무소
    // 투자자
    type_detail: 0,
    manager_name: "",
    company_phone: "",
    company_homepage: "",
    desc: "", // 투자자 회사 소개
    company_file: [],
    // 개인 가입자
    name: "",
    birth_at: "",
    personal_number: "", // 출원인 등록번호
    type: 0,
    biznavi_benefit_notification_agree: 0,
  });
  const [checkList, setCheckList] = useState({
    checkId: false,
    checkEmail: false,
    checkPassword: null,
    checkRegPassword: null,
  });
  const [showPassword, setShowPassword] = useState({
    password: false,
    password_confirm: false,
  });
  const [checkAll, setCheckAll] = useState(false);
  const [popupTerms, setPopupTerms] = useState(false);
  const [termsType, setTermsType] = useState(1);
  // alert
  const [alertShow, setAlertShow] = useState(false);
  const [alertSuccessShow, setAlertSuccessShow] = useState(false);
  // 팝업
  const [popupShow, setPopupShow] = useState(false);
  const [keywordList, setKeywordList] = useState([]);
  const [showKeywordList, setShowKeywordList] = useState(false);
  const [mouse, setMouse] = useState(false);
  const { isMobile } = useContext(CommonContext);

  useEffect(() => {
    const checkPhone = signInfo.phone?.length === 11;

    if (
      signInfo.id &&
      checkList.checkId &&
      signInfo.password &&
      checkList.checkPassword &&
      checkList.checkRegPassword &&
      checkPhone &&
      signInfo.email &&
      checkList.checkEmail &&
      checkList.checkAgree
    ) {
      setFirstPageReg(true);
    } else {
      setFirstPageReg(false);
    }

    if (
      signInfo.company_name &&
      signInfo.id &&
      checkList.checkId &&
      signInfo.password &&
      checkList.checkPassword &&
      checkList.checkRegPassword &&
      checkPhone &&
      signInfo.email &&
      checkList.checkEmail &&
      checkList.checkAgree
    ) {
      if (toggleChecked) {
        setCheckAll(true);
      } else {
        if (
          Boolean(signInfo.corporate_number) ||
          Boolean(signInfo.licence_number)
        ) {
          setCheckAll(true);
        } else {
          setCheckAll(false);
        }
      }
    } else {
      setCheckAll(false);
    }

    // if (
    //   signInfo.company_name &&
    //   (signInfo.corporate_number || signInfo.licence_number)
    // ) {
    //   setMCheckDetailInfo(true);
    // } else {
    //   setMCheckDetailInfo(false);
    // }
  }, [signInfo, checkList, toggleChecked]);

  useEffect(() => {
    setCheckList({ ...checkList, checkId: false });
  }, [signInfo.id]);

  useEffect(() => {
    setCheckList({ ...checkList, checkEmail: false });
  }, [signInfo.email]);

  useEffect(() => {
    if (!signInfo.password) return;

    let copy = _.cloneDeep(checkList);

    if (signInfo.password === signInfo.password_confirm) {
      copy.checkPassword = true;
    } else {
      copy.checkPassword = false;
    }

    if (!signInfo.password.match(config.checkReg)) {
      copy.checkRegPassword = false;
    } else {
      copy.checkRegPassword = true;
    }
    setCheckList(copy);
  }, [signInfo.password, signInfo.password_confirm]);

  const onClickCheckId = () => {
    if (!signInfo.id.match(config.idReg)) {
      alert(message.errorRegxId);
      return;
    }

    MemberAPI.idCheck(signInfo.id).then(() => {
      alert("사용가능한 아이디입니다");
      setCheckList({ ...checkList, checkId: true });
    });
  };

  const onClickCheckEmail = () => {
    if (!signInfo.email) {
      alert("이메일을 입력해주세요");
      return;
    }

    if (!signInfo.email.match(config.emailReg)) {
      alert(message.errorRegxEmail);
      return;
    }

    MemberAPI.emailCheck(signInfo.email).then(() => {
      alert("사용가능한 이메일입니다");
      setCheckList({ ...checkList, checkEmail: true });
    });
  };

  const onClickTermsShow = (type, e) => {
    e.preventDefault();
    setTermsType(type);
    setPopupTerms(true);
  };

  const onClickSignup = async () => {
    let payload = {
      ...signInfo,
    };

    delete payload.patent;
    delete payload.tradeMark;
    delete payload.design;

    await MemberAPI.signup(payload).then(async (res) => {
      let code = res.data.code;
      await insertIP(res);
      history.push("/taskManage/list?signUp=success");
    });
  };

  const onChangeFindCompany = (value, key) => {
    setSignInfo({ ...signInfo, [key]: value });
    // setMCheckCompanyCode(false);
    setAlertShow(false);
    setAlertSuccessShow(false);
    if (!value.replace(/(\s*)/g, "").length) {
      setKeywordList([]);
      return;
    }
    if (value.length < 2) return;
    debounceHandler(value, key, signInfo);
  };

  const debounceHandler = useCallback(
    _.debounce(async (value, key, signInfo) => {
      let data;
      if (key === "company" || key === "company_name") {
        data = await InfoAPI.getCompany(value, 0, 1).then((res) => res.data);
      } else {
        data = await InfoAPI.getCompany(value, 0, 0).then((res) => res.data);
      }

      // if (!Boolean(data.length)) {
      //   setMCheckCompanyCode(true);
      // }
      setKeywordList(
        <KeywordListWithCompany
          arr={data}
          signInfo={signInfo}
          onClickAutoInput={onClickAutoInput}
          onClickManualInput={onClickManualInput}
        />
      );
    }, 400),
    []
  );

  const onClickManualInput = () => {
    setKeywordList([]);
    // setMCheckCompanyCode(true);
  };

  const onClickAutoInput = (item, isMobile, signInfo) => {
    if (item.signed_check == 1) {
      setShowKeywordList(false);

      if (isMobile) {
        // setErrorAlready({show: true, company: item.name, email: item.email});
        return;
      } else {
        alert(
          "위 기업은 이미 가입되어 있습니다\n가입 정보를 확인하고 싶으시면 아래 정보로 연락부탁드립니다.\n대표전화: 1551-8442"
        );
        return;
      }
    } else {
      if (!isMobile) {
        setAlertShow(true);
      }
      setSignInfo({
        ...signInfo,
        name: item.name,
        company_code: item.company_code,
        company_name: item.name || "",
        company_name_en: item.name_en || "",
        licence_number: item.license_number || "",
        corporate_number: item.corporate_number || "",
        location: item.location || "",
        unq_key: item.unq_key,
        personal_number: item.company_code,
      });
      setKeywordList([]);
      // setMCheckCompanyCode(true);
    }
  };

  const onBlur = () => {
    if (!mouse) {
      setShowKeywordList(false);
    }
  };

  const onChangeToggle = () => {
    if (!toggleChecked) {
      setSignInfo({
        ...signInfo,
        corporate_number: "",
        licence_number: "",
      });
    }
    setToggleChecked((prev) => !prev);
  };

  const insertIP = async (res) => {
    console.log(res);
    let code = res.data.code;

    let object = {
      company_idx: res.data.company_idx,
      member_idx: res.data.member_idx,
      company_code: signInfo.company_code,
    };
    setTimeout(() => {
      let patent_length = signInfo.patent.length;
      let td_length = signInfo.tradeMark.length;
      let design_length = signInfo.design.length;

      if (patent_length) {
        let payload = {
          ...object,
        };
        payload.patent_list = signInfo.patent.map((item) => item.idx);
        InfoAPI.insertPatent(payload)
          .then()
          .catch((err) => err);
      }
      if (td_length) {
        let payload = {
          ...object,
        };
        payload.td_list = signInfo.tradeMark.map((item) => item.idx);
        InfoAPI.insertTrademark(payload)
          .then()
          .catch((err) => err);
      }
      if (design_length) {
        let payload = {
          ...object,
        };
        payload.design_list = signInfo.design.map((item) => item.idx);
        InfoAPI.insertDesign(payload)
          .then()
          .catch((err) => err);
      }

      if (patent_length || td_length || design_length) {
        alert(
          "선택하신 IP를 등록되는데에는 일정시간이 소요됩니다\n새로고침 후 정상적으로 이용 가능합니다"
        );
      }
    }, 100);
  };

  const onClickSearchPopupShow = () => {
    setAlertShow(false);
    setPopupShow(true);
  };

  const onClickSelectIPAdd = (json, code) => {
    setSignInfo({ ...signInfo, ...json, company_code: code });
    setPopupShow(false);
    setAlertSuccessShow(true);
  };

  return (
    <div id="RenewalSignupCompany">
      {!next ? (
        <div id="SignupCompanyFirst" className={isMobile ? "mo" : ""}>
          <div className="login_box">
            <div className="title">
              <h3>
                <img src={emoji} alt="기업회원 가입하기" />
                회원가입 하기
              </h3>
              <p>
                신규 회원가입하고, <br />
                비즈내비가 제공하는 업무 지원 서비스를 누려보세요.
              </p>
            </div>
            <div className="box_inner">
              <ul className="input_form">
                <li>
                  <label htmlFor="" className="required">
                    아이디
                  </label>
                  <div className="input">
                    <input
                      type="text"
                      id="user_id"
                      placeholder="입력하세요."
                      value={signInfo.id}
                      onChange={(e) =>
                        setSignInfo({ ...signInfo, id: e.target.value })
                      }
                    />
                    <button className="certify_btn" onClick={onClickCheckId}>
                      중복확인
                    </button>
                  </div>
                  <div className="msg">
                    <p className="normal">
                      한글/특수문자를 제외한 문자로만 입력해주세요.
                    </p>
                  </div>
                </li>
                <li>
                  <label htmlFor="" className="required">
                    이메일
                  </label>
                  <div className="input">
                    <input
                      type="text"
                      id="user_email"
                      placeholder="입력하세요."
                      value={signInfo.email}
                      onChange={(e) =>
                        setSignInfo({ ...signInfo, email: e.target.value })
                      }
                    />
                    <button className="certify_btn" onClick={onClickCheckEmail}>
                      중복확인
                    </button>
                  </div>
                </li>
                <li>
                  <label htmlFor="">이름</label>
                  <div className="input">
                    <input
                      type="text"
                      placeholder="입력하세요."
                      value={signInfo.name}
                      onChange={(e) =>
                        setSignInfo({ ...signInfo, name: e.target.value })
                      }
                    />
                  </div>
                </li>
                <li>
                  <label htmlFor="" className="required">
                    가입자 휴대폰
                  </label>
                  <div className="input">
                    <input
                      type="text"
                      id="user_phone"
                      placeholder="입력하세요."
                      value={signInfo.phone}
                      onChange={(e) =>
                        setSignInfo({
                          ...signInfo,
                          phone: window.$Global.isNumberInput(e.target.value),
                        })
                      }
                    />
                  </div>
                </li>
                <li>
                  <label htmlFor="" className="required">
                    비밀번호
                  </label>
                  <div className="input">
                    <input
                      type={showPassword.password ? "text" : "password"}
                      id="user_pw"
                      placeholder="입력하세요."
                      value={signInfo.password}
                      onChange={(e) =>
                        setSignInfo({ ...signInfo, password: e.target.value })
                      }
                    />
                    <i
                      className="icon_view_gray"
                      onMouseDown={() =>
                        setShowPassword({ ...showPassword, password: true })
                      }
                      onMouseUp={() =>
                        setShowPassword({ ...showPassword, password: false })
                      }
                    />
                  </div>
                  <div className="msg">
                    <p className="normal">
                      8~20자의 영문 대문자/소문자/숫자/특수문자(!@#$%^&*()+=-_)
                      혼용 사용
                    </p>
                  </div>
                </li>
                <li>
                  <label htmlFor="" className="required">
                    비밀번호 재확인
                  </label>
                  <div className="input">
                    <input
                      type={showPassword.password_confirm ? "text" : "password"}
                      id="user_pw_confirm"
                      placeholder="입력하세요."
                      value={signInfo.password_confirm}
                      onChange={(e) =>
                        setSignInfo({
                          ...signInfo,
                          password_confirm: e.target.value,
                        })
                      }
                    />
                    <i
                      className="icon_view_gray"
                      onMouseDown={() =>
                        setShowPassword({
                          ...showPassword,
                          password_confirm: true,
                        })
                      }
                      onMouseUp={() =>
                        setShowPassword({
                          ...showPassword,
                          password_confirm: false,
                        })
                      }
                    />
                  </div>
                  <div className="msg">
                    <p className="normal">동일한 비밀번호를 입력 해 주세요.</p>
                    {checkList.checkPassword != null &&
                      !checkList.checkPassword && (
                        <p className="error">
                          {message.errorNotEqualsPassword}
                        </p>
                      )}
                  </div>
                </li>
              </ul>
              <ul className="agree">
                <li>
                  <label>
                    <input
                      type="checkbox"
                      checked={checkList.checkAgree}
                      onChange={(e) =>
                        setCheckList({
                          ...checkList,
                          checkAgree: e.target.checked,
                        })
                      }
                    />
                    <i
                      className={
                        checkList.checkAgree
                          ? "icon_checkbox_blue"
                          : "icon_checkbox_gray"
                      }
                    />
                    <strong className="red">[필수]</strong>
                    <span>서비스 이용약관</span>, <span>개인정보처리방침</span>
                    을 확인하였고, 이에 동의합니다.
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      checked={checkList.biznavi_benefit_notification_agree}
                      onChange={(e) =>
                        setCheckList({
                          ...checkList,
                          biznavi_benefit_notification_agree: e.target.checked,
                        })
                      }
                    />
                    <i
                      className={
                        checkList.biznavi_benefit_notification_agree
                          ? "icon_checkbox_blue"
                          : "icon_checkbox_gray"
                      }
                    />
                    <strong>[선택]</strong> <span>비즈내비 혜택 알림 수신</span>
                    에 동의합니다.
                  </label>
                </li>
              </ul>
              <div className="btn">
                <button
                  onClick={() => setNext(true)}
                  className={firstPageReg ? "" : "btn_disabled"}
                >
                  다음
                </button>
              </div>
              <p className="join">
                이미 가입한 회원이라면?&emsp;
                <a href="#">
                  로그인 하기
                  <i className="icon_arrow_right_s" />
                </a>
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div id="RenewalSignupCompanySecond" className={isMobile ? "mo" : ""}>
          <div className="login_box">
            <div className="title">
              <h3>
                <img src={emoji} alt="기업회원 가입하기" />
                회원가입 하기
              </h3>
              <p>
                회사 정보를 입력하고, <br />
                기업회원에게만 제공되는 혜택을 누려 보세요.
              </p>
            </div>
            <div className="box_inner">
              <ul className="input_form">
                <li>
                  <label htmlFor="" className="required">
                    회사 이름(법인명)
                  </label>
                  <div
                    className="input"
                    onMouseEnter={() => setMouse(true)}
                    onMouseLeave={() => setMouse(false)}
                  >
                    {toggleChecked ? (
                      <input
                        type="text"
                        id="user_company"
                        value={signInfo.company_name}
                        placeholder="회사명을 입력하세요."
                        onChange={(e) =>
                          setSignInfo({
                            ...signInfo,
                            company_name: e.target.value,
                          })
                        }
                      />
                    ) : (
                      <>
                        <input
                          type="text"
                          id="user_company"
                          value={signInfo.company_name}
                          placeholder="회사명을 입력하세요."
                          onChange={(e) =>
                            onChangeFindCompany(e.target.value, "company_name")
                          }
                          onFocus={() => setShowKeywordList(true)}
                          onBlur={onBlur}
                          autoFocus
                        />
                        {showKeywordList && keywordList}
                      </>
                    )}
                  </div>
                  <div className="setting">
                    <i className="icon_info_with_blue_bg" />
                    회사정보 없이 시작하기{" "}
                    <span
                      className={toggleChecked ? "switch on" : "switch"}
                      onClick={onChangeToggle}
                    />
                  </div>
                </li>
                <li>
                  <label htmlFor="">법인 번호</label>
                  <div className="input">
                    <input
                      type="text"
                      id="user_corporate_number"
                      placeholder="기업명을 검색하시면 자동입력 됩니다."
                      disabled={toggleChecked}
                      value={signInfo.corporate_number}
                      onChange={(e) =>
                        setSignInfo({
                          ...signInfo,
                          corporate_number: e.target.value,
                        })
                      }
                    />
                    {/*<a href="#" className="search_btn">*/}
                    {/*  <i className="icon_search_blue" />*/}
                    {/*</a>*/}
                  </div>
                </li>
                <li>
                  <label htmlFor="">사업자 등록번호</label>
                  <div className="input">
                    <input
                      type="text"
                      id="user_licence_number"
                      placeholder="기업명을 검색하시면 자동입력 됩니다."
                      disabled={toggleChecked}
                      value={signInfo.licence_number}
                      onChange={(e) =>
                        setSignInfo({
                          ...signInfo,
                          licence_number: e.target.value,
                        })
                      }
                    />
                    {/*<a href="#" className="search_btn">*/}
                    {/*  <i className="icon_search_blue" />*/}
                    {/*</a>*/}
                  </div>
                </li>
              </ul>
              <div className="btn">
                <button
                  onClick={onClickSignup}
                  className={checkAll ? "" : "btn_disabled"}
                >
                  가입하기
                </button>
              </div>
              <div className="btn white">
                <button onClick={() => setNext(false)}>이 전</button>
              </div>
            </div>
          </div>
        </div>
      )}
      {alertShow && <SignupAlertPopup onClick={onClickSearchPopupShow} />}

      {popupShow && (
        <PopupIPAutoAdd
          company_code={signInfo.company_code}
          onClickSelectIPAdd={onClickSelectIPAdd}
        />
      )}

      {alertSuccessShow && (
        <SignupAlertAddSuccess onClick={() => setAlertSuccessShow(false)} />
      )}
    </div>
  );
};

export default RenewalSignupCompany;
