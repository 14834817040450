// 접근 권한 없을 시 띄우는 Alert
import "./css/PaymentAlert.scss";
import { useHistory } from "react-router-dom";

const MenuBlockAlert = ({ setShowMenuBlockAlert }) => {
  return (
    <div id="PaymentAlert">
      <i
        className="icon_exit_gray"
        onClick={() => setShowMenuBlockAlert(false)}
      />
      <h2>메뉴 접근 권한 없음</h2>
      <h5>해당 서비스는 관리자만 이용 가능한 서비스 입니다.</h5>
    </div>
  );
};

export default MenuBlockAlert;
