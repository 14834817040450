import GroupwareSideBar from "./GroupwareSideBar";
import { groupWareEmpPath, groupWareExpensionWorksPath, groupwareAdminmenuPath } from "./groupwarePath";
import "./css/GroupwareWorks.scss";
import { useLocation } from "react-router-dom"
import "moment/locale/ko";
import { param } from "express/lib/router";
import config from "../../const/config";
import React, { useEffect, useState } from "react";
import IframeResizer from 'iframe-resizer-react';

const getAppPath = (section, command, params) => {
  var src = config.gwUrl + "/";
  let adminMenuParam = "";
  if (section == "assignmenu") {
    src += "menu.admin.do";
    // 어드민 권한만 보이도록 adminMenu 설정
    adminMenuParam = "&menuCd=200060"; 
  } else if (section == "service") {
    src += "service.auth.do";
    // 어드민 권한만 보이도록 adminMenu 설정
    adminMenuParam = "&menuCd=200420";
  } else if (section == "reservation") {
    switch (command) {
      case "vehicleReserve":
        src += "vehicle.do";
        break;
      case "insBusinessCardForm":
      case "lstBusinessCard":
        src += "rental.auth.do";
        break;
      case "conferenceReserve":
      default:
        src += "conference.do";
        break;
    }
  }
  src += "?command=" + command + adminMenuParam;
  return src;
};

const resizeIframe = (ifrIdName) => {
  //var the_height = document.getElementById(ifrIdName).body.scrollHeight;
  //document.getElementById(ifrIdName).height = the_height;
  //let iframe = document.querySelector("#contentWrap");
  //alert(iframe.contentDocument.body.scrollHeight);
  //iframe.style.height = iframe.contentDocument.body.scrollHeight;
};

export const GroupwareAdminMenus = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  var isSection = !params.get('section') ? "doc" : params.get('section');
  var isCommand = !params.get('command') ? "writeForm" : params.get("command");
  var isApp = getAppPath(isSection, isCommand, params) + "&siteFlag=biznavi";

  // useEffect(() => {
  //   window.addEventListener(
  //     "message",
  //     (e) => {
  //       //alert(e.data.iframeHeight);
  //       let height = e.data.iframeHeight;
  //       if (height < 800) {
  //         height = 800;
  //       }
  //       document.getElementById('contentWrap').height = height + 20;
  //     },
  //     false
  //   );
  // }, []);

  return (
    <div id="GroupwareWorks">
      <GroupwareSideBar currentPath={groupwareAdminmenuPath} />
      <div className="commute_wrapper">
        {/* <iframe
          id="contentWrap"
          src={isApp}
          width="100%"
          height="800px"
          onLoad={() => {
            resizeIframe("contentWrap");
          }}
        /> */}
        <IframeResizer
          id="contentWrap"
          src={isApp}
          style={{ minWidth: '100%' }}
          heightCalculationMethod='bodyScroll'
        />
      </div>
    </div>
  );
};

export default GroupwareAdminMenus;















