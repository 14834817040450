import React from 'react';
import ToggleButtonWithSpan from "../../common/ToggleButtonWithSpan";

function ToggleButtonRow({title, selected, condition, toggleSelected}) {
    return (
        <div className="row">
            <h2 className="title">{title}</h2>
            {
                condition
                    ? <ToggleButtonWithSpan text="" condition={{on: "유", off: "무"}} selected={selected} toggleSelected={toggleSelected}/>
                    : <p>{selected ? "유" : "무"}</p>
            }
        </div>
    );
}

export default ToggleButtonRow;
