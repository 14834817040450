import React, { useState, useRef, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import "./RenewalLogin.scss";
import AdminAPI from "../../API/admin";
import MemberAPI from "../../API/member";
import Validator from "../../util/validator";
import config from "../../const/config";
import CommonContext from "../../store/Common";

let { Kakao } = window;

const RenewalLogin = ({ history }) => {
  let idRef = useRef();
  let pwRef = useRef();
  let frmRef = useRef();
  let gwParam = {};
  let [input, setInput] = useState([]);
  const [error, setError] = useState(false);
  const [loginType, setLoginType] = useState(0); // 0 기업, 1 개인
  const isViewTypeIpnow = window.$Global.isIpnow();
  const isPersonalLogin = loginType == 1;
  const inputRef = useRef([]);
  const { isMobile } = useContext(CommonContext);

  useEffect(() => {
    idRef.current.value = "";
    pwRef.current.value = "";
    setError(false);
  }, [loginType]);

  const onClickLoginToKakao = () => {
    Kakao.Auth.login({
      throughTalk: false,
      success: function (res) {
        MemberAPI.loginKakao(res.access_token).then((res) => {
          let data = res.data;
          checkLogin(data, loginToKakaoError);
        });
      },
      fail: function (error) {
        alert(error);
      },
    });
  };

  const loginToKakaoError = () => {
    alert("가입하지 않은 계정입니다\n가입 후 이용 부탁드립니다");
    if (isViewTypeIpnow) {
      history.push("/signup/terms?type=personal");
    } else {
      history.push("/signup/type");
    }
  };

  const gwLogin = (errorCallback) => {
    if (gwParam.token) {
      localStorage.setItem(config.keyToken, gwParam.token);
      localStorage.setItem(config.gwKeyToken, gwParam.gwParam.gwToken);
      inputRef.current[0].value = gwParam.gwParam.id;
      inputRef.current[1].value = gwParam.gwParam.idx;
      inputRef.current[2].value = gwParam.gwParam.companyIdx;
      inputRef.current[3].value = gwParam.gwParam.loginToken;
      //inputRef.current[4].value = window.location.hostname;

      const isAdmin = AdminAPI.checkAuth({
        headers: { "x-token": gwParam.token },
      }).then((res) => res.data);

      let historyURL = localStorage.getItem("historyURL");
      frmRef.current.requestSubmit();
    } else {
      errorCallback();
    }
  };

  const onClickLogin = () => {
    let id = idRef.current.value;
    let pw = pwRef.current.value;
    let refs = [idRef, pwRef];
    let returnData;
    if (Validator.refValidator(refs)) {
      MemberAPI.login({ is_corporate: loginType, id: id, password: pw }).then(
        (res) => {
          const data = res.data;
          gwParam = res.data;
          console.log(returnData);
          inputRef.current[0].value = res.data.token;
          gwLogin(() => setError(true));
          //checkLogin(data, () => setError(true));
        }
      );
    }
  };

  const checkLogin = async (data, errorCallback) => {
    if (data.token) {
      localStorage.setItem(config.keyToken, data.token);
      inputRef.current[0].value = data.token;
      //console.log(inputRef.current[0].value);

      const isAdmin = await AdminAPI.checkAuth({
        headers: { "x-token": data.token },
      }).then((res) => res.data);

      let historyURL = localStorage.getItem("historyURL");
      if (historyURL) {
        window.location.href = historyURL;
        localStorage.removeItem("historyURL");
        return;
      }

      if (window.$Global.checkAuth("대리인", data.rank)) {
        window.location.href = "/choice";
      } else if (isAdmin) {
        window.location.href = "/admin/check";
      } else {
        window.location.href = "/";
      }
    } else {
      errorCallback();
    }
  };

  const kakaoLoginButton = () =>
    isPersonalLogin && (
      <button className="btn_kakao" onClick={onClickLoginToKakao}>
        <span>카카오 계정으로 로그인</span>
        <i className="icon_kakao" />
      </button>
    );

  const loginTab = (
    <ul className="tab">
      <li
        className={isPersonalLogin ? "active" : ""}
        onClick={() => setLoginType(1)}
      >
        <i className="icon_user_outline" />
        <p>개인회원</p>
      </li>
      <li
        className={!isPersonalLogin ? "active" : ""}
        onClick={() => setLoginType(0)}
      >
        <i className="icon_business_outline" />
        <p>{isViewTypeIpnow ? "기업 / 특허사무소 / 투자자" : "기업회원"}</p>
      </li>
    </ul>
  );

  return (
    <div id="RenewalLogin" className={`login ${isMobile ? "mo" : ""}`}>
      <div className="wrap_form">
        <div className="login_form">
          <div className="form">
            <h2>로그인 하기</h2>
            <div className="input_wrap">
              <p>아이디</p>
              <div className="input_area id_area">
                <input
                  type="text"
                  id="id"
                  className="input_id"
                  data-name="아이디"
                  ref={idRef}
                  placeholder="입력하세요."
                  onKeyUp={(e) => {
                    if (e.key === "Enter") onClickLogin();
                  }}
                />
              </div>
              <p>비밀번호</p>
              <div className="input_area pw_area">
                <input
                  type="password"
                  id="pw"
                  className="input_password"
                  data-name="비밀번호"
                  ref={pwRef}
                  placeholder="비밀번호를 입력하세요."
                  onKeyUp={(e) => {
                    if (e.key === "Enter") onClickLogin();
                  }}
                />
              </div>
            </div>
            {error && (
              <p className="error">
                가입하지 않은 아이디이거나 잘못된 비밀번호입니다.
              </p>
            )}
            <div className="account_search">
              <Link to="/find/id">아이디 찾기</Link>
              <Link to="/find/pw">비밀번호 찾기</Link>
            </div>

            <button className="btn_login" onClick={() => onClickLogin()}>
              로그인
            </button>
            {/*{kakaoLoginButton()}*/}
            <Link to="/signup/type" className="signup">
              계정이 필요하신가요?&emsp;<span>가입하기</span>{" "}
              <i className="icon_arrow_right_s" />
            </Link>
          </div>
        </div>
      </div>
      <form
        name="frm"
        ref={frmRef}
        method="POST"
        target="_self"
        action={`${config.gwUrl}/bizoffi-proc/login/bizNaviLoginForm.do`}
      >
        <input
          type="hidden"
          id="emp_no"
          name="emp_no"
          ref={(el) => (inputRef.current[0] = el)}
        />
        <input
          type="hidden"
          id="idx"
          name="idx"
          ref={(el) => (inputRef.current[1] = el)}
        />
        <input
          type="hidden"
          id="companyIdx"
          name="companyIdx"
          ref={(el) => (inputRef.current[2] = el)}
        />
        <input
          type="hidden"
          id="loginToken"
          name="loginToken"
          ref={(el) => (inputRef.current[3] = el)}
        />
      </form>
    </div>
  );
};

export default RenewalLogin;
