import React, { useState, useEffect } from "react";
import "../css/ExpectationAnnual.scss";
import AnnualFeeChart from "../AnnualFeeChart";
import AnnualPayAPI from "../../../API/annualPay";
import CommonAPI from "../../../API/common";
import PopupAnnualFeeCalc from "../PopupAnnualFeeCalc";
import { useLocation } from "react-router-dom";

function AdminExpectationAnnual({ history }) {
  const location = useLocation();
  const { company_idx } = location.state;
  console.log(company_idx);
  const [option, setOption] = useState({});
  const [arr, setArr] = useState([]);
  const [popupShow, setPopupShow] = useState(false);
  const year = 2022;
  window.localStorage.setItem("curPage", 1);
  window.localStorage.setItem("showPatent", true);
  let initPayload = {
    start_year: year,
    end_year: year + 15,
  };

  useEffect(async () => {
    let payload = await CommonAPI.getOption(0).then((res) => {
      let option = res.data[0]?.option || {};
      setOption(option);
      return option;
    });

    payload = { ...initPayload, ...payload };

    await getAnnualPay(payload);

    if (window.$Global.isEmptyObject(payload)) {
      setPopupShow(true);
    }
  }, []);

  const getAnnualPay = (payload) => {
    AnnualPayAPI.getAnnualPay(payload).then((res) => {
      setArr(res.data);
    });
  };

  const onClickSuccess = (payload) => {
    payload = { ...initPayload, ...payload };
    let add_option = {
      type: 0,
      option: {
        ...payload,
      },
    };
    CommonAPI.addOption(add_option);
    getAnnualPay(payload);
    onClickClose();
  };

  const onClickClose = () => {
    setPopupShow(false);
  };

  const redirect = (year) => {
    history.push(`/admin/annual/${company_idx}`);
  };

  return (
    <div id="ExpectationAnnual">
      <div className="header">
        <div className="info">
          <h2>
            “{localStorage.getItem("company_name")}”의 예상연차료 리스트
          </h2>
          <p>
            <i className="icon_badge_info_gray" />
            <span>
              연도를 선택해 각 연도별 연차료 지급 리스트를 확인할 수 있습니다.
            </span>
          </p>
        </div>
        <button
          className="search_inventor"
          onClick={() => history.push("/inventor/list")}
        >
          전체 발명자 찾기
        </button>
      </div>
      <div className="flex">
        <div className="annual_fee_button">
          <p>
            {year} ~ {year + 15}년
          </p>
          <button className="btn_calc" onClick={() => setPopupShow(true)}>
            연차료 조건 설정
          </button>
        </div>
        <div className="legend">
          <div className="item">
            <div className="circle blue" />
            <p>특허</p>
          </div>
          <div className="item">
            <div className="circle main_color" />
            <p>상표</p>
          </div>
        </div>
      </div>
      <AnnualFeeChart history={history} redirect={redirect} arr_data={arr} />
      {popupShow && (
        <PopupAnnualFeeCalc
          option={option}
          onClickSuccess={onClickSuccess}
          onClickClose={onClickClose}
        />
      )}
    </div>
  );
}

export default AdminExpectationAnnual;
