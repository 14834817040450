/*
 * 구독 멤버쉽 > 패키지 옆 FREE, BEST 뱃지
 * 1 = FREE
 * 2 = BEST
 * 0 = NULL
 * */

import "./css/Badge.scss";

const Badge = ({ badgeType }) => {
  const text = badgeType == 1 ? "FREE" : badgeType == 2 ? "BEST" : null;
  const style =
    badgeType == 1
      ? {
          backgroundColor: "#4fff00",
          color: "#000",
        }
      : badgeType == 2
      ? {
          backgroundColor: "#ffe8e1",
          color: "#d94925",
        }
      : null;

  return (
    badgeType != 0 && (
      <div className="badge" style={style}>
        <p>{text}</p>
      </div>
    )
  );
};

export default Badge;
