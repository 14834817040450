import React, { useEffect, useRef, useState } from "react";
import "./css/ResearchTechKeywordSearch.scss";
import { useHistory, Link, useLocation } from "react-router-dom";
import CommonAPI from "../../API/common";
import Pagination from "../common/Pagination";
import ResearchTechAddPatent from "./ResearchTechAddPatent";
import { useRecoilState } from "recoil";
import { applyNumberArray, keywordIdx, researchTechHistory } from "../../atom";
import InfoCompanyAPI from "../../API/infoCompany";
import InfoAPI from "../../API/info";
import AnnualFeeReport from "../annualFee/AnnualFeeReport";
import ReportButton from './ReportButton';
const ResearchTechMyPatentSearch = ({ changeTab }) => {
  const history = useHistory();
  const location = useLocation();
  const [list, setList] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [addPatentData, setAddPatentData] = useState([]);
  const [count, setCount] = useState(10);
  const [last, setLast] = useState(1);
  const [companyName, setCompanyName] = useState("");
  const companyCode = window.localStorage.getItem("company_code");
  const [companyIdx, setCompanyIdx] = useState(null);
  const [corporateNumber, setCorporateNumber] = useState("");
  const [licenseNumber, setLicenseNumber] = useState("");
  const [applyNumArr, setApplyNumArr] = useRecoilState(applyNumberArray);
  const [historyData, setHistoryData] = useRecoilState(researchTechHistory);
  const { isKeywordSearch } = location.search;
  const [isReportShow, setIsReportShow] = useState(false);
  const [annualFeeData, setAnnualFeeData] = useState({});

  useEffect(() => {
    if (typeof changeTab === "object") {
      changeTab = changeTab.isKeywordSearch;
    }
    if (changeTab === 5) {
      getMyPatentList();
    }
    const storedCompanyIdx = window.localStorage.getItem("company_idx");
    setCompanyIdx(storedCompanyIdx);
  }, [curPage, count]);

  const getMyPatentList = () => {
    let params = {
      company_code: companyCode,
      page: curPage,
      count: count,
    };
    InfoAPI.getMyPatent(params).then((res) => {
      res.data.items.map((el) => {
        if (el.nation === "KR") return el.apply_number.replace(/-/g, "");
      });
      setList(res.data.items);
      setTotalCount(res.data.total_count);
      setLast(res.data.last);
    });
  };

  const sendData = () => {
    let arr = [];
    let params = {
      company_code: companyCode,
    };
    InfoAPI.getCompanyPatent(params)
        .then((res) => {
          res.data.map((el) => {
            if (el.nation === "KR") arr.push(el.apply_number.replace(/-/g, ""));
          });
        })
        .then(() => {
          setApplyNumArr(arr);
          setHistoryData({
            companyCode: companyCode,
            companyName: companyName,
            isKeywordSearch: 5,
          });
          history.push(`edit?company_code=${companyCode}`);
        });
  };

  const handleCountChange = (e) => {
    setCount(e.target.value);
    setCurPage(1);
  };

  // 특허 활용 보고서 태그 실행
  /*const ReportButton = ({ item }) => {
    const [buttonState, setButtonState] = useState(() => {
      if ((item.pge_idx && item.org_nation === 0) || (item.pge_idx_f && item.org_nation !== 0)) {
        return 'READY';
      } else if (item.apply_cnt > 0 || item.isIng) {
        return 'GENERATING';
      } else {
        return 'INITIAL';
      }
    });

    useEffect(() => {
      console.log(`buttonState changed: ${buttonState}`);
    }, [buttonState]);

    const [reportIdx, setReportIdx] = useState(item.reportIdx || null);

    useEffect(() => {
      let interval;
      if (buttonState === 'GENERATING') {
        interval = setInterval(() => {
          CommonAPI.getReportApplyCheck(item).then((isReady) => {
            debugger;
            if (isReady) {
              item.isIng = false;
              setButtonState('READY');
              clearInterval(interval);
            } else {
              item.isIng = false;
              setButtonState('GENERATING');
              clearInterval(interval);
            }
          });
        }, 10000); // 30초마다 상태 확인
      }
      return () => clearInterval(interval);
    }, [buttonState, item.idx]);

    const handleReportApply = () => {
      const confirm = window.confirm("특허활용 보고서를 생성하시겠습니까?");
      if (confirm) {
        // 먼저 상태를 변경하여 버튼을 '생성중'으로 설정
        setButtonState('GENERATING');
        item.isIng = true;
        //setTimeout(() => {
        CommonAPI.patentReportApply(item).then((response) => {
          //setButtonState('GENERATING'); // 버튼 상태를 '생성중'으로 바로 변경
          alert("보고서 생성까지 5분정도 소요됩니다.\n5분후 새로고침 해 주세요.");
          const generatedReportIdx = response.data.reportIdx;
          //alert(generatedReportIdx);
          //setReportIdx(generatedReportIdx);
          // getPatent() 대신 버튼 상태만 '생성중'으로 설정
        }).catch((error) => {
          // 에러 처리: 상태를 초기 상태로 복원
          console.error("Error during report generation:", error);
          alert("보고서 생성에 실패했습니다. 다시 시도해 주세요.");
          setButtonState('INITIAL'); // 오류가 발생하면 상태를 원래대로 되돌림
        });
        //}, 200);  // 0ms 딜레이로 비동기 작업 지연
      }
    };

    /!*    const handleReportShow = () => {
          setIsReportShow(true);
          setAnnualFeeData({
            report_idx: item.pge_idx || item.pge_idx2 || item.pge_idx_f || "",
            nation: item.nation,
            apply_number: item.apply_number,
            register_number: item.register_number || item.regist_number,
            idx: item.idx,
          });
        };*!/

    if (buttonState === 'READY') {
      return (
          <button
              className="report_btn"
              onClick={(event) => {
                event.stopPropagation();

                let patent_idx = item.idx;
                let family_idx = item.family_idx;

                if (item.org_nation !== 0) {
                  // 해외특허 처리
                }

                setIsReportShow(true);
                setAnnualFeeData({
                  manage_number: item.manage_number,
                  patent_idx: patent_idx,
                  nation: item.org_nation,
                  register_number: item.register_number || item.regist_number,
                  first_renewal_expiration_deadline: item.first_renewal_expiration_deadline,
                  apply_number: item.apply_number,
                  document_number: item.document_number,
                  company_name: item.company_name,
                  family_idx: family_idx,
                  type: "patent",
                  year: item.year,
                });
              }}
          >
            확인하기
          </button>
      );
    } else if (buttonState === 'GENERATING') {
      return "생성중";
    } else if (item.nation === "KR") {
      if (item.register_number !== null && item.register_number !== "") {
        return (
            <button
                className="report_btn"
                onClick={(event) => {
                  event.stopPropagation();
                  handleReportApply();
                }}
            >
              생성하기
            </button>
        );
      } else {
        return "-";
      }
    } else {
      if (
          item.parent_register_number !== null &&
          item.parent_register_number !== "" &&
          item.parent_register_number !== undefined
      ) {
        return (
            <button
                className="report_btn"
                onClick={(event) => {
                  event.stopPropagation();
                  handleReportApply();
                }}
            >
              생성하기
            </button>
        );
      } else {
        return "-";
      }
    }
  };*/

  return (
      <div id="ResearchTechKeywordSearch">
        {list.length > 0 ? (
            <>
              <div className="keyword_search_wrapper">
                <div className="keyword_header">
                  <h2>
                    검색 결과 <span>{totalCount}</span>건
                  </h2>
                  <div className="util_btn">
                    <select name="count" onChange={(e) => handleCountChange(e)}>
                      <option value={10}>10건 씩 보기</option>
                      <option value={20}>20건 씩 보기</option>
                      <option value={50}>50건 씩 보기</option>
                    </select>
                    <button onClick={() => sendData()}>보고서 작성하기</button>
                  </div>
                </div>
                <table>
                  <colgroup>
                    <col width={50} />
                    <col width={50} />
                    <col width={150} />
                    <col width={100} />
                    <col width={150} />
                    <col width={100} />
                    <col width={500} />
                    <col width={75} />
                    <col width={70} />
                  </colgroup>
                  <thead>
                  <tr>
                    <th>번 호</th>
                    <th>국가</th>
                    <th>등록번호</th>
                    <th>등록일</th>
                    <th>출원번호</th>
                    <th>출원일</th>
                    <th style={{ textAlign: "left" }}>발명의 명칭</th>
                    <th>등급</th>
                    <th> {"특허보고서"}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {list?.map((item, idx) => {
                    return (
                        <tr
                            style={{ opacity: item.isDeleted && "0.4" }}
                            onClick={() => {
                              if (
                                  (item.nation === "KR" && item.apply_number == null) ||
                                  (item.nation !== "KR" && item.document_number == null)
                              ) {
                                alert("특허 상세보기 데이터가 없습니다.");
                                return;
                              }
                              history.push({
                                pathname: `patent/${
                                    item.nation === "KR"
                                        ? item.apply_number.replace(/-/g, "")
                                        : item.document_number
                                }/detail?nation=${item.nation}`,
                                state: {
                                  // companyKeyword: searchInput,
                                  companyCode: companyCode,
                                  companyName: companyName,
                                  corporateNumber: corporateNumber,
                                  licenseNumber: licenseNumber,
                                  isKeywordSearch: 5,
                                },
                              });
                              window.location.reload();
                            }}
                        >
                          <td>{(curPage - 1) * 10 + (idx + 1)}</td>
                          <td>{item.nation}</td>
                          <td>{item.register_number}</td>
                          <td>{window.$Global.convertDate(item.register_at)}</td>
                          <td>{item.apply_number}</td>
                          <td>{window.$Global.convertDate(item.apply_at)}</td>
                          <td style={{ textAlign: "left" }}>
                            {item.invention_title || item.invention_name_en}
                          </td>
                          <td
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                          >
                            <div
                                className={`circle grade_${
                                    item.sm_grade || "default"
                                }`}
                            >
                              {item.sm_grade || "N"}
                            </div>
                          </td>
                          <td>
                            {" "}
                            {(<ReportButton item={item} setIsReportShow={setIsReportShow} setAnnualFeeData={setAnnualFeeData} />)}
                          </td>
                        </tr>
                    );
                  })}
                  </tbody>
                </table>
              </div>
              <Pagination curPage={curPage} lastNum={last} onClick={setCurPage} />
            </>
        ) : (
            <div className="no_data_wrapper">특허 데이터가 없습니다.</div>
        )}
        {showPopup && (
            <ResearchTechAddPatent
                onChangeShow={setShowPopup}
                list={addPatentData}
                setList={setAddPatentData}
                setCurPage={setCurPage}
            />
        )}
        {isReportShow && (
            <AnnualFeeReport
                patent_idx={annualFeeData.patent_idx}
                annualFeeData={annualFeeData}
                setIsReportShow={setIsReportShow}
                family_idx={annualFeeData.family_idx}
            />
        )}
      </div>
  );
};

export default ResearchTechMyPatentSearch;
