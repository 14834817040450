import React from "react";
import UserAuthRoute from "./common/UserAuthRoute";
import MarketInfoCategory from "../components/marketInfo/MarketInfoCategory";
import MarketInfoMain from "../components/marketInfo/MarketInfoMain";
import MarketInfoMainTest from "../components/marketInfo/MarketInfoMainTest";
import MarketInfoEdit from "../components/marketInfo/edit/MarketInfoEdit";
import MarketInfoMy from "../components/marketInfo/MarketInfoMy";
import MarketInfoRequest from "../components/marketInfo/request/MarketInfoRequest";
import CustomSwitch from "./common/CustomSwitch";
import MarketInfoRequestEnd from "../components/marketInfo/request/MarketInfoRequestEnd";
import MarketInfoTest from "../components/marketInfo/MarketInfoTest";
import MarketInfoHeader from "../components/marketInfo/MarketInfoHeader";
import MarketInfoSitemap from "../components/marketInfo/MarketInfoSitemap";
import MarketInfoUpdateChart from "../components/marketInfo/MarketInfoUpdateChart";

function MarketInfoRoute({ match }) {
  return (
    <>
      <UserAuthRoute
        exact
        path={`${match.path}/*`}
        requireAuth={false}
        component={MarketInfoHeader}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/*`}
        requireAuth={false}
        component={MarketInfoSitemap}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/category`}
        requireAuth={false}
        component={MarketInfoCategory}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/list`}
        requireAuth={false}
        component={MarketInfoMain}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/chart`}
        requireAuth={false}
        component={MarketInfoUpdateChart}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/test/:idx`}
        requireAuth={false}
        component={MarketInfoTest}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/edit`}
        requireAuth={true}
        component={MarketInfoEdit}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/my`}
        requireAuth={true}
        component={MarketInfoMy}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/request`}
        requireAuth={true}
        component={MarketInfoRequest}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/request/end`}
        requireAuth={true}
        component={MarketInfoRequestEnd}
      />
    </>
  );
}

export default MarketInfoRoute;
