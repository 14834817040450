import "./css/ResearchTechRelatedPopup.scss";
import CommonAPI from "../../API/common";
import PopupTaskDetail from "../taskManage/PopupTaskDetail";
import React, { useState } from "react";
import _ from "lodash";
import Const from "../taskManage/const";

const ResearchTechRelatedPopup = ({ data, onChange }) => {
  console.log(data);
  const [popShow, setPopShow] = useState(false);
  const [list, setList] = useState({});
  const [field, setField] = useState({});
  const showRelatedTask = (idx) => {
    CommonAPI.getTaskField(Const.showType.bizAll).then((res) =>
      setField(res.data)
    );
    CommonAPI.getTaskInfo(idx).then((res) => {
      setList(res.data);
      setPopShow(true);
    });
  };

  const onClickInterestAdd = async (idx) => {
    await CommonAPI.addInterest(idx).then(() => {
      alert("관심공고에 추가되었습니다");
    });

    let copy = _.cloneDeep(list);

    copy.items.map((item) => {
      if (item.idx == idx) {
        item.is_interest = !item.is_interest;
      }
    });
    setList(copy);
  };

  return (
    <div id="ResearchTechRelatedPopup">
      <div className="popup_header">
        <h2>연관 정부과제 리스트</h2>
        <i className="icon_exit" onClick={() => onChange(false)} />
      </div>
      <table>
        <colgroup>
          <col width="80%" />
          <col width="20%" />
        </colgroup>
        <thead>
          <tr>
            <th>공고 명</th>
            <th>바로가기</th>
          </tr>
        </thead>
        <tbody>
          {data.map((el) => {
            return (
              <tr>
                <td>{el.title}</td>
                <td>
                  <button onClick={() => showRelatedTask(el.idx)}>
                    공고 바로가기
                    <i className="icon_arrow_right_s" />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <PopupTaskDetail
        show={popShow}
        item={list}
        field={field}
        onClickInterestAdd={onClickInterestAdd}
        onClose={() => setPopShow(false)}
      />
    </div>
  );
};

export default ResearchTechRelatedPopup;
