import { useHistory } from "react-router-dom/cjs/react-router-dom";

const StepBtns = ({ prev, next, goNext, goPrev, end }) => {
  return (
    <div id="StepBtns">
      <div className={`step_btns ${!prev && next ? "only_next" : ""}`}>
        {prev ? (
          <button className="back" onClick={() => goPrev()}>
            <img
              src={
                require("../../../assets/images/cashflow/step_back.png").default
              }
              alt="icon"
            />
            BACK
          </button>
        ) : (
          ""
        )}
        {next && !end ? (
          <button className="next green_bg" onClick={() => goNext()}>
            NEXT
            <img
              src={
                require("../../../assets/images/cashflow/step_next.png").default
              }
              alt="icon"
            />
          </button>
        ) : (
          ""
        )}
        {end ? (
          <button className="next end green_bg" onClick={() => goNext()}>
            완료하기
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default StepBtns;
