import React from "react";
import CommonAPI from "../../API/common";
import "./css/MarketInfoPopupDetail.scss";

function MarketInfoPopupDetail({ show, type, item, onClose }) {
  let hasContent = Boolean(item.content || item.content_image_list?.length);
  let isGraph = type == "graph";
  let isContent = type == "content";
  if (!show) return null;

  // 워드 다운로드
  const downloadWord = async (item) => {
    //console.log("item:", item);
    CommonAPI.downloadMarketInfoDocument({ idx: item.idx });
  };

  return (
    <div id="MarketInfoPopupDetail" className="custom_scroll">
      <div className="header">
        <h2>{item.title}</h2>
        <button className="icon_exit ir_txt" onClick={onClose}>
          닫기
        </button>
      </div>
      {isGraph &&
        (item.image_key ? (
          <img
            src={
              item.image_key
                ? window.$Global.getCDN(item.image_key)
                : require("../../assets/images/common/no_image_lg.png").default
            }
            className={`graph`}
            alt={`${item.title} 이미지`}
          />
        ) : (
          ""
        ))}
      {isContent && hasContent && (
        <div className="content">
          {item.content ? (
            <p>{item.content}</p>
          ) : (
            // <>
            // 	<SlickSlider list={preview || []} onClick={() => setViewer(false)} />
            // </>
            <img
              src={window.$Global.getCDN(
                item.content_image_list[0].content_key
              )}
              alt={`${item.title} 이미지`}
            />
          )}
        </div>
      )}
      {item.mi_info?.gpt_summary && (
        <p className="gpt_summary">{item.mi_info?.gpt_summary}</p>
      )}
      <div className="btn">
        <button className="word_btn" onClick={() => downloadWord(item)}>
          WORD 다운로드
        </button>
      </div>
    </div>
  );
}

export default MarketInfoPopupDetail;
