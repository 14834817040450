import React, { useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import "../css/HoldingsSituation.scss";

const HoldingsSituation = () => {
  const [activeTab, setActiveTab] = useState("quarterly");

  const dataQuarterly = {
    labels: [
      "2023년 1분기",
      "2023년 2분기",
      "2023년 3분기",
      "2023년 4분기",
      "2024년 1분기",
      "2025년 2분기",
    ],
    datasets: [
      {
        type: "line",
        label: "투자 건수 (건)",
        data: [10, 20, 14, 30, 35, 25],
        fill: false,
        borderColor: "#605BFF",
        backgroundColor: "#605BFF",
        yAxisID: "y-axis-2",
      },
      {
        type: "bar",
        label: "투자 금액 (억 원)",
        data: [500, 1500, 451.2, 800, 1000, 700],
        backgroundColor: "rgba(191, 189, 255, .5)",
        borderColor: "rgba(191, 189, 255, .5)",
        borderWidth: 1,
        yAxisID: "y-axis-1",
        barThickness: 40,
      },
    ],
  };

  const dataCumulative = {
    labels: [
      "2023년 1분기",
      "2023년 2분기",
      "2023년 3분기",
      "2023년 4분기",
      "2024년 1분기",
      "2025년 2분기",
    ],
    datasets: [
      {
        type: "line",
        label: "투자 건수 (건)",
        data: [10, 30, 44, 74, 109, 134],
        fill: false,
        borderColor: "#605BFF",
        backgroundColor: "#605BFF",
        yAxisID: "y-axis-2",
      },
      {
        type: "bar",
        label: "투자 금액 (억 원)",
        data: [500, 2000, 2451.2, 3251.2, 4251.2, 4951.2],
        backgroundColor: "rgba(191, 189, 255, .5)",
        borderColor: "rgba(191, 189, 255, .5)",
        barThickness: 40,
        yAxisID: "y-axis-1",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          stacked: true,
          ticks: {
            display: true,
          },
          gridLines: {
            display: true,
          },
        },
      ],
      yAxes: [
        {
          id: "y-axis-1",
          type: "linear",
          position: "left",
          ticks: {
            beginAtZero: true,
          },
          scaleLabel: {
            display: true,
            labelString: "투자 금액 (억 원)",
          },
        },
        {
          id: "y-axis-2",
          type: "linear",
          position: "right",
          ticks: {
            beginAtZero: true,
          },
          scaleLabel: {
            display: true,
            labelString: "투자 건수 (건)",
          },
          gridLines: {
            drawOnChartArea: false,
          },
        },
      ],
    },
  };

  return (
    <div id="HoldingsSituation">
      <div className="part">
        <div>
          <strong className="part_tit">투자현황</strong>
        </div>
        <div className="tabs">
          <button
            className={activeTab === "quarterly" && "active"}
            onClick={() => setActiveTab("quarterly")}
          >
            분기별 투자 추이
          </button>
          <button
            className={activeTab === "cumulative" && "active"}
            onClick={() => setActiveTab("cumulative")}
          >
            누적 투자 추이
          </button>
        </div>
        {activeTab === "quarterly" && (
          <div>
            <Line data={dataQuarterly} options={options} height={300} />
          </div>
        )}
        {activeTab === "cumulative" && (
          <div>
            <Line data={dataCumulative} options={options} height={300} />
          </div>
        )}
      </div>
    </div>
  );
};

export default HoldingsSituation;
