import EstimatePopUpRequire from "./EstimatePopUpRequire";
import EstimatePopUpOption from "./EstimatePopUpOption";

import "./css/EstimatePopUp.scss";

const EstimatePopUp = ({ setPopUpShow, type }) => {
  return (
    <div id="EstimatePopUp">
      {type === 0 ? (
        <EstimatePopUpRequire setPopUpShow={setPopUpShow} />
      ) : (
        <EstimatePopUpOption setPopUpShow={setPopUpShow} />
      )}
    </div>
  );
};

export default EstimatePopUp;
