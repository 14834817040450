const DetailPop = ({ item, setDetailPopOpen }) => {
  return (
    <div id="DetailPop">
      <div className="title">
        <strong>{item.title}</strong>
      </div>
      <div className="image">
        <img src={window.$Global.getCDN(item.image_key)} alt={item.title} />
      </div>
      <div className="content">{item.gpt_summary}</div>
      <button className="close" onClick={() => setDetailPopOpen(false)}>
        <i className="icon_exit_gray"></i>
      </button>
    </div>
  );
};

export default DetailPop;
