import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import MemberContext from "../../store/Member";
import axios from "axios";
import config from "../../const/config";

function UserTab({ member, onClickLogout, unEvent }) {
  const history = useHistory();
  const { auth, admin } = useContext(MemberContext);
  const isViewTypeIpnow = window.$Global.isIpnow();
  const isCompany = window.$Global.checkAuth("특허회사", member.auth);
  const isInvestor = window.$Global.checkAuth("투자자", member.auth);
  //const isAdmin = admin; // 슈퍼 계정인지 판별
  const [isAdmin, setIsAdmin] = useState(false);
  const gwmodeExists = window.localStorage.getItem("gwmode");
  const [userName, setUserName] = useState("");
  const [browserWidthSize, setBrowserWidthSize] = useState(0);

  const navigateUserSearch = () => {
    history.push(
      `/groupware/office?section=emp&command=lstEmp&${
        userName ? `name=${userName}` : ""
      }`
    );
  };

  const createDummy = () => {
    return Math.floor(Math.random() * 1000000); // 0에서 999999 사이의 난수 생성 / iframe 리다이렉트 위함
  };

  // useEffect(() => {
  //   let url = config.gwUrl;
  //   axios
  //     .get(url + "/bizoffi-api/member/userInfo.json", {
  //       headers: {
  //         "g-token": localStorage.getItem(config.gwKeyToken),
  //       },
  //     })
  //     .then((res) => {
  //       if (res.data.result == "fail") {
  //         window.$Global.logout("self");
  //       } else {
  //         setIsAdmin(res.data.userInfo.gwAdmin);
  //         //console.log("res.data.userInfo.gwAdmin::", res.data.userInfo.gwAdmin);
  //       }
  //     });
  //
  //   setBrowserWidthSize(window.$Global.getBrowserSize().width);
  // }, []);

  return (
    <div className="user_tab">
      <ul className="header_user_tab_ul">
        {/*<li>*/}
        {/*  {gwmodeExists !== "off" && (*/}
        {/*    <div className="user_search_input">*/}
        {/*      <input*/}
        {/*        type="text"*/}
        {/*        placeholder="조회할 직원의 이름을 입력하세요."*/}
        {/*        value={userName}*/}
        {/*        onChange={(e) => {*/}
        {/*          setUserName(e.target.value);*/}
        {/*        }}*/}
        {/*        onKeyUp={(e) => e.key === "Enter" && navigateUserSearch()}*/}
        {/*      />*/}
        {/*      <i*/}
        {/*        className="icon_search_blue"*/}
        {/*        onClick={() => navigateUserSearch()}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  )}*/}
        {/*</li>*/}
        {!isInvestor && (
          <li>
            <Link to="/unEvent">
              <i
                className={
                  unEvent.unConfirmEventCnt > 0
                    ? "icon_notification_on"
                    : "icon_notification"
                }
              />
              {browserWidthSize > 1580 && (
                <span
                  style={{
                    color:
                      unEvent.unConfirmEventCnt > 0 ? "#4593f5" : "#343434",
                  }}
                >
                  {unEvent.unConfirmEventCnt}건
                </span>
              )}
              {/* </a> */}
            </Link>
          </li>
        )}
        <li style={{ display: gwmodeExists === "off" && "none" }}>
          <Link
            to={"/groupware/boardlist?section=board&command=list&boardno=101"}
          >
            <i
              className="icon_write"
              style={{ width: "18px", height: "18px" }}
            />
            {browserWidthSize > 1580 && <span>게시판</span>}
          </Link>
        </li>
        <li style={{ display: gwmodeExists === "off" && "none" }}>
          <Link
            to={`/groupware/works?section=reservation&command=conferenceReserve&dummy=${createDummy()}`}
          >
            <i
              className="icon_menu_clock_gray"
              style={{ width: "18px", height: "18px" }}
            />
            {browserWidthSize > 1580 && <span>예약 관리</span>}
          </Link>
        </li>
        <li style={{ display: gwmodeExists === "off" && "none" }}>
          <Link to={`/groupware/addressbook?dummy=${createDummy()}`}>
            <i
              className="icon_menu_folder_gray"
              style={{ width: "18px", height: "18px" }}
            />
            {browserWidthSize > 1580 && <span>주소록</span>}
          </Link>
        </li>
        <li style={{ display: gwmodeExists === "off" && "none" }}>
          <Link
            to={"/groupware/adminmenu?section=assignmenu&command=lstMenuAuth"}
          >
            <i
              className="icon_condition"
              style={{ width: "18px", height: "18px" }}
            />
            {browserWidthSize > 1580 && <span>관리자</span>}
          </Link>
        </li>
        <li>
          <Link
            to={
              gwmodeExists !== "off"
                ? "/groupware/mypage?section=empAuth&command=myinfoFrm"
                : "/mypage/info"
            }
          >
            <i
              className="icon_user_outline"
              style={{ width: "18px", height: "18px" }}
            />
            {browserWidthSize > 1580 && <span>마이 페이지</span>}
          </Link>
        </li>
        {isViewTypeIpnow && isCompany && (
          <li>
            <Link to="/approval/list">결재</Link>
          </li>
        )}
        <li onClick={onClickLogout}>로그아웃</li>
      </ul>
    </div>
  );
}

export default React.memo(UserTab);
