import React, {useContext, useEffect, useState} from "react";
import "./css/PopupGrade.scss";
import GradeCompany from "../grade/GradeCompany";
import InvestorAPI from "../../API/investor";
import PatentAPI from "../../API/patent";
import MemberContext from "../../store/Member";
import _ from "lodash";
import {useHistory} from "react-router-dom";

function PopupGrade({companyName, companyCode, onClickClose, all}) {
    const history = useHistory();
    const {auth} = useContext(MemberContext);
    const [resData, setResData] = useState({patent: []});
    const [gradeData, setGradeData] = useState({});
    const isCompany = window.$Global.checkAuth("특허회사", auth);
    const isInvestor = window.$Global.checkAuth("투자자", auth);

    useEffect(async () => {
        if(auth === undefined){
            alert('로그인 이후에 사용할 수 있는 기능입니다.');
            history.push('/login');
        }

        let data = _.cloneDeep(resData);

        if (isCompany) {
            data = await PatentAPI.getGradePatent(companyCode, all).then(res => res.data);
        } else if (isInvestor) {
            data = await InvestorAPI.getCompanyGrade(companyCode).then(res => res.data);
        }

        setResData(data);
        setGradeData({
            quality: data.quality,
            quantity: data.quantity,
            total: data.total
        });
    }, []);

    const onClickDownload = (idx) => {
        if (isCompany) {
            PatentAPI.downloadRegisterFile(idx);
        } else if (isInvestor) {
            InvestorAPI.downloadRegisterFile(idx);
        }
    };

    let tbody = window.$Global.notTd(6, '등록된 특허가 없습니다.');

    if (resData.patent.length) {
        tbody =
            resData.patent.map((item, idx) => {
                return (
                    <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td>{item.nation}</td>
                        <td>{item.register_number || 'N/A'}</td>
                        <td>
                            <div className={`circle grade_${item.sm_grade || 'default'}`}>{item.sm_grade || 'N'}</div>
                        </td>
                        <td>{item.invention_title || item.invention_title_en || 'N/A'}</td>
                        <td>{window.$Global.convertDate(item.apply_at)}</td>
                        <td>
                            <button className={`icon_download ${item.register_file_cnt == 0 ? 'disable' : ''}`} onClick={() => onClickDownload(item.idx)}/>
                        </td>
                    </tr>
                )
            });
    }

    if (window.$Global.isEmptyObject(gradeData)) return null;

    return (
        <div id="PopupGrade" className="custom_scroll">
            <div className="header">
                <h2>{companyName} 기술역량 등급</h2>
                <button className="icon_exit" onClick={onClickClose}/>
            </div>
            <GradeCompany data={gradeData}/>

            <div className="wrap_table">
                <h2>{companyName} 등록특허 등급</h2>
                <div className="table-box-wrap">
                    <div className="wrap_list" id="scrollableDiv">
                        <table className="list">
                            <thead>
                                <tr>
                                    <th>순번</th>
                                    <th>국가</th>
                                    <th>등록 번호</th>
                                    <th>SMART3<br/>등급</th>
                                    <th>발명의 명칭</th>
                                    <th>출원일자</th>
                                    <th>특허 다운</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tbody}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="alert_msg">
                <i className="icon_badge_info"/>
                <p>등록된 특허만 등급을 주므로, 미등록된 특허는 리스트에서 제외되었습니다.</p>
            </div>
        </div>
    )
}

export default PopupGrade;
