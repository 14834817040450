export default {
    refValidator(arr) {
        for (let i = 0; i < arr.length; i++) {
            if (!arr[i].current.value.length) {
                alert(`${arr[i].current.dataset.name}을(를) 입력해주세요`);
                arr[i].current.focus();
                return false;
            }

            if (arr[i].current.dataset.name === "이메일") {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!re.test(String(arr[i].current.value).toLowerCase())) {
                    alert("이메일 형식이 올바르지 않습니다");
                    return false;
                }
            }
        }

        return true;
    }
};
