import "./scss/TopBanner.scss";

const TopBanner = () => {
  return (
    <div id="TopBanner" className="business">
      <div className="top_banner">
        <div className="wrap">
          <strong>사업계획</strong>
          {/* <p>귀사의 RUNWAY를 쉽고 빠르게 계산해 보세요</p> */}
        </div>
      </div>
    </div>
  );
};

export default TopBanner;
