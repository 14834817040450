import "./css/AnnualFeeReviewSecond.scss";
import React, { useEffect, useState } from "react";
import AnnualPay from "../../API/annualPay";
import Admin from "../../API/admin";
import AdminAPI from "../../API/admin";

const AnnualFeeReviewSecond = ({
  annualFeeData,
  setShowPopup,
  showPatent,
  isAdmin,
}) => {
  const [data, setData] = useState([]);
  const [sendData, setSendData] = useState({
    memo: "",
    file: [],
  });
  const [fileName, setFileName] = useState([]);

  useEffect(() => {
    isAdmin
      ? showPatent
        ? getAdminPatentPayDetail()
        : getAdminTradeMarkPayDetail()
      : showPatent
      ? getCompanyPatentPayDetail()
      : getCompanyTradeMarkPayDetail();
  }, []);

  const getCompanyPatentPayDetail = () => {
    AnnualPay.getPatentPayDetail(
      annualFeeData.patent_idx,
      annualFeeData.annual_pay_idx
    ).then((res) => {
      console.log(
        res.data.comment.find((item) => item.my_comment === true).name
      );
      setData(res.data);
      setSendData({
        memo: res.data.memo,
        file: res.data.file,
      });
    });
  };

  const getCompanyTradeMarkPayDetail = () => {
    AnnualPay.getTrademarkPayDetail(
      annualFeeData.trademark_idx,
      annualFeeData.annual_pay_idx
    ).then((res) => {
      setData(res.data);
      setSendData({
        memo: res.data.memo,
        file: res.data.file,
      });
    });
  };

  const getAdminPatentPayDetail = () => {
    AdminAPI.getAdminPatentAnnualPay(
      annualFeeData.patent_idx,
      annualFeeData.annual_pay_idx
    ).then((res) => {
      console.log(res.data);
      setData(res.data);
      setSendData({
        memo: res.data.memo,
        file: res.data.file,
      });
    });
  };

  const getAdminTradeMarkPayDetail = () => {
    AdminAPI.getAdminTradeMarkAnnualPay(
      annualFeeData.patent_idx,
      annualFeeData.annual_pay_idx
    ).then((res) => {
      setData(res.data);
      setSendData({
        memo: res.data.memo,
        file: res.data.file,
      });
    });
  };

  const handleChange = (e) => {
    if (e.currentTarget.name === "memo") {
      setSendData({
        ...sendData,
        memo: e.currentTarget.value,
      });
    } else if (e.currentTarget.name === "file") {
      setFileName([]);
      const fileArray = Array.from(e.currentTarget.files);
      fileArray.map((el) => {
        sendData.file.push(el);
        setFileName((prevState) => [...prevState, el.name]);
      });
    }
  };

  const handleClick = (e) => {
    const formData = new FormData();
    const comment_idx = data.comment.find(
      (item) => item.my_comment === true
    ).idx;
    formData.append("memo", sendData.memo);
    sendData.file.map((el) => {
      formData.append("file", el);
    });
    showPatent
      ? AnnualPay.uploadPatentPayData(
          annualFeeData.patent_idx,
          annualFeeData.annual_pay_idx,
          formData
        )
          .then(() =>
            AnnualPay.updateComment(
              annualFeeData.patent_idx,
              annualFeeData.annual_pay_idx,
              comment_idx,
              { comment: sendData.memo }
            )
          )
          .then(() => {
            alert("등록되었습니다.");
            sendData.file = [];
            window.location.reload();
          })
      : AnnualPay.uploadTradeMarkPayData(
          annualFeeData.trademark_idx,
          annualFeeData.annual_pay_idx,
          formData
        ).then(() => {
          alert("등록되었습니다.");
          sendData.file = [];
          window.location.reload();
        });
  };

  const deleteFile = (lastModified) => {
    const fileArray = sendData?.file?.filter(
      (el) => el.lastModified !== lastModified
    );

    setSendData({
      ...sendData,
      file: fileArray,
    });
  };

  const downloadFile = (file_idx) => {
    window.$Global.fileDownloadSync(
      isAdmin
        ? showPatent
          ? `admin/company/${annualFeeData.patent_idx}/patent/annualPay/${annualFeeData.annual_pay_idx}/file/${file_idx}/download`
          : `admin/company/${annualFeeData.trademark_idx}/trademark/annualPay/${annualFeeData.annual_pay_idx}/file/${file_idx}/download`
        : showPatent
        ? `/manager/patent/${annualFeeData.patent_idx}/annualPay/${annualFeeData.annual_pay_idx}/billing/file/${file_idx}/download`
        : `/manager/patent/${annualFeeData.trademark_idx}/annualPay/${annualFeeData.annual_pay_idx}/billing/file/${file_idx}/download`
    );
  };

  return (
    <div id="AnnualFeeReviewSecond" className="custom_scroll">
      <div className="annual_fee_review_header">
        <div>
          <h2>연차료 납부 검토</h2>
          <i
            className="icon_exit_gray"
            onClick={() =>
              setShowPopup({
                first: false,
                second: false,
              })
            }
          />
        </div>
        <p>
          <i className="icon_badge_info_gray" /> 연도를 선택해 각 연도별
          연차료에 대해 검토가 가능합니다.
        </p>
      </div>
      <div className="annual_fee_review_body">
        <div className="review_manage_number">
          <p>관리번호</p>
          <p>{annualFeeData.manage_number}</p>
        </div>
        <div
          className="review_manage_number"
          style={{ paddingBottom: "20.5px" }}
        >
          <p>특허연차</p>
          <p>{data.payment_year}년차</p>
          <button
            onClick={() => {
              isAdmin
                ? setShowPopup({
                    first: false,
                    second: false,
                  })
                : setShowPopup({
                    first: true,
                    second: false,
                  });
            }}
          >
            목록으로 돌아가기
          </button>
        </div>
        <div className="review_manage_number">
          <p>회사명</p>
          <p>{data.comment?.find((item) => item.my_comment === true).name}</p>
        </div>
        <textarea
          placeholder="입력하세요."
          name="memo"
          value={sendData.memo}
          onChange={handleChange}
        />
        <div
          className="review_manage_number review_upload_file"
          style={{ borderBottom: "none" }}
        >
          <p>파일선택</p>
          {sendData.file.length === 0 ? (
            <div className="file_input">
              <label htmlFor="file">첨부파일 등록하기</label>
              <input
                type="file"
                name="file"
                id="file"
                multiple
                onChange={handleChange}
              />
            </div>
          ) : (
            <div className="file_list_items">
              {sendData?.file?.map((el) => {
                return (
                  <div className="file_list_item">
                    <p
                      onClick={() =>
                        el.file_key.length > 0 && downloadFile(el.idx)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {el.name || el.file_name}
                    </p>
                    <i
                      className="icon_badge_del"
                      onClick={() => deleteFile(el.lastModified)}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className="review_manage_comment">
          <p className="title">공동 출원인</p>
          <p className="commenter">
            {data?.comment?.length === 0 ? (
              <span>없음</span>
            ) : (
              data?.comment?.map((el) => {
                return <span>{el.name}</span>;
              })
            )}
          </p>
        </div>
        {data.comment
          ?.filter((item) => item.my_comment === false)
          .map((item) => {
            return (
              <>
                <div className="review_manage_number">
                  <p>회사명</p>
                  <p>{item.name}</p>
                </div>
                <textarea
                  placeholder="입력하세요."
                  name="memo"
                  value={item.comment}
                  onChange={handleChange}
                  readOnly={true}
                />
              </>
            );
          })}
      </div>
      <div className="review_manage_footer">
        {!isAdmin && <button onClick={handleClick}>저&emsp;장</button>}
      </div>
    </div>
  );
};

export default AnnualFeeReviewSecond;
