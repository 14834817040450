import { Link } from "react-router-dom";
import "./LoginPop.scss";

const LoginPop = ({ isVisible, handleClose }) => {

  if (!isVisible) {
    return null;
  }

  return(
    <div className="loginPop">
      <div className="close"><i className="icon_exit" onClick={handleClose}></i></div>
      <p>
        <strong>BIZNAVI</strong>에 가입하시면 모든 정부과제 정보를<br />
        무료로 확인할 수 있습니다.
      </p>
        <div className="btn">
          <Link to="/signup/type" className="signup">
            회원가입 하기
          </Link>
        </div>
        <div className="btn">
          <Link to="/login" className="login">
            로그인 하기
          </Link>
        </div>
    </div>
  )
}

export default LoginPop;