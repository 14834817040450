import { useHistory, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import MemberContext from "../../../store/Member";
import "./css/AdminAnnualFeeList.scss";
import AdminAPI from "../../../API/admin";
import Pagination from "../../common/Pagination";
import introTaskCategoryTemp from "../../intro/organisms/IntroTaskCategoryTemp";
import AnnualFeeReview from "../AnnualFeeReview";
import AnnualFeeReviewSecond from "../AnnualFeeReviewSecond";
import AnnualFeeReceipt from "../AnnualFeeReceipt";
import PaymentState from "../PaymentState";
import AnnualFeeBilling from "../AnnualFeeBilling";

const AdminAnnualFeeList = ({ location }) => {
  const history = useHistory();
  const { company_idx } = useParams();
  const { company_name } = useContext(MemberContext);
  const [patentTag, setPatentTag] = useState([]);
  const [tradeMarkTag, setTradeMarkTag] = useState([]);
  const [showPatent, setShowPatent] = useState(true);
  const [curPage, setCurPage] = useState(1);
  const [last, setLast] = useState(1);
  const [paymentYear, setPaymentYear] = useState("");
  const [showPopup, setShowPopup] = useState({
    first: false,
    second: false,
    receipt: false,
    billing: false,
  });
  const [annualFeeData, setAnnualFeeData] = useState({
    manage_number: "",
    annual_pay_idx: "",
    patent_idx: "",
    trademark_idx: "",
    billing_idx: null,
  });

  useEffect(() => {
    showPatent ? getPatentList() : getTradeMarkList();
  }, [showPatent]);

  const AnnualPayReceipt = ({ item }) => {
    return item.billing_idx !== null ? (
      <div className="annual_receipt">
        <p>비용청구</p>
        <button
          onClick={(e) => {
            e.stopPropagation();
            setAnnualFeeData({
              annual_pay_idx: item.idx,
              ...(showPatent
                ? { patent_idx: company_idx }
                : { trademark_idx: company_idx }),
            });
            setShowPopup({ ...showPopup, receipt: true });
          }}
        >
          영수증확인하기
        </button>
      </div>
    ) : (
      <p>-</p>
    );
  };

  const getPatentList = () => {
    AdminAPI.getAdminCompanyPatentList(company_idx).then((res) => {
      setLast(res.data.last);
      drawTable(res.data.items);
    });
  };

  const getTradeMarkList = () => {
    AdminAPI.getAdminCompanyTradeMarkList(company_idx).then((res) => {
      setLast(res.data.last);
      drawTable(res.data.items);
    });
  };

  const drawTable = (data) => {
    const patentArr = data?.map((el, idx) => {
      const nation = window.$Global.convertNationCodeToStr(el.nation);
      return (
        <tr onClick={() => history.push("/admin/annual/inventor/list")}>
          <td>{idx + 1}</td>
          <td>{nation}</td>
          <td>{el.apply_number}</td>
          <td>{window.$Global.convertDate(el.apply_at)}</td>
          <td>{el.register_number || "미등록"}</td>
          <td>{window.$Global.convertDate(el.register_at) || "미등록"}</td>
          <td>{window.$Global.convertDate(el.annual_pay_deadline)}</td>
          <td>
            {el.cost ? window.$Global.numberWithCommas(el.cost) + "원" : ""}
          </td>
          <td>
            <button
              className="button_review"
              onClick={(e) => {
                e.stopPropagation();
                setShowPopup({
                  ...showPopup,
                  second: true,
                });
                setAnnualFeeData({
                  manage_number: el.manage_number,
                  annual_pay_idx: el.idx,
                  patent_idx: company_idx,
                  trademark_idx: company_idx,
                });
              }}
            >
              검토하기
            </button>
          </td>
          <td>
            <PaymentState state={el.payment_state} />
          </td>
          <td>
            <AnnualPayReceipt item={el} />
          </td>
          <td className="billing_button">
            <button
              onClick={(e) => {
                e.stopPropagation();
                setShowPopup({
                  ...showPopup,
                  billing: true,
                });
                setAnnualFeeData({
                  annual_pay_idx: el.idx,
                  patent_idx: company_idx,
                  trademark_idx: company_idx,
                  billing_idx: el.billing_idx,
                });
              }}
            >
              {el.billing_idx === null ? "청구서 등록" : "청구서 수정"}
            </button>
          </td>
        </tr>
      );
    });
    setPatentTag(patentArr);
  };

  return (
    <div id="AdminAnnualFeeList">
      <div className="list_header">
        <p>“{company_name}”의 연차료 리스트</p>
        <button onClick={() => history.push("/admin/annual")}>
          목록으로 돌아가기
        </button>
      </div>
      <div className="annual_change_view">
        <p
          className="table_title"
          style={{ borderBottom: showPatent && "3px solid #4593f5" }}
          onClick={() => setShowPatent(true)}
        >
          특&nbsp; 허
        </p>
        <p
          className="table_title"
          style={{ borderBottom: !showPatent && "3px solid #4593f5" }}
          onClick={() => setShowPatent(false)}
        >
          상&nbsp; 표
        </p>
      </div>
      <div className="annual_list_table">
        <table>
          <colgroup>
            <col width={60} />
            <col width={70} />
            <col width={110} />
            <col width={110} />
            <col width={110} />
            <col width={110} />
            <col width={90} />
            <col width={90} />
            <col width={70} />
            <col width={120} />
            <col width={90} />
            <col width={100} />
          </colgroup>
          <thead>
            <tr>
              <th>건 수</th>
              <th>국가</th>
              <th>출원번호</th>
              <th>출원일</th>
              <th>등록번호</th>
              <th>등록일</th>
              <th>납부기한</th>
              <th>금액</th>
              <th>
                검토
                <i className="icon_badge_info_gray" />
              </th>
              <th>연차료 포기/납부</th>
              <th>
                비용청구
                <i className="icon_badge_info_gray" />
              </th>
              <th>청구서 등록/수정</th>
            </tr>
          </thead>
          <tbody>{patentTag}</tbody>
        </table>
      </div>
      <Pagination curPage={curPage} lastNum={last} onClick={setCurPage} />
      {showPopup.first && (
        <AnnualFeeReview
          annualFeeData={annualFeeData}
          setShowPopup={setShowPopup}
          showPatent={showPatent}
          isAdmin={true}
        />
      )}
      {showPopup.second && (
        <AnnualFeeReviewSecond
          setShowPopup={setShowPopup}
          annualFeeData={annualFeeData}
          showPatent={showPatent}
          isAdmin={true}
        />
      )}
      {showPopup.receipt && (
        <AnnualFeeReceipt
          setShowPopup={setShowPopup}
          annualFeeData={annualFeeData}
          showPatent={showPatent}
          isAdmin={true}
        />
      )}
      {showPopup.billing && (
        <AnnualFeeBilling
          setShowPopup={setShowPopup}
          annualFeeData={annualFeeData}
          showPatent={showPatent}
          isAdmin={true}
        />
      )}
    </div>
  );
};

export default AdminAnnualFeeList;
