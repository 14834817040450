import React, {useContext} from "react";
import PatentContext from "../../../store/Patent";
import DateAndFileRow from "./common/DateAndFileRow";
import InnerDatePickerAndFileRow from "./common/InnerDatePickerAndFileRow";
import NormalRow from "./common/NormalRow";
import ToggleButtonWithSpan from "../../common/ToggleButtonWithSpan";
import InputRow from "./common/InputRow";
import typeName from "./constrant/typeName";
import {Link} from "react-router-dom";
import DatePickerRow from "./common/DatePickerRow";
import InputAndFileRow from "./common/InputAndFileRow";

function FormAgentPct({ListOaTag, managerSelectTag, inventorListTag,
                       onClickDownPopShow, onClickUpPopShow, onChangeInput, onChangeDatePicker}) {
    const patentInfo = useContext(PatentContext);

    return (
        <div className="form">
            <div className="field">
                <div className="rows">
                    <InputRow title="대리인 관리번호" titleClass="color_blue" condition={patentInfo.editing} content={patentInfo.agent_manage_number} onChange={onChangeInput} setKey="agent_manage_number"/>
                </div>
                <div className="rows">
                    <NormalRow title="관리번호" content={patentInfo.manage_number}/>
                    <NormalRow title="유형" content={patentInfo.type}/>
                    <div className="row">
                        <h2 className="title">Family 출원<Link className="icon_plus" to={`/patent/family/${patentInfo.idx}`}/></h2>
                        <p>{patentInfo.family_cnt}건</p>
                    </div>
                </div>
                <div className="rows">
                    <NormalRow title="담당자" content={patentInfo.manager_name}/>
                    <NormalRow title="발명자 1" content={patentInfo.inventor.length && patentInfo.inventor[0].name}/>
                    <div className="row">
                        <h2 className="title">대리인 담당자</h2>
                        {
                            patentInfo.editing
                                ?
                                <select defaultValue={patentInfo.agent_idx || 0} onChange={(e) => patentInfo.setPatent({...patentInfo, agent_idx: e.target.value, agent_name: e.target.options[e.target.selectedIndex].text})}>
                                    <option value={0} disabled hidden>선택</option>
                                    {managerSelectTag}
                                </select>
                                : <p>{patentInfo.agent_name ? patentInfo.agent_name : 'N/A'}</p>
                        }
                    </div>
                </div>
                {inventorListTag}
            </div>
            {patentInfo.mergeTag}
            <div className="field">
                {
                    <>
                        <div className="rows">
                            <NormalRow title='초안작성 지시 접수일' content={patentInfo.draft_order_at} txtType='date'/>
                            <InnerDatePickerAndFileRow title='초안 전달' data={patentInfo.draftReceivingDate}
                                                       dateBtnHide={true}
                                                       uploadBtnShow={true}
                                                       haveFile={Boolean(patentInfo.draft_file_cnt)}
                                                       onClickDownPopShow={() => onClickDownPopShow(typeName.agent_draft, {modified: 0})}
                                                       onClickUpPopShow={() => onClickUpPopShow(typeName.agent_draft, {modified: 0})}
                            />
                            <DateAndFileRow title={`초안 수정본 접수${patentInfo.draftModifiedUploadDate.date !== null ? '일' : ''}`}
                                            date={patentInfo.draftModifiedUploadDate.date}
                                            uploadBtnShow={false}
                                            onClickDownPopShow={() => onClickDownPopShow(typeName.agent_draft, {modified: 1})}
                                            onClickUpPopShow={() => onClickUpPopShow(typeName.agent_draft, {modified: 1})}
                                            haveFile={Boolean(patentInfo.modified_draft_file_cnt)}
                            />
                        </div>
                        <div className="rows">
                            <div className="row no_data"/>
                            <div className="row no_data"/>
                            <InnerDatePickerAndFileRow title={'초안\n최종본 전달'} data={patentInfo.draftFinalDate}
                                                       dateBtnHide={true}
                                                       uploadBtnShow={true}
                                                       haveFile={Boolean(patentInfo.final_draft_file_cnt)}
                                                       onClickDownPopShow={() => onClickDownPopShow(typeName.agent_draft, {modified: 2})}
                                                       onClickUpPopShow={() => onClickUpPopShow(typeName.agent_draft, {modified: 2})}
                            />
                        </div>
                        <div className="rows">
                            <div className="row no_data"/>
                            <div className="row no_data"/>
                            <NormalRow title='출원 지시 접수일' titleClassName='color_blue' content={patentInfo.apply_order_at} txtType="date"/>
                        </div>
                    </>
                }
            </div>
            <div className="field">
                <div className="rows">
                    <div className="row">
                        <h2 className="title">
                            번역문 {patentInfo.translation ? "ON" : "OFF"}
                            {
                                patentInfo.editing
                                ? <ToggleButtonWithSpan text="" condition={{on: '', off: ''}} selected={patentInfo.translation} toggleSelected={() => patentInfo.setPatent({...patentInfo, translation: !patentInfo.translation})}/>
                                : null
                            }
                        </h2>
                    </div>
                    <div className="row" style={{width: patentInfo.translation ? "67%" : 0, margin: 0}}/>
                </div>
                {
                    Boolean(patentInfo.translation) &&
                    <>
                        <div className="rows">
                            <NormalRow title={'번역문\n초안작성 지시일'} content={patentInfo.draft_translation_order_at} txtType='date'/>
                            <InnerDatePickerAndFileRow title={'번역문\n초안 전달'} data={patentInfo.translateDraftReceivingDate}
                                                       haveFile={Boolean(patentInfo.translation_draft_file_cnt)}
                                                       dateBtnHide={true}
                                                       uploadBtnShow={true}
                                                       onClickDownPopShow={() => onClickDownPopShow(typeName.agent_draft_translation, {modified: 0})}
                                                       onClickUpPopShow={() => onClickUpPopShow(typeName.agent_draft_translation, {modified: 0})}/>
                            <DateAndFileRow title={`번역문\n초안 수정본 접수${patentInfo.draft_translation_modified_upload_at !== null ? '일' : ''}`}
                                            date={patentInfo.draft_translation_modified_upload_at}
                                            haveFile={Boolean(patentInfo.translation_modified_draft_file_cnt)}
                                            uploadBtnShow={false}
                                            onClickDownPopShow={() => onClickDownPopShow(typeName.agent_draft_translation, {modified: 1})}
                            />
                        </div>
                        <div className="rows">
                            <InnerDatePickerAndFileRow title={'번역문 초안\n최종본 전달'} data={patentInfo.translateDraftFinalDate}
                                                       haveFile={Boolean(patentInfo.translation_final_draft_file_cnt)}
                                                       dateBtnHide={true}
                                                       uploadBtnShow={true}
                                                       onClickDownPopShow={() => onClickDownPopShow(typeName.agent_draft_translation, {modified: 2})}
                                                       onClickUpPopShow={() => onClickUpPopShow(typeName.agent_draft_translation, {modified: 2})}
                            />
                            <div className="row no_data"/>
                            <NormalRow title='번역문 기한' titleClassName='color_blue' content={patentInfo.translation_deadline}/>
                        </div>
                    </>
                }
            </div>
            <div className="field">
                <div className="rows">
                    <InputAndFileRow title="출원번호"
                                     titleClassName="color_blue"
                                     content={patentInfo.apply_number}
                                     editing={patentInfo.editing}
                                     haveFile={Boolean(patentInfo.apply_file_cnt)}
                                     onChange={(e) => onChangeInput("apply_number", e.target.value)}
                                     onClickDownPopShow={() => onClickDownPopShow(typeName.agent_apply)}
                                     onClickUpPopShow={() => onClickUpPopShow(typeName.agent_apply)}
                    />
                    <DatePickerRow title='출원일' condition={patentInfo.editing} data={patentInfo.apply_at} dataKey="apply_at" useOaFunc={false} onChangeDatePicker={onChangeDatePicker}/>
                    <NormalRow title="우선일" content={patentInfo.priority_at} txtType='date'/>
                </div>
                <div className="rows">
                    <InputRow rowClassName='colspan2' title="발명의 명칭" condition={patentInfo.editing} content={patentInfo.invention_name}
                              inputClassName="long" onChange={onChangeInput} setKey="invention_name"/>
                </div>
                <div className="rows">
                    <InputRow rowClassName='colspan2' title="영문 명칭" condition={patentInfo.editing} content={patentInfo.invention_name_en}
                              inputClassName="long" onChange={onChangeInput} setKey="invention_name_en"/>
                </div>
            </div>
            <div className="field">
                <div className="rows">
                    <DateAndFileRow title={`국제조사보고서`}
                                    titleClassName="color_blue"
                                    date={patentInfo.draftReceivingDate.date}
                                    haveFile={patentInfo.international_file_cnt}
                                    onClickDownPopShow={() => onClickDownPopShow(typeName.agent_report)}
                                    onClickUpPopShow={() => onClickUpPopShow(typeName.agent_report)}
                                    uploadBtnShow={true}/>
                    <NormalRow title="국내단계 진입기한" content={patentInfo.domestic_level_deadline} txtType='date'/>
                </div>
            </div>
            {
                ListOaTag
            }
        </div>
    );
}

export default FormAgentPct;
