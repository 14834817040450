/*
 * 구독 멤버쉽 패키지 컴포넌트
 * */

import "./css/RadioLabel.scss";
import Badge from "./Badge";

const RadioLabel = ({ item, onChange }) => {
  const { title, subTitle, name, id, badgeType, isChecked } = item;

  return (
    <label htmlFor={id} className={`radio_label ${isChecked ? "checked" : ""}`}>
      <h2>
        {title}
        <Badge badgeType={badgeType} />
      </h2>
      <h5>{subTitle}</h5>
      <input
        type="radio"
        name={name}
        id={id}
        checked={isChecked}
        onChange={onChange}
      />
    </label>
  );
};

export default RadioLabel;
