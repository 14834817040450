import "./css/InventorStepFour.scss";
import { useEffect, useState } from "react";

const InventorStepFour = ({ history }) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <div id="InventorStepFour">
      <div className="inventor_info_box_title">
        <h5>내가 선택 한 리스트 최종 확인하기</h5>
        <p>
          <i className="icon_badge_info_gray" />
          내가 선택했던 리스트와 발명자 정보를 최종으로 확인 후 등록 완료해
          주세요.
        </p>
        <button className="edit_btn" onClick={() => setIsEditing(!isEditing)}>
          수정
        </button>
      </div>
      <div className="input_box">
        <div>
          <p>발명자 이름</p>
          {isEditing ? <input type="text" /> : <p>김고은</p>}
        </div>
        <div>
          <p>전화번호</p>
          {isEditing ? <input type="text" /> : <p>01012345678</p>}
        </div>
        <div>
          <p>이메일</p>
          {isEditing ? <input type="text" /> : <p>a1231@gooditl.com</p>}
        </div>
        <div>
          <p>주 소</p>
          {isEditing ? (
            <input type="text" />
          ) : (
            <p>
              (우 46285)부산광역시 금정구 부산대학로 50번길 68, 피엔유 에이백
              2층 비7호 (장전동)
            </p>
          )}
        </div>
      </div>
      <div className="patent_list">
        <h5>내가 선택한 특허 리스트</h5>
        <table>
          <colgroup>
            <col width="60px" />
            <col width="100px" />
            <col width="200px" />
            <col width="200px" />
            <col width="300px" />
          </colgroup>
          <thead>
            <th>No.</th>
            <th>구분</th>
            <th>소속기관명</th>
            <th>특허번호</th>
            <th>발명의 명칭</th>
            <th>등급</th>
            <th>등록일</th>
            <th>삭제</th>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>대학</td>
              <td>부산과학기술대학교 [본교]</td>
              <td>ITL14-0060-KR_우_D1</td>
              <td>D2D 통신을 위한 동기화 신호 구성 방법 및 장치</td>
              <td>SS</td>
              <td>2020-11-12</td>
              <td>
                <i className="icon_badge_del" />
              </td>
            </tr>
            <tr>
              <td>1</td>
              <td>대학</td>
              <td>부산과학기술대학교 [본교]</td>
              <td>ITL14-0060-KR_우_D1</td>
              <td>D2D 통신을 위한 동기화 신호 구성 방법 및 장치</td>
              <td>SS</td>
              <td>2020-11-12</td>
              <td>
                <i className="icon_badge_del" />
              </td>
            </tr>
            <tr>
              <td>1</td>
              <td>대학</td>
              <td>부산과학기술대학교 [본교]</td>
              <td>ITL14-0060-KR_우_D1</td>
              <td>D2D 통신을 위한 동기화 신호 구성 방법 및 장치</td>
              <td>SS</td>
              <td>2020-11-12</td>
              <td>
                <i className="icon_badge_del" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <button onClick={() => history.push("/inventor/done")}>완&emsp;료</button>
    </div>
  );
};

export default InventorStepFour;
