import React, { useState, useContext } from "react";
import "./css/CustomerService.scss";
import _ from "lodash";
import CommunityContainer from "../costManage/container/CommunityContainer";
import CheckBox from "../common/CheckBox";
import IntroAPI from "../../API/intro";
import phone from "../../assets/images/intro/icon-phone.png";
import emoji from "../../assets/images/intro/icon-smile.png";
import CommonContext from "../../store/Common";

function CustomerService({ history }) {
  const { isMobile } = useContext(CommonContext);
  const [agree, setAgree] = useState({
    essential: false,
    choice: false,
  });
  const isViewTypeIpnow = window.$Global.isIpnow();
  const [questions, setQuestions] = useState({
    name: "",
    company_name: "",
    phone: "",
    email: "",
    content: "",
    industry: "",
  });

  let str = isViewTypeIpnow ? "IPNOW" : "BIZNAVI";
  const onChange = (key, e) => {
    let copy = _.cloneDeep(questions);
    copy[key] = e.target.value;
    setQuestions(copy);
  };

  const onClickSend = () => {
    if (!agree.essential) {
      alert("개인정보 수집에 동의해주세요");
      return;
    }

    let keys = Object.keys(questions);

    for (let i in keys) {
      if (questions[keys[i]].length == 0) {
        alert("모든 정보를 입력해주세요");
        return;
      }
    }

    let payload = {
      ...questions,
      biznavi_benefit_notification_agree: Number(agree.choice),
    };

    if (window.confirm("접수하시겠습니까?")) {
      IntroAPI.sendSupport(payload).then(() => {
        alert("문의사항이 접수되었습니다\n빠른 시일내로 답변드리겠습니다");
        history.go(0);
      });
    }
  };

  return (
    <div id="login_inquiry" className={isMobile ? "mo" : ""}>
      <div className="login_inner">
        <div className="login_box contact">
          <div className="title">
            <h3>
              <img src={phone} alt="문의하기" />
              문의하기 <span>월~금 09:00~18:00 주말 및 공휴일 휴무</span>
            </h3>
          </div>
          <ul>
            <li>
              <span>Tel.</span>1551-8442
            </li>
            <li>
              <span>E-Mail.</span>ipnow@ipnow.co.kr
            </li>
          </ul>
        </div>
        <div className="login_box">
          <div className="title">
            <h3>
              <img src={emoji} alt="문의하기" />
              도입문의
            </h3>
            <p>
              문의 남겨주시면 <br />
              비즈내비에서 빠르게 연락드리도록 하겠습니다.
            </p>
          </div>
          <div className="box_inner">
            <ul className="input_form">
              <li>
                <label htmlFor="" className="required">
                  회사명
                </label>
                <div className="input">
                  <input
                    type="text"
                    placeholder="입력하세요."
                    onChange={(e) => onChange("company_name", e)}
                  />
                </div>
              </li>
              <li>
                <label htmlFor="" className="required">
                  업종
                </label>
                <div className="input">
                  <input
                    type="text"
                    placeholder="입력하세요."
                    onChange={(e) => onChange("industry", e)}
                  />
                </div>
              </li>
              <li>
                <label htmlFor="" className="required">
                  이름
                </label>
                <div className="input">
                  <input
                    type="text"
                    placeholder="입력하세요."
                    onChange={(e) => onChange("name", e)}
                  />
                </div>
              </li>
              <li>
                <label htmlFor="" className="required">
                  전화번호
                </label>
                <div className="input">
                  <input
                    type="text"
                    placeholder="전화번호 (-)제외."
                    onChange={(e) => onChange("phone", e)}
                  />
                </div>
              </li>
              <li>
                <label htmlFor="" className="required">
                  이메일
                </label>
                <div className="input">
                  <input
                    type="text"
                    placeholder="이메일"
                    onChange={(e) => onChange("email", e)}
                  />
                </div>
              </li>
              <li>
                <label htmlFor="" className="required">
                  문의 내용
                </label>
                <div className="textarea">
                  <textarea
                    placeholder="OOOOO 입력하세요"
                    onChange={(e) => onChange("content", e)}
                  ></textarea>
                </div>
              </li>
            </ul>
            <ul className="agree">
              <li>
                <label>
                  <input
                    type="checkbox"
                    checked={agree.essential}
                    onChange={() => {
                      agree.essential = !agree.essential;
                      setAgree({ ...agree });
                    }}
                  />
                  <i
                    className={
                      agree.essential
                        ? "icon_checkbox_blue"
                        : "icon_checkbox_gray"
                    }
                  />
                  <strong className="red">[필수]</strong>
                  <span>개인정보처리방침</span>을 확인하였고, 이에 동의합니다.
                </label>
              </li>
              <li>
                <label>
                  <input
                    type="checkbox"
                    checked={agree.choice}
                    onChange={() => {
                      agree.choice = !agree.choice;
                      setAgree({ ...agree });
                    }}
                  />
                  <i
                    className={
                      agree.choice ? "icon_checkbox_blue" : "icon_checkbox_gray"
                    }
                  />
                  <strong>[선택]</strong> <span>비즈내비 혜택 알림 수신</span>에
                  동의합니다.
                </label>
              </li>
            </ul>
            <div className="btn">
              <button onClick={onClickSend}>도입문의 신청하기</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerService;
