import React, {useContext, useEffect, useState} from 'react';
import InputRow from "./common/InputRow";
import DateAndFileRow from "./common/DateAndFileRow";
import typeName from "./constrant/typeName";
import DatePickerRow from "./common/DatePickerRow";
import PatentContext from "../../../store/Patent";
import InventionContext from "../../../store/Invention";
import FileRow from "../../invention/row/FileRow";
import DynamicSelectRow from "../../invention/row/DynamicSelectRow";
import API from "../../../util/api";
import DetailPopupUpload from "../../common/DetailPopupUpload";
import DatePickerOaAndFileRow from "./common/DatePickerOaAndFileRow";
import DatePicker from "react-datepicker";
import InnerDatePickerAndFileRow from "./common/InnerDatePickerAndFileRow";
import OaInnerDatePickerAndFileRow from "./common/OaInnerDatePickerAndFileRow";
import InputAndFileRow from "./common/InputAndFileRow";
import SelectRow from "./common/SelectRow";
import ProcessManage from "./common/ProcessManage";
import {nationType} from "../../../types/nationType";

function FormDesignAddKr({agentCompanyListTag, managerSelectTag, checkAuth, setInventionInfo, setPatentInfo,
                           onClickOaRemove, onChangeInput, onChangeDatePicker, onChangeDatePrckerOA}) {
    let inventorTag;
    let patentInfo = useContext(PatentContext);
    patentInfo.editing = true;
    const inventionInfo = useContext(InventionContext);

    const INVENTOR = '발명자';
    const APPLICANTORS = '공동출원인';
    const INVENTIONPUBLISH = '발명의 공개';
    const INVENTION_UPLOAD_FILE_VIEW_LIST = 'inventionUploadIFileViewList';
    const DESCRIPTION_UPLOAD_FILE_VIEW_LIST = 'descriptionUploadIFileViewList';
    const RELATIVE_DATA = 'relativeData';

    const [inventorSelect, setInventorSelect] = useState([]);
    const [fileType, setFileType] = useState('');
    const [upPopupShow, setUpPopupShow] = useState(false);
    const [uploadFileViewList, setUploadFileViewList] = useState([]); // 업로드 파일 리스트
    const [patentFileUpPopupShow, setPatentFileUpPopupShow] = useState(false);
    const [uploadPatentFileViewList, setUploadPatentFileViewList] = useState([]); // 업로드 파일 리스트

    const [inventionUploadIFileViewList, setInventionUploadFileViewList] = useState([]); // 직무 발명 신고서 업로드 파일 리스트
    const [descriptionUploadIFileViewList, setDescriptionInventionUploadFileViewList] = useState([]); // 발명 설명서 업로드 파일 리스트
    const [popupProp, setPopupProp] = useState({type: null, params: {}, oa_idx: null});
    const [timer, setTimer] = useState(0); // 디바운싱 타이머

    useEffect(() => {
        API.get('/manager/member').then(res => {
            // setInventorSelect(res.data.researcher || []);
            setInventorSelect(res.data.manager || []);
        });
    }, []);

    const onClickCategoryAdd = (type) => {
        switch (type) {
            case INVENTOR:
                inventionInfo.inventors = inventionInfo.inventors.concat({idx: '', name: '', share: 0});
                setInventionInfo({...inventionInfo});
                break;
            case APPLICANTORS:
                inventionInfo.applicantors = inventionInfo.applicantors.concat([{name:'', idx: "", auto_keyword: false}]);
                setInventionInfo({...inventionInfo, applicantors: inventionInfo.applicantors.concat([{name:'', idx: "", auto_keyword: false}])});
                setInventionInfo({...inventionInfo});
                break;
            case INVENTIONPUBLISH:
                inventionInfo.inventionPublish = inventionInfo.inventionPublish.concat({idx: '', open_at: 0, reason: '', created_at: new Date().getTime(), fileList: []});
                setInventionInfo({...inventionInfo});
                break;
        }
    };

    const onChangeInventorUpdate = (idx, value) => {
        inventionInfo.inventors[idx].idx = value.target.value;
        inventionInfo.inventors[idx].name = value.target.options[value.target.selectedIndex].text;
        setInventionInfo({...inventionInfo});
    };

    const onClickCategoryDelete = (type, idx) => {
        switch (type) {
            case INVENTOR:
                setInventionInfo({...inventionInfo, inventors: inventionInfo.inventors.filter((value, index) => index !== idx)});
                break;
            case APPLICANTORS:
                setInventionInfo({...inventionInfo, applicantors: inventionInfo.applicantors.filter((value, index) => index !== idx)});
                break;
            case INVENTIONPUBLISH:
                setInventionInfo({...inventionInfo, inventionPublish: inventionInfo.inventionPublish.filter((value, index) => index !== idx)});
                break;
        }
    };

    const onClickUploadPopShow = (type, idx, open_idx) => {
        setFileType({type: type, idx: idx, open_idx: open_idx});

        switch (type) {
            case INVENTION_UPLOAD_FILE_VIEW_LIST:
                setUploadFileViewList(inventionUploadIFileViewList);
                setInventionInfo({...inventionInfo, inventionUploadIFileViewList: inventionUploadIFileViewList});
                break;
            case DESCRIPTION_UPLOAD_FILE_VIEW_LIST:
                setUploadFileViewList(descriptionUploadIFileViewList);
                setInventionInfo({...inventionInfo, descriptionUploadIFileViewList: descriptionUploadIFileViewList});
                break;
            case RELATIVE_DATA:
                setUploadFileViewList(inventionInfo.inventionPublish[idx].fileList);
                break;
            default:
                break;
        }
        setUpPopupShow(true);
    };

    const onClickInsertInvention = () => {
        switch (fileType.type) {
            case INVENTION_UPLOAD_FILE_VIEW_LIST:
                setInventionUploadFileViewList(uploadFileViewList);
                setInventionInfo({...inventionInfo, inventionUploadIFileViewList: uploadFileViewList});
                break;
            case DESCRIPTION_UPLOAD_FILE_VIEW_LIST:
                setDescriptionInventionUploadFileViewList(uploadFileViewList);
                setInventionInfo({...inventionInfo, descriptionUploadIFileViewList: uploadFileViewList});
                break;
            case RELATIVE_DATA:
                inventionInfo.inventionPublish[fileType.idx].fileList = uploadFileViewList;
                setInventionInfo({...inventionInfo});
                break;
        }
        setUpPopupShow(false);
    };

    const onClickDeleteFileInUpload = (idx) => {
        let copyArr = uploadFileViewList.slice(0);
        copyArr.splice(idx, 1);
        setUploadFileViewList(copyArr);

        switch (fileType.type) {
            case INVENTION_UPLOAD_FILE_VIEW_LIST:
                setInventionUploadFileViewList(copyArr);
                break;
            case DESCRIPTION_UPLOAD_FILE_VIEW_LIST:
                setDescriptionInventionUploadFileViewList(copyArr);
                break;
            case RELATIVE_DATA:
                inventionInfo.inventionPublish[fileType.idx].fileList = copyArr;
                setInventionInfo({...inventionInfo});
                break;
        }
    };

    const onClickInsertPatentFile = () => {
        switch (popupProp.type) {
            case "survey":
                patentInfo.fileList.survey_file = uploadPatentFileViewList;
                break;
            case "draft":
                patentInfo.fileList.draft = uploadPatentFileViewList;
                break;
            case "draft_inventor":
                patentInfo.fileList.draft_researcher = uploadPatentFileViewList;
                break;
            case "draft_manager":
                patentInfo.fileList.draft_manager = uploadPatentFileViewList;
                break;
            case "draft_reply":
                patentInfo.fileList.draft_modified = uploadPatentFileViewList;
                break;
            case "draft_finish":
                patentInfo.fileList.draft_final = uploadPatentFileViewList;
                break;
            case "apply":
                patentInfo.fileList.patent_apply = uploadPatentFileViewList;
                break;
            case "register":
                patentInfo.fileList.patent_register = uploadPatentFileViewList;
                break;
            case "oa_content":
                patentInfo.fileList.oa[popupProp.oa_idx].oa_content = uploadPatentFileViewList;
                break;
            case "oa_opinion":
                patentInfo.fileList.oa[popupProp.oa_idx].oa_opinion = uploadPatentFileViewList;
                break;
            case "oa_opinion_researcher":
                patentInfo.fileList.oa[popupProp.oa_idx].oa_opinion_researcher = uploadPatentFileViewList;
                break;
            case "oa_opinion_manager":
                patentInfo.fileList.oa[popupProp.oa_idx].oa_opinion_manager = uploadPatentFileViewList;
                break;
            case "oa_opinion_modified":
                patentInfo.fileList.oa[popupProp.oa_idx].oa_opinion_modified = uploadPatentFileViewList;
                break;
            case "oa_opinion_final":
                patentInfo.fileList.oa[popupProp.oa_idx].oa_opinion_final = uploadPatentFileViewList;
                break;
            case "oa_draft":
                patentInfo.fileList.oa[popupProp.oa_idx].oa_draft = uploadPatentFileViewList;
                break;
            case "oa_draft_modified":
                patentInfo.fileList.oa[popupProp.oa_idx].oa_draft_modified = uploadPatentFileViewList;
                break;
            case "oa_draft_final":
                patentInfo.fileList.oa[popupProp.oa_idx].oa_draft_final = uploadPatentFileViewList;
                break;
        }
        setPatentInfo({...patentInfo});
        setUploadPatentFileViewList([]);
        setPatentFileUpPopupShow(false);
    };

    const onClickDeletePatentFileInUpload = (idx) => {
        let copyArr = uploadPatentFileViewList.slice(0);
        copyArr.splice(idx, 1);
        setUploadPatentFileViewList(copyArr);
    };

    const onClickUpPopShow = (type, oa_idx) => {
        popupProp.type = type;
        popupProp.oa_idx = oa_idx;
        setPopupProp({...popupProp});

        switch (popupProp.type) {
            case "survey":
                setUploadPatentFileViewList(patentInfo.fileList.survey_file);
                break;
            case "draft":
                setUploadPatentFileViewList(patentInfo.fileList.draft);
                break;
            case "draft_inventor":
                setUploadPatentFileViewList(patentInfo.fileList.draft_researcher);
                break;
            case "draft_manager":
                setUploadPatentFileViewList(patentInfo.fileList.draft_manager);
                break;
            case "draft_reply":
                setUploadPatentFileViewList(patentInfo.fileList.draft_modified);
                break;
            case "draft_finish":
                setUploadPatentFileViewList(patentInfo.fileList.draft_final);
                break;
            case "apply":
                setUploadPatentFileViewList(patentInfo.fileList.patent_apply);
                break;
            case "register":
                setUploadPatentFileViewList(patentInfo.fileList.patent_register);
                break;
            case "oa_content":
                setUploadPatentFileViewList(patentInfo.fileList.oa[oa_idx].oa_content);
                break;
            case "oa_opinion":
                setUploadPatentFileViewList(patentInfo.fileList.oa[oa_idx].oa_opinion);
                break;
            case "oa_opinion_researcher":
                setUploadPatentFileViewList(patentInfo.fileList.oa[oa_idx].oa_opinion_researcher);
                break;
            case "oa_opinion_manager":
                setUploadPatentFileViewList(patentInfo.fileList.oa[oa_idx].oa_opinion_manager);
                break;
            case "oa_opinion_modified":
                setUploadPatentFileViewList(patentInfo.fileList.oa[oa_idx].oa_opinion_modified);
                break;
            case "oa_opinion_final":
                setUploadPatentFileViewList(patentInfo.fileList.oa[oa_idx].oa_opinion_final);
                break;
            case "oa_draft":
                setUploadPatentFileViewList(patentInfo.fileList.oa[oa_idx].oa_draft);
                break;
            case "oa_draft_modified":
                setUploadPatentFileViewList(patentInfo.fileList.oa[oa_idx].oa_draft_modified);
                break;
            case "oa_draft_final":
                setUploadPatentFileViewList(patentInfo.fileList.oa[oa_idx].oa_draft_final);
                break;
        }
        setPatentFileUpPopupShow(true);
    };

    const onChangeDatePickerInnerDate = (key, date) => {
        patentInfo[key].inner_date = new Date(date).getTime();
        setPatentInfo({...patentInfo});
    };

    const onChangeOaInnerDate = (date, index, key) => {
        patentInfo.ListOa[index][key].inner_date = date;
        setPatentInfo({...patentInfo});
    };

    inventorTag = inventionInfo.inventors.map((item, index) => {
        return (
            <div key={index} className={`rows right`}>
                <DynamicSelectRow title={`${INVENTOR} ${index + 1}`} titleClass="between" content={item.name} defaultValue={item.idx} editing={true} onClickCategoryDelete={() => onClickCategoryDelete(INVENTOR, index)}
                                  options={inventorSelect} onChange={(e) => onChangeInventorUpdate(index, e)}/>
                <div className="row no_data"/>
            </div>
        );
    });

    let ListOaTag = patentInfo.ListOa.map((item, index) => {
        return (
            <div className="field" key={index}>
                <div className="header">
                    <h2 className="title">OA {index + 1}</h2>
                    <button className="btn_remove" onClick={() => onClickOaRemove(index)}>삭제하기</button>
                </div>
                <div className="rows">
                    <DatePickerOaAndFileRow title='OA발행일' titleClassName='color_blue' data={item.published_at} onChangeDatePickerJson={onChangeDatePrckerOA}
                                            index={index} dataKey='published_at' dateBtnHide={!patentInfo.editing} onClickUpPopShow={() => onClickUpPopShow("oa_content", index)} uploadBtnShow={checkAuth('모두')}/>
                    <OaInnerDatePickerAndFileRow title='대리인 대응안' preventTitle={true} data={item.opinion} index={index} dataKey='opinion' onChangeOaInnerDate={onChangeOaInnerDate} dateBtnHide={!checkAuth('특허회사')}
                                                 uploadBtnShow={checkAuth('모두')} onClickUpPopShow={() => onClickUpPopShow("oa_opinion", index)} />
                    <OaInnerDatePickerAndFileRow title='대응안 발명자 검토' data={item.opinion_researcher} index={index}
                                                 dataKey='opinion_researcher' onChangeOaInnerDate={onChangeOaInnerDate}
                                                 dateBtnHide={!checkAuth('특허회사')}
                                                 customWidth={!checkAuth('특허회사') && patentInfo.customWidth}
                                                 uploadBtnShow={checkAuth('특허회사')} align={true}
                                                 onClickUpPopShow={() => onClickUpPopShow("oa_opinion_researcher", index)}/>
                </div>
                <div className="rows">
                    <div className="row no_data"/>
                    <div className="row no_data"/>
                    <OaInnerDatePickerAndFileRow title='대응안 담당자 검토' data={item.opinion_manager} index={index} dataKey='opinion_manager' onChangeOaInnerDate={onChangeOaInnerDate} dateBtnHide={!checkAuth('특허회사')} customWidth={!checkAuth('특허회사') && patentInfo.customWidth}
                                                 uploadBtnShow={checkAuth('특허회사')} align={true} onClickUpPopShow={() => onClickUpPopShow("oa_opinion_manager", index)} />
                </div>
                <div className="rows">
                    <DateAndFileRow title='대응안 수정본 전달' date={item.draft_modified_upload_at} onClickUpPopShow={() => onClickUpPopShow("oa_opinion_modified", index)} uploadBtnShow={checkAuth('특허회사')}/>
                    <DateAndFileRow title='대응안 최종본 접수' date={item.opinion_final_upload_at} onClickUpPopShow={() => onClickUpPopShow("oa_opinion_final", index)} uploadBtnShow={checkAuth('모두')}/>
                    <DatePickerRow index={index} title={`OA초안작성 지시${item.draft_order_at ? "일" : ""}`} condition={patentInfo.editing} data={item.draft_order_at} dataKey="draft_order_at" useOaFunc={true} onChangeDatePicker={onChangeDatePrckerOA} />
                </div>
                <div className="rows">
                    <DateAndFileRow title='OA초안 접수' date={item.draft_upload_at}
                                    onClickUpPopShow={() => onClickUpPopShow("oa_draft", index)}
                                    uploadBtnShow={checkAuth('모두')} align={true}
                                    removeWidth={!checkAuth('모두')}/>
                    <DateAndFileRow title='OA수정본 전달' date={item.draft_modified_upload_at}
                                    onClickUpPopShow={() => onClickUpPopShow("oa_draft_modified", index)}
                                    uploadBtnShow={checkAuth('모두')}/>
                    <DateAndFileRow title='OA최종본 접수' date={item.final_upload_at}
                                    onClickUpPopShow={() => onClickUpPopShow("oa_draft_final", index)}
                                    uploadBtnShow={checkAuth('모두')}/>
                </div>
                <div className="rows">
                    <DatePickerRow index={index} title={`OA제출 지시${item.submission_order_at ? "일" : ""}`} condition={patentInfo.editing} data={item.submission_order_at} dataKey="submission_order_at" useOaFunc={true} onChangeDatePicker={onChangeDatePrckerOA} />
                    <div className="row">
                        <h2 className="title">OA연장 횟수<i className="icon_info" data-content={window.$Global.getInfoMessage("OA연장 횟수")}/></h2>
                        <select defaultValue={item.extension_cnt} onChange={(e) => {
                            patentInfo.ListOa[index].extension_cnt = e.target.value;
                            setPatentInfo({...patentInfo})
                        }} style={{width: 100}}>
                            <option value={0}>0회</option>
                            <option value={1}>1회</option>
                            <option value={2}>2회</option>
                        </select>
                    </div>
                    <DatePickerRow title='OA기한' titleClassName='color`_blue' condition={patentInfo.editing}
                                   data={item.deadline} dataClassName="color_red" index={index}
                                   dataKey='deadline' useOaFunc={true}
                                   onChangeDatePicker={onChangeDatePrckerOA}/>
                </div>
            </div>
        );
    });

    return (
        <div className="form hidden_download">
            <div id="InventionDetail" className="area_box">
                <div className="form">
                    <div className="field">
                        <div className="rows">
                            <InputRow rowClassName='colspan2 between' title="디자인의 명칭" condition={true} content={patentInfo.inventionName}
                                      inputClassName="long" onChange={onChangeInput} setKey="inventionName"/>
                            <FileRow title="디자인 창작물"  onClick2={() => onClickUploadPopShow(INVENTION_UPLOAD_FILE_VIEW_LIST)}/>
                        </div>
                    </div>
                    <div className="field" style={{marginTop:0}}>
                        <div className="rows">
                            <div className="row no_data"/>
                            <div className="row no_data"/>
                            <FileRow title="디자인 설명서"  onClick2={() => onClickUploadPopShow(DESCRIPTION_UPLOAD_FILE_VIEW_LIST)}/>
                        </div>
                        <div className="rows">
                            <div className="row between disable">
                                <h2 className="title">국가</h2>
                                <select>
                                    { nationType.map((item, idx) => <option key={idx} value={idx}>{item}</option>) }
                                </select>
                            </div>
                            <div className="row between disable">
                                <h2 className="title">유형</h2>
                                <select defaultValue={0}>
                                    <option value={0}>원출원</option>
                                    <option value={1}>우선권 주장</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="field" style={{marginTop: 60}}>
                        <div className="rows" style={{float: "left", overflow: "hidden"}}>
                            <div className="row between">
                                <h2 className="title color_blue">발명자 추가</h2>
                                <button className="icon_blue_add" onClick={() => onClickCategoryAdd(INVENTOR)}/>
                            </div>
                        </div>
                        {inventorTag}
                    </div>
                </div>
            </div>
            <div className="field" className="area_box" style={{marginTop: 30}}>
                <div className="rows">
                    <InputRow title="관리번호" titleClass="color_blue" condition={true} content={patentInfo.manage_number}
                              onChange={onChangeInput} setKey="manage_number"/>
                </div>
                <div className="rows">
                    <SelectRow title="담당자" content={patentInfo.manager_name} onChange={(e) => setPatentInfo({...patentInfo, manager_idx: e.target.value, manager_name: e.target.options[e.target.selectedIndex].text})}
                               condition={patentInfo.editing} contentIdx={patentInfo.manager_idx} selectTag={managerSelectTag}/>
                    <SelectRow title="대리인" content={patentInfo.agent_company_name} onChange={(e) => setPatentInfo({...patentInfo, agent_company_idx: e.target.value})}
                                condition={patentInfo.editing} contentIdx={patentInfo.agent_company_idx} selectTag={agentCompanyListTag}/>
                </div>
            </div>
            <div className="field" className="area_box" style={{marginTop: 30}}>
                <div className="rows">
                    <InputAndFileRow title="출원번호" titleClassName="color_blue" content={patentInfo.apply_number} onChange={(e) => onChangeInput("apply_number", e.target.value)}
                                     editing={patentInfo.editing} onClickUpPopShow={() => onClickUpPopShow(typeName.apply)}/>
                    <DatePickerRow title='출원일' condition={patentInfo.editing} data={patentInfo.apply_at} dataKey="apply_at" useOaFunc={false} onChangeDatePicker={onChangeDatePicker} />
                </div>
                <div className="rows">
                    <InputRow rowClassName='colspan2' title="디자인의 명칭" condition={patentInfo.editing} content={patentInfo.inventionName}
                              inputClassName="long" onChange={onChangeInput} setKey="inventionName"/>
                </div>
                <div className="rows">
                    <InputRow rowClassName='colspan2' title="영문 명칭" condition={patentInfo.editing} content={patentInfo.invention_name_en}
                              inputClassName="long" onChange={onChangeInput} setKey="invention_name_en"/>
                    <div className="row no_data"/>
                </div>
                <div className="process_manage">
                    <ProcessManage title="출원 상세 절차 관리" selected={patentInfo.apply_detail} isNew={onChangeInput}/>
                    {
                        Boolean(patentInfo.apply_detail) &&
                        <>
                            <div className="rows">
                                <div className="row">
                                    <h2 className="title">초안작성 지시{patentInfo.draft_order_at ? "일" : ""}<i
                                        className="icon_info" data-content={window.$Global.getInfoMessage("초안작성 지시")}/>
                                    </h2>
                                    <div className="btns">
                                        <span>{patentInfo.draft_order_at ? window.$Global.convertDate(patentInfo.draft_order_at) : 'N/A'} </span>
                                        <DatePicker selected={patentInfo.draft_order_at} onChange={(date) => {
                                            patentInfo.draft_order_at = date;
                                            setPatentInfo({...patentInfo});
                                        }}
                                                    customInput={<button className="icon_calendar"/>}
                                                    dayClassName={(date) => window.$Global.setWeekendColor(date)}/>
                                    </div>
                                </div>
                                <InnerDatePickerAndFileRow title='초안 접수' data={patentInfo.draftReceivingDate}
                                                           setKey="draftReceivingDate"
                                                           onChangeDatePickerJson={onChangeDatePickerInnerDate}
                                                           dateBtnHide={!checkAuth('특허회사')}
                                                           uploadBtnShow={true}
                                                           onClickUpPopShow={() => onClickUpPopShow("draft")}/>
                                <InnerDatePickerAndFileRow title='초안 발명자 검토' data={patentInfo.draftInventorsDate}
                                                           setKey="draftInventorsDate"
                                                           onChangeDatePickerJson={onChangeDatePickerInnerDate}
                                                           dateBtnHide={!checkAuth('특허회사')}
                                                           customWidth={!checkAuth('특허회사') && patentInfo.customWidth}
                                                           uploadBtnShow={checkAuth('특허회사')} align={true}
                                                           onClickUpPopShow={() => onClickUpPopShow("draft_inventor")}/>
                            </div>
                            <div className="rows">
                                <div className="row no_data"/>
                                <div className="row no_data"/>
                                <InnerDatePickerAndFileRow title='초안 담당자 검토' data={patentInfo.draftManagerDate}
                                                           setKey="draftManagerDate"
                                                           onChangeDatePickerJson={onChangeDatePickerInnerDate}
                                                           dateBtnHide={!checkAuth('특허회사')}
                                                           customWidth={!checkAuth('특허회사') && patentInfo.customWidth}
                                                           uploadBtnShow={checkAuth('특허회사')} align={true}
                                                           onClickUpPopShow={() => onClickUpPopShow("draft_manager")}/>
                            </div>
                            <div className="rows">
                                <DateAndFileRow title='초안 수정본 전달' date={patentInfo.draftModifiedUploadDate}
                                                onClickUpPopShow={() => onClickUpPopShow("draft_reply")}
                                                uploadBtnShow={checkAuth('특허회사_관리자')}/>
                                <DateAndFileRow title={`초안 최종본 접수${patentInfo.draftFinalDate.date !== null ? '일' : ''}`}
                                                date={patentInfo.draftFinalDate.date}
                                                onClickUpPopShow={() => onClickUpPopShow("draft_finish")}
                                                uploadBtnShow={checkAuth('모두')}/>
                                <div className="row no_data"/>
                            </div>
                            <div className="rows">
                                <div className="row">
                                    <h2 className="title">출원 지시{patentInfo.apply_order_at ? "일" : ""}<i
                                        className="icon_info" data-content={window.$Global.getInfoMessage("출원 지시")}/>
                                    </h2>
                                    <div className="btns">
                                        <span>{patentInfo.apply_order_at ? window.$Global.convertDate(patentInfo.apply_order_at) : 'N/A'} </span>
                                        <DatePicker selected={patentInfo.apply_order_at}
                                                    onChange={(date) => setPatentInfo({
                                                        ...patentInfo,
                                                        apply_order_at: date
                                                    })}
                                                    customInput={<button className="icon_calendar"/>}
                                                    dayClassName={(date) => window.$Global.setWeekendColor(date)}/>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
            {
                ListOaTag
            }
            <div className="field" className="area_box" style={{marginTop: 30}}>
                <div className="rows">
                    <DatePickerRow title='등록 결정일' titleClassName='color_blue' condition={patentInfo.editing} data={patentInfo.register_decision_at} dataKey="register_decision_at" useOaFunc={false} onChangeDatePicker={onChangeDatePicker}  />
                    <DatePickerRow title='등록일' condition={patentInfo.editing} data={patentInfo.register_at} dataKey="register_at" useOaFunc={false} onChangeDatePicker={onChangeDatePicker}/>
                </div>
                <div className="rows">
                    <InputAndFileRow title="등록번호" content={patentInfo.register_number} onChange={(e) => onChangeInput("register_number", e.target.value)}
                                     editing={patentInfo.editing} onClickUpPopShow={() => onClickUpPopShow(typeName.register)}/>
                    <DatePickerRow title='등록기한' titleClassName='color_blue' condition={patentInfo.editing} data={patentInfo.register_deadline} dataClassName='color_red' dataKey="register_deadline" useOaFunc={false} onChangeDatePicker={onChangeDatePicker}  />
                </div>
            </div>
            <DetailPopupUpload show={upPopupShow} setShow={setUpPopupShow} uploadFileViewList={uploadFileViewList} setFileList={setUploadFileViewList} onClickUpload={onClickInsertInvention} prop_onClickDelete={onClickDeleteFileInUpload}/>
            <DetailPopupUpload show={patentFileUpPopupShow} setShow={setPatentFileUpPopupShow} uploadFileViewList={uploadPatentFileViewList} setFileList={setUploadPatentFileViewList} onClickUpload={onClickInsertPatentFile} prop_onClickDelete={onClickDeletePatentFileInUpload}/>
        </div>
    );
}

export default FormDesignAddKr;
