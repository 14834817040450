import "../css/RenewalServiceMainMobile.scss";
import { useHistory } from "react-router-dom";

const RenewalServiceMainMobile = () => {
  const history = useHistory();
  return (
    <div id="RenewalServiceMainMobile">
      <div onClick={() => history.push("/groupware/approval")}>
        <span>OFFICE</span>
        <button>
          바로가기 <i className="icon_arrow_right" />
        </button>
      </div>
      <div onClick={() => history.push("/taskManage/list")}>
        <span>정부과제</span>
        <button>
          바로가기 <i className="icon_arrow_right" />
        </button>
      </div>
      <div onClick={() => history.push("/marketInfo/category")}>
        <span>시장정보</span>
        <button>
          바로가기 <i className="icon_arrow_right" />
        </button>
      </div>
      <div onClick={() => history.push("/researchTech/category")}>
        <span>특허/기술트렌드조사</span>
        <button>
          바로가기 <i className="icon_arrow_right" />
        </button>
      </div>
      <div onClick={() => history.push("/competitor/main")}>
        <span>기업정보</span>
        <button>
          바로가기 <i className="icon_arrow_right" />
        </button>
      </div>
    </div>
  );
};

export default RenewalServiceMainMobile;
