import { useEffect, useState } from "react";
import GroupwareSideBar from "../GroupwareSideBar";
import { groupCashflow } from "../groupwarePath";
import GwAPI from "../../../API/groupware";
import "./scss/GroupwareCashflow.scss";
import TopBanner from "./TopBanner";
import Top from "./Top";
import {
  Link,
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom";
import { Bar, Line } from "react-chartjs-2";
import CashflowHistoryList from "./CashflowHistoryList";
import Financing from "./Financing";
import cashflowUtils from "./cashflowUtils";
import moment from "moment";
import HistoryAnalyze from "./HistoryAnalyze";

const CashflowReport = () => {
  const step = "view";
  let history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [reportId, setReportId] = useState(
    Number(searchParams.get("report_id"))
  ); // 리포트 id
  const [reportSumData, setReportSumData] = useState({
    baseCashAmount: 0,
    totalIncome: 0,
    totalOutcome: 0,
  }); // 리포트 금액 데이터
  const [monGraphData, setMonGraphData] = useState([]); // 월별 그래프 데이터
  const [runwayData, setRunwayData] = useState({}); // 런웨이 데이터
  const [mixedCahrtData, setMixedCahrtData] = useState({});
  const [reportInfo, setReportInfo] = useState({});
  const [mostIncomeCategory, setMostIncomeCategory] = useState({}); // 최대 현금 유입처
  const [mostUseCategory, setMostUseCategory] = useState({}); // 최대 현금 유출처
  const [startDate, setStartDate] = useState({ year: 0, month: 0, day: 0 });
  const [triggerSetData, setTriggerSetData] = useState(false);
  const [historyStartDate, setHistoryStartDate] = useState(
    moment().format("YYYY-MM-DD") // 오늘
  );
  const [historyEndDate, setHistoryEndDate] = useState(
    moment().add(1, "months").format("YYYY-MM-DD") // 오늘로부터 한달 후
  );
  const [historyAnalyzeList, setHistoryAnalyzeList] = useState([]); // 히스토리 분석 리스트
  const chartOption = {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        boxWidth: 11,
        boxHeight: 11,
        usePointStyle: true,
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };

  // 보유금액, 현금 유입, 유출 금액 가져오기
  const getReportSum = () => {
    return GwAPI.getReportSum({ report_id: reportId })
      .then((res) => {
        if (res.status === 200) {
          return res;
        } else {
          alert("데이터 가져오기 중 오류가 발생했습니다");
          return false;
        }
      })
      .catch((error) => {
        console.error("Fetching cashflow data failed:", error);
        alert("데이터 가져오기 중 오류가 발생했습니다");
        return false;
      });
  };

  const fetchReportData = async () => {
    if (!reportId) {
      alert("해당 리포트를 찾을 수 없습니다.");
      history.push("/groupware/cashflow/list");
    } else {
      const reportSum = await getReportSum();
      const reportSumData = reportSum?.data?.previewData;
      //console.log("reportSumData:", reportSumData);
      setReportSumData({
        baseCashAmount: formatValue(reportSumData.baseCashAmount),
        totalIncome: formatValue(reportSumData.totalIncome),
        totalOutcome: formatValue(reportSumData.totalOutcome),
      });
    }
  };

  // 그래프 데이터 가져오기
  const getMonthGraphData = () => {
    return GwAPI.getMonthGraph({ report_id: reportId })
      .then((res) => {
        if (res.status === 200) {
          return res;
        } else {
          alert("데이터 가져오기 중 오류가 발생했습니다");
          return false;
        }
      })
      .catch((error) => {
        console.error("Fetching cashflow data failed:", error);
        alert("데이터 가져오기 중 오류가 발생했습니다");
        return false;
      });
  };

  const fetchGraphData = async () => {
    if (!reportId) {
      alert("해당 리포트를 찾을 수 없습니다.");
      history.push("/groupware/cashflow/list");
    } else {
      const monthGraph = await getMonthGraphData();
      const monthGraphData = monthGraph?.data?.monthlyCashFlow;
      const monthRunwayData = monthGraph?.data?.runways;
      //console.log("monthGraphData:", monthGraphData);

      setMonGraphData(monthGraphData);
      setRunwayData(monthRunwayData);
    }
  };

  const getOneReportInfo = () => {
    return GwAPI.getOneReportInfo({ report_id: reportId })
      .then((res) => {
        ////console.log("getOneReportInfo:", res);
        if (res.status === 200) {
          return res;
        } else {
          alert("데이터 가져오기 중 오류가 발생했습니다");
          return false;
        }
      })
      .catch((error) => {
        console.error("Fetching report data failed:", error);
        alert("데이터 가져오기 중 오류가 발생했습니다");
        return false;
      });
  };

  const fetchOneReportInfo = async () => {
    if (!reportId) {
      alert("해당 리포트를 찾을 수 없습니다.");
      history.push("/groupware/cashflow/list");
    } else {
      const reportInfo = await getOneReportInfo();
      const reportInfoData = reportInfo?.data?.reportEntity;
      const mostUseCategory = reportInfo?.data?.mostUseCategory;
      const mostIncomeCategory = reportInfo?.data?.mostIncomeCategory;
      setReportInfo(reportInfoData);
      setMostIncomeCategory(mostIncomeCategory);
      setMostUseCategory(mostUseCategory);
      setStartDate(formatDate(reportInfoData.reg_date));
    }
  };

  const formatValue = (value) => {
    // 입력값의 부호를 기억하고 절대값으로 변환
    const isNegative = value < 0;
    let realWon = Math.abs(value);

    // 억 단위와 만 단위로 분리
    const billion = Math.floor(realWon / 10000 / 10000);
    const tenThousand = (realWon / 10000) % 10000;

    // 억 단위가 있을 경우 억 단위 포함하여 표시, 만 단위만 있을 경우 만 단위만 표시
    const result = `${billion > 0 ? `${billion}억 ` : ""}${
      tenThousand > 0 ? `${Math.floor(tenThousand)}만원` : "0만원"
    }`;

    // 음수였다면 결과 앞에 "-" 추가
    return isNegative ? `-${result}` : result;
  };

  const formatDate = (date) => {
    const newDate = new Date(date);

    const year = newDate.getFullYear();
    const month = newDate.getMonth() + 1;
    const day = newDate.getDate();

    return { year: year, month: month, day: day };
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchReportData();
      await fetchGraphData();
      await fetchOneReportInfo();
      await getHistory();
      // 모든 데이터 로딩 후 차트 데이터 설정
      setTriggerSetData(true);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await fetchReportData();
      await fetchGraphData();
      await fetchOneReportInfo();
      await getHistory();
      // 모든 데이터 로딩 후 차트 데이터 설정
      setTriggerSetData(true);
    };

    fetchData();

    window.scrollTo({
      top: 0, // 상단으로 이동
      behavior: "smooth", // 스무스 스크롤 옵션
    });
  }, [reportId]);

  useEffect(() => {
    setReportId(Number(searchParams.get("report_id")));
  }, [location]);

  useEffect(() => {
    //console.log("triggerSetData:", triggerSetData);
    if (triggerSetData) {
      let monthArr = [];
      let incomeArr = [];
      let outcomeArr = [];
      let totalArr = [];
      let baseArr = [];

      for (let i = 0; i < 12; i++) {
        monthArr.push(
          startDate.month + i > 12
            ? startDate.month + i - 12 + "월"
            : startDate.month + i + "월"
        );
      }
      monGraphData.forEach((el) => {
        incomeArr.push(el.income / 1000);
        outcomeArr.push(el.outcome / 1000);
        totalArr.push(el.totalAmount / 1000);
        baseArr.push(el.baseCashAmount / 1000);
      });

      setMixedCahrtData({
        labels: monthArr,
        datasets: [
          // {
          //   label: "기초",
          //   data: baseArr,
          // },
          {
            label: "수입",
            data: incomeArr,
            backgroundColor: "#3E74FF",
          },
          {
            label: "지출",
            data: outcomeArr,
            backgroundColor: "#6AF0F0",
          },
          {
            label: "기말",
            data: totalArr,
            type: "line",
            fill: false,
            borderColor: "#00dd77",
            order: 3,
            borderWidth: 4,
          },
        ],
      });

      setTriggerSetData(false);
    }
  }, [triggerSetData]);

  const handleStartDateChange = (e) => {
    setHistoryStartDate(e.target.value); // 시작 날짜 업데이트
  };

  const handleEndDateChange = (e) => {
    setHistoryEndDate(e.target.value); // 종료 날짜 업데이트
  };

  const getList = async () => {
    try {
      const res = await GwAPI.getCashflowList({
        start_date: historyStartDate,
        end_date: historyEndDate,
      });

      if (res.data.cashflowReportList.length > 0) {
        const formattedList = await Promise.all(
          res.data.cashflowReportList.map(async (item) => {
            const formattedDate = formatDate(item.reg_date);
            const runwayRes = await GwAPI.getMonthGraph({
              report_id: item.report_id,
            });
            const runway = runwayRes.data;
            return {
              ...item,
              updated_Date: formattedDate,
              runway: runway,
            };
          })
        );

        setHistoryAnalyzeList(formattedList);
      }
    } catch (error) {
      console.error("Error fetching cashflow list:", error);
    }
  };

  const getHistory = async () => {
    const startDate = new Date(historyStartDate);
    const endDate = new Date(historyEndDate);

    if (startDate > endDate) {
      alert("기간의 시작 날짜가 종료 날짜보다 늦습니다.");
    } else {
      getList();
    }
  };

  const getTimingString = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;

    return `${year}년 ${month}월`;
  };

  const getDifferenceBetweenToday = (date) => {
    return cashflowUtils.getDaysDifference(new Date(date), new Date());
  };

  return (
    <div id="GroupwareWorks">
      <GroupwareSideBar currentPath={groupCashflow} />
      <div className="commute_wrapper">
        <div id="GroupwareCashflow">
          <TopBanner />
          <Top
            page={step}
            date={getDifferenceBetweenToday(
              `${startDate.year}-${startDate.month}-${startDate.day}`
            )}
          />
          <div className="content_box">
            <div className="report_box">
              <div className="cashflow_report wrap">
                <div className="bg_wh rounded calc_box">
                  <div className="info_box">
                    <p className="start_date">
                      {startDate.year}년 {startDate.month}월 {startDate.day}일
                      기준
                    </p>
                    <p className="status">
                      {localStorage.getItem("company_name")}의 현금흐름
                      상황은&nbsp;
                      {runwayData && runwayData.runway_idx !== undefined && (
                        <strong
                          className={`${
                            cashflowUtils.translateMonthToStatus(
                              runwayData.runway_idx
                            ).code
                          } status_code`}
                        >
                          {
                            cashflowUtils.translateMonthToStatus(
                              runwayData.runway_idx
                            ).text
                          }
                        </strong>
                      )}
                      &nbsp; 입니다.
                    </p>
                  </div>

                  <ul>
                    <li className="plus">
                      <strong className="cash_name">보유금액</strong>
                      <p className="cash">
                        <strong className="blue">
                          {reportSumData.baseCashAmount}
                        </strong>
                      </p>
                    </li>
                    <li className="minus">
                      <strong className="cash_name">예상 현금유입</strong>
                      <p className="cash">
                        <strong className="blue">
                          {reportSumData.totalIncome}
                        </strong>
                      </p>
                    </li>
                    <li>
                      <strong className="cash_name">예상 현금유출</strong>
                      <p className="cash">
                        <strong className="blue">
                          {reportSumData.totalOutcome}
                        </strong>
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="data_box bg_wh rounded mt-10">
                  <strong className="data_tit">월별 CASHFLOW</strong>
                  <div className="mon_tbl_box">
                    <div className="item head">
                      <div className="month">&nbsp;</div>
                      <div>기초</div>
                      <div>수입</div>
                      <div>지출</div>
                      <div>기말</div>
                    </div>
                    {monGraphData.map((data, idx) => (
                      <div key={idx} className="item mon_box">
                        <div className="month">
                          {startDate.month + idx > 12
                            ? startDate.month + idx - 12
                            : startDate.month + idx}
                          월
                        </div>
                        <div className="rAlign">
                          {formatValue(data.baseCashAmount)}
                        </div>
                        <div className="rAlign">{formatValue(data.income)}</div>
                        <div className="rAlign">
                          {formatValue(data.outcome)}
                        </div>
                        <div className="rAlign">
                          {formatValue(data.totalAmount)}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="graph_box">
                    <p className="unit">단위: 천원</p>
                    <Bar
                      data={mixedCahrtData}
                      options={chartOption}
                      width={882}
                      height={200}
                    />
                  </div>
                </div>
                <div
                  className="data_box bg_wh rounded mt-10"
                  style={{ marginBottom: "20px" }}
                >
                  <strong className="data_tit">CASHFLOW 히스토리 분석</strong>
                  <div className="date">
                    <span>기간: </span>
                    <input
                      type="date"
                      id="start_date"
                      name="start_date"
                      value={historyStartDate}
                      onChange={handleStartDateChange}
                    />
                    &nbsp;~&nbsp;
                    <input
                      type="date"
                      id="end_date"
                      name="end_date"
                      value={historyEndDate}
                      onChange={handleEndDateChange}
                    />
                    <button onClick={() => getHistory()}>검색</button>
                  </div>
                  <HistoryAnalyze analyzeList={historyAnalyzeList} />
                </div>
                <div className="data_box" style={{ padding: "0" }}>
                  <strong class="data_tit">히스토리</strong>
                  <CashflowHistoryList />
                </div>
              </div>
            </div>
            <div>
              <div className="timing">
                <strong>예상 RUNWAY 시점</strong>
                {runwayData.runway_idx === "" ? (
                  <p>12개월 이상 이후</p>
                ) : (
                  <p>
                    {getTimingString(
                      cashflowUtils.addMonths(
                        `${startDate.year}-${startDate.month}-${startDate.day}`,
                        runwayData.runway_idx
                      )
                    )}
                    <span>
                      {runwayData.runway_idx === 0
                        ? ""
                        : `(${runwayData.runway_idx} 개월)`}
                    </span>
                  </p>
                )}
              </div>
              <div className="most_use">
                <div>
                  <p>최대 현금 유출처</p>
                  <strong>
                    {mostUseCategory
                      ? mostUseCategory.category_name === ""
                        ? "-"
                        : mostUseCategory.category_name
                      : "-"}
                  </strong>
                </div>
                <div>
                  <p>최대 현금 유입처</p>
                  <strong>
                    {mostIncomeCategory
                      ? mostIncomeCategory.category_name === ""
                        ? "-"
                        : mostIncomeCategory.category_name
                      : "-"}
                  </strong>
                </div>
              </div>
              <Financing runway={runwayData.runway_idx} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashflowReport;
