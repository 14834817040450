import "./css/Price.scss";
import React, { useEffect, useState, useContext } from "react";
import CommonAPI from "../../API/common";
import Counter from "./Counter";
import { subPackageData, infoPackageData } from "./data";
import RadioLabel from "./RadioLabel";
import Package from "./Package";
import CheckboxLabel from "./CheckboxLabel";
import SubsChoice from "./SubsChoice";
import InfoChoice from "./InfoChoice";
import _ from "lodash";
import SubsCost from "./SubsCost";
import InfoCost from "./InfoCost";
import { useHistory, useLocation } from "react-router-dom";
import PaymentAPI from "../../API/payment";
import memberContext from "../../store/Member";
import qs from "query-string";

const { IMP } = window;
IMP.init("imp60657743");

const Price = () => {
  const location = useLocation();
  const { idx, company, email, auth } = useContext(memberContext);
  const { testMode } = qs.parse(location.search);
  const history = useHistory();
  const [numberArr, setNumberArr] = useState([]); // 사용자 수 저장을 위한 Array
  const [count, setCount] = useState(5);
  const [tabChange, setTabChange] = useState(false);
  const [cost, setCost] = useState({
    storage: 0,
    subsPackage: "",
    subsPeriod: 12,
    dataPackage: "",
    dataPeriod: 12,
    packageCost: 0,
    dataCost: 0,
  });
  const [totalCost, setTotalCost] = useState(0); // 총합
  const [packageCost, setPackageCost] = useState(0); // 패키지 가격
  const [dataPackageCost, setDataPackageCost] = useState(0); // 패키지 가격
  const [subPackage, setSubPackage] = useState([]); // 구독 멤버십 데이터
  const [dataPackage, setDataPackage] = useState([]);
  const [packageCheckedData, setPackageCheckedData] = useState({}); // 고른 패키지의 데이터
  const [dataPackageCheckedData, setDataPackageCheckedData] = useState({}); // 비즈니스 정보 서비스 패키지 데이터
  const [storageData, setStorageData] = useState([]); // 추가 용량 데이터
  const [storageCheckedData, setStorageCheckedData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [testPrice, setTestPrice] = useState(200); // 테스트에 사용되는 변수
  const isTestMode = testMode == "test";

  useEffect(() => {
    getProductList();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      calculateCost();
    }
  }, [cost, count]);

  const getProductList = () => {
    CommonAPI.getProductList().then((res) => {
      setNumberArr(res.data.member_count);
      initWorks(res.data);
    });
  };

  const initWorks = (data) => {
    // 구독 멤버십 초기화 작업
    let worksArr = [];
    data.works.map((item) => {
      let obj = {
        category_idx: item.product_category_idx,
        key: item.product_service_type,
        title: item.product_category_nm,
        subTitle: item.sub_title,
        id: item.product_service_type,
        name: item.product_category_type,
        badgeType: item.badge_type,
        menuItems: item.menu_idx?.split(","),
        productList: item.productList,
        isChecked: false,
        isStorageChecked: false,
      };
      worksArr.push(obj);
    });
    setSubPackage(worksArr);
    setStorageData(data.option);
    // 비즈니스 정보 서비스 초기화 작업
    let bizArr = [];
    data.data.map((item) => {
      let obj = {
        key: item.product_service_type,
        title: item.product_category_nm,
        subTitle: item.sub_title,
        idx: item.product_category_idx,
        id: item.product_service_type,
        name: item.product_category_nm,
        badgeType: item.badgeType,
        productList: item.productList,
        icon: "camera", // 컬럼 추가 해야함
        isChecked: false,
      };
      bizArr.push(obj);
    });
    setDataPackage(bizArr);
    setIsLoading(false);
  };

  const onSubsRadioChange = (selectedIndex) => {
    const updatedPackages = subPackage.map((item, index) => ({
      ...item,
      isChecked: index === selectedIndex,
    }));

    updatedPackages.map((item) => {
      if (item.isChecked) {
        setCost({
          ...cost,
          subsPackage: item.key,
          subsPeriod: cost.subsPeriod,
        });
      }
    });

    setSubPackage(updatedPackages);
  };

  const onDataRadioChange = (selectedIndex) => {
    const updatedPackages = dataPackage.map((item, index) => ({
      ...item,
      isChecked: index === selectedIndex ? true : false,
    }));

    updatedPackages.map((item) => {
      if (item.isChecked) {
        setCost({
          ...cost,
          dataPackage: item.key,
          dataPeriod: cost.dataPeriod,
        });
      }
    });

    setDataPackage(updatedPackages);
  };

  const calculateCost = () => {
    // 고른 패키지 찾기
    let checkPackage = subPackage.filter(
      (item) => item.key === cost.subsPackage
    )[0];

    let dataCheckPackage = dataPackage.filter(
      (item) => item.key === cost.dataPackage
    )[0];

    // 패키지 안에서 멤버수와 개월수를 이용해 해당 가격값 가져오기
    let subsCheck = checkPackage?.productList?.filter((item) => {
      if (
        item.product_member_cnt == count &&
        item.product_month == cost.subsPeriod
      )
        return true;
      else return false;
    })[0];

    let dataCheck = dataCheckPackage?.productList?.filter((item) => {
      if (item.product_month == cost.dataPeriod) return true;
      else return false;
    })[0];

    setPackageCheckedData(subsCheck);
    setDataPackageCheckedData(dataCheck);

    let storageCost = 0;
    let dataPackageCost = dataCheck?.product_price || 0;
    let packageCost = subsCheck?.product_price || 0;
    if (!_.isEmpty(storageCheckedData)) {
      storageCost +=
        storageCheckedData.product_option_price * subsCheck.product_month;
    }

    setTotalCost(Math.floor(dataPackageCost + storageCost + packageCost));

    setPackageCost(packageCost + storageCost);
    setDataPackageCost(dataPackageCost);
  };

  const onClickPayment = () => {
    if (_.isEmpty(packageCheckedData) && _.isEmpty(dataPackageCheckedData)) {
      alert("패키지를 선택해주세요");
      return;
    }

    let name = "";

    if (!_.isEmpty(packageCheckedData)) {
      name = `${
        subPackage.filter((item) => cost.subsPackage === item.key)[0].title
      }`;
    }

    if (!_.isEmpty(dataPackageCheckedData)) {
      if (name.length === 0) {
        name = `${
          dataPackage.filter((item) => cost.dataPackage === item.key)[0].title
        }`;
      } else {
        name += " 외 1건";
      }
    }

    name = "BIZNAVI " + name;

    let totalCostPlusSurtax = Math.floor(totalCost * 1.1);

    const data = {
      pg: "inicis",
      pay_method: "card",
      merchant_uid: `mid_${new Date().getTime()}`,
      amount: isTestMode ? testPrice : totalCostPlusSurtax,
      // customer_uid: isTestMode && idx, // 자동 결제
      name: name,
      buyer_name: company,
      buyer_email: email,
    };
    // await으로 일단 먼저 Log Data 넣고 시작

    // 결제 진행
    IMP.request_pay(data, (response) => {
      const { success, merchant_uid, imp_uid, error_msg } = response;

      if (success) {
        let payload = {
          imp_uid: imp_uid,
          package_idx: packageCheckedData?.product_category_idx,
          product_idx: packageCheckedData?.product_idx,
          data_package_idx: dataPackageCheckedData?.product_category_idx,
          data_product_idx: dataPackageCheckedData?.product_idx,
          storage_idx: storageCheckedData?.product_option_idx,
          need_cost: totalCostPlusSurtax,
          need_cost_non_surtax: totalCost,
        };
        PaymentAPI.payment(payload).then((res) => {
          let state = {
            ...response,
            payment_no: res.data.payment_no,
          };
          alert("결제가 완료되었습니다");
          history.push({ pathname: "/intro/price/result", state: state });
        });
      } else {
        alert(`결제 실패: ${error_msg}`);
      }
    });
  };

  return (
    <div id="Price">
      <header>
        <div className="header_left">
          <div className="counter_wrapper">
            <p>사용자 수</p>
            <Counter count={count} setCount={setCount} numberArr={numberArr} />
            <p>명</p>
            <p
              className="custom-text"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <i
                className="icon_info_with_blue_bg"
                style={{ marginRight: "4px", marginTop: "1px" }}
              ></i>
              100인 이상은 별도 협의 (☎ 1551-8442)
            </p>
          </div>
        </div>
        <div className="header_right" onClick={() => history.push("/")}>
          <i className="icon_arrow_left_gray" />
          <p>모든 서비스 목록으로 돌아가기</p>
          <i className="icon_menu_square" />
        </div>
      </header>
      <section>
        <div className="section_header">
          <div className="tab">
            <div className="tab_left">
              <div
                className={!tabChange && "active"}
                onClick={() => setTabChange(false)}
              >
                <span>구독 멤버십</span>
              </div>
              <div
                className={tabChange && "active"}
                onClick={() => setTabChange(true)}
              >
                <span>비즈니스 정보 서비스</span>
              </div>
            </div>
            <div className="tab_right">
              <p>선택 서비스 및 옵션</p>
            </div>
          </div>
          <p>계산기</p>
        </div>
        <div className="section">
          <div className="section_left">
            {tabChange ? (
              <div>
                <div className="service_package">
                  <div className="service_package_title">
                    <h5 className="">패키지 구매</h5>
                  </div>
                  {dataPackage.map((item, index) => {
                    return (
                      <RadioLabel
                        item={item}
                        onChange={() => onDataRadioChange(index)}
                      />
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="subs_package">
                <h5 className="subs_package_title">
                  가장 필요한 서비스를 기본으로 모든 서비스를 경험하세요.
                </h5>
                {subPackage.map((item, index) => {
                  return (
                    <>
                      <RadioLabel
                        item={item}
                        onChange={() => onSubsRadioChange(index)}
                      />
                      {item.isChecked && <Package menu={item.menuItems} />}
                      {item.id !== "free" && item.isChecked && (
                        <label
                          htmlFor="storage"
                          className="radio_label sub_label"
                        >
                          <h2>그룹웨어·클라우드 용량 추가</h2>
                          <h5>
                            용량에 구애받지 않는 올인원 패키지를 사용해보세요.
                          </h5>
                          <input
                            type="checkbox"
                            id="storage"
                            checked={item.isStorageChecked}
                            onChange={(e) => {
                              if (!e.target.checked) {
                                setCost({ ...cost, storage: 0 });
                              }
                              item.isStorageChecked = e.target.checked;
                              setSubPackage([...subPackage]);
                            }}
                          />
                        </label>
                      )}
                    </>
                  );
                })}
              </div>
            )}
            <div className="choice_wrapper">
              <div>
                <h2>구독 멤버십</h2>
                <SubsChoice
                  storageData={storageData}
                  storageCheckedData={storageCheckedData}
                  setStorageCheckedData={setStorageCheckedData}
                  subPackage={subPackage}
                  count={count}
                  cost={cost}
                  setCost={setCost}
                />
              </div>
              <div>
                <h2>비즈니스 정보 서비스</h2>
                <InfoChoice
                  infoPackage={dataPackage}
                  setTabChange={setTabChange}
                  cost={cost}
                  setCost={setCost}
                />
              </div>
            </div>
          </div>
          <div className="section_right">
            <div className="cost_wrapper">
              <div className="cost">
                <h2>구독 멤버십</h2>
                <SubsCost
                  storageCheckedData={storageCheckedData}
                  subPackage={subPackage}
                  count={count}
                  cost={cost}
                  packageCost={packageCost}
                />
              </div>
              <div className="cost">
                <h2>비즈니스 정보 서비스</h2>
                <InfoCost
                  infoPackage={dataPackage}
                  cost={cost}
                  packageCost={dataPackageCost}
                />
              </div>
              <div className="total_cost">
                <p>총 합(부가세 별도)</p>
                <p>{window.$Global.commaify(totalCost)} 원</p>
              </div>
            </div>
            <div className="button_wrapper" onClick={() => onClickPayment()}>
              <button>비즈내비 시작하기</button>
            </div>
            {/*<div*/}
            {/*  className="button_wrapper"*/}
            {/*  onClick={() => {*/}
            {/*    if (_.isEmpty(packageCheckedData)) {*/}
            {/*      alert("패키지를 선택해주세요");*/}
            {/*    } else {*/}
            {/*      history.push({*/}
            {/*        pathname: "/intro/estimate",*/}
            {/*        state: {*/}
            {/*          packageData: packageCheckedData,*/}
            {/*          optionData: storageCheckedData,*/}
            {/*        },*/}
            {/*      });*/}
            {/*    }*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <button>견적서 받기</button>*/}
            {/*</div>*/}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Price;
