import React from "react";
import { Link } from "react-router-dom";
import CommonFunc from "./common";
import Path from "./const/path";

function IntroMenu({ activeTab }) {
  return (
    <ul style={{ gridGap: 0, gap: 0 }}>
      {CommonFunc.DrawTab(Path.match_admin, activeTab, "active")}
    </ul>
  );
}

export default IntroMenu;
