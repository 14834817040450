import React, { useState } from "react";
import "./css/ResearchTechMyRequest.scss";
import { useHistory } from "react-router-dom";
import ResearchTechMySideBar from "./ResearchTechMySideBar";

function ResearchTechMyRequest() {
  const history = useHistory();
  const [isDone, setIsDone] = useState(false);

  return (
    <div id="ResearchTechRequestEdit">
      <ResearchTechMySideBar />
      {isDone ? (
        <div className="edit_wrapper">
          <h2>선행기술 조사 요청 완료!</h2>
          <p>감사합니다.</p>
          <button onClick={() => history.push("/researchTech/category")}>
            선행기술 조사 메인으로 돌아가기
          </button>
        </div>
      ) : (
        <div className="edit_wrapper">
          <h2>
            선행기술 조사 요청하기
            <br />
            <span>
              비즈내비에게 요청하는 선행기술 조사 키워드나 문장을 최대한 많이
              적어 주세요.
            </span>
          </h2>
          <div className="contents">
            <div className="field">
              <p>선행기술 명</p>
              <input
                type="text"
                placeholder="찾고자 하는 선행기술 명을 입력 해 주세요."
              />
            </div>

            <div className="field">
              <p>선행기술 키워드</p>
              <div className="inputs">
                <input type="text" placeholder="키워드를 입력해 주세요" />
                <input type="text" placeholder="키워드를 입력해 주세요" />
                <input type="text" placeholder="키워드를 입력해 주세요" />
                <input type="text" placeholder="키워드를 입력해 주세요" />
              </div>
            </div>

            <div className="field">
              <p>선택사항</p>
              <textarea placeholder="찾고자 하는 선행기술 분야에 대해 간략하게 작성해 주세요" />
            </div>
            <p className="edit_ex">ex) 자율주행, 드론, 등등 예시 문장</p>
          </div>

          <button className="btn_next" onClick={() => setIsDone(true)}>
            요청 하기
          </button>
        </div>
      )}
    </div>
  );
}

export default ResearchTechMyRequest;
