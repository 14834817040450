import { useEffect } from "react";
import "./css/WizardPop.scss";

const WizardPop = ({ setShowWizard }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  window.addEventListener("message", (event) => {
    //console.log("부모 페이지가 받은 메시지:", event.data);
    if (event.data === "finish") {
      setShowWizard(false);
    }
  });

  return (
    <div id="WizardPop">
      <iframe
        src="/g2works/main/wizardPage.do?siteFlag=biznavi"
        frameborder="0"
        title="그룹웨어 설정 마법사"
        style={{ width: "100%", height: "100%" }}
      ></iframe>
      <button className="close" onClick={() => setShowWizard(false)}>
        <i className="icon_exit_white"></i>
      </button>
    </div>
  );
};

export default WizardPop;
