import React, { useContext, useEffect, useState } from "react";
import "./css/PartList.scss";
import TaskTable from "../taskManage/organisms/TaskTable";
import Const from "../taskManage/const";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import CommonContext from "../../store/Common";
import MobilePopupContext from "../../store/MobilePopup";
import CommonAPI from "../../API/common";
import config from "../../const/config";
import qs from "query-string";
import Pagination from "../common/Pagination";
import PopupTaskDetail from "../taskManage/PopupTaskDetail";
import PopupMobileTaskDetail from "../taskManage/PopupMobileTaskDetail";
import MobilePopupAttach from "../common/MobilePopupAttach";
import memberContext from "../../store/Member";
import LoginPop from "../popup/LoginPop";

const PartList = () => {
  const history = useHistory();
  const location = useLocation();
  const { isMobile } = useContext(CommonContext);
  const MobilePopup = useContext(MobilePopupContext);
  const { keyword } = qs.parse(location.search);
  const [idx, setIdx] = useState(null);
  const [field, setField] = useState({}); // 조건 검색 필드 담는 변수
  const [list, setList] = useState({ items: [] });
  const [detailInfo, setDetailInfo] = useState({}); // 상세보기 팝업 데이터를 담는 변수
  const [popupShow, setPopupShow] = useState(false);
  const [moPopShow, setMoPopShow] = useState(false); // 모바일 상세보기 팝업 노출 여부
  const [moPopDownloadShow, setMoPopDownloadShow] = useState(false);
  const popTextDetail = "사업 상세페이지";
  const popTextDownload = "사업 다운로드";
  const popHash = "#pop";
  const [curPage, setCurPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [stateType, setStateType] = useState(Const.stateType.all); // 4 접수중, 2 접수예정
  const [titleName, setTitleName] = useState("");
  const [isLoginPopVisible, setIsLoginPopVisible] = useState(false);
  const memberInfo = useContext(memberContext);
  const auth = memberInfo.auth;
  const isLogin = auth != null && auth != undefined;

  useEffect(() => {
    CommonAPI.getTaskField(Const.showType.bizAll).then((res) =>
      setField(res.data)
    );
    getTitle();
  }, []);

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    if (location.hash !== popHash) {
      location.hash = "";
      setMoPopShow(false);
      MobilePopup.setMobilePopup({
        ...MobilePopup.mobilePopup,
        mobileShow: false,
        historyMobileHeaderTitle: "",
      });
    }
  }, [location]);

  useEffect(() => {
    // 헤더 close 이벤트 발생 시
    closeHeader();
  }, [MobilePopup.mobilePopup]);

  const getTitle = () => {

    if(keyword === "2") {
      setTitleName("R&D");
    } else if (keyword === "256") {
      setTitleName("창업");
    } else if (keyword === "8") {
      setTitleName("자금");
    } else if (keyword === "1024") {
      setTitleName("인프라");
    } else if (keyword === "64") {
      setTitleName("판로-수출");
    } else if (keyword === "512") {
      setTitleName("인력");
    } else if (keyword === "128") {
      setTitleName("기타");
    } else if (keyword === "2048") {
      setTitleName("바우처");
    }
  }

  const closeHeader = () => {
    if (!MobilePopup.mobilePopup.mobileShow) {
      if (moPopShow && moPopDownloadShow) {
        // 둘 다 true 일 경우 상세 페이지 팝업에서 사업 다운로드가 열린 것이므로 사업 다운로드 팝업 숨김 처리
        setMoPopDownloadShow(false);
        MobilePopup.setMobilePopup({
          ...MobilePopup.mobilePopup,
          mobileShow: true,
          mobileHeaderTitle: popTextDetail,
          historyMobileHeaderTitle: "",
        });
      } else {
        setMoPopShow(false);
        setMoPopDownloadShow(false);
      }
    }
  };

  const getList = (page, p_searchKeyword) => {
    let payload = {
      page: page || 1,
      keyword: p_searchKeyword || null,
      count: 50,
      //department_name: getDepartment(),
      sort: 0,
      select_start_at: new Date(2024, 0, 1).getTime() / 1000,
      state: 6,
      field_value: keyword
    };

    if (isSearch) {
      payload.keyword = searchKeyword;
    }

    CommonAPI.getTaskList(payload).then((res) => {
      // console.log(res.data);
      setList(res.data);
    });
  };

  const initList = () => {
    setCurPage(1);
    setIsSearch(false);
    setSearchKeyword("");
    let payload = { page: 1, count: 50, field_value: keyword };
    CommonAPI.getTaskList(payload).then((res) => setList(res.data));
  };

  const changePage = (page) => {
    getList(page);
    setCurPage(page);
  };

  const searchAPI = () => {
    setCurPage(1);
    setIsSearch(true);
    getList(1, searchKeyword);
  };

  const getDetail = async (idx) => {
    if(isLogin) {
      setIdx(idx);
      let result = await CommonAPI.getTaskInfo(idx, {
        headers: { "x-token": config.findbizFreeToken },
      }).then((res) => res.data);
      setDetailInfo(result);

      if (isMobile) {
        if (location.hash !== popHash) {
          history.push(popHash);
        }
        setMoPopShow(true);
        MobilePopup.setMobilePopup({
          ...MobilePopup.mobilePopup,
          mobileShow: true,
          mobileHeaderTitle: popTextDetail,
        });
      } else {
        setPopupShow(true);
      }
    } else {
      setIsLoginPopVisible(true);
    }
    
  };

  const showMoPopupFile = () => {
    setMoPopDownloadShow(true);

    let payload = {
      ...MobilePopup.mobilePopup,
      mobileShow: true,
      mobileHeaderTitle: popTextDownload,
    };

    if (moPopShow) {
      payload.historyMobileHeaderTitle = popTextDownload;
    }

    MobilePopup.setMobilePopup(payload);
  };

  const onClickFileDownload = (f_idx) => {
    CommonAPI.taskFileDownload(idx, f_idx);
  };

  const showLoginPop = () => {
    setIsLoginPopVisible(true);
  };

  const closeLoginPop = () => {
    setIsLoginPopVisible(false);
  };

  return (
    <div id="PartList">
      <div className="top_banner">
        <div>
          <h2>
            {titleName}
            <span>사업 총정리</span>
          </h2>
          <p>
          <>정부지원사업의 최신 정보를 모두 확인하세요.</>
          </p>
        </div>
        <img
          src={
            require("../../assets/images/intro/gov_work.png").default
          }
          className="banner_top"
          alt="탑 배너"
        />
      </div>
      <div className="index">
        <Link to="/">HOME</Link> &gt; 
        <Link to="/taskManage/page2">2025 지원사업 총정리</Link> &gt;
        <Link to={`/taskManage/part/list?keyword=${keyword}`}>{titleName} 사업 총정리</Link>
      </div>

      <div className="head">
        <h2>2024 공고 모아보기</h2>
        <div className="search_area">
          <input
            type="text"
            placeholder="검색어를 입력해주세요"
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            onKeyUp={(e) => e.key === "Enter" && searchAPI()}
          />
          {isSearch && (
            <button className="icon_del_small_with_bg" onClick={initList} />
          )}
        </div>
      </div>

      <div className="task_tbl_box">
        <TaskTable
          showType={Const.showType.bizOrganized}
          searchType={{}}
          stateType={stateType}
          field={field}
          list={list.items}
          onClickDetail={getDetail}
          setIsLoginPopVisible={setIsLoginPopVisible}
          isLogin={isLogin}
        />
      </div>

      <Pagination curPage={curPage} lastNum={list.last} onClick={changePage} />

      <PopupTaskDetail
        show={popupShow}
        item={detailInfo}
        field={field}
        onClose={() => setPopupShow(false)}
      />
      <PopupMobileTaskDetail
        show={moPopShow}
        field={field}
        item={detailInfo}
        showMoPopupFile={showMoPopupFile}
      />
      <MobilePopupAttach
        show={moPopDownloadShow}
        item={detailInfo.file}
        onDownload={onClickFileDownload}
      />

      <LoginPop isVisible={isLoginPopVisible} handleClose={closeLoginPop} />
    </div>
  );
}

export default PartList;