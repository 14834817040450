import React from "react";
import "./People.scss";

function People() {
    return (
        <div id="People">
            <h2 className="page_title">PEOPLE</h2>
            <div className="experts wrap">
                <h2>이사진</h2>
                <ul>
                    <li>
                        <img src={require('../../assets/images/people/img_cd.png').default} alt="황차동"/>
                        <h2>황차동</h2>
                        <p>CEO/기술거래사</p>
                    </li>
                    <li>
                        <img src={require('../../assets/images/people/img_jc.png').default} alt="임종철"/>
                        <h2>임종철</h2>
                        <p>
                            Executive Director<br/>
                            /전 한국산업은행 기술금융부 부부장
                        </p>
                    </li>
                    <li>
                        <img src={require('../../assets/images/people/img_cm.png').default} alt="김철모"/>
                        <h2>김철모</h2>
                        <p>
                            Executive Director<br/>
                            /전 발렉스 본부장(이사)
                        </p>
                    </li>
                    <li>
                        <img src={require('../../assets/images/people/img_dw.png').default} alt="신동원"/>
                        <h2>신동원</h2>
                        <p>
                            전 아이피밸류 파트너스<br/>
                            / NIPP CEO
                        </p>
                    </li>
                    <li>
                        <img src={require('../../assets/images/people/img_hj.png').default} alt="박항준"/>
                        <h2>박항준</h2>
                        <p>
                            기업 기술가치 평가사<br/>
                            / 국민대 겸임교수
                        </p>
                    </li>
                    <li>
                        <img src={require('../../assets/images/people/img_js.png').default} alt="이재성"/>
                        <h2>이재성</h2>
                        <p>전 엔씨소프트 전무</p>
                    </li>
                </ul>
            </div>
            <div className="outside_experts wrap">
                <h2>자문위원</h2>
                <ul>
                    <li>
                        <img src={require('../../assets/images/people/img_syh.png').default} alt="신용현"/>
                        <h2>신용현</h2>
                        <p>변리사</p>
                    </li>
                    <li>
                        <img src={require('../../assets/images/people/img_pjh.png').default} alt="박종하"/>
                        <h2>박종하</h2>
                        <p>변리사</p>
                    </li>
                    <li>
                        <img src={require('../../assets/images/people/img_kcd.png').default} alt="김창덕"/>
                        <h2>김창덕</h2>
                        <p>변리사</p>
                    </li>
                    <li>
                        <img src={require('../../assets/images/people/img_ys.png').default} alt="김용성"/>
                        <h2>김용성</h2>
                        <p>변호사</p>
                    </li>
                    <li>
                        <img src={require('../../assets/images/people/img_lyy.png').default} alt="이은욱"/>
                        <h2>이은욱</h2>
                        <p>변리사</p>
                    </li>
                    <li>
                        <img src={require('../../assets/images/people/img_hayashi.png').default} alt="Hayashi"/>
                        <h2>Hayashi</h2>
                        <p>변리사</p>
                    </li>
                </ul>
            </div>
        </div>
    )
};

export default People;