import PieGraph from "./common/PieGraph";
import { Link, useHistory } from "react-router-dom";
import { Bar, Line } from "react-chartjs-2";
import React, { useContext, useEffect, useState } from "react";
import UnEventContext from "../../store/UnEvent";
import MemberContext from "../../store/Member";
import CommonContext from "../../store/Common";
import CommonAPI from "../../API/common";
import DeadlineAPI from "../../API/deadline";
import CompanyAPI from "../../API/company";
import AccountAPI from "../../API/account";
import AnnualFeeChart from "../annualFee/AnnualFeeChart";
import PopupAnnualFeeCalc from "../annualFee/PopupAnnualFeeCalc";
import AnnualPayAPI from "../../API/annualPay";
import PopupGrade from "./PopupGrade";
import GradeCompany from "../grade/GradeCompany";
import PatentAPI from "../../API/patent";
import InvestorAPI from "../../API/investor";
import _ from "lodash";
import img from "../../assets/images/competitor/img-search.png";
import logo from "../../assets/images/common/logo_findBiz.svg";
import InfoCompanyAPI from "../../API/infoCompany";
import company from "../../API/company";

const HomeIpnowNew = ({
  data,
  data2,
  data3,
  data4,
  designData,
  options,
  options2,
  options3,
  patent_all_cnt,
  trademark_all_cnt,
  design_all_cnt,
  isEmptyPatentData,
  isEmptyTrademarkData,
  isEmptyDesignData,
}) => {
  const history = useHistory();
  const unEvent = useContext(UnEventContext);
  const { company_name, ipnow_permission, simple, name, company_idx, auth } =
    useContext(MemberContext);
  console.log(company_idx);
  const [deadlineList, setDeadlineList] = useState([]);
  const [companyGrade, setCompanyGrade] = useState("N");
  const [findXTotalCount, setFindXTotalCount] = useState(0);
  const [ipnowExpirationAt, setIpnowExpirationAt] = useState(0);
  const { isMobile } = useContext(CommonContext);
  const [option, setOption] = useState({});
  const [arr, setArr] = useState([]);
  const [popupShow, setPopupShow] = useState(false);
  const [totalAnnualFee, setTotalAnnualFee] = useState(0);
  const year = new Date().getFullYear();

  let initPayload = {
    start_year: year,
    end_year: year + 15,
  };

  const [resData, setResData] = useState({ patent: [] });
  const [gradeData, setGradeData] = useState({});
  const isCompany = window.$Global.checkAuth("특허회사", auth);
  const isInvestor = window.$Global.checkAuth("투자자", auth);

  window.localStorage.setItem("deadlineTab", true);
  window.localStorage.setItem("curPage", 1);
  window.localStorage.setItem("showPatent", true);

  useEffect(() => {
    if (isMobile) {
      CommonAPI.getTaskTotalCount().then((res) =>
        setFindXTotalCount(res.data.total_count)
      );

      if (ipnow_permission > 0 && !simple) {
        DeadlineAPI.getPatentDeadline({
          count: 4,
          page: 1,
          sort_type: 0,
        }).then((res) => setDeadlineList(res.data.items));
      }
    }
    CompanyAPI.getCompanyGrade().then((res) => setCompanyGrade(res.data.total));
    AccountAPI.getAccountInfo().then((res) =>
      setIpnowExpirationAt(res.data.ipnow_expiration_at)
    );
  }, []);

  useEffect(async () => {
    let payload = await CommonAPI.getOption(0).then((res) => {
      let option = res.data[0]?.option || {};
      setOption(option);
      return option;
    });

    payload = { ...initPayload, ...payload };

    await getAnnualPay(payload);

    if (window.$Global.isEmptyObject(payload)) {
      setPopupShow(true);
    }

    // 특허 등급 관련 API
    const companyCode = localStorage.getItem("company_code");

    console.log(companyCode);
    if (companyCode !== "null") {
      if (isCompany) {
        data = await PatentAPI.getGradePatent(companyCode).then(
          (res) => res.data
        );
      } else if (isInvestor) {
        data = await InvestorAPI.getCompanyGrade(companyCode).then(
          (res) => res.data
        );
      }
    }
    setResData(data);
    setGradeData({
      quality: data.quality,
      quantity: data.quantity,
      total: data.total,
    });
  }, []);

  const getAnnualPay = (payload) => {
    AnnualPayAPI.getAnnualPay(payload).then((res) => {
      let total = 0;
      setArr(res.data);
      res.data.slice(0, 10).map((item) => {
        total += item.cost;
      });
      setTotalAnnualFee(total);
    });
  };

  const onClickSuccess = (payload) => {
    payload = { ...initPayload, ...payload };
    let add_option = {
      type: 0,
      option: {
        ...payload,
      },
    };
    CommonAPI.addOption(add_option);
    getAnnualPay(payload);
    onClickClose();
  };

  const onClickClose = () => {
    setPopupShow(false);
  };

  const onClickDownload = (idx) => {
    if (isCompany) {
      PatentAPI.downloadRegisterFile(idx);
    } else if (isInvestor) {
      InvestorAPI.downloadRegisterFile(idx);
    }
  };

  let tbody = window.$Global.notTd(6, "등록된 특허가 없습니다.");

  if (resData?.patent?.length) {
    tbody = resData.patent.map((item, idx) => {
      return (
        <tr key={idx}>
          <td>{idx + 1}</td>
          <td>{item.nation}</td>
          <td>{item.register_number || "N/A"}</td>
          <td>
            <div className={`circle grade_${item.sm_grade || "default"}`}>
              {item.sm_grade || "N"}
            </div>
          </td>
          <td>{item.invention_title || item.invention_title_en || "N/A"}</td>
          <td>{window.$Global.convertDate(item.apply_at)}</td>
          <td>
            <button
              className={`icon_download ${
                item.register_file_cnt == 0 ? "disable" : ""
              }`}
              onClick={() => onClickDownload(item.idx)}
            />
          </td>
        </tr>
      );
    });
  }

  if (window.$Global.isEmptyObject(gradeData)) return null;

  const redirect = (year) => {
    history.push(`/annual/${year}/list`);
  };

  return (
    <div className="pc" style={{ maxWidth: "1280px", margin: "40px auto" }}>
      <div
        className="area_box shadow"
        style={{ maxWidth: "960px", margin: "0 auto" }}
      >
        <div className="title">
          <h2>실시간 IP 현황</h2>
          <h2 style={{ fontFamily: "Pretendard_extraBold" }}>
            IP Monetization Platform
          </h2>
        </div>
        <div className="menu">
          <div className="wrap_tab">
            <PieGraph
              title={"특 허"}
              to="/patent/list"
              data={data}
              options={options}
              cnt={patent_all_cnt}
              isEmptyData={isEmptyPatentData}
            />
            <PieGraph
              title={["상 표", "디 자 인"]}
              to={["/tradeMark/list", "/design/list"]}
              data={[data2, designData]}
              options={options}
              cnt={[trademark_all_cnt, design_all_cnt]}
              isEmptyData={[isEmptyTrademarkData, isEmptyDesignData]}
              dual={true}
            />
            <div className="inner_tab">
              <div className="tab_my_info tab_link">
                <h2>{company_name || name}</h2>
                {Boolean(ipnowExpirationAt) && (
                  <p>
                    <b>만료일</b>{" "}
                    {window.$Global.convertDate(ipnowExpirationAt)}
                  </p>
                )}
                <div className="btns">
                  <Link to="/manage/price" className="btn_mypage">
                    내 요금제 확인/결제
                  </Link>
                </div>
              </div>
              <div className="tab_un_event tab_link">
                <Link to="/unEvent">
                  <h2>알림</h2>
                  <p className="desc">단계별 진행사항 알림 제공</p>
                  <p className="count">
                    <span>{unEvent.unConfirmEventCnt}</span>건
                  </p>
                </Link>
              </div>
            </div>
          </div>
          <div className="wrap_tab">
            <div className="tab_payment tab_info">
              <div className="header">
                <h2>예상 연차료</h2>
                <Link to="/annual/graph" className="btn_link">
                  자세히보기
                </Link>
              </div>
              <p>(추천등급제공)</p>
              {data3.datasets[0].data.findIndex((item) => item > 0) > -1 ? (
                <div className="chart">
                  <Bar
                    data={data3}
                    width={160}
                    height={180}
                    options={options2}
                  />
                </div>
              ) : (
                <p className="no_data no_data_black">
                  현재 등록되어진 그래프가 없습니다.
                </p>
              )}
            </div>
            <div className="inner_tab">
              <div className="tab_count tab_info">
                <div className="header" style={{ marginBottom: 15 }}>
                  <h2>연도별 데이터</h2>
                  <Link to="/annualCount/graph" className="btn_link">
                    자세히보기
                  </Link>
                </div>
                {data4.datasets[0].data.findIndex((item) => item > 0) > -1 ||
                data4.datasets[1].data.findIndex((item) => item > 0) > -1 ? (
                  <div className="chart">
                    <Line
                      data={data4}
                      width={180}
                      height={190}
                      options={options3}
                    />
                  </div>
                ) : (
                  <p
                    className="no_data no_data_black"
                    style={{ paddingTop: 65 }}
                  >
                    현재 등록되어진 그래프가 없습니다.
                  </p>
                )}
              </div>
            </div>
            <div className="divide_tab">
              <div className="tab_date_manage">
                <Link to="/deadline/list">
                  <i className="icon_hourglass_small" />
                  <p>IP기한관리</p>
                  <button className="icon_next_black" />
                </Link>
              </div>
              <div className="tab_invention">
                <Link to="/invention/list">
                  <i className="icon_invention" />
                  <p>직무발명신고서</p>
                  <button className="icon_next_black" />
                </Link>
              </div>
              <div className="tab_invention">
                <Link to="/cost/list?nation=1">
                  <i className="icon_combined_shape" />
                  <p>비용 관리</p>
                  <button className="icon_next_black" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="item_box">
        <h2 className="item_box_title">{company_name} 연차료 현황</h2>
        <div className="item_box_annual_info">
          <p>
            {company_name}의<br />
            <span>향후 10년간 예상 연차료</span>는<br />
            <span>{window.$Global.commaify(totalAnnualFee)}</span>원 이며,
            절감이 필요합니다.
          </p>
        </div>
        <div id="ExpectationAnnual">
          <div className="header">
            <div className="info">
              <h2>
                “{localStorage.getItem("company_name")}”의 예상연차료 리스트
              </h2>
              {/*<p>*/}
              {/*  <i className="icon_badge_info_gray" />*/}
              {/*  <span>*/}
              {/*    연도를 선택해 각 연도별 연차료 지급 리스트를 확인할 수*/}
              {/*    있습니다.*/}
              {/*  </span>*/}
              {/*</p>*/}
            </div>
          </div>
          <div className="flex">
            <div className="annual_fee_button">
              <p>
                {year} ~ {year + 9}년
              </p>
              {/*<button className="btn_calc" onClick={() => setPopupShow(true)}>*/}
              {/*  연차료 조건 설정*/}
              {/*</button>*/}
            </div>
            <div className="legend">
              <div className="item">
                <div className="circle blue" />
                <p>특허</p>
              </div>
              <div className="item">
                <div className="circle main_color" />
                <p>상표</p>
              </div>
            </div>
          </div>
          <AnnualFeeChart
            history={history}
            redirect={redirect}
            arr_data={arr.slice(0, 10)}
          />
          {popupShow && (
            <PopupAnnualFeeCalc
              option={option}
              onClickSuccess={onClickSuccess}
              onClickClose={onClickClose}
            />
          )}
        </div>
      </div>

      <div className="item_box">
        <h2 className="item_box_title" style={{ marginTop: "40px" }}>
          {company_name} 특허등급 현황
        </h2>
        <div id="PopupGrade" className="custom_scroll">
          <GradeCompany data={gradeData} />

          <div className="wrap_table">
            <h2>{company_name} 등록특허 등급</h2>
            <div className="table-box-wrap">
              <div className="wrap_list" id="scrollableDiv">
                <table className="list">
                  <thead>
                    <tr>
                      <th>순번</th>
                      <th>국가</th>
                      <th>등록 번호</th>
                      <th>
                        SMART3
                        <br />
                        등급
                      </th>
                      <th>발명의 명칭</th>
                      <th>출원일자</th>
                      <th>특허 다운</th>
                    </tr>
                  </thead>
                  <tbody>{tbody}</tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="alert_msg">
            <i className="icon_badge_info" />
            <p>
              등록된 특허만 등급을 주므로, 미등록된 특허는 리스트에서
              제외되었습니다.
            </p>
          </div>
        </div>
      </div>

      <div className="item_box">
        <h2 className="item_box_title">{company_name} 특허 연관기업 리스트</h2>
        <div className="company_info">
          <div className="company_info_items">
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>(주)유메딕스</p>
                <p>웨어러블 패치 형태의 심전도 모니터링 기기를 개발하는 기업</p>
                <div className="item_text_category">
                  <p>데이터</p>
                  <p>B2B</p>
                  <p>플랫폼</p>
                </div>
              </div>
              <button>기업 분석정보</button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>(주)유메딕스</p>
                <p>웨어러블 패치 형태의 심전도 모니터링 기기를 개발하는 기업</p>
                <div className="item_text_category">
                  <p>데이터</p>
                  <p>B2B</p>
                  <p>플랫폼</p>
                </div>
              </div>
              <button>기업 분석정보</button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>(주)유메딕스</p>
                <p>웨어러블 패치 형태의 심전도 모니터링 기기를 개발하는 기업</p>
                <div className="item_text_category">
                  <p>데이터</p>
                  <p>B2B</p>
                  <p>플랫폼</p>
                </div>
              </div>
              <button>기업 분석정보</button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>(주)유메딕스</p>
                <p>웨어러블 패치 형태의 심전도 모니터링 기기를 개발하는 기업</p>
                <div className="item_text_category">
                  <p>데이터</p>
                  <p>B2B</p>
                  <p>플랫폼</p>
                </div>
              </div>
              <button>기업 분석정보</button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>(주)유메딕스</p>
                <p>웨어러블 패치 형태의 심전도 모니터링 기기를 개발하는 기업</p>
                <div className="item_text_category">
                  <p>데이터</p>
                  <p>B2B</p>
                  <p>플랫폼</p>
                </div>
              </div>
              <button>기업 분석정보</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeIpnowNew;
