import React from "react";
import "./css/ResearchTechSiteMap.scss";
import { useHistory } from "react-router-dom";

const ResearchTechSiteMap = () => {
  const history = useHistory();
  return (
    <div id="ResearchTechSiteMap">
      <button
        className="btn_go_my"
        onClick={() => history.push("/researchTech/my/list")}
      >
        나의 선행기술 페이지
        <i className="icon_copy" />
      </button>
      <button
        className="btn_go_request"
        onClick={() => history.push("/researchTech/my/request")}
      >
        선행기술 작성 요청
        <i className="icon_write" />
      </button>
    </div>
  );
};

export default ResearchTechSiteMap;
