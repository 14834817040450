const EstimatePopUpRequire = ({ setPopUpShow }) => {
  return (
    <div>
      <i className="icon_exit_gray" onClick={() => setPopUpShow(false)} />
      <h2>개인 정보 수집 및 이용 동의</h2>
      <p>
        수집하는 개인정보의 항목, 개인정보의 보유 및 이용기간을 안내드립니다.
      </p>
      <p>
        귀하는 개인정보 수집 및 이용을 거부할 수 있으나, 거부 시 상담을 받으실
        수 없습니다.
        <br />
        상담 내용은 원활한 상담을 위하여 보관되며, 개인정보처리방침에 따라
        처리됩니다.
      </p>
      <table>
        <thead>
          <tr>
            <th>수집/이용 목적</th>
            <th>항목</th>
            <th>기간</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              상담 접수 및 처리
              <br />
              처리 내역 보관
              <br />
              중복 상담 확인
            </td>
            <td>회사명, 직원 수, 담당자명, 연락처, 이메일</td>
            <td>수집 및 이용 동의일로부터 1년까지 (요청시 삭제)</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default EstimatePopUpRequire;
