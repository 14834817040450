/*
 * 선택 서비스 및 옵션 구독 멤버쉽 쪽 정보
 * */

import { useEffect, useState } from "react";
import "./css/SubsChoice.scss";
import { period, storage } from "./data";

const SubsChoice = ({
  storageData,
  storageCheckedData,
  setStorageCheckedData,
  subPackage,
  count,
  cost,
  setCost,
}) => {
  const [data, setData] = useState({});

  useEffect(() => {
    subPackage.map((item) => {
      if (item.isChecked) {
        setData(item);
      }
    });
  }, [subPackage]);

  return data.isChecked ? (
    <div id="SubsChoice">
      <div className="header">
        <h2>{data.title}</h2>
        <p>{count} 명</p>
      </div>
      <div className="section">
        {data.id === "free" ? (
          <></>
        ) : (
          // <p className="free_text">
          //   한 달간 모든 그룹웨어·협업툴 서비스를
          //   <br />
          //   체험해 볼 수 있습니다
          // </p>
          <div className="section_items">
            <div>
              <p>사용기간</p>
              <select
                onChange={(e) => {
                  setCost({ ...cost, subsPeriod: Number(e.target.value) });
                }}
              >
                {period.map((item) => {
                  return <option value={item.value}>{item.name}</option>;
                })}
              </select>
            </div>
            <div>
              <p>기본제공 용량</p>
              <p>{count}GB</p>
            </div>
            {data.isStorageChecked ? (
              <div>
                <p>추가 용량</p>
                <select
                  value={storageCheckedData.product_option_idx}
                  onChange={(e) => {
                    if (e.target.value !== 0) {
                      let data = storageData.filter((item) => {
                        return item.product_option_idx === e.target.value;
                      })[0];

                      setStorageCheckedData(data);
                      setCost({ ...cost, storage: Number(e.target.value) });
                    }
                  }}
                >
                  <option value={0}>용량 선택</option>
                  {storageData.map((item) => {
                    return (
                      <option value={item.product_option_idx}>
                        {item.product_option_nm}
                      </option>
                    );
                  })}
                </select>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  ) : null;
};

export default SubsChoice;
