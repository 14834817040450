import GroupwareSideBar from "./GroupwareSideBar";
import { groupWareWorksPath } from "./groupwarePath";
import React, { useEffect, useRef, useState } from "react";
import "./css/GroupwarePims.scss";
import Moment from "react-moment";
import "moment/locale/ko";
import { Bar, Doughnut } from "react-chartjs-2";
import config from "../../const/config";
import { useLocation } from "react-router-dom"
import util from "../../util/util";

let mailParam = "";

const resizeIframe = (ifrIdName) => {
  
  var the_height = document.getElementById(ifrIdName).contentWindow.document.body.scrollHeight;
  document.getElementById(ifrIdName).height = the_height;
  
};
const loadLeftPage = (ifrIdName) => {
  var iframeWindow = document.getElementById(ifrIdName).contentWindow;
  iframeWindow.getLeftMenu("100015");
};
const loadMailPage = (ifrIdName) => {
  document.getElementById(ifrIdName).contentWindow.moveMenu('/mail/mailLeftMenu.do' + mailParam, 'mailIcn');
  resizeIframe("contentsWrap")
};

export const GroupwareAdminMain = () => {
  const [adminMenu, setAdminMenu] = useState([]);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  var sideBarSection = !params.get("sideBarSection") ? "approval" : params.get("sideBarSection");
  var moveUrl = !params.get("moveUrl") ? "doc" : params.get("moveUrl");
  var command = !params.get("command") ? "doc" : params.get("command");
  var suMenuCd = !params.get("suMenuCd") ? "doc" : params.get("suMenuCd");
  var menuCd = !params.get("menuCd") ? "doc" : params.get("menuCd");
  var menuTitle = !params.get("menuTitle") ? "관리자" : params.get("menuTitle");
  let moveSrc = moveUrl + "?command=" + command;
  //let id = !params.get('id') ? "?siteFlag=biznavi" : "?id=" + params.get('id') + "&siteFlag=biznavi";
  //let param = id;
  moveSrc = config.gwUrl + "/" + moveSrc  + "&menuCd=" + menuCd + "&siteFlag=biznavi";
  useEffect(() => {
    getAdminMenu();
   }, []); 
   
   const getAdminMenu = () => {
     let param = {
       su_menu_cd: suMenuCd,
       menuTitle : menuTitle,
       isContentWrap : true
     };
     util.getGroupwareAdminMenu(setAdminMenu, param);
     //setAdminMenu(util.getGroupwareAdminMenu(adminMenu));
   }
  return (
    <div id="GroupwareWorks">
      <GroupwareSideBar currentPath={groupWareWorksPath[sideBarSection]} adminMenu={adminMenu} sidebar={sideBarSection}/>
      <div className="commute_wrapper">
        <iframe
            id="contentWrap"
            src={moveSrc}
            width="100%"
            style={{minHeight:"895px"}}
            onLoad={() => {
              resizeIframe("contentWrap");
            }}
          />
        </div>
    </div>
  );
};

export const _GroupwareMailWorks = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let url = !params.get('mailUrl') ? "/g2works/main/leftPage.do" : params.get('mailUrl');
  mailParam = url;
  return (
    <div id="GroupwarePims">
      <div id="GroupwareSideBar" style={{padding:"0px"}}>
      <iframe
            id="leftmenuWrap"
            name="leftframe"
            // src="/mail/mailLeftMenu.do"
            src={`${url}`}
            width="100%"
            height="100%"
            onLoad={() => {
              loadMailPage("leftmenuWrap");
              resizeIframe("leftmenuWrap");
            }}
          />
      </div>
      <div className="commute_wrapper">
        <iframe
            id="contentsWrap"
            name="bodyframe"
            src=""
            width="100%"
            height="100%"
            //style={{minHeight:"895px"}}
            onLoad={() => {
              //resizeIframe("contentsWrap");
            }}
          />
        </div>
    </div>
  );
};

export const GroupwareMailWorks = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let url = !params.get('mailUrl') ? "/mail/mailLeftMenu.do" : params.get('mailUrl');
  if(url.indexOf("?") > -1){
    url = url + "&siteFlag=biznavi"
  }else{
    url = url + "?siteFlag=biznavi";
  }
  mailParam = url;
  //let url2 = "/mail/mailLeftMenu.do?url=/mail/mailView.do?mailBox=INBOX&uid=3";
  return (
    <div id="GroupwarePims">
      <div id="GroupwareSideBar" style={{padding:"0px"}}>
      <iframe
            id="leftmenuWrap"
            name="leftframe"
            src={`${url}`}
            width="300"
            height="897px"
            onLoad={() => {
              //resizeIframe("leftmenuWrap");
            }}
          />
      </div>
      <div className="commute_wrapper">
        <iframe
            id="contentsWrap"
            name="bodyframe"
            src=""
            width="100%"
            height="897px"
            //onLoad={() => { resizeIframe("contentsWrap"); }}
          />
        </div>
    </div>
  );
};

export const GroupwareCloudWorks = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let id = !params.get('id') ? "?siteFlag='biznavi'" : "?id=" + params.get('id')+"&siteFlag='biznavi'";
  let param = id;
  return (
    <div id="GroupwarePims" style={{height: "900px"}}>
      {/* <div id="WebhardSideBar" style={{padding:"0px"}}> */}
      <iframe
            id="leftmenuWrap"
            name="leftframe"
            src={`${config.gwUrl}/webfolder/webfolderLeftMenu.do${param}`}
            width="100%"
            height="100%"
            onLoad={() => {
              //resizeIframe("leftmenuWrap");
            }}
          />
      {/* </div> */}
    </div>
  );
};
