import React, { useContext, useRef, useState } from "react";
import "./css/MarketInfoMainCart.scss";
import memberContext from "../../store/Member";
import { Link, useHistory, useLocation } from "react-router-dom";
import MarketInfoCartItem from "./MarketInfoCartItem";

function MarketInfoMainCart({
  cartArr,
  showPopup,
  onClickImageDelete,
  onChangeFile,
  onClickCheck,
  onClickMoPopupClose,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const memberInfo = useContext(memberContext);
  const mi_cart_max_cnt = memberInfo.mi_cart_max_cnt || 0;
  const fileRef = useRef();
  const history = useHistory();
  const location = useLocation();
  //console.log(cartArr);
  return (
    <div id="MarketInfoMainCart" className="MarketCart pc">
      {isOpen ? (
        <div
          className="MarketCart_header after_open"
          onClick={() => setIsOpen(false)}
        >
          <h2>
            내가 담은 정보({cartArr.length})개
            <i className="icon_badge_info_gray" />
            <span> 최대 8개까지 담을 수 있습니다.</span>
          </h2>
          <i className="icon_more_arrow_down" />
        </div>
      ) : (
        <div
          className="MarketCart_header before_open"
          onClick={() => setIsOpen(true)}
        >
          <h2>
            내가 담은 정보({cartArr.length})개
            <i className="icon_badge_info_white" />
            <span> 최대 8개까지 담을 수 있습니다.</span>
          </h2>
          <i className="icon_more_arrow_up_white" />
        </div>
      )}
      {isOpen && (
        <>
          <div className="MarketCart_body">
            <ul className="custom_scroll">
              {cartArr.map((item) => {
                const notUserUploadImage = !item.title; // 유저가 업로드한 이미지가 아닌 경우 참
                return (
                  <MarketInfoCartItem
                    key={item.idx}
                    item={item}
                    hideCheckBox={notUserUploadImage}
                    checked={true}
                    showPopup={() => showPopup(item, "graph")}
                    onClickImageDelete={onClickImageDelete}
                    onClickCheck={onClickCheck}
                  />
                );
              })}
            </ul>
          </div>
          <div className="MarketCart_footer">
            <button
              onClick={() => fileRef.current.click()}
              className="upload_btn"
            >
              내 파일 업로드하기
            </button>
            <input
              type="file"
              ref={fileRef}
              accept="image/*"
              hidden
              onChange={(e) => onChangeFile(e.target.files[0])}
            />
            <button
              onClick={() => history.push(`/marketInfo/edit${location.search}`)}
              className="go_btn"
            >
              시장정보 작성하러 가기
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default MarketInfoMainCart;
