import React, { useContext, useEffect, useState } from "react";
import "./css/MarketInfoKeywordSearch.scss";
import CommonAPI from "../../API/common";
import MarketInfoMainItem from "./MarketInfoMainItem";
import message from "../../const/message";
import { useRecoilState } from "recoil";
import { marketInfoCartArrayLength } from "../../atom";
import CommonContext from "../../store/Common";
import memberContext from "../../store/Member";
import { useHistory } from "react-router-dom";
import MarketInfoMainCart from "./MarketInfoMainCart";
import config from "../../const/config";
import Pagination from "../common/Pagination";
import MarketInfoPopupDetail from "./MarketInfoPopupDetail";

const MarketInfoKeywordSearch = ({ history, location }) => {
  const { isMobile } = useContext(CommonContext);
  const memberInfo = useContext(memberContext);
  const isLogin = Boolean(memberInfo.idx);
  const isAdmin = memberInfo.admin; // 슈퍼 계정인지 판별
  const mi_cart_max_cnt = memberInfo.mi_cart_max_cnt || 0;
  const [searchInput, setSearchInput] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [count, setCount] = useState(16);
  const [curPage, setCurPage] = useState(1);
  const [last, setLast] = useState(1);
  const [marketInfoList, setMarketInfoList] = useState({});
  const [datalist, setDatalist] = useState([]);
  const [cart, setCart] = useState([]);
  const [cartArrLength, setCartArrLength] = useRecoilState(
    marketInfoCartArrayLength
  );
  const [popupType, setPopupType] = useState("graph"); // graph, content
  const [popupInfo, setPopupInfo] = useState({});
  const [popupShow, setPopupShow] = useState(false);

  useEffect(() => {
    onClickKeywordSearch();
  }, [curPage]);

  useEffect(() => {
    getCartList();
  }, []);

  const onClickSearchCancel = () => {
    setSearchInput("");
  };

  const onClickKeywordSearch = () => {
    setSearchKeyword(searchInput);
    let payload = {
      ...(searchInput !== "" && { keyword: searchInput }),
      count: count,
      page: curPage,
    };
    CommonAPI.getMarketList(payload).then((res) => {
      setMarketInfoList(res.data);
      setDatalist(res.data.items);
      setLast(res.data.last);
    });
  };

  const onClickImageDelete = (idx) => {
    CommonAPI.deleteMarketImage(idx).then(() => getCartList());
  };

  const onChangeFile = (files) => {
    if (!config.imgTypeCheck.test(files.name)) {
      alert("이미지 파일만 업로드할 수 있습니다");
      return;
    }

    let formData = new FormData();
    formData.append("image", files);

    CommonAPI.addMarketImage(formData).then(() => {
      getCartList();
    });
  };

  const getCartList = () => {
    CommonAPI.getMarketCartList().then((res) => {
      let data = res.data;
      CommonAPI.getMarketImageList().then((res2) => {
        res2.data.map((item) => {
          item.type = 1;
        });
        let copy = data.concat(res2.data);
        copy.sort((a, b) => b.created_at - a.created_at);
        setCart(copy);
        setCartArrLength(copy.length);
      });
    });
  };

  const onClickCheck = async (e, obj) => {
    if (e.target.checked) {
      if (cart.length == mi_cart_max_cnt) {
        if (isLogin) {
          alert(`최대 ${mi_cart_max_cnt}개까지만 담을 수 있습니다`);
        } else {
          alert(message.requireLogin);
          history.push("/login");
        }
        return;
      }
      await CommonAPI.addMarketCart(obj.idx).then(() => {
        alert("장바구니에 추가되었습니다.");
      });
    } else {
      if (obj.type === 1) {
        let cart_idx = cart.find(
          (item) => (item.mi_idx || item.idx) == (obj.mi_idx || obj.idx)
        ).idx;
        await CommonAPI.deleteMarketImage(cart_idx);
      } else {
        let cart_idx = cart.find(
          (item) => (item.mi_idx || item.idx) == (obj.mi_idx || obj.idx)
        ).idx;
        await CommonAPI.removeMarketCart(cart_idx);
      }
    }
    getCartList();
  };

  const onClickShowPopup = (item, type) => {
    let isGraph = type == "graph";
    let isContent = type == "content";
    let hasContent = Boolean(item.content || item.content_image_list.length);

    if (isMobile) {
      let imageWin = window.open("", "_blank");
      imageWin.document.write(
        `<html><body style='margin:0; text-align: center;'><style type="text/css">img {max-width: 1100px;}</style>`
      );

      if (isGraph) {
        imageWin.document.write(
          `<img src="${window.$Global.getCDN(
            item.image_key
          )}" alt="그래프 이미지"/>`
        );
      } else if (isContent) {
        if (hasContent) {
          if (item.content) {
            imageWin.document.write(`<p>${item.content}</p>`);
          } else {
            imageWin.document.write(
              `<img src="${window.$Global.getCDN(
                item.content_image_list[0].content_key
              )}" alt="그래프 설명 이미지"/>`
            );
          }
        }
      }
      imageWin.document.write("</body><html>");
    } else {
      setPopupShow(true);
      setPopupInfo(item);
      setPopupType(type);
    }
  };

  const downloadReport = () => {
    if (searchKeyword) {
      CommonAPI.downloadMarketReport({ keyword: searchKeyword });
    } else {
      alert("키워드를 입력해주세요.");
      return;
    }
  };
  return (
    <div id="MarketInfoKeywordSearch">
      <div className="input_wrapper">
        <p>키워드 검색</p>
        <div className="search">
          <input
            type="text"
            placeholder="검색어를 입력하세요"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyUp={(e) => e.key === "Enter" && onClickKeywordSearch()}
          />
          {searchInput.length > 0 ? (
            <button
              className="icon_del_small_with_bg"
              onClick={onClickSearchCancel}
            />
          ) : (
            <button
              className="icon_search_gray ir_txt"
              onClick={onClickKeywordSearch}
            >
              검색
            </button>
          )}
        </div>
        <button
          className="btn_marketinfo_report"
          onClick={() => {
            isAdmin
              ? downloadReport()
              : alert("요금제 결제 후 사용가능한 기능입니다.");
          }}
        >
          <i className="icon_download_attached" />
          시장정보 보고서 다운로드
        </button>
      </div>
      <div className="marketInfo_list">
        {marketInfoList?.items?.length ? (
          datalist.map((item) => (
            <MarketInfoMainItem
              key={item.idx}
              item={item}
              cartArr={cart}
              showPopup={onClickShowPopup}
              onClickCheck={onClickCheck}
            />
          ))
        ) : (
          <p>조회된 데이터가 없습니다</p>
        )}
      </div>
      <Pagination curPage={curPage} lastNum={last} onClick={setCurPage} />
      <MarketInfoPopupDetail
        show={popupShow}
        type={popupType}
        item={popupInfo}
        onClose={() => setPopupShow(false)}
      />
      <MarketInfoMainCart
        location={location}
        cartArr={cart}
        showPopup={onClickShowPopup}
        onClickImageDelete={onClickImageDelete}
        onChangeFile={onChangeFile}
        onClickCheck={onClickCheck}
      />
    </div>
  );
};

export default MarketInfoKeywordSearch;
