import React from 'react';
import DatePicker from "react-datepicker";
import ToggleButtonWithSpan from "../../common/ToggleButtonWithSpan";

function ToggleButtonAndDatePickerRow({title, date, condition, selected, toggleSelected, onChangeDatePicker}) {
    return (
        <div className="row">
            <h2 className="title">{title}</h2>
            <div className="btns">
                {
                    condition ?
                        Boolean(selected) &&
                        <>
                            <span>{date ? window.$Global.convertDate(date) : 'N/A'} </span>
                            <DatePicker selected={date} onChange={onChangeDatePicker}
                                        customInput={<button className="icon_calendar"/>}
                                        dayClassName={day => window.$Global.setWeekendColor(day)}
                            />
                        </>
                        : <span>{Boolean(selected) ? (date ? window.$Global.convertDate(date) : 'N/A') : "무"} </span>
                }
                {
                    condition && <ToggleButtonWithSpan text="" toggleSelected={toggleSelected} condition={{on: "", off: "무"}} selected={selected}/>
                }
            </div>
        </div>
    );
}

export default ToggleButtonAndDatePickerRow;
