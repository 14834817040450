import CustomSwitch from "./common/CustomSwitch";
import UserAuthRoute from "./common/UserAuthRoute";
import CashflowIntro from "../components/groupware/cashflow/CashflowIntro";
import CashflowFind from "../components/groupware/cashflow/CashflowFind";
import BalanceIns from "../components/groupware/cashflow/business_plan/BalanceIns";
import BizAct from "../components/groupware/cashflow/business_plan/BizAct";
import CashflowList from "../components/groupware/cashflow/business_plan/CashflowList";
import CashflowReport from "../components/groupware/cashflow/business_plan/CashflowReport";
import CashflowHistory from "../components/groupware/cashflow/business_plan/CashflowHistory";

const CashflowRoute = ({ match }) => {
  const token = window.$Global.getToken();
  const auth = token != null;

  return (
    <CustomSwitch>
      <UserAuthRoute
        exact
        path={`${match.path}/intro`}
        requireAuth={true}
        component={CashflowIntro}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/find/:type`}
        requireAuth={true}
        component={CashflowFind}
      />

      {/* 사업계획서용 */}
      <UserAuthRoute
        exact
        path={`${match.path}/business/balanceIns`}
        auth={auth}
        component={BalanceIns}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/business/bizAct`}
        auth={auth}
        component={BizAct}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/business/list`}
        auth={auth}
        component={CashflowList}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/business/view`}
        auth={auth}
        component={CashflowReport}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/business/history`}
        auth={auth}
        component={CashflowHistory}
      />
    </CustomSwitch>
  );
};

export default CashflowRoute;
