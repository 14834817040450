import React, { useContext, useEffect, useState } from "react";
import "./css/MenuTab.scss";
import MemberContext from "../../store/Member";
import IntroMenu from "./IntroMenu";
import AdminMenu from "./AdminMenu";
import Exception from "../../util/exception";
import Path from "./const/path";
import CommonFunc from "./common/index";
import { useLocation } from "react-router-dom";
import { groupwareAdmin, tabNotice } from "../../atom";
import { useRecoilValue } from "recoil";
import axios from "axios";
import config from "../../const/config";
import CommonContext from "../../store/Common";

function MenuTab({ history }) {
  const { auth, admin } = useContext(MemberContext);
  const { isMobile } = useContext(CommonContext);
  const location = useLocation();
  const isLogin = auth != null;
  const isInvestor = window.$Global.checkAuth("투자자", auth);
  const isAdmin = admin; // 슈퍼 계정인지 판별
  //const [isAdmin, setIsAdmin] = useState(false); // 슈퍼 계정인지 판별
  const isViewTypeIpnow = window.$Global.isIpnow();
  const pathname = history.location.pathname.toString();
  const isIntroPath = Exception.exceptionIntro(pathname);
  const isCalcPath = pathname.indexOf("calc") > -1;
  const isExceptionURL =
    Exception.exceptionRoot(pathname) || (isIntroPath && isLogin) || isCalcPath;
  //const gwAdmin = useRecoilValue(groupwareAdmin);
  const [gwAdmin, setGwAdmin] = useState(false);
  // MenuTab에 메일, 전자결재 New 표시를 위한 전역변수
  const noticeCount = useRecoilValue(tabNotice);

  let activeTab = 0;

  const getActiveTab = (path) => {
    return window.location.href.indexOf(window.location.host + path) > -1;
  };

  const isIntro = getActiveTab("/intro/");
  const isOpenMarket = getActiveTab("/trade/");
  /* 기존 메뉴 */
  const isFindx =
    getActiveTab("/docTrade/") ||
    getActiveTab("/consortium/") ||
    getActiveTab("/consultCom/") ||
    getActiveTab("/matchingBiz/");
  /* 리뉴얼 메뉴 */
  const isRenewalGov =
    getActiveTab("/business/") ||
    getActiveTab("/govSupport/") ||
    getActiveTab("/businessDescription/") ||
    getActiveTab("/exposition/") ||
    getActiveTab("/taskManage/");

  const isRenewalMarket = getActiveTab("/marketInfo/");

  const isRenewalResearchTech = getActiveTab("/researchTech/");

  const isRenewalGroupware = getActiveTab("/groupware/");

  const isRenewalCompanyInfo = getActiveTab("/competitor/");

  const isMyPage = getActiveTab("/mypage/");

  /* BIZNAVI IP관리에서 사용하는 MenuTab */
  const isIpManage =
    getActiveTab("/ipHome") ||
    getActiveTab("/patent/") ||
    getActiveTab("/tradeMark/") ||
    getActiveTab("/annual/") ||
    getActiveTab("/annualCount/");

  const findActiveTab = (obj, idx) => {
    let keys = Object.keys(obj);
    let condition = false;
    keys.forEach((key) => {
      if (key.startsWith("url")) {
        condition = getActiveTab(obj[key]);
      }
    });

    if (condition) activeTab = idx + 1;
  };

  useEffect(() => {
    let url = config.gwUrl;
    if (isLogin) {
      axios
        .get(url + "/bizoffi-api/member/userInfo.json", {
          headers: {
            "g-token": localStorage.getItem(config.gwKeyToken),
          },
        })
        .then((res) => {
          if (res.data.result == "fail") {
            window.$Global.logout("self");
          } else {
            setGwAdmin(res.data.userInfo?.gwAdmin);
          }
        });
    }
  }, []);

  if (isAdmin) {
    Path.match_admin.map((item, idx) => findActiveTab(item, idx));
  } else if ((isIntro || !isLogin) && !isMobile) {
    console.log(isMobile);
    Path.match_intro.map((item, idx) => findActiveTab(item, idx));
  } else if (isOpenMarket) {
    Path.match_openMarket.map((item, idx) => findActiveTab(item, idx));
  } else if (isFindx) {
    Path.match_findx.map((item, idx) => findActiveTab(item, idx));
  } else if (isRenewalGov) {
    Path.match_renewal_gov.map((item, idx) => findActiveTab(item, idx));
  } else if (isRenewalMarket) {
    Path.match_renewal_market.map((item, idx) => findActiveTab(item, idx));
  } else if (isRenewalResearchTech || isMyPage) {
    Path.match_empty_menu.map((item, idx) => findActiveTab(item, idx));
  } else if (isRenewalGroupware) {
    Path.match_renewal_groupware.map((item, idx) => findActiveTab(item, idx));
  } else if (isRenewalCompanyInfo) {
    Path.match_company_info.map((item, idx) => findActiveTab(item, idx));
  } else {
    Path.match_ip_service_login.map((item, idx) => findActiveTab(item, idx));
  }

  return (
    !isMobile && (
      <div id="MenuTab">
        <div className="wrap">
          {isOpenMarket ? (
            <ul>
              {CommonFunc.DrawTab(
                Path.match_openMarket,
                activeTab,
                "bold",
                location
              )}
            </ul>
          ) : isFindx && isLogin ? (
            <ul>
              {CommonFunc.DrawTab(
                Path.match_findx,
                activeTab,
                "bold",
                location
              )}
            </ul>
          ) : isRenewalGov && isLogin ? (
            <ul className="tab_list gov">
              {CommonFunc.DrawTab(
                Path.match_renewal_gov,
                activeTab,
                "bold",
                location
              )}
            </ul>
          ) : isRenewalMarket && isLogin ? (
            <ul>
              {CommonFunc.DrawTab(
                Path.match_renewal_market,
                activeTab,
                "bold",
                location
              )}
            </ul>
          ) : (isRenewalResearchTech || isMyPage) && isLogin ? (
            <ul>
              {CommonFunc.DrawTab(
                Path.match_empty_menu,
                activeTab,
                "bold",
                location
              )}
            </ul>
          ) : isRenewalGroupware && isLogin ? (
            <ul className="3">
              {CommonFunc.DrawTab(
                Path.match_renewal_groupware,
                activeTab,
                "bold",
                location,
                gwAdmin,
                noticeCount
              )}
            </ul>
          ) : isRenewalCompanyInfo && isLogin ? (
            <ul>
              {CommonFunc.DrawTab(
                Path.match_company_info,
                activeTab,
                "bold",
                location
              )}
            </ul>
          ) : !isInvestor &&
            !isIntroPath &&
            isLogin &&
            !isExceptionURL &&
            !isMyPage ? (
            <ul>
              {CommonFunc.DrawTab(
                Path.match_ip_service,
                activeTab,
                "bold",
                location
              )}
            </ul>
          ) : (
            !isAdmin &&
            !isInvestor && (
              <IntroMenu
                activeTab={activeTab}
                auth={auth}
                location={location}
                isExceptionURL={isExceptionURL}
              />
            )
          )}
          {isExceptionURL && isAdmin && (
            <IntroMenu
              activeTab={activeTab}
              auth={auth}
              location={location}
              isExceptionURL={isExceptionURL}
            />
          )}
          {isAdmin && !isExceptionURL && <AdminMenu activeTab={activeTab} />}
        </div>
      </div>
    )
  );
}

export default MenuTab;
