import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import GroupwareSideBar from "../GroupwareSideBar";
import { groupCashflow } from "../groupwarePath";

import "./scss/CashflowIntro.scss";

const CashflowIntro = () => {
  const history = useHistory();
  const links = [
    {
      title: "투자사 컨택하러 가기",
      text: "(주)아이피나우는 5개월 내로 투자유치가 필요할 것으로 예측됩니다.",
      link: "/cashflow/find/investor",
    },
    {
      title: "IP담보대출 준비하러 가기",
      text: "(주)아이피나우는 4개월 내로 대출이 필요할 것으로 예측됩니다.",
      link: "/mortgageLoan/list",
    },
    {
      title: "대출은행 알아보기",
      text: "(주)아이피나우는 4개월 내로 대출이 필요할 것으로 예측됩니다.",
      link: "/cashflow/find/bank",
    },
    {
      title: "상표 판매하기",
      text: "(주)아이피나우는 5개월 내로 현금확보가 필요할 것으로 예측됩니다.",
      link: "/trade/list",
    },
  ];
  return (
    <div id="GroupwareWorks">
      <GroupwareSideBar currentPath={groupCashflow} />
      <div className="commute_wrapper">
        <div id="CashflowIntro">
          <div className="top">
            <strong className="title">
              비즈내비로 가장 쉽게
              <br />
              기업 CASH 현황파악부터 자금조달 방법까지 확인하세요
            </strong>
            <button
              className="start"
              onClick={() => history.push("/groupware/cashflow/balanceIns")}
            >
              지금 시작하기
            </button>
          </div>
          <div className="caculator">
            <div className="img">
              <img
                src={
                  require("../../../assets/images/cashflow/runway.png").default
                }
                alt="runway"
              />
            </div>
            <div className="desc">
              <strong className="cal_tit">
                BIZNAVI
                <br />
                RUNWAY 계산기
              </strong>
              <p>대표님 필수업무 캐시플로우 계산!</p>
              <p>
                간단한 5단계 STEP을 통해
                <br />
                우리 회사의 <strong className="highlight">CASHFLOW현황</strong>
                과<br />
                추후 계획에 따른
                <strong className="highlight">RUNWAY 예상 현황</strong>을<br />
                빠르게 확인할 수 있어요.
              </p>
              <p>
                한 번 작성 후 기업의 캐시플로우
                <br />
                <strong className="highlight">
                  히스토리를 지속적으로 업데이트
                </strong>
                <br />
                하실 수 있도록 알려드려요.
              </p>
              <button
                className="start"
                onClick={() => history.push("/groupware/cashflow/balanceIns")}
              >
                지금 시작하기
              </button>
            </div>
          </div>
          <div className="start_box">
            <ul className="link_list">
              {links.map((item, idx) => (
                <li key={idx}>
                  <Link to={item.link}>
                    <div>
                      <strong>{item.title}</strong>
                      <p>{item.text}</p>
                      <div className="go">
                        <strong>바로가기&rarr;</strong>
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
            <div className="text">
              <p>
                기업 현금상황을 기반으로
                <br />
                <strong>자금조달에 도움이 되는 정보</strong>를<br />
                확인하실 수 있습니다.
              </p>
              <strong>
                비즈내비에서
                <br />
                자금조달까지 해결하세요!
              </strong>
              <button
                className="start"
                onClick={() => history.push("/groupware/cashflow/balanceIns")}
              >
                지금 시작하기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashflowIntro;
