import React, { useContext, useEffect, useState } from "react";
import "./css/Home.scss";
import HomeAgent from "./HomeAgent";
import HomeInventor from "./HomeInventor";
import HomeCompany from "./HomeCompany";
import HomeTaskManage from "./HomeTaskManage";
import MemberContext from "../../store/Member";
import "chartjs-plugin-datalabels";
import StatisticAPI from "../../API/statistic/index";
import HomeInvestor from "./HomeInvestor";
import CommonAPI from "../../API/common";
import CommonContext from "../../store/Common";
import FindBizChange from "../common/FindBizChange";
import BannerOneClick from "../common/BannerOneClick";
import IntroFindBiz from "../intro/findbiz/IntroFindBiz";
import { useLocation } from "react-router-dom";
import RenewalMainMobile from "../intro/findbiz/RenewalMainMobile";
import HomeIpnowNew from "./HomeIpnowNew";
import RenewalServiceMain from "../intro/findbiz/RenewalServiceMain";
import RenewalServiceMainMobile from "../intro/findbiz/RenewalServiceMainMobile";
import HomeIpnowGrade from "./HomeIpnowGrade";

function Home({ history, data }) {
  const location = useLocation();
  const { isMobile } = useContext(CommonContext);
  const { auth, manage_company_idx } = useContext(MemberContext);
  const cur_year = new Date().getFullYear();
  const prev_four_year = cur_year - 4;

  const isCompany = window.$Global.checkAuth("특허회사", auth);
  const isAgent = window.$Global.checkAuth("대리인", auth);
  const isInvestor = window.$Global.checkAuth("투자자", auth);
  const [statistic, setStatistic] = useState({});

  const [patentData, setPatentData] = useState({});
  const [trademarkData, setTrademarkData] = useState({});
  const [designData, setDesignData] = useState({});
  const [annualFeeData, setAnnualFeeData] = useState({});
  const [patent_count_data, setPatentCountData] = useState({});

  const isIpnowType = window.$Global.isIpnow(location);
  const findbizType = !window.$Global.isIpnow(location);

  useEffect(async () => {
    let result = {};

    if (isCompany) {
      let payload = await CommonAPI.getOption(0).then(
        (res) => res.data[0]?.option || {}
      );
      payload.start_year = cur_year - 4;
      payload.end_year = cur_year + 3;

      result = await StatisticAPI.getStatistic(payload).then((res) => {
        let data = res.data;
        let apply = data.graph.apply;
        let register = data.graph.register;
        let apply_keys = Object.keys(apply);
        let register_keys = Object.keys(register);
        let apply_arr = [];
        let register_arr = [];

        for (let i in apply_keys) {
          apply_arr.push({
            year: apply_keys[i],
            cnt: apply[apply_keys[i]].cnt,
          });
        }

        for (let i in register_keys) {
          register_arr.push({
            year: register_keys[i],
            cnt: register[register_keys[i]].cnt,
          });
        }

        data.annual_apply = apply_arr;
        data.annual_register = register_arr;
        return data;
      });
    } else if (isAgent) {
      result = await StatisticAPI.getStatisticAgent(manage_company_idx).then(
        (res) => res.data
      );
    }
    setStatistic(result);
  }, []);

  useEffect(() => {
    if (window.$Global.isEmptyObject(statistic)) return;

    setPatentData(getCommonData(statistic.patent_statistic));
    setTrademarkData(getCommonData(statistic.trademark_statistic));
    setDesignData(getCommonData(statistic.design_statistic));

    if (isCompany) {
      let annual_fee_year_arr = window.$Global.calcYear(cur_year, 3);

      setAnnualFeeData({
        labels: annual_fee_year_arr.convert_arr,
        datasets: [
          {
            data: statistic.annual_payment.slice(4).map((item) => {
              let result = annual_fee_year_arr.year_arr.find(
                (year) => year == item.year
              );

              if (result) {
                return item.cost + item.td_cost;
              }
            }),
            backgroundColor: [
              "rgb(255, 99, 132)",
              "rgb(54, 162, 235)",
              "rgb(255, 206, 86)",
              "rgb(75, 192, 192)",
            ],
            borderColor: [
              "rgb(255, 99, 132)",
              "rgb(54, 162, 235)",
              "rgb(255, 206, 86)",
              "rgb(75, 192, 192)",
            ],
            borderWidth: 1,
          },
        ],
      });

      let start_year = cur_year - 4;
      let copy_annual_apply = statistic.annual_apply.slice(0, 5);

      for (let i = 0; i < 5 && copy_annual_apply.length < 5; i++) {
        if (copy_annual_apply[i]) {
          if (prev_four_year != Number(copy_annual_apply[i].year) - i) {
            copy_annual_apply.splice(i + 1, 0, {
              cnt: 0,
              year: String(prev_four_year + i),
            });
          }
        } else {
          copy_annual_apply.splice(i + 1, 0, {
            cnt: 0,
            year: String(prev_four_year + i),
          });
        }
      }

      let copy_annual_register = statistic.annual_register.slice(0, 5);

      for (let j = 0; j < 5 && copy_annual_register.length < 5; j++) {
        if (copy_annual_register[j]) {
          if (prev_four_year != Number(copy_annual_register[j].year) - j) {
            copy_annual_register.splice(j + 1, 0, {
              cnt: 0,
              year: String(prev_four_year + j),
            });
          }
        } else {
          copy_annual_register.splice(j + 1, 0, {
            cnt: 0,
            year: String(prev_four_year + j),
          });
        }
      }

      // 특허 갯수 데이터
      setPatentCountData({
        labels: window.$Global.calcYear(start_year, 4).convert_arr,
        datasets: [
          {
            label: "출원갯수",
            data: copy_annual_apply.map((item) => item.cnt),
            backgroundColor: "rgb(255, 172, 18)",
            borderColor: "rgba(255, 172, 18, 0.2)",
            borderWidth: 4,
            lineTension: 0,
            fill: false,
          },
          {
            label: "등록갯수",
            data: copy_annual_register.map((item) => item.cnt),
            backgroundColor: "rgb(0, 149, 174)",
            borderColor: "rgba(0, 149, 174, 0.2)",
            borderWidth: 4,
            lineTension: 0,
            fill: false,
          },
        ],
      });
    }
  }, [statistic]);

  const getCommonData = (obj) => {
    return {
      labels: ["등록", "출원", "포기"],
      datasets: [
        {
          data: [obj?.registered, obj?.applied, obj?.terminated],
          backgroundColor: ["#ffffff", "#ffd4a6", "#ffa94d"],
          hoverBackgroundColor: ["#ffffff", "#ffd4a6", "#ffa94d"],
          borderWidth: 0,
        },
      ],
    };
  };

  const patent_trademark_options = {
    legend: {
      display: false,
    },
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };

  const annualFee_options = {
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        anchor: "end",
        align: "top",
        font: {
          size: 10,
          weight: "bold",
        },
        formatter: function (value) {
          return window.$Global.commaify(value);
        },
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            display: false,
            max: window.$Global.calcGraphMaxNumber(
              statistic.annual_payment &&
                statistic.annual_payment.map((item) => item.cost + item.td_cost)
            ),
            callback: function (value) {
              return window.$Global.commaify(value);
            },
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem) {
          return window.$Global.commaify(tooltipItem.value) + "원";
        },
      },
    },
  };

  const patent_count_max =
    window.$Global.calcGraphMaxNumber(
      statistic.annual_apply &&
        statistic.annual_apply
          .map((item) => item.cnt)
          .concat(statistic.annual_register.map((item) => item.cnt))
    ) * 1.2;

  const patent_count_options = {
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    scales: {
      angleLines: {
        lineWidth: 0,
      },
      yAxes: [
        {
          ticks: {
            display: false,
            beginAtZero: true,
            max: patent_count_max,
            stepSize: patent_count_max / 4,
            maxTicksLimit: 5,
          },
          gridLines: {
            display: true,
            borderDash: [3],
            color: "#ffffff",
            zeroLineColor: "#ffffff",
            drawBorder: true,
            drawOnChartArea: true,
            drawTicks: true,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        anchor: "end",
        align: "top",
        font: {
          weight: "bold",
        },
      },
    },
  };

  const hasRegistered = (obj) => {
    return obj?.registered + obj?.applied + obj?.terminated > 0;
  };

  if (
    !window.$Global.checkAuth("투자자", auth) &&
    (window.$Global.isEmptyObject(statistic) ||
      window.$Global.isEmptyObject(patentData) ||
      window.$Global.isEmptyObject(trademarkData))
  ) {
    return null;
  }

  const findbizStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: 1200,
    margin: "20px auto",
  };

  return (
    <>
      {isIpnowType && (
        <div className="pc">
          <div className="section" style={findbizStyle}>
            <BannerOneClick history={history} />
            <FindBizChange history={history} />
          </div>
        </div>
      )}
      <div id="Home" className={isCompany && findbizType ? "findbiz" : ""}>
        {isIpnowType || location.pathname === "/ipHome" ? (
          window.$Global.checkAuth("특허회사_관리자", auth) ? (
            <HomeCompany
              data={patentData}
              data2={trademarkData}
              data3={annualFeeData}
              data4={patent_count_data}
              designData={designData}
              options={patent_trademark_options}
              options2={annualFee_options}
              options3={patent_count_options}
              patent_all_cnt={statistic.patent_statistic.all}
              trademark_all_cnt={statistic.trademark_statistic.all}
              design_all_cnt={statistic.design_statistic?.all}
              isEmptyPatentData={!hasRegistered(statistic.patent_statistic)}
              isEmptyTrademarkData={
                !hasRegistered(statistic.trademark_statistic)
              }
              isEmptyDesignData={!hasRegistered(statistic.design_statistic)}
              history={history}
              statistic={statistic}
            />
          ) : window.$Global.checkAuth("특허회사_발명자만", auth) ? (
            <HomeInventor
              data={patentData}
              data2={trademarkData}
              designData={designData}
              options={patent_trademark_options}
              patent_all_cnt={statistic.patent_statistic.all}
              trademark_all_cnt={statistic.trademark_statistic.all}
              design_all_cnt={statistic.design_statistic?.all}
              isEmptyPatentData={!hasRegistered(statistic.patent_statistic)}
              isEmptyTrademarkData={
                !hasRegistered(statistic.trademark_statistic)
              }
              isEmptyDesignData={!hasRegistered(statistic.design_statistic)}
            />
          ) : isAgent ? (
            <HomeAgent
              data={patentData}
              data2={trademarkData}
              designData={designData}
              options={patent_trademark_options}
              patent_all_cnt={statistic.patent_statistic.all}
              trademark_all_cnt={statistic.trademark_statistic.all}
              design_all_cnt={statistic.design_statistic?.all}
              isEmptyPatentData={!hasRegistered(statistic.patent_statistic)}
              isEmptyTrademarkData={
                !hasRegistered(statistic.trademark_statistic)
              }
              isEmptyDesignData={!hasRegistered(statistic.design_statistic)}
            />
          ) : (
            isInvestor && <HomeInvestor history={history} />
          )
        ) : location.pathname === "/ipHome/new" ? (
          <HomeIpnowNew
            data={patentData}
            data2={trademarkData}
            data3={annualFeeData}
            data4={patent_count_data}
            designData={designData}
            options={patent_trademark_options}
            options2={annualFee_options}
            options3={patent_count_options}
            patent_all_cnt={statistic.patent_statistic.all}
            trademark_all_cnt={statistic.trademark_statistic.all}
            design_all_cnt={statistic.design_statistic?.all}
            isEmptyPatentData={!hasRegistered(statistic.patent_statistic)}
            isEmptyTrademarkData={!hasRegistered(statistic.trademark_statistic)}
            isEmptyDesignData={!hasRegistered(statistic.design_statistic)}
            history={history}
            statistic={statistic}
          />
        ) : location.pathname === "/ipHome/grade" ? (
          <HomeIpnowGrade
            data={patentData}
            data2={trademarkData}
            data3={annualFeeData}
            data4={patent_count_data}
            designData={designData}
            options={patent_trademark_options}
            options2={annualFee_options}
            options3={patent_count_options}
            patent_all_cnt={statistic.patent_statistic.all}
            trademark_all_cnt={statistic.trademark_statistic.all}
            design_all_cnt={statistic.design_statistic?.all}
            isEmptyPatentData={!hasRegistered(statistic.patent_statistic)}
            isEmptyTrademarkData={!hasRegistered(statistic.trademark_statistic)}
            isEmptyDesignData={!hasRegistered(statistic.design_statistic)}
            history={history}
            statistic={statistic}
          />
        ) : isMobile ? (
          <RenewalServiceMainMobile />
        ) : (
          <HomeTaskManage data={data} />
        )}
      </div>
    </>
  );
}

export default Home;
