import { useEffect, useState } from "react";
import GroupwareSideBar from "../groupware/GroupwareSideBar";
import { groupwareMypagePath } from "../groupware/groupwarePath";
import MyClt from "./my/MyClt";
import CompanyAPI from "../../API/company";
import qs from "query-string";
import "./css/CompetitorMy.scss";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const CompetitorMy = () => {
  const member_idx = localStorage.getItem("idx");
  const location = useLocation();
  const queryString = location.search;
  const [flagType, setFlagType] = useState(
    Number(qs.parse(queryString).type) || 1
  );
  const [ctlList, setCtlList] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(6);
  const [last, setLast] = useState(1);

  const getCtlAll = () => {
    CompanyAPI.getRelationMyPage({
      owner_idx: member_idx,
      page: page,
      count: count,
      flag: flagType,
    }).then((res) => {
      //console.log("getCtlAll:", res);
      if (res.data?.data?.cltData?.length) {
        setCtlList(res.data?.data?.cltData || []);
        setLast(res.data?.data?.last || 1);
      } else {
        setCtlList([]);
      }
    });
  };

  useEffect(() => {
    getCtlAll();
  }, []);

  useEffect(() => {
    getCtlAll();
  }, [page]);

  useEffect(() => {
    setPage(1);
    setFlagType(Number(qs.parse(queryString).type) || 1);
  }, [location]);

  useEffect(() => {
    getCtlAll();
  }, [flagType]);

  return (
    <div id="GroupwareWorks">
      <GroupwareSideBar currentPath={groupwareMypagePath} />
      <div className="commute_wrapper">
        <div id="CompetitorMy">
          <MyClt
            ctlList={ctlList}
            getCtlAll={getCtlAll}
            memberIdx={member_idx}
            page={page}
            last={last}
            setPage={setPage}
            flagType={flagType}
          />
        </div>
      </div>
    </div>
  );
};

export default CompetitorMy;
