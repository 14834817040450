import React from "react";
import { Route, Redirect } from "react-router-dom";

function RouteIf({
  component: Component,
  condition,
  redirectPath,
  ...parentProps
}) {
  return (
    <Route
      {...parentProps}
      render={(props) =>
        condition ? (
          <Component {...props} {...parentProps} />
        ) : (
          <Redirect to={redirectPath} />
        )
      }
    />
  );
}
export default RouteIf;
