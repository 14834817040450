import "./css/InventorStepThree.scss";

const InventorStepThree = ({ history }) => {
  return (
    <div id="InventorStepThree">
      <div className="inventor_info_box_title">
        <h5>“김고은”의 “부산교육대학교[본교]”특허리스트</h5>
        <p>
          <i className="icon_badge_info_gray" />
          내가 발명한 특허를 모두 체크 해 보세요.
        </p>
      </div>
      <table className="inventor_patent_table">
        <colgroup>
          <col width="40px" />
          <col width="30px" />
          <col width="200px" />
          <col width="300px" />
          <col width="100px" />
          <col width="100px" />
        </colgroup>
        <thead>
          <th>
            <input type="checkbox" />
          </th>
          <th>No.</th>
          <th>특허번호</th>
          <th>발명의 명칭</th>
          <th>등록일</th>
          <th>발명자 이름</th>
          <th>주소</th>
        </thead>
        <tbody>
          <tr>
            <td>
              <input type="checkbox" checked={true} />
            </td>
            <td>1</td>
            <td>ITL14-0060-KR_우_D1</td>
            <td>무선 통신 시스템에서 사이드링크 PRS를 ..</td>
            <td>2020-11-12</td>
            <td>김고은</td>
            <td>
              (우 06744)서울특별시 서초구 바우뫼로 175, 4F/5F (양재동, 창덕빌딩)
            </td>
          </tr>
          <tr>
            <td>
              <input type="checkbox" checked={true} />
            </td>
            <td>1</td>
            <td>ITL14-0060-KR_우_D1</td>
            <td>무선 통신 시스템에서 사이드링크 PRS를 ..</td>
            <td>2020-11-12</td>
            <td>김고은</td>
            <td>
              (우 06744)서울특별시 서초구 바우뫼로 175, 4F/5F (양재동, 창덕빌딩)
            </td>
          </tr>
          <tr>
            <td>
              <input type="checkbox" checked={true} />
            </td>
            <td>1</td>
            <td>ITL14-0060-KR_우_D1</td>
            <td>무선 통신 시스템에서 사이드링크 PRS를 ..</td>
            <td>2020-11-12</td>
            <td>김고은</td>
            <td>
              (우 06744)서울특별시 서초구 바우뫼로 175, 4F/5F (양재동, 창덕빌딩)
            </td>
          </tr>
          <tr>
            <td>
              <input type="checkbox" checked={true} />
            </td>
            <td>1</td>
            <td>ITL14-0060-KR_우_D1</td>
            <td>무선 통신 시스템에서 사이드링크 PRS를 ..</td>
            <td>2020-11-12</td>
            <td>김고은</td>
            <td>
              (우 06744)서울특별시 서초구 바우뫼로 175, 4F/5F (양재동, 창덕빌딩)
            </td>
          </tr>
        </tbody>
      </table>
      <div className="btns">
        <button onClick={() => history.goBack()}>이 전</button>
        <button onClick={() => history.push("/inventor/stepthree/done")}>
          선택완료
        </button>
      </div>
    </div>
  );
};

export default InventorStepThree;
