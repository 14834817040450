import { useEffect, useRef } from "react";
import { Chart } from "chart.js";
import "chartjs-chart-treemap";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "../css/HoldingsInvestDistribution.scss";

Chart.plugins.unregister(ChartDataLabels); // datalabels 충돌나서 해제

const HoldingsInvestDistribution = () => {
  // const chartRef = useRef(null);

  // useEffect(() => {
  //   const ctx = chartRef.current.getContext("2d");
  //   new Chart(ctx, {
  //     type: "treemap",
  //     data: {
  //       labels: [
  //         "바이오·신약",
  //         "헬스케어",
  //         "마케팅",
  //         "모빌리티",
  //         "교육",
  //         "게임",
  //         "경영",
  //         "금융",
  //         "기타",
  //       ],
  //       datasets: [
  //         {
  //           label: "시장 점유율",
  //           data: [29.8, 17.6, 17.6, 19.7, 4.5, 4.5, 2.8, 2.8, 2.8],
  //           backgroundColor: [
  //             "#605BFF",
  //             "#807CFF",
  //             "#807CFF",
  //             "#807CFF",
  //             "#A09DFF",
  //             "#A09DFF",
  //             "#BFBDFF",
  //             "#BFBDFF",
  //             "#BFBDFF",
  //           ],
  //           spacing: 10,
  //           datalabels: {
  //             callbacks: {
  //               title: function (item, data) {
  //                 return data.datasets[item[0].datasetIndex];
  //               },
  //               label: function (item, data) {
  //                 var dataset = data.datasets[item.datasetIndex];
  //                 var dataItem = dataset.data[item.index];
  //                 return dataItem.g + " " + dataItem.v;
  //               },
  //             },
  //           },
  //         },
  //       ],
  //     },
  //     options: {
  //       plugins: {
  //         tooltip: {
  //           callbacks: {
  //             label: function (context) {
  //               return `${context.label}: ${context.raw.value}%`;
  //             },
  //           },
  //         },
  //       },
  //     },
  //   });
  // }, []);

  return (
    <div id="HoldingsInvestDistribution">
      <div className="part">
        <div>
          <strong className="part_tit">투자단계별 기업 분포</strong>
          {/* <canvas ref={chartRef} height={450}></canvas> */}
          <div className="distribution_chart">
            <div className="box">
              <div
                className="theme"
                style={{ height: "60%", background: "#605BFF" }}
              >
                <span>바이오-신약</span>
                <strong>29.8%</strong>
              </div>
              <div
                className="theme"
                style={{ height: "20%", background: "#807CFF" }}
              >
                <span>헬스케어</span>
                <strong>17.6%</strong>
              </div>
              <div
                className="theme"
                style={{ height: "20%", background: "#807CFF" }}
              >
                <span>마케팅</span>
                <strong>17.6%</strong>
              </div>
            </div>
            <div className="box">
              <div
                className="theme"
                style={{ height: "100%", background: "#807CFF" }}
              >
                <span>모빌리티</span>
                <strong>19.7%</strong>
              </div>
            </div>
            <div className="box">
              <div
                className="theme"
                style={{ height: "50%", background: "#A09DFF" }}
              >
                <span>교육</span>
                <strong>4.5%</strong>
              </div>
              <div
                className="theme"
                style={{ height: "50%", background: "#A09DFF" }}
              >
                <span>게임</span>
                <strong>4.5%</strong>
              </div>
            </div>
            <div className="box">
              <div
                className="theme"
                style={{ height: "34%", background: "#BFBDFF" }}
              >
                <span>경영</span>
                <strong>2.8%</strong>
              </div>
              <div
                className="theme"
                style={{ height: "33%", background: "#BFBDFF" }}
              >
                <span>금융</span>
                <strong>2.8%</strong>
              </div>
              <div
                className="theme"
                style={{ height: "33%", background: "#BFBDFF" }}
              >
                <span>기타</span>
                <strong>2.8%</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HoldingsInvestDistribution;
