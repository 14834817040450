const EstimatePopUpOption = ({ setPopUpShow }) => {
  return (
    <div>
      <i className="icon_exit_gray" onClick={() => setPopUpShow(false)} />
      <h2>마케팅 수집 및 활용 동의</h2>
      <p>
        수집하는 개인정보의 항목, 개인정보의 수집 및 이용목적, 개인정보의 보유
        및 이용기간을 안내 드립니다.
      </p>
      <p>
        귀하는 마케팅 수집 및 활용을 거부할 수 있으며,
        <br />
        동의해주신다면 업데이트 소식, 프로모션 이벤트 등을 전달 받으실 수
        있습니다.
        <br />* 만약 사용자가 더 이상 홍보 및 마케팅 정보 수신을 원하지 않을
        경우 고객센터를 통해 요청하시면 본 동의를 철회 할 수 있습니다.
      </p>
      <table>
        <thead>
          <tr>
            <th>수집/이용 목적</th>
            <th>항목</th>
            <th>기간</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>제품/솔루션/서비스마케팅/광고수신</td>
            <td>연락처, 이메일</td>
            <td>수집 및 이용 동의일로부터 1년까지 (요청시 삭제)</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default EstimatePopUpOption;
