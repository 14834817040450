import React, {useEffect, useState} from "react";

function DynamicSelectRow({title, titleClass, content, options, deleteBtnHide, defaultValue, editing, onClickCategoryDelete, onChange}) {
    const [optionsTag, setOptionTag] = useState([]);

    useEffect(() => {
        setOptionTag(options.map((item, idx) => {
            if (item.name === "") return;
            return <option key={idx} value={item.idx || ''}>{item.name}</option>;
        }));
    }, [options]);

    return (
        <div className={`row ${titleClass || ''}`}>
            <h2 className="title">
                {title}
                {editing && (deleteBtnHide || <button className="icon_badge_del" onClick={onClickCategoryDelete}/>)}
            </h2>
            {
                editing
                    ?
                        optionsTag.length
                        ?
                        <select defaultValue={defaultValue || 1} onChange={onChange}>
                            <option value={1} disabled hidden>선택</option>
                            {optionsTag}
                        </select>
                        : <p>계정 등록 후 선택 가능</p>
                    : <p>{content}</p>
            }
        </div>
    );
}

export default DynamicSelectRow;
