import React, { useContext, useState } from "react";
import CheckBox from "../common/CheckBox";
import { useHistory } from "react-router-dom";
import MemberContext from "../../store/Member";

function MarketInfoMainItem({ item, cartArr, showPopup, onClickCheck }) {
  const { admin } = useContext(MemberContext);
  const history = useHistory();
  const [showFullSummary, setShowFullSummary] = useState(true);
  let hasGPTSummary = Boolean(item.mi_info?.gpt_summary);
  let hasContent = Boolean(item.content || item.content_image_list.length);
  let checked;

  if (cartArr) {
    if (!cartArr.type) {
      checked = Boolean(
        cartArr.findIndex(
          (cart) => (!cart.type && (cart.mi_idx || cart.idx)) === item.idx
        ) > -1
      );
    }
  }

  return (
    <div className="marketInfoMain_item">
      <div className="info">
        <h2>{item.title}</h2>
      </div>
      <img
        src={
          item.image_key
            ? window.$Global.getCDN(item.image_key)
            : require("../../assets/images/common/no_image.png").default
        }
        alt="이미지"
        onContextMenu={window.$Global.blockRightClick}
        onClick={() => showPopup(item, "graph")}
      />
      {item.mi_info ? (
        <div
          className="gpt_summary"
          // onMouseEnter={() => setShowFullSummary(true)}
          // onMouseLeave={() => setShowFullSummary(false)}
          onClick={() => showPopup(item, "graph")}
        >
          <h2>{item.mi_info?.gpt_summary}</h2>
        </div>
      ) : (
        ""
      )}
      <div className="etc">
        <div className="etc_btn">
          {(admin || item.chart_data !== null) && (
            <button
              onClick={() =>
                history.push(
                  `/marketInfo/chart?isPostType=post&idx=${item.idx}`
                )
              }
              className="chart_btn"
            >
              차트만들기
              <i className="icon_blue_add" />
            </button>
          )}
          {hasContent && (
            <button
              onClick={() => showPopup(item, "content")}
              className="content_btn"
            >
              관련글 보기
            </button>
          )}
          {admin && item.chart_data !== null ? (
            <p className="chart_isComplete">작업 완료</p>
          ) : null}
        </div>
        <label for={item.idx}>
          <input
            type="checkbox"
            id={item.idx}
            checked={checked}
            onChange={(e) => onClickCheck(e, item)}
          />
          {checked ? (
            <i className="icon_checkbox_blue" />
          ) : (
            <i className="icon_checkbox_gray" />
          )}
        </label>
      </div>
      {/*{showFullSummary ? (*/}
      {/*  <div className="summary_modal">{item.mi_info?.gpt_summary}</div>*/}
      {/*) : null}*/}
    </div>
  );
}

export default MarketInfoMainItem;
