import Tooltip from "./Tooltip";

const DocumentStep = () => {
  return (
    <div id="DocumentStep">
      <ul>
        <li>
          <div className="top">
            <p className="regist btn">제품 등록하기</p>
          </div>
          <div className="bottom">
            <p className="desc">
              클릭 및 입력하기
              <Tooltip
                children={<i className="fi-rs-interrogation"></i>}
                text={
                  <p>
                    보유특허와 연관된 제품명을 입력해 주세요.
                    <br />
                    은행에서 기업 특허 검토시 필수 확인하는 정보입니다.
                    <br />
                    제품별로 관련 특허를 선택하고 저장해 보세요.
                  </p>
                }
              />
            </p>
          </div>
        </li>
        <li>
          <div className="top">
            <p className="modify btn">수정하기</p>
          </div>
          <div className="bottom">
            <p>클릭</p>
          </div>
        </li>
        <li>
          <div className="top">
            <p className="txt">제품 적용유무</p>
          </div>
          <div className="bottom">
            <p>
              입력하기
              <Tooltip
                children={<i className="fi-rs-interrogation"></i>}
                text={
                  <p>
                    해당 특허가 현재 제품에 적용되어있는 상태를 표시할 선택하여
                    표시할 수 있습니다.
                    <br />
                    제품 개발중/적용중/미적용 상태 중 선택해 주세요.
                  </p>
                }
              />
            </p>
          </div>
        </li>
        <li>
          <div className="top">
            <p className="txt">(예상)매출액</p>
          </div>
          <div className="bottom">
            <p>
              입력 및 저장하기
              <Tooltip
                children={<i className="fi-rs-interrogation"></i>}
                text={
                  <p>
                    전년도, 올해, 그리고 추후 3년간 이 제품을 통해 벌어들일 수
                    있는 예상 매출액을 작성해 보세요.
                    <br />
                    은행이 해당특허를 검토하는데에 활용할 수 있습니다.
                  </p>
                }
              />
            </p>
          </div>
        </li>
        <li>
          <div className="top">
            <p className="btn download">선택 다운로드</p>
          </div>
          <div className="bottom">
            <p>
              리스트 선택 후 클릭
              <Tooltip
                children={<i className="fi-rs-interrogation"></i>}
                text={
                  <p>
                    은행에서 필요로 하는 우리 회사 특허에 대한 정보를 엑셀로
                    내려받아 제출할 수 있습니다.
                  </p>
                }
              />
            </p>
          </div>
        </li>
        <li className="end">
          <div className="top">
            <p className="done">
              <strong>완료!</strong>
            </p>
          </div>
          <div className="bottom">
            <p>
              다운로드 폴더
              <br />
              확인하기
            </p>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default DocumentStep;
