import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CommonAPI from "../../../API/common";
import "./CommunityContainer.scss";
import TutorialTab from "../../home/TutorialTab";
import commonFunc from "../../admin/commonFunc/common";

function CommunityContainer() {
  const [postList, setPostList] = useState({});
  const [categoryList, setCategoryList] = useState([]);
  const [selectCategoryIdx, setSelectCategoryIdx] = useState(-1);

  let postTag = [];
  let payload = {
    page: 1,
    count: 5,
    category: selectCategoryIdx,
  };

  useEffect(() => {
    commonFunc.convertCommunityCategory().then((res) => {
      let add = [
        {
          name: "전체",
          value: -1,
        },
      ];
      setCategoryList(add.concat(res));
    });
  }, []);

  useEffect(() => {
    CommonAPI.getNoticeList(payload).then((res) => setPostList(res.data));
  }, [selectCategoryIdx]);

  if (!window.$Global.isEmptyObject(postList)) {
    postTag = postList.items.map((item, index) => {
      return (
        <div key={index} className={`post ${item.pin ? "pin" : ""}`}>
          <Link to={`/community/detail/${item.idx}?category=${item.category}`}>
            <p>
              {item.pin ? (
                <i className="icon_notification" />
              ) : (
                postList.total_count - index
              )}
            </p>
            <p>
              {commonFunc.convertCommunityCategoryNo(
                categoryList,
                item.category
              )}
            </p>
            <p>{item.title}</p>
            <p>{window.$Global.convertDate(item.created_at)}</p>
          </Link>
        </div>
      );
    });
  }

  const onClickSelectCategory = (idx) => {
    setSelectCategoryIdx(idx);
  };

  return (
    <>
      <div id="CommunityContainer">
        <h2 className="page_title">IPNOW 소식 & 튜토리얼</h2>
        <div className="header">
          <div className="inner">
            {categoryList.map((item, idx) => {
              return (
                <p
                  key={idx}
                  data-value={item.value}
                  className={`${
                    item.value == selectCategoryIdx ? "active" : ""
                  }`}
                  onClick={() => onClickSelectCategory(item.value)}
                >
                  {item.name}
                </p>
              );
            })}
          </div>
          {selectCategoryIdx != -1 && (
            <Link
              to={`/community/list?category=${selectCategoryIdx}`}
              className="btn_link"
            >
              더보기
            </Link>
          )}
        </div>
        <div className="post_table">
          <div className="thead">
            <p>번호</p>
            <p>분류</p>
            <p>제목</p>
            <p>등록일</p>
          </div>
          <div className="tbody">{postTag}</div>
        </div>
      </div>
      {/*<TutorialTab/>*/}
    </>
  );
}

export default CommunityContainer;
