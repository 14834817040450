import "./css/InventorDone.scss";
import { useEffect, useState } from "react";

const InventorDone = ({ history }) => {
  const [remainingTime, setRemainingTime] = useState(5000);

  useEffect(() => {
    const timer = setTimeout(() => {
      setRemainingTime(0); // Update remaining time to 0
      history.push("/ipHome"); // Redirect to the specified URL
    }, remainingTime);

    const interval = setInterval(() => {
      setRemainingTime((prevTime) => prevTime - 1000); // Update remaining time every second
    }, 1000);

    return () => {
      clearTimeout(timer); // Clear the timeout if the component unmounts before the delay expires
      clearInterval(interval); // Clear the interval for updating remaining time
    };
  }, [remainingTime, history]);

  const seconds = Math.floor(remainingTime / 1000);

  return (
    <div id="InventorDone">
      <h2>등록이 완료되었습니다.</h2>
      <h5>{seconds}초 후 IP관리 홈으로 이동합니다.</h5>
      <button onClick={() => history.push("/ipHome")}>홈으로</button>
    </div>
  );
};

export default InventorDone;
