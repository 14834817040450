import Highlighter from "react-highlight-words";
import React from "react";
import useMoveScroll from "../../../util/useMoveScroll";

const PatentInfoUS = ({ infoData, highlightKeyword }) => {
  const tabs = {
    0: useMoveScroll("배경기술"),
    1: useMoveScroll("도면설명"),
    2: useMoveScroll("발명 구체내용"),
    length: 3,
  };

  return (
    <div>
      <h5>명세서</h5>
      <div className="spec_tab">
        {Array.from(tabs).map((tab, index) => {
          return <div onClick={tab.onMoveToElement}>{tab.name}</div>;
        })}
      </div>
      <div className="patent_spec_items">
        <div>
          <p style={{ color: "#4593f5" }} ref={tabs[0].element}>
            배경 기술
          </p>
          <Highlighter
            searchWords={highlightKeyword}
            autoEscape={true}
            textToHighlight={infoData.statementHtml.backgroundArt.replace(
              /<[^>]+>/g,
              ""
            )}
          />
          <br />
          <br />
        </div>
        <div>
          <p ref={tabs[1].element}>도면설명</p>
          <Highlighter
            searchWords={highlightKeyword}
            autoEscape={true}
            textToHighlight={infoData.statementHtml.descriptionOfDrawings.replace(
              /<[^>]+>/g,
              ""
            )}
          />
          <br />
          <br />
        </div>
        <div>
          <p ref={tabs[2].element}>발명 구체내용</p>
          <Highlighter
            searchWords={highlightKeyword}
            autoEscape={true}
            textToHighlight={infoData.statementHtml.descriptionOfEmbodiments.replace(
              /<[^>]+>/g,
              ""
            )}
          />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default PatentInfoUS;
