import React, { useEffect, useState } from "react";
import "./css/AdminCommunity.scss";
import ContentTableList from "../common/ContentTableList";
import AdminAPI from "../../API/admin";
import commonFunc from "./commonFunc/common";
import _ from "lodash";
import { Link } from "react-router-dom";

function AdminCommunity() {
  const [categoryList, setCategoryList] = useState([]);
  const [selectCategoryIdx, setSelectCategoryIdx] = useState(0);

  const [resData, setResData] = useState({ items: [] });
  const [tbody, setTbody] = useState([]);

  const [curPage, setCurPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const count = 10;

  useEffect(() => {
    commonFunc.convertCommunityCategory().then((res) => {
      let arr = [];
      res.map((item) => {
        if (!item.child) arr.push(item);
      });
      setCategoryList(arr);
    });
  }, []);

  useEffect(() => {
    getCommunityList();
    setCurPage(1);
  }, [selectCategoryIdx]);

  useEffect(() => {
    drawTable();
  }, [resData]);

  const getCommunityList = (content_type) => {
    let payload = {
      count: count,
      ...(content_type == "next" ? { page: curPage + 1 } : { page: 1 }),
      category: selectCategoryIdx,
      ...(selectCategoryIdx > 5 && { service: -1 }),
    };

    setCurPage(curPage + 1);

    AdminAPI.getCommunityList(payload).then((res) => {
      let result = res.data;
      console.log(result);
      if (content_type == "next") {
        result.items = resData.items.concat(result.items);
      }
      setResData(result);
    });
  };

  const drawTable = () => {
    setTbody(
      resData.items.map((item, index) => {
        return (
          <tr key={index}>
            <td>{resData.total_count - index}</td>
            <td>
              <Link to={`/admin/community/posting?idx=${item.idx}&type=view`}>
                <div className="content">
                  <img
                    src={window.$Global.getCDN(item.thumbnail_key)}
                    alt="이미지"
                  />
                  <div>
                    <h2>{item.title || item.question}</h2>
                    <p>
                      {window.$Global.decode(
                        window.$Global.removeHTML(
                          item.content || item.answer || ""
                        )
                      )}
                    </p>
                  </div>
                </div>
              </Link>
            </td>
            <td>{window.$Global.convertDate(item.created_at)}</td>
            <td>
              <button
                className={`btn_pin ${item.pin ? "on" : ""}`}
                onClick={() => onClickPin(index, item.idx)}
              >
                {item.pin ? "고정 해제" : "고정"}
              </button>
            </td>
            <td>
              <button
                className="icon_badge_del"
                onClick={() => onClickPostDelete(index, item.idx)}
              />
            </td>
          </tr>
        );
      })
    );
    if (resData.page == resData.last) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  };

  const nextList = () => {
    getCommunityList("next");
  };

  const onClickSelectCategory = (idx) => {
    setSelectCategoryIdx(idx);
  };

  const onClickPin = (index, idx) => {
    AdminAPI.getCommunityPin(idx).then((res) => {
      let copy = _.cloneDeep(resData);
      copy.items[index].pin = Number(res.data);
      setResData(copy);
    });
  };

  const onClickPostDelete = (depth, idx) => {
    AdminAPI.deleteNotify(idx).then(() => {
      let copy = _.cloneDeep(resData);
      copy.items.splice(depth, 1);
      setResData(copy);
      alert("삭제되었습니다");
    });
  };

  console.log(categoryList);
  return (
    <div id="AdminCommunity">
      <div className="category_tab">
        <h2>카테고리</h2>
        <ul>
          {categoryList.map((item, idx) => {
            return (
              <li
                className={`${item.value == selectCategoryIdx ? "active" : ""}`}
                onClick={() => onClickSelectCategory(item.value)}
              >
                {item.name}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="btns">
        <Link to={`/admin/community/posting`} className="btn_post">
          글 작성하기
        </Link>
      </div>
      <ContentTableList
        addTheadList={["상단 고정", "삭제"]}
        title={
          categoryList.find((item) => item.value === selectCategoryIdx)?.name
        }
        data={tbody}
        content={tbody}
        drawList={nextList}
        hasMore={hasMore}
      />
    </div>
  );
}

export default AdminCommunity;
