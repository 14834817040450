import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './registrationPopUp.scss';

const RegistrationPopUp = () => {
    return (
        <div id="registrationPopUp">
           <div className="popup">
                <header>
                    <section>
                        <h1>회원가입이 완료되었습니다!🎉</h1>
                    </section>
                    <section>
                        <p>팀을 위한 협업 솔루션이 셋팅되고 있습니다.</p>
                        <p>완료까지 <span>최대 30분</span> 정도 소요됩니다.</p>
                    </section>
                </header>
                <body>
                    <section>
                        <h1>그 동안, 비즈내비를 탐색하며</h1>
                        <h1><span>사업에 필요한 정보</span>를 한 발 앞서 확인해보세요!🔎</h1>
                    </section>
                    <section>
                        <h1><span>정부사업부터 최신 시장·특허·기업동향</span>까지</h1>
                        <h1>모두 제공합니다.📈</h1>
                    </section>
                    <section>
                        <Link to="/login">
                            <button>➡ 로그인 하러가기</button>
                        </Link>
                    </section>
                </body>      
           </div>
        </div>
    );
};

export default RegistrationPopUp;