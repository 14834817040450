import "../css/RenewalMainMobile.scss";
import "../css/OpenMobile.scss";
import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

const RenewalMainMobile = () => {
  return (
    <div id="MainMobile">
      <div className="sec top">
        <strong className="top_txt wh">
          <span>중소벤처기업</span>이라면,
          <br />
          <span>비즈내비</span> 하나로
          <br />
          충분합니다
        </strong>
        <p className="wh sol">중소벤처기업 생존에 필요한 워크 솔루션</p>
        <div className="top_btns">
          <Link to="/signup/type" className="blue start">
            지금 무료로 시작하기
          </Link>
          <Link to="/intro/service" className="question">
            도입문의
          </Link>
        </div>
        <p className="wh sol">
          <strong>
            중소벤처기업 대표님,
            <br />
          </strong>
          혹시 이 업무들&nbsp;
          <strong>포기</strong>
          하셨나요?
        </p>
        <ul className="task_list">
          <li>
            <strong>스마트 Cash 관리</strong>
            <p className="desc">
              기업 현금흐름과 Runway,
              <br />
              체계적으로 관리할 수 있을까?
            </p>
            <p className="possible">
              <span>가능합니다</span>
            </p>
          </li>
          <li>
            <strong>실시간 경영활동 파악</strong>
            <p className="desc">
              우리 회사 구성원과 전사 업무현황,
              <br />
              심플하게 파악할 수 없을까?
            </p>
            <p className="possible">
              <span>가능합니다</span>
            </p>
          </li>
          <li>
            <strong>정부사업 수주</strong>
            <p className="desc">
              우리회사에 딱맞는 과제 찾고, 합격하는
              <br />
              정부사업 제안서 쓰기. 가능할까?
            </p>
            <p className="possible">
              <span>가능합니다</span>
            </p>
          </li>
          <li>
            <strong>기업 자금확보</strong>
            <p className="desc">
              자금이 필요 할 때,
              <br />
              도움받을 수 있는 정보 없을까?
            </p>
            <p className="possible">
              <span>가능합니다</span>
            </p>
          </li>
          <li>
            <strong>매출증대</strong>
            <p className="desc">
              흩어져있는 우리 회사 영업현황과
              <br />
              영업 내용을 아카이빙할 수는 없을까?
            </p>
            <p className="possible">
              <span>가능합니다</span>
            </p>
          </li>
          <li>
            <strong>IP Value UP</strong>
            <p className="desc">
              비싸게 만들어놓고 방치한 특허,
              <br />
              수익화할 수는 없을까?
            </p>
            <p className="possible">
              <span>가능합니다</span>
            </p>
          </li>
          <li>
            <strong>중소기업 특화 그룹웨어</strong>
            <p className="desc">
              어려운 기능 없이
              <br />
              직관적이고 쉬운 그룹웨어 없을까?
            </p>
            <p className="possible">
              <span>가능합니다</span>
            </p>
          </li>
          <li>
            <strong>전략적 의사결정 지원</strong>
            <p className="desc">
              비즈니스 방향성을 판단할 수 있는
              <br />
              종합적인 정보 파악 가능할까?
            </p>
            <p className="possible">
              <span>가능합니다</span>
            </p>
          </li>
        </ul>
        <div className="giveup">
          <strong>포기하지 마세요.</strong>
          <p>
            <strong>비즈내비</strong>에서는 <strong>충분히</strong> 가능합니다.
          </p>
        </div>
      </div>
      <div className="sec work">
        <div className="wrap">
          <img
            src={
              require("../../../assets/images/intro/grand/intro_m01.png")
                .default
            }
            alt="일잘하는 워크 솔루션"
          />
        </div>
      </div>
      <div className="sec cash_mg">
        <p className="sec_tit">자금관리</p>
        <strong className="sec_name">스마트 Cash 관리</strong>
        <p className="sec_desc">
          아직도 엑셀로 기업 자금 관리하시나요?
          <br />
          비즈내비 캐시플로우 계산기 & 지출관리로 기업 데이터
          <br />
          DB화 하고, 분석 인사이트를 얻어보세요.
        </p>
        <img
          src={
            require("../../../assets/images/intro/grand/intro_m02.png").default
          }
          alt="캐시플로우"
          className="img_m"
        />
        <img
          src={
            require("../../../assets/images/intro/grand/intro_m03.png").default
          }
          alt="캐시플로우"
        />
      </div>
      <div className="sec mg">
        <p className="sec_tit">경영관리</p>
        <strong className="sec_name">실시간 경영활동 파악 & 매출증대</strong>
        <p className="sec_desc">
          전사 경영사항과 업무현황을 아카이빙 해보세요.
          <br />
          투자유치, 투자보고 및 사업 현황 파악을 위한 기업의
          <br />
          소중한 자산이 될 수 있습니다.
        </p>
        <img
          src={
            require("../../../assets/images/intro/grand/intro_m04.png").default
          }
          alt="경영관리"
          className="img_m"
        />
        <img
          src={
            require("../../../assets/images/intro/grand/intro_m05.png").default
          }
          alt="경영관리"
          className="img_m"
        />
        <img
          src={
            require("../../../assets/images/intro/grand/intro_m06.png").default
          }
          alt="경영관리"
        />
      </div>
      <div className="sec busi">
        <p className="sec_tit">비즈니스 데이터</p>
        <strong className="sec_name">전략적 의사결정을 위한 정보</strong>
        <p className="sec_desc">
          사업방향 설정과 효과적 사업운영을 위한 정보들을
          <br />
          비즈내비에서 모두 찾아보세요
        </p>
        <img
          src={
            require("../../../assets/images/intro/grand/intro_m07.png").default
          }
          alt="비즈니스 데이터"
          className="img_m"
        />
        <img
          src={
            require("../../../assets/images/intro/grand/intro_m08.png").default
          }
          alt="비즈니스 데이터"
          className="img_m"
        />
        <img
          src={
            require("../../../assets/images/intro/grand/intro_m09.png").default
          }
          alt="비즈니스 데이터"
        />
      </div>
      <div className="sec gov">
        <p className="sec_tit">정부사업</p>
        <strong className="sec_name">정부사업 수주</strong>
        <p className="sec_desc">
          한끗차이를 만들어 합격하는 정부사업의 모든 정보
        </p>
        <img
          src={
            require("../../../assets/images/intro/grand/intro_m10.png").default
          }
          alt="정부사업"
          className="img_m"
        />
        <img
          src={
            require("../../../assets/images/intro/grand/intro_m11.png").default
          }
          alt="정부사업"
        />
      </div>
      <div className="sec gw">
        <p className="sec_tit">업무효율화</p>
        <strong className="sec_name">중소벤처기업 특화 그룹웨어</strong>
        <p className="sec_desc">
          중소벤처기업을 위해 복잡한 기능을 배제하고
          <br />
          가장 필요한 에센스만 담아 쉽고 편한 그룹웨어 입니다.
        </p>
        <img
          src={
            require("../../../assets/images/intro/grand/intro_m12.png").default
          }
          alt="업무효율화"
          className="img_m"
        />
        <img
          src={
            require("../../../assets/images/intro/grand/intro_m13.png").default
          }
          alt="업무효율화"
          className="img_m"
        />
        <img
          src={
            require("../../../assets/images/intro/grand/intro_m14.png").default
          }
          alt="업무효율화"
        />
      </div>
      <div className="sec tip">
        <p className="sec_tit">TIP</p>
        <strong className="sec_name">기업자금 확보</strong>
        <p className="sec_desc">
          기업 자금이 필요할 때, 도움받을 수 있는 정보는 없을까?
        </p>
        <img
          src={
            require("../../../assets/images/intro/grand/intro_m15.png").default
          }
          alt="업무효율화"
          className="img_m"
        />
        <img
          src={
            require("../../../assets/images/intro/grand/intro_m16.png").default
          }
          alt="업무효율화"
        />
      </div>
      <div className="sec value">
        <p className="sec_tit">경영관리</p>
        <strong className="sec_name">IP Value UP</strong>
        <p className="sec_desc">심플하고 쉽게 파악하는 전사 업무현황 파악</p>
        <img
          src={
            require("../../../assets/images/intro/grand/intro_m17.png").default
          }
          alt="경영관리"
          className="img_m"
        />
        <img
          src={
            require("../../../assets/images/intro/grand/intro_m18.png").default
          }
          alt="경영관리"
        />
      </div>
      <div className="sec free">
        <div className="free_info">
          <strong>
            결제정보 입력 없이
            <br />
            지금 바로 무료체험 해보세요!
          </strong>
          <Link to="/signup/type" className="start">
            지금 무료로 사용하기
          </Link>
        </div>
      </div>
      <div className="sec mobile">
        <img
          src={
            require("../../../assets/images/intro/grand/intro_m19.png").default
          }
          alt="모바일"
        />
      </div>
    </div>
  );
};

export default RenewalMainMobile;
