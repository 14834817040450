import React, { useContext } from "react";
import "./termsMobile.scss";
import memberContext from "../../store/Member";
import Terms from "../../const/Terms";

function TermsPrivateMobile() {
  const { auth } = useContext(memberContext);

  const redirect = () => {
    window.$Global.redirectHome(auth);
  };

  return (
    <div id="TermsMobile">
      <h2>IPNOW 개인정보처리방침</h2>
      <div dangerouslySetInnerHTML={{ __html: Terms.privateTxtMobile }} />
    </div>
  );
}

export default TermsPrivateMobile;
