import { useEffect, useState } from "react";
import Pagination from "../common/Pagination";
import CompetitorSearchPop from "./CompetitorSearchPop";
import "./css/CompetitorSearch.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import axios from "axios";
import moment from "moment";
import CompetitorStrategyPop from "./CompetitorStrategyPop";
import Loading from "../loading/Loading";

const CompetitorSearch = () => {
  const history = useHistory();
  const companyIdx = localStorage.getItem("company_idx");
  const [isSchPopOpen, setIsSchPopOpen] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [total, setTotal] = useState(0);
  const [resultTotal, setResultTotal] = useState(0);
  const [curPage, setCurPage] = useState(1);
  const [isLoading, setIsLoding] = useState(false);
  const [isCompFrameShow, setIsCompFrameShow] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [bizNo, setBizNo] = useState(null);
  let offset = 0;
  let limit = 50;
  let [listSpecial, setListSpecial] = useState(false); // false: 기업정보, true: 특구
  const [strategyArr, setStrategyArr] = useState([
    { cd: 1, name: "반도체·디스플레이", selected: false },
    { cd: 2, name: "사이버 보안", selected: false },
    { cd: 4, name: "수소", selected: false },
    { cd: 8, name: "우주항공·해양", selected: false },
    { cd: 16, name: "이차전지", selected: false },
    { cd: 32, name: "인공지능", selected: false },
    { cd: 64, name: "차세대 원자력", selected: false },
    { cd: 128, name: "차세대 통신", selected: false },
    { cd: 256, name: "첨단 모빌리티", selected: false },
    { cd: 512, name: "첨단 바이오", selected: false },
    { cd: 1024, name: "첨단로봇·제조", selected: false },
    { cd: 2048, name: "양자", selected: false },
  ]);
  const [selectedStrategy, setSelectedStrategy] = useState([]);
  const [selectedStrategyStr, setSelectedStrategyStr] = useState("");
  const goStatistic = () => {
    history.push("/competitor/statistic", { totalNum: resultTotal });
  };

  const getCompanyInfo = async () => {
    setIsLoding(true);
    await axios
      .get(`https://api.biznavi.co.kr/api/v3/company`, {
        params: { offset: curPage - 1, limit: limit, keyword: keyword },
      })
      .then((res) => {
        //console.log("res1:", res);
        setCompanyList(res.data.result);
        setTotal(res.data.total);
        setResultTotal(res.data.total);
        setIsLoding(false);
      });
  };

  const getCompanyMatch = async () => {
    setIsLoding(true);
    await axios
      .get(`https://api.biznavi.co.kr/api/v3/match_company`, {
        params: {
          offset: curPage - 1,
          limit: limit,
          keyword: keyword,
          tech_type: selectedStrategyStr,
        },
      })
      .then((res) => {
        console.log("res2:", res);
        setCompanyList(res.data.result);
        setResultTotal(res.data.total);
        setIsLoding(false);
      });
  };

  const callInfo = () => {
    if (listSpecial) {
      getCompanyMatch();
    } else {
      getCompanyInfo();
    }
  };

  const formatDate = (date) => {
    const momentDate = moment(date);
    return momentDate.format("YYYY-MM-DD");
  };

  const getClass = (str, delimiter) => {
    if (str) {
      const parts = str.split(delimiter);
      return parts[0];
    }
    return "";
  };

  const getClassByArr = (arr) => {
    if (arr.length) {
      return arr[0].company_group_name;
    }
    return "";
  };

  const handleCheckboxChange = (cd) => {
    const newStrategyArr = strategyArr.map((item) =>
      item.cd === cd ? { ...item, selected: !item.selected } : item
    );
    setStrategyArr(newStrategyArr);
  };

  const makeCodesString = (arr) => {
    setSelectedStrategyStr(arr.join(":"));
  };

  const compInfo = (biz) => {
    setBizNo(biz);
    setIsCompFrameShow(true);
  };

  useEffect(() => {
    callInfo();
  }, []);

  useEffect(() => {
    callInfo();
  }, [curPage, listSpecial, selectedStrategyStr]);

  useEffect(() => {
    // strategyArr에서 selected 속성이 true인 요소만 필터링
    const selectedItems = strategyArr.filter((item) => item.selected);
    // 필터링된 요소들의 cd 값을 추출
    const selectedCds = selectedItems.map((item) => item.cd);
    setSelectedStrategy(selectedCds);
  }, [strategyArr]);

  useEffect(() => {
    makeCodesString(selectedStrategy);
  }, [selectedStrategy]);

  return (
    <div id="CompetitorSearch">
      <div className="content_box">
        <strong className="top_txt">
          아래의 검색 조건으로&nbsp;
          <span className="bright_blue">
            총 {total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}개{" "}
          </span>
          기업을 찾았습니다.
        </strong>
        <div>
          <div className="border_light_gray sch_box mb20">
            <span className="sch_tit">카테고리</span>
            <ul className="tag_list">
              {companyIdx === "MOZQYC25XKG8KEE0" ? (
                <li
                  className={`tag bg_bright_blue color_white ${
                    listSpecial ? "on" : ""
                  }`}
                  onClick={() => setListSpecial((prev) => !prev)}
                >
                  #특구사업
                </li>
              ) : (
                ""
              )}
              <li className="tag bg_bright_green color_white">#VC투자기업</li>
              <li className="tag bg_light_gray">#테크노파크 기업</li>
              <li className="tag bg_light_blue">#상장기업</li>
            </ul>
          </div>
        </div>
        {/* <div className="keyword mb20">
          <input type="text" placeholder="키워드로 검색해보세요." />
          <button>
            <i className="icon_search_blue"></i>
          </button>
        </div> */}
        <div>
          <div className="border_light_gray sch_box mb30">
            <span className="sch_tit">12대 국가전략기술</span>
            <ul className="tag_list condition">
              {strategyArr
                .filter((item) => item.selected)
                .map((item) => (
                  <li key={item.cd} className="tag bg_light_gray">
                    #{item.name}
                    <i
                      className="icon_delete"
                      onClick={() => handleCheckboxChange(item.cd)}
                    ></i>
                  </li>
                ))}
            </ul>
            <button
              className="bg_light_blue bright_blue"
              onClick={() => setIsSchPopOpen((prev) => !prev)}
            >
              선택하기
            </button>
          </div>
        </div>
        <div className="comp_list pt20 mt-30">
          <div className="num mb20">
            <strong className="bright_blue">
              {resultTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </strong>
            건 /
            <strong>
              {total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </strong>
            건
            <button
              className="bg_bright_blue color_white pa10 ml20"
              onClick={() => goStatistic()}
            >
              {resultTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}건
              결과값 내 비교분석하기
            </button>
          </div>
        </div>
        <table>
          <colgroup>
            <col width="100px" />
            <col width="100px" />
            <col width="*" />
            <col width="*" />
            <col width="100px" />
            <col width="*" />
            <col width="100px" />
            <col width="*" />
            <col width="*" />
            <col width="*" />
            <col width="*" />
            <col width="80px" />
          </colgroup>
          <thead>
            <tr>
              <th>구분</th>
              <th>지역</th>
              <th>회사명</th>
              <th>대표자명</th>
              <th>매출액</th>
              <th>12대 전략기술</th>
              <th>50대 중점기술</th>
              <th>기술평가</th>
              <th>국문연구키워드</th>
              <th>영문연구키워드</th>
              <th>연구분야</th>
              <th>IP 보유건수</th>
            </tr>
          </thead>
          <tbody>
            {companyList.map((item) => (
              <tr
                key={item.company_idx}
                onClick={() => compInfo(item.company_bizno)}
              >
                <td>{getClassByArr(item.grouptags)}</td>
                <td>{item.sp_area_01}</td>
                <td>{item.company_name}</td>
                <td>{item.company_ceo}</td>
                <td>
                  {item.finance?.enpSaleAmt
                    ? item.finance?.enpSaleAmt.val
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : ""}
                </td>
                <td>
                  {item.tech_type_string?.name
                    ? item.tech_type_string?.name
                    : ""}
                </td>
                <td>
                  {item.tech_type_sub_string?.name
                    ? item.tech_type_sub_string?.name
                    : ""}
                </td>
                <td>{item.tech_grade?.total?.str}</td>
                <td>{item.rnd_kor_keyword}</td>
                <td>{item.rnd_eng_keyword}</td>
                <td>{item.rnd_category}</td>
                <td>{item.company_ipcnt}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* <div className="pagination">
          <button>
            <i className="icon_page_start"></i>
          </button>
          <button>
            <i className="icon_page_prev"></i>
          </button>
          <button>1</button>
          <button>2</button>
          <button>3</button>
          <button>4</button>
          <button>
            <i className="icon_page_next"></i>
          </button>
          <button>
            <i className="icon_page_end"></i>
          </button>
        </div> */}
        <Pagination
          curPage={curPage}
          lastNum={resultTotal / limit}
          onClick={setCurPage}
          scrollType
        />
      </div>
      {isSchPopOpen && (
        <CompetitorStrategyPop
          data={strategyArr}
          close={setIsSchPopOpen}
          handleCheckboxChange={handleCheckboxChange}
        />
      )}
      {isSchPopOpen && <div className="dimmed"></div>}
      {isCompFrameShow && (
        <div className="comp_frame">
          <div className="close">
            <i
              class="icon_exit_gray"
              onClick={() => setIsCompFrameShow(false)}
            ></i>
          </div>

          <iframe
            src={`https://dev.biznavi.co.kr/competitor/detailsNew?applicant=0&bizno=${bizNo}`}
            width="100%"
            height="100%"
            title="기업정보 더보기"
          />
        </div>
      )}
      {isLoading && <Loading show={isLoading} />}
    </div>
  );
};

export default CompetitorSearch;
