import "./css/DashBoardTitle.scss";

const DashBoardTitle = ({ text }) => {
  return (
    <div id="DashBoardTitle">
      <p>{text}</p>
    </div>
  );
};

export default DashBoardTitle;
