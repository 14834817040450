import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import memberContext from "../../store/Member";
import UnEventContext from "../../store/UnEvent";
import CommonContext from "../../store/Common";
import MobilePopupContext from "../../store/MobilePopup";

function RenewalLogout({ history }) {
  const location = useLocation();
  const unEvent = useContext(UnEventContext);
  const memberInfo = useContext(memberContext);
  const { isMobile } = useContext(CommonContext);
  const { mobilePopup, setMobilePopup } = useContext(MobilePopupContext);
  const auth = memberInfo.auth;
  const isLogin = auth != null && auth != undefined;
  
  window.$Global.logout('parent');
}


export default RenewalLogout;
