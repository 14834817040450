import "./scss/GroupwareCashflow.scss";

const CashflowStep = ({ step }) => {
  const stepInfo = ["매출", "비용", "영업외매출"]; //"가용잔고" ,"투자활동" 제외
  return (
    <div className="steps">
      <div className="wrap">
        <ul>
          {stepInfo.map((item, idx) => (
            <li
              key={idx}
              className={`bg_wh rounded business ${
                idx + 1 === step - 1 ? "on" : ""
              }`}
            >
              <div className="name">
                <p>STEP.{idx + 1}</p>
                <p>{item}</p>
              </div>
              <div class="bar rounded"></div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CashflowStep;
