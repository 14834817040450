import GroupwareSideBar from "../../GroupwareSideBar";
import { groupCashflow } from "../../groupwarePath";
import GwAPI from "../../../../API/groupware";
import "./scss/GroupwareCashflow.scss";
import { useEffect, useState } from "react";
import TopBanner from "./TopBanner";
import Top from "./Top";
import { Link } from "react-router-dom/cjs/react-router-dom";

const CashflowList = () => {
  const step = "list";
  const [cashflowList, setcashflowList] = useState([]);

  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    return GwAPI.getCashPlanList().then((res) => {
      if (res.data.cashflowReportList.length > 0) {
        const formattedList = res.data.cashflowReportList.map((item) => {
          const formattedDate = formatDate(item.reg_date);
          return {
            ...item,
            updated_Date: formattedDate,
          };
        });
        return setcashflowList(formattedList);
      }
    });
  };

  const delCashflow = (id) => {
    const isConfirmed = window.confirm("정말로 삭제하시겠습니까?");

    if (isConfirmed) {
      return GwAPI.deleteCashPlan({ report_id: id }).then((res) => {
        if (res.status === 200) {
          getList();
        } else {
          alert("삭제를 실패했습니다.");
        }
      });
    }
  };

  const formatDate = (date) => {
    const dateString =
      typeof date === "number"
        ? new Date(date).toLocaleDateString("ko-KR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
        : date;

    const matched = dateString.match(/(\d{4})\. (\d{2})\. (\d{2})\./);
    if (matched) {
      return `${matched[1]}-${matched[2]}-${matched[3]}`;
    }
    return dateString;
  };

  return (
    <div id="GroupwareWorks">
      <GroupwareSideBar currentPath={groupCashflow} />
      <div className="commute_wrapper">
        <div id="GroupwareCashflow">
          <TopBanner />
          <Top page={step} />
          <div className="cashflow_list wrap bg_wh rounded">
            <table>
              <colgroup>
                <col />
                <col width="20%" />
                <col width="20%" />
                <col width="100px" />
                <col width="100px" />
                <col width="100px" />
              </colgroup>
              <thead>
                <tr>
                  <th>업데이트 날짜</th>
                  <th>상태</th>
                  <th>작성자</th>
                  <th>리포트</th>
                  <th>수정</th>
                  <th>삭제</th>
                </tr>
              </thead>
              <tbody>
                {cashflowList.length ? (
                  cashflowList.map((item) => (
                    <tr
                      key={item.report_id}
                      className={`item ${item.report_id}`}
                    >
                      <td>{item.updated_Date}</td>
                      <td>
                        {item.is_completed === 0 ? (
                          <span className="blue">작성중</span>
                        ) : (
                          <span className="red">완료</span>
                        )}
                      </td>
                      <td>{item.reg_name}</td>
                      <td>
                        <Link
                          className="view_btn btn"
                          to={`/cashflow/business/view?report_id=${item.report_id}`}
                        >
                          보기
                        </Link>
                      </td>
                      <td>
                        <Link
                          className="mod_btn btn"
                          to={`/cashflow/business/bizAct?isIncome=1&type=1&step=2&report_id=${item.report_id}&mod=1`}
                        >
                          수정
                        </Link>
                      </td>
                      <td>
                        <button
                          className="del_btn btn"
                          onClick={() => delCashflow(item.report_id)}
                        >
                          삭제
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5}>데이터가 없습니다.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashflowList;
