import { useContext } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import memberContext from "../../store/Member";
import "./HeaderPersonal.scss";

const HeaderPersonal = () => {
  const memberInfo = useContext(memberContext);
  const auth = memberInfo.auth;
  const isLogin = auth != null && auth != undefined;

  const onClickLogout = () => window.$Global.logout("self");

  return <div id="HeaderPersonal">
    <div className="top">
      <div className="menu_box">
        <div className="logo">
          <Link to="/">
            <img
              src={
                require("../../assets/images/common/logo_kor.svg")
                  .default
              }
              alt="logo"
              className="logo_img"
            />
          </Link>
        </div>
        <ul class="menus">
          {/* <li><Link to="/intro/main?pmode=on">서비스 소개</Link></li>
          <li><Link to="/intro/price?pmode=on">가격정책</Link></li> */}
          <li><Link to="/marketInfo/category?pmode=on">시장정보</Link></li>
          <li><Link to="/researchTech/category?pmode=on">기술동향</Link></li>
          <li><Link to="/taskManage/list?pmode=on">정부사업</Link></li>
          <li><Link to="/competitor/main?pmode=on">기업정보</Link></li>
          {/* <li><Link to="/intro/main?pmode=on">블로그</Link></li> */}
        </ul>
      </div>
      <div className="service_menus">
        <ul className="business">
          <li><Link to="/intro/main?pmode=on">서비스 소개</Link></li>
          <li><Link to="/intro/price?pmode=on">가격정책</Link></li>
          <li>
            <Link to="/groupware/office?section=command&command=showCommandList&method=receive_list&pmode=on">
              업무관리
            </Link>
          </li>
        </ul>
        <div className="member_menus">
          {isLogin 
          ? <button className="logout member" onClick={() => onClickLogout()}>로그아웃</button> 
          : <Link to="/login" className="login member">로그인</Link>}
        </div>
      </div>
    </div>
  </div>
}

export default HeaderPersonal;