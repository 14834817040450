import { exportTypedArrayMethod } from "core-js/internals/array-buffer-view-core";

export const nationType = [
  "KR",
  "PCT",
  "US",
  "EP",
  "CN",
  "JP",
  "TW", // 대만
  "RU", // 러시아
  "NZ", // 뉴질랜드
  "IN", // 인도
  "VN", // 베트남
  "AU", // 호주
  "CA", // 캐나다
  "BR", // 브라질
  "SP", // 스페인
  "MO", // 마카오
  "SG", // 싱가폴
  "MX", // 멕시코
  "IL", // 이스라엘
  "PH", // 필리핀
  "HK", // 홍콩
  "EA", // EA
  "ZA", // 남아프리카 공화국
];

export const nationStrType = [
  "한국",
  "PCT",
  "미국",
  "유럽",
  "중국",
  "일본",
  "대만",
  "러시아",
  "뉴질랜드",
  "인도",
  "베트남",
  "호주",
  "캐나다",
  "브라질",
  "스페인",
  "마카오",
  "싱가폴",
  "멕시코",
  "이스라엘",
  "필리핀",
  "홍콩",
  "EA",
  "남아프리카 공화국",
];

export const tradeMarkNationType = [
  "KR",
  "MADRID",
  "US",
  "EP",
  "CN",
  "JP",
  "TW", // 대만
  "RU", // 러시아
  "NZ", // 뉴질랜드
  "IN", // 인도
  "VN", // 베트남
  "AU", // 호주
  "CA", // 캐나다
  "BR", // 브라질
  "SP", // 스페인
  "MO", // 마카오
  "SG", // 싱가폴
  "MX", // 멕시코
  "IL", // 이스라엘
  "PH", // 필리핀
  "HK", // 홍콩
  "EA", // EA
  "ZA", // 남아프리카 공화국
];

export const designatedCountryEp = [
  "AL", // 알바니아
  "AT", // 오스트리아
  "BE", // 벨기에
  "BG", // 불가리아
  "CH", // 스위스
  "CY", // 키프로스
  "CZ", // 체코
  "DE", // 독일
  "DK", // 덴마크
  "EE", // 에스토니아
  "ES", // 스페인
  "FI", // 핀란드
  "FR", // 프랑스
  "GB", // 영국
  "GR", // 그리스
  "HR", // 크로아티아
  "HU", // 헝가리
  "IE", // 아일랜드
  "IS", // 아이슬란드
  "IT", // 이탈리아
  "LI", // 리히텐슈타인
  "LT", // 리투아니아
  "LU", // 룩셈부르크
  "LV", // 라트비아
  "MC", // 모나코
  "MK", // 북마케도니아
  "MT", // 몰타
  "NL", // 네덜란드
  "NO", // 노르웨이
  "PL", // 폴란드
  "PT", // 포르투갈
  "RO", // 루마니아
  "RS", // 세르비아
  "SE", // 스웨덴
  "SI", // 슬로베니아
  "SK", // 슬로바키아
  "SM", // 산마리노
  "TR", // 튀르키예
  "SG", // 싱가포르
];
