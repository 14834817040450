import "./css/CompetitorInfo.scss";
import React, { useEffect, useState } from "react";
import logo from "../../assets/images/common/logo_findBiz.svg";
import { useParams } from "react-router-dom";
import axios from "axios";
import CompetitorLoading from "./CompetitorLoading";
import InfoAPI from "../../API/info";
import session1 from "../../assets/images/common/companysearch.png";

const CompetitorInfo = ({ history }) => {
  const { idx } = useParams();
  const [companyInfo, setCompanyInfo] = useState(
    //history.location.state.company_data
    {}
  );
  const [competitorInfo, setCompetitorInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showCompetitor, setShowCompetitor] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getCompetitorInfo();
  }, [idx]);

  const getCompetitorInfo = async () => {
    await axios
      .get(`/api/v3/get_company_info`, {
        params: { company: idx },
      })
      .then((res) => {
        setCompanyInfo(res.data.info);
        setCompetitorInfo(res.data);
        setIsLoading(false);
      });
  };

  const handleNavigate = () => {
    let payload = {
      company_idx: companyInfo.applicant_code || 0,
      company_name: companyInfo.company_name,
      company_bizno: Number(idx),
    };

    InfoAPI.insertCompanyResearchHistory(payload).then((res) => {});

    history.push(
      `/competitor/details?applicant=${
        companyInfo.applicant_code || ""
      }&bizno=${idx}`
    );
  };

  return (
    <div id="CompetitorInfo">
      <div className="tutorial-box pc">
        <div className="wrap_text">
          <h2>기업정보 검색</h2>
        </div>
        <div className="content">
          <div className="title">
            <p>
              <span>기업명을 검색하여 재무·기술·정부사업 참여현황 등</span>
              <br />
              <span>기업의 최신 현황과 </span>
              <br />
              <span>분석정보를 확인해 보세요. </span>
            </p>
          </div>
          <img src={session1} alt="session1" />
        </div>
      </div>
      <div className="company_info_wrapper">
        {isLoading ? (
          <CompetitorLoading />
        ) : (
          <div>
            <h5>
              <span>{companyInfo.company_name} </span> 기업 상세정보 보기
            </h5>
            <button onClick={() => history.goBack()} className="btn_back">
              뒤로 돌아가기
            </button>
            <div className="company_info_items">
              <div className="company_info_item">
                <img src={logo} />
                <div className="item_text">
                  <p>{companyInfo.company_name}</p>
                  <p>대표명: {companyInfo.company_ceo}</p>
                  <div className="item_text_category">
                    {companyInfo?.grouptags?.map((keyword) => {
                      return <p>{keyword.company_group_name}</p>;
                    })}
                  </div>
                </div>
                <button onClick={() => handleNavigate()}>기업 분석정보</button>
              </div>
            </div>
          </div>
        )}
        {!isLoading && (
          <div>
            <h5>
              <span>{companyInfo.company_name}</span>의 기업 정보 분석
              <button
                className="show_competitor_btn"
                onClick={() => setShowCompetitor(!showCompetitor)}
              >
                {showCompetitor ? "접기" : "보기"}
              </button>
            </h5>
            {showCompetitor ? (
              <div className="company_info_items custom_scroll">
                {Boolean(!competitorInfo?.competitorlist) ? (
                  <p>기업 정보 없음</p>
                ) : (
                  competitorInfo?.competitorlist?.map((item) => {
                    return (
                      <div className="company_info_item">
                        <img src={logo} />
                        <div className="item_text">
                          <p>{item.info.company_name}</p>
                          <p>대표명: {item.info.company_ceo}</p>
                          {item.grouptag && (
                            <div className="item_text_category">
                              {item.grouptag.slice(0, 8).map((keyword) => {
                                return <p>{keyword.company_group_name}</p>;
                              })}
                            </div>
                          )}
                        </div>
                        <button
                          onClick={() => {
                            if (item.info.company_bizno) {
                              history.push(
                                `/competitor/info/${item.info.company_bizno}`
                              );
                            } else {
                              alert("분석 정보를 확인할 수 없는 회사입니다.");
                            }
                          }}
                        >
                          기업 정보 확인하기
                        </button>
                      </div>
                    );
                  })
                )}
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default CompetitorInfo;
