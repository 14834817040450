import React, { useContext } from "react";
import "./termsMobile.scss";
import memberContext from "../../store/Member";
import Terms from "../../const/Terms";

function TermsUseMobile() {
  const { auth } = useContext(memberContext);

  const redirect = () => {
    window.$Global.redirectHome(auth);
  };

  return (
    <div id="TermsMobile">
      <h2>IPNOW 서비스 이용약관</h2>
      <div dangerouslySetInnerHTML={{ __html: Terms.useTxtMobile }} />
    </div>
  );
}

export default TermsUseMobile;
