import React, { useEffect, useState } from "react";
import "./css/AnnualFeeReview.scss";
import AnnualPay from "../../API/annualPay";
import AnnualFeeReviewState from "./AnnualFeeReviewState";

const AnnualFeeReview = ({
  annualFeeData,
  setShowPopup,
  showPatent,
  isAdmin,
}) => {
  const [data, setData] = useState([]);
  const date = new Date();
  const year = date.getFullYear();

  useEffect(() => {
    showPatent
      ? AnnualPay.getPatentPayList(annualFeeData.patent_idx).then((res) => {
          setData(res.data.annualPayList);
          console.log(res.data);
        })
      : AnnualPay.getTradeMarkPayList(annualFeeData.trademark_idx).then(
          (res) => {
            setData(res.data.annualPayList);
            console.log(res.data);
          }
        );
  }, []);

  return (
    <div id="AnnualFeeReview">
      <div className="annual_fee_review_header">
        <div>
          <h2>연차료 납부 검토</h2>
          <i
            className="icon_exit_gray"
            onClick={() =>
              setShowPopup({
                first: false,
                second: false,
              })
            }
          />
        </div>
        <p>
          <i className="icon_badge_info_gray" /> 연도를 선택해 각 연도별
          연차료에 대해 검토가 가능합니다.
        </p>
      </div>
      <div className="annual_fee_review_body">
        <div className="review_manage_number">
          <p>관리번호</p>
          <p>{annualFeeData.manage_number}</p>
        </div>
        <div className="annual_fee_list">
          <p className="title">특허연차</p>
          <div>
            {data?.map((el) => {
              if (el.year > year) return;
              return (
                <div
                  className={
                    "annual_fee_item " + (year === el.year ? "highlight" : "")
                  }
                  onClick={() => {
                    setShowPopup({
                      first: false,
                      second: true,
                    });
                    annualFeeData.annual_pay_idx = el.idx;
                  }}
                >
                  <div>
                    <p>{el.payment_year}년차</p>
                    <AnnualFeeReviewState
                      type={el.review_state}
                      year={el.year}
                    />
                  </div>
                  <i className="icon_arrow_right_s" />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnualFeeReview;
