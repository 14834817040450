import React from "react";

function Loading({ show }) {
  return (
    <>
      {show && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1000,
          }}
        >
          <div className="progress-bar">
           <div className="progress-bar-value"></div>
          </div>
          {/* <span className="spinner" /> */}
          {/*<img src={require('../../assets/images/common/loadingbar.gif').default} />*/}
        </div>
      )}
    </>
  );
}

export default React.memo(Loading);
