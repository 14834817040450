import React, { useEffect, useState } from "react";
import "./css/ConsultSideBar.scss";
import ConsultingAPI from "../../API/consulting";

const ConsultSideBar = () => {
  const [category, setCategory] = useState([]);

  useEffect(() => {
    ConsultingAPI.getCategoryList().then((res) =>
      setCategory(res.data.categoryList)
    );
  }, []);

  return (
    <div id="ConsultSideBar">
      <h2>컨설턴트 찾기</h2>
      <ul>
        <li idx={-1}>전체</li>
        {category.map((item) => {
          return <li idx={item.idx}>{item.name}</li>;
        })}
      </ul>
    </div>
  );
};

export default ConsultSideBar;
