import React from 'react';
import DatePicker from "react-datepicker";
import ko from 'date-fns/locale/ko';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../datepicker/datepicker.scss';
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";

registerLocale('ko', ko);
setDefaultLocale(ko);

function DatePickerCustom({data, onChangeDatePicker, minDate, maxDate, readOnly}) {
    const setWeekendColor = (date) => {
        return (date.getDay() === 0 || date.getDay() === 6) ? 'color_red' : ''
    };
    const range = (start, end) => {
        return new Array(end - start).fill().map((d, i) => i + start);
    };
    const years = range(1900, getYear(new Date()) + 20, 1);
    const months = ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"];

    return (
        <DatePicker
            renderCustomHeader={({
                                  date,
                                  changeYear,
                                  changeMonth,
                                  decreaseMonth,
                                  increaseMonth,
                                  prevMonthButtonDisabled,
                                  nextMonthButtonDisabled
                               }) => (
                <div className="date_header">
                    <button className="btn_date_prev" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}/>
                    <select
                        value={getYear(date)}
                        onChange={({ target: { value } }) => changeYear(value)}
                        style={{width: 70, height: 24, margin: 0, marginRight: 15, padding: 0, paddingLeft: 10, border: "none"}}
                    >
                        {
                            years.map(option => (<option key={option} value={option}>{option}</option>))
                        }
                    </select>
                    <select
                        value={months[months.indexOf(getMonth(date) + 1 + "월")]}
                        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                        style={{width: 60, height: 24, margin: 0, padding: 0, paddingLeft: 5,  border: "none"}}
                    >
                        {
                            months.map(option => (<option key={option} value={option}>{option}</option>))
                        }
                    </select>
                    <button className="btn_date_next" onClick={increaseMonth} disabled={nextMonthButtonDisabled}/>
                </div>
            )}
            selected={Number(data) || new Date()}
            onChange={onChangeDatePicker}
            customInput={<button className="icon_calendar"/>} dayClassName={date => setWeekendColor(date)}
            minDate={minDate}
            maxDate={maxDate}
            readOnly={readOnly}
            popperModifiers={{
                preventOverflow: {
                    enabled: true
                }
            }}
        />
    );
};

export default DatePickerCustom;
