export default {
  paymentTxt:
    "제18조 (결제 취소 및 환불 규정)<br/>" +
    "① 회사는 부득이한 사정으로 유료서비스를 지속할 수 없을 경우, 이용자가 잔여 이용요금을 요구할 경우, 이용자가 지정하는 구좌로 환불합니다. 단, 잔여이용료 환불 시 이는 회사가 규정한 환불 정책에 따릅니다.<br/>" +
    "② 요금 선납 이용자가 유료서비스 이용신청을 취소하고자 하는 경우에는 취소 사유를 명기하여 본인이 직접 고객센터로 전화나 고객지원을 통하여 취소 신청할 수 있습니다.<br/>" +
    "③ 취소 또는 환불 신청을 받은 회사는 각 서비스의 각 인터넷서비스에 명기된 환불 정책에 따라 취소 또는 환불 신청에 응할 수 있습니다. 단, 공사는 다음 각 호에 해당하는 경우 이용자의 환불신청을 승낙하지 아니 할 수 있습니다.<br/>" +
    "1. 이용자의 실수로 이용기간 내에 서비스를 이용하지 못한 경우<br/>" +
    "2. 환불관련 제비용이 잔여이용료를 초과할 경우<br/>" +
    "3. 서비스에서 탈퇴한 후, 환불을 요구할 경우<br/>" +
    "4. 구입한 상품 또는 온라인 콘텐츠 등에 특별한 하자가 없는데도 불구하고 상습적인 환불요청 또는 계약 취소 등으로 공사의 업무를 방해하는 경우. 단, 당해 회원의 계약취소 및 환불비율이 공사의 평균 계약취소 및 환불율보다 50%이상 높을 경우에는 상습적인 것으로 인정될 수 있음<br/>" +
    "5. 기타 정당한 환불사유로 판명되지 않은 경우<br/>" +
    "④ 공사는 이용자로부터 해제 또는 해지 통보를 받은 경우 3영업일 이내에 온라인콘텐츠에 대한 대금을 환급합니다.<br/>단, 실물 상품의 경우에는 각 인터넷서비스에 명기된 환불 정책을 따르며, 상품 수취 확인 후에 3영업일 이내에 대금을 환급합니다.<br/>" +
    "⑤ 사업자는 이용대금의 결제와 동일한 방법으로 결제 대금을 환불해야하며 동일한 방법으로 환불이 불가능할 때에는 이를 사전에 고지하여야 합니다.<br/>" +
    "⑥ 공사가 제공하는 서비스 중 유료로 파일을 다운로드 받는 상품의 경우, 다운로드 즉시 회수가 불가능하며 상품가치가 사용자에게 전달되는 상품이므로 원칙적으로 환불이 불가합니다.<br/>" +
    "⑦ 공사는 과오금이 발생한 경우 이용대금 결제와 동일한 방법으로 결제대금 전액을 환불하여야합니다. 다만 동일한 방법으로 환불이 불가능할 때에는 이를 사전에 고지합니다. 또한 공사는 회원이 주장하는 과오금에 대해 환불을 거부할 경우에 정당하게 이용대금이 부과되었음을 입증할 책임을 집니다.<br/>" +
    "⑧ 공사의 책임있는 사유로 과오금이 발생한 경우 사업자는 수수료 등에 관계없이 과오금 전액을 환불하여야하며, 이용자의 책임 있는 사유로 과오금이 발생한 경우, 공사가 과오금을 환불하는 데 소용되는 비용은 합리적인 범위내에서 이용자가 부담합니다.<br/>" +
    "⑨ 공사는 과오금의 환불절차를 콘텐츠이용자보호지침에 따라 처리합니다.<br/>",
  useTxt:
    "이용약관<br/>" +
    "                        제1조 (목적)<br/>" +
    "                        이 약관은 주식회사 아이피나우(이하 ‘회사’)이 회원에게 IPNOW 서비스를 제공하기 위하여 운영하는 https://www.ipnow.co.kr/ (이하 ‘IPNOW’)의 이용과 관련하여 회사와 회원의 권리 및 의무 기타 필요한 사항을 규정함을 목적으로 합니다.<br/>" +
    "<br/>" +
    "                        제 2 조 (정의)<br/>" +
    "                        이 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br/>" +
    "<br/>" +
    "                        ① 서비스 : 사유 여하를 불문하고, 회사가 운영하는 서비스를 통하여 회원에게 온라인 또는 모바일을 통해 제공하는 일체의 용역(회사와 제휴한 온라인서비스 제공자가 제공하는 용역을 회원이 이용하는 경우에는 제휴 온라인서비스 제공자가 제공하는 용역을 포함합니다)<br/>" +
    "                        ② 유료서비스 : 회사가 유료로 제공하는 각종 온라인디지털콘텐츠(각종 정보콘텐츠, 데이터, 정보 등 유료콘텐츠를 포함) 기타 일체의 서비스<br/>" +
    "                        ③ 회원 : 회사의 서비스에 접속하여 이 약관에 따라 회사와 이용계약을 체결한 뒤 회사가 제공하는 서비스를 이용하는 고객<br/>" +
    "                        ④ 아이디(ID) : 회원의 식별 및 서비스 이용을 위하여 회원이 선정하고 회사가 승인하는 문자 또는 숫자의 조합<br/>" +
    "                        ⑤ 비밀번호 : 서비스를 이용함에 있어, 회원의 아이디(ID)와 일치되는 회원임을 확인하고, 회원의 비밀 보호를 위해 회원 자신이 설정한 문자 또는 숫자의 조합<br/>" +
    "                        ⑥ 포인트 : 마일리지 등과 같이 서비스의 효율적 이용을 위해 회사가 임의로 책정 또는 지급, 조정할 수 있는 재산적 가치 없는 서비스상의 가상 데이터<br/>" +
    "                        ⑦ 게시물 : 회원이 서비스를 이용함에 있어, 서비스상에 게시한 부호ㆍ문자ㆍ음성ㆍ음향ㆍ화상ㆍ동영상 기타 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크<br/>" +
    "                        ⑧ 해지 : 서비스 개통 후 회사 또는 회원이 이용계약의 효력을 상실시키는 행위<br/>" +
    "                        제3조 (약관의 게시 및 개정)<br/>" +
    "                        ① 회사는 이 약관의 내용을 회원이 쉽게 확인할 수 있도록 아이피나우 인터넷 홈페이지(www.ipnow.co.kr) 서비스 초기 화면에 이를 게시합니다.<br/>" +
    "                        ② 회사는 회원에게 제공하는 서비스의 품질 향상 기타 필요한 경우, ‘정보통신망이용촉진및정보보호등에관한법률(이하 ‘정보보호법’)’, ‘약관의규제에관한법률(이하 ‘약관규제법’)’ 기타 관련법령을 위배하지 아니하는 범위 내에서 이 약관을 개정할 수 있습니다.<br/>" +
    "                        ③ 회사가 이 약관을 개정하고자 할 경우, 회사는 개정 약관의 효력발생일을 명시하여 현행 약관과 함께 개정 약관의 효력발생일 30일 전부터 1항의 방법으로 개정약관을 공지합니다. 단, 회원의 권리ㆍ의무에 중대한 영향을 미치는 개정이 아닌 때에는 효력발생일 7일 전부터 이를 공지할 수 있습니다.<br/>" +
    "                        ④ 개정 약관의 효력발생일 이후 회원이 서비스를 이용하는 경우, 회사는 회원이 개정 약관에 동의한 것으로 봅니다. 단, 회원이 개정 약관에 동의하지 아니할 때에는 회원은 회사에 대하여 회원의 탈퇴 기타 이용계약을 해지할 수 있습니다.<br/>" +
    "                        ⑤ 유료서비스를 이용하는 회원이 이 약관의 개정에 동의하지 아니하여 이용계약을 해지한 때에는 이 약관이 정한 환불정책에 따릅니다.<br/>" +
    "                        ⑥ 회사는 서비스의 품질 향상, 회원 의사의 충족 기타 필요한 경우, 이 약관과 달리 개별약관을 둘 수 있고, 이 경우 1항 내지 5항을 준용합니다.<br/>" +
    "                        제4조 (약관의 적용)<br/>" +
    "                        전기통신사업법, 정보보호법, 약관규제법 기타 관련법령의 규정 중 강행규정과 이 약관의 내용이 서로 다른 경우에는 강행규정을 적용하고, 전기통신사업법, 정보보호법, 약관규제법 기타 관련법령의 규정 중 임의규정 또는 전기통신사업법, 정보보호법, 약관규제법 기타 관련법령에 규정되지 아니한 사항에 대하여는 이 약관을 적용합니다.<br/>" +
    "<br/>" +
    "                        제 5 조 (이용계약의 체결)<br/>" +
    "                        ① 이용계약은 회원이 되고자 하는 자(이하 ‘가입신청자’)가 이 약관의 내용에 대하여 동의를 한 후 회원가입 신청을 하고, 회사가 위 신청에 대하여 승낙함으로써 체결됩니다.<br/>" +
    "                        ② 1항에 의한 가입신청자의 신청에 대하여, 회사는 원칙적으로 서비스의 이용을 승낙합니다. 단, 회사는 다음 각 호의 1에 해당하는 신청에 대하여는 그 사유가 해소될 때까지 승낙을 하지 아니하거나 별도의 절차 없이 이용계약을 해지할 수 있습니다.<br/>" +
    "                        1) 가입신청자가 회원 자격을 상실한 사실이 있는 경우. 단, 회원 자격을 상실한 날로부터 1년이 경과한 자로서 회사의 재가입 승낙을 얻은 경우에 는 그러하지 아니합니다.<br/>" +
    "                        2) 가입신청자의 실명이 아니거나 타인의 명의를 이용한 경우<br/>" +
    "                        3) 가입신청자가 허위의 정보를 기재한 경우<br/>" +
    "                        4) 회사가 요구한 정보를 가입신청자가 제공하지 아니한 경우<br/>" +
    "                        5) 미성년자인 가입신청자가 법정대리인의 동의를 얻지 아니한 경우<br/>" +
    "                        6) 기타 회원의 책임있는 사유로 인하여 회사가 승낙할 수 없는 경우<br/>" +
    "                        7) 기타 회사가 필요하다고 인정한 경우<br/>" +
    "                        ③ 1항에 의한 가입신청자의 신청에 대하여, 회사는 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.<br/>" +
    "                        ④ 1항에 의한 가입신청자의 신청에 대하여, 회사는 서비스 설비의 부족 기타 회사의 사정을 이유로 그 승낙을 유보할 수 있습니다.<br/>" +
    "                        ⑤ 2항 또는 4항에 따라 회사가 회원가입 신청의 승낙을 하지 아니하거나 이를 유보한 경우, 회사는 가입신청자에게 이 사실을 알릴 수 있습니다.<br/>" +
    "                        ⑥ 이용계약은 회원가입 절차상 회사가 가입완료를 표시한 시점에 성립합니다.<br/>" +
    "                        ⑦ 회사는 서비스의 품질 향상 기타 필요한 경우, 회원을 등급별로 구분하여 이용시간, 이용횟수, 서비스 메뉴 기타 필요한 사항을 세분하여 그 이용에 차등을 둘 수 있습니다.<br/>" +
    "                        ⑧ 회사는 회원에 대하여 영화및비디오물의진흥에관한법률, 청소년보호법 기타 관련법령에 따른 등급 및 연령 준수를 위하여 이용제한이나 등급별 제한 기타 필요한 조치를 취할 수 있습니다.<br/>" +
    "                        ⑨ 회원이 이 약관에 동의함은 아이피나우서비스를 통해 제공하거나 제공할 모든 내용에 동의하는 것으로 봅니다.<br/>" +
    "                        제 6 조 (회원 정보의 변경 등)<br/>" +
    "                        ① 회원은 개인정보 관리화면을 통하여 언제든지 본인의 개인정보를 열람할 수 있고, 회사가 인정하는 경미한 내용을 변경 또는 수정할 수 있습니다. 단, 서비스 관리를 위하여 필요한 실명, 생년월일, 성별, 아이디 기타 회사가 정한 사항은 변경 또는 수정할 수 없습니다.<br/>" +
    "                        ② 회원은 회원가입 신청 시 기재한 사항이 변경되었을 경우, 즉시 개인정보 관리화면을 통하여 이를 수정하거나 이메일 기타 회사가 정한 방법으로 회사에 대하여 그 변경사항을 알려야 합니다.<br/>" +
    "                        ③ 2항의 변경사항을 회사에 알리지 아니함으로써, 회원이 입은 불이익에 대하여 회사는 회원 또는 제3자에 대하여 손해배상 기타 일체의 책임을 부담하지 아니하고, 회사 또는 회사와 제휴한 온라인서비스 제공자가 입은 불이익에 대하여 회원은 손해배상 기타 일체의 책임을 부담합니다.<br/>" +
    "                        제 7 조 (개인정보 보호 및 도용에 대한 책임 등)<br/>" +
    "                        ① 회사는 『정보통신망이용촉진및정보보호에관한법률』상의 개인정보보호규정 및 정보통신부가 제정한 『개인정보 보호법』등 관련 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위하여 최선의 노력을 하고 개인정보 보호 및 사용에 대하여 관련법령 및 회사의 개인정보 취급방침을 적용합니다.<br/>" +
    "                        단, 아이피나우의 웹사이트 또는 모바일 어플리케이션에 공식적으로 링크된 이외의 사이트에 대하여는 회사의 개인정보취급방침이 적용되지 아니합니다.<br/>" +
    "                        ② 회원은 가입 또는 정보 변경 시 반드시 본인의 이름, 생년월일 등 기타 정확한 본인 정보를 입력하여야 하며 타인의 정보 도용 등 허위정보를 입력할 경우 서비스 이용이 제한되거나 관련 법령에 의거 처벌받을 수 있습니다.<br/>" +
    "                        ③ 판매자의 경우 자료 판매 시 정확한 개인정보를 바탕으로 본인인증 절차를 거쳐야 합니다. 만일 타인의 정보 도용 등 허위정보를 입력할 경우 거래가 정지 될 수 있으며, 관련 법령에 의거 처벌받을 수 있습니다.<br/>" +
    "                        ④ 게시물은 반드시 본인에게 저작권이 있는 자료만 등록하여야 합니다. 만일 저작권 관련 분쟁 시 모든 책임은 판매자 본인에게 있으며, 저작권법 등 관련법령 및 회사가 정한 절차에 따라 필요한 조치를 취합니다.<br/>" +
    "                        제 8 조 (회원의 아이디 및 비밀번호의 관리 등)<br/>" +
    "                        ① 회원의 아이디와 비밀번호에 대한 관리책임은 회원에게 있고, 회원은 제3자에게 이를 알리거나 알게 하여서는 아니됩니다. 제3자가 이를 이용하여 회사 또는 회사와 제휴한 온라인서비스 제공자가 제공하는 서비스를 이용하게 하여서도 아니됩니다.<br/>" +
    "                        ② 다음 각호의 1에 해당하는 경우, 회사는 회원의 아이디 사용을 제한할 수 있습니다.<br/>" +
    "                        1) 회원의 아이디가 회원의 개인정보를 유출할 우려가 있는 경우<br/>" +
    "                        2) 회원의 아이디가 반사회적이거나 또는 미풍양속에 어긋나는 경우<br/>" +
    "                        3) 회원의 아이디가 회사 또는 회사의 운영자로 오인할 우려가 있는 경우<br/>" +
    "                        4) 기타 회사가 필요하다고 인정한 경우<br/>" +
    "                        ③ 회원이 회원의 아이디 또는 비밀번호가 유출되었거나 제3자가 이를 사용하고 있음을 안 경우, 회원은 즉시 회사에 이를 통지하고, 회사의 안내에 따라야 합니다.<br/>" +
    "                        ④ 3항의 경우, 회원이 회사에 대하여 유출 사실 또는 제3자의 사용 사실을 통지하지 아니하거나 또는 회사의 안내에 따르지 아니함으로써, 회원이 입은 불이익에 대하여 회사는 회원 또는 제3자에 대하여 손해배상 기타 일체의 책임을 부담하지 아니하고, 회사 또는 회사와 제휴한 온라인서비스 제공자가 입은 불이익에 대하여 회원은 손해배상 기타 일체의 책임을 부담합니다.<br/>" +
    "                        제 9 조 (회원에 대한 통지)<br/>" +
    "                        ① 회사가 회원에 대하여 통지할 필요가 있는 경우, 전기통신사업법 기타 관련법령 및 이 약관에 별도의 규정이 없는 한 회원이 회사에 제시한 이메일주소로서 그 통지를 할 수 있습니다.<br/>" +
    "                        ② 회사는 회원의 전부 또는 등급별, 판매자별, 구매자별로 통지를 할 필요가 있는 경우, 7일 이상 회사의 인터넷 홈페이지 게시판에 게시함으로써 1항의 통지에 갈음할 수 있습니다.<br/>" +
    "                        제 10 조 (회사의 방침)<br/>" +
    "                        ① 회사는 전기통신사업법 기타 관련법령을 위반하지 아니하며, 회원에게 계속적이고 안정적인 서비스를 제공하기 위하여 최선의 노력을 다합니다.<br/>" +
    "                        ② 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함) 보호를 위한 보안시스템을 갖추고 있습니다.<br/>" +
    "                        ③ 필요한 경우, 회사는 회원의 전부 또는 일부의 개인정보에 관한 통계자료를 작성하여 회사의 마케팅자료 또는 광고자료로 활용할 수 있습니다. 단, 이 경우에도 회사는 개인정보의 보호 기타 관련법령에서 정한 규정을 준수합니다.<br/>" +
    "                        ④ 서비스 이용 관련하여 회원이 제기한 불만 기타 의견이 정당하다고 회사가 인정한 경우, 회사는 관련 규정 및 절차를 통하여 이를 처리합니다. 회원이 제기한 불만 기타 의견에 대한 회사의 처리 결과에 대하여는 회원에게 게시판, 이메일 기타 적절한 방법으로 이를 통지합니다.<br/>" +
    "                        제 11 조 (회원의 의무)<br/>" +
    "                        ① 회원은 다음 각호의 1에 해당하는 행위를 하여서는 아니됩니다.<br/>" +
    "                        1) 회원가입의 신청 또는 회원정보의 변경시 허위내용을 기재하는 행위<br/>" +
    "                        2) 제3자의 개인정보를 도용하는 행위<br/>" +
    "                        3) 회사가 게시한 정보를 변경하는 행위<br/>" +
    "                        4) 회사 기타 제3자의 저작권 기타 일체의 권리를 침해하는 행위<br/>" +
    "                        5) 회사 기타 제3자에 대한 명예훼손, 업무방해 기타 불법행위<br/>" +
    "                        6) 외설 또는 폭력적인 메시지, 화상, 음성 기타 선량한 풍속에 반하는 정보, 문장, 도형 등을 서비스에 공개 또는 게시하거나 제3자에게 유포하는 행위<br/>" +
    "                        7) 회사의 사전 서면에 의한 동의 없이 영리를 목적으로 서비스를 이용하는 행위<br/>" +
    "                        8) 회사의 사전 서면에 의한 동의 없이 회사로부터 얻은 정보를 제3자에게 제공하거나 기타 방법으로 회원 자신의 이용 이외의 목적으로 사용하는 행위<br/>" +
    "                        9) 기타 관련법령에 위배되는 행위<br/>" +
    "                        ② 회원은 정보보호법 기타 관련법령, 이 약관, 이용안내 기타 회사가 서비스와 관련하여 공지한 사항을 준수하여야 합니다.<br/>" +
    "                        ③ 회원은 회사가 별도로 공지한 이용제한 사항을 준수하여야 합니다.<br/>" +
    "                        ④ 회원은 회사의 사전 서면에 의한 동의 없이 회사로부터 서비스를 받을 수 있는 권리, 권한을 증여하거나 양도 또는 담보제공 기타 일체의<br/>" +
    "                        처분행위를 하여서는 아니됩니다.<br/>" +
    "                        ⑤ 회원이 1항 내지 4항에 규정한 사항을 위반한 경우, 회사는 회원 또는 제3자에 대하여 민ㆍ형사상 일체의 책임을 부담하지 아니하고, 회원은 회사 또는 회사와 제휴한 온라인서비스 제공자에 대하여 민ㆍ형사상 일체의 책임을 부담합니다.<br/>" +
    "                        ⑥ 허위의 구인광고 또는 허위의 구인조건을 제시한 참여기업은 직업안정법 제47조에 의하여 5년 이하의 징역 또는 2,000만원 이하의 벌금에 처해집니다.<br/>" +
    "                        제 12 조 (서비스의 제공 등)<br/>" +
    "                        ① 회사는 회원에게 다음 각호의 서비스를 제공합니다.<br/>" +
    "                        1) 자료구매 서비스<br/>" +
    "                        2) 자료판매 서비스<br/>" +
    "                        3) 검색서비스<br/>" +
    "                        4) 교육목적에 활용가능한 정보제공 서비스<br/>" +
    "                        5) 기타 회사 또는 회사와 제휴한 온라인서비스 제공자가 회원에게 제공하는 일체의 서비스<br/>" +
    "                        ② 서비스는 연중무휴, 1일 24시간 제공을 원칙으로 합니다.<br/>" +
    "                        ③ 컴퓨터 등 정보통신 설비의 보수점검, 교체 및 고장, 통신 두절, 서비스 설비의 장애, 서비스 이용의 폭주 또는 운영상 상당한 이유가 있는 경우, 회사는 서비스의 제공을 일시적으로 중단하거나 제한할 수 있고, 이 경우 회사는 제9조에 정한 방법으로 회원에게 통지합니다. 단, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 때에는 사후에 이를 통지할 수 있습니다.<br/>" +
    "                        ④ 회사는 서비스의 품질향상 기타 필요한 경우, 정기점검을 실시할 수 있고, 정기점검 시간, 방법 기타 필요한 사항은 서비스 제공화면에 공지한 바에 따릅니다.<br/>" +
    "                        ⑤ 회사는 서비스를 일정범위로 분할하여 각 범위 별로 이용가능시간을 별도로 지정할 수 있습니다. 단, 이러한 경우 회사는 회원에게 그 내용을 제9조에 정한 방법으로 공지합니다.<br/>" +
    "                        제 13 조 (서비스의 변경 등)<br/>" +
    "                        ① 서비스의 품질향상, 운영상ㆍ기술상의 필요 기타 회사가 인정하는 상당한 사유가 있는 경우, 회사는 회원에게 제공하는 서비스(서비스의 내용, 이용방법, 이용시간 기타 사항)의 전부 또는 일부를 중단, 변경하거나 추가(이하 ‘변경’)할 수 있습니다.<br/>" +
    "                        ② 서비스의 품질향상, 운영상ㆍ기술상의 필요, 회사의 정책 기타 회사가 인정하는 상당한 사유가 있는 경우, 회사는 무료로 제공되는 서비스의 전부 또는 일부를 변경할 수 있으며, 이에 대하여 관련법령에 특별한 규정이 없는 한 회사는 회원에게 별도의 보상을 하지 아니합니다.<br/>" +
    "                        ③ 1항 또는 2항에 의한 서비스를 변경할 경우, 회사는 서비스의 변경 7일 전부터 변경사유 및 변경 내용의 요지 기타 필요한 사항을 제9조 2항에 정한 방법으로 게시합니다.<br/>" +
    "                        제 14 조 (정보의 제공 및 광고의 게재)<br/>" +
    "                        ① 회원이 서비스를 이용함에 있어 필요하다고 회사가 인정하는 경우, 회사는 회원에게 다양한 정보를 제9조에 정한 방법 기타 적절한 방법으로 제공할 수 있습니다.<br/>" +
    "                        ② 회사는 회원의 사전 동의를 얻은 때에 한하여 1항의 정보를 전화 또는 팩스에 의하여 제공할 수 있습니다.<br/>" +
    "                        ③ 회사는 서비스의 운영과 관련하여 회사의 서비스화면이나 홈페이지, 회원의 이메일 등을 통하여 광고를 게재할 수 있습니다.<br/>" +
    "                        ④ 회원이 1항 또는 3항에 의한 정보 또는 광고를 수신한 경우, 회원은 관련법령에 따른 거래관련 정보 및 회원의 질의에 대한 답변, 정보 또는 광고가 회원의 이익을 위한 경우 등을 제외하고는 언제든지 회사에 대하여 이메일 기타 적절한 방법으로 그 수신을 거절할 수 있습니다.<br/>" +
    "                        제 15 조 (자료대금 및 수수료)<br/>" +
    "                        ① 종량제 서비스를 이용하여 자료를 판매하는 회원(이하 ‘판매회원’)은 자료 대금을 정하고, 회사는 자료대금의 결정에 대하여 일체 관여하지 아니합니다. 정액제 서비스를 통한 자료 거래 시에는 판매회원이 정한 자료대금과는 무관하며, 회사가 정한 기준에 따라 자료대금이 결정됩니다.<br/>" +
    "                        ② 서비스를 이용하여 자료를 구매하는 회원(이하 ‘구매회원’)은 판매회원에게 자료대금을 지급하여야 합니다. 단, 회사는 판매회원을 위하여 구매회원으로부터 자료대금의 수령을 대행할 수 있습니다.<br/>" +
    "                        ③ 구매회원의 자료대금 지급방법은 종량제 방식과 정액제 방식에 의하고, 판매회원의 수수료 지급 방법은 원천징수방식에 의합니다.<br/>" +
    "                        ④ 구매회원은 자료대금 결제시에 결제대행사에 지급하여야 하는 결제처리수수료를 포함하여 이를 결제하여야 합니다. 결제처리수수료는 결제대행사가 구매회원의 대금을 결제하는데 소요되는 비용입니다.<br/>" +
    "                        ⑤ 판매회원은 현금 자료에 한하여 회사에게 수수료를 지급하여야 합니다. 회사는 종량제 서비스의 경우 판매회원의 종류에 따라 수수료를 차등적으로 적용할 수 있습니다. 정액제 서비스의 경우 판매회원의 종류에 상관없이 동일한 수수료가 적용됩니다.<br/>" +
    "                        ⑥ 회사는 구매회원이 지급한 자료대금 중 5항에 의한 수수료 및 제세공과금을 제외한 나머지 금액을 회사 사이트에 개설된 판매회원의 계정에 적립합니다.<br/>" +
    "                        ⑦ 판매회원의 적립금액이 회사가 정한 금액 이상인 경우에 한하여, 판매회원은 회사에 대하여 다음 각호의 사항을 기재한 서면을 제출하거나 또는 출금신청화면 기타 회사가 정한 방법을 통하여 일정 금액 단위로 출금을 요청할 수 있습니다. 단, 회사가 판매회원의 실명 및 예금주의 일치를 확인할 수 있는 경우에 한합니다.<br/>" +
    "                        1) 판매회원의 거래은행, 계좌번호<br/>" +
    "                        2) 출금금액<br/>" +
    "                        3) 기타 회사가 필요로 하는 정보<br/>" +
    "                        ⑧ 판매회원이 7항에 의한 출금을 요청한 경우, 회사는 판매회원에게 출금 요청 1회당 출금수수료로서 500원을 부과합니다. 출금수수료는 판매회원에 대한 송금과 동시에 적립된 판매회원의 계정에서 적립금을 감액하는 방법으로 부과합니다.<br/>" +
    "                        ⑨ 회사가 필요하다고 인정한 경우, 회사는 3항, 5항 내지 8항에 규정된 사항을 변경할 수 있고, 이 경우 제9조 1항을 준용합니다.<br/>" +
    "                        제 16조 (회원의 자료 구매)<br/>" +
    "                        ① 서비스 화면에 게시된 자료를 구매하고자 하는 회원과 자료를 열람하고자 하는 회원은 사이트에서 안내하는 절차에 따라 자료를 구매하거나 열람 및 스크랩 하기 직전까지 자료대금, 납부방법 기타 필요한 사항을 확인하여야 하고, 회원이 이를 승낙함과 동시에 회사와의 사이에 자료구매계약(이하 ‘구매계약’)이 성립한 것으로 봅니다.<br/>" +
    "                        ② 사유 여하를 불문하고, 회원은 구매계약의 무효, 취소, 해제 기타 구매계약의 효력을 부인하는 일체의 주장을 할 수 없습니다.<br/>" +
    "                        ③ 2항에도 불구하고, 다음 각호의 1에 해당하는 사유가 발생한 경우, 회원은 회사가 정한 절차에 따라 구매계약을 취소한 뒤 회사에 대하여 환불을 요청할 수 있습니다. 이 경우, 제20조 3항 및 4항을 준용합니다.<br/>" +
    "                        종량제 서비스 이용 시<br/>" +
    "                        1) 회원에게 저작권이 없는 자료<br/>" +
    "                        2) 파일이 손상된 자료<br/>" +
    "                        3) 다른 자료와 중복된 자료<br/>" +
    "                        4) 기타 회사가 인정하는 자료<br/>" +
    "                        정액제 서비스 이용 시<br/>" +
    "                        1) 자료 열람 및 스크랩이 1건도 없는 상태<br/>" +
    "                        제 17 조 (게시물의 저작권 등)<br/>" +
    "                        ① 회원이 서비스상에 게시한 게시물(이하 ‘게시물’)의 저작권은 해당 게시물의 저작자에게 있습니다. 회사의 결정으로 회원이 등록신청(upload)한 게시물을 회사 또는 회사와 제휴한 온라인서비스 제공자가 운영하는 사이트에 등록한 경우에도, 사유 여하를 불문하고, 회사 또는 회사와 제휴한 온라인서비스 제공자의 게시물 등록에 대하여 회사가 게시물을 등록신청한 회원에게 게시물의 저작권을 인정한 것으로 간주되거나 추정되지 아니한다.<br/>" +
    "                        ② 회원이 서비스상에 게시한 게시물은 검색결과 내지 서비스 프로모션 등에 노출될 수 있으며, 해당 노출을 위하여 필요한 경우, 회사는 게시물을 일부 수정하거나 편집 기타 방법으로 이를 게시할 수 있습니다. 이 경우, 회원은 회사에 대하여 해당 게시물의 삭제, 검색결과 제외, 비공개 기타 필요한 조치를 요청할 수 있습니다.<br/>" +
    "                        제 18 조 (게시물의 관리 등)<br/>" +
    "                        ① 회사는 회원에 대한 별도의 조치 없이 회원이 서비스상에 게시한 게시물을 편집하거나 이동할 수 있는 권리를 보유합니다.<br/>" +
    "                        ② 회원의 게시물이 저작권법, 정보보호법 기타 관련법령에 위반되는 내용을 포함하는 경우, 권리자는 관련법령이 정한 절차에 따라 해당 게시물의 게시중단, 삭제 기타 필요한 조치를 요청할 수 있고, 회사는 관련법령 및 회사가 정한 절차에 따라 필요한 조치를 취합니다.<br/>" +
    "                        ③ 2항에 따른 권리자의 요청이 없는 경우라도 다음 각호의 1에 해당하는 경우, 회사는 관련법령 및 이 약관에 따라 별도의 조치 없이 해당 게시물에 대한 게시중단, 삭제 기타 필요한 조치를 취할 수 있습니다.<br/>" +
    "                        1) 해당 게시물이 저작권법 기타 관련법령을 위반하거나 위반할 우려가 있는 경우<br/>" +
    "                        2) 해당 게시물이 제3자의 권리를 침해하거나 침해할 우려가 있는 경우<br/>" +
    "                        3) 해당 게시물이 이 약관이나 회사의 정책에 위반되거나 위반될 우려가 있는 경우<br/>" +
    "                        4) 기타 해당 게시물의 게시중단, 삭제 기타 조치를 취할 필요가 있다고 회사가 인정한 경우<br/>" +
    "                        ④ 회원의 게시물이 제3자의 저작권 기타 재산권을 침해함으로써 발생하는 일체의 민ㆍ형사상 책임에 대하여,회사는 이를 일체 부담하지 아니합니다.<br/>" +
    "                        제 19 조 (권리의 귀속 등)<br/>" +
    "                        ① 서비스에 대한 저작권 및 지적재산권은 회사에 있습니다. 단, 회원의 게시물, 저작물 등은 그러하지 아니합니다.<br/>" +
    "                        ②회사는 서비스와 관련하여 회원에게 회사가 정한 이용조건에 따라 계정, 아이디, 콘텐츠, 포인트 등을 이용할 수 있는 이용권만을 부여하며, 회원은 이를 증여하거나 양도 또는 담보제공 기타 일체의 처분행위를 하여서는 아니됩니다.<br/>" +
    "                        ③ 회사는 서비스의 효율적 이용 및 운영을 위하여 제9조에 정한 방법에 따라 포인트의 전부 또는 일부를 조정할 수 있고, 포인트는 회사가 정한 기간에 따라 주기적으로 소멸합니다. 포인트의 조정 및 소멸에 대하여 회원은 회사가 정한 절차에 따라 이의를 신청할 수 있고, 이 경우, 회사는 관련 규정 및 절차를 통하여 이를 처리합니다.<br/>" +
    "                        제 20 조 (계약의 해지 등)<br/>" +
    "                        ① 회원은 언제든지 회사가 정한 절차에 따라 서비스 이용계약을 해지할 수 있고, 해지신청을 받은 회사는 다음 각호의 방법으로 회원 본인의 해지의사를 확인하여야 하며, 회사가 본인의 해지의사를 확인함과 동시에 별도의 절차 없이 이용계약은 효력을 상실합니다. 단, 회원이 회사 또는 제3자에 대하여 민ㆍ형사상의 책임을 부담하는 등 회사가 필요하다고 인정한 경우, 회사는 이용계약의 효력을 존속시킬 수 있습니다.<br/>" +
    "                        1) 회원 본인 또는 적법한 대리인이 홈페이지 탈퇴신청 화면에서 계약 해지 신청<br/>" +
    "                        2) 신분증사본을 팩스로 송부하는 등 전화, 팩스, 이메일 기타 방법으로 회사에 대하여 회원이 본인의 해지의사를 명백히 밝히는 경우. 단, 회사가 회원 본인임을 확인한 경우에 한합니다.<br/>" +
    "                        ② 다음 각 호의 1에 해당하는 사유가 발생한 경우, 회사는 별도의 조치 없이 이용계약을 해지할 수 있습니다.<br/>" +
    "                        1) 회원이 회사의 명예를 훼손한 경우<br/>" +
    "                        2) 회원이 서비스의 제공 기타 회사의 업무를 방해한 경우<br/>" +
    "                        3) 회원이 다른 회원의 ID 또는 비밀번호를 도용한 경우<br/>" +
    "                        4) 회원가입의 신청 또는 개인정보의 수정시 회사에 허위의 정보를 제공한 경우<br/>" +
    "                        5) 회원이 회사의 사전 서면에 의한 동의 없이 서비스를 이용하여 영리활동을 한 경우<br/>" +
    "                        6) 기타 회사가 필요하다고 인정한 경우<br/>" +
    "                        ③ 1항 또는 2항에 의하여 이용계약이 해지된 경우, 회원이 등록한 자료는 별도의 절차 없이 삭제되며, 회원이 보유한 이용권, 쿠폰, 포인트 기타 회사에 대한 권리, 권한은 모두 소멸합니다. 단, 이용계약 해지일 기준 회사에 대한 회원의 자료대금, 충전대금 기타 금액에 대하여는 회사가 정한 절차에 따라 회원에게 송금수수료 기타 필요한 비용을 제외한 나머지 금액을 송금하거나 기타 방법으로 반환합니다.<br/>" +
    "                        ④ 3항에 의하여 회사가 회원에게 나머지 금액을 지급하여야 할 경우, 회원은 [고객센터 1:1문의하기] 기타 회사가 안내한 절차에 따라 그 반환을 요청하여야 합니다.<br/>" +
    "                        제 21 조 (이용제한 등)<br/>" +
    "                        ① 회원이 관련법령 또는 이 약관의 의무를 위반하거나 기타 필요한 경우, 회사는 회원에 대하여 경고, 서비스의 전부 또는 일부의 이용정지 등 서비스의 이용을 단계적으로 제한할 수 있습니다.<br/>" +
    "                        ② 1항에도 불구하고, 다음 각호의 1에 해당하는 사유가 발생한 경우, 회사는 별도의 조치 없이 즉시 회원에 대하여 이용계약을 해지할 수 있습니다.<br/>" +
    "                        1) 주민등록법 기타 관련법령을 위반한 명의 도용 또는 결제 도용<br/>" +
    "                        2) 저작권법 또는 컴퓨터프로그램보호법 기타 관련법령을 위반한 불법 저작물, 프로그램의 제공 또는 운영 방해<br/>" +
    "                        3)정보보호법 기타 관련법령을 위반한 불법통신 및 해킹, 악성 프로그램의 배포, 접속권한의 초과행위<br/>" +
    "                        4) 기타 1호 내지 3호에 준하는 행위<br/>" +
    "                        ③ 2항에 의하여 이용계약이 해지된 경우, 서비스 이용을 통하여 회원이 획득한 포인트 기타 혜택은 별도의 조치 없이 모두 소멸되고, 회사는 이에 대하여 별도의 보상을 하지 아니합니다. 단, 이용계약이 해지된 경우, 해지일 기준 회사에 대한 회원의 자료대금, 충전대금 기타 금액에 대하여는 회사가 정한 절차에 따라 회원에게 송금수수료 기타 필요한 비용을 제외한 나머지 금액을 송금하거나 기타 방법으로 반환합니다.<br/>" +
    "                        ④ 회원이 계속하여 3개월 이상 로그인하지 아니하거나 기타 필요한 경우, 회사는 회원정보의 보호 및 운영의 효율성을 위하여 회원에 대한 경고, 이용제한기타 회사가 정한 조치를 취할 수 있습니다.<br/>" +
    "                        ⑤ 본 조에 의한 경고, 이용정지, 이용계약의 해지 기타 회사가 정한 조치 등 구체적인 조건 및 세부내용은 회사의 이용제한정책 및 개별 서비스상의 운영정책에서 정하는 바에 의합니다.<br/>" +
    "                        ⑥ 본 조에 따라 회원에 대하여 경고, 이용정지, 이용계약의 해지 기타 회사가 정한 조치를 취하는 경우, 회사는 회원에게 제9조에 정한 방법으로 이를 통지합니다.<br/>" +
    "                        ⑦ 본 조에 따른 회사의 조치에 대하여, 회원은 회사가 정한 절차에 따라 이의신청을 할 수 있습니다.<br/>" +
    "                        제 22 조 (책임 제한 등)<br/>" +
    "                        ① 천재지변 기타 불가항력으로 인하여 회사가 서비스를 제공할 수 없는 경우, 회사는 회원에 대하여 서비스 제공에 관한 책임이 면제됩니다.<br/>" +
    "                        ② 회사는 회원의 책임 있는 사유로 인한 서비스 이용의 장애에 대하여는 일체의 책임을 부담하지 아니합니다.<br/>" +
    "                        ③ 회사는 회원이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 일체의 책임을 부담하지 아니합니다.<br/>" +
    "                        ④ 사유 여하를 불문하고, 회원이 서비스를 이용하여 기대하였던 수익, 이익을 얻지 못하거나 서비스를 통하여 얻은 자료로 인하여 손해 기타 불이익을 입은 경우에도 회사는 이에 대하여 일체의 책임을 부담하지 아니합니다.<br/>" +
    "                        ⑤ 회원 상호간 또는 회원과 제3자 상호간에 서비스를 매개로 하여 거래 등을 한 경우, 회사는 이에 대하여 일체의 책임을 부담하지 아니합니다.<br/>" +
    "                        ⑥ 무료로 제공되는 서비스 이용과 관련하여 관련법령에 특별한 규정이 없는 한 회사는 이에 대하여 일체의 책임을 부담하지 아니합니다.<br/>" +
    "                        제 23 조 (전속관할)<br/>" +
    "                        이 약관 기타 회사와 회원 사이에 발생하는 일체의 분쟁에 대하여는 회사의 소재지를 관할하는 법원의 전속 관할로 합니다.<br/>" +
    "<br/>" +
    "                        개인정보의 수집 및 이용목적, 개인정보 처리방침<br/>" +
    "                        주식회사 아이피나우(이하 IPNOW이라 함)은 귀하의 개인정보보호를 매우 중요시하며, 『개인정보보호법』을 준수하고 있습니다. IPNOW은 아이피나우 서비스와 관련하여, 개인정보처리(취급)방침을 통하여 귀하께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다. 이 개인정보처리(취급)방침의 순서는 다음과 같습니다.<br/>" +
    "<br/>" +
    "                        개인정보수집과 이용에 대한 안내는 아래와 같습니다.<br/>" +
    "<br/>" +
    "                        1. 수집하는 개인정보의 항목 및 수집방법<br/>" +
    "                        2. 개인정보의 수집 및 이용목적<br/>" +
    "                        3. 개인정보 제공 및 공유<br/>" +
    "                        4. 수집한 개인정보의 취급위탁<br/>" +
    "                        5. 개인정보의 안전성 확보조치에 관한 사항<br/>" +
    "                        6. 개인정보의 보유 및 이용기간<br/>" +
    "                        7. 개인정보의 파기절차 및 그 방법<br/>" +
    "                        8. 이용자 및 법정대리인의 권리와 그 행사방법<br/>" +
    "                        9. 아동의 개인정보보호<br/>" +
    "                        10. 동의철회 / 회원탈퇴 방법<br/>" +
    "                        11. 개인정보 자동 수집 장치의 설치/운영 및 그 거부에 관한 사항<br/>" +
    "                        12. 개인정보관리책임자<br/>" +
    "                        13. 정책 변경에 따른 공지의무<br/>" +
    "                        .<br/>" +
    "                        1. 수집하는 개인정보의 항목 및 수집방법<br/>" +
    "<br/>" +
    "                        IPNOW은 회원가입 시 서비스 이용을 위해 필요한 최소한의 개인정보만을 수집합니다. 귀하가 IPNOW의 서비스를 이용하기 위해서는 회원가입 시 필수항목과 선택항목이 있는데, 메일수신여부 등과 같은 선택항목은 입력하지 않더라도 서비스 이용에는 제한이 없습니다.<br/>" +
    "<br/>" +
    "                        [일반 회원가입 시 수집항목]필수항목 : 아이디, 비밀번호, 휴대전화번호, e-mail선택항목 : 학교, 메일수신여부서비스 이용 과정이나 서비스 제공 업무 처리 과정에서 서비스 이용기록, 접속 로그, 쿠키, 접속 IP 정보 등은 자동으로 생성되어 수집될 수 있습니다.[유료정보 이용 시 수집항목]필수항목 : (성명,) 아이디, 비밀번호(, 휴대전화번호)선택항목 : 휴대전화번호, e-mail[자료 판매금액 출금 시 수집항목]은행명, 계좌번호, 주민등록번호 (최초 1회)<br/>" +
    "                        * 그 외 특정 목적을 위해 단기적으로 개인정보를 수집할 경우에는 별도로 공지하고 수집합니다.<br/>" +
    "                        [개인정보 수집방법]홈페이지 또는 모바일 어플리케이션을 통해 개인정보를 수집합니다.<br/>" +
    "                        2. 개인정보의 수집 및 이용목적<br/>" +
    "<br/>" +
    "                        IPNOW은 수집한 개인정보를 다음의 목적을 위해 활용합니다.<br/>" +
    "                        이용자가 제공한 모든 정보는 하기 목적에 필요한 용도 이외로는 사용되지 않으며 이용 목적이 변경될 시에는 사전 동의를 구할 것입니다.<br/>" +
    "<br/>" +
    "                        회원제 서비스 이용에 따른 본인 식별 절차에 이용<br/>" +
    "                        주민등록번호: 출금 회원에 한하여 소득세 신고를 위하여 수집<br/>" +
    "                        서비스 이용에 대한 통계<br/>" +
    "                        고지사항 전달, 불만처리 등을 위한 의사소통 경로로 이용<br/>" +
    "                        온라인 상담 답변 처리를 위한 자료<br/>" +
    "                        새로운 서비스 및 행사정보 안내 제공<br/>" +
    "                        신규 서비스 개발과 개인 맞춤 서비스 제공을 위한 자료<br/>" +
    "                        소비자 기본법 제 54조에 의거한 소비자 위해 정보 수집<br/>" +
    "<br/>" +
    "                        3. 개인정보 제공 및 공유<br/>" +
    "<br/>" +
    "                        IPNOW은 귀하의 동의가 있거나 관련법령의 규정에 의한 경우를 제외하고는 어떠한 경우에도 『개인정보의 수집 및 이용목적』에서 고지한 범위를 넘어 귀하의 개인정보를 이용하거나 타인 또는 타기업ㆍ기관에 제공하지 않습니다.<br/>" +
    "                        다만, 아래의 경우에는 예외로 합니다.<br/>" +
    "<br/>" +
    "                        이용자들이 사전에 공개에 동의한 경우<br/>" +
    "                        법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우<br/>" +
    "                        통계작성ㆍ학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 알아볼 수 없는 형태로 가공하여 제공하는 경우<br/>" +
    "<br/>" +
    "                        4. 수집한 개인정보의 취급위탁<br/>" +
    "<br/>" +
    "                        IPNOW은 아래와 같이 개인정보를 위탁하고 있으며, 위탁계약 체결 시 개인정보 보호법 제 26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적 / 관리적 보호조치, 재 위탁 제한, 수탁자에 대한 관리 / 감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.<br/>" +
    "                        위탁업무의 내용이나 수탁자가 변경될 경우에는 지체 없이 본 개인정보처리(취급)방침을 통하여 공개하도록 하겠습니다.<br/>" +
    "                        회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같습니다.<br/>" +
    "<br/>" +
    "<br/>" +
    "                        위탁업체<br/>" +
    "                        한국모바일인증㈜<br/>" +
    "<br/>" +
    "                        위탁업무 내용<br/>" +
    "                        회원 본인인증 서비스 위탁<br/>" +
    "<br/>" +
    "                        개인정보 보유기간<br/>" +
    "                        (아이디/비밀번호 찾기, 판매자 전환 등 본인인증, 이름/생년월일 변경) 목적달성 후 즉시 파기<br/>" +
    "<br/>" +
    "                        5. 개인정보의 안전성 확보조치에 관한 사항<br/>" +
    "<br/>" +
    "                        [개인정보 취급 직원의 최소화 및 교육]개인정보취급자의 지정을 최소화하고 정기적인 교육을 시행하고 있습니다.[정기적인 자체 감사 실시]개인정보 취급 관련 안정성 확보를 위해 연 1회이상 정기적으로 자체 감사를 실시하고 있습니다.[내부관리계획의 수립 및 시행]개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.[개인정보의 암호화]이용자의 개인정보 중 비밀번호는 암호화 되어 저장 및 관리되고 있어 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하는 등의 별도 보안기능을 사용하고 있습니다.[해킹 등에 대비한 기술적 대책]해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신?점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다[개인정보에 대한 접근 제한]개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.[접속기록의 보관 및 위변조 방지]개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.[비인가자에 대한 출입 통제]개인정보를 보관하고 있는 개인정보시스템의 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.<br/>" +
    "                        6. 개인정보의 보유 및 이용기간<br/>" +
    "<br/>" +
    "                        IPNOW은 개인정보의 수집목적 또는 제공받은 목적이 달성된 때에는 귀하의 개인정보를 지체 없이 파기합니다.<br/>" +
    "<br/>" +
    "                        회원가입정보의 경우 : 회원가입을 탈퇴하거나 회원에서 제명된 때<br/>" +
    "                        설문조사, 행사 등의 목적을 위하여 수집한 경우 : 당해 설문조사, 행사 등이 종료한 때<br/>" +
    "                        신용정보의 수집 / 처리 및 이용 등에 관한 정보의 경우 : 신용정보의 이용 및 보호에 관한 법률에 따라 3년간 보존(보존 항목 : 카드사명, 카드번호 등 카드결제 승인 정보)<br/>" +
    "                        다만, 수집목적 또는 제공받은 목적이 달성된 경우에도 상법 등 법령의 규정에 의하여 보존할 필요성이 있는 경우에는 귀하의 개인정보를 보유할 수 있습니다.<br/>" +
    "<br/>" +
    "                        7. 개인정보의 파기절차 및 그 방법<br/>" +
    "<br/>" +
    "                        IPNOW은 『개인정보의 수집 및 이용목적』이 달성된 후에는 즉시 파기합니다. 파기절차 및 방법은 다음과 같습니다.<br/>" +
    "<br/>" +
    "                        파기절차이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 파기방법에 의하여 즉시 파기합니다.파기방법전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.<br/>" +
    "                        종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.<br/>" +
    "                        8. 이용자 및 법정대리인의 권리와 그 행사방법<br/>" +
    "<br/>" +
    "                        IPNOW은 고객이 개인정보에 대한 열람, 정정 및 삭제를 요구하는 경우에는 고객의 요구에 성실하게 응대하고, 지체없이 처리합니다. 개인정보를 보호하기 위하여 고객의 방문 이외의 전화ㆍ우편ㆍFAX 등 기타 신청방법에 의한 고객의 개인정보의 열람, 정정 및 삭제 절차는 제공하지 않습니다. [개인정보의 열람]고객은 IPNOW을 방문하여 개인정보의 열람을 요구할 수 있으며, 신속하게 이에 대하여 응대합니다.[개인정보의 정정ㆍ삭제]IPNOW은 고객이 개인정보에 대한 정정ㆍ삭제를 요구하는 경우, 개인정보에 오류가 있다고 판명되는 등 정정ㆍ삭제를 할 필요가 있다고 인정되는 경우에는 지체없이 정정ㆍ삭제를 합니다. IPNOW은 정정ㆍ삭제 내용의 사실 확인에 필요한 증빙자료를 요청할 수 있습니다.<br/>" +
    "                        고객이 본인의 개인정보에 대한 열람, 정정 및 삭제를 요구하는 경우, 고객의 신분을 나타내는 주민등록증, 여권, 운전면허증 등의 신분증명서를 제시 받아 본인 여부를 확인합니다.<br/>" +
    "                        고객의 대리인이 방문하여 열람, 정정 및 삭제을 요구하는 경우에는 고객의 위임장 및 동의서와 대리인의 신분증명서 등을 확인하여 정당한 대리인인지 여부를 확인합니다.<br/>" +
    "                        IPNOW은 개인정보의 전부 또는 일부에 대하여 열람, 정정 및 삭제를 거절할 정당한 이유가 있는 경우에는 고객에게 이를 통지하고 그 이유를 설명합니다.<br/>" +
    "                        만 14세 미만의 아동의 경우, 법정대리인이 아동의 개인정보를 조회하거나 수정할 권리, 수집 및 이용 동의를 철회할 권리를 가집니다.<br/>" +
    "<br/>" +
    "                        9. 아동의 개인정보보호<br/>" +
    "<br/>" +
    '                        만14세 미만 아동(이하 "아동"이라 함)의 회원가입은 아동이 이해하기 쉬운 평이한 표현으로 작성된 별도의 양식을 통해 이루어지고 있으며 개인정보 수집ㆍ이용에 대하여 반드시 법정대리인의 동의를 구하고 있습니다.<br/>' +
    "<br/>" +
    "                        IPNOW은 법정대리인의 동의를 받기 위하여 아동으로부터 법정대리인의 성명과 연락처 등 최소한의 정보를 수집하고 있으며, 개인정보처리(취급)방침에서 규정하고 있는 방법에 따라 법정대리인의 동의를 받고 있습니다.<br/>" +
    "<br/>" +
    "                        아동의 법정대리인은 아동의 개인정보에 대한 열람, 정정 및 삭제를 요청할 수 있습니다. 아동의 개인정보를 열람·정정, 삭제하고자 할 경우에는 회원정보수정을 클릭하여 법정대리인 확인 절차를 거치신 후 아동의 개인정보를 법정대리인이 직접 열람·정정, 삭제하실 수 있습니다.<br/>" +
    "<br/>" +
    "                        1) 이용자 및 법정대리인의 권리와 그 행사방법<br/>" +
    "                        ① 정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.<br/>" +
    "                        가. 개인정보 열람 요구<br/>" +
    "                        나. 오류 등이 있을 경우 정정 요구<br/>" +
    "                        다. 삭제 요구<br/>" +
    "                        라 처리정지 요구<br/>" +
    "                        ② 제1항에 따른 권리 행사는 회사에 대해 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며<br/>" +
    "                        회사는 이에 대해 지체 없이 조치하겠습니다.<br/>" +
    "                        ③ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는<br/>" +
    "                        회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.<br/>" +
    "                        ④ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.<br/>" +
    "                        이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.<br/>" +
    "                        ⑤ 정보주체는 개인정보보호법 등 관계법령을 위반하여 회사가 처리하고 있는 정보주체 본인이나<br/>" +
    "                        타인의 개인정보 및 사생활을 침해하여서는 아니 됩니다.<br/>" +
    "<br/>" +
    "<br/>" +
    "                        10. 동의철회 / 회원탈퇴 방법<br/>" +
    "<br/>" +
    "                        귀하는 회원가입 시 개인정보의 수집ㆍ이용 및 제공에 대해 동의하신 내용을 언제든지 철회하실 수 있습니다. 회원탈퇴는 아이피나우 홈페이지 나의 마이페이지의 『회원탈퇴』를 클릭하여 본인 확인 절차를 거치신 후 직접 회원탈퇴를 하시거나, 개인정보보호 고충처리부서로 서면, 전화 또는 Fax 등으로 연락하시면 지체 없이 귀하의 개인정보를 파기하는 등 필요한 조치를 하겠습니다.<br/>" +
    "<br/>" +
    "<br/>" +
    "                        11. 개인정보 자동 수집 장치의 설치/운영 및 그 거부에 관한 사항<br/>" +
    "<br/>" +
    "                        IPNOW은 귀하의 정보를 수시로 저장하고 찾아내는 '쿠키(cookie)'를 운용합니다. 쿠키란 회사의 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에 보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다. IPNOW은 다음과 같은 목적을 위해 쿠키를 사용합니다.<br/>" +
    "<br/>" +
    "                        회원과 비회원의 접속 빈도나 방문 시간 등을 분석하고 이용자의 취향과 관심분야를 파악하여 서비스 개편 등의 척도로 활용합니다.<br/>" +
    "                        열람한 웹 페이지들에 대한 정보와 관심 있게 둘러본 페이지들에 대한 자취를 추적하여 다음 번 방문 때 개인 맞춤 서비스를 제공하는 데 이용합니다.<br/>" +
    "                        IPNOW이 진행하는 각종 행사에서 귀하의 참여 정도 및 방문 회수를 파악하여 차별적인 응모 기회를 부여하고 개인의 관심 분야에 따라 차별화된 정보를 제공하기 위한 자료로<br/>" +
    "                        이용됩니다.<br/>" +
    "                        귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 귀하는 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나,<br/>" +
    "                        아니면 모든 쿠키의 저장을 거부할 수도 있습니다.<br/>" +
    "<br/>" +
    "                        설정방법의 예<br/>" +
    "                        1) Internet Explorer의 경우 : 웹 브라우저 상단의 도구 메뉴 > 인터넷 옵션 > 개인정보 > 설정<br/>" +
    "                        2) Chrome의 경우 : 웹 브라우저 우측의 설정 메뉴 > 화면 하단의 고급 설정 표시 > 개인정보의 콘텐츠 설정 버튼 > 쿠키<br/>" +
    "<br/>" +
    "                        회원님께서 쿠키 설치를 거부하셨을 경우 일부 서비스 제공에 어려움이 있습니다.<br/>" +
    "<br/>" +
    "                        12. 개인정보관리책임자<br/>" +
    "<br/>" +
    "                        귀하의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 IPNOW은 아래와 같이 개인정보관리책임자 혹은 담당부서를 두고 있습니다.<br/>" +
    "<br/>" +
    "                        [개인정보 관리책임자]이름 : 강민우 직위 : 전임연구원 소속 : 플랫폼 개발팀 전화번호 : (02) 3463-9768 메일 : kmuu011@gooditl.com<br/>" +
    "                        귀하께서는 IPNOW의 아이피나우 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담담부서로 신고하실 수 있습니다. IPNOW은 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다. 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.<br/>" +
    "                        개인분쟁조정위원회 (http://www.1336.or.kr / 1336)<br/>" +
    "                        정보보호마크인증위원회 (http://www.eprivacy.or.kr / (02) 580-0533~4)<br/>" +
    "                        대검찰청 첨단범죄수사과 (http://www.spo.go.kr / (02) 3480-2000)<br/>" +
    "                        경찰청 사이버테러대응센터 (http://www.ctrc.go.kr / (02) 392-0330)<br/>" +
    "<br/>" +
    "                        13. 정책 변경에 따른 공지의무<br/>" +
    "<br/>" +
    "                        이 개인정보처리(취급)방침은 2021년 8월 1일에 제정되었으며 법령ㆍ정책 또는 보안기술의 변경에 따라 내용의 추가ㆍ삭제 및 수정이 있을 시에는 변경되는 개인정보처리(취급)방침을 시행하기 최소 7일전에 아이피나우 홈페이지를 통해 변경이유 및 내용 등을 공지하도록 하겠습니다.<br/>" +
    "                        공고일자 : 2019년 11월 29일<br/>" +
    "                        시행일자 : 2019년 12월 9일<br/>" +
    "<br/>" +
    "                        [개인정보 관리책임자]<br/>" +
    "                        이름 : 강민우<br/>" +
    "                        직위 : 전임 연구원<br/>" +
    "                        소속 : 플랫폼 개발팀<br/>" +
    "                        전화번호 : (070) 4659-3223<br/>" +
    "                        메일 : kmuu011@gooditl.com<br/>" +
    "                        귀하께서는 IPNOW의 아이피나우 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담담부서로 신고하실 수 있습니다. IPNOW은 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다. 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.<br/>" +
    "<br/>" +
    "                        개인분쟁조정위원회 (http://www.1336.or.kr / 1336)<br/>" +
    "                        정보보호마크인증위원회 (http://www.eprivacy.or.kr / (02) 580-0533~4)<br/>" +
    "                        대검찰청 첨단범죄수사과 (http://www.spo.go.kr / (02) 3480-2000)<br/>" +
    "                        경찰청 사이버테러대응센터 (http://www.ctrc.go.kr / (02) 392-0330)<br/>" +
    "                        13. 정책 변경에 따른 공지의무<br/>" +
    "                        이 개인정보처리(취급)방침은 2021년 8월 1일에 제정되었으며 법령ㆍ정책 또는 보안기술의 변경에 따라 내용의 추가ㆍ삭제 및 수정이 있을 시에는 변경되는 개인정보처리(취급)방침을 시행하기 최소 7일전에 아이피나우 홈페이지를 통해 변경이유 및 내용 등을 공지하도록 하겠습니다.<br/>" +
    "<br/>" +
    "                        공고일자 : 2021년 8월 1일<br/>" +
    "                        시행일자 : 2016년 8월 1일",

  privateTxt:
    "주식회사아이피나우'('www.ipnow.co.kr'이하 'IPNOW')은(는) 「개인정보 보호법」 제30조에 따라 정부주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.<br/><br/>" +
    "                                    ○ 이 개인정보처리방침은 2021년 6월 1부터 적용됩니다.<br/><br/>" +
    "                                    <br/><br/>" +
    "                                    제1조(개인정보의 처리 목적)<br/><br/>" +
    "                                    <br/><br/>" +
    "                                    '주식회사 아이피나우'('www.ipnow.co.kr'이하 'IPNOW')은(는) 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 겨우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.<br/><br/>" +
    "                                    1. 홈페이지 회원가입 및 관리<br/><br/>" +
    "                                    회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 각종 고지·통지 목적으로 개인정보를 처리합니다.<br/><br/>" +
    "                                    <br/><br/>" +
    "                                    2. 민원사무 처리<br/><br/>" +
    "                                    민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 목적으로 개인정보를 처리합니다.<br/><br/>" +
    "                                    <br/><br/>" +
    "                                    3. 재화 또는 서비스 제공<br/><br/>" +
    "                                    서비스 제공, 계약서·청구서 발송, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 요금결제·정산을 목적으로 개인정보를 처리합니다.<br/><br/>" +
    "                                    <br/><br/>" +
    "                                    4. 마케팅 및 광고에의 활용<br/><br/>" +
    "                                    신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 , 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.<br/><br/>" +
    "                                    <br/><br/>" +
    "                                    5. 개인영상정보<br/><br/>" +
    "                                    범죄의 예방 및 수사 등을 목적으로 개인정보를 처리합니다.<br/><br/>" +
    "                                    <br/><br/>" +
    "                                    제2조(개인정보의 처리 및 보유 기간)<br/><br/>" +
    "                                    <br/><br/>" +
    "                                    ① '주식회사 아이피나우'은(는) 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.<br/><br/>" +
    "                                    <br/><br/>" +
    "                                    ② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.<br/><br/>" +
    "                                    •\t1.[홈페이지 회원가입 및 관리]<br/><br/>" +
    "                                    •\t[홈페이지 회원가입 및 관리]와 관련한 개인정보는 수집.이용에 관한 동의일로부터[3년]까지 위 이용목적을 위하여 보유.이용됩니다.<br/><br/>" +
    "                                    •\t보유근거 : 추후 법적 분쟁 등에 활용하기 위하여<br/><br/>" +
    "                                    •\t관련법령 : 1)신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년<br/><br/>" +
    "                                    2) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년<br/><br/>" +
    "                                    3) 대금결제 및 재화 등의 공급에 관한 기록 : 5년<br/><br/>" +
    "                                    4) 계약 또는 청약철회 등에 관한 기록 : 5년<br/><br/>" +
    "                                    5) 표시/광고에 관한 기록 : 6개월<br/><br/>" +
    "                                    •\t예외사유 :<br/><br/>" +
    "                                    <br/><br/>" +
    "                                    제3조(정보주체와 법정대리인의 권리·의무 및 그 행사방법)<br/><br/>" +
    "                                    <br/><br/>" +
    "                                    ① 정보주체는 주식회사 아이피나우에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.<br/><br/>" +
    "                                    ② 제1항에 따른 권리 행사는주식회사 아이피나우에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 주식회사 아이피나우은(는) 이에 대해 지체 없이 조치하겠습니다.<br/><br/>" +
    "                                    ③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.<br/><br/>" +
    "                                    ④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.<br/><br/>" +
    "                                    ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.<br/><br/>" +
    "                                    ⑥ 주식회사 아이피나우은(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.<br/><br/>" +
    "                                    <br/><br/>" +
    "                                    제4조(처리하는 개인정보의 항목 작성)<br/><br/>" +
    "                                    <br/><br/>" +
    "                                    ① '주식회사 아이피나우'은(는) 다음의 개인정보 항목을 처리하고 있습니다.<br/><br/>" +
    "                                    •\t1[홈페이지 회원가입 및 관리]<br/><br/>" +
    "                                    •\t필수항목 : 이메일, 휴대전화번호, 비밀번호, 로그인ID, 이름, 회사전화번호, 직책, 회사명, 서비스 이용 기록, 접속 로그, 쿠키, 결제기록<br/><br/>" +
    "                                    •\t선택항목 : 신용카드정보, 은행계좌정보<br/><br/>" +
    "                                    <br/><br/>" +
    "                                    제5조(개인정보의 파기)<br/><br/>" +
    "                                    <br/><br/>" +
    "                                    ① '주식회사 아이피나우' 은(는) 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.<br/><br/>" +
    "                                    <br/><br/>" +
    "                                    ② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.<br/><br/>" +
    "                                    1. 법령 근거 :<br/><br/>" +
    "                                    2. 보존하는 개인정보 항목 : 계좌정보, 거래날짜<br/><br/>" +
    "                                    <br/><br/>" +
    "                                    ③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.<br/><br/>" +
    "                                    1. 파기절차<br/><br/>" +
    "                                    '주식회사 아이피나우' 은(는) 파기 사유가 발생한 개인정보를 선정하고, '주식회사 아이피나우' 의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.<br/><br/>" +
    "                                    2. 파기방법<br/><br/>" +
    "                                    전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.<br/><br/>" +
    "                                    종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다<br/><br/>" +
    "                                    <br/><br/>" +
    "                                    제6조(개인정보의 안전성 확보 조치)<br/><br/>" +
    "                                    <br/><br/>" +
    "                                    '주식회사 아이피나우'은(는) 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.<br/><br/>" +
    "                                    1. 개인정보 취급 직원의 최소화 및 교육<br/><br/>" +
    "                                    개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.<br/><br/>" +
    "                                    <br/><br/>" +
    "                                    2. 개인정보의 암호화<br/><br/>" +
    "                                    이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.<br/><br/>" +
    "                                    <br/><br/>" +
    "                                    3. 개인정보에 대한 접근 제한<br/><br/>" +
    "                                    개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.<br/><br/>" +
    "                                    <br/><br/>" +
    "                                    4. 비인가자에 대한 출입 통제<br/><br/>" +
    "                                    개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.<br/><br/>" +
    "                                    <br/><br/>" +
    "                                    제7조(개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항)<br/><br/>" +
    "                                    <br/><br/>" +
    "                                    ① 주식회사 아이피나우 은(는) 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다. ② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다. 가. 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다. 나. 쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다. 다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.<br/><br/>" +
    "                                    제8조 (개인정보 보호책임자)<br/><br/>" +
    "                                    ① 주식회사 아이피나우 은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.<br/><br/>" +
    "                                    •\t▶ 개인정보 보호책임자<br/><br/>" +
    "                                    •\t성명 :강민우<br/><br/>" +
    "                                    •\t직책 :전임 연구원<br/><br/>" +
    "                                    •\t직급 :전임연구원<br/><br/>" +
    "                                    •\t연락처 :070-4659-3223, kmuu011@gooditl.com, 02-3463-2200<br/><br/>" +
    "                                    ※ 개인정보 보호 담당부서로 연결됩니다.<br/><br/>" +
    "                                    •\t▶ 개인정보 보호 담당부서<br/><br/>" +
    "                                    •\t부서명 :플랫폼 개발팀<br/><br/>" +
    "                                    •\t담당자 :강민우<br/><br/>" +
    "                                    •\t연락처 :070-4659-3223, kmuu011@gooditl.com, 02-3463-2200<br/><br/>" +
    "                                    ② 정보주체께서는 주식회사 아이피나우 의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 주식회사 아이피나우 은(는) 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.<br/><br/>" +
    "                                    제9조(개인정보 열람청구)<br/><br/>" +
    "                                    정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.<br/><br/>" +
    "                                    '주식회사 아이피나우'은(는) 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.<br/><br/>" +
    "                                    •\t▶ 개인정보 열람청구 접수·처리 부서<br/><br/>" +
    "                                    •\t부서명 : 플랫폼 개발팀<br/><br/>" +
    "                                    •\t담당자 : 강민우<br/><br/>" +
    "                                    •\t연락처 : 070-4659-3223, kmuu011@gooditl.com, 02-3463-2200<br/><br/>" +
    "                                    <br/><br/>" +
    "                                    제10조(권익침해 구제방법)<br/><br/>" +
    "                                    <br/><br/>" +
    "                                    정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.<br/><br/>" +
    "                                    <br/><br/>" +
    "                                    1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)<br/><br/>" +
    "                                    2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)<br/><br/>" +
    "                                    3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)<br/><br/>" +
    "                                    4. 경찰청 : (국번없이) 182 (cyberbureau.police.go.kr)<br/><br/>" +
    "                                    <br/><br/>" +
    "                                    「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.<br/><br/>" +
    "                                    <br/><br/>" +
    "                                    ※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.<br/><br/>" +
    "                                    제11조(개인정보 처리방침 변경)<br/><br/>" +
    "                                    <br/><br/>" +
    "                                    ① 이 개인정보처리방침은 2021년 6월 1부터 적용됩니다.",
  useTxtMobile: `<div class="textlist">
  <ul>
    <li id="rule1">
      <h3>제1조 (목적)</h3>
      <p>이 약관은 주식회사 아이피나우(이하 "회사")가 제공하는 클라우드 기반의 업무 관리 도구 및 그룹웨어 서비스인 비즈내비(이하 "서비스")의 이용 조건 및 절차, 회사와 회원의 권리와 의무, 기타
        필요한 사항을 규정함을 목적으로 합니다. 본 약관은 회원이 서비스 이용을 위해 준수해야 할 사항을 명시하고, 서비스 제공과 관련된 법적 기반을 명확히 합니다.</p>
    </li>
    <li id="rule2">
      <h3>제2조 (용어의 정의)</h3>
      <p>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
      <ol class="list">
        <li class="point">1. "서비스"란 회사가 제공하는 클라우드 기반의 그룹웨어 및 업무 관리 도구로, 사용자들의 업무 효율성을 높이기 위한 SaaS 솔루션을 의미합니다.</li>
        <li class="point">2. "회원"이란 이 약관에 동의하고 서비스를 이용하는 개인 또는 법인을 말합니다.</li>
        <li class="point">3. "계정"이란 회원이 서비스를 이용하기 위해 제공한 이메일 주소와 비밀번호로 구성된 회원 식별 정보를 말합니다.</li>
        <li class="point">4. "데이터"란 회원이 서비스를 통해 생성하거나 관리하는 모든 업무 관련 정보 및 자료를 의미합니다.</li>
      </ol>
    </li>
    <li id="rule3">
      <h3>제3조 (약관의 효력 및 변경)</h3>
      <ol class="list">
        <li class="point">1. 이 약관은 회원이 서비스 가입 및 이용을 신청하고, 회사가 이를 승낙한 시점부터 효력을 발생합니다.</li>
        <li class="point">2. 회사는 관련 법령을 준수하는 범위 내에서 약관을 개정할 수 있으며, 개정된 약관은 서비스 내 공지사항을 통해 사전 공지됩니다.</li>
        <li class="point">3. 회원은 개정된 약관에 동의하지 않을 경우, 서비스 이용을 중단하고 탈퇴할 수 있습니다. 개정된 약관 시행일 이후에도 서비스를 계속 이용하는 경우, 해당 회원은
          개정된 약관에 동의한 것으로 간주됩니다.</li>
      </ol>
    </li>
    <li id="rule4">
      <h3>제4조 (서비스의 제공 및 변경)</h3>
      <ol class="list">
        <li class="point">
          <p>1. 회사는 다음과 같은 주요 서비스를 제공합니다.</p>
          <ol class="inner_list">
            <li>1. 그룹웨어: 메일, 일정, 게시판, 메신저 등 업무 효율을 높이기 위한 협업 기능 제공</li>
            <li>2. 업무 관리: 프로젝트 관리, 할 일 관리, 보고서 생성, 업무 상태 추적 및 공유 기능</li>
            <li>3. 문서 관리: 클라우드 기반 파일 관리, 저장, 공유 기능</li>
            <li>4. 기타 업무 관련 도구 및 기능</li>
          </ol>
        </li>
        <li class="point">
          2. 회사는 기술적 필요 및 운영 정책의 변경에 따라 서비스 내용을 변경할 수 있으며, 이 경우 변경사항을 사전에 회원에게 통지합니다. 다만, 긴급한 사유가 있는 경우 사후 통지할 수 있습니다.
        </li>
        <li class="point">
          3. 회사는 안정적인 서비스 제공을 위하여 정기점검을 실시하며, 점검 시간은 사전에 공지합니다. 불가피한 사정으로 인해 긴급 점검이 필요할 경우, 사전 통지 없이 서비스를 일시 중단할 수 있습니다.
        </li>
      </ol>
    </li>
    <li id="rule5">
      <h3>제 5 조 (회원 가입 및 이용 계약)</h3>
      <ol class="list">
        <li class="point">1. 서비스 이용 계약은 회원이 약관에 동의하고, 회원 가입 신청서를 작성하여 제출한 후 회사가 이를 승낙함으로써 성립됩니다.</li>
        <li class="point">
          <p>2. 회사는 다음 각 호에 해당하는 경우, 회원의 가입 신청을 거부하거나 이용 계약을 해지할 수 있습니다.</p>
          <ol class="inner_list">
            <li>1) 회원이 제공한 정보가 허위이거나 타인의 명의를 도용한 경우.</li>
            <li>2) 법령 또는 본 약관을 위반하는 행위로 인한 가입 신청</li>
            <li>3) 기타 회사가 정당한 이유로 서비스 제공이 부적절하다고 판단하는 경우</li>
          </ol>
        </li>
        <li class="point">3. 회원은 가입 신청 시 제공한 정보의 변경 사항이 발생할 경우, 지체 없이 회사에 알리거나 서비스 내에서 이를 수정해야 합니다.</li>
      </ol>
    </li>
    <li id="rule6">
      <h3>제 6 조 (계정 관리 및 회원 정보)</h3>
      <ol class="list">
        <li class="point">1. 회원은 서비스 이용을 위해 등록한 계정 정보의 비밀을 유지할 책임이 있습니다.</li>
        <li class="point">2. 회원의 계정 관리 소홀로 인한 모든 책임은 회원 본인에게 있으며, 회사는 계정 도용, 비밀번호 유출로 인한 손해에 대해 책임을 지지 않습니다.</li>
        <li class="point">3. 회원은 계정 정보가 도용되거나 무단으로 사용된 사실을 알게 되었을 경우, 즉시 회사에 통지해야 하며, 회사는 이에 따른 적절한 조치를 취할 의무가 있습니다.
        </li>
        <li class="point">4. 회사는 회원의 개인정보를 보호하기 위해 관련 법령 및 회사의 개인정보 처리방침에 따라 개인정보를 안전하게 관리합니다.</li>
      </ol>
    </li>
    <li id="rule7">
      <h3>제 7 조 (데이터의 관리 및 보관)</h3>
      <ol class="list">
        <li class="point">1. 회원이 서비스 이용 과정에서 생성하거나 관리하는 모든 데이터의 소유권은 회원에게 있습니다. 회사는 회원의 데이터를 회원의 동의 없이 상업적 목적으로 이용하거나
          제3자에게 제공하지 않습니다.</li>
        <li class="point">2. 회사는 서비스의 안정적인 운영을 위하여 데이터 백업 기능을 제공할 수 있으며, 회원의 요청에 따라 데이터의 복구, 이전 등의 기술적 지원을 제공합니다.</li>
        <li class="point">3. 회사는 서비스 종료 또는 회원 탈퇴 시, 회원의 데이터를 일정 기간 보관한 후 삭제할 수 있으며, 이와 관련된 구체적인 정책은 서비스 내에서 안내됩니다. 단,
          법령에 따라 보관해야 하는 경우는 예외로 합니다.</li>
        <li class="point">4. 회사는 서비스 이용 중 발생한 데이터의 손실이나 손해에 대해 고의 또는 중대한 과실이 없는 한 책임을 지지 않으며, 회원은 중요한 데이터를 자체적으로 백업해야
          합니다.</li>
      </ol>
    </li>
    <li id="rule8">
      <h3>제 8 조 (서비스 이용 요금 및 결제)</h3>
      <ol class="list">
        <li class="point">1. 서비스의 기본적인 기능은 무료로 제공되며, 유료 서비스는 별도의 요금을 부과합니다.</li>
        <li class="point">2. 유료 서비스 이용 요금은 서비스 이용 신청 시 명시되며, 회원은 해당 요금을 납부해야 이용할 수 있습니다.</li>
        <li class="point">3. 결제는 신용카드, 은행 이체 등 회사가 지정한 방법을 통해 이루어지며, 회원이 지정한 결제 수단으로 요금이 자동 청구됩니다.</li>
        <li class="point">4. 회원이 유료 서비스 이용 중 요금을 미납할 경우, 회사는 사전 통지 후 유료 서비스 이용을 제한하거나 중단할 수 있습니다.</li>
        <li class="point">5. 회사는 유료 서비스 이용과 관련하여 환불 정책을 운영하며, 환불 요청 시 회사의 정책에 따라 처리됩니다.</li>
      </ol>
    </li>
    <li id="rule9">
      <h3>제 9 조 (회원의 의무)</h3>
      <ol class="list">
        <li class="point">
          <p>1. 회원은 서비스 이용 시 법령, 본 약관 및 서비스 이용에 관련된 운영 정책을 준수해야 하며, 다음 행위를 해서는 안 됩니다.</p>
          <ol class="inner_list">
            <li>1. 타인의 명예를 훼손하거나 권리를 침해하는 행위</li>
            <li>2. 서비스의 정상적인 운영을 방해하는 행위</li>
            <li>3. 회사의 허락 없이 서비스를 재판매하거나 상업적 목적으로 이용하는 행위</li>
            <li>4. 서비스 내 제공된 정보를 무단으로 변경하거나 삭제하는 행위</li>
          </ol>
        </li>
        <li class="point">2. 회원은 서비스 이용과 관련하여 발생하는 모든 분쟁 및 법적 책임을 스스로 부담해야 하며, 회사에 손해를 끼친 경우 이를 배상할 책임이 있습니다.</li>
      </ol>
    </li>
    <li id="rule10">
      <h3>제 10 조 (회사의 의무)</h3>
      <ol class="list">
        <li class="point">1. 회사는 관련 법령을 준수하고, 회원에게 안정적이고 지속적인 서비스 제공을 위해 노력합니다.</li>
        <li class="point">2. 회사는 회원의 개인정보 및 데이터를 보호하기 위해 법령에서 정하는 기준에 따라 보안 시스템을 유지·관리하며, 이에 따른 개인정보 처리방침을 운영합니다.</li>
        <li class="point">3. 회사는 서비스의 안정성을 위해 정기적으로 시스템 점검을 시행하며, 점검 시 사전 공지합니다.</li>
        <li class="point">4. 회사는 회원이 서비스 이용 중 불편을 겪지 않도록 최선을 다해 지원합니다.</li>
      </ol>
    </li>
    <li id="rule11">
      <h3>제 11 조 (손해배상 및 면책)</h3>
      <ol class="list">
        <li class="point">1. 회사는 서비스 제공과 관련하여 회원에게 손해가 발생한 경우, 고의 또는 중대한 과실이 없는 한 그 책임을 지지 않습니다.</li>
        <li class="point">2. 회원이 본 약관을 위반하여 회사에 손해를 끼친 경우, 회원은 회사에 그 손해를 배상해야 합니다.</li>
        <li class="point">3. 회사는 천재지변, 불가항력 등으로 인해 서비스를 제공할 수 없는 경우, 서비스 제공에 대한 책임이 면제됩니다.</li>
        <li class="point">4. 회사는 회원이 서비스를 통해 기대하는 수익이나 성과를 얻지 못한 것에 대해 책임지지 않습니다.</li>
      </ol>
    </li>
    <li id="rule12">
      <h3>제 12 조 (계약 해지 및 이용 제한)</h3>
      <ol class="list">
        <li class="point">1. 회원은 언제든지 서비스 탈퇴를 요청할 수 있으며, 탈퇴 즉시 서비스 이용이 중단됩니다. 탈퇴 시 관련 데이터는 회사의 정책에 따라 삭제되며, 회원은 삭제된 데이터에
          대해 이의를 제기할 수 없습니다.</li>
        <li class="point">2. 회사는 회원이 약관을 위반하거나 불법 행위를 한 경우, 서비스 이용을 제한하거나 계약을 해지할 수 있습니다.</li>
        <li class="point">3. 계약이 해지된 경우, 회사는 관련 법령에 따라 회원의 데이터를 보관할 수 있으며, 그 외의 경우 모든 데이터를 삭제합니다.</li>
      </ol>
    </li>
    <li id="rule13">
      <h3>제 13 조 (분쟁 해결)</h3>
      <ol class="list">
        <li class="point">1. 회사와 회원 간 발생한 분쟁에 대해서는 성실히 협의하여 해결하는 것을 원칙으로 합니다.</li>
        <li class="point">2. 이 약관과 관련하여 발생한 분쟁에 대한 소송은 회사의 본사 소재지를 관할하는 법원을 제1심 관할 법원으로 합니다.</li>
        <li class="point">3. 이 약관과 관련된 모든 소송은 대한민국 법을 준거법으로 합니다.</li>
      </ol>
    </li>
    <li id="rule14">
      <h3>부칙</h3>
      <p>이 약관은 2024년 11월 1일부터 시행됩니다.</p>
    </li>
  </ul>
</div>`,

  privateTxtMobile: `<p class="summary">
  주식회사 아이피나우(이하 ‘회사’)는 개인정보보호법 등 관련 법령에 따라 이용자의 개인정보를 보호하고, 이에 대한 권익을 보호하기 위해 다음과 같은 개인정보 처리방침을 수립하고 준수하고 있습니다.
</p>
<div class="textlist">
  <ul>
    <li id="rule1">
      <h3>1. 개인정보의 수집 목적 및 항목</h3>
      <p>회사는 다음과 같은 목적을 위해 개인정보를 수집합니다.</p>
      <ol class="list">
        <li>
          <strong class="list_subject">• 서비스 제공 및 계약 이행</strong>
          <p>고객 지원, 서비스 제공, 본인 확인 등</p>
        </li>
        <li>
          <strong class="list_subject">• 고객 관리</strong>
          <p>서비스 이용 관련 문의 대응, 서비스 개선</p>
        </li>
        <li>
          <strong class="list_subject">• 마케팅 및 광고</strong>
          <p>서비스 업데이트, 이벤트 정보 제공 (별도의 동의 필요)</p>
        </li>
        <li>
          <p class="list_subject">수집하는 개인정보 항목은 다음과 같습니다.</p>
          <p>• 필수항목: 이름, 이메일 주소, 연락처, 회사이름</p>
          <p>• 선택항목: 법인 번호, 사업자 등록번호</p>
        </li>
      </ol>
    </li>
    <li id="rule2">
      <h3>2. 개인정보의 처리 및 보유 기간</h3>
      <ol class="list">
        <li class="point">회사는 원칙적으로 개인정보 수집 및 이용 목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 다만, 다음의 경우에는 관련 법령에 따라 일정 기간 동안 개인정보를
          보관할 수 있습니다.</li>
        <li class="point">
          <ol class="inner_list">
            <li class="point">
              <p>관련법령</p>
              <ol class="inner_list">
                <li class="point">1) 계약 또는 청약철회 등에 관한 기록 : 5년(전자상거래 등에서의 소비자 보호에 관한 법률)</li>
                <li class="point">2) 대금 결제 및 재화 등의 공급에 관한 기록 : 5년(전자상거래 등에서의 소비자 보호에 관한 법률)</li>
                <li class="point">3) 소비자의 불만 또는 분쟁 처리에 관한 기록 : 3년(전자상거래 등에서의 소비자 보호에 관한 법률)</li>
              </ol>
            </li>
          </ol>
        </li>
      </ol>
    </li>
    <li id="rule3">
      <h3>3. 개인정보의 제3자 제공</h3>
      <p>회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.</p>
      <ol class="list">
        <li class="point">• 이용자가 사전에 동의한 경우</li>
        <li class="point">• 법령의 규정에 의거하거나, 수사기관의 요청에 따라 법적 절차에 따라 제공이 필요한 경우</li>
      </ol>
    </li>
    <li id="rule4">
      <h3>4. 개인정보 처리 위탁</h3>
      <p>회사는 원활한 업무 처리를 위해 다음과 같은 업체에 개인정보 처리를 위탁하고 있으며, 개인정보보호 관련 법령에 따라 안전하게 관리하고 있습니다.</p>
      <ol class="list">
        <li class="point">
          <ol class="inner_list">
            <li class="point">• 수탁업체: [아마존 웹 서비스]</li>
            <li class="point">• 위탁업무 내용: [인프라 제공, 정보보관]</li>
            <li class="point">• 보유 및 이용 기간: 회원탈퇴 시 혹은 위탁 계약 종료 시까지</li>
          </ol>
        </li>
      </ol>
    </li>
    <li id="rule5">
      <h3>5. 정보주체의 권리</h3>
      <p>이용자는 언제든지 자신의 개인정보에 대해 열람, 수정, 삭제, 처리 정지 등을 요구할 수 있습니다. 이러한 권리 행사는 회사에 서면, 이메일 등을 통해 하실 수 있으며, 회사는 지체 없이 이에 대해
        조치하겠습니다.</p>
    </li>
    <li id="rule6">
      <h3>6. 개인정보의 파기 절차 및 방법</h3>
      <p>회사는 원칙적으로 개인정보 처리 목적이 달성된 경우에는 해당 개인정보를 지체 없이 파기합니다. 개인정보의 파기 절차 및 방법은 다음과 같습니다.</p>
      <ol class="list">
        <li class="point">
          <p>• 파기 절차</p>
          <ol class="inner_list">
            <li class="point">이용자가 입력한 정보는 목적 달성 후 별도의 데이터베이스(DB)로 옮겨져 관련 법령에 따라 일정 기간 저장된 후 파기됩니다.</li>
          </ol>
        </li>
        <li class="point">
          <p>• 파기 방법</p>
          <ol class="inner_list">
            <li class="point">전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제하며, 종이 문서에 기록된 개인정보는 분쇄기로 분쇄하거나 소각합니다.
            </li>
          </ol>
        </li>
      </ol>
    </li>
    <li id="rule7">
      <h3>7. 개인정보의 안전성 확보 조치</h3>
      <p>회사는 개인정보보호법 제29조에 따라 다음과 같은 기술적, 관리적, 물리적 조치를 하고 있습니다.</p>
      <ol class="list">
        <li class="point">
          <p>• 기술적 조치</p>
          <ol class="inner_list">
            <li class="point">개인정보의 암호화, 보안 프로그램 설치</li>
          </ol>
        </li>
        <li class="point">
          <p>• 관리적 조치</p>
          <ol class="inner_list">
            <li class="point">개인정보 취급 직원의 최소화 및 교육</li>
          </ol>
        </li>
        <li class="point">
          <p>• 물리적 조치</p>
          <ol class="inner_list">
            <li class="point">전산실, 자료 보관실 등의 접근 통제</li>
          </ol>
        </li>
      </ol>
    </li>
    <li id="rule8">
      <h3>8. 개인정보보호 책임자</h3>
      <ol class="list">
        <li class="point">
          <p>회사는 개인정보 처리와 관련한 업무를 총괄하여 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.</p>
          <ol class="inner_list">
            <li class="point">
              <p>개인정보 보호책임자</p>
              <table>
                <tr>
                  <th>성명</th>
                  <td>황차동</td>
                </tr>
                <tr>
                  <th>연락처</th>
                  <td>1551-8442,<br />ipnow@ipnow.co.kr</td>
                </tr>
              </table>
            </li>
          </ol>
        </li>
      </ol>
    </li>
    <li id="rule9">
      <h3>9. 기타</h3>
      <p>
        이 개인정보 처리방침은 시행일로부터 적용되며, 법령 및 회사의 정책에 따라 변경될 수 있습니다. 변경 사항은 회사 홈페이지 또는 해당 서비스를 통해 공지될 예정입니다.
      </p>
      <ol class="list">
        <li class="point">
          <p>• 시행일</p>
          <ol class="inner_list">
            <li class="point">2024년 10월 15일</li>
          </ol>
        </li>
      </ol>
    </li>
  </ul>
</div>`,
};
