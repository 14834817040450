import React, { useEffect, useState } from "react";
import "./css/DetailPopupUpload.scss";

function DetailPopupUpload({
  show,
  setShow,
  uploadFileViewList,
  setFileList,
  onClickUpload,
  prop_onClickDelete,
  setFileImage,
}) {
  let fileRef = React.useRef();
  const [index, setIndex] = useState(0);

  useEffect(() => {
    fileRef.current.value = null;
  });

  const onClickFile = () => {
    fileRef.current.click();
  };

  const onClickHide = () => {
    setShow(false);
    setFileList([]);
  };

  const onChangeFile = (e) => {
    let files = e.target.files;
    let arr = [];

    if (files.constructor !== Array) {
      files = Object.keys(files).map((k) => files[k]);
    }

    files.forEach((file, index) => {
      let name = file.name.substr(0, file.name.lastIndexOf("."));
      let type = file.name.substr(file.name.lastIndexOf(".") + 1);
      let item = {
        idx: uploadFileViewList.length + index,
        file_name: name,
        file_type: type,
        created_at: file.lastModified,
        file: file,
      };
      arr.push(item);
    });
    setFileList(uploadFileViewList.concat(arr));
  };

  const onClickDelete = (idx) => {
    let copyArr = uploadFileViewList.slice(0);
    copyArr.splice(idx, 1);
    setFileList(copyArr);
    setFileImage("");
  };

  const validator = () => {
    if (uploadFileViewList.length == 0) {
      alert("파일을 등록해주세요");
      return;
    }
    onClickUpload();
  };

  let files = uploadFileViewList.map((file, index) => {
    return (
      <tr key={`${file.idx}_upload`}>
        <td>{index + 1}</td>
        <td>{`${file.file_name}.${file.file_type}`}</td>
        <td>{window.$Global.convertDate(new Date())}</td>
        <td>
          <button
            className="btn_delete"
            onClick={() =>
              prop_onClickDelete
                ? prop_onClickDelete(isNaN(file.idx) ? file.idx : index)
                : onClickDelete(index)
            }
          >
            삭제하기
          </button>
        </td>
      </tr>
    );
  });

  return (
    <div id="DetailUploadPopup" style={show ? {} : { display: "none" }}>
      <h2 className="title">업로드</h2>
      <button className="btn_attach" onClick={onClickFile}>
        첨부하기
      </button>
      <button className="icon_exit" onClick={onClickHide} />
      <div className="wrap_table">
        <table>
          <thead>
            <tr>
              <th width="8%">순번</th>
              <th>파일명</th>
              <th width="15%">업로드 날짜</th>
              <th width="10%">삭제하기</th>
            </tr>
          </thead>
          <tbody>{files}</tbody>
        </table>
      </div>
      <button className="btn_upload_complete" onClick={validator}>
        업로드 완료하기
      </button>
      <input
        type="file"
        onChange={onChangeFile}
        multiple
        ref={fileRef}
        hidden
      />
    </div>
  );
}

export default DetailPopupUpload;
