import "./css/PaymentResult.scss";
import MemberAPI from "../../API/member";
import { useEffect } from "react";

const PaymentResult = ({ history, location }) => {
  const {
    paid_at,
    bank_name,
    paid_amount,
    currency,
    card_name,
    card_number,
    payment_no,
    pay_method,
  } = location.state;

  useEffect(() => {
    // 결제된 내역을 토대로 localstorage 다시 세팅
    MemberAPI.getInfo().then((res) => {
      localStorage.setItem(
        "package_expiration_at",
        res.data.package_expiration_at
      );
      localStorage.setItem("package_idx", res.data.package_idx);
    });
  }, []);

  return (
    <div id="PaymentResult">
      <h2>결제가 성공적으로 완료되었습니다.</h2>
      <div className="data_box">
        <div>
          <p>결제 번호</p>
          <p>{payment_no}</p>
        </div>
        <div>
          <p>결제 날짜</p>
          <p>{window.$Global.convertDate(paid_at * 1000)}</p>
        </div>
        <div>
          <p>결제 수단</p>
          {pay_method === "point" ? (
            <p>간편결제</p>
          ) : (
            <p>{`${card_name || ""}${bank_name ? `(${bank_name})` : ""} ${
              card_number ? `[${card_number}]` : ""
            }`}</p>
          )}
        </div>
        <div>
          <p>상태</p>
          <p>결제완료</p>
        </div>
        <div>
          <p>총 결제금액</p>
          <p>{`${window.$Global.commaify(paid_amount)} ${currency}`}</p>
        </div>
      </div>
      <div className="button_box">
        <button onClick={() => history.push("/groupware/payment/list")}>
          결제내역 확인하기
        </button>
        <button onClick={() => history.push("/")}>비즈내비 이용하기</button>
      </div>
    </div>
  );
};

export default PaymentResult;
