import GroupwareSideBar from "./GroupwareSideBar";
import "./css/GroupwareCommute.scss";
import Moment from "react-moment";
import "moment/locale/ko";
import moment from "moment";
import { Bar, Doughnut } from "react-chartjs-2";
import { groupWareWorksPath } from "./groupwarePath";
import GwAPI from "../../API/groupware";
import React, { useEffect, useState } from "react";
import util from "../../util/util";
import grpFunc from "../../util/groupwareAPIUtil";
import { set } from "lodash";
import config from "../../const/config";

const barOption = {
  responsive: false,
  legend: {
    display: false,
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
  scales: {
    xAxes: [
      {
        ticks: {
          display: false,
        },
        display: false,
      },
    ],
    yAxes: [
      {
        ticks: {
          display: false,
          beginAtZero: true,
        },
        display: false,
      },
    ],
  },
};

const doughnutOption = {
  responsive: false,
  legend: {
    display: false,
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
};

const GroupwareCommute = () => {
  const [comNm, setComNm] = useState(""); // 회사명
  const [empNm, setEmpNm] = useState(""); // 이름
  const [enterDate, setEnterDate] = useState(""); // 입사일
  const [postionNm, setPostionNm] = useState(""); // 직위
  const [branchNm, setBranchNm] = useState(""); // 부서명
  const [typeNm, setTypeNm] = useState(""); // 출근상태명
  const [attendFlag, setAttendFlag] = useState(""); // 출근 상태값
  const [attendType, seAttendType] = useState(""); // 출근 상태값
  const [attendTodayInfo, setAttendTodayInfo] = useState({}); // 금일 출근 정보
  const [dataAttendMap, setDataAttendMap] = useState({}); // 출퇴근 내역
  const [isVacation, setIsVacation] = useState(false); // 휴가 유무
  const [weekCertHour, setWeekCertHour] = useState("0"); // 금주 근무시간
  const [weekCertMin, setWeekCertMin] = useState("0"); // 금주 근무시간
  const [weekRemainHour, setWeekRemainHour] = useState("0"); // 금주 남은시간
  const [weekRemainMin, setWeekRemainMin] = useState("0"); // 금주 남은시간
  const [weekBeforeList, setWeekBeforeList] = useState([]); // 과거 주간 리스트
  const [attendList, setAttendList] = useState([]); // 출근기록
  const [overTimeHour, setOverTimeHour] = useState("0"); // 연장 근무시간
  const [overTimeMin, setOverTimeMin] = useState("0"); // 연장 근무시간
  const [unsignOverTimeMin, setUnSignOverTimeMin] = useState("0"); // 미승인 연장 근무시간
  const [unsignOverTimeHour, setUnSignOverTimeHour] = useState("0"); // 미승인 연장 근무시간
  const [varBarData, setVarBarData] = useState([0, 0]); //  연장 근무시간 그래프
  const [yearCnt1, setYearCnt1] = useState("0"); // 정상출근 카운트
  const [yearCnt2, setYearCnt2] = useState("0"); // 지각 카운트
  const [yearCnt3, setYearCnt3] = useState("0"); // 휴가/반차 카운트
  const [varDoughnutData, setVarDoughnutData] = useState([0, 0, 0]);
  const [adminMenu, setAdminMenu] = useState([]);
  const [image, setImage] = useState(""); // 프로필 사진

  const barData = {
    labels: ["승인 연장근무", "미승인 연장근무"],
    datasets: [
      {
        labels: "연장 근무",
        data: varBarData,
        backgroundColor: ["#0078f1", "#cce4fc"],
        borderWidth: 1,
      },
    ],
  };

  const doughnutData = {
    labels: ["정상", "지각", "초과근무"],
    datasets: [
      {
        label: "# of Votes",
        data: varDoughnutData,
        backgroundColor: ["#0078f1", "#fc1923", "#ffa600"],
        borderColor: ["#0078f1", "#fc1923", "#ffa600"],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    getAttendInfo();
    getAttendWorkInfo();
    getAdminMenu();
  }, []);

  const getAttendInfo = () => {
    GwAPI.getAttendDashBoradInfo().then((res) => {
      let attendFlag = res.data.dataAttendMap.attendInfo.type;
      let attendTypeNm = res.data.dataAttendMap.attendInfo.type_name;
      let attendType = res.data.dataAttendMap.attendEmp.attend_type;
      let sdate = res.data.dataAttendMap.attendInfo.sdate;
      let todate = res.data.dataAttendMap.attendInfo.todate;

      setComNm(res.data.dataAttendMap.empEntity.corporation_name);
      setEmpNm(res.data.dataAttendMap.empEntity.name_kor);
      setPostionNm(res.data.dataAttendMap.empEntity.position_name);
      setEnterDate(res.data.dataAttendMap.empEntity.enter_date);
      setBranchNm(res.data.dataAttendMap.empEntity.work_branch_name);
      setImage(res.data.dataAttendMap.empEntity.user_picture);

      setAttendTodayInfo(res.data.dataAttendMap.attendInfo);
      setDataAttendMap(res.data.dataAttendMap);
      setTypeNm(attendTypeNm);
      setAttendFlag(attendFlag);
      seAttendType(attendType);
      setIsVacation(res.data.dataAttendMap.isVacation);
      //seIsAttendNew(res.data.dataAttendMap.isAttendNew);
      if (attendFlag == 900773) {
        setTypeNm("출근전");
      }
    });
  };

  const getAttendWorkInfo = () => {
    GwAPI.getAttendWorkInfo().then((res) => {
      let attendData = res.data;
      let weekCertSec = attendData.weekCertSec;
      let weekRemainSec = attendData.weekRemainSec;
      let weekOverTimeList = attendData.weekOverTimeList;
      let weekOverTime = weekOverTimeList[0];
      let yearTotal = attendData.yearTotal;

      // 금주 근무시간이 존재하면 처리
      if (weekCertSec > 0) {
        let weekCert = attendData.weekCertTime.split(":");
        let weekCertHour = weekCert[0];
        let weekCertMin = weekCert[1];
        if (weekCertHour > 0) {
          setWeekCertHour(weekCertHour);
        }
        if (weekCertMin > 0) {
          setWeekCertMin(weekCertMin);
        }
      }

      // 잔여시간 처리
      if (weekRemainSec > 0) {
        let weekRemain = attendData.weekRemainTime.split(":");
        let weekRemainHour = weekRemain[0];
        let weekRemainMin = weekRemain[1];
        if (weekRemainHour > 0) {
          setWeekRemainHour(weekRemainHour);
        }
        if (weekRemainMin > 0) {
          setWeekRemainMin(weekRemainMin);
        }
      }

      let totOverTime = 0;
      let totUnsignOverTime = 0;
      if (weekOverTime.over_sec > 60) {
        let overTimeHour = parseInt(weekOverTime.over_sec / 60 / 60);
        let overTimeMin = (weekOverTime.over_sec / 60) % 60;

        setOverTimeHour(overTimeHour);
        setOverTimeMin(overTimeMin);

        totOverTime = overTimeHour + overTimeMin / 100;
      }

      if (weekOverTime.unsign_over_sec > 60) {
        let overTimeHour = parseInt(weekOverTime.unsign_over_sec / 60 / 60);
        let overTimeMin = (weekOverTime.unsign_over_sec / 60) % 60;

        setUnSignOverTimeHour(overTimeHour);
        setUnSignOverTimeMin(overTimeMin);

        totUnsignOverTime = overTimeHour + overTimeMin / 100;
      }

      setWeekBeforeList(attendData.weekBeforeList);
      setVarBarData([totOverTime, totUnsignOverTime]);
      let year1 = yearTotal.year1 + yearTotal.year6 + yearTotal.year7;
      let year2 = yearTotal.year2;
      let year3 = yearTotal.year5 + yearTotal.year6;
      setYearCnt1(year1); // 정상출근
      setYearCnt2(year2);
      setYearCnt3(year3);
      setVarDoughnutData([year1, year2, year3]);

      setAttendList(attendData.attendList);
      console.log("===========>" + barData.datasets[0].data[0]);
    });
  };

  const getAdminMenu = () => {
    let param = {
      su_menu_cd: "100009",
      menuTitle: "관리자",
      isContentWrap: false,
      isOpen: false,
    };
    util.getGroupwareAdminMenu(setAdminMenu, param);
  };

  const onClickAttend = (kind) => {
    let currentTime = moment().format("HH:mm");
    let checkTime = currentTime.replace(":", "");
    let attendStart = "0000";
    let attendEnd = "0500";

    if (attendType != "900782") {
      alert("그룹웨어(사이트) 근태처리 설정이 조회되지 않습니다.");
      return;
    }

    if (attendFlag == "900775") {
      // 휴가
      alert("휴가시에는 출/퇴근처리 대상자가 아닙니다.");
      return;
    } else if (attendFlag == "900776") {
      // 휴직
      alert("휴직시에는 출/퇴근처리 대상자가 아닙니다.");
      return;
    } else if (attendFlag == "900778") {
      // 결근
      alert("결근시에는 근태조정계를 제출하십시오");
      return;
    }

    // 출근일 경우 예외 처리
    if (kind == "900773") {
      if (checkTime >= attendStart && checkTime <= attendEnd) {
        // 00:00 ~ ##:## 사이에는 출근 체크를 할 수 없습니다.
        alert(
          "00:00 ~ " +
            attendEnd.substring(0, 2) +
            ":" +
            attendEnd.substring(2, 4) +
            " 사이에는 출근 체크를 할 수 없습니다."
        );
        return;
      }

      if (attendFlag != "900773" && attendFlag != "") {
        if (!isVacation) {
          // 해당일 초기화된 대상의 근태코드가 휴가유형인 경우 출근 시간 입력 허용
          // 이미 출근 체크를 하셨습니다.
          alert("이미 출근체크를 하셨습니다.");
          return;
        }
      }
    }

    // 퇴근일 경우 예외 처리
    if (kind == "900788") {
      if (checkTime >= attendStart && checkTime <= attendEnd) {
        if (
          !window.confirm(
            "00:00 ~ " +
              attendEnd.substring(0, 2) +
              ":" +
              attendEnd.substring(2, 4) +
              " 사이의 퇴근은 전일 퇴근으로 처리됩니다."
          )
        ) {
          // 00:00 ~ ??:?? 사이의 퇴근은 전일 퇴근으로 처리됩니다.
          return;
        }
      }

      // 퇴근요청시 전일 근무기록이 조회되지 않는 경우 금일 출근처리가 진행되지 않아 퇴근할수 없음
      if (attendTodayInfo.sdate == attendTodayInfo.todate) {
        alert("업무 시작을 하지 않으셨습니다.");
        return;
      }
    }

    let payload = {
      kind: kind,
    };
    // 900773 : 출근전
    // 900775 : 휴가
    // 900776 : 휴직
    // 900778 : 결근
    GwAPI.postAttened(payload).then((res) => {
      alert(res.data.jsonMessage.message);
      getAttendInfo();
      getAttendWorkInfo();
    });
  };

  return (
    <div id="GroupwareCommute">
      <GroupwareSideBar
        currentPath={groupWareWorksPath["attend"]}
        adminMenu={adminMenu}
        sidebar={"attend"}
      />
      <div className="commute_wrapper">
        <h2>MY 출퇴근 현황</h2>
        <div className="commute_grid_items">
          <div className="commute_grid_item user_info">
            <div>
              <h5>
                {comNm}
                <span>입사일 : {enterDate}</span>
              </h5>
              <div className="user_info_data">
                <img
                    onError={(e) => {
                      e.target.src =
                          config.gwUrl +
                          "/resource/gw/images/kor/g2works/main/basic/user_photo_default.png";
                    }}
                    src={`${
                        config.gwUrl
                    }/bizoffi-api/member/userImg.do?type=user&id=${image}&g-token=${localStorage.getItem(
                        config.gwKeyToken
                    )}`}
                />
                <div className="user_name">
                  <p>{branchNm}</p>
                  <p>
                    {empNm} <span>{postionNm}</span>
                  </p>
                </div>
                <div className="user_commute">
                  <span>{typeNm}</span>입니다
                </div>
              </div>
            </div>
            <div className="commute_datetime">
              <Moment format="yyyy-MM-DD (ddd)" />
              <Moment format="HH : mm : ss" interval={1000} />
              {attendFlag === "900773" && attendType === "900782" ? (
                <button onClick={() => onClickAttend("900787")}>
                  출근하기
                </button>
              ) : attendType === "900782" ? (
                <button onClick={() => onClickAttend("900788")}>
                  퇴근하기
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="commute_grid_item">
            <p className="grid_item_title">근로시간 현황</p>
            <div className="bar_chart_data">
              <div>
                <p>이번 주 근로시간</p>
                <p>
                  <span>{weekCertHour} </span>시간 <span>{weekCertMin} </span>분
                </p>
              </div>
              <div>
                <p>잔여 근로시간</p>
                <p>
                  <span>{weekRemainHour} </span>시간{" "}
                  <span>{weekRemainMin} </span>분
                </p>
              </div>
            </div>
            <div className="percentage_bar_wrapper">
              <div className="percentage_bar_text">
                <p>최소 40시간</p>
                <p>최대 52시간</p>
              </div>
              <div className="percentage_bar">
                <div className="percentage_bar_week" />
                <div className="percentage_bar_remain" />
              </div>
            </div>
            <div className="bar_chart_data_history">
              {weekBeforeList.map((item, index) => {
                let weekCert = item.weekCertTime.split(":");
                let weekCertSec = item.weekCertSec;
                let weekCertHour = 0;
                let weekCertMin = 0;
                if (weekCertSec > 0) {
                  weekCertHour = weekCert[0];
                  weekCertMin = weekCert[1];
                }
                return (
                  <p>
                    {item.startDate} ~ {item.endDate}{" "}
                    <span>
                      {weekCertHour}시간 {weekCertMin}분
                    </span>
                  </p>
                );
              })}
            </div>
            <div className="commute_link_btn">
              <button
                onClick={() => util.getGroupwareSrcPath("attendWorkInfo")}
              >
                모두 보기 &gt;
              </button>
            </div>
          </div>
          <div className="commute_grid_item">
            <p className="grid_item_title">출퇴근 통계</p>
            <div className="doughnut_chart_wrapper">
              <Doughnut
                data={doughnutData}
                options={doughnutOption}
                width={100}
                height={100}
              />
            </div>
            <div className="doughnut_data">
              <div>
                <p>정상</p>
                <p>{yearCnt1}</p>
              </div>
              <div>
                <p>지각</p>
                <p>{yearCnt2}</p>
              </div>
              <div>
                <p>휴가/반차</p>
                <p>{yearCnt3}</p>
              </div>
            </div>
            <div className="commute_link_btn">
              <button
                onClick={() =>
                  util.getGroupwareSrcPath("writeForm", "100108", "vacation")
                }
              >
                근태조정 신청 &gt;
              </button>
            </div>
          </div>
          {/*<div className="commute_grid_item">*/}
          {/*  <p className="grid_item_title">연장근무 현황</p>*/}
          {/*  <div className="bar_chart_wrapper">*/}
          {/*    <Bar*/}
          {/*      data={barData}*/}
          {/*      options={barOption}*/}
          {/*      width={200}*/}
          {/*      height={100}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*  <div className="bar_chart_data">*/}
          {/*    <div>*/}
          {/*      <p>승인 연장근무</p>*/}
          {/*      <p>*/}
          {/*        <span>{overTimeHour} </span>시간 <span>{overTimeMin} </span>분*/}
          {/*      </p>*/}
          {/*    </div>*/}
          {/*    <div>*/}
          {/*      <p>미승인 연장근무</p>*/}
          {/*      <p>*/}
          {/*        <span>{unsignOverTimeHour} </span>시간 <span>{unsignOverTimeMin} </span>분*/}
          {/*      </p>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div className="commute_link_btn">*/}
          {/*    <button onClick={() => util.getGroupwareSrcPath("writeFormWorkTime", "worktime", "vacation")}>연장근무 신청 &gt;</button>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="commute_grid_item">
            <p className="grid_item_title">내 출퇴근 현황</p>
            <div className="commute_table_wrapper">
              <table className="commute_table">
                <thead>
                  <tr>
                    <th>날짜</th>
                    <th>출근시각</th>
                    <th>퇴근</th>
                    <th>여부</th>
                  </tr>
                </thead>
                <tbody>
                  {attendList.slice(0, 5).map((item, index) => {
                    return (
                      <tr>
                        <td>{item.todate}</td>
                        <td>{item.sdate}</td>
                        <td>{item.edate}</td>
                        <td>{item.type_name}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="commute_link_btn">
              <button onClick={() => util.getGroupwareSrcPath("myAttendList")}>
                모두 보기 &gt;
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupwareCommute;
