const CompetitorStatisticSubject = ({ tagList, num }) => {
  const formattedNum = num
    ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : 0;
  return (
    <div id="CompetitorStatisticSubject">
      <div className="subject mb30">
        <ul className="tag_list">
          {tagList.length
            ? tagList.map((item, idx) => (
                <li key={idx} className="tag bg_light_blue">
                  #{item}
                </li>
              ))
            : ""}
        </ul>
        <strong>
          <span className="bright_blue">{formattedNum}개</span> 기업에 대한
          동향과 통계/분석을 확인하세요.
        </strong>
      </div>
    </div>
  );
};

export default CompetitorStatisticSubject;
