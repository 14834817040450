// 미 구매 패키지 메뉴 이용 시 뜨는 Alert 창
import "./css/PaymentAlert.scss";
import { useHistory } from "react-router-dom";

const PaymentAlert = ({ setShowPaymentAlert }) => {
  const history = useHistory();

  return (
    <div id="PaymentAlert">
      <i
        className="icon_exit_gray"
        onClick={() => setShowPaymentAlert(false)}
      />
      <h2>서비스 미구독 안내</h2>
      <h5>
        해당 서비스는 구독하지 않은 서비스입니다.
        <br />
        추가결제를 통해 이용하시겠습니까?
      </h5>
      <div>
        <button onClick={() => history.push("/groupware/payment/list")}>
          결제내역 확인하기
        </button>
        <button onClick={() => history.push("/intro/price")}>
          추가결제 하러가기
        </button>
      </div>
    </div>
  );
};

export default PaymentAlert;
