import PDFDocument from "../common/PDFDocument";
import React, { useContext, useEffect, useState } from "react";
import CommonAPI from "../../API/common";
import "./css/IntroService.scss";
import CommonContext from "../../store/Common";
import memberContext from "../../store/Member";
import LoginPop from "../popup/LoginPop";
import IntroServiceRenew from "./IntroServiceRenew";
import GovProjectBySubject from "./GovProjectBySubject";

const IntroGovProject = () => {
  const [PDFList, setPDFList] = useState([]);
  const [filePDF, setFilePDF] = useState(null);
  const [isLoginPopVisible, setIsLoginPopVisible] = useState(false);
  const { isMobile } = useContext(CommonContext);

  const memberInfo = useContext(memberContext);
  const auth = memberInfo.auth;
  const isLogin = auth != null && auth != undefined;

  useEffect(() => {
    CommonAPI.getTaskPDFList().then((res) => {
      setPDFList(res.data.items.sort((a, b) => a.order - b.order));
    });
  }, []);

  const handlePDFClick = (item) => {
    if (isLogin) {
      setFilePDF(item);
    } else {
      showLoginPop(true);
    }
  };

  const showLoginPop = () => {
    setIsLoginPopVisible(true);
  };

  const closeLoginPop = () => {
    setIsLoginPopVisible(false);
  };

  return (
    <div id="IntroService">
      <img
        src={
          require(isMobile
            ? "../../assets/images/intro/page_banner_top3_mo.png"
            : "../../assets/images/intro/page_banner_top3.png").default
        }
        className="banner_top"
        alt="탑 배너"
      />
      <div className="upload_area">
        <GovProjectBySubject />
        <IntroServiceRenew />
      </div>
      <img
        src={
          require(isMobile
            ? "../../assets/images/intro/master_plan_mo.png"
            : "../../assets/images/intro/master_plan.png").default
        }
        className="banner_top"
        alt="탑 배너"
      />
      <div className="upload_area">
        <h2 className="work_tit">2025년도 정부지원사업 핵심공고</h2>
        <div className="upload_list">
          {PDFList.map((item) => {
            return (
              <div
                key={item.idx}
                className="upload_item"
                onClick={() => handlePDFClick(item)}
              >
                <img
                  src={
                    require("../../assets/images/common/government.png").default
                  }
                  className="images"
                />
                {/* {isMobile && (
                  <img
                    src={
                      require("../../assets/images/common/img-gov-pdf.svg")
                        .default
                    }
                  />
                )} */}
                <p>{item.title}</p>
                <button className="btn_pdf_download">자료보기</button>
              </div>
            );
          })}
        </div>
      </div>
      {filePDF && <PDFDocument file={filePDF} setFile={setFilePDF} />}
      <LoginPop isVisible={isLoginPopVisible} handleClose={closeLoginPop} />
    </div>
  );
};

export default IntroGovProject;
