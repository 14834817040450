import ResearchTechInfoText from "./ResearchTechInfoText";
import ResearchTechSiteMap from "./ResearchTechSiteMap";

const ResearchTechHeader = () => {
  return (
    <>
      <ResearchTechInfoText />
      <ResearchTechSiteMap />
    </>
  );
};

export default ResearchTechHeader;
