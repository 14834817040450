import React from "react";
import SignupPerson from "./SignupPerson";
import SignupCompany from "./SignupCompany";
import qs from "query-string";
import { useLocation } from "react-router-dom";
import SignupConst from "../const";
import RenewalSignupCompanyFirst from "./RenewalSignupCompany";

function SignupInputContainer() {
  const location = useLocation();
  const { type } = qs.parse(location.search);
  const isPerson = type === SignupConst.findbiz.signup.person;
  const isCompany = type === SignupConst.findbiz.signup.company;

  return (
    <div id="SignupInputContainer">
      {isPerson && <SignupPerson />}
      {isCompany && <RenewalSignupCompanyFirst />}
    </div>
  );
}

export default SignupInputContainer;
