import React from "react";
import UserAuthRoute from "./common/UserAuthRoute";
import InvetionList from "../components/invention/InventionListView";
import InventionDetail from "../components/invention/InventionDetail";
import CustomSwitch from "./common/CustomSwitch";

function InventionRoute({match}) {
	return (
		<CustomSwitch>
			<UserAuthRoute exact path={`${match.path}/list`} requireAuth={true} component={InvetionList}/>
			<UserAuthRoute exact path={`${match.path}/:invention_idx`} requireAuth={true} component={InventionDetail}/>
		</CustomSwitch>
	)
}

export default InventionRoute;
