import "../competitor/css/CompetitorInfo.scss";
import React, { useEffect, useState } from "react";
import logo from "../../assets/images/common/logo_findBiz.svg";
import { useParams } from "react-router-dom";
import axios from "axios";

const CompetitorIpnowInfo = ({ history }) => {
  const { idx } = useParams();
  const [companyInfo, setCompanyInfo] = useState(
    history.location.state.company_data
  );
  const [competitorInfo, setCompetitorInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getCompetitorInfo();
  }, []);

  const getCompetitorInfo = async () => {
    await axios
      .get(`/api/v3/get_company_info`, {
        params: { company: idx },
      })
      .then((res) => {
        setCompetitorInfo(res.data);
        setIsLoading(false);
      });
  };

  const handleNavigate = () => {
    if (companyInfo.dummy_idx) {
      history.push({
        pathname: `/competitor/details/${companyInfo.dummy_idx}`,
        state: {
          company_data: competitorInfo.info,
        },
      });
    } else {
      alert("분석정보를 볼 수 없는 기업입니다.");
      return;
    }
  };

  return (
    <div id="CompetitorInfo">
      <header className="header_base">
        <h2>경쟁사 조사</h2>
        <p>
          {/*설명 란<br />*/}
          {/*설명 란<br />*/}
          {/*설명 란*/}
        </p>
      </header>
      <div className="company_info_wrapper">
        <div>
          <h5>
            <span>{companyInfo.company_name}</span> 기업 상세정보 보기
          </h5>
          <button onClick={() => history.goBack()} className="btn_back">
            뒤로 돌아가기
          </button>
          <div className="company_info_items">
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>{companyInfo.company_name}</p>
                <p>대표명: {companyInfo.company_ceo}</p>
                <div className="item_text_category">
                  {companyInfo.grouptags.map((keyword) => {
                    return <p>{keyword.company_group_name}</p>;
                  })}
                </div>
              </div>
              <button onClick={handleNavigate}>기업 분석정보</button>
            </div>
          </div>
        </div>
        <div>
          <h5>
            <span>{companyInfo.company_name}</span>의 기업 정보 분석
          </h5>
          <div className="company_info_items custom_scroll">
            {isLoading ? (
              <p>Loading...</p>
            ) : competitorInfo?.competitorlist === false ? (
              <p>경쟁사 정보 없음</p>
            ) : (
              competitorInfo?.competitorlist?.result.map((item) => {
                console.log(item);
                return (
                  <div className="company_info_item">
                    <img src={logo} />
                    <div className="item_text">
                      <p>{item.company_name}</p>
                      <p>-</p>
                      <div className="item_text_category">
                        <p>데이터</p>
                        <p>B2B</p>
                        <p>플랫폼</p>
                      </div>
                    </div>
                    <button onClick={() => alert("준비중입니다.")}>
                      기업 분석정보
                    </button>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompetitorIpnowInfo;
