import { useEffect, useState } from "react";
import CompanyAPI from "../../../API/company";
import MyCltGroupPop from "./MyCltGroupPop";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Pagination from "../../common/Pagination";

const MyClt = ({
  ctlList,
  getCtlAll,
  memberIdx,
  page,
  last,
  setPage,
  flagType,
}) => {
  const [expandedItems, setExpandedItems] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [compCltGroup, setCompCltGroup] = useState([]); // 거래처 그룹
  const [isOpenGroupPop, setIsOpenGroupPop] = useState(false); // 거래처 그룹관리 팝업
  const typeName = flagType === 1 ? "거래처" : "경쟁사";

  const toggleItem = (group_idx) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [group_idx]: !prevState[group_idx],
    }));
  };

  const handleCheck = (groupIdx, itemIdx, biz_no, idx) => {
    setSelectedItems((prevSelectedItems) => {
      const itemExists = prevSelectedItems.some(
        (item) => item.group_idx === groupIdx && item.client_idx === itemIdx
      );
      if (itemExists) {
        return prevSelectedItems.filter(
          (item) =>
            !(item.group_idx === groupIdx && item.client_idx === itemIdx)
        );
      } else {
        return [
          {
            group_idx: groupIdx,
            client_idx: itemIdx,
            biz_no: biz_no,
            idx: idx,
          },
          ...prevSelectedItems,
        ];
      }
    });
  };

  const getCltGroup = () => {
    CompanyAPI.getCltGroup({
      owner_idx: memberIdx,
      flag: flagType,
    }).then((res) => {
      //console.log("getCltGroup:", res);
      if (res.data.code === "00") {
        if (flagType === 1) {
          setCompCltGroup(res.data?.data?.clientGroups || []);
        } else if (flagType === 2) {
          setCompCltGroup(res.data?.data?.competitorGroups || []);
        }
      } else {
        setCompCltGroup([]);
      }
    });
  };

  const groupMng = () => {
    getCltGroup();
    setIsOpenGroupPop(true);
  };

  // const delItems = (groupIdx) => {
  //   let groupItems = [];

  //   if (selectedItems.length > 0) {
  //     groupItems = selectedItems.filter((item) => item.group_idx === groupIdx);
  //     if (groupItems.length) {
  //       groupItems.forEach((comp) => {
  //         CompanyAPI.deleteClt({
  //           group_idx: comp.group_idx,
  //           client_idx: comp.client_ids,
  //           member_idx: memberIdx,
  //         }).then((res) => {
  //           //console.log("res:", res);
  //           getCtlAll();
  //         });
  //       });
  //     }
  //   }
  // };

  const delCtl = () => {
    if (selectedItems.length) {
      const confirmResult = window.confirm(`${typeName}를 삭제하시겠습니까?`);
      if (confirmResult) {
        selectedItems.forEach((comp) => {
          //console.log("comp:", comp);
          CompanyAPI.deleteClt({
            group_idx: comp.group_idx,
            xcompany_idx: comp.client_idx,
            biz_no: comp.biz_no,
            idx: comp.idx,
            owner_idx: memberIdx,
            flag: flagType,
          }).then((res) => {
            //console.log("delCtl:", res);
            getCtlAll();
            setSelectedItems([]);
          });
        });
      }
    } else {
      alert(`삭제할 ${typeName}를 선택해주세요.`);
    }
  };

  return (
    <div id="MyClt">
      <h3 className="cont_title">{typeName} 기업</h3>
      <p className="cont_sub_title">
        {flagType === 1 ? "거래처 기업으로" : "경쟁사로"} 지정한 기업을 확인하고
        수정할 수 있습니다.
      </p>
      <div className="btn_box">
        <button className="del" onClick={() => delCtl()}>
          {typeName} 삭제
        </button>
        <button onClick={() => groupMng()}>{typeName} 그룹 관리</button>
      </div>
      <div className="ctl_list">
        {ctlList.length ? (
          <ul>
            {ctlList.map((list) => (
              <li key={list.group_idx}>
                <div className="group">
                  <strong>
                    {list.group_name}
                    <span style={{ marginLeft: "4px" }}>
                      ({list.length || 0})
                    </span>
                  </strong>
                  {list.length ? (
                    expandedItems[list.group_idx] ? (
                      <div className="open_util">
                        {/* <button
                          className="del_btn"
                          onClick={() => delItems(list.group_idx)}
                        >
                          삭제
                        </button> */}
                        <button
                          className={`show_list rotate`}
                          onClick={() => toggleItem(list.group_idx)}
                        >
                          <i className="icon_more_arrow_right"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        className={`show_list`}
                        onClick={() => toggleItem(list.group_idx)}
                      >
                        <i className="icon_more_arrow_right"></i>
                      </button>
                    )
                  ) : (
                    ""
                  )}
                </div>
                {expandedItems[list.group_idx] &&
                  list.children &&
                  list.children.length > 0 && (
                    <div className="children_tbl custom_scroll">
                      <table>
                        <thead>
                          <tr>
                            <th>선택</th>
                            <th>기업명</th>
                            <th>대표자</th>
                            <th>소재지</th>
                            {/* <th>기업형태</th> */}
                            <th>바로가기</th>
                          </tr>
                        </thead>
                        <tbody>
                          {list.children.map((child) => (
                            <tr key={child.client_idx}>
                              <td>
                                <input
                                  type="checkbox"
                                  onChange={() =>
                                    handleCheck(
                                      list.group_idx,
                                      child.client_idx,
                                      child.biz_no,
                                      child.idx
                                    )
                                  }
                                />
                              </td>
                              <td>{child.client_name || ""}</td>
                              <td>{child.client_ceo || ""}</td>
                              <td>{child.client_addr || ""}</td>
                              {/* <td>기업형태</td> */}
                              <td>
                                {child.applicant && child.biz_no ? (
                                  <Link
                                    to={`/competitor/details?applicant=${child.applicant}&bizno=${child.biz_no}`}
                                    className="url"
                                  >
                                    조회
                                  </Link>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
              </li>
            ))}
          </ul>
        ) : (
          "데이터가 없습니다."
        )}
      </div>
      {ctlList.length ? (
        <Pagination curPage={page} lastNum={last} onClick={setPage} />
      ) : (
        ""
      )}

      {isOpenGroupPop && (
        <MyCltGroupPop
          myClt={compCltGroup}
          setIsOpenGroupPop={setIsOpenGroupPop}
          memberIdx={memberIdx}
          getCltGroup={getCltGroup}
          getCtlAll={getCtlAll}
          flagType={flagType}
        />
      )}
    </div>
  );
};

export default MyClt;
