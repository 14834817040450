import "../css/HoldingsHome.scss";
import HoldingsCashflow from "./HoldingsCashflow";
import HoldingsComp from "./HoldingsComp";
import HoldingsHomeTop from "./HoldingsHomeTop";
import HoldingsInvest from "./HoldingsInvest";
import HoldingsInvestDistribution from "./HoldingsInvestDistribution";
import HoldingsLevel from "./HoldingsLevel";
import HoldingsSituation from "./HoldingsSituation";
import HoldingsStatic from "./HoldingsStatic";

const HoldingsHome = () => {
  return (
    <div id="HoldingsHome" className="holdings">
      <HoldingsHomeTop />
      <HoldingsInvest />
      <HoldingsComp />
      <HoldingsStatic />
      <HoldingsCashflow />
      <div className="distribution">
        <HoldingsLevel />
        <HoldingsInvestDistribution />
      </div>
      <HoldingsSituation />
    </div>
  );
};

export default HoldingsHome;
