import React, { useContext, useEffect } from "react";
import "./css/IntroContainer.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import IntroIpNow from "./ipnow/IntroIpNow";
import IntroFindBiz from "./findbiz/IntroFindBiz";
import { useLocation } from "react-router-dom";
import RenewalMain from "./findbiz/RenewalMain";
import CommonContext from "../../store/Common";
import RenewalMainMobile from "./findbiz/RenewalMainMobile";
import CommonAPI from "../../API/common";

function IntroContainer({ history }) {
  const location = useLocation();
  const isViewTypeIpnow = window.$Global.isIpnow(location);
  const { isMobile } = useContext(CommonContext);

  useEffect(() => {
    const payload = {
      referer: document.referrer,
    };
    CommonAPI.log(payload).then(() => console.log("success"));
  }, []);

  return (
    <div id="IntroContainer">
      {isViewTypeIpnow ? (
        <IntroIpNow history={history} />
      ) : isMobile ? (
        <RenewalMainMobile />
      ) : (
        <RenewalMain />
      )}
    </div>
  );
}

export default IntroContainer;
