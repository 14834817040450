import React, {useState} from 'react';

let invention =  React.createContext({
    inventionName: "",
    projectName: {idx: '', name: ''},
    inventors: [{idx: '', name: '', share: '100'}], // 발명자 목록
    applicantors: [], // 공동 출원인
    inventionPublish: [], // 발명의 공개
    paymentManageCompany: {idx: '', name: ''},
    patentManageCompany: {idx: '', name: ''},
    inventionUploadIFileViewList: [], // 직무 발명 신고서 업로드 파일 리스트
    descriptionUploadIFileViewList: [], // 발명 설명서 업로드 파일 리스트
});

export default invention;
