import React, { useRef, useState } from "react";
import AdminMailingTemplate from "../admin/manage/AdminMailingTemplate";
import InfoCompanyAPI from "../../API/infoCompany";
import _ from "lodash";
import Validator from "../../util/validator";
import "./css/Calculator.scss";

function Calculator({ history }) {
  const initData = {
    name: "",
    company_code: "IPNOW",
    type: 0,
  };
  const [payload, setPayload] = useState(initData);
  const [selectCompanyList, setSelectCompanyList] = useState([]);
  const [mouse, setMouse] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [keywordList, setKeywordList] = useState([]);

  const keywordRef = useRef();
  const show = mouse && Boolean(keywordList.length);

  const onClickSearch = () => {
    if (Validator.refValidator([keywordRef])) {
      InfoCompanyAPI.getInfoCompany(keyword).then((res) => {
        setMouse(true);
        setKeywordList(res.data.filter((item) => item.company_code));
      });
    }
  };

  const choiceKeyword = (name, code) => {
    setMouse(false);
    setPayload({ ...payload, name: name, company_code: code });

    let copy = _.cloneDeep(selectCompanyList);
    copy.push({ name: name, company_code: code });
    setSelectCompanyList(copy);
  };

  const onClickClose = () => {
    setMouse(false);
    setKeyword("");
    setKeywordList([]);
    setPayload({ name: "", company_code: "" });
  };

  return (
    <div id="Calculator">
      <div className="input">
        <h2 className="title">
          <span>회사명</span>을 입력해주세요.
        </h2>
        <div className="wrap_search">
          <input
            type="text"
            className="company"
            value={keyword}
            placeholder="회사명을 입력하여 검색하실 수 있습니다"
            onChange={(e) => setKeyword(e.target.value)}
            onKeyUp={(e) => e.key === "Enter" && onClickSearch()}
            ref={keywordRef}
            data-name={"회사명"}
            autoFocus={true}
            onFocus={() => setMouse(true)}
          />
          <button className="btn_search ir_txt" onClick={onClickSearch}>
            검색
          </button>
        </div>
        <img
          className="img_person"
          src={require(`../../assets/images/calc/person.png`).default}
          alt="사람 이미지"
        />
        <p className="desc">
          *회사명을 입력하시면 아래 IPNOW처럼
          <br />
          귀사의 실시간 IP 현황을 바로 확인할 수 있습니다.
        </p>
        {show && (
          <button className="icon_badge_del ir_txt" onClick={onClickClose}>
            닫기
          </button>
        )}
        {show && (
          <ul className="keyword_list">
            {keywordList.map((item, idx) => {
              return (
                <li
                  key={idx}
                  onClick={() => choiceKeyword(item.name, item.company_code)}
                >
                  <p>
                    <b>회사명:</b>
                    {item.name}
                  </p>
                  <p>
                    <b>주소:</b>
                    {item.location}
                  </p>
                  <p>
                    <b>법인번호:</b>
                    {item.corporate_number}
                  </p>
                  <p>
                    <b>사업자 등록번호:</b>
                    {item.license_number}
                  </p>
                </li>
              );
            })}
          </ul>
        )}
      </div>
      <AdminMailingTemplate
        history={history}
        company_code={payload.company_code}
        isAll={true}
      />
    </div>
  );
}

export default Calculator;
