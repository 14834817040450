import { useEffect, useState } from "react";
import "./css/SubsCost.scss";

const SubsCost = ({
  storageCheckedData,
  subPackage,
  count,
  cost,
  packageCost,
}) => {
  const [data, setData] = useState({});

  useEffect(() => {
    subPackage.map((item) => {
      if (item.isChecked) {
        setData(item);
      }
    });
  }, [subPackage]);

  const getText = () => {
    if (data.title.includes("베이직")) {
      return (
        <>
          {cost.subsPeriod === 1 ? (
            <p>{`└ 기본가 월 3,000원 / 계정`}</p>
          ) : (
            <>
              <p>{`└ 할인가 월 1,800원 / 계정 (기본가 3,000원)`}</p>
              <p>{`└ 기업정보 조회 월 10건 제공 / 3개월`}</p>
              <p>{`└ 시장정보 월 3건 다운로드 제공 / 3개월`}</p>
              <p>{`└ 특허리포트 월 3건 다운로드 제공 / 3개월`}</p>
            </>
          )}
          {/*<p>{`└ 맞춤 정부사업 메일링 제공`}</p>*/}
          {/*<p>{`└ 최초 설치비 40,000원 포함`}</p>*/}
        </>
      );
    } else if (data.title.includes("프리미엄")) {
      return (
        <>
          {cost.subsPeriod === 1 ? (
            <p>{`└ 기본가 월 6,900원 / 계정`}</p>
          ) : (
            <>
              <p>{`└ 할인가 월 4,140원 / 계정 (기본가 6,900원)`}</p>
              <p>{`└ 기업정보 조회 월 10건 제공 / 3개월`}</p>
              <p>{`└ 시장정보 월 3건 다운로드 제공 / 3개월`}</p>
              <p>{`└ 특허리포트 월 3건 다운로드 제공 / 3개월`}</p>
            </>
          )}
          {/*<p>{`└ 최초 설치비 40,000원 포함`}</p>*/}
        </>
      );
    } else if (data.title.includes("무료")) {
      return null;
    }
  };

  return data.isChecked ? (
    <div className="subs_section_items">
      <header>
        <p className="name">{data.title}</p>
        <p>{window.$Global.commaify(packageCost)}원</p>
      </header>
      <div>
        <p>{`└ ${count}명`}</p>
        {data.id !== "free" && <p>{`└ ${cost.subsPeriod}개월`}</p>}
        {data.isStorageChecked ? (
          <p>{`└ ${storageCheckedData.product_option_nm}`}</p>
        ) : null}
        {getText()}
      </div>
    </div>
  ) : null;
};

export default SubsCost;
