import Highlighter from "react-highlight-words";
import React from "react";
import useMoveScroll from "../../../util/useMoveScroll";

const PatentInfoKR = ({ infoData, highlightKeyword }) => {
  const tabs = {
    0: useMoveScroll("기술분야"),
    1: useMoveScroll("배경기술"),
    2: useMoveScroll("해결과제"),
    3: useMoveScroll("해결수단"),
    4: useMoveScroll("발명효과"),
    5: useMoveScroll("도면 간단설명"),
    6: useMoveScroll("발명 구체내용"),
    length: 7,
  };

  return (
    <div>
      <h5>명세서</h5>
      <div className="spec_tab">
        {Array.from(tabs).map((tab, index) => {
          return <div onClick={tab.onMoveToElement}>{tab.name}</div>;
        })}
      </div>
      <div className="patent_spec_items">
        <div>
          <p style={{ color: "#4593f5" }} ref={tabs[0].element}>
            기술분야
          </p>
          <p>
            {infoData.technialField?.map((item) => {
              return (
                <>
                  <span>[{item.num}]</span>
                  <Highlighter
                    searchWords={highlightKeyword}
                    autoEscape={true}
                    textToHighlight={item.text}
                  />
                  {/*<span>{item.text}</span>*/}
                  <br />
                  <br />
                </>
              );
            })}
          </p>
        </div>
        <div>
          <p ref={tabs[1].element}>배경 기술</p>
          <p>
            {infoData.backgroundArt?.map((item) => {
              return (
                <>
                  <span>[{item.num}]</span>
                  <Highlighter
                    searchWords={highlightKeyword}
                    autoEscape={true}
                    textToHighlight={item.text}
                  />
                  <br />
                  <br />
                </>
              );
            })}
          </p>
        </div>
        <div>
          <p ref={tabs[2].element}>해결 과제</p>
          <p>
            {infoData.techProblem?.map((item) => {
              return (
                <>
                  <span>[{item.num}]</span>
                  <Highlighter
                    searchWords={highlightKeyword}
                    autoEscape={true}
                    textToHighlight={item.text}
                  />
                  <br />
                  <br />
                </>
              );
            })}
          </p>
        </div>
        <div>
          <p ref={tabs[3].element}>해결 수단</p>
          <p>
            {infoData.techSolution?.map((item) => {
              return (
                <>
                  <span>[{item.num}]</span>
                  <Highlighter
                    searchWords={highlightKeyword}
                    autoEscape={true}
                    textToHighlight={item.text}
                  />
                  <br />
                  <br />
                </>
              );
            })}
          </p>
        </div>
        <div>
          <p ref={tabs[4].element}>발명 효과</p>
          <p>
            {infoData.advantageousEffects?.map((item) => {
              return (
                <>
                  <span>[{item.num}]</span>
                  <Highlighter
                    searchWords={highlightKeyword}
                    autoEscape={true}
                    textToHighlight={item.text}
                  />
                  <br />
                  <br />
                </>
              );
            })}
          </p>
        </div>
        <div>
          <p ref={tabs[5].element}>도면 간단설명</p>
          <p>
            {infoData.descriptionOfDrawings?.map((item) => {
              return (
                <>
                  <span>[{item.num}]</span>
                  <Highlighter
                    searchWords={highlightKeyword}
                    autoEscape={true}
                    textToHighlight={item.text}
                  />
                  <br />
                  <br />
                </>
              );
            })}
          </p>
        </div>
        <div>
          <p ref={tabs[6].element}>발명실시 구체내용</p>
          <p>
            {infoData.descriptionOfEmbodiments?.map((item) => {
              return (
                <>
                  <span>[{item.num}]</span>
                  <Highlighter
                    searchWords={highlightKeyword}
                    autoEscape={true}
                    textToHighlight={item.text}
                  />
                  <br />
                  <br />
                </>
              );
            })}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PatentInfoKR;
