import React, {useContext, useState} from "react";
import PatentContext from "../../../store/Patent";
import DateAndFileRow from "./common/DateAndFileRow";
import InnerDatePickerAndFileRow from "./common/InnerDatePickerAndFileRow";
import NormalRow from "./common/NormalRow";
import InputRow from "./common/InputRow";
import typeName from "./constrant/typeName";
import {Link} from "react-router-dom";
import DatePickerRow from "./common/DatePickerRow";
import InputAndFileRow from "./common/InputAndFileRow";
import NationAddPopup from "./NationAddPopup";
import SelectRow from "./common/SelectRow";
import DivideApplyRow from "./common/DivideApplyRow";
import ChildNationRow from "./common/ChildNationRow";
import DatePickerEpRow from "./common/DatePickerEpRow";
import ClaimCountRow from "./common/ClaimCountRow";
import _ from "lodash";

function FormAgentEp({ListOaTag, agentCompanyListTag, managerSelectTag, checkAuth, epCreate, inventorListTag,
                       onClickDownPopShow, onClickUpPopShow, onChangeInput, onClickClaim, onClickFirstClaim, onClickNationDelete,
                       onChangeDatePickerInnerDate, onChangeClaimCount, onChangeDatePicker}) {
    const patentInfo = useContext(PatentContext);
    const [popup, setPopup] = useState(false);
    let copy = _.cloneDeep(patentInfo);

    let epTag =
        patentInfo.ep.map((item, index) => {
            return (
                <div key={index} className="field">
                    <div className="header">
                        <h2 className="title">{window.$Global.convertChildNationCodeToStr(item.child_nation)}<i className={`icon_flag_${window.$Global.convertChildNationCodeToStr(item.child_nation)}`}/></h2>
                        <button className="btn_remove" onClick={() => onClickNationDelete(item.idx)}>삭제하기</button>
                    </div>
                    <div className="rows">
                        <NormalRow title={`지정국 번역문\n초안작성 지시 접수일`} content={item.draft_translation_order_at} txtType="date"/>
                        <InnerDatePickerAndFileRow title={'번역문\n초안 접수'} data={item.draft_translation}
                                                   dateBtnHide={true}
                                                   uploadBtnShow={true}
                                                   haveFile={item.translation_draft_file_cnt}
                                                   onClickDownPopShow={() => onClickDownPopShow(typeName.agent_ep_draft_translation, {modified: 0}, '', item.idx)}
                                                   onClickUpPopShow={() => onClickUpPopShow(typeName.agent_ep_draft_translation, {modified: 0}, '', item.idx)}/>
                        <InnerDatePickerAndFileRow title={'번역문\n초안최종본 접수'} data={item.draft_translation_final}
                                                   dateBtnHide={true}
                                                   uploadBtnShow={true}
                                                   haveFile={item.translation_draft_reply_researcher_file_cnt}
                                                   onClickDownPopShow={() => onClickDownPopShow(typeName.agent_ep_draft_translation, {modified: 2}, '', item.idx)}
                                                   onClickUpPopShow={() => onClickUpPopShow(typeName.agent_ep_draft_translation, {modified: 2}, '', item.idx)}/>
                    </div>
                    <div className="rows">
                        <div className="row no_data"/>
                        <div className="row no_data"/>
                        <DatePickerEpRow title='지정국 번역문 기한' titleClassName="color_blue" condition={patentInfo.editing} data={item.translation_deadline}
                                         onChangeDatePicker={(date) => {
                                             copy.ep[index].translation_deadline = date;
                                             patentInfo.setPatent(copy);
                                         }}
                        />
                    </div>
                    <div className="rows">
                        <InputAndFileRow title="등록번호"
                                         content={item.register_number}
                                         editing={patentInfo.editing}
                                         haveFile={item.register_file_cnt}
                                         onClickDownPopShow={() => onClickDownPopShow(typeName.agent_ep_register, '', '', item.idx)}
                                         onClickUpPopShow={() => onClickUpPopShow(typeName.agent_ep_register, '', '', item.idx)}
                                         onChange={(e) => {
                                             copy.ep[index].register_number = e.target.value;
                                             patentInfo.setPatent(copy);
                                         }}
                        />
                        <DatePickerEpRow title='등록일' condition={patentInfo.editing} data={item.register_at} maxDate={new Date()}
                                         onChangeDatePicker={(date) => {
                                             copy.ep[index].register_at = date;
                                             patentInfo.setPatent(copy);
                                         }}
                        />
                        <ClaimCountRow editing={patentInfo.editing} claimCount={{independence: item.independent_item, dependent: item.dependent_item}}
                                       onChangeIndependence={(e) => {
                                           copy.ep[index].independent_item = e.target.value;
                                           patentInfo.setPatent(copy);
                                       }}
                                       onChangeDependent={(e) => {
                                           copy.ep[index].dependent_item = e.target.value;
                                           patentInfo.setPatent(copy);
                                       }}
                        />
                    </div>
                </div>
            );
        });

    return (
        <div className="form">
            <div className="field">
                <div className="rows">
                    <InputRow title="대리인 관리번호" titleClass="color_blue" condition={patentInfo.editing} content={patentInfo.agent_manage_number} onChange={onChangeInput} setKey="agent_manage_number"/>
                </div>
                <div className="rows">
                    <NormalRow title="관리번호" content={patentInfo.manage_number}/>
                    <NormalRow title="유형" content={patentInfo.type}/>
                    <div className="row">
                        <h2 className="title">Family 출원<Link className="icon_plus" to={`/patent/family/${patentInfo.idx}`}/></h2>
                        <p>{patentInfo.family_cnt}건</p>
                    </div>
                </div>
                <div className="rows">
                    <NormalRow title="담당자" content={patentInfo.manager_name}/>
                    <NormalRow title="발명자 1" content={patentInfo.inventor.length && patentInfo.inventor[0].name}/>
                    <SelectRow title="대리인 담당자" content={patentInfo.agent_name} onChange={(e) => patentInfo.setPatent({...patentInfo, agent_idx: e.target.value, agent_name: e.target.options[e.target.selectedIndex].text})}
                               condition={patentInfo.editing} contentIdx={patentInfo.agent_idx} selectTag={managerSelectTag}/>
                </div>
            </div>
            {inventorListTag}
            {patentInfo.mergeTag}
            <div className="field">
                <div className="rows">
                    <NormalRow title={'번역문\n초안작성 지시 접수일'} content={patentInfo.draft_translation_order_at} txtType='date'/>
                    <InnerDatePickerAndFileRow title={'번역문\n초안 전달'} data={patentInfo.translateDraftReceivingDate}
                                               setKey="draftReceivingDate"
                                               onChangeDatePickerJson={onChangeDatePickerInnerDate}
                                               haveFile={Boolean(patentInfo.translation_draft_file_cnt)}
                                               dateBtnHide={true}
                                               uploadBtnShow={true}
                                               onClickDownPopShow={() => onClickDownPopShow(typeName.agent_draft_translation, {modified: 0})}
                                               onClickUpPopShow={() => onClickUpPopShow(typeName.agent_draft_translation, {modified: 0})}/>
                    <DateAndFileRow title={`번역문\n초안 수정본 접수${patentInfo.draft_translation_modified_upload_at !== null ? '일' : ''}`}
                                    date={patentInfo.draft_translation_modified_upload_at}
                                    haveFile={Boolean(patentInfo.translation_modified_draft_file_cnt)}
                                    uploadBtnShow={false}
                                    onClickDownPopShow={() => onClickDownPopShow(typeName.agent_draft_translation, {modified: 1})}
                    />
                </div>
                <div className="rows">
                    <InnerDatePickerAndFileRow title={'번역문 초안\n최종본 전달'} data={patentInfo.translateDraftFinalDate}
                                               setKey="draftReceivingDate"
                                               onChangeDatePickerJson={onChangeDatePickerInnerDate}
                                               haveFile={Boolean(patentInfo.translation_final_draft_file_cnt)}
                                               dateBtnHide={true}
                                               uploadBtnShow={true}
                                               onClickDownPopShow={() => onClickDownPopShow(typeName.agent_draft_translation, {modified: 2})}
                                               onClickUpPopShow={() => onClickUpPopShow(typeName.agent_draft_translation, {modified: 2})}/>
                    <div className="row no_data"/>
                    <NormalRow title='번역문 기한' titleClassName='color_blue' content={patentInfo.translation_deadline}/>
                </div>
                <div className="rows">
                    <div className="row no_data"/>
                    <div className="row no_data"/>
                    <NormalRow title='출원 지시 접수일' titleClassName='color_blue' txtType={"date"} content={patentInfo.apply_order_at}/>
                </div>
            </div>

            <div className="field">
                <div className="rows">
                    <InputAndFileRow title="출원번호"
                                     titleClassName="color_blue"
                                     content={patentInfo.apply_number}
                                     editing={patentInfo.editing}
                                     haveFile={Boolean(patentInfo.apply_file_cnt)}
                                     onChange={(e) => onChangeInput("apply_number", e.target.value)}
                                     onClickDownPopShow={() => onClickDownPopShow(typeName.agent_apply)}
                                     onClickUpPopShow={() => onClickUpPopShow(typeName.agent_apply)}
                    />
                    <DatePickerRow title='출원일' condition={patentInfo.editing} data={patentInfo.apply_at} dataKey="apply_at" useOaFunc={false} onChangeDatePicker={onChangeDatePicker} />
                    <NormalRow title="우선일" content={patentInfo.priority_at} txtType='date'/>
                </div>
                <div className="rows">
                    <InputRow rowClassName="colspan2" title="발명의 명칭" condition={patentInfo.editing} content={patentInfo.invention_name}
                              inputClassName="long" onChange={onChangeInput} setKey="invention_name"/>
                    <NormalRow title='PACE 신청일' titleClassName='color_blue' content={patentInfo.pace_order_at} contentClassName='color_blue' txtType="date"/>
                </div>
                <div className="rows">
                    <InputRow rowClassName="colspan2" title="영문 명칭" condition={patentInfo.editing} content={patentInfo.invention_name_en}
                              inputClassName="long" onChange={onChangeInput} setKey="invention_name_en"/>
                </div>
            </div>
            {
                ListOaTag
            }

            <div className="field">
                <div className="rows">
                    <DatePickerRow title='등록 결정일' titleClassName="color_blue" condition={patentInfo.editing} data={patentInfo.register_decision_at} dataKey="register_decision_at" useOaFunc={false} onChangeDatePicker={onChangeDatePicker} />
                    <DivideApplyRow editingClass={patentInfo.editingClass} divideApplication={patentInfo.divideApplication} toggleSelected={() => patentInfo.setPatent({...patentInfo, divideApplication: {...patentInfo.divideApplication, value: !patentInfo.divideApplication.value}})}/>
                    <DatePickerRow title='등록일' condition={patentInfo.editing} data={patentInfo.register_at} dataKey="register_at" useOaFunc={false} onChangeDatePicker={onChangeDatePicker}/>
                </div>
                <div className="rows">
                    <InputAndFileRow title="등록번호"
                                     content={patentInfo.register_number}
                                     editing={patentInfo.editing}
                                     haveFile={Boolean(patentInfo.register_file_cnt)}
                                     onChange={(e) => onChangeInput("register_number", e.target.value)}
                                     onClickDownPopShow={() => onClickDownPopShow(typeName.agent_register)}
                                     onClickUpPopShow={() => onClickUpPopShow(typeName.agent_register)}/>
                    <div className="row no_data"/>
                    <NormalRow title='등록기한' titleClassName='color_blue' content={patentInfo.register_deadline} contentClassName='color_red' txtType='date'/>
                </div>
                <div className="rows">
                    <ChildNationRow ep={patentInfo.ep} onClick={() => setPopup(true)} />
                    <div className="row no_data"/>
                    <NormalRow title='분할 출원 기한' titleClassName='color_blue' content={patentInfo.division_deadline} contentClassName='color_red' txtType='date'/>
                </div>
            </div>
            {epTag}
            <div className="field">
                <div className="rows">
                    <NormalRow title='납부년차' titleClassName='color_blue' content={patentInfo.payment_year && patentInfo.payment_year + "년차"} contentClassName='color_blue'/>
                    {/*<NormalRow title='연차관리 회사' content={patentInfo.annual_payment_manage_company}/>*/}
                    <div className="row no_data"/>
                    <NormalRow title='연차료 기한' titleClassName='color_blue' content={patentInfo.annual_payment_deadline} contentClassName='color_red' txtType="date"/>
                </div>
            </div>
            {
                popup
                && <NationAddPopup onClickClose={() => setPopup(false)} onClickAdd={epCreate}/>
            }
        </div>
    );
}

export default FormAgentEp;
