import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
//import ".css/ChartTestTest.scss";
import { Line, Bar, Pie, Doughnut } from "react-chartjs-2";
import "./css/Modal.scss";
import qs from "query-string";
import Button from "@mui/material/Button";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

import CommonAPI from "../../API/common";
import MemberAPI from "../../API/member";

const options = {
  responsive: true,
  legend: {
    position: "bottom",
  },

  scales: {
    xAxes: [
      {
        //x축값 누적
        //stacked:true,
        display: true,
      },
    ],
    yAxes: [
      {
        //y축값 누적
        //stacked:true,
        display: true,
      },
    ],
  },
  plugins: {
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
    datalabels: {
      display: false,
    },
  },
};

const data = {
  labels: "a,b,c,d,e".split(","),
  datasets: [
    {
      label: "출원건수",
      data: [1, 2, 3, 4],
      borderColor: "rgba(255, 99, 132, 0.5)",
      fill: false,
    },
    {
      label: "등록건수",
      data: [3, 4, 6, 7],
      borderColor: "rgba(53, 162, 235, 0.5)",
      fill: false,
    },
  ],
};

function TotalChart() {
  const queryData = qs.parse(window?.location?.search);

  const [chartIdx, setChartIdx] = useState(""); // 차트 타입 구분
  const [inputList, setInputList] = useState(""); // 인풋창 관리
  const [labelInputList, setLabelInputList] = useState(""); //라벨 인풋창 관리
  const [xInputList, setXInputList] = useState("");

  const chartRef = useRef(null); // 차트 요소 접근
  const colorInputRef = useRef(null);
  const [showColorChangeInput, setShowColorChangeInput] = useState(false);
  const downloadRef = useRef(); // 차트 다운로드
  const [inputColor, setInputColor] = useState("#111111"); // 색상 요소
  const [targetIndex, setTargetIndex] = useState(null);
  const [colorList, setColorList] = useState([]);

  const [isAdmin, setIsAdmin] = useState(false);
  const [checked, setChecked] = React.useState(false);
  //const [dataSet, setDataSet] = useState([
  //   [1, 2, 3, 4],
  //  [1, 2, 3, 4],
  //]); // 데이터 값 관리
  const [dataSet, setDataSet] = useState([]); // 데이터 값 관리
  //const [LabelSet, setLabelSet] = useState([
  //    ['기업A'],
  //    ['기업B'],
  //]);
  const [LabelSet, setLabelSet] = useState([]);
  const [xSet, setXSet] = useState([[2018], [2019], [2020], [2021]]);

  const [chartData, setChartData] = useState(data); // 차트 구성 요소 관리
  const [rowName, setRowName] = useState("");
  const [columnName, setColumnName] = useState("");

  const [chartType, setChartType] = useState("");

  const [typeType, setTypeType] = useState([]);

  const [optionSet, setOptionSet] = useState(options);

  const lender = async () => {
    const marketInfoData = () => {
      CommonAPI.getMarketInfo("EM4NCKEYJ3PNWK3G").then((res) => {
        console.log(res.data);
      });
    };

    console.log(marketInfoData, "111111");
    setChartType(marketInfoData.data.chart_type);
    setChartIdx(marketInfoData.data.idx);
    if (marketInfoData.data.chart_data.type == undefined) {
      marketInfoData.data.chart_data["type"] = [];
    }

    if (marketInfoData.data.chart_data.color == undefined) {
      marketInfoData.data.chart_data["color"] = [
        ["#00359F"],
        ["#73A1FF"],
        ["#BBCFFF"],
        ["#7F7F7F"],
        ["#BEBEBE"],
      ];
    }

    if (marketInfoData.data.chart_data.x == undefined) {
      marketInfoData.data.chart_data["x"] = [["17"], ["18"], ["19"], ["20"]];
    }

    setChartData(marketInfoData.data.chart_data);
    if (marketInfoData.data.chart_data !== null) {
      setXSet(marketInfoData.data.chart_data.x);
      setDataSet(marketInfoData.data.chart_data.datasets);
      setLabelSet(marketInfoData.data.chart_data.labels);
      setTypeType(marketInfoData.data.chart_data.type);
      setColorList(marketInfoData.data.chart_data.color);
    }

    if (marketInfoData.data.chart_data.type == undefined) {
      marketInfoData.data.chart_data["type"] = [];
    }
  };

  useEffect(() => {
    lender();
  }, []);

  const [answer, setAnswer] = useState([]);

  console.log(typeType);
  const deleteItem = (e, idx) => {
    let arr = dataSet.map((list) => {
      let tmp = list.filter((el, index) => {
        return index !== idx;
      });

      setAnswer(tmp);
    });
    console.log(answer);
    //setDataSet(arr);
  };
  //******************차트 렌더링 함수***********************************//
  const renderChart = () => {
    //***********새로 추가된 정보 없는 경우(최초) 아래 코드 실행 ***************//

    setLabelInputList(
      LabelSet.map((list, i) => {
        if (chartData.datasets[i]?.backgroundColor) {
          return (
            <div key={`dddiv_${i}`}>
              {list.map((v, j) => {
                return (
                  <input
                    style={{
                      borderColor: chartData.datasets[i].backgroundColor,
                    }}
                    className="wow"
                    key={`label_${i}, ${j}`}
                    type="string"
                    Value={v}
                    onChange={(e) => onChangeLabelValue(e, i, j)}
                  />
                );
              })}
            </div>
          );
        } else {
          return (
            <div key={`dddiv_${i}`}>
              {list.map((v, j) => {
                return (
                  <input
                    style={{ border: "dotted" }}
                    className="wow"
                    key={`label_${i}, ${j}`}
                    type="string"
                    Value={v}
                    onChange={(e) => onChangeLabelValue(e, i, j)}
                  />
                );
              })}
            </div>
          );
        }
      })
    ); // 차트 라벨창 생성

    setXInputList(
      xSet.map((list, i) => {
        return (
          <div key={`ddiv_${i}`}>
            {list.map((v, j) => {
              return (
                <div>
                  <input
                    className="xwow"
                    key={`label_${i}, ${j}`}
                    type="string"
                    Value={v}
                    onChange={(e) => onChangeXValue(e, i, j)}
                  />
                  <Button
                    style={{
                      maxWidth: "30px",
                      maxHeight: "30px",
                      minWidth: "30px",
                      minHeight: "30px",
                    }}
                    onClick={(e) => deleteItem(v, j)}
                  ></Button>
                </div>
              );
            })}
          </div>
        );
      })
    );

    setInputList(
      dataSet.map((list, i) => {
        //console.log(list);
        if (chartData.datasets[i]?.backgroundColor) {
          return (
            <div className="dataLine" key={`div_${i}`}>
              {list.map((v, j) => {
                //console.log(v, j);
                return (
                  <input
                    style={{
                      borderColor: chartData.datasets[i].backgroundColor,
                    }}
                    onClick={(e) => deleteItem(v, j)}
                    className="dwow"
                    key={`${i}, ${j}`}
                    type="number"
                    value={v}
                    onChange={(e) => onChangeValue(e, i, j)}
                  />
                );
              })}
              <button
                className="buttonLine"
                onClick={() => showColorChangeDiv(i)}
              >
                색상 변경
              </button>
              <button key={`t_${i}`} onClick={() => changetLineType(i)}>
                line 변경
              </button>
              <button key={`tt_${i}`} onClick={() => changetBarType(i)}>
                bar 변경
              </button>
            </div>
          );
        } else {
          return (
            <div className="dataLine" key={`div_${i}`}>
              {list.map((v, j) => {
                return (
                  <input
                    className="dwow"
                    style={{ border: "dotted" }}
                    key={`${i}, ${j}`}
                    type="number"
                    value={v}
                    onChange={(e) => onChangeValue(e, i, j)}
                  />
                );
              })}
              <button
                className="buttonLine"
                onClick={() => showColorChangeDiv(i)}
              >
                색상 변경
              </button>
              <button key={`t_${i}`} onClick={() => changetBarType(i)}>
                line 변경
              </button>
              <button key={`tt_${i}`} onClick={() => changetBarType(i)}>
                bar 변경
              </button>
            </div>
          );
        }
      })
    ); // 인풋창 생성

    const labels = xSet;
    const datasets = [];
    let cnt = -1;

    for (const d of dataSet) {
      cnt++;
      const obj = {};
      let color = colorList[cnt];

      if (!color) {
        color = colorCreator();
        colorList[cnt] = color;
      }

      obj.label = LabelSet[cnt];
      obj.data = d;
      obj.backgroundColor = color;
      obj.borderColor = color;
      obj.fill = false;
      obj.type = typeType[cnt];

      datasets.push(obj);
    }

    setChartData({ labels, datasets });

    chartRef.current.chartInstance.chart.update();
  };

  //console.log(dataSet)

  //******************차트 렌더링 함수 끝***********************************//

  useEffect(() => {
    MemberAPI.getInfo().then((response) => {
      if (response?.data.idx === "PQ5KWXNY72LYDUJ7") {
        setIsAdmin(true);
      }
    });
  }, []);

  const onChangeValue = (e, i, j) => {
    dataSet[i][j] = e.target.value;

    setDataSet([...dataSet]);
  }; // 데이터 값 변경 로직

  const onChangeLabelValue = (e, i, j) => {
    LabelSet[i][j] = e.target.value;
    setLabelSet([...LabelSet]);
  }; //차트 라벨 값 변경 로직

  const onChangeXValue = (e, i, j) => {
    xSet[i][j] = e.target.value;
    setXSet([...xSet]);
  }; //차트 라벨 값 변경 로직

  /***const changetLineType = (i) => {
        typeType[i] = ["line"];
        setTypeType([...typeType])
    }

    const changetBarType = (i) => {
        typeType[i] = ["bar"]
        setTypeType([...typeType])
    }***/

  const changetLineType = (i) => {
    typeType[i] = ["line"];
    setTypeType([...typeType]);
  };

  const changetBarType = (i) => {
    typeType[i] = null;
    setTypeType([...typeType]);
  };

  const changeOptionStack = async () => {
    const stackOption = { ...options };
    stackOption.scales.xAxes[0] = { stacked: !checked };
    stackOption.scales.yAxes[0] = { stacked: !checked };
    setOptionSet({ ...stackOption });
    console.log(stackOption);
    setChecked(!checked);
    console.log("222222");
  };

  console.log(optionSet);

  const showColorChangeDiv = (index) => {
    setShowColorChangeInput(true);
    const color = colorList[index];

    const rgb = color
      .substring(
        color.indexOf("rgba(") + "rgba(".length,
        color.lastIndexOf(",")
      )
      .split(",");

    const hex = rgb.reduce((hexColor, v) => {
      return (hexColor += parseInt(v).toString(16).padEnd(2, "0"));
    }, "#");

    setTargetIndex(index);
    setInputColor(hex);
  };

  const cancelColorChange = () => {
    setShowColorChangeInput(false);
  };
  const confirmColorChange = () => {
    const colorHex = inputColor.substring(1);

    colorList[targetIndex] =
      "rgba(" +
      parseInt(colorHex.substring(0, 2), 16) +
      "," +
      parseInt(colorHex.substring(2, 4), 16) +
      "," +
      parseInt(colorHex.substring(4, 6), 16) +
      ", 0.5)";

    renderChart();
    setShowColorChangeInput(false);
  };

  useEffect(() => {
    renderChart();
    //initArr();
    console.log("111111");
  }, [LabelSet, xSet, dataSet, typeType, optionSet]);

  const colorCreator = () => {
    return `rgba(${Math.random() * 255 + 1}, ${Math.random() * 255 + 1}, ${
      Math.random() * 255 + 1
    }, 0.5)`;
  }; // 색상 만들기

  const initArr = () => {
    const randomnum = parseInt((Math.random() + 1) * 1000);
    for (let i = 0; i < 2; ++i) {
      dataSet.push("1".repeat(3).split(""));
      LabelSet.push([`기업${randomnum}`]);
    }
  };
  const controller = (target, action) => {
    console.log(target, action);
    if (target === "행") {
      const randomnum = parseInt((Math.random() + 1) * 1000);

      if (action === "+") {
        console.log(dataSet);
        dataSet.push("1".repeat(dataSet[0].length).split(""));
        LabelSet.push([`기업${randomnum}`]);

        console.log(LabelSet);
      } else {
        if (dataSet.length <= 1) {
          alert("더이상 행을 삭제할 수 없습니다.");
          return;
        }
        dataSet.pop();
        LabelSet.pop();
      }
    } else if (target === "열") {
      const randomnum = parseInt((Math.random() + 1) * 1000);
      if (action === "+") {
        for (let i = 0; i < dataSet.length; i++) {
          if (dataSet[i].length >= 10) {
            alert("더이상 데이터를 추가 할 수 없습니다..");
            return;
          }
          //dataSet[i][dataSet[i].length-1] = 1;
          //console.log(dataSet[i])

          // const newArray = [...dataSet[i]];
          // newArray.push(1);

          dataSet[i].push(1);
          // dataSet[i] = newArray;
        }
        xSet.push([randomnum]);
      } else {
        for (let i = 0; i < dataSet.length; i++) {
          if (dataSet[i].length <= 2) {
            alert("더이상 열을 삭제할 수 없습니다.");
            return;
          }
          dataSet[i].pop();
        }
        xSet.pop();
      }
    }

    setDataSet([...dataSet]);
  }; // 추가 삭제 기능

  const copyDOM = async (imageFileName) => {
    const canvas = await html2canvas(document.getElementById("screenShot"));
    const image = canvas.toDataURL("image/png", 1.0);
    downloadImage(image, imageFileName);
    console.log("캡쳐 완료", image);
  };

  const downloadImage = (blob, fileName) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);
    fakeLink.remove();
  };

  // Modal.jsx

  const insertMarketInfoChartData = async () => {
    const payload = {
      chartData: {
        x: xSet,
        labels: LabelSet,
        datasets: dataSet,
        type: typeType,
        color: colorList,
      },
      chartOption: options,
      rowName,
      columnName,
    };

    await CommonAPI.insertMarketInfoChartData(chartIdx, payload);
  };

  console.log(chartIdx);

  //####################################################################################차트 타입 따라 그래프 모양 바꾸기
  const makeChart = () => {
    if (chartType === 0) {
      return (
        <div ref={downloadRef} id="screenShot" className="chart_wrap">
          <Bar
            ref={chartRef}
            height={250}
            data={{ ...chartData }}
            options={optionSet}
          />
        </div>
      );
    } else if (chartType === 1) {
      return (
        <div ref={downloadRef} id="screenShot" className="chart_wrap">
          <Line
            ref={chartRef}
            height={250}
            data={{ ...chartData }}
            options={optionSet}
          />
        </div>
      );
    } else if (chartType === 2) {
      return (
        <div ref={downloadRef} id="screenShot" className="chart_wrap">
          <Pie
            ref={chartRef}
            height={250}
            data={{ ...chartData }}
            options={options}
          />
        </div>
      );
    } else if (chartType === 3) {
      return (
        <div ref={downloadRef} id="screenShot" className="chart_wrap">
          <Doughnut
            ref={chartRef}
            height={250}
            data={{ ...chartData }}
            options={options}
          />
        </div>
      );
    } else if (chartType !== 0) {
      return (
        <div ref={downloadRef} id="screenShot" className="chart_wrap">
          <Bar
            ref={chartRef}
            height={250}
            data={{ ...chartData }}
            options={options}
          />
        </div>
      );
    }
  };
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  //##########################################################################################

  return (
    <div id="PatentDownload">
      <div className="flex_column2">
        <div className="button_side">
          <div>
            <br />
            <br />
            <div id="MainTitle">&nbsp;시장정보 차트 만들기</div>
            <div id="SubTitle">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 시장정보 차트를 내 맘대로 만들어
              보세요
            </div>
            <br />
            <div className="content_title">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 시장정보 제목 및 그래프
              수정하기
            </div>

            <div className="title_section">
              <div id="hihi1">그래프 제목</div>

              <div id="boxbox1">그래프 제목 ############</div>
            </div>

            <div className="graph_section">
              <div id="hihi2">그래프 보기</div>

              <div id="boxbox2">
                <div id="chart12">{makeChart()}</div>
              </div>
            </div>

            <div className="button_wrap">
              <div className="button_2">
                <button id="btt1" onClick={() => controller("행", "+")}>
                  데이터셋 추가
                </button>
                <button id="btt2" onClick={() => controller("행", "-")}>
                  데이터셋 삭제
                </button>
                <button id="btt3" onClick={() => controller("열", "+")}>
                  데이터 추가
                </button>
                <button id="btt4" onClick={() => controller("열", "-")}>
                  데이터 삭제
                </button>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>Off</Typography>
                  <AntSwitch
                    checked={checked}
                    onChange={() => changeOptionStack()}
                    inputProps={{ "aria-label": "ant design" }}
                  />
                  <Typography>On</Typography>
                </Stack>
              </div>
              {isAdmin ? (
                <div>
                  행이름
                  <input onChange={(e) => setRowName(e.target.value)} />
                  열이름
                  <input onChange={(e) => setColumnName(e.target.value)} />
                  <button onClick={() => insertMarketInfoChartData()}>
                    차트 저장
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="table">
              <span className="chartLabel">{labelInputList}</span>

              <span className="xLabel">{xInputList}</span>

              <div className="Data">{inputList}</div>
            </div>

            <br></br>
            <br></br>

            {showColorChangeInput ? (
              <div className="color_input">
                <input
                  ref={colorInputRef}
                  type="color"
                  value={inputColor}
                  onChange={(e) => {
                    setInputColor(e.target.value);
                  }}
                />
                <button onClick={confirmColorChange}>변경하기</button>
                <button onClick={cancelColorChange}>취소</button>
              </div>
            ) : (
              ""
            )}

            <div>
              <div className="margin1">&nbsp; &nbsp;그래프 관련글 수정하기</div>

              <div className="button_side">
                <div className="flex_column">
                  설명 란
                  <Button
                    style={{
                      color: "#42a5f5",
                      backgroundColor: "#e3f2fd",
                      width: "100px",
                    }}
                    variant="contained"
                  >
                    복원하기 X
                  </Button>
                </div>
                <TextareaAutosize
                  maxRows={4}
                  aria-label="maximum height"
                  style={{ width: 700, height: 300 }}
                />
              </div>
            </div>
          </div>

          <br></br>
          <br></br>

          <div className="all">
            <div className="button_right">
              <div className="margin">&nbsp; &nbsp;그래프 확인하기</div>
              <hr className="hr_border" />
              <div className="flex_around">
                원본 그래프
                <Button style={{ width: "170px" }} variant="contained">
                  {" "}
                  원본 그래프로 복원하기{" "}
                </Button>
              </div>
              <div>
                <div className="flex_center">
                  <div className="graph_line"></div>
                </div>

                <div className="flex_center">
                  <Button
                    style={{
                      backgroundColor: "#b3e5fc",
                      height: "40px",
                      width: "300px",
                    }}
                    variant="contained"
                  >
                    {" "}
                    수정한 그래프 이미지로 추출하기{" "}
                  </Button>
                </div>
                <div className="flex_center">
                  <Button
                    style={{ height: "40px", width: "300px" }}
                    variant="contained"
                  >
                    {" "}
                    장바구니에 담기{" "}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex_center">
          <div className="button_side">
            <Button
              style={{
                backgroundColor: "#b3e5fc",
                height: "40px",
                width: "250px",
                margin: "20px",
              }}
              onClick={() => copyDOM(downloadRef.current, "test")}
              variant="contained"
            >
              {" "}
              수정한 그래프 이미지로 추출하기{" "}
            </Button>

            <Button
              style={{ height: "40px", width: "250px", margin: "20px" }}
              variant="contained"
            >
              {" "}
              수정한 그래프 장바구니에 담기{" "}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TotalChart;
