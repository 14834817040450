import { useState } from "react";

const BizActChild = ({
  id,
  category,
  cash_data,
  onUpdateCashData,
  onUpdateCategoryData,
  deleteCashItem,
}) => {
  const months = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
  ];
  const [bizCategory, setBizCategory] = useState(category);
  const [bizCashData, setBizCashData] = useState(cash_data);

  const handleInputChange = (event, month) => {
    const value = event.target.value;

    // 숫자와 콤마만 허용하는 정규식
    const isNumeric = /^[0-9,]*$/;

    if (!isNumeric.test(value)) {
      alert("숫자만 입력하세요."); // 숫자가 아닌 경우 알림
      return; // 함수 실행 중단
    }

    // 콤마 제거하여 숫자만 남김 (부모 컴포넌트로 전달될 값)
    const numericValue = value.replace(/,/g, "");

    // 입력값이 빈 문자열인 경우 0으로 처리, 아니면 정수로 변환
    const parsedValue = numericValue === "" ? 0 : parseInt(numericValue, 10);

    const updatedCashData = {
      ...bizCashData,
      [month]: parsedValue,
    };

    // 콤마 추가하여 화면에 표시될 값 (로컬 상태 업데이트)
    const formattedValue = isNaN(parsedValue)
      ? ""
      : parsedValue.toLocaleString();

    const updatedCashDataCm = {
      ...bizCashData,
      [month]: formattedValue || "",
    };

    setBizCashData(updatedCashDataCm); // 화면에 표시될 값 업데이트
    onUpdateCashData(updatedCashData); // 부모 컴포넌트로 전달될 값
  };

  const handleCategoryChange = (event) => {
    const value = event.target.value;
    const updatedCategoryData = {
      ...bizCategory,
      category_name: value,
    };

    setBizCategory(updatedCategoryData);
    onUpdateCategoryData(updatedCategoryData);
  };

  return (
    <div id={id} className="child">
      <div className="label">
        <input
          type="text"
          value={bizCategory.category_name}
          onChange={(e) => handleCategoryChange(e)}
        />
      </div>
      <ul>
        {months.map((month, idx) => (
          <li key={idx}>
            <input
              type="text"
              placeholder="0"
              value={bizCashData[month].toLocaleString() || ""}
              onChange={(e) => handleInputChange(e, month)}
              className="align_right"
            />
          </li>
        ))}
        <li className="btn_list ">
          {/* double */}
          {/* <button className={`all`}>
            <i className="icon_in_all"></i>
          </button> */}
          <button
            className={`del act_child`}
            onClick={() => deleteCashItem(id)}
          >
            -
          </button>
        </li>
      </ul>
    </div>
  );
};

export default BizActChild;
