const HoldingsCashflow = () => {
  const data = [
    {
      date: "2024.01.01",
      half: "2024.04",
      third: "2024.07",
      runway: "2025.03",
    },
    {
      date: "2024.02.01",
      half: "2024.08",
      third: "2025.02",
      runway: "2025.11",
    },
    {
      date: "2024.03.01",
      half: "2025.03",
      third: "2025.06",
      runway: "2025.12",
    },
    {
      date: "2024.04.01",
      half: "2024.05",
      third: "2024.10",
      runway: "2025.05",
    },
    {
      date: "2024.05.01",
      half: "2024.08",
      third: "2025.01",
      runway: "2025.09",
    },
    {
      date: "2024.06.01",
      half: "2024.04",
      third: "2024.11",
      runway: "2025.06",
    },
  ];
  const getDateFromString = (dateString) => {
    const parts = dateString.split(".");
    if (parts.length === 2) {
      return new Date(`${parts[0]}-${parts[1]}-01`);
    }
    if (parts.length === 3) {
      return new Date(`${parts[0]}-${parts[1]}-${parts[2]}`);
    }
    throw new Error("Invalid date format");
  };

  const allDates = data.flatMap((item) => [
    getDateFromString(item.date),
    getDateFromString(item.half + ".01"),
    getDateFromString(item.third + ".01"),
    getDateFromString(item.runway + ".01"),
  ]);
  const monthDifference = (d1, d2) => {
    const date1 = new Date(d1);
    const date2 = new Date(d2);
    return (
      date2.getFullYear() * 12 +
      date2.getMonth() -
      (date1.getFullYear() * 12 + date1.getMonth())
    );
  };

  const startDate = new Date(Math.min(...allDates));
  const endDate = new Date(Math.max(...allDates));
  const totalMonths = monthDifference(startDate, endDate);

  const getPosition = (date) => {
    const dateObj = new Date(date.replace(".", "-"));
    const monthsFromStart = monthDifference(startDate, dateObj);
    return (monthsFromStart / totalMonths) * 100;
  };

  return (
    <div id="HoldingsHome">
      <div className="part">
        <div>
          <strong className="part_tit">캐시플로우 히스토리</strong>
        </div>
        <div className="gantt_box">
          <div className="gantt-chart">
            {data.map((item, index) => (
              <div key={index} className="item">
                <div className="date">{item.date}</div>
                <div className="timeline">
                  {Array.from({ length: totalMonths }, (_, i) => (
                    <div
                      key={i}
                      className={`month ${i % 12 === 0 ? "year" : ""}`}
                      style={{ left: `${(i / totalMonths) * 100}%` }}
                    >
                      {index === 0 && i % 12 === 0 && (
                        <div className="year-label">{2024 + i / 12}년</div>
                      )}
                      {index === 0 && (
                        <div className="month-label">{(i % 12) + 1}월</div>
                      )}
                    </div>
                  ))}
                </div>
                <div
                  className="marker half"
                  style={{ left: `${getPosition(item.half + ".01")}%` }}
                  title="현금이 1/2 남는 시점"
                ></div>
                <div
                  className="marker third"
                  style={{ left: `${getPosition(item.third + ".01")}%` }}
                  title="현금이 1/3 남는 시점"
                ></div>
                <div
                  className="marker runway"
                  style={{ left: `${getPosition(item.runway + ".01")}%` }}
                  title="런웨이 시점"
                ></div>
                <span
                  className="runway_bg"
                  style={{
                    width: `calc(${getPosition(item.runway + ".01")}% - 82px)`,
                  }}
                ></span>
              </div>
            ))}
          </div>

          <div className="legend">
            <div className="legend_tit">시그널</div>
            <div className="legend-item">
              <span className="marker half"></span> 현금이 1/2 남는 시점
            </div>
            <div className="legend-item">
              <span className="marker third"></span> 현금이 1/3 남는 시점
            </div>
            <div className="legend-item">
              <span className="marker runway"></span> 런웨이 시점
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HoldingsCashflow;
