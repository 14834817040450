import API from '../../util/api';

export default {
    getAccountInfo() {
        return API.get('/common/company/info');
    },

    getAccountCompany() {
        return API.get('/manager/member');
    },

    getAccountCompanyInfoAsAgent() {
        return API.get('/agent/company/info');
    },

    getAccountAgent() {
        return API.get('/agent/member');
    },

    getAgentCompanyList() {
        return API.get('/manager/company');
    },

    getCompanyToAgentList() {
        return API.get('/agent/company');
    },

    uploadProfile(payload) {
        return API.post(`/common/company/profile_image`, payload);
    }
};
