import { useState } from "react";
import EstimateInput from "./EstimateInput";
import API from "../../../util/api";
import "./css/Estimate.scss";
import EstimatePopUp from "./EstimatePopUp";

const Estimate = ({ history, location }) => {
  // packageData = 선택한 패키지의 데이터
  // optionData = 추가 용량 데이터
  const { packageData, optionData } = location.state;

  // 사용자가 입력하는 데이터
  const [estimateData, setEstimateData] = useState({
    company_name: "", // 회사명
    manager_name: "", // 담당자명
    manager_email: "", // 이메일
    manager_phone: "", // 핸드폰 번호
  });

  const [checkBox, setCheckBox] = useState({
    all: false,
    require: false,
    option: false,
  });

  const [popUpShow, setPopUpShow] = useState(false);
  const [popUpType, setPopUpType] = useState(0);

  const onChange = (e) => {
    setEstimateData({ ...estimateData, [e.target.name]: e.target.value });
  };

  const handleCheckBox = (e) => {
    const { name, checked } = e.target;

    if (name === "all") {
      setCheckBox({
        all: checked,
        require: checked,
        option: checked,
      });
    } else {
      if (checked === false) {
        setCheckBox({ ...checkBox, all: false, [name]: checked });
      } else {
        setCheckBox({ ...checkBox, [name]: checked });
      }
    }
  };

  const handleSubmit = async () => {
    let check = false;

    Object.values(estimateData).map((item) => {
      if (item === "") {
        check = true;
      }
    });

    if (check) {
      alert("필수 입력 항목을 입력해 주세요.");
      return;
    }

    if (checkBox.require === false) {
      alert("개인정보 수집 및 이용에 동의해주세요.");
      return;
    }

    // 모든 데이터 추가한 payload
    let payload = {
      ...packageData,
      ...optionData,
      ...estimateData,
    };

    // 서버에 데이터 전송하는 API 추가
    await API.post(`/common/product/send_mail`, payload).then(() =>
      history.push("/intro/estimate/done")
    );
  };

  return (
    <div id="Estimate">
      <header>
        <h2>견적서 신청을 위한 정보 작성</h2>
        <h5>
          상품 유형 및 사용 기간, 옵션 여부에 따라 이용 금액을 전송 드리기 위한
          과정입니다.
          <br />
          입력해주신 담당자 이메일로 견적서를 송부 드리겠습니다.
        </h5>
      </header>
      <section>
        <div className="info_tab">정보 입력</div>
        <div className="input_wrapper">
          <EstimateInput
            data={estimateData}
            title="회사명"
            name="company_name"
            essential={true}
            onChange={onChange}
          />
          <EstimateInput
            data={estimateData}
            title="담당자명"
            name="manager_name"
            essential={true}
            onChange={onChange}
          />
          <EstimateInput
            data={estimateData}
            title="이메일"
            name="manager_email"
            essential={true}
            onChange={onChange}
          />
          <EstimateInput
            data={estimateData}
            title="휴대전화"
            name="manager_phone"
            essential={true}
            onChange={onChange}
          />
        </div>
        <div className="checkbox_wrapper">
          <div>
            서비스 약관 동의{" "}
            <input
              type="checkbox"
              name="all"
              checked={checkBox.all}
              onClick={(e) => handleCheckBox(e)}
            />
          </div>
          <div>
            <div>
              <input
                type="checkbox"
                name="require"
                checked={checkBox.require}
                onClick={(e) => handleCheckBox(e)}
              />{" "}
              <p>개인정보 수집 및 이용에 동의합니다 (필수)</p>{" "}
              <span
                onClick={() => {
                  setPopUpType(0);
                  setPopUpShow(true);
                }}
              >
                &gt;
              </span>
            </div>
            <div>
              <input
                type="checkbox"
                name="option"
                checked={checkBox.option}
                onClick={(e) => handleCheckBox(e)}
              />{" "}
              <p>마케팅 수집 및 활용에 동의합니다 (선택)</p>{" "}
              <span
                onClick={() => {
                  setPopUpType(1);
                  setPopUpShow(true);
                }}
              >
                &gt;
              </span>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <button onClick={() => history.goBack()}>돌아가기</button>
        <button onClick={() => handleSubmit()}>견적서 받기</button>
      </footer>
      {popUpShow && (
        <EstimatePopUp setPopUpShow={setPopUpShow} type={popUpType} />
      )}
    </div>
  );
};

export default Estimate;
