import React from "react";
import { Route } from "react-router-dom";
import IntroContainer from "../components/intro/IntroContainer";
import CustomerService from "../components/intro/CustomerService";
import Loans from "../components/intro/Loans";
import Investment from "../components/intro/Investment";
import CustomSwitch from "./common/CustomSwitch";
import IntroServiceDetail from "../components/intro/IntroServiceDetail";
import Price from "../components/price/Price";
import IntroWaiting from "../components/intro/IntroWaiting";
import Estimate from "../components/intro/estimate/Estimate";
import PaymentResult from "../components/price/PaymentResult";
import EstimateDone from "../components/intro/estimate/EstimateDone";

function IntroRoute({ match }) {
  return (
    <CustomSwitch>
      <Route
        exact
        path={`${match.path}/page/detail`}
        component={IntroServiceDetail}
      />
      <Route exact path={`${match.path}/main`} component={IntroContainer} />
      <Route exact path={`${match.path}/price`} component={Price} />
      <Route
        exact
        path={`${match.path}/price/result`}
        component={PaymentResult}
      />
      <Route exact path={`${match.path}/service`} component={CustomerService} />
      <Route
        exact
        path={`${match.path}/service/detail`}
        component={CustomerService}
      />
      <Route exact path={`${match.path}/loans`} component={Loans} />
      <Route exact path={`${match.path}/investment`} component={Investment} />
      <Route exact path={`${match.path}/waiting`} component={IntroWaiting} />
      <Route exact path={`${match.path}/estimate`} component={Estimate} />
      <Route
        exact
        path={`${match.path}/estimate/done`}
        component={EstimateDone}
      />
    </CustomSwitch>
  );
}

export default IntroRoute;
