import React, { useContext, useEffect, useState } from "react";
import "./css/MarketInfoCategory.scss";
import MarketInfoSideBar from "./MarketInfoSideBar";
import MarketInfoCategoryList from "./MarketInfoCategoryList";
import MarketInfoKeywordSearch from "./MarketInfoKeywordSearch";

function MarketInfoCategory({ history }) {
  const [changeTab, setChangeTab] = useState(false);
  return (
    <div id="MarketInfoCategory">
      <div className="marketInfo_wrapper">
        <MarketInfoSideBar />
        <div className="marketInfo_section">
          <div className="marketInfo_tab">
            <p
              className={!changeTab && "active"}
              onClick={() => setChangeTab(false)}
            >
              시장정보 키워드 검색
            </p>
            <p
              className={changeTab && "active"}
              onClick={() => setChangeTab(true)}
            >
              시장정보 카테고리 보기
            </p>
          </div>
          {changeTab ? <MarketInfoCategoryList /> : <MarketInfoKeywordSearch />}
        </div>
      </div>
    </div>
  );
}

export default MarketInfoCategory;
