import React, { useEffect, useState } from "react";
import GroupwareSideBar from "../GroupwareSideBar";
import GWAPI from "../../../API/groupware";
import util from "../../../util/util";
import { useHistory } from "react-router-dom";
import { groupWareWorksPath } from "../groupwarePath";

const GroupwareDocDashBoard = () => {
    const history = useHistory();
    const [adminMenu, setAdminMenu] = useState([]);
    const [commonDocs, setCommonDocs] = useState([]);

    
    useEffect(() => {
        GWAPI.getCommonDocList().then((res)=>{
            //console.log("데이터: ", res.data); 
            setCommonDocs(res.data.commonDocList);      
        });
    }, []);
    

    useEffect(() => {
        getAdminMenu();
    }, []);

    const getAdminMenu = () => {
        let param = {
            su_menu_cd: "100006",
            menuTitle: "관리자",
            isContentWrap: false,
            isOpen: false,
        };
        util.getGroupwareAdminMenu(setAdminMenu, param);
    };

    return (
        <div id="GroupwareVacationDashBoard">
            <GroupwareSideBar
                currentPath={groupWareWorksPath["approval"]}
                adminMenu={adminMenu}
                sidebar={"approval"}
            />
            <div className="vacation_wrapper">
                <h5>일반결재</h5>
                <div className="vacation_grid_apply">
                    {
                        commonDocs && commonDocs.map(doc => (
                            <div key={doc.document_cd}>
                                <p>
                                    <i className="icon_widget_briefcase" />
                                    {doc.comment}
                                </p>
                                <p className="item_head_title">{doc.comment} 작성하기</p>
                                <button
                                    onClick={() =>
                                        history.push(`/groupware/works?section=docList&command=writeForm&doc_type=${doc.doc_type}`)
                                    }
                                >
                                    신청하기
                                </button>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default GroupwareDocDashBoard;